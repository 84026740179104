export const CONTRACT_FILE_GET_REQUEST = 'CONTRACT_FILE_GET_REQUEST'
export const CONTRACT_FILE_GET_SUCCESS = 'CONTRACT_FILE_GET_SUCCESS'
export const CONTRACT_FILE_GET_ERROR = 'CONTRACT_FILE_GET_ERROR'

export const CONTRACT_FILE_ADD_REQUEST = 'CONTRACT_FILE_ADD_REQUEST'
export const CONTRACT_FILE_ADD_SUCCESS = 'CONTRACT_FILE_ADD_SUCCESS'
export const CONTRACT_FILE_ADD_ERROR = 'CONTRACT_FILE_ADD_ERROR'

export const CONTRACT_FILE_DELETE_REQUEST = 'CONTRACT_FILE_DELETE_REQUEST'
export const CONTRACT_FILE_DELETE_SUCCESS = 'CONTRACT_FILE_DELETE_SUCCESS'
export const CONTRACT_FILE_DELETE_ERROR = 'CONTRACT_FILE_DELETE_ERROR'
