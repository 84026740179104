import {$authHost} from "../../../http";
import {
    SPECIFICATION_ADD_ERROR,
    SPECIFICATION_ADD_REQUEST, SPECIFICATION_ADD_SUCCESS,
    SPECIFICATION_GET_ERROR,
    SPECIFICATION_GET_REQUEST,
    SPECIFICATION_GET_SUCCESS
} from "../../constants/contract/specifitaionConstants";
import {getData} from "../template/get";
import {editData} from "../template/edit";

export const getSpecification = () => async (dispatch) => {
    return await dispatch(getData(SPECIFICATION_GET_REQUEST, SPECIFICATION_GET_SUCCESS, SPECIFICATION_GET_ERROR, 'contractSpecification'))
};

export const addSpecification = (qty, price, unitOfMeasure, service, serviceKind, servicePrice) => async (dispatch) => {
    try {
        dispatch({
            type: SPECIFICATION_ADD_REQUEST,
        });

        const {data} = await $authHost.post(
            "/api/v1/contractSpecification",
            {quantity: qty, price, unitId: unitOfMeasure, serviceId: service, serviceKindId: serviceKind, servicePriceListId: servicePrice}
        );
        await dispatch(getSpecification())
        dispatch({
            type: SPECIFICATION_ADD_SUCCESS,
            payload: data.result
        });
        return data.result
    } catch (error) {
        dispatch({
            type: SPECIFICATION_ADD_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const editSpecification = (id, qty, price, unitOfMeasure, service, serviceKind, servicePrice) => async (dispatch) => {
    return await dispatch(editData(SPECIFICATION_ADD_REQUEST, SPECIFICATION_ADD_SUCCESS, SPECIFICATION_ADD_ERROR, 'contractSpecification', getSpecification(), {id, quantity: qty, price, unitId: unitOfMeasure, serviceId: service, serviceKindId: serviceKind, servicePriceListId: servicePrice}))
};

export const deleteSpecification = (id) => async (dispatch) => {
    try {
        dispatch({
            type: SPECIFICATION_ADD_REQUEST,
        });

        await $authHost.delete(
            "/api/v1/contractSpecification",
            {
                data: {
                    id
                }
            },
        );
        await dispatch(getSpecification())
        dispatch({
            type: SPECIFICATION_ADD_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: SPECIFICATION_ADD_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};