import {
    SERVICE_CATEGORY_GET_ERROR,
    SERVICE_CATEGORY_GET_REQUEST,
    SERVICE_CATEGORY_GET_SUCCESS, SERVICE_PACKAGE_GET_ERROR, SERVICE_PACKAGE_GET_REQUEST, SERVICE_PACKAGE_GET_SUCCESS
} from "../../constants/serviceCategory/serviceCategoryConstants";

const initialState = {
    serviceCategory: [],
    servicePackage: [],
    loading: false,
    success: null,
    error: null
};

export const serviceCategoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case SERVICE_CATEGORY_GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_CATEGORY_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                serviceCategory: action.payload.result,
            };
        case SERVICE_CATEGORY_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case SERVICE_PACKAGE_GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_PACKAGE_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                servicePackage: action.payload.result,
            };
        case SERVICE_PACKAGE_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        default:
            return state;
    }
}