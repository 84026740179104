import React, {useEffect, useState} from 'react';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {style} from "./popup";
import Typography from "@mui/material/Typography";
import {Page, Text, View, Document, StyleSheet} from '@react-pdf/renderer';
import {PDFViewer} from '@react-pdf/renderer';
import {Font} from '@react-pdf/renderer'
import RobotoRegular from '../../assets/fonts/Roboto/Roboto-Regular.ttf'
import RobotoItalic from '../../assets/fonts/Roboto/Roboto-Italic.ttf'
import RobotoBold from '../../assets/fonts/Roboto/Roboto-Bold.ttf'
import RobotoMedium from '../../assets/fonts/Roboto/Roboto-Medium.ttf'
import {useDispatch, useSelector} from "react-redux";
import {getOneCompletionAct} from "../../redux/actions/bookkeeping/bookkeepingActions";
import moment from "moment";
import {getOneContract} from "../../redux/actions/contract/contractActions";
import Loader from "../Shared/Loader";

function PopupExcel({open, handleClose, id}) {

    const dispatch = useDispatch()
    const {completionActOne} = useSelector((state) => state.bookkeeping);
    const [specifications, setSpecifications] = useState([])
    const [loading, setLoading] = useState(false)
    const [customer, setCustomer] = useState('')

    useEffect(() => {
        (async function () {
            setLoading(true)
            const res = await dispatch(getOneCompletionAct(id))
            const client = res?.customer?.legalPersonId ? res?.customer?.legalPerson?.name : res?.customer?.person?.fullName
            setCustomer(client)
            if (res?.contractId) {
                const contract = await dispatch(getOneContract(res?.contractId))
                setSpecifications(contract?.specifications)
            }
            setLoading(false)
        }())
    }, [dispatch, id]);

    Font.register({
        family: "RobotoRegular",
        src: RobotoRegular
    });

    Font.register({
        family: "RobotoItalic",
        src: RobotoItalic
    });

    Font.register({
        family: "RobotoMedium",
        src: RobotoMedium
    });

    Font.register({
        family: "RobotoBold",
        src: RobotoBold
    });

    const styles = StyleSheet.create({
        page: {
            flexDirection: 'row',
            fontFamily: "RobotoRegular",
            fontSize: 10,
            width: '100%'
        },
        section: {
            margin: 45,
            padding: 10,
            flexGrow: 1
        },
        text1: {
            alignSelf: 'flex-end',
            fontFamily: "RobotoItalic",
            maxWidth: 140,
            textAlign: 'center'
        },
        text2: {
            alignSelf: 'flex-end',
            marginTop: 21
        },
        text3: {
            marginTop: 21
        },
        formGroup4: {
            marginLeft: 65,
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center'
        },
        text4: {
            marginTop: 3,
        },
        form1: {
            marginTop: 67,
            display: 'flex',
            flexDirection: 'row'
        },
        form2: {
            marginTop: 37,
            display: 'flex',
            flexDirection: 'row'
        },
        input1: {
            width: 700,
            borderBottom: '2px solid black'
        },
        input2: {
            width: 701,
            borderBottom: '2px solid black'
        },
        textInput: {
            textAlign: 'center',
            fontFamily: "RobotoItalic",
            alignSelf: 'center'
        },
        formGroup: {
            marginLeft: 46,
            display: 'flex',
        },
        formGroup2: {
            marginLeft: 27,
            display: 'flex',
        },
        formAndInput: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row'
        },
        forms: {
            display: 'flex'
        },
        inn: {
            display: 'flex',
            marginTop: 18
        },
        innText: {
            alignSelf: 'center'
        },
        innBlock: {
            width: 160,
            height: 50,
            border: '2px solid black',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        contract1: {
            display: 'flex',
            flexDirection: 'row'
        },
        contractLeft: {
            display: 'flex'
        },
        formContract: {
            marginTop: 2,
            display: 'flex',
            flexDirection: 'row'
        },
        formGroupContract: {
            marginLeft: 14,
            display: 'flex',
        },
        inputContract: {
            width: 535,
            borderBottom: '1.5px solid black'
        },
        formContractText: {
            fontSize: 14,
            fontFamily: 'RobotoBold',
            marginLeft: 189,
            marginTop: 15
        },
        contractTablet: {
            marginLeft: 95,
            width: 183,
            height: 50,
            border: '2px solid black'
        },
        contractTabletTop: {
            borderBottom: '2px solid black',
            height: 30,
            display: 'flex',
            flexDirection: 'row',
            width: '100%'
        },
        contractTabletBottom: {
            height: 20,
            display: 'flex',
            flexDirection: 'row',
            width: '100%'
        },
        contractTabletColumn: {
            width: '50%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            borderRight: '2px solid black'
        },
        contractTabletColumnText: {
            maxWidth: '70%'
        },
        contractTabletColumnRight: {
            width: '50%',
            height: '100%',
            display: 'flex',
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
        },
        table: {
            marginTop: 12,
            width: '100%',
            minHeight: 70,
            borderTop: '2px solid black',
            borderRight: '2px solid black',
            display: 'flex',
        },
        tableHeader: {
            display: 'flex',
            flexDirection: 'row'
        },
        tableHeaderLast: {
            display: 'flex',
            flexDirection: 'row'
        },
        tableHeaderColumn1: {
            width: '5%',
            height: 71,
            textAlign: 'center',
            display: 'flex',
            borderLeft: '2px solid black',
            justifyContent: 'center',
            borderBottom: '1px solid black',
            borderRight: '1px solid black',
            alignItems: 'center'
        },
        tableHeaderColumn2: {
            width: '22.5%',
            height: 71,
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            borderBottom: '1px solid black',
            borderRight: '1px solid black',
            alignItems: 'center'
        },
        tableHeaderColumn3: {
            width: '8.5%',
            height: 71,
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            borderBottom: '1px solid black',
            borderRight: '1px solid black',
            alignItems: 'center'
        },
        tableHeaderColumn4: {
            width: '17%',
            height: 71,
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            borderBottom: '1px solid black',
            borderRight: '1px solid black',
            alignItems: 'center'
        },
        tableHeaderColumn5: {
            width: '6%',
            height: 71,
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            borderBottom: '1px solid black',
            borderRight: '1px solid black',
            alignItems: 'center'
        },
        tableHeaderColumn6: {
            width: '45%',
            height: 71,
            textAlign: 'center',
            display: 'flex',
            borderBottom: '1px solid black',
            borderRight: '1px solid black',
        },
        tableHeaderColumnLast: {
            display: 'flex',
            width: '100%',
        },
        tableBodyColumnLast: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
        },
        tableHeaderColumnLastTop: {
            textAlign: 'center',
            flex: '1 0 50%',
            display: 'flex',
            justifyContent: 'center',
            borderBottom: '1px solid black',
        },
        tableHeaderColumnLastBottom: {
            flexDirection: 'row',
            display: 'flex',
            height: '100%'
        },
        tableHeaderMiniColumn: {
            flex: '1 0 33.3333%',
            display: 'flex',
            justifyContent: 'center',
            borderRight: '1px solid black',
        },
        tableBodyMiniColumn: {
            flex: '1 0 33.3333%',
            display: 'flex',
            justifyContent: 'center',
            borderRight: '1px solid black',
        },
        tableBodyColumn1: {
            width: '5%',
            height: 14,
            textAlign: 'center',
            display: 'flex',
            borderLeft: '2px solid black',
            justifyContent: 'center',
            borderBottom: '1px solid black',
            borderRight: '1px solid black',
            alignItems: 'center'
        },
        tableBodyColumn2: {
            width: '22.5%',
            height: 14,
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            borderBottom: '1px solid black',
            borderRight: '1px solid black',
            alignItems: 'center'
        },
        tableBodyColumn3: {
            width: '8.5%',
            height: 14,
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            borderBottom: '1px solid black',
            borderRight: '1px solid black',
            alignItems: 'center'
        },
        tableBodyColumn4: {
            width: '17%',
            height: 14,
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            borderBottom: '1px solid black',
            borderRight: '1px solid black',
            alignItems: 'center'
        },
        tableBodyColumn5: {
            width: '6%',
            height: 14,
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            borderBottom: '1px solid black',
            borderRight: '1px solid black',
            alignItems: 'center'
        },
        tableBodyColumn6: {
            width: '45%',
            height: 14,
            textAlign: 'center',
            display: 'flex',
            borderBottom: '1px solid black',
            borderRight: '1px solid black',
        },
        tableLastColumn1: {
            width: '5%',
            height: 14,
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        tableLastColumn2: {
            width: '22.5%',
            height: 14,
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        tableLastColumn3: {
            width: '8.5%',
            height: 14,
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        tableLastColumn4: {
            width: '17%',
            height: 14,
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        tableLastColumn5: {
            width: '6%',
            height: 14,
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center'
        },
        tableLastColumn6: {
            width: '45%',
            height: 14,
            display: 'flex',
            textAlign: 'center',
            borderLeft: '1px solid black',
            borderBottom: '1px solid black',
            borderRight: '1px solid black',
        },
        form3: {
            marginTop: 32,
            display: 'flex',
            flexDirection: 'row'
        },
        input3: {
            width: 770,
            borderBottom: '2px solid black'
        },
        input4: {
            width: 70,
            marginLeft: 3,
            textAlign: 'center',
            borderBottom: '1px solid black'
        },
        input5: {
            width: 825,
            marginLeft: 32,
            textAlign: 'center',
            borderBottom: '2px solid black'
        },
        formGroup3: {
            marginLeft: 12,
            display: 'flex',
        },
        bottom: {
            display: 'flex',
            flexDirection: 'row',
            marginTop: 52
        },
        bottomLeft: {
            display: 'flex',
            flex: '0 1 50%'
        },
        bottomLeftRow: {
            display: 'flex',
            flexDirection: 'row'
        },
        formGroup6: {
            marginLeft: 12,
            display: 'flex',
        },
        input6: {
            width: 117,
            textAlign: 'center',
            borderBottom: '2px solid black'
        },
        input7: {
            width: 162,
            textAlign: 'center',
            borderBottom: '2px solid black'
        },
        input8: {
            marginLeft: 14,
            width: 162,
            textAlign: 'center',
            borderBottom: '2px solid black'
        },
        bottomLeftChar: {
            marginLeft: 5,
            marginRight: 3
        },
        formGroup7: {
            display: 'flex',
        },
        signature: {
            marginTop: 18,
            marginLeft: 29,
            fontFamily: 'RobotoMedium'
        },
        signature2: {
            marginTop: 17,
            marginLeft: 23,
            fontFamily: 'RobotoMedium'
        },
        formGroup8: {
            display: 'flex',
            marginTop: 19,
            flexDirection: 'row'
        },
    });
        return (
            <div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{...style, width: "90%", minHeight: "90%", display: 'flex', flexDirection: 'column'}}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Просмотр акта
                        </Typography>
                        {loading ? <Loader/> : <PDFViewer width="100%" height="100%" style={{flex: 1}}>
                            <Document language="ru">
                                <Page wrap orientation="landscape" size="A3" style={styles.page}>
                                    <View style={styles.section}>
                                        <Text style={styles.text1}>Приложение 50
                                            к приказу Министра финансов
                                            Республики Казахстан
                                            от 20 декабря 2012 года № 562</Text>
                                        <Text style={styles.text2}>Форма Р-1</Text>
                                        <View style={styles.formAndInput}>
                                            <View style={styles.forms}>
                                                <View style={styles.form1}>
                                                    <Text>Заказчик</Text>
                                                    <View style={styles.formGroup}>
                                                        <View style={styles.input1}>
                                                            <Text>{customer}</Text>
                                                        </View>
                                                        <Text style={styles.textInput}>полное наименование, адрес,
                                                            данные о
                                                            средствах связи</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.form2}>
                                                    <Text>Исполнитель</Text>
                                                    <View style={styles.formGroup2}>
                                                        <View style={styles.input2}>
                                                            <Text>{completionActOne?.performer?.name}, {completionActOne?.performer?.addressLine}</Text>
                                                        </View>
                                                        <Text style={styles.textInput}>полное наименование, адрес,
                                                            данные о
                                                            средствах связи</Text>
                                                    </View>
                                                </View>
                                            </View>
                                            <View style={styles.forms}>
                                                <View style={styles.inn}>
                                                    <Text style={styles.innText}>ИИН/БИН</Text>
                                                    <View style={styles.innBlock}>
                                                        <Text>{completionActOne?.customerIdentity}</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.inn}>
                                                    <View style={styles.innBlock}>
                                                        <Text>{completionActOne?.performerIdentity}</Text>
                                                    </View>
                                                </View>
                                            </View>
                                        </View>
                                        <View style={styles.contract1}>
                                            <View style={styles.contractLeft}>
                                                <View style={styles.formContract}>
                                                    <Text>Договор (контракт)</Text>
                                                    <View style={styles.formGroupContract}>
                                                        <View style={styles.inputContract}>
                                                            <Text>{completionActOne?.contract?.name || 'Без договора'}</Text>
                                                        </View>
                                                    </View>
                                                </View>
                                                <Text style={styles.formContractText}>АКТ ВЫПОЛНЕННЫХ РАБОТ (ОКАЗАННЫХ
                                                    УСЛУГ)</Text>
                                            </View>
                                            <View style={styles.contractTablet}>
                                                <View style={styles.contractTabletTop}>
                                                    <View style={styles.contractTabletColumn}>
                                                        <Text style={styles.contractTabletColumnText}>Номер
                                                            документа</Text>
                                                    </View>
                                                    <View style={styles.contractTabletColumnRight}>
                                                        <Text style={styles.contractTabletColumnText}>Дата
                                                            составления</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.contractTabletBottom}>
                                                    <View style={styles.contractTabletColumn}>
                                                        <Text style={styles.contractTabletColumnText}>{completionActOne?.number}</Text>
                                                    </View>
                                                    <View style={styles.contractTabletColumnRight}>
                                                        <Text style={styles.contractTabletColumnText}>{moment(completionActOne?.createdAt).format('L')}</Text>
                                                    </View>
                                                </View>
                                            </View>
                                        </View>
                                        <View style={styles.table}>
                                            <View style={styles.tableHeader}>
                                                <View style={styles.tableHeaderColumn1}>
                                                    <Text>Номер
                                                        по
                                                        порядку</Text>
                                                </View>
                                                <View style={styles.tableHeaderColumn2}>
                                                    <Text>Наименование работ (услуг) (в разрезе их
                                                        подвидов в соответствии с технической
                                                        спецификацией, заданием, графиком выполнения
                                                        работ (услуг) при их наличии)</Text>
                                                </View>
                                                <View style={styles.tableHeaderColumn3}>
                                                    <Text>Дата выполнения
                                                        работ (оказания
                                                        услуг)
                                                    </Text>
                                                </View>
                                                <View style={styles.tableHeaderColumn4}>
                                                    <Text>Сведения об отчете о научных
                                                        исследованиях, маркетинговых,
                                                        консультационных и прочих услугах
                                                        (дата, номер, количество страниц)
                                                        (при их наличии)
                                                    </Text>
                                                </View>
                                                <View style={styles.tableHeaderColumn5}>
                                                    <Text>Единица
                                                        измерения</Text>
                                                </View>
                                                <View style={styles.tableHeaderColumn6}>
                                                    <View style={styles.tableHeaderColumnLast}>
                                                        <View style={styles.tableHeaderColumnLastTop}>
                                                            <Text>Выполнено работ (оказано услуг)</Text>
                                                        </View>
                                                        <View style={styles.tableHeaderColumnLastBottom}>
                                                            <View style={styles.tableHeaderMiniColumn}>
                                                                <Text>количество</Text>
                                                            </View>
                                                            <View style={styles.tableHeaderMiniColumn}>
                                                                <Text>количество</Text>
                                                            </View>
                                                            <View style={styles.tableHeaderMiniColumn}>
                                                                <Text>количество</Text>
                                                            </View>
                                                        </View>
                                                    </View>
                                                </View>
                                            </View>
                                            {specifications?.length && specifications.map((el, idx) => (
                                            <View key={el.id} style={styles.tableHeader}>
                                                <View style={styles.tableBodyColumn1}>
                                                    <Text>{idx + 1}</Text>
                                                </View>
                                                <View style={styles.tableBodyColumn2}>
                                                    <Text>{el?.service?.name}</Text>
                                                </View>
                                                <View style={styles.tableBodyColumn3}>
                                                    <Text>3</Text>
                                                </View>
                                                <View style={styles.tableBodyColumn4}>
                                                    <Text>4</Text>
                                                </View>
                                                <View style={styles.tableBodyColumn5}>
                                                    <Text>{el?.unit?.name}</Text>
                                                </View>
                                                <View style={styles.tableBodyColumn6}>
                                                    <View style={styles.tableBodyColumnLast}>
                                                        <View style={styles.tableBodyMiniColumn}>
                                                            <Text>6</Text>
                                                        </View>
                                                        <View style={styles.tableBodyMiniColumn}>
                                                            <Text>7</Text>
                                                        </View>
                                                        <View style={styles.tableBodyMiniColumn}>
                                                            <Text>8</Text>
                                                        </View>
                                                    </View>
                                                </View>
                                            </View>
                                            ))}
                                            <View style={styles.tableHeaderLast}>
                                                <View style={styles.tableLastColumn1}>
                                                </View>
                                                <View style={styles.tableLastColumn2}>
                                                </View>
                                                <View style={styles.tableLastColumn3}>
                                                </View>
                                                <View style={styles.tableLastColumn4}>
                                                </View>
                                                <View style={styles.tableLastColumn5}>
                                                    <Text>Итого:</Text>
                                                </View>
                                                <View style={styles.tableLastColumn6}>
                                                    <View style={styles.tableBodyColumnLast}>
                                                        <View style={styles.tableBodyMiniColumn}>
                                                            <Text>6</Text>
                                                        </View>
                                                        <View style={styles.tableBodyMiniColumn}>
                                                            <Text>7</Text>
                                                        </View>
                                                        <View style={styles.tableBodyMiniColumn}>
                                                            <Text>8</Text>
                                                        </View>
                                                    </View>
                                                </View>
                                            </View>
                                        </View>
                                        <View style={styles.form3}>
                                            <Text>Сведения об использовании запасов, полученных от заказчика</Text>
                                            <View style={styles.formGroup3}>
                                                <View style={styles.input3}>
                                                    <Text>{customer}</Text>
                                                </View>
                                                <Text style={styles.textInput}>наименование, количество,
                                                    стоимость</Text>
                                            </View>
                                        </View>
                                        <View>
                                            <Text style={styles.text3}>Приложение: Перечень документации, в том числе
                                                отчет(ы) о маркетинговых, научных исследованиях, консультационных и
                                                прочих услугах (обязательны при его</Text>
                                            <View style={styles.formGroup4}>
                                                <Text style={styles.text4}>(их) наличии) на</Text>
                                                <View style={styles.input4}>
                                                    <Text>1</Text>
                                                </View>
                                                <Text style={styles.text4}> страниц</Text>
                                                <View style={styles.input5}>
                                                    <Text>1</Text>
                                                </View>
                                            </View>
                                        </View>
                                        <View style={styles.bottom}>
                                            <View style={styles.bottomLeft}>
                                                <View style={styles.bottomLeftRow}>
                                                    <Text>Сдал (Исполнитель)</Text>
                                                    <View style={styles.formGroup6}>
                                                        <View style={styles.input6}>
                                                            <Text></Text>
                                                        </View>
                                                        <Text style={styles.textInput}>должность</Text>
                                                    </View>
                                                    <Text style={styles.bottomLeftChar}>/</Text>
                                                    <View style={styles.formGroup7}>
                                                        <View style={styles.input6}>
                                                            <Text></Text>
                                                        </View>
                                                        <Text style={styles.textInput}>подпись</Text>
                                                    </View>
                                                    <Text style={styles.bottomLeftChar}>/</Text>
                                                    <View style={styles.formGroup7}>
                                                        <View style={styles.input7}>
                                                            <Text></Text>
                                                        </View>
                                                        <Text style={styles.textInput}>расшифровка подписи</Text>
                                                    </View>
                                                </View>
                                                <Text style={styles.signature}>
                                                    М.П.
                                                </Text>
                                            </View>
                                            <View style={styles.bottomLeft}>
                                                <View style={styles.bottomLeftRow}>
                                                    <Text>Принял (Заказчик)</Text>
                                                    <View style={styles.formGroup6}>
                                                        <View style={styles.input6}>
                                                            <Text></Text>
                                                        </View>
                                                        <Text style={styles.textInput}>должность</Text>
                                                    </View>
                                                    <Text style={styles.bottomLeftChar}>/</Text>
                                                    <View style={styles.formGroup7}>
                                                        <View style={styles.input6}>
                                                            <Text></Text>
                                                        </View>
                                                        <Text style={styles.textInput}>подпись</Text>
                                                    </View>
                                                    <Text style={styles.bottomLeftChar}>/</Text>
                                                    <View style={styles.formGroup7}>
                                                        <View style={styles.input7}>
                                                            <Text></Text>
                                                        </View>
                                                        <Text style={styles.textInput}>расшифровка подписи</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.formGroup8}>
                                                    <Text>Дата подписания (принятия) работ (услуг)</Text>
                                                    <View style={styles.input8}>
                                                        <Text>{moment(completionActOne?.signedDateTime).format('L')}</Text>
                                                    </View>
                                                </View>
                                                <Text style={styles.signature2}>М.П.</Text>
                                            </View>
                                        </View>
                                    </View>
                                </Page>
                            </Document>
                        </PDFViewer>}
                    </Box>
                </Modal>
            </div>
        );
}

export default PopupExcel;