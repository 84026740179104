import {
    GET_EDUCATION_SERVICE_ERROR,
    GET_EDUCATION_SERVICE_KIND_ERROR,
    GET_EDUCATION_SERVICE_KIND_REQUEST,
    GET_EDUCATION_SERVICE_KIND_SUCCESS, GET_EDUCATION_SERVICE_REQUEST, GET_EDUCATION_SERVICE_SUCCESS
} from "../../constants/educationService/educationServiceConstants";

const initialState = {
    educationServiceKind: [],
    educationService: [],
    loading: false,
    success: null,
    error: null
};

export const educationReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_EDUCATION_SERVICE_KIND_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_EDUCATION_SERVICE_KIND_SUCCESS:
            return {
                ...state,
                loading: false,
                educationServiceKind: action.payload.result,
                success: true
            };
        case GET_EDUCATION_SERVICE_KIND_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case GET_EDUCATION_SERVICE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_EDUCATION_SERVICE_SUCCESS:
            return {
                ...state,
                loading: false,
                educationService: action.payload.result,
                success: true
            };
        case GET_EDUCATION_SERVICE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        default:
            return state;
    }
}