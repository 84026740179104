import {$authHost} from "../../../http";
import {
    TYPE_CLIENT_ADD_ERROR,
    TYPE_CLIENT_ADD_REQUEST,
    TYPE_CLIENT_ADD_SUCCESS,
    TYPE_CLIENT_DELETE_ERROR,
    TYPE_CLIENT_DELETE_REQUEST,
    TYPE_CLIENT_DELETE_SUCCESS, TYPE_CLIENT_EDIT_ERROR, TYPE_CLIENT_EDIT_REQUEST, TYPE_CLIENT_EDIT_SUCCESS,
    TYPE_CLIENT_GET_ERROR,
    TYPE_CLIENT_GET_REQUEST,
    TYPE_CLIENT_GET_SUCCESS
} from "../../constants/client/typeClientConstants";
import {getData} from "../template/get";
import {editData} from "../template/edit";

export const addTypeClient = (code, description, name) => async (dispatch) => {
    try {
        dispatch({
            type: TYPE_CLIENT_ADD_REQUEST,
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.post(
            "/api/v1/legalPersonType",
            {code, description, name},
            config
        );
        dispatch(getTypeClient())
        dispatch({
            type: TYPE_CLIENT_ADD_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: TYPE_CLIENT_ADD_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const editTypeClient = (id, code, description, name) => async (dispatch) => {
    return await dispatch(editData(TYPE_CLIENT_EDIT_REQUEST, TYPE_CLIENT_EDIT_SUCCESS, TYPE_CLIENT_EDIT_ERROR, 'legalPersonType', getTypeClient(), {id, code, description, name}))
};

export const deleteTypeClient = (id) => async (dispatch) => {
    try {
        dispatch({
            type: TYPE_CLIENT_DELETE_REQUEST,
        });

        await $authHost.delete(
            "/api/v1/legalPersonType",
            {
                data: {
                    id
                }
            },
        );
        dispatch(getTypeClient())
        dispatch({
            type: TYPE_CLIENT_DELETE_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: TYPE_CLIENT_DELETE_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const getTypeClient = () => async (dispatch) => {
    return await dispatch(getData(TYPE_CLIENT_GET_REQUEST, TYPE_CLIENT_GET_SUCCESS, TYPE_CLIENT_GET_ERROR, 'legalPersonType'))
};

export const getOneTypeClient = async (id) => {
    try {
        const {data} = await $authHost.get(
            `api/v1/legalPersonType/${id}`,
        );
        return data
    } catch (error) {
        console.log(error)
    }
};