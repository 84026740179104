export const AN_OBJECT_GET_REQUEST = 'AN_OBJECT_GET_REQUEST'
export const AN_OBJECT_GET_SUCCESS = 'AN_OBJECT_GET_SUCCESS'
export const AN_OBJECT_GET_ERROR = 'AN_OBJECT_GET_ERROR'

export const AN_OBJECT_KIND_GET_REQUEST = 'AN_OBJECT_KIND_GET_REQUEST'
export const AN_OBJECT_KIND_GET_SUCCESS = 'AN_OBJECT_KIND_GET_SUCCESS'
export const AN_OBJECT_KIND_GET_ERROR = 'AN_OBJECT_KIND_GET_ERROR'

export const TRANSPORT_TYPE_GET_REQUEST = 'TRANSPORT_TYPE_GET_REQUEST'
export const TRANSPORT_TYPE_GET_SUCCESS = 'TRANSPORT_TYPE_GET_SUCCESS'
export const TRANSPORT_TYPE_GET_ERROR = 'TRANSPORT_TYPE_GET_ERROR'