import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {Grid} from "@mui/material";
import {useEffect, useState} from "react";
import {Number, SelectShared} from "../../index";
import {style} from "../popup";

export default function PopupContractSpecificationLocal({
                                                            specification,
                                                            Id,
                                                            setSpecification,
                                                            open,
                                                            setOpen,
                                                            serviceKind,
                                                            services,
                                                            unityOfMeasure,
                                                            servicesPrice,
                                                            localServiceKindState,
                                                            serviceState,
                                                            unitOfMeasureState,
                                                            servicePriceState,
                                                            quantity
                                                        }) {
    const [qty, setQty] = useState()
    const [unitOfMeasure, setUnitOfMeasure] = useState()
    const [service, setService] = useState()
    const [localServiceKind, setLocalServiceKind] = useState()
    const [servicePrice, setServicePrice] = useState()
    const emptyState = () => {
        // setQty(0)
        // setPrice(0)
        // setUnitOfMeasure(1)
        // setService(1)
        // setLocalServiceKind(1)
        // setServicePrice(1)
    }
    const handleForm = async (e) => {
        e.preventDefault()
        const newSpecification = [...specification];
        const old = specification.find(el => el.Id === Id)
        const index = specification.findIndex(el => el.Id === Id)
        const localServiceKindIdx = serviceKind.findIndex(el => el.id === localServiceKind)
        const serviceIdx = services.findIndex(el => el.id === service)
        const unitOfMeasureIdx = unityOfMeasure.findIndex(el => el.id === unitOfMeasure)
        const servicePriceIdx = servicesPrice.findIndex(el => el.id === servicePrice)
        newSpecification[index] = {
            Id: old.Id,
            serviceKind: serviceKind[localServiceKindIdx].name,
            serviceKindId: localServiceKind,
            service: services[serviceIdx].name,
            serviceId: service,
            unitId: unitOfMeasure,
            unit: unityOfMeasure[unitOfMeasureIdx].name,
            servicePriceListId: servicePrice,
            servicePriceList: servicesPrice[servicePriceIdx].name,
            quantity: +qty,
            price: servicesPrice[servicePriceIdx].price,
        }
        setSpecification(newSpecification)

        setOpen(false)
        emptyState()
    }
    const handleClose = async () => {
        emptyState()
        setOpen(false)
    }

    useEffect(() => {
        setQty(quantity)
        setUnitOfMeasure(unitOfMeasureState)
        setService(serviceState)
        setLocalServiceKind(localServiceKindState)
        setServicePrice(servicePriceState)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Редактирование специфики
                    </Typography>
                    <Box component="form" onSubmit={handleForm} sx={{mt: 1, display: 'flex', flexWrap: 'wrap'}}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Number value={qty} setValue={setQty} label="Количество"/>
                            </Grid>
                            <Grid item xs={6}>
                                <SelectShared value={localServiceKind} setValue={setLocalServiceKind} label="Вид услуги" array={serviceKind}/>
                            </Grid>
                            <Grid item xs={6}>
                                <SelectShared value={service} setValue={setService} label="Услуга" array={services}/>
                            </Grid>
                            <Grid item xs={6}>
                                <SelectShared value={unitOfMeasure} setValue={setUnitOfMeasure} label="Ед. измерения" array={unityOfMeasure}/>
                            </Grid>
                            <Grid item xs={6}>
                                <SelectShared value={servicePrice} setValue={setServicePrice} label="Прейскурант (тариф)" array={servicesPrice}/>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{mt: 3, mb: 2}}
                                >
                                    Изменить
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
