import React, {useContext} from 'react';
import {useTranslation} from "react-i18next";
import {DeleteForeverIconCustom} from "../../components";
import {Box, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import ConfirmDialog from "../Popups/message/PopupDelete";
import ResearchScheduleRow from "./ResearchScheduleRow";
import {ContractContext} from "../../screens/Contract/ContractPage";
import {useLocation} from "react-router-dom";
import {ContractRequestAddContext} from "../../screens/ContractRequest/ContractRequestAdd";

function ResearchSchedule({edit, mode}) {
    const {pathname} = useLocation()
    const {setSchedules, schedules} = useContext(pathname.includes('/contract-request') ? ContractRequestAddContext : ContractContext)
    const {t} = useTranslation();
    const rowName = [
        {text: t("contract.tableItemSchedule.targetMonth")},
        {text: t("contract.tableItemSchedule.targetYear")},
        {text: t("contractRequest.tableItem.actions")},
    ]
    const handleIcons = async (type, row) => {
        if (type === 'DeleteForeverIcon') {
            const newSpec = schedules.filter(el => el.Id !== row)
            await setSchedules(newSpec);
        }
    }
    const actions = [{icon: <DeleteForeverIconCustom/>, id: 'DeleteForeverIcon'}]
    return (
            <Table sx={{minWidth: '80%'}}>
                <TableHead>
                    <TableRow>
                        {(mode === 'edit' || !mode) ? rowName.map((el, idx) => {
                            return <TableCell key={idx}
                                              align="center">{el.text}</TableCell>
                        }) : rowName.slice(0, -1).map((el, idx) => {
                            return <TableCell key={idx}
                                              align="center">{el.text}</TableCell>
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {schedules.length ? schedules.map((row) => (
                            <ResearchScheduleRow mode={mode} edit={edit}  handleIcons={handleIcons} actions={actions} key={row.Id} row={row}/>)) :
                        <TableRow><TableCell align="center" colSpan={8}><Box>Записей пока
                            нет</Box></TableCell></TableRow>}
                </TableBody>
                <ConfirmDialog/>
            </Table>
    );
}

export default React.memo(ResearchSchedule);