import React from 'react';

import {serviceScheduleCRUD} from "../../../http/CRUD";
import CRUDTableQuery from "../CRUDTableQuery";
import useReload from "../../../hooks/useReload";
import CRUDTableStandart from "../CRUDTableStandart";

export default function ServiceScheduleCRUDTable({type = 'standart', ...props}) {
    const { reload, reloadValue } = useReload();
    const columns = [
        {title: "ID", dataIndex: 'id', key: 'id'},
        {title: "Наименование", dataIndex: 'name', key: 'name'},
    ];
    const searchFields = [
        {
            title: "Наименование",
            key: "name",
            compareType: "like",
            operandPosition: 1,
            filterType: "normal"
        }
    ]

    const CRUDTables = {
        standart: CRUDTableStandart,
        query: CRUDTableQuery,
    }

    const CRUDTable = CRUDTables[type]

    return (
        <>
            <CRUDTable search={serviceScheduleCRUD.search}
                            columns={columns}
                            searchFields={searchFields}
                            reloadValue={reloadValue}
                            {...props}
            />
        </>
    );
}
