import React from 'react';
import {Paper, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {ToggleButton, ToggleButtonGroup} from "@mui/lab";

function StatusView({alignment, handleChange, status}) {
    return (
        <Box component={Paper} sx={{marginTop: 5, marginBottom: 5, display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '15px 10px 15px 10px'}}>
            <Typography variant="h5" component="div">{alignment === 'edit' ? 'Редактирование' : 'Просмотр'} сводной заявки</Typography>
            <ToggleButtonGroup
                color="primary"
                value={alignment}
                exclusive
                onChange={handleChange}
            >
                <ToggleButton disabled={status === 2 || status === 3 || status === 4} value="edit">Редактирование</ToggleButton>
                <ToggleButton value="see">Просмотр</ToggleButton>
            </ToggleButtonGroup>
        </Box>
    );
}

export default StatusView;