import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextShared from "../../components/Shared/TextShared";
import NumberFormatShared from "../../components/Shared/NumberFormatShared";
import {DatePickerShared, SelectShared} from "../../components";
import {useDispatch, useSelector} from "react-redux";
import {getGenderAll} from "../../redux/actions/genericActions";
import {getGeoObject} from "../../redux/actions/geo/geoActions";
import {LoadingButton} from "@mui/lab";
import {showSuccessSnackbar} from "../../redux/actions/userActions";
import {updateProfile} from "../../redux/actions/profile/profileActions";

function PersonalInformation({person}) {
    const dispatch = useDispatch();
    const {profile} = useSelector((state) => state.profile);
    const [lastName, setLastName] = useState('')
    const [firstName, setFirstName] = useState('')
    const [birthDay, setBirthDay] = useState(new Date())
    const [middleName, setMiddleName] = useState('')
    const [identityNumber, setIdentityNumber] = useState({formattedValue: '',
        value: ''})
    const [number, setNumber] = useState({formattedValue: '',
        value: ''})
    const [email, setEmail] = useState('')
    const [gender, setGender] = useState(1)
    const {genderAll} = useSelector((state) => state.generic);
    const [loadingEdit, setLoadingEdit] = useState(false)
    useEffect(() => {
        dispatch(getGenderAll())
    }, [dispatch])

    useEffect(() => {
        if (person) {
            setFirstName(person?.firstName)
            setLastName(person?.lastName)
            setMiddleName(person?.secondName)
            setIdentityNumber({formattedValue: '',
                value: person?.identityNumber})
            setAddressLine(person?.addressLine)
            setBirthDay(person?.birthDay)
            setNumber({formattedValue: '',
                value: person?.phoneNumber})
            setEmail(person?.email)
            setGender(person?.genderId)
        }
    }, [person])

    const [addressLine, setAddressLine] = useState([])

    const handleSubmit = (event) => {
        event.preventDefault()
        setLoadingEdit(true)
        const persons = {
            id: person?.id,
            firstName,
            lastName,
            secondName: middleName,
            identityNumber: identityNumber.value,
            addressLine,
            phoneNumber: number.value,
            email,
            birthDay,
            genderId: gender
        }
        dispatch(updateProfile(profile?.client?.id, profile?.client?.legalPersonId, profile?.client?.personId, profile?.client?.userId, persons))
        setLoadingEdit(false)
        dispatch(showSuccessSnackbar("Профиль обновлён!", "success"));
    }
    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextShared value={lastName} setValue={setLastName} required label="Фамилия"/>
                </Grid>
                <Grid item xs={6}>
                    <TextShared value={firstName} setValue={setFirstName} required label="Имя"/>
                </Grid>
                <Grid item xs={6}>
                    <TextShared value={middleName} setValue={setMiddleName} required label="Отчество"/>
                </Grid>
                <Grid item xs={6}>
                    <NumberFormatShared format="############" mask="_" placeholder="Инн" value={identityNumber} setValues={setIdentityNumber}/>
                </Grid>
                <Grid item xs={6}>
                    <TextShared value={addressLine} setValue={setAddressLine} required label="Адрес"/>
                </Grid>
                <Grid item xs={6}>
                    <DatePickerShared setValue={setBirthDay} value={birthDay} maxValue={birthDay} label="День рождения"/>
                </Grid>
                <Grid item xs={6}>
                    <NumberFormatShared format="+7 (###) ###-##-##" mask="_" placeholder="+7 (999) 333-22-11" value={number} setValues={setNumber}/>
                </Grid>
                <Grid item xs={6}>
                    <TextShared value={email} setValue={setEmail} required label="Email" type="email"/>
                </Grid>
                <Grid item xs={6}>
                    <SelectShared array={genderAll} required label="Пол" value={gender} setValue={setGender}/>
                </Grid>
            </Grid>
            <LoadingButton
                loading={loadingEdit}
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
            >
                Сохранить
            </LoadingButton>
        </Box>

    );
}

export default PersonalInformation;