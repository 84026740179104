import * as React from 'react';
import TextField from '@mui/material/TextField';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import {DatePicker, LocalizationProvider} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {Stack} from "@mui/material";


const FilterDatePicker = ({handleFunction, value, label})=>{
    return (

        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                label={label}
                inputFormat="dd/MM/yyyy"
                value={value}
                onChange={handleFunction}
                renderInput={(params) => <TextField margin={"none"} {...params} />}
            />
        </LocalizationProvider>

    );
}

export default FilterDatePicker
