import React from 'react';
import Dashboard from "../../layouts/Dashboard";
import {
    FILIAL_PACKAGE_ADD_ROUTE,
    FILIAL_PACKAGE_EDIT_ROUTE,
    FILIAL_PACKAGE_ROUTE
} from "../../routes/const";
import BreadcrumbsShared from "../../components/Shared/BreadcrumbsShared";
import FilialPackageCRUDTable from "../../components/Crud/Tables/FilialPackageCRUDTable";
import {useHistory} from "react-router-dom";
import {confirmDialog} from "../../components/Popups/message/PopupDelete";
import {filialPackageCRUD} from "../../http/CRUD";
import useReload from "../../hooks/useReload";

function FilialPackage(props) {
    const history = useHistory()
    const {reload, reloadValue} = useReload();

    async function deleteHandler(id) {
        return confirmDialog('Удаление элемента', 'Вы действительно хотите удалить этот элемент?', async () => {
            try {
                await filialPackageCRUD.delete(id)
                await reload();
            } catch (e) {
                console.log(e);
            }
        })
    }

    return (
        <Dashboard>
            <BreadcrumbsShared breadcrumbs={[
                {text: 'Пакетные услуги (филиал)', link: FILIAL_PACKAGE_ROUTE}
            ]}/>
            <FilialPackageCRUDTable reloadValue={reloadValue} onClickDeleteButton={deleteHandler} onClickDetailsButton={(id) => history.push(FILIAL_PACKAGE_EDIT_ROUTE + `/${id}`)} onClickCreateButton={() => history.push(FILIAL_PACKAGE_ADD_ROUTE)}/>
        </Dashboard>
    );
}

export default FilialPackage;