import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {Dialog, DialogContent, Divider, Grid} from "@mui/material";
import {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getGenderAll} from "../../redux/actions/genericActions";
import {DatePickerShared, Number, PhoneShared, SelectShared} from "../index";
import TextShared from "../Shared/TextShared";
import DialogTitle from "@mui/material/DialogTitle";
import {legalPersonCRUD, personCRUD} from "../../http/CRUD";
import {addClient} from "../../redux/actions/client/clientActions";

export default function PopupPerson({open, setOpen, confirmHandler, edit, id, setId}) {

    const [data, setData] = useState({});

    const handleChange = useCallback((key, value) => {
        setData({...data, [key]: value});
    }, [data]);

    const [birthDay, setBirthDay] = useState(new Date())
    const [maxBirthDay] = useState(birthDay)
    const [gender, setGender] = useState(10)
    const dispatch = useDispatch()
    const {genderAll} = useSelector((state) => state.generic);

    const emptyState = () => {
        id && setId(null)
    }
    const handleForm = async (e) => {
        e.preventDefault()
        if (edit && id) {
            await personCRUD.edit({
                id,
                ...data,
                fullName: `${data?.firstName} ${data?.lastName} ${data?.secondName}`,
            })
        }  else {
            const {id} = await personCRUD.create({
                ...data,
                fullName: `${data?.firstName} ${data?.lastName} ${data?.secondName}`,
            })
            await addClient(id, null, null)
        }
        confirmHandler ? confirmHandler() : setOpen(false)
        emptyState()
    }
    const handleClose = async () => {
        setOpen(false)
        emptyState()
    }
    useEffect(() => {
        if (id && open && edit) {
            const getData = async () => {
                const data = await personCRUD.get(id)
                setData({...data})
            }
            getData()
        }
    }, [edit, id, open])

    useEffect(() => {
        let res
        const getGenderLocal = async () => {
            res = await dispatch(getGenderAll())
            if (res?.length && !edit) {
                setGender(res[0].id)
            }
        }
        getGenderLocal()
    }, [dispatch, edit])

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            PaperProps={{sx: {maxHeight: '85%'}}}
            maxWidth={"80%"}
        >
                    <DialogTitle>Физ.лица ({!edit ? 'Создание' : 'Редактирование'})</DialogTitle>
                    <Divider />
                    <DialogContent>
                        <Box component="form" onSubmit={handleForm} sx={{mt: 1, display: 'flex', flexWrap: 'wrap'}}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextShared value={data?.firstName} setValue={val => handleChange("firstName", val)} label="Имя"/>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextShared value={data?.lastName} setValue={val => handleChange("lastName", val)} label="Фамилия"/>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextShared value={data?.secondName} setValue={val => handleChange("secondName", val)} label="Отчество"/>
                                </Grid>
                                <Grid item xs={6}>
                                    <Number required={false} max="9999999999999" value={data?.bin}
                                            setValue={val => handleChange("bin", val)} label="БИН"/>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextShared value={data?.identityNumber} setValue={val => handleChange("identityNumber", val)} label="ИНН"/>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextShared value={data?.addressLine} setValue={val => handleChange("addressLine", val)} label="Адрес"/>
                                </Grid>
                                <Grid item xs={6}>
                                    <PhoneShared value={data?.phoneNumber} setValue={val => handleChange("phoneNumber", val)}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextShared value={data?.email} setValue={val => handleChange("email", val)} type="email" label="Почта"/>
                                </Grid>
                                <Grid item xs={6}>
                                    <DatePickerShared setValue={val => handleChange("birthDay", val)} value={data?.birthDay} label="Дата рождения" maxValue={maxBirthDay}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectShared value={data?.gender} setValue={val => handleChange("gender", val)} array={genderAll} label="Пол"/>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{mt: 3, mb: 2}}
                                    >
                                        {edit ? 'Редактирование' : 'Добавить'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>
        </Dialog>
    );
}
