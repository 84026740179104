import React from 'react';
import Dashboard from "../../layouts/Dashboard";
import {TableCell, TableRow} from "@mui/material";
import TableCustomVariantTwo from "../../components/TableCustom/TableCustomVariantTwo";
import {EFF_ROUTE} from "../../routes/const";
import BreadcrumbsShared from "../../components/Shared/BreadcrumbsShared";

function Effect(props) {
    return (
        <Dashboard>
            <BreadcrumbsShared breadcrumbs={[
                {text: 'Эффективность филиалов/отделений', link: EFF_ROUTE}
            ]}/>
            <TableCustomVariantTwo name="Эффективность филиалов/отделений" header={<React.Fragment>
                <TableRow>
                    <TableCell align="center" colSpan={1}>
                        №
                    </TableCell>
                    <TableCell align="center" colSpan={1}>
                        Филиал/Отделение
                    </TableCell>
                    <TableRow>
                        <TableRow>
                            <TableCell align="center" colSpan={4}>
                                Рынок
                            </TableCell>
                            <TableCell align="center" colSpan={1}>
                                Охват рынка
                            </TableCell>
                            <TableCell align="center" colSpan={11}>
                                Эффективность
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center">
                                Население
                            </TableCell>
                            <TableCell align="center">
                                Количество субъектов
                                (юр.лица и ИП)
                            </TableCell>
                            <TableCell align="center">
                                Количество объектов
                            </TableCell>
                            <TableCell align="center">
                                Объем рынка в тенге
                            </TableCell>
                            <TableRow>
                                <TableCell rowSpan={1} colSpan={2} align="center">
                                    Субъекты
                                    (юр.лица и ИП)
                                </TableCell>
                                <TableCell rowSpan={1}  colSpan={2} align="center">
                                    Объекты
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center">
                                    Количество
                                </TableCell>
                                <TableCell align="center">
                                    %
                                </TableCell>
                                <TableCell align="center">
                                    Количество
                                </TableCell>
                                <TableCell align="center">
                                    %
                                </TableCell>
                            </TableRow>
                            <TableCell align="center" colSpan={1}>
                                Общий штат
                            </TableCell>
                            <TableCell align="center" colSpan={1}>
                                Количество договоров
                            </TableCell>
                            <TableCell align="center" colSpan={1}>
                                Сумма договоров
                            </TableCell>
                            <TableRow>
                                <TableCell rowSpan={1} colSpan={3} align="center">
                                    Поступление денежных средств
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center">
                                    по договорам
                                </TableCell>
                                <TableCell align="center">
                                    от населения
                                </TableCell>
                                <TableCell align="center">
                                    ВСЕГО
                                </TableCell>
                            </TableRow>
                            <TableCell align="center" colSpan={1}>
                                Количество оказанных услуг (исследований/м2)
                            </TableCell>
                            <TableRow>
                                <TableCell rowSpan={1} colSpan={4} align="center">
                                    Средняя эффективность 1 сотрудника
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center">
                                    договор
                                </TableCell>
                                <TableCell align="center">
                                    тенге
                                </TableCell>
                                <TableCell align="center">
                                    объект
                                </TableCell>
                                <TableCell align="center">
                                    услуга (исследований/м2)
                                </TableCell>
                            </TableRow>
                        </TableRow>
                    </TableRow>
                </TableRow>
            </React.Fragment>}/>
        </Dashboard>
    );
}

export default Effect;