import React, {useEffect, useState} from 'react';
import DashboardLayout from "../../layouts/Dashboard";
import {useTranslation} from "react-i18next";
import {DeleteForeverIconCustom, EditIconCustom, PopupTypeClient, TableCustom} from "../../components";
import Box from "@mui/material/Box";
import {Button} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {getTypeClient} from "../../redux/actions/client/typeClientActions";

const Client = () => {
    const {t} = useTranslation();
    const rowName = [
        {text: 'id'},
        {text: t("typeClient.tableItem.name")},
        {text: t("typeClient.tableItem.code")},
        {text: t("typeClient.tableItem.description")},
        {text: t("typeClient.tableItem.actions")},
    ]
    const [open, setOpen] = useState(false);
    const [edit, setEdit] = useState(false);
    const [id, setId] = useState(null);
    const dispatch = useDispatch()
    const {typeClient} = useSelector((state) => state.typeClient);
    const setEditHandler = async (idEdit) => {
        setOpen(true)
        setEdit(true)
        setId(idEdit)
    }
    useEffect(() => {
        dispatch(getTypeClient())
    }, [dispatch])
    return (
        <DashboardLayout>
            <Box sx={{margin: '20px 0', display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                <Button onClick={() => setOpen(true)} color="primary"
                        variant="contained">{t("typeClient.buttonText")}</Button>
            </Box>
            <TableCustom name="Типы клиентов" avatars={false} rowName={rowName} rows={typeClient} setEdit={setEditHandler}
                         actions={[{icon: <EditIconCustom/>, id: 'EditIcon'}, {
                             icon: <DeleteForeverIconCustom/>,
                             id: 'DeleteForeverIcon'
                         }]}/>
            <PopupTypeClient id={id} edit={edit} setEdit={setEdit} setOpen={setOpen} open={open} title="Типы клиентов"/>
        </DashboardLayout>
    );
};

export default Client;