import React, {useState} from 'react';
import Dashboard from "../../layouts/Dashboard";
import {DEAL_ROUTE} from "../../routes/const";
import BreadcrumbsShared from "../../components/Shared/BreadcrumbsShared";
import DealCRUDTable from "../../components/Crud/Tables/DealCRUDTable";
import useReload from "../../hooks/useReload";

function Deal(props) {

    const { reload, reloadValue } = useReload();

    const [view] = useState('table');


    return (
        <Dashboard>
            <BreadcrumbsShared breadcrumbs={[
                {text: 'Сделки', link: DEAL_ROUTE}
            ]}/>
            {view === 'table' && <DealCRUDTable reload={reload} reloadValue={reloadValue}/>}
        </Dashboard>
    );
}

export default Deal;
