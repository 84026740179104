import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import Dashboard from "../../layouts/Dashboard";
import {CONTRACT_EDIT_ROUTE, DEAL_ADD_ROUTE, DEAL_ROUTE} from "../../routes/const";
import BreadcrumbsShared from "../../components/Shared/BreadcrumbsShared";
import {Button, Paper} from "@mui/material";
import Box from "@mui/material/Box";
import {useHistory, useParams} from "react-router-dom";
import {
    changeStateDeal,
    getDeal,
    getDealStage,
} from "../../redux/actions/deal/dealActions";
import {useDispatch, useSelector} from "react-redux";
import {
    Loader, Number,
    PopupLegalClient,
    PopupPerson,
} from "../../components";
import {LoadingButton} from "@mui/lab";
import {getServiceCategory} from "../../redux/actions/serviceCategory/serviceCategoryActions";
import {showSuccessSnackbar} from "../../redux/actions/userActions";
import {dealCommercialCRUD, dealCRUD} from "../../http/CRUD";
import {getFilial, getFilialDivision} from "../../redux/actions/filial/filialActions";
import {$authHost, $s3host} from "../../http";
import {DealStateChecker} from "../../functions/state";
import DealNew from "../../components/Deal/Stage/DealNew";
import {getGeoObject} from "../../redux/actions/geo/geoActions";
import {getRandomInt, removeEmpty} from "../../functions";
import DealClarificationofneed from "../../components/Deal/Tables/DealClarificationofneed";
import Typography from "@mui/material/Typography";
import TextShared from "../../components/Shared/TextShared";
import {confirmDialog} from "../../components/Popups/message/PopupDelete";
import {formatDate, MOMENT_FORMAT_DATE_DMY_DOTS} from "../../functions/formatters";
import {addContract} from "../../redux/actions/contract/contractActions";
import {v4 as uuidv4} from "uuid";
import {initialValueLegalContract2} from "../Contract/Pages/templateLegal";
import moment from "moment";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@pred1995/crm-nce-ckeditor';

function DealDetails() {
    const [loadingAdd, setLoadingAdd] = useState(false)
    const [openPerson, setOpenPerson] = useState(false);
    const [openLegalClient, setOpenLegalClient] = useState(false);
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();
    const [generatedContract, setGeneratedContract] = useState(false)


    const history = useHistory()

    const [data, setData] = useState({})
    const [typeClient, setTypeClient] = useState(false)
    const {serviceCategory} = useSelector((state) => state.serviceCategory);
    const {dealState} = useSelector((state) => state.deal);
    const [client, setClient] = useState('')
    const [legalClient, setLegalClient] = useState('')
    const {filial, filialDivision} = useSelector((state) => state.filial);
    const [price, setPrice] = useState(0)
    const [district, setDistrict] = useState([])

    const handleChangeObject = useCallback(
        (key, value) => setData({...data, [key]: value}),
        [data]
    );

    const handleChangeObjectMulti = useCallback(
        (key, key1, value, value1) => setData({...data, [key]: value, [key1]: value1}),
        [data]
    );

    const {id} = useParams()

    useEffect(() => {
        setPrice(data?.specifications?.map(el => el?.service)?.reduce((acc, curr) => {
            return acc + (curr.price);
        }, 0))
    }, [data?.specifications]);


    useEffect(() => {
        (async function () {
            setLoading(true)
            let deal = await dealCRUD.get(id)
            const comments = [
                {...deal.comments[0], comment: deal.comments[0].comment || ' '},
                {...deal.comments[1], comment: deal.comments[1]?.comment || ' '},
                {...deal.comments[2], comment: deal.comments[2]?.comment || ' '},
                {...deal.comments[3], comment: deal.comments[3]?.comment || ' '},
                {...deal.comments[4], comment: deal.comments[4]?.comment || ' '},
                {...deal.comments[5], comment: deal.comments[5]?.comment || ' '},
            ]
            deal.comments = comments
            setData({
                ...deal, specifications: deal?.specifications?.map(el => {
                    return {
                        ...el, service: {
                            ...el.service,
                            price: el.service?.prices[0]?.price || 0
                        }
                    }
                })
            })
            setTypeClient(!!deal.contact?.personId)
            deal?.contact?.legalPersonId ? setLegalClient(deal?.contact?.legalPersonId) : setClient(deal?.contact?.personId)
            dispatch(getServiceCategory(1, 100))
            dispatch(getDealStage(1, 100))
            dispatch(getDeal(1, 100))
            await dispatch(getFilial(1, 100))
            await dispatch(getFilialDivision(1, 10000))
            setLoading(false)
        }())
    }, [dispatch, id]);

    const stateCode = data?.stage?.code;



    const state = new DealStateChecker(stateCode);

    useEffect(() => {
        (async function () {
            if (!state.isCreated()) {
                setDistrict(await dispatch(getGeoObject(1, 100, {
                    filter: {
                        typeId: {
                            operand1: 2,
                            operator: "equals"
                        },
                    }
                }, true)))
                await dispatch(getGeoObject(1, 100, {
                    filter: {
                        typeId: {
                            operand1: 3,
                            operator: "equals"
                        },
                    }
                }))
            }
        }())
    }, [dispatch, stateCode])

    const RenderDeal = useMemo(() => {
        return (
            <DealNew disabled={!state.isCreated()} filialDivision={filialDivision} data={data}
                     filial={filial} handleChangeObject={handleChangeObject} client={client}
                     legalClient={legalClient}
                     setLegalClient={setLegalClient} serviceCategory={serviceCategory}
                     setClient={setClient} setOpenLegalClient={setOpenLegalClient}
                     setOpenPerson={setOpenPerson}
                     setTypeClient={setTypeClient} typeClient={typeClient}/>
        );
    }, [client, data, filial, filialDivision, handleChangeObject, legalClient, serviceCategory, state, typeClient])


    const handleSubmit = async (event) => {
        event.preventDefault()
        setLoadingAdd(true)
        const {data: dataClient} = await $authHost.post(
            "/api/v1/client/query",
            {
                filter: {
                    [typeClient ? 'personId' : 'legalPersonId']: {
                        operand1: typeClient ? client : legalClient,
                        operator: "equals"
                    },
                }
            }
        )

        let commercialOfferId;
        if (state.isProposalPreparation()) {
            const res = await dealCommercialCRUD.create({
                ...data?.commercialOffer
            })
            commercialOfferId = res?.id
        }

        await dealCRUD.edit({
            ...data,
            contactId: dataClient?.result[0]?.id,
            ...(state.isProposalPreparation() && commercialOfferId && {commercialOfferId})
        })
        setLoadingAdd(false)
        dispatch(showSuccessSnackbar("Сделка изменена", "success"))
    }

    const handleChangeStage = async (oldStageId, stageId) => {
        await changeStateDeal(id, oldStageId, stageId)
        dispatch(showSuccessSnackbar("Статус сделки изменён!", "success"))
    }

    const handleContractObjectRequest = () => {
        const newElement = {
            Id: getRandomInt(1, 10000000),
            name: '',
            district: '',
            districtId: '',
            city: '',
            cityId: '',
            locality: '',
            localityId: '',
            addressLine: '',
            objectId: ''
        }
        handleChangeObject('objects', [...data.objects, newElement])
    }

    const handleLeads = (newElement) => {

        handleChangeObject('leads', [...data.leads, newElement])
    }


    const generateContract = () => {
        setGeneratedContract(!generatedContract)
    }

    const editorRef = useRef(null);
    const contractInit = Object.keys(data)?.length ? initialValueLegalContract2({
        supervisorName: data.contact?.legalPerson?.supervisorName,
        name: data?.name,
        specification: data?.specifications?.map(el => el?.service),
        date1: formatDate(data?.createdAt, MOMENT_FORMAT_DATE_DMY_DOTS),
        price,
        filial: (data?.filialDivision && Object.keys(data?.filialDivision).length) ? `${data?.filialDivision?.filial?.name} ${data?.filialDivision?.name}` : data?.filial?.name,
        filialDate: data?.filial,
        proxy: (data?.filialDivision && Object.keys(data?.filialDivision).length) ? {
            proxy: data?.filialDivision?.proxy,
            proxyDate: moment(data?.filialDivision?.proxyDate).format('LL'),
            proxyNumber: data?.filialDivision?.proxyNumber,

        } : {
            proxy: data?.filial?.proxy,
            proxyDate: moment(data?.filial?.proxyDate).format('LL'),
            proxyNumber: data?.filial?.proxyNumber,
        },
        client: data.contact?.legalPerson?.name || data.contact?.person?.fullName,
        inn: data.contact?.legalPerson?.identityNumber || data.contact?.person?.identityNumber,
        bin: data.contact?.person?.bin || data.contact?.legalPerson?.bin,
        phoneNumber: data.contact?.legalPerson?.phoneNumber || data.contact?.person?.phoneNumber,
        addressLine: data.contact?.legalPerson?.addressLine || data.contact?.person?.addressLine,
        supervisor: (data?.filialDivision && Object.keys(data?.filialDivision).length) ? data?.filialDivision?.supervisor?.fullName : data?.filial?.supervisor?.fullName,
        position: (data?.filialDivision && Object.keys(data?.filialDivision).length) ? data?.filialDivision?.supervisor?.position?.name : data?.filial?.supervisor?.position?.name,
        position2: (data?.filialDivision && Object.keys(data?.filialDivision).length) ? data?.filialDivision?.supervisor?.position?.name : data?.filial?.supervisor?.position?.name,
        bank: data.contact?.legalPerson?.bankName,
        bankBik: data.contact?.legalPerson?.bankBik,
        bankAccountNumber: data.contact?.legalPerson?.bankAccountNumber,
        clientData: data.contact?.legalPerson
    }) : ''

    const authorizedEmployee = useSelector(state => state.employee.authorizedEmployee)

    async function generateContractApi() {
        const htmlFile = editorRef.current.getContent()
        const file = new File([htmlFile], "name", {type: 'text/html'});
        let formData = new FormData()
        formData.append("file", file)
        const size = file.size
        const {data: s3Path} = await $s3host.post("/api/v1/file/upload?userName=user1&path=contracts&size=" + size, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
        })
        const newSigners = [{
            employeeId: authorizedEmployee?.id,
            isSigned: false
        }]
        let newData = removeEmpty(data)
        const contractId = await dispatch(addContract(newData?.name, uuidv4(), new Date(newData?.createdAt), new Date(), price, typeClient ? client : legalClient, 2, newData?.specifications?.map(el => {
                const oldSerivce = {...el.service}
                const newService = {...el.service}
                let service = removeEmpty(newService)
                delete service['id'];
                delete service['categoryId'];
                delete service['code'];
                delete service['costCoefficient'];
                delete service['createdAt'];
                delete service['departmentId'];
                delete service['groupId'];
                delete service['group'];
                delete service['kindId'];
                delete service['kind'];
                delete service['prices'];
                delete service['name'];
                delete service['subGroup'];
                delete service['subGroupId'];
                delete service['typeId'];

                return {
                    ...service,
                    objectId: '',
                    scheduleId: '',
                    schedule: {},
                    object: {},
                    serviceId: oldSerivce?.id,
                    qty: 1,
                    quantity: 1,
                    service: oldSerivce,
                    unit: service?.unit?.name,
                    price: el?.service?.price ? el?.service?.price : el?.service?.prices?.length ? el?.service?.prices[0]?.price : 0
                }
            }), newSigners, typeClient ? 'personId' : 'legalPersonId', s3Path, newData?.filialId, newData?.filialDivisionId, null, [],
            [],
            newData?.objects?.map(el => {
                return {
                    addressLine: el?.addressLine,
                    cityId: el?.cityId,
                    districtId: el?.districtId,
                    localityId: el?.localityId || "",
                    name: "",
                    objectId: el?.objectId
                }
            }) || [], false, 1))
        history.push(CONTRACT_EDIT_ROUTE + `/${contractId}`)
    }

    return (
        <Dashboard>
            <BreadcrumbsShared breadcrumbs={[
                {text: 'Сделки', link: DEAL_ROUTE},
                {text: 'Просмотреть сделку', link: DEAL_ADD_ROUTE},
            ]}/>
            {loading ? <Loader/> : <Box component="form" onSubmit={handleSubmit}>
                <Box>
                    <Box sx={{display: 'flex'}}>
                        <Box sx={{mt: 1, display: 'flex', maxWidth: '72%', padding: '0px 10px', flexWrap: 'wrap'}}>
                            <Box component={Paper} sx={{padding: '0px 10px', width: '100%'}}>
                                {RenderDeal}
                            </Box>
                            {!state.isCreated() &&
                                <Box component={Paper} sx={{padding: '0px 10px', width: '100%', marginTop: '15px'}}>
                                    <DealClarificationofneed disabled={!state.isClarificationOfNeed()} data={data}
                                                             handleLeads={handleLeads}
                                                             handleChangeObject={handleChangeObject} id={id}
                                                             district={district}
                                                             handleContractObjectRequest={handleContractObjectRequest}/>
                                </Box>}
                            {!state.isCreated() && !state.isClarificationOfNeed() &&
                                <Box component={Paper} sx={{padding: '0px 10px', width: '100%', marginTop: '15px'}}>
                                    <Typography sx={{margin: '20px 0'}}>Подготовка предложения</Typography>
                                    <TextShared disabled={!state.isProposalPreparation()}
                                                label="Коммерческое предложение (название)"
                                                value={data?.commercialOffer?.name}
                                                setValue={(e) => handleChangeObject('commercialOffer', {
                                                    ...data?.commercialOffer, 'name': e
                                                })}/>
                                    <Number disabled={!state.isProposalPreparation()}
                                            label="Коммерческое предложение (цена)" value={data?.commercialOffer?.price}
                                            setValue={(e) => handleChangeObject('commercialOffer', {
                                                ...data?.commercialOffer, 'price': e
                                            })}/>
                                    <TextShared disabled={!state.isProposalPreparation()}
                                                value={data?.comments?.length ? data?.comments[2]?.comment : ''}
                                                setValue={(e) => {
                                                    let comments = [...data?.comments]
                                                    comments[2] = {
                                                        ...data?.comments[2],
                                                        'comment': e,
                                                        dealId: data?.id,
                                                        stageId: data?.stageId
                                                    }
                                                    handleChangeObject('comments', [...comments])
                                                }} required={false} label="Комментарии"/>
                                </Box>}
                            {!state.isCreated() && !state.isClarificationOfNeed() && !state.isProposalPreparation() && !state.isOfferProtection() &&
                                <Box component={Paper} sx={{padding: '0px 10px', width: '100%', marginTop: '15px'}}>
                                    <Typography sx={{margin: '20px 0'}}>Договор</Typography>
                                    <TextShared disabled={!state.isToContract()}
                                                value={data?.comments?.length ? data?.comments[4]?.comment : ''}
                                                setValue={(e) => {
                                                    let comments = [...data?.comments]
                                                    comments[4] = {
                                                        ...data?.comments[4],
                                                        'comment': e,
                                                        dealId: data?.id,
                                                        stageId: data?.stageId
                                                    }
                                                    handleChangeObject('comments', [...comments])
                                                }} required={false} label="Комментарии"/>
                                </Box>}
                            {!state.isCreated() && !state.isClarificationOfNeed() && !state.isProposalPreparation() && !state.isOfferProtection() && !state.isToContract() &&
                                <Box component={Paper} sx={{padding: '0px 10px', width: '100%', marginTop: '15px'}}>
                                    <Typography sx={{margin: '20px 0'}}>Отказ</Typography>
                                    <TextShared disabled={!state.isDeclined()}
                                                value={data?.comments?.length ? data?.comments[5]?.comment : ''}
                                                setValue={(e) => {
                                                    let comments = [...data?.comments]
                                                    comments[5] = {
                                                        ...data?.comments[5],
                                                        'comment': e,
                                                        dealId: data?.id,
                                                        stageId: data?.stageId
                                                    }
                                                    handleChangeObject('comments', [...comments])
                                                }} required={false} label="Комментарии"/>
                                </Box>}
                            <Box>
                                <LoadingButton
                                    loading={loadingAdd}
                                    type="submit"
                                    variant="contained"
                                    sx={{mt: 3, mb: 2, maxHeight: '40px'}}
                                >
                                    Сохранить сделку
                                </LoadingButton>
                                <Button
                                    onClick={generateContract}
                                    variant="contained"
                                    sx={{ml: 2, mt: 3, mb: 2, maxHeight: '40px'}}
                                >
                                    Показать договор
                                </Button>
                                {generatedContract && <Button
                                    onClick={generateContractApi}
                                    variant="contained"
                                    sx={{ml: 2, mt: 3, mb: 2, maxHeight: '40px'}}
                                >
                                    Сформировать договор
                                </Button>}
                            </Box>
                            {generatedContract && <Box sx={{mt: 5, width: '100%'}}><CKEditor
                                id="editor"
                                onReady={ editor => {
                                    editorRef.current = editor
                                } }
                                editor={ ClassicEditor }
                                data={contractInit}
                            /></Box>}
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            marginTop: '8px',
                            paddingLeft: '100px',
                            flexDirection: 'column',
                            flex: 1
                        }}>
                            {dealState && dealState.filter(el => el.name !== 'Защита предложения').map(el => {
                                return <Button onClick={async () => {
                                    confirmDialog('Смена статуса', 'Вы действительно хотите изменить статус сделки (не забывайте, что перед тем как менять статус, сделку нужно сохранить)?', async () => {
                                        await handleChangeStage(data?.stage?.id, el.id)
                                        await handleChangeObjectMulti('stage', 'stageId', {
                                            id: el.id,
                                            code: el.code,
                                            name: el.name
                                        }, el.id)
                                    })
                                }} disabled={el.id < data?.stage?.id - 1 || el.id > data?.stage?.id + 2} key={el.id}
                                               sx={{
                                                   marginBottom: 5,
                                                   padding: '10px 0',
                                                   display: 'flex',
                                                   alignItems: 'center',
                                                   textAlign: 'center',
                                                   justifyContent: 'center',
                                                   color: 'black',
                                                   cursor: 'pointer',
                                                   ":hover": {backgroundColor: '#d9d9d9'},
                                                   backgroundColor: el.id === data?.stage?.id ? '#d9d9d9' : '#f5f5f5'
                                               }}>{el.name}</Button>
                            })}
                        </Box>
                    </Box>
                </Box>

            </Box>}
            <PopupLegalClient details={true} title="Клиенты (юр. лица)" open={openLegalClient}
                              setOpen={setOpenLegalClient}/>
            <PopupPerson details={true} title="Клиенты (физ. лица)" open={openPerson}
                         setOpen={setOpenPerson}/>
        </Dashboard>
    );
}

export default DealDetails;
