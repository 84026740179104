export const GET_DEAL_REQUEST = 'GET_DEAL_REQUEST'
export const GET_DEAL_SUCCESS = 'GET_DEAL_SUCCESS'
export const GET_DEAL_ERROR = 'GET_DEAL_ERROR'

export const GET_ONE_DEAL_REQUEST = 'GET_ONE_DEAL_REQUEST'
export const GET_ONE_DEAL_SUCCESS = 'GET_ONE_DEAL_SUCCESS'
export const GET_ONE_DEAL_ERROR = 'GET_ONE_DEAL_ERROR'

export const GET_DEAL_STAGE_REQUEST = 'GET_DEAL_STAGE_REQUEST'
export const GET_DEAL_STAGE_SUCCESS = 'GET_DEAL_STAGE_SUCCESS'
export const GET_DEAL_STAGE_ERROR = 'GET_DEAL_STAGE_ERROR'

export const ADD_DEAL_REQUEST = 'ADD_DEAL_REQUEST'
export const ADD_DEAL_SUCCESS = 'ADD_DEAL_SUCCESS'
export const ADD_DEAL_ERROR = 'ADD_DEAL_ERROR'

export const DELETE_DEAL_REQUEST = 'DELETE_DEAL_REQUEST'
export const DELETE_DEAL_SUCCESS = 'DELETE_DEAL_SUCCESS'
export const DELETE_DEAL_ERROR = 'DELETE_DEAL_ERROR'

export const DEAL_RELOAD = 'DEAL_RELOAD'