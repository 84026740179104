import React, {useState} from 'react';

import {clientCRUD, legalPersonCRUD} from "../../../http/CRUD";
import CRUDTableQuery from "../CRUDTableQuery";
import {PopupLegalClient} from "../../index";
import useReload from "../../../hooks/useReload";
import {confirmDialog} from "../../Popups/message/PopupDelete";
import CRUDTableStandart from "../CRUDTableStandart";

export default function LegalPersonCRUDTable({type = 'standart', ...props}) {
    const { reload, reloadValue } = useReload();
    const [edit, setEdit] = useState(false)
    const columns = [
        {title: "#", dataIndex: 'id', key: 'id'},
        {title: "Название", dataIndex: 'name', key: 'name'},
        {
            title: "Бин",
            key: 'bin',
            render: (unknown, row) => row.bin ? row.bin :
                <span style={{color: "gray"}}>Не задан</span>
        },
        {
            title: "Регион",
            key: 'regionLine',
            render: (unknown, row) => row.regionLine ? row.regionLine :
                <span style={{color: "gray"}}>Не задан</span>
        },
        {
            title: "Номер телефона",
            key: 'phoneNumber',
            render: (unknown, row) => row.phoneNumber ? row.phoneNumber :
                <span style={{color: "gray"}}>Не задан</span>
        }
    ]
    const searchFields = [
        {
            title: "Название клиента",
            key: "name",
            compareType: "like",
            operandPosition: 1,
            filterType: "normal"
        },
        {
            title: "Бин",
            key: "bin",
            compareType: "like",
            operandPosition: 1,
            filterType: "normal"
        }
    ]


    const [open, setOpen] = useState(false);
    const [id, setId] = useState(null);
    const editHandler = async (idEdit) => {
        setOpen(true)
        setEdit(true)
        setId(idEdit)
    }
    const confirmHandler = async () => {
        setOpen(false)
        await reload();
    }

    async function deleteHandler(id) {
        return confirmDialog('Удаление элемента', 'Вы действительно хотите удалить этот элемент?', async () => {
            try {
                const {result} = await clientCRUD.search({filter: {
                        legalPersonId: {
                            operand1: id,
                            operator: "equals"
                        }
                    }})
                if (result?.length){
                    await clientCRUD.delete(result[0].id)
                    await legalPersonCRUD.delete(id)
                }
                await reload();
            } catch (e) {
                console.log(e);
            }
        })
    }

    async function openHandler () {
        setOpen(true)
        setEdit(false)
    }

    const CRUDTables = {
        standart: CRUDTableStandart,
        query: CRUDTableQuery,
    }

    const CRUDTable = CRUDTables[type]


    return (
        <>
            <CRUDTable search={legalPersonCRUD.search}
                            columns={columns}
                            onClickEditButton={props.edit ? editHandler : null}
                            onClickDeleteButton={props.delete ? deleteHandler : null}
                            onClickCreateButton={openHandler}
                            searchFields={searchFields}
                            reloadValue={reloadValue}
                            {...props}
            />
            <PopupLegalClient edit={edit} id={id} setId={setId} open={open} setOpen={setOpen} confirmHandler={confirmHandler}/>
        </>
    );
}
