import {
    TYPE_CLIENT_ADD_ERROR,
    TYPE_CLIENT_ADD_REQUEST,
    TYPE_CLIENT_ADD_SUCCESS,
    TYPE_CLIENT_DELETE_ERROR,
    TYPE_CLIENT_DELETE_REQUEST,
    TYPE_CLIENT_DELETE_SUCCESS, TYPE_CLIENT_EDIT_ERROR, TYPE_CLIENT_EDIT_REQUEST, TYPE_CLIENT_EDIT_SUCCESS,
    TYPE_CLIENT_GET_ERROR,
    TYPE_CLIENT_GET_REQUEST,
    TYPE_CLIENT_GET_SUCCESS
} from "../../constants/client/typeClientConstants";

const initialState = {
    typeClient: [],
    loading: false,
    success: null,
    error: null
};

export const typeClientReducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPE_CLIENT_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case TYPE_CLIENT_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true
            };
        case TYPE_CLIENT_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case TYPE_CLIENT_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case TYPE_CLIENT_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true
            };
        case TYPE_CLIENT_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case TYPE_CLIENT_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case TYPE_CLIENT_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true
            };
        case TYPE_CLIENT_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case TYPE_CLIENT_GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case TYPE_CLIENT_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                typeClient: action.payload,
            };
        case TYPE_CLIENT_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        default:
            return state;
    }
}