import React from 'react';
import {Button, FormGroup, Grid, Stack, Switch} from "@mui/material";
import Typography from "@mui/material/Typography";
import PersonField from "../../Fields/PersonField";
import LegalClientField from "../../Fields/LegalClientField";
import EmployeeField from "../../Fields/EmployeeField";
import {DatePickerShared, SelectShared} from "../../index";
import TextShared from "../../Shared/TextShared";

function DealNew({typeClient, disabled, setTypeClient, filialDivision, filial, serviceCategory, data, setOpenLegalClient, handleChangeObject, setLegalClient,  setOpenPerson, client, legalClient, setClient}) {
    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <FormGroup sx={{marginTop: 1, display: 'flex', justifyContent: 'center'}}>
                    <Typography>Тип контакта</Typography>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography>юр. лицо</Typography>
                        <Switch disabled={disabled} onChange={(event) => setTypeClient(event.target.checked)}
                                checked={typeClient}
                                inputProps={{'aria-label': 'ant design'}}/>
                        <Typography>физ. лицо</Typography>
                    </Stack>
                </FormGroup>
                {typeClient ?
                    <Button disabled={disabled} sx={{marginTop: 1}} onClick={() => setOpenPerson(true)} color="info"
                            variant="contained">Добавить физ лицо</Button> :
                    <Button disabled={disabled} sx={{marginTop: 1}} onClick={() => setOpenLegalClient(true)} color="info"
                            variant="contained">Добавить юр. лицо</Button>}
            </Grid>
            <Grid item xs={6}>
                {typeClient ?
                    <PersonField
                        disabled={disabled}
                        fullWidth
                        object={data?.contact?.person}
                        value={client}
                        label="Контакт (физ. лицо)"
                        sx={{paddingTop: '35px'}}
                        onChange={(client) => {
                            setClient(client.id)
                        }}
                    /> :
                    <LegalClientField
                        disabled={disabled}
                        fullWidth
                        object={data?.contact?.legalPerson}
                        value={legalClient}
                        label="Контакт (юр. лицо)"
                        sx={{paddingTop: '35px'}}
                        onChange={(client) => {
                            setLegalClient(client.id)
                        }}
                    />}
            </Grid>
            <Grid item xs={6}>
                <EmployeeField
                    disabled={disabled}
                    fullWidth
                    object={data?.manager}
                    value={data?.managerId}
                    label="Менеджер"
                    sx={{paddingTop: '35px'}}
                    onChange={(employee) => {
                        handleChangeObject('manager', employee)
                        handleChangeObject('managerId', employee.id)
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <SelectShared disabled={disabled} value={data?.filialId} setValue={(e) => handleChangeObject('filialId', e)} label="Филиал" array={filial}/>
            </Grid>
            <Grid item xs={6}>
                <SelectShared disabled={!data?.filialId || disabled}  value={data?.filialDivisionId} required={false} setValue={(e) => handleChangeObject('filialDivisionId', e)}
                              label="Районное отделение" array={filialDivision && filialDivision.filter(el => el.filialId === data.filialId)}/>
            </Grid>
            <Grid item xs={6}>
                <DatePickerShared disabled={disabled} setValue={(e) => handleChangeObject('createdAt', new Date(e))} value={data?.createdAt} label="Дата регистрации"
                                  fullWidth/>
            </Grid>
            <Grid item xs={6}>
                <TextShared disabled={disabled} value={data?.comments?.length ? data?.comments[0]?.comment : ''} setValue={(e) => {
                    let comments = [...data?.comments]
                    comments[0] = {...data?.comments[0], 'comment': e, dealId: data?.id, stageId: data?.stageId}
                    handleChangeObject('comments', [...comments])
                }} required={false} label="Комментарии"/>
            </Grid>
            <Grid item xs={6}>
                <SelectShared disabled={disabled} array={serviceCategory}
                              value={serviceCategory?.find(el => el.name === data?.name)?.id}
                              setValue={(e) => handleChangeObject('name', serviceCategory?.find(el => el.id === e)?.name)} label="Название сделки"/>
            </Grid>
        </Grid>
    );
}

export default DealNew;
