export const LEGAL_CLIENT_ADD_REQUEST = 'LEGAL_CLIENT_ADD_REQUEST'
export const LEGAL_CLIENT_ADD_SUCCESS = 'LEGAL_CLIENT_ADD_SUCCESS'
export const LEGAL_CLIENT_ADD_ERROR = 'LEGAL_CLIENT_ADD_ERROR'

export const LEGAL_CLIENT_GET_REQUEST = 'LEGAL_CLIENT_GET_REQUEST'
export const LEGAL_CLIENT_GET_SUCCESS = 'LEGAL_CLIENT_GET_SUCCESS'
export const LEGAL_CLIENT_GET_ERROR = 'LEGAL_CLIENT_GET_ERROR'


export const ONE_LEGAL_CLIENT_GET_REQUEST = 'ONE_LEGAL_CLIENT_GET_REQUEST'
export const ONE_LEGAL_CLIENT_GET_SUCCESS = 'ONE_LEGAL_CLIENT_GET_SUCCESS'
export const ONE_LEGAL_CLIENT_GET_ERROR = 'ONE_LEGAL_CLIENT_GET_ERROR'

export const ONE_LEGAL_CLIENT_EDIT_REQUEST = 'ONE_LEGAL_CLIENT_EDIT_REQUEST'
export const ONE_LEGAL_CLIENT_EDIT_SUCCESS = 'ONE_LEGAL_CLIENT_EDIT_SUCCESS'
export const ONE_LEGAL_CLIENT_EDIT_ERROR = 'ONE_LEGAL_CLIENT_EDIT_ERROR'

export const LEGAL_CLIENT_DELETE_REQUEST = 'LEGAL_CLIENT_DELETE_REQUEST'
export const LEGAL_CLIENT_DELETE_SUCCESS = 'LEGAL_CLIENT_DELETE_SUCCESS'
export const LEGAL_CLIENT_DELETE_ERROR = 'LEGAL_CLIENT_DELETE_ERROR'

export const LEGAL_CLIENT_EDIT_REQUEST = 'LEGAL_CLIENT_EDIT_REQUEST'
export const LEGAL_CLIENT_EDIT_SUCCESS = 'LEGAL_CLIENT_EDIT_SUCCESS'
export const LEGAL_CLIENT_EDIT_ERROR = 'LEGAL_CLIENT_EDIT_ERROR'