import {
    GET_GEO_OBJECT_REQUEST,
    GET_GEO_OBJECT_SUCCESS,
    GET_GEO_OBJECT_ERROR,
    GET_GEO_OBJECT_TYPE_REQUEST,
    GET_GEO_OBJECT_TYPE_SUCCESS,
    GET_GEO_OBJECT_TYPE_ERROR,
    GET_GEO_OBJECT_FILTER_REQUEST,
    GET_GEO_OBJECT_FILTER_SUCCESS,
    GET_GEO_OBJECT_FILTER_ERROR,
    ADD_GEO_OBJECT_REQUEST,
    ADD_GEO_OBJECT_SUCCESS,
    ADD_GEO_OBJECT_ERROR,
    DELETE_GEO_OBJECT_REQUEST,
    DELETE_GEO_OBJECT_SUCCESS,
    DELETE_GEO_OBJECT_ERROR,
    EDIT_GEO_OBJECT_REQUEST,
    EDIT_GEO_OBJECT_SUCCESS,
    EDIT_GEO_OBJECT_ERROR,
    GET_GEO_CHILD_OBJECT_REQUEST, GET_GEO_CHILD_OBJECT_SUCCESS, GET_GEO_CHILD_OBJECT_ERROR,
} from "../../constants/geo/geoConstants";

const initialState = {
    geoType: [],
    geoFilter: [],
    geoChild: [],
    geo: [],
    loading: false,
    success: null,
    error: null
};

export const geoReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_GEO_OBJECT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_GEO_OBJECT_SUCCESS:
            return {
                ...state,
                loading: false,
                geo: action.payload,
                success: true
            };
        case GET_GEO_OBJECT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case GET_GEO_CHILD_OBJECT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_GEO_CHILD_OBJECT_SUCCESS:
            return {
                ...state,
                loading: false,
                geoChild: action.payload,
                success: true
            };
        case GET_GEO_CHILD_OBJECT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case DELETE_GEO_OBJECT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DELETE_GEO_OBJECT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true
            };
        case DELETE_GEO_OBJECT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case EDIT_GEO_OBJECT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case EDIT_GEO_OBJECT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true
            };
        case EDIT_GEO_OBJECT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case ADD_GEO_OBJECT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ADD_GEO_OBJECT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true
            };
        case ADD_GEO_OBJECT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case GET_GEO_OBJECT_FILTER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_GEO_OBJECT_FILTER_SUCCESS:
            return {
                ...state,
                loading: false,
                geoFilter: action.payload,
                success: true
            };
        case GET_GEO_OBJECT_FILTER_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case GET_GEO_OBJECT_TYPE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_GEO_OBJECT_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                geoType: action.payload,
                success: true
            };
        case GET_GEO_OBJECT_TYPE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        default:
            return state;
    }
}