import React, {useMemo} from 'react'
import {TextField} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ruLocale from "date-fns/locale/ru";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {DatePicker} from "@mui/x-date-pickers";

const DatePickerShared = ({value, maxValue, setValue, label, fullWidth, views, disabled}) => {
    return useMemo(() => {
        return <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
            <DatePicker
                mask="__.__.____"
                views={views}
                label={label}
                value={value}
                disabled={disabled}
                onChange={(newValue) => {
                    setValue(newValue);
                }}
                minDate={new Date('1910-03-01')}
                maxDate={maxValue}
                renderInput={(params) => <TextField
                    margin="normal"
                    fullWidth={fullWidth}
                    required
                    {...params} helperText={null}/>}
            />
        </LocalizationProvider>;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [label, value, maxValue, setValue, fullWidth]);
}

DatePickerShared.defaultProps = {
    fullWidth: true,
    disabled: false,
    views: ['day', 'month', 'year']
}

export default DatePickerShared