import {PROFILE_GET_ERROR, PROFILE_GET_REQUEST, PROFILE_GET_SUCCESS} from "../../constants/profile/profileConstants";

const initialState = {
    profile: {},
    loading: false,
    success: null,
    error: null
};

export const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case PROFILE_GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PROFILE_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                profile: action.payload,
            };
        case PROFILE_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        default:
            return state;
    }
}