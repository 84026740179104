import React, {useState} from 'react';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import {Collapse, ListItemButton, ListSubheader} from "@mui/material";
import {useHistory} from "react-router-dom";
import {GENERAL_ROUTE} from "../../routes/const";
import Typography from "@mui/material/Typography";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import GeneralDrawerChild from "./GeneralDrawerChild";

function GeneralDrawer({id, category, packageParent}) {
    const [open, setOpen] = useState(false)
    const history = useHistory()

    const goToPage = (id, search) => {
        return () => {
            history.push({pathname: GENERAL_ROUTE +`/${id}`, search})
        }
    }
    return (
        <List
            sx={{width: '100%', maxWidth: 300, maxHeight: 700, overflowY: 'scroll', bgcolor: 'background.paper'}}
            component="nav"
            subheader={
                <ListSubheader component="div">
                    Исследования и цены
                </ListSubheader>
            }
        >
            {category && category.map(el => {
                if (el.id === 1) {
                    return <React.Fragment key={el.id}>
                        <ListItemButton selected={el.id === +id}
                                        onClick={() => setOpen(!open)}
                                        key={el.id}>
                            <ListItemText secondary={
                                <Typography
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                >
                                    {el.name}
                                </Typography>}/>
                            {open ? <ExpandLess/> : <ExpandMore/>}
                        </ListItemButton>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <GeneralDrawerChild goToPage={goToPage} packageParent={packageParent} id={el.id}/>
                        </Collapse>
                    </React.Fragment>
                } else {
                    return <ListItemButton selected={el.id === +id}
                                           onClick={goToPage(el.id)}
                                           key={el.id}>
                        <ListItemText secondary={
                            <Typography
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                {el.name}
                            </Typography>}/>
                    </ListItemButton>
                }

            })}
        </List>
    );
}

export default GeneralDrawer;