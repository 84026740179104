import {
    CLIENT_REQUEST_ONE_ERROR,
    CLIENT_REQUEST_ONE_REQUEST, CLIENT_REQUEST_ONE_SUCCESS,
    CLIENT_REQUEST_REQUEST_ERROR,
    CLIENT_REQUEST_REQUEST_REQUEST,
    CLIENT_REQUEST_REQUEST_SUCCESS
} from "../../constants/clientRequest/clientRequestConstants";

const initialState = {
    clientRequests: [],
    clientRequestsCountTable: null,
    clientRequestOne: {},
    loading: false,
    success: null,
    error: null
};

export const clientRequestReducer = (state = initialState, action) => {
    switch (action.type) {
        case CLIENT_REQUEST_ONE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CLIENT_REQUEST_ONE_SUCCESS:
            return {
                ...state,
                loading: false,
                clientRequestOne: action.payload,
                success: true
            };
        case CLIENT_REQUEST_ONE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case CLIENT_REQUEST_REQUEST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CLIENT_REQUEST_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                clientRequests: action.payload.result,
                clientRequestsCountTable: action.payload.total,
                success: true
            };
        case CLIENT_REQUEST_REQUEST_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        default:
            return state;
    }
}