import React, {useState} from 'react';
import useLoader from "../../../hooks/useLoader";
import {Button} from "@mui/material";
import {Table} from "antd";
import TextShared from "../../Shared/TextShared";
import {v4 as uuidv4} from "uuid";
import LegalClientField from "../../Fields/LegalClientField";
import ClientField from "../../Fields/ClientField";
import EmployeeField from "../../Fields/EmployeeField";
import {Number} from "../../index";
import {getRandomInt} from "../../../functions";

function DealLeads({handleChangeObject, data, onRowClick, handleLeads, disabled}) {
    const [_data, setData] = useState(data?.leads)

    const changeOriginal = (row, e, name) => {
        let objectOld = data?.leads?.find(el => el.id === row.id)
        let idx = data?.leads?.findIndex(el => el.id === row.id)
        let leads = [...data?.leads]
        leads[idx] = {...objectOld, [name]: e}
        handleChangeObject('leads', [...leads])
        setData([...leads])
    }

    const columns = [
        {
            title: "Тема сотрудничества", dataIndex: 'partnershipTheme', key: 'partnershipTheme',
            render: (unknown, row) => {
                return <TextShared disabled={disabled} setValue={(e) => {
                    changeOriginal(row, e, 'partnershipTheme')
                }} value={row['partnershipTheme']} label="Тема сотрудничества"/>
            }
        },
        {
            title: "Потенциальный бюджет", dataIndex: 'potentialBudget', key: 'potentialBudget',
            render: (unknown, row) => {
                return <Number disabled={disabled} max={9999999999} setValue={(e) => {
                    changeOriginal(row, e, 'potentialBudget')
                }} value={row['potentialBudget']} label="Потенциальный бюджет"/>
            }
        },
        {
            title: "Строка для заполнения стадии у клиента", dataIndex: 'stateLine', key: 'stateLine',
            render: (unknown, row) => {
                return <TextShared disabled={disabled} setValue={(e) => {
                    changeOriginal(row, e, 'stateLine')
                }} value={row['stateLine']} label="Строка для заполнения стадии у клиента"/>
            }
        },
        {
            title: "Контакт", dataIndex: 'contactId', key: 'contactId',
            render: (unknown, row) => {
                return <ClientField
                    disabled={disabled}
                    fullWidth
                    value={row['contactId']}
                    label="Контакт"
                    onChange={(client) => {
                        changeOriginal(row, client.id, 'contactId')
                    }}
                />
            }
        },
        {
            title: "Менеджер", dataIndex: 'managerId', key: 'managerId',
            render: (unknown, row) => {
                return <EmployeeField
                    disabled={disabled}
                    fullWidth
                    value={row['managerId']}
                    label="Менеджер"
                    onChange={(client) => {
                        changeOriginal(row, client.id, 'managerId')
                    }}
                />
            }
        },
        {
            title: "Действия", dataIndex: 'actions', key: 'actions',
            render: (unknown, row) => <Button disabled={disabled} onClick={() => {
                handleChangeObject('leads', [...data.leads.filter(el => el.id !== row.id)])
                setData([...data.leads.filter(el => el.id !== row.id)])
            }
            }>Удалить</Button>
        },
    ];

    return (
        <>
            <Table
                columns={columns}
                dataSource={_data}
                pagination={false}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (e) => onRowClick && onRowClick(record, rowIndex), // click row
                        onDoubleClick: (event) => {
                        }, // double click row
                        onContextMenu: (event) => {
                        }, // right button click row
                        onMouseEnter: (event) => {
                        }, // mouse enter row
                        onMouseLeave: (event) => {
                        }, // mouse leave row
                    };
                }}
            />
            <Button disabled={disabled} onClick={
                () => {
                    const newElement = {
                        id: getRandomInt(1, 10000000),
                        partnershipTheme: '',
                        potentialBudget: '',
                        stateLine: '',
                        dealId: '',
                        contactId: '',
                        managerId: '',
                        stateId: ''
                    }
                    handleLeads(newElement)
                    setData([...data.leads, newElement])
                }
            }>Добавить лид</Button>
        </>
    );
}

export default DealLeads;