export const SPECIFICATION_GET_REQUEST = 'SPECIFICATION_GET_REQUEST'
export const SPECIFICATION_GET_SUCCESS = 'SPECIFICATION_GET_SUCCESS'
export const SPECIFICATION_GET_ERROR = 'SPECIFICATION_GET_ERROR'

export const SPECIFICATION_ADD_REQUEST = 'SPECIFICATION_ADD_REQUEST'
export const SPECIFICATION_ADD_SUCCESS = 'SPECIFICATION_ADD_SUCCESS'
export const SPECIFICATION_ADD_ERROR = 'SPECIFICATION_ADD_ERROR'


export const SPECIFICATION_DELETE_REQUEST = 'SPECIFICATION_DELETE_REQUEST'
export const SPECIFICATION_DELETE_SUCCESS = 'SPECIFICATION_DELETE_SUCCESS'
export const SPECIFICATION_DELETE_ERROR = 'SPECIFICATION_DELETE_ERROR'
