import React, {createContext, useState} from 'react';
import DashboardLayout from "../../layouts/Dashboard";
import {CONTRACT_DETAILS_ROUTE, CONTRACT_EDIT_ROUTE, CONTRACT_ROUTE, CONTRACT_VIEW_ROUTE} from "../../routes/const";
import moment from "moment";
import 'moment/locale/ru'
import BreadcrumbsShared from "../../components/Shared/BreadcrumbsShared";
import useReload from "../../hooks/useReload";
import ContractCRUDTable from "../../components/Crud/Tables/ContractCRUDTable";
import {useHistory} from "react-router-dom";
import {confirmDialog} from "../../components/Popups/message/PopupDelete";
import {contractCRUD} from "../../http/CRUD";
import {showSuccessSnackbar} from "../../redux/actions/userActions";
import {useDispatch, useSelector} from "react-redux";
import {convertContractToExcel} from "../../functions";
import {$authHost, $s3host} from "../../http";
import {parseResponse} from "../../http/utils";
import {checkRoles} from "../../functions/checkRoles";
import {getContractFile, getContractFileS3} from "../../redux/actions/contract/contractActions";

moment.locale('ru')

export const ContractContext = createContext();

const Contract = () => {
    const {reload, reloadValue} = useReload();
    const history = useHistory()
    const [page, setPage] = useState(0);
    const [filter, setFilter] = useState({})
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const dispatch = useDispatch()

    async function deleteHandler(id) {
        return confirmDialog('Удаление элемента', 'Вы действительно хотите удалить этот элемент?', async () => {
            try {
                await contractCRUD.delete(id)
                await reload();
            } catch (e) {
                console.log(e);
            }
        })
    }

    async function copyHandler({id, ...other}) {
        return confirmDialog('Копирование элемента', 'Вы действительно хотите копировать этот договор?', async () => {
            try {
                const data = await contractCRUD.get(id)

                const answer = await dispatch(getContractFile(1, 10, {
                    filter: {
                        contractId: {
                            operand1: id,
                            operator: "equals"
                        },
                    }
                }))
                const {bucketName, key, objectName} = JSON.parse(answer[0]?.s3Path)
                const htmlFile = await dispatch(getContractFileS3(bucketName, key, objectName))

                const file = new File([htmlFile], "name", {type: 'text/html'});
                let formData = new FormData()
                formData.append("file", file)
                const size = file.size

                const {data: s3Path} = await $s3host.post("/api/v1/file/upload?userName=user1&path=contracts&size=" + size, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
                const {id: idCreated} = await contractCRUD.create({...data, name: `${data.name} (копия)`})
                await $authHost.post(
                    "/api/v1/contractFile",
                    {createdAt: new Date(), s3Path: {...s3Path}, contractId: idCreated}
                );

                reload()
            } catch (e) {
                dispatch(showSuccessSnackbar("Ошибка при копировании договора", "error"))
                console.log(e);
            }
        })
    }

    const authorizedEmployee = useSelector(state => state.employee.authorizedEmployee)
    const isAdmin = checkRoles("SuperAdministrator")

    const handleContractToExcel = async (filter) => {
        if (filter?.actionAt?.operand1 && filter?.actionAt?.operand2) {
            const filterToServer = {
                actionAt: {...filter?.actionAt}
            };

            if (!isAdmin) {
                filterToServer.filialId = {
                    operand1: +authorizedEmployee.filialId,
                    operator: "equals"
                }
                if (authorizedEmployee.filialDivisionId)
                    filterToServer.filialDivisionId = {
                        operand1: +authorizedEmployee.filialDivisionId,
                        operator: "equals"
                    }
            }

            if (filter?.name) {
                filterToServer.name = {...filter?.name};
            }
            if (filter?.number) {
                filterToServer.number = {...filter?.number};
            }
            if (filter?.stateId) {
                filterToServer.stateId = {...filter?.stateId}
            }

            const res = await parseResponse($authHost.post(`/api/v1/contract/summary/query`, {
                paging: {skip: 0},
                filter: filterToServer
            }));

            if (res?.length) {
                await convertContractToExcel(res, filter)
            }
        } else {
            dispatch(showSuccessSnackbar("Вы не указали отчётный период", "error"))
        }
    }

    return (
        <DashboardLayout>
            <BreadcrumbsShared breadcrumbs={[
                {text: 'Договоры', link: CONTRACT_ROUTE}
            ]}/>
            <ContractContext.Provider value={{handleContractToExcel, page, rowsPerPage, filter}}>
                <ContractCRUDTable deleteVisibleFunc={(_) => {
                    return !!(authorizedEmployee?.id === _?.createdBy?.id || checkRoles("SuperAdministrator", 'AdministratorMarketer'));

                }} type="standart" onClickEditButton={(id) => history.push(CONTRACT_EDIT_ROUTE + `/${id}`)}
                                   onClickDetailsButton={(id) => history.push(CONTRACT_VIEW_ROUTE + `/${id}`)}
                                   onClickDeleteButton={deleteHandler}
                                   onClickCopyButton={row => row?.typeId === 2 ? copyHandler(row) : null}
                                   onClickCreateButton={() => history.push(CONTRACT_DETAILS_ROUTE)}
                                   reloadValue={reloadValue} modalFilter/>
            </ContractContext.Provider>
        </DashboardLayout>
    );
};

export default Contract;
