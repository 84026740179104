import React, {useEffect} from 'react';
import DashboardLayout from "../../layouts/Dashboard";
import {useDispatch, useSelector} from "react-redux";
import CardGeneralRow from "../../components/General/CardGeneralRow";
import {getServiceCategory} from "../../redux/actions/serviceCategory/serviceCategoryActions";
import BreadcrumbsShared from "../../components/Shared/BreadcrumbsShared";
import {GENERAL_ROUTE} from "../../routes/const";
import ClientLayout from "../../layouts/ClientLayout";

function General(props) {
    const userLogin = useSelector((state) => state.user);
    const dispatch = useDispatch()
    const {isClient} = userLogin;
    const {serviceCategory} = useSelector((state) => state.serviceCategory);
    useEffect(() => {
        dispatch(getServiceCategory(1, 100))
    }, [dispatch])

    return (
        <>
            {!isClient && <DashboardLayout>
                <BreadcrumbsShared breadcrumbs={[
                    {text: 'Главная', link: GENERAL_ROUTE}
                ]}/>
                <CardGeneralRow serviceCategory={serviceCategory}/>
            </DashboardLayout>}
            {isClient && <ClientLayout>
                <BreadcrumbsShared breadcrumbs={[
                    {text: 'Главная', link: GENERAL_ROUTE}
                ]}/>
                <CardGeneralRow serviceCategory={serviceCategory}/>
            </ClientLayout>}
        </>
    );
}

export default General;