import React, {useState} from 'react';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import {Collapse, ListItemButton, ListSubheader} from "@mui/material";
import Typography from "@mui/material/Typography";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ModalDrawerChild from "./ModalDrawerChild";

function ModalDrawer({id, category, packageParent, setId, searchDrawer, setSearchDrawer}) {
    const [open, setOpen] = useState(false)

    const goToPage = (id, search) => {
        return () => {
            setId(id)
            if (search) {
                setSearchDrawer(true)
            } else {
                setSearchDrawer(false)
            }
        }
    }
    return (
        <List
            sx={{width: '100%', margin: '10px 10px 10px 0', maxWidth: 300, maxHeight: 700, overflowY: 'scroll', bgcolor: 'background.paper'}}
            component="nav"
            subheader={
                <ListSubheader component="div">
                    Исследования и цены
                </ListSubheader>
            }
        >
            {category && category.map(el => {
                if (el.id === 1) {
                    return <React.Fragment key={el.id}>
                        <ListItemButton selected={el.id === +id}
                                        onClick={() => setOpen(!open)}
                                        key={el.id}>
                            <ListItemText secondary={
                                <Typography
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                >
                                    {el.name}
                                </Typography>}/>
                            {open ? <ExpandLess/> : <ExpandMore/>}
                        </ListItemButton>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <ModalDrawerChild goToPage={goToPage} packageParent={packageParent} id={el.id}/>
                        </Collapse>
                    </React.Fragment>
                } else {
                    return <ListItemButton selected={el.id === +id}
                                           onClick={goToPage(el.id)}
                                           key={el.id}>
                        <ListItemText secondary={
                            <Typography
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                {el.name}
                            </Typography>}/>
                    </ListItemButton>
                }

            })}
        </List>
    );
}

export default ModalDrawer;