import React from 'react';
import {authRoutes, clientRoutes, publicRoutes} from "../../routes/routes";
import {Redirect, Route, Switch} from 'react-router-dom'
import {LOGIN_ROUTE, GENERAL_ROUTE} from "../../routes/const";
import {useSelector} from "react-redux";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

const AppRouter = () => {
    const userLogin = useSelector((state) => state.user);
    const { isClient, isAuth } = userLogin;
    // let isAuth = !!localStorage.getItem("token") != null &&
    //     localStorage.getItem("token") != "null";
    const location = useLocation().pathname;

    const history = useHistory();

    const knownRoute = (authRoutes.find(_ => _.path.startsWith(location)) != null ||
        clientRoutes.find(_ => _.path.startsWith(location)) != null ||
        publicRoutes.find(_ => _.path.startsWith(location)) != null) && location != '/';

    return (
        <div>
            <Switch>
                {isAuth && !isClient && authRoutes.map(({path, Component}) =>
                    <Route key={path} path={path} component={Component} exact/>
                )}
                {isAuth && isClient && clientRoutes.map(({path, Component}) =>
                    <Route key={path} path={path} component={Component} exact/>
                )}
                {publicRoutes.map(({path, Component}) =>
                    <Route key={path} path={path} component={Component} exact/>
                )}
                {!knownRoute && <Redirect to={GENERAL_ROUTE}/>}
                {!isAuth && <Redirect to={LOGIN_ROUTE}/>}                
            </Switch>
        </div>
    );
};

export default AppRouter;