import React from 'react';
import DashboardLayout from "../../../layouts/Dashboard";
import PersonCRUDTable from "../../../components/Crud/Tables/PersonCRUDTable";
import {ContractContext} from "../../Contract/Contract";
import {personCRUD} from "../../../http/CRUD";
import {convertClientToExcel} from "../../../functions";

const Person = () => {

    const handleContractToExcel = async () => {
        const {result} = await personCRUD.search({paging: {skip: 0}})
        if (result?.length) {
            await convertClientToExcel(result, 'fullName')
        }
    }

    return (
        <DashboardLayout>
            <ContractContext.Provider value={{handleContractToExcel}}>
                <PersonCRUDTable modalFilter edit={true} delete={true}/>
            </ContractContext.Provider>
        </DashboardLayout>
    );
};

export default Person;
