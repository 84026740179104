import React, {useEffect, useMemo, useState} from 'react';
import {Card, CardContent, Grid} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {SearchPanel} from "../index";
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import ModalDrawer from "./ModalDrawer";
import {LOADING_FALSE, LOADING_TRUE} from "../../redux/constants/genericConstants";
import {getServiceCategory, getServicePackage} from "../../redux/actions/serviceCategory/serviceCategoryActions";
import ServiceCRUDTable from "../Crud/Tables/ServiceCRUDTable";
import {serviceCRUD, serviceToPackageCRUD} from "../../http/CRUD";
import {v4 as uuidv4} from "uuid";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    height: '85%',
    transform: 'translate(-50%, -50%)',
    width: '95%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const SelectFieldModalGroup = ({
                              setValue,
                              reduxCount,
                              nameFilter,
                              func,
                              navigate,
                              disabled
                          }) => {
    const typeDrawer = {
        service: 'Услуга',
        nothing: 'Ничего',
    }

    const typeData = 'package'

    const drawer = typeDrawer[navigate === 1 ? 'service' : 'nothing']

    const [packageParent, setPackageParent] = useState([])
    const {serviceCategory} = useSelector((state) => state.serviceCategory);


    const [id, setId] = useState(2)
    const [searchDrawer, setSearchDrawer] = useState(false)

    const RenderDrawer = useMemo(() => {
        if (drawer === 'Услуга') {
            return (
                <ModalDrawer setSearchDrawer={setSearchDrawer} searchDrawer={searchDrawer} packageParent={packageParent}
                             setId={setId} id={id} category={serviceCategory}/>
            )
        } else {
            return false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, packageParent, serviceCategory]);


    const [open, setOpen] = useState(false)
    const [filter, setFilter] = useState({})
    const [rowCount, setRowCount] = useState(reduxCount)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const dispatch = useDispatch()
    const [page, setPage] = useState(1);

    useEffect(() => {
        setPage(1)
        setFilter({})
    }, [func, open])

    useEffect(() => {
        (async function () {
            if (drawer === 'Услуга') {
                dispatch({type: LOADING_TRUE})
                await dispatch(getServiceCategory(1, 100))
                const {result} = await dispatch(getServicePackage(1, 100, {
                    filter: {
                        parentId: {
                            operand1: null,
                            operator: "equals"
                        }
                    }
                }, true))
                setPackageParent(result)
                dispatch({type: LOADING_FALSE})
            }
        }())
    }, [dispatch, drawer])

    useEffect(() => {
        return () => {
            setPackageParent([])
            setSearchDrawer(false)
            setId(2)
        }
    }, [])

    const RenderSearch = useMemo(() => {
        if (drawer === 'Услуга') return false
        return (
            <SearchPanel setFilter={setFilter} rowCount={rowCount}
                         setRowCount={setRowCount} page={page} setPage={setPage}
                         func={func} searchName={nameFilter} label="Поиск записи"/>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [func, nameFilter, page, rowCount])

    const handleSelectAllServicesInPackage = async () => {
        let res;
        if (drawer === 'Услуга' && !searchDrawer) {
            res = await serviceCRUD.search({
                paging: { skip: 0 },
                filter: {
                    typeId: {
                        operand1: 1,
                        operator: "equals"
                    },
                    categoryId: {
                        operand1: id,
                        operator: "equals"
                    }
                }
            })
            if (res?.result?.length) {
                const data = res?.result?.map(el => {
                    return {
                        Id: uuidv4(),
                        service: el,
                        serviceId: el.id,
                        unitId: el?.unitId,
                        unit: el?.unit,
                        quantity: 1,
                        square: 1,
                        multiplicity: 1,
                        price: el.price,
                        objectId: '',
                        scheduleId: '',
                    }
                })
                await setValue([...data])
            }
        } else {
            res = await serviceToPackageCRUD.search({
                paging: { skip: 0 },
                filter: {
                    packageId: {
                        operand1: +id,
                        operator: "equals"
                    }
                }
            })
            if (res?.result?.length) {
                const data = res?.result?.map(el => {
                    return {
                        Id: uuidv4(),
                        service: el.service,
                        serviceId: el.service?.id,
                        unitId: '',
                        unit: {},
                        quantity: 1,
                        price: el.service?.price,
                        objectId: '',
                        scheduleId: '',
                    }
                })
                await setValue([...data])
            }

        }
        handleClose()

    }

    return useMemo(() => {
        return (
            <>
                <Button onClick={handleOpen} variant="text">Выбрать пакет</Button>
                {!disabled && <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Card sx={style}
                    >
                        <CardContent sx={{padding: 0, height: '95%', overflowY: 'auto'}}>
                            <Typography
                                align="center"
                                variant="h5"
                            >
                                Выбор пакета
                            </Typography>
                            <Box sx={{margin: '20px 0'}}>
                                {typeData === 'package' && (
                                    <>
                                        {RenderSearch}
                                        <Box sx={{display: 'flex'}}>
                                            {RenderDrawer}
                                            <Box sx={{display: 'block', flex: 1}}>
                                                <Grid sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                                    <Button onClick={handleSelectAllServicesInPackage} variant="contained" sx={{alignSelf: 'flex-end'}}>Выбрать пакет</Button>
                                                </Grid>
                                                <ServiceCRUDTable
                                                    edit={false}
                                                    serviceToPackage={!(drawer === 'Услуга' && !searchDrawer)}
                                                    search={drawer === 'Услуга' && !searchDrawer ? serviceCRUD.search : serviceToPackageCRUD.search}
                                                    extraFilter={{
                                                        ...(drawer === 'Услуга' && !searchDrawer && {
                                                            typeId: {
                                                                operand1: 1,
                                                                operator: "equals"
                                                            },
                                                            categoryId: {
                                                                operand1: id,
                                                                operator: "equals"
                                                            }
                                                        }),
                                                        ...(drawer === 'Услуга' && searchDrawer && {
                                                            packageId: {
                                                                operand1: +id,
                                                                operator: "equals"
                                                            }
                                                        })
                                                    }}
                                                    onRowClick={undefined}
                                                    onClickCreateButton={undefined} // что бы не показывать кнопку создания
                                                    // onRowClick={async (record) => {
                                                    //
                                                    //     await setValue(record)
                                                    //     handleClose()
                                                    // }}
                                                />
                                            </Box>

                                        </Box>
                                    </>
                                )}
                            </Box>
                        </CardContent>
                    </Card>
                </Modal>}
            </>
        );
    }, [RenderDrawer, RenderSearch, disabled, drawer, handleSelectAllServicesInPackage, id, open, searchDrawer, setValue])
};

SelectFieldModalGroup.defaultProps = {
    type: "text"
}


export default SelectFieldModalGroup;
