import { Button, Dialog, DialogContent, Divider } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import LegalPersonCRUDTable from "../Crud/Tables/LegalPersonCRUDTable";
import ClientCRUDTable from "../Crud/Tables/ClientCRUDTable";

export default function ClientField({
                                          label,
                                          value,
                                          object,
                                          onChange,
                                          extraFilter,
                                          disabled,
                                          sx
                                      }) {
    const [open, setOpen] = useState(false);
    const [name, setName] = useState('')
    return (
        <>
            <Grid container spacing={1}>
                {/* Field Line */}
                <Grid item xs={12}>
                    <Toolbar sx={sx}>
                        <Typography style={{ fontWeight: "bold", width: "50%" }}>
                            {label || "Клиент"}:{" "}
                        </Typography>
                        <Typography>
                            {name || object?.name || `id клиента: ${value || 'не указано'}`}
                        </Typography>
                        <Button disabled={disabled} onClick={() => setOpen(true)} variant={"outlined"} style={{ marginLeft: "10px", outline: 'none' }}>Выбрать</Button>
                    </Toolbar>
                </Grid>
            </Grid>
            {/* Dialog Select*/}
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                fullWidth
                PaperProps={{sx: {maxHeight: '85%'}}}
                maxWidth={"80%"}
            >
                <DialogTitle>Выбор клиента</DialogTitle>
                <Divider />
                <DialogContent>
                    <ClientCRUDTable
                        edit={false}
                        extraFilter={extraFilter}
                        onClickCreateButton={undefined} // что бы не показывать кнопку создания
                        onRowClick={(record, rowIndex) => {
                            onChange(record);
                            setName(record?.legalPerson?.name ? record?.legalPerson?.name : record?.person?.fullName)
                            setOpen(false);
                        }}
                    />
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button
                        variant={"text"}
                        style={{ marginLeft: "auto" }}
                        onClick={() => setOpen(false)}
                    >
                        Отменить
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
