import React from 'react';
import Dashboard from "../../layouts/Dashboard";
import {TableCell, TableRow} from "@mui/material";
import TableCustomVariantTwo from "../../components/TableCustom/TableCustomVariantTwo";
import {STATUS_ROUTE} from "../../routes/const";
import BreadcrumbsShared from "../../components/Shared/BreadcrumbsShared";

function Status(props) {
    return (
        <Dashboard>
            <BreadcrumbsShared breadcrumbs={[
                {text: 'Статус договоров', link: STATUS_ROUTE}
            ]}/>
            <TableCustomVariantTwo name="Статус договоров" header={<React.Fragment>
                <TableRow>
                    <TableCell align="center" colSpan={1}>
                        №
                    </TableCell>
                    <TableCell align="center" colSpan={1}>
                        Филиал/Отделение
                    </TableCell>
                    <TableRow>
                        <TableRow>
                            <TableCell align="center" colSpan={2}>
                                Количество договоров
                            </TableCell>
                            <TableCell align="center" colSpan={2}>
                                Сумма договоров
                            </TableCell>
                            <TableCell align="center" colSpan={3}>
                                Сумма поступлений
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableRow>
                                <TableCell rowSpan={1} colSpan={2} align="center">
                                    тендер
                                </TableCell>
                                <TableCell rowSpan={1} colSpan={2} align="center">
                                    новые клиенты
                                </TableCell>
                                <TableCell rowSpan={1} colSpan={2} align="center">
                                    текущие клиенты
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center">
                                    Количество
                                </TableCell>
                                <TableCell align="center">
                                    %
                                </TableCell>
                                <TableCell align="center">
                                    Количество
                                </TableCell>
                                <TableCell align="center">
                                    %
                                </TableCell>
                                <TableCell align="center">
                                    Количество
                                </TableCell>
                                <TableCell align="center">
                                    %
                                </TableCell>
                            </TableRow>
                            <TableCell align="center" colSpan={1}>
                                Всего
                            </TableCell>
                            <TableRow>
                                <TableCell rowSpan={1} colSpan={2} align="center">
                                    тендер
                                </TableCell>
                                <TableCell rowSpan={1} colSpan={2} align="center">
                                    новые клиенты
                                </TableCell>
                                <TableCell rowSpan={1} colSpan={2} align="center">
                                    текущие клиенты
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center">
                                    Количество
                                </TableCell>
                                <TableCell align="center">
                                    %
                                </TableCell>
                                <TableCell align="center">
                                    Количество
                                </TableCell>
                                <TableCell align="center">
                                    %
                                </TableCell>
                                <TableCell align="center">
                                    Количество
                                </TableCell>
                                <TableCell align="center">
                                    %
                                </TableCell>
                            </TableRow>
                            <TableCell align="center" colSpan={1}>
                                Всего
                            </TableCell>
                            <TableCell align="center" colSpan={1}>
                                от договоров
                            </TableCell>
                            <TableCell align="center" colSpan={1}>
                                от населения
                            </TableCell>
                            <TableCell align="center" colSpan={1}>
                                Всего
                            </TableCell>
                        </TableRow>
                        <TableCell align="center" colSpan={1}>
                            Изменение суммы по договорам
                        </TableCell>
                        <TableCell align="center" colSpan={1}>
                            Сумма остатка реализации по договорам
                        </TableCell>
                        <TableCell align="center" colSpan={1}>
                            Дебиторская задолженность
                        </TableCell>
                    </TableRow>
                </TableRow>
            </React.Fragment>}/>
        </Dashboard>
    );
}

export default Status;