import React, {createContext, useEffect, useState} from 'react';
import DashboardLayout from "../../layouts/Dashboard";
import Box from "@mui/material/Box";
import {
    Button, Checkbox, CircularProgress,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    Paper, Radio,
    RadioGroup,
    Stack,
    Switch
} from "@mui/material";
import {
    DatePickerShared,
    PopupLegalClient,
    PopupPerson,
    TableMin
} from "../../components";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {getRequestSection} from "../../redux/actions/request/reqestActions";
import Typography from "@mui/material/Typography";
import {ContractRequestObject, EducationServicesTableContractRequest} from "../index";
import {
    getEducationService,
} from "../../redux/actions/educationService/educationServiceActions";
import {v4 as uuidv4} from "uuid";
import {getGeoObject} from "../../redux/actions/geo/geoActions";
import {
    getContractsPayState,
    getContractStates,
    getOneContract
} from "../../redux/actions/contract/contractActions";
import ContractRequestContract from "./Add/ContractRequestContract";
import ContractRequestAdditionallyInfo from "./Add/ContractRequestAdditionallyInfo";
import Status from "../../components/Status/Status";
import {addContractRequest} from "../../redux/actions/contractRequest/contractRequestActions";
import {useHistory} from "react-router-dom";
import {CONTRACT_REQUESTS_ADD_ROUTE, CONTRACT_REQUESTS_ROUTE} from "../../routes/const";
import {LoadingButton} from "@mui/lab";
import BreadcrumbsShared from "../../components/Shared/BreadcrumbsShared";
import ResearchSchedule from "../../components/Contract/ResearchSchedule";
import {ContractRequestContext} from "./ContractRequestEdit";
import PersonField from "../../components/Fields/PersonField";
import ContractField from "../../components/Fields/ContractField";
import EmployeeField from "../../components/Fields/EmployeeField";
import LegalClientField from "../../components/Fields/LegalClientField";
import {getAnObjectKind, getTransportType} from "../../redux/actions/anObject/anObjectActions";
import {getUnitOfMeasure} from "../../redux/actions/genericActions";

export const ContractRequestAddContext = createContext()

function ContractRequestAdd() {
    const [number] = useState(1)
    const [createdAt, setCreatedAt] = useState(new Date())
    const dispatch = useDispatch()

    const [client, setClient] = useState('')
    const [legalClient, setLegalClient] = useState('')
    const [typeClient, setTypeClient] = useState(false)
    const history = useHistory()

    const [educationServices, setEducationServices] = useState([])
    const [localEducationServiceKind] = useState('')
    const {educationServiceKind} = useSelector((state) => state.education);

    const [contractId, setContractId] = useState('')

    const [contractType, setContractType] = useState(1)
    const [paymentPassed, setPaymentPassed] = useState(false)
    const [signBySystem, setSignBySystem] = useState(true)

    const [contractRequestObject, setContractRequestObject] = useState([])
    const {geo} = useSelector((state) => state.geo);

    const [localEmployee, setLocalEmployee] = useState('')
    const [localRequestSection, setLocalRequestSection] = useState(1)
    const [localEmployeeResponsible, setLocalEmployeeResponsible] = useState('')

    // Договор
    const [oneContract, setOneContract] = useState({})
    const [contractNumber, setContractNumber] = useState('')
    const [contractActionAt, setContractActionAt] = useState(Date.now())
    const [contractState, setContractState] = useState(1)
    const [contractFilial, setContractFilial] = useState(1)
    const [contractSummary, setContractSummary] = useState(1)
    const [contractFilialDivision, setContractFilialDivision] = useState(1)
    const [researchCount, setResearchCount] = useState(1)
    const [paySummary, setPaySummary] = useState(1)
    const [realizationSummary, setRealizationSummary] = useState(1)
    const [contractPaySummary, setContractPaySummary] = useState(1)
    const [contractPaySummaryRemainder, setContractPaySummaryRemainder] = useState(1)
    const [contractPayState, setContractPayState] = useState(1)

    // Специфики
    const [specification, setSpecification] = useState([])
    const [localServiceKind, setLocalServiceKind] = useState(1)
    const [unitOfMeasure, setUnitOfMeasure] = useState(1)
    const {unityOfMeasure, serviceKind} = useSelector((state) => state.generic);
    const {anObjects, anObjectsCount} = useSelector((state) => state.anObject);
    const {servicesSchedule, servicesScheduleCount} = useSelector((state) => state.service);
    const [anObjectsLocal, setAnObjectsLocal] = useState(1)
    const [servicesScheduleLocal, setServicesScheduleLocal] = useState(1)
    const [service, setService] = useState(1)
    const {services, servicesCount} = useSelector((state) => state.service);
    const [qty, setQty] = useState(1)
    const [loadingAdd, setLoadingAdd] = useState(false)

    const [objectKind, setObjectKind] = useState(1)
    const [contractDeadline, setContractDeadline] = useState(new Date());
    const [departureOfSpecialists, setDepartureOfSpecialists] = useState(true)
    const [personTransportType, setPersonTransportType] = useState(1)
    const [legalPersonTransportType, setLegalPersonTransportType] = useState(1)
    const {contractsState, contractsPayState} = useSelector((state) => state.contract);
    const {filial, filialDivision} = useSelector((state) => state.filial);
    const [openPerson, setOpenPerson] = useState(false);
    const [openLegalClient, setOpenLegalClient] = useState(false);

    const handleDeleteSpec = async (row) => {
        const newSpec = specification.filter(el => el.Id !== row)
        await setSpecification(newSpec);
    }

    const [loading, setLoading] = useState(false)


    useEffect(() => {
        (async function () {
            setLoading(true)
            let contractsPayState
            const resReqSection = await dispatch(getRequestSection())
            await dispatch(getEducationService())
            await dispatch(getUnitOfMeasure(1))
            await dispatch(getGeoObject(1, 100000, {
                filter: {
                    typeId: {
                        operand1: 2,
                        operator: "equals"
                    },
                }
            }))
            contractsPayState = await dispatch(getContractsPayState())
            setContractPayState(contractsPayState[0].id)
            setLocalRequestSection(resReqSection.result[0]?.id)
            setLoading(false)
        }())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        (async function () {
            if (contractId) {
                const res = await dispatch(getOneContract(contractId))
                setOneContract(res)
                setContractNumber(res?.number)
                setTypeClient(res?.client?.personId)
                if (res?.client?.personId) {
                    setClient(res?.client?.personId)
                } else if (res?.client?.legalPersonId) {
                    setLegalClient(res?.client?.legalPersonId)
                }
                setResearchCount(res.specifications?.map(item => item.quantity).reduce((prev, curr) => prev + curr, 0))
                setSpecification(res.specifications?.map(el => {
                    return {
                        Id: uuidv4(),
                        service: el.service,
                        object: el.object,
                        serviceId: el.serviceId,
                        unit: el.unit,
                        unitId: el.unitId,
                        quantity: el.quantity,
                        count: el.quantity,
                        price: el.price,
                        objectId: el.objectId,
                        scheduleId: el.scheduleId,
                        schedule: el.schedule,
                        contractCount: el.quantity,
                        square: 1,
                        multiplicity: 1
                    }
                }))
                setContractRequestObject(res?.objects.map(el => {
                    return {
                        Id: uuidv4(),
                        name: el.name,
                        district: '',
                        districtId: el?.districtId,
                        city: '',
                        cityId: el?.cityId,
                        locality: '',
                        localityId: el?.localityId,
                        addressLine: el?.addressLine,
                        objectId: el?.objectId,
                        object: el?.object,
                    }
                }))
                setEducationServices(res?.educationServices.map(el => {
                    return {
                        Id: uuidv4(),
                        kind: '',
                        kindId: el?.kindId,
                        service: '',
                        serviceId: el?.serviceId
                    }
                }))
                setSchedules(res?.schedules.map(el => {
                    return {
                        Id: uuidv4(),
                        targetMonth: el.targetMonth,
                        targetYear: el.targetYear,
                    }
                }))
            }
        }())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contractId])
    const {anObjectKind, transportType} = useSelector((state) => state.anObject);

    useEffect(() => {
        if (!signBySystem) {
            dispatch(getAnObjectKind(1, 100090))
            dispatch(getTransportType(1, 100000))
        }
    }, [dispatch, signBySystem])


    const handleEducationServices = () => {
        const educationServiceKindIdx = educationServiceKind.findIndex(el => el.id === localEducationServiceKind)
        const newElement = {
            Id: uuidv4(),
            serviceKind: educationServiceKind[educationServiceKindIdx]?.name,
            serviceKindId: localEducationServiceKind,
            service: '',
            serviceId: '',
        }
        setEducationServices(oldArray => [...oldArray, newElement])
    }

    const handleContractRequest = () => {
        const newElement = {
            Id: uuidv4(),
            name: '',
            district: '',
            districtId: '',
            city: '',
            cityId: '',
            locality: '',
            localityId: '',
            addressLine: '',
            objectId: ''
        }
        setContractRequestObject(oldArray => [...oldArray, newElement])
    }

    const handleFormSpec = async (e) => {
        e.preventDefault()
        const newElement = {
            Id: uuidv4(),
            service: {},
            serviceId: '',
            unitId: '',
            unit: {},
            quantity: qty,
            square: 1,
            multiplicity: 1,
            contractCount: 0,
            price: 0,
            count: 0,
            objectId: '',
            scheduleId: '',
        }
        setSpecification(oldArray => [...oldArray, newElement])
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        setLoadingAdd(true)
        await dispatch(addContractRequest(educationServices, specification, contractRequestObject, number, createdAt, contractType, paymentPassed, signBySystem, contractNumber,
            contractActionAt, researchCount, paySummary, contractSummary, realizationSummary, contractPaySummary, contractPaySummaryRemainder, contractDeadline,
            departureOfSpecialists, typeClient ? client : legalClient, localEmployee, localEmployeeResponsible, localRequestSection, contractId,
            contractState, contractFilial, contractFilialDivision, contractPayState, !signBySystem ? objectKind : null, !signBySystem ? personTransportType : null, !signBySystem ? legalPersonTransportType : null, contractFilial, typeClient ? 'personId' : 'legalPersonId', schedules))
        setLoadingAdd(false)
        history.push(CONTRACT_REQUESTS_ROUTE)
    }

    const [schedules, setSchedules] = useState([])
    const [localTargetMonth] = useState(new Date())
    const [localTargetYear] = useState(new Date())

    const handleSchedules = () => {
        const newElement = {
            Id: uuidv4(),
            targetMonth: localTargetMonth,
            targetYear: localTargetYear,
        }
        setSchedules(oldArray => [...oldArray, newElement])
    }

    return (
        <DashboardLayout>
            <BreadcrumbsShared breadcrumbs={[
                {text: 'Сводные заявки', link: CONTRACT_REQUESTS_ROUTE},
                {text: 'Добавление сводной заявки', link: CONTRACT_REQUESTS_ADD_ROUTE},
            ]}/>
            <Status status="Создается"/>
            {loading ? <Box sx={{
                    minHeight: 556,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}><CircularProgress/></Box> :
                <Box component="form" onSubmit={handleSubmit}>
                    <Box component={Paper}
                         sx={{mt: 5, display: 'flex', padding: '0px 10px', flexWrap: 'wrap'}}>
                        <Grid sx={{paddingTop: 2, paddingBottom: 2}} container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Договор</FormLabel>
                                    <RadioGroup
                                        aria-label="gender"
                                        name="controlled-radio-buttons-group"
                                        value={contractType}
                                        onChange={(event => setContractType(event.target.value))}
                                    >
                                        <FormControlLabel value={1} control={<Radio/>} label="ФЛ или разовые ЮЛ и ИП"/>
                                        <FormControlLabel value={2} control={<Radio/>} label="ЮЛ и ИП"/>
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormGroup sx={{display: 'flex', justifyContent: 'center'}}>
                                    <Typography>Отправлять договор на подписание через систему</Typography>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <Typography>Да</Typography>
                                        <Switch onChange={(event) => setSignBySystem(event.target.checked)}
                                                checked={signBySystem}
                                                inputProps={{'aria-label': 'ant design'}}/>
                                        <Typography>Нет</Typography>
                                    </Stack>
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12}>
                                <ContractField
                                    fullWidth
                                    value={contractId}
                                    onChange={(contract) => {
                                        setContractId(contract.id)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox checked={paymentPassed}
                                                                         onChange={(event) => setPaymentPassed(event.target.checked)}/>} label="Оплата прошла"/>
                                </FormGroup>
                            </Grid>

                        </Grid>
                    </Box>
                    {!signBySystem &&
                    <Box component={Paper} sx={{mt: 5, display: 'flex', padding: '0px 10px', flexWrap: 'wrap'}}>
                        <ContractRequestAdditionallyInfo anObjectKind={anObjectKind} transportType={transportType} setLegalPersonTransportType={setLegalPersonTransportType}
                                                         legalPersonTransportType={legalPersonTransportType}
                                                         dispatch={dispatch} signBySystem={signBySystem}
                                                         setPersonTransportType={setPersonTransportType}
                                                         personTransportType={personTransportType}
                                                         setDepartureOfSpecialists={setDepartureOfSpecialists}
                                                         departureOfSpecialists={departureOfSpecialists}
                                                         setContractDeadline={setContractDeadline}
                                                         contractDeadline={contractDeadline}
                                                         setObjectKind={setObjectKind}
                                                         objectKind={objectKind}/>
                    </Box>}
                    <Box component={Paper}
                         sx={{mt: 5, display: 'flex', padding: '0px 10px', flexWrap: 'wrap'}}>
                        <Typography sx={{margin: '5px 0'}} component="h1" align="center" variant="h5">
                            Сводная заявка
                        </Typography>
                        <Grid sx={{paddingTop: 2, paddingBottom: 2}} container spacing={2}>
                            <Grid item xs={6}>
                                <DatePickerShared value={createdAt} setValue={setCreatedAt}
                                                  label="Дата время создания"/>
                            </Grid>
                            <Grid item xs={6}>
                                <EmployeeField
                                    fullWidth
                                    value={localEmployee}
                                    label="Исполнитель заявки"
                                    onChange={(employee) => {
                                        setLocalEmployee(employee.id)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <EmployeeField
                                    fullWidth
                                    value={localEmployeeResponsible}
                                    label="Ответственный за отбор проб/Специалист ЦД или отдела Дезинфекции"
                                    onChange={(employee) => {
                                        setLocalEmployeeResponsible(employee.id)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormGroup sx={{display: 'flex', justifyContent: 'center'}}>
                                    <Typography>Тип клиента</Typography>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <Typography>юр. лицо</Typography>
                                        <Switch onChange={(event) => setTypeClient(event.target.checked)}
                                                checked={typeClient}
                                                inputProps={{'aria-label': 'ant design'}}/>
                                        <Typography>физ. лицо</Typography>
                                    </Stack>
                                </FormGroup>
                            </Grid>
                            <Grid item xs={6}>
                                {typeClient ?
                                    <PersonField
                                        fullWidth
                                        value={client}
                                        object={oneContract?.client?.person}
                                        label="Контакт (физ. лицо)"
                                        sx={{paddingTop: '35px'}}
                                        onChange={(client) => {
                                            setClient(client.id)
                                        }}
                                    /> :
                                    <LegalClientField
                                        fullWidth
                                        object={oneContract?.client?.legalPerson}
                                        value={legalClient}
                                        label="Контакт (юр. лицо)"
                                        sx={{paddingTop: '35px'}}
                                        onChange={(client) => {
                                            setLegalClient(client.id)
                                        }}
                                    />}
                            </Grid>
                            <Grid item xs={6} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                {typeClient ?
                                    <Button onClick={() => setOpenPerson(true)} color="info" variant="contained">Добавить
                                        физ лицо</Button> :
                                    <Button onClick={() => setOpenLegalClient(true)} color="info" variant="contained">Добавить
                                        юр. лицо</Button>}
                            </Grid>
                        </Grid>
                    </Box>
                    <Box component={Paper} sx={{marginTop: 5}}>
                        <EducationServicesTableContractRequest setEducationServices={setEducationServices}
                                                               educationServiceKind={educationServiceKind}
                                                               localEducationServiceKind={localEducationServiceKind}
                                                               educationServices={educationServices}/>
                        <Button onClick={handleEducationServices}>Добавить блок</Button>
                    </Box>
                    <Box component={Paper} sx={{marginTop: 5}}>
                        <ContractRequestContext.Provider value={{anObjectsCount, anObjects}}>
                            <ContractRequestObject geo={geo} contractRequestObject={contractRequestObject}
                                                   setContractRequestObject={setContractRequestObject}/>
                        </ContractRequestContext.Provider>
                        <Button onClick={handleContractRequest}>Добавить блок</Button>
                    </Box>
                    <Box component={Paper} sx={{mt: 5, display: 'flex', padding: '0px 10px', flexWrap: 'wrap'}}>
                        <ContractRequestContract filial={filial} filialDivision={filialDivision}
                                                 contractsState={contractsState} contractsPayState={contractsPayState}
                                                 setContractPaySummary={setContractPaySummary}
                                                 contractPaySummary={contractPaySummary}
                                                 contractSummary={contractSummary}
                                                 setContractSummary={setContractSummary} dispatch={dispatch}
                                                 contractNumber={contractNumber} setContractNumber={setContractNumber}
                                                 oneContract={oneContract}
                                                 contractActionAt={contractActionAt}
                                                 setContractActionAt={setContractActionAt} contractState={contractState}
                                                 setContractState={setContractState}
                                                 contractFilial={contractFilial} setContractFilial={setContractFilial}
                                                 contractFilialDivision={contractFilialDivision}
                                                 setContractFilialDivision={setContractFilialDivision}
                                                 researchCount={researchCount} setResearchCount={setResearchCount}
                                                 paySummary={paySummary} setPaySummary={setPaySummary}
                                                 realizationSummary={realizationSummary}
                                                 setRealizationSummary={setRealizationSummary}
                                                 contractPaySummaryRemainder={contractPaySummaryRemainder}
                                                 setContractPaySummaryRemainder={setContractPaySummaryRemainder}
                                                 contractPayState={contractPayState}
                                                 setContractPayState={setContractPayState}/>
                    </Box>
                    <Box component={Paper} sx={{mt: 5, display: 'flex', padding: '0px 10px', flexWrap: 'wrap'}}>
                        <ContractRequestAddContext.Provider
                            value={{setSchedules, schedules, localTargetYear, localTargetMonth}}>
                            <ResearchSchedule/>
                        </ContractRequestAddContext.Provider>
                        <Button onClick={handleSchedules}>Добавить блок</Button>
                    </Box>
                    <Box component={Paper} sx={{mt: 5, display: 'flex', padding: '0px 10px', flexWrap: 'wrap', overflowX: 'scroll'}}>
                        <TableMin count edit={true} servicesCount={servicesCount} handleDeleteSpec={handleDeleteSpec}
                                  unityOfMeasure={unityOfMeasure} setUnitOfMeasure={setUnitOfMeasure}
                                  unitOfMeasure={unitOfMeasure}
                                  services={services.map(el => ({id: el.id, name: el.name, price: el.price}))}
                                  setService={setService} service={service} serviceKind={serviceKind}
                                  setLocalServiceKind={setLocalServiceKind} localServiceKind={localServiceKind}
                                  setQty={setQty} qty={qty} setServicesScheduleLocal={setServicesScheduleLocal}
                                  servicesScheduleLocal={servicesScheduleLocal} setAnObjectsLocal={setAnObjectsLocal}
                                  anObjectsLocal={anObjectsLocal} servicesScheduleCount={servicesScheduleCount}
                                  servicesSchedule={servicesSchedule} anObjectsCount={anObjectsCount}
                                  anObjects={anObjects}
                                  setSpecification={setSpecification} specification={specification}/>
                        <Button onClick={handleFormSpec}>Добавить блок</Button>
                    </Box>
                    <LoadingButton
                        loading={loadingAdd}
                        type="submit"
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                    >
                        Создать заявку
                    </LoadingButton>
                </Box>}
            <PopupLegalClient details={true} title="Клиенты (юр. лица)" open={openLegalClient}
                              setOpen={setOpenLegalClient}/>
            <PopupPerson details={true} title="Клиенты (физ. лица)" open={openPerson}
                         setOpen={setOpenPerson}/>
        </DashboardLayout>
    );
}

export default ContractRequestAdd;
