import React, {useEffect, useState} from 'react';
import {
    Box,
    Grid,
    Popover,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tabs,
    TextField
} from "@mui/material";
import {useTranslation} from "react-i18next";
import Tab from "@mui/material/Tab";
import Button from "@mui/material/Button";
import {FileCard, PopupContract, TableTabLegalClient} from "../index";
import {useDispatch, useSelector} from "react-redux";
import {getOnePerson} from "../../redux/actions/client/personActions";
import {getOneLegalClient} from "../../redux/actions/client/legalClientActions";
import moment from "moment";
import 'moment/locale/ru'
import TableTabPerson from "./TableTabPerson";
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import TableTabSigners from "./TableTabSigners";
import PopupContractComment from "./PopupContractComment";
import {changeContractState, getOneContract} from "../../redux/actions/contract/contractActions";
import {checkRoles} from "../../functions/checkRoles";
import {useNCALayerClient} from "../../hooks/ecp/SignHooks";
import {contractRequestCRUD, contractSignerCRUD} from "../../http/CRUD";
import {LoadingButton} from "@mui/lab";
import useLoader from "../../hooks/useLoader";
import {showSuccessSnackbar} from "../../redux/actions/userActions";
import Typography from "@mui/material/Typography";
import {useHistory} from "react-router-dom";
import { CONTRACT_REQUESTS_ROUTE} from "../../routes/const";
import {$authHost} from "../../http";

window.Buffer = window.Buffer || require("buffer").Buffer;

moment.locale('ru')

function TableTab({rowsPage, locale, reload, reloadValue}) {

    const {t} = useTranslation();
    const [client, setClient] = useState([])
    const dispatch = useDispatch()
    const [contractRequest, setContractRequest] = useState([])
    const [value, setValue] = useState(0);
    const [open, setOpen] = useState(false)
    const [openComment, setOpenComment] = useState(false)
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        contractRequestCRUD.search(
            {
                paging: {skip: 0},
                filter: {
                    contractId: {
                        operand1: rowsPage?.number,
                        operator: "equals"
                    }
                }
            }
        ).then(({result}) => {
            setContractRequest(result)
        })
    }, [])

    const [clientNCA] = useNCALayerClient();

    useEffect(() => {
        if (!clientNCA)
            return;

        clientNCA.onmessage = function (msg) {
            console.log(msg)
            if (msg?.result === true) {
                dispatch(showSuccessSnackbar("Договор валидный", "success"))
            }
        };

    }, [clientNCA])

    const rowName = [
        {text: t("contractRequest.tableItemRequestObject.name")},
        {text: t("contractRequest.tableItemRequestObject.district")},
        {text: t("contractRequest.tableItemRequestObject.city")},
        {text: t("contractRequest.tableItemRequestObject.locality")},
        {text: t("contractRequest.tableItemRequestObject.address")},
        {text: t("contractRequest.tableItem.actions")},
    ]

    const rowName2 = [
        {text: t("specification.tableItem.service")},
        {text: t("specification.tableItem.unitOfMeasure")},
        {text: t("specification.tableItem.qty")},
        {text: t("specification.tableItem.price")},
        {text: t("specification.tableItem.anObject")},
        {text: t("specification.tableItem.servicesSchedule")},
        {text: t("specification.tableItem.actions")},
    ]

    const rowName3 = [
        {text: t("contract.tableItemSchedule.targetMonth")},
        {text: t("contract.tableItemSchedule.targetYear")},
        {text: t("contractRequest.tableItem.actions")},
    ]

    const [conform, setConform] = useState(false)

    const handleClose = () => {
        setOpen(false)
    }
    const handleCloseComment = () => {
        setOpenComment(false)
    }


    const isAdmin = checkRoles("SuperAdministrator", "Administrator")
    const {loading, start, stop} = useLoader(false);

    const {profile} = useSelector((state) => state.profile);

    useEffect(() => {
        (async function () {
            const personId = rowsPage?.client?.personId
            const legalPersonId = rowsPage?.client?.legalPersonId
            if (personId) {
                const data = await dispatch(getOnePerson(personId))
                setClient({...data, typeClient: 1})
            } else if (legalPersonId) {
                const data = await dispatch(getOneLegalClient(legalPersonId))
                setClient({...data, typeClient: 2})
            }
        }())
    }, [dispatch, rowsPage]);

    const [oneContract, setOneContract] = useState([])

    useEffect(() => {
        (async function () {
            const res = await dispatch(getOneContract(rowsPage?.number))
            setOneContract(res)
        }())
    }, [dispatch, rowsPage?.number])

    const handleCheckContract = async () => {
        if (lastElement) {
            start()
            const data = await contractSignerCRUD.get(lastElement.id)
            const jsonData = JSON.parse(data?.sign);
            await clientNCA.verifyXml(jsonData?.SignedXml)
            stop()
        }
    }

    const {push} = useHistory()

    const lastSigned = React.useMemo(() => {
        return oneContract?.signers?.find(el => el.lastSigned) || null
    }, [oneContract?.signers])

    const lastElement = React.useMemo(() => {
        return oneContract?.signers?.slice(-1)[0] || null
    }, [oneContract?.signers])

    const handleRecreationContract = async () => {
        await dispatch(changeContractState(rowsPage?.number, 7, 1))
        reload()
    }

    useEffect(() => {
        rowsPage.signers.forEach(el => {
            if (isAdmin && rowsPage.status === 'На согласовании' && el.isSigned !== true) {
                setConform(true)
            } else if ((el?.employee?.userId === profile?.userId || el?.userId === profile?.userId) && rowsPage.status === 'На согласовании' && el.isSigned !== true) {
                setConform(true)
            }
        })
    }, [profile?.userId, rowsPage.signers, rowsPage.status, reload, reloadValue])

    const handleTerminateContract = async () => {
        await dispatch(changeContractState(rowsPage?.number, 4, 8, comment))
        reload()
        await dispatch(showSuccessSnackbar("Договор расторгнут", "success"))
    }

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const openPopover = Boolean(anchorEl);
    const idPopover = open ? 'simple-popover' : undefined;

    const [comment, setComment] = useState('')

    return (
        <Box sx={{padding: '0 20px'}}>
            <Tabs
                value={value}
                onChange={handleChange}
            >
                <Tab label="Основная информация"/>
                <Tab label="Клиент"/>
                <Tab label="Договор"/>
                <Tab label="Объекты"/>
                <Tab label="Услуги"/>
                <Tab label="График исследований"/>
                {(rowsPage?.typeId === 1 || rowsPage?.typeId === 2) && <Tab label="Согласующие"/>}
            </Tabs>
            {value === 0 && <>
                {Object.keys(rowsPage).slice(0, 8).map((el, idx) => (
                    <Grid container key={idx}>
                        <Grid item xs={6}>
                            <Box sx={{
                                padding: '5px 0',
                                borderTop: "1 px",
                                borderBottom: '1px'
                            }}>{t(`${locale}.tableItem.${[el]}`)}</Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box sx={{
                                padding: '5px 0',
                                borderTop: "1 px",
                                borderBottom: '1px'
                            }}>{rowsPage[el] ? rowsPage[el] : 'Значение не задано'}</Box>
                        </Grid>
                    </Grid>
                ))}
                <Box mt={2} mb={2}>
                    <Typography gutterBottom variant={'h6'}>Сводные заявки:</Typography>
                    <table>
                        {contractRequest?.length ? contractRequest.map(_ => <tr>
                            <td onClick={() => {
                                console.log(_)
                                push(CONTRACT_REQUESTS_ROUTE + `/${_.id}`)
                            }}>{_.number}</td>
                        </tr>) : 'Отсутствуют'}
                    </table>
                </Box>

                {conform && <Button onClick={() => setOpenComment(true)}
                                    sx={{margin: '10px 0 10px 10px'}} color="success" variant="outlined"
                                    startIcon={<CompareArrowsIcon/>}>
                    Согласовать
                </Button>}
                {rowsPage.status === 'Подписан' && <Button
                    sx={{margin: '10px 0 10px 10px'}} color="warning" variant="outlined">
                    Отправить в бухглатерию
                </Button>}
                {rowsPage.status === 'Подписан' && <LoadingButton loading={loading} onClick={handleCheckContract}
                                                                  sx={{margin: '10px 0 10px 10px'}} color="info"
                                                                  variant="outlined">
                    Проверить валидность договора
                </LoadingButton>}
                {rowsPage.status === 'Подписан' && <LoadingButton loading={loading} onClick={handleClick}
                                                                  sx={{margin: '10px 0 10px 10px'}} color="error"
                                                                  variant="outlined">
                    Расторгнуть
                </LoadingButton>}
                <Popover
                    id={idPopover}
                    open={openPopover}
                    anchorEl={anchorEl}
                    onClose={handleClosePopover}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}
                >
                    <Stack direction="column" spacing={2} sx={{padding: 3, width: '350px'}}>
                        <TextField
                            multiline
                            label={"Комментарий"}
                            onChange={e => setComment(e.target.value)}
                            fullWidth
                            value={comment}
                        />
                        <Button onClick={handleTerminateContract} fullWidth variant="contained" sx={{mt: 3}}>
                            {'Подтвердить'}
                        </Button>
                    </Stack>
                </Popover>
                {rowsPage.status === 'Отказано' && <LoadingButton loading={loading} onClick={handleRecreationContract}
                                                                  sx={{margin: '10px 0 10px 10px'}} color="warning"
                                                                  variant="outlined">
                    Пересоздать
                </LoadingButton>}
            </>
            }
            {value === 1 && <>
                {client && client.typeClient === 1 ? <TableTabPerson client={client}/> : client.typeClient === 2 ?
                    <TableTabLegalClient client={client}/> : <Box>Клиент не указан</Box>}
            </>}
            {value === 2 && <>
                <Grid
                    container
                    sx={{marginTop: 1, marginBottom: 2}}
                    spacing={3}
                >
                    <Grid
                        item
                        lg={4}
                        md={4}
                        sm={6}
                        xs={12}
                    >
                        <FileCard handleOpen={setOpen} data={oneContract}/>
                    </Grid>
                </Grid>
            </>}
            {value === 3 && <>
                <Grid
                    container
                    sx={{marginTop: 1, marginBottom: 2}}
                    spacing={3}
                >
                    <Table>
                        <TableHead>
                            <TableRow>
                                {rowName.slice(0, -1).map((el, idx) => {
                                    return <TableCell key={idx}
                                                      align="center">{el.text}</TableCell>
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {oneContract?.objects.length ? oneContract.objects.map(el => (
                                <TableRow key={el.id}>
                                    <TableCell align="center">
                                        {el?.object?.name || 'Не заполнено'}
                                    </TableCell>
                                    <TableCell align="center">
                                        {el?.district?.name || 'Не заполнено'}
                                    </TableCell>
                                    <TableCell align="center">
                                        {el?.city?.name || 'Не заполнено'}
                                    </TableCell>
                                    <TableCell align="center">
                                        {el?.locality?.name || 'Не заполнено'}
                                    </TableCell>
                                    <TableCell align="center">
                                        {el?.addressLine || 'Не заполнено'}
                                    </TableCell>
                                </TableRow>
                            )) : <TableCell colSpan={5}>Объектов нет</TableCell>}
                        </TableBody>
                    </Table>
                </Grid>
            </>}
            {value === 4 && <>
                <Grid
                    container
                    sx={{marginTop: 1, marginBottom: 2}}
                    spacing={3}
                >
                    <Table>
                        <TableHead>
                            <TableRow>
                                {rowName2.slice(0, -1).map((el, idx) => {
                                    return <TableCell key={idx}
                                                      align="center">{el.text}</TableCell>
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {oneContract?.specifications?.length ? oneContract?.specifications.map(el => (
                                <TableRow key={el.id}>
                                    <TableCell align="center">
                                        {el?.service?.name || 'Не заполнено'}
                                    </TableCell>
                                    <TableCell align="center">
                                        {el?.unit?.name || 'Не заполнено'}
                                    </TableCell>
                                    <TableCell align="center">
                                        {el?.quantity || 'Не заполнено'}
                                    </TableCell>
                                    <TableCell align="center">
                                        {el?.price || 'Не заполнено'}
                                    </TableCell>
                                    <TableCell align="center">
                                        {el?.object?.name || 'Не заполнено'}
                                    </TableCell>
                                    <TableCell align="center">
                                        {el?.schedule?.name || 'Не заполнено'}
                                    </TableCell>
                                </TableRow>
                            )) : <TableCell colSpan={5}>Услуги не заполнены</TableCell>}
                        </TableBody>
                    </Table>
                </Grid>
            </>}
            {value === 5 && <>
                <Grid
                    container
                    sx={{marginTop: 1, marginBottom: 2}}
                    spacing={3}
                >
                    <Table>
                        <TableHead>
                            <TableRow>
                                {rowName3.slice(0, -1).map((el, idx) => {
                                    return <TableCell key={idx}
                                                      align="center">{el.text}</TableCell>
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {oneContract?.schedules?.length ? oneContract?.schedules.map(el => (
                                <TableRow key={el.id}>
                                    <TableCell align="center">
                                        {moment(el?.targetMonth).format('MMMM') || 'Не заполнено'}
                                    </TableCell>
                                    <TableCell align="center">
                                        {moment(el?.targetYear).format('YYYY') || 'Не заполнено'}
                                    </TableCell>
                                </TableRow>
                            )) : <TableCell colSpan={5}>График не заполнен</TableCell>}
                        </TableBody>
                    </Table>
                </Grid>
            </>}
            {(rowsPage?.typeId === 1 || rowsPage?.typeId === 2) && value === 6 &&
                <TableTabSigners reloadValue={reloadValue} reload={reload} number={rowsPage?.number}
                                 status={rowsPage?.status} signers={rowsPage?.signers}/>}
            <PopupContract lastSigned={lastSigned} rowsPage={rowsPage} typeId={rowsPage?.typeId} id={rowsPage?.number}
                           open={open}
                           handleClose={handleClose}/>
            <PopupContractComment lastSigned={lastSigned} data={oneContract} setConfrom={setConform} reload={reload}
                                  id={rowsPage?.number}
                                  open={openComment} handleClose={handleCloseComment}/>
        </Box>
    )
}

export default TableTab;
