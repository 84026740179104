import {getData, getOneData} from "../template/get";
import {
    EMPLOYEE_GET_AUTHORIZED_ERROR,
    EMPLOYEE_GET_AUTHORIZED_REQUEST, EMPLOYEE_GET_AUTHORIZED_SUCCESS,
    EMPLOYEE_GET_ERROR,
    EMPLOYEE_GET_REQUEST,
    EMPLOYEE_GET_SUCCESS
} from "../../constants/employee/employeeConstants";
import {employeeCRUD} from "../../../http/CRUD";

export const getEmployee = (page, size, args, nonGlobal) => async (dispatch) => {
    return await dispatch(getData(EMPLOYEE_GET_REQUEST, EMPLOYEE_GET_SUCCESS, EMPLOYEE_GET_ERROR, 'employee', true, page, null, null, true, size, args, nonGlobal))
};

export const getOneEmployee = (id) => async (dispatch) => {
    return await dispatch(getOneData(id, '', '', '', 'employee'))
};

export const getAuthorizedEmployee = () => async (dispatch) => {
    try {
        dispatch({
            type: EMPLOYEE_GET_AUTHORIZED_REQUEST,
        });

        const employeeId = JSON.parse(localStorage.getItem("employee")).id;
        if (!employeeId) throw Error("Authorized Employee Not Found!");

        const data = await employeeCRUD.get(employeeId);

        dispatch({
            type: EMPLOYEE_GET_AUTHORIZED_SUCCESS,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: EMPLOYEE_GET_AUTHORIZED_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};