import {$authHost} from "../../../http";

export const getData = (get, success, err, link, pagination, page, filterValue, filterName, nonresult, size, args, nonGlobal, sortName, operatorSort) => async (dispatch) => {
    try {
        dispatch({
            type: get,
        });
        if (size || page)
            pagination = true;
        const paging = {
            skip: 0,
            //take: 10,
            pageSize: size ? size : 10,
            pageNumber: page ? page : 1,
            returnCount: true
        }
        const filter =  {
            [filterName]: {
                operand1: filterValue,
                operator: "equals"
            }
        }
        const {data} = await $authHost.post(
            `api/v1/${link}/query`,
            {
                ...(args && { ...args }),
                ...(pagination && { paging }),
                ...(filterValue && { filter }),
                sort: {
                    [sortName ? sortName : 'id']: {
                        operator: operatorSort ? operatorSort : "desc"
                    }
                }
            }
        );
        !nonGlobal && dispatch({
            type: success,
            payload: nonresult ? data : data.result
        });
        return nonresult ? data : data.result
    } catch (error) {
        dispatch({
            type: err,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const getOneData = (id, get, success, err, link) => async (dispatch) => {
    try {
        dispatch({
            type: get,
        });
        const {data} = await $authHost.get(
            `api/v1/${link}/${id}`
        );
        dispatch({
            type: success,
            payload: data
        });
        return data
    } catch (error) {
        dispatch({
            type: err,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const getDataWithoutChild = (get, success, err, link, page, value) => async (dispatch) => {
    try {
        dispatch({
            type: get,
        });

        const {data} = await $authHost.post(
            `api/v1/${link}/query`,
            {
                paging: {
                    skip: 0,
                    take: 10,
                    pageSize: 10,
                    pageNumber: page,
                    returnCount: true
                },
                sort: {
                    id: {
                        operator: "asc"
                    }
                },
                filter: {
                    parentId: {
                        operand1: value,
                        operator: "equals"
                    }
                },
            }
        );
        dispatch({
            type: success,
            payload: data
        });
        return data
    } catch (error) {
        dispatch({
            type: err,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};



