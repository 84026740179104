import {
    ADD_DEAL_ERROR,
    ADD_DEAL_REQUEST, ADD_DEAL_SUCCESS,
    DELETE_DEAL_ERROR,
    DEAL_RELOAD,
    DELETE_DEAL_REQUEST, DELETE_DEAL_SUCCESS,
    GET_DEAL_ERROR,
    GET_DEAL_REQUEST, GET_DEAL_STAGE_ERROR, GET_DEAL_STAGE_REQUEST, GET_DEAL_STAGE_SUCCESS,
    GET_DEAL_SUCCESS, GET_ONE_DEAL_ERROR, GET_ONE_DEAL_REQUEST, GET_ONE_DEAL_SUCCESS
} from "../../constants/deal/dealConstants";

const initialState = {
    deals: [],
    dealOne: {},
    dealState: [],
    dealReload: null,
    loading: false,
    success: null,
    error: null
};

export const dealReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_DEAL_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_DEAL_SUCCESS:
            return {
                ...state,
                loading: false,
                deals: action.payload,
                success: true
            };
        case GET_DEAL_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case GET_ONE_DEAL_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_ONE_DEAL_SUCCESS:
            return {
                ...state,
                loading: false,
                dealOne: action.payload,
                success: true
            };
        case GET_ONE_DEAL_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case GET_DEAL_STAGE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_DEAL_STAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                dealState: action.payload,
                success: true
            };
        case GET_DEAL_STAGE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case DELETE_DEAL_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DELETE_DEAL_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true
            };
        case DELETE_DEAL_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case ADD_DEAL_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ADD_DEAL_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true
            };
        case DEAL_RELOAD:
            return {
                ...state,
                dealReload: action.payload
            };
        case ADD_DEAL_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        default:
            return state;
    }
}