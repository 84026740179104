import {$authHost} from "../../../http";
import {v4 as uuidv4} from "uuid";
import {getData, getOneData} from "../template/get";
import {
    CLIENT_REQUEST_ONE_ERROR,
    CLIENT_REQUEST_ONE_REQUEST,
    CLIENT_REQUEST_ONE_SUCCESS,
    CLIENT_REQUEST_REQUEST_ERROR,
    CLIENT_REQUEST_REQUEST_REQUEST,
    CLIENT_REQUEST_REQUEST_SUCCESS
} from "../../constants/clientRequest/clientRequestConstants";

export const getClientRequest = (page, size, args, nonGlobal) => async (dispatch) => {
    return await dispatch(getData(CLIENT_REQUEST_REQUEST_REQUEST, CLIENT_REQUEST_REQUEST_SUCCESS, CLIENT_REQUEST_REQUEST_ERROR, 'clientRequest', true, page, null, null, true, size, args, nonGlobal))
};

export const getOneClientRequest = (id) => async (dispatch) => {
    return await dispatch(getOneData(id, CLIENT_REQUEST_ONE_REQUEST, CLIENT_REQUEST_ONE_SUCCESS, CLIENT_REQUEST_ONE_ERROR, 'clientRequest'))
};

export const addClientRequest = (specifications, clientId, cityId, specificationsSummary) => async (dispatch) => {
    try {
        await $authHost.post(
            `api/v1/clientRequest`,
            {
                specifications: [
                    ...specifications
                ],
                number: uuidv4(),
                createdAt: new Date(),
                stateId: 1,
                clientId,
                cityId,
                specificationsSummary
            }
        );
    } catch (error) {
        console.log(error)
    }
};


export const deleteClientRequest = (id) => async (dispatch) => {
    try {

        await $authHost.delete(
            "/api/v1/clientRequest",
            {
                data: {
                    id
                }
            },
        );
        dispatch(getClientRequest())
    } catch (error) {
        dispatch({
            type: CLIENT_REQUEST_REQUEST_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
        throw new Error()
    }
}
