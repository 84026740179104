import React from 'react';
import {useTranslation} from "react-i18next";
import {DeleteForeverIconCustom} from "../../../components";
import {Box, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {EducationServicesTableContractRequestRow} from "../../index";
import ConfirmDialog from "../../../components/Popups/message/PopupDelete";

function EducationServicesTableContractRequest({edit, mode, educationServices, setEducationServices, localEducationServiceKind, educationServiceKind}) {
    const {t} = useTranslation();
    const rowName = [
        {text: t("contractRequest.tableItemEducationServices.kind")},
        {text: t("contractRequest.tableItemEducationServices.place")},
        {text: t("contractRequest.tableItem.actions")},
    ]
    const handleIcons = async (type, row) => {
        if (type === 'DeleteForeverIcon') {
            const newSpec = educationServices.filter(el => el.Id !== row)
            await setEducationServices(newSpec);
        }
    }
    const actions = [{icon: <DeleteForeverIconCustom/>, id: 'DeleteForeverIcon'}]
    return (
            <Table sx={{minWidth: '80%'}}>
                <TableHead>
                    <TableRow>
                        {(mode === 'edit' || !mode) ? rowName.map((el, idx) => {
                            return <TableCell key={idx}
                                              align="center">{el.text}</TableCell>
                        }) : rowName.slice(0, -1).map((el, idx) => {
                            return <TableCell key={idx}
                                              align="center">{el.text}</TableCell>
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {educationServices.length ? educationServices.map((row) => (
                            <EducationServicesTableContractRequestRow edit={edit} mode={mode} setEducationServices={setEducationServices} educationServices={educationServices} educationServiceKind={educationServiceKind} localEducationServiceKind={localEducationServiceKind} handleIcons={handleIcons} actions={actions} key={row.Id} row={row}/>)) :
                        <TableRow><TableCell align="center" colSpan={8}><Box>Записей пока
                            нет</Box></TableCell></TableRow>}
                </TableBody>
                <ConfirmDialog/>
            </Table>
    );
}

export default React.memo(EducationServicesTableContractRequest);