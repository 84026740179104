import React, {useState} from 'react';
import {
    Table,
    Paper,
    TableHead,
    TableRow,
    TableCell,
    Box,
    TableContainer, TablePagination, Alert, Snackbar, Card, CardHeader, CardContent, Tooltip, IconButton
} from "@mui/material";
import {TableRowCustomService} from "../index";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {useTranslation} from "react-i18next";
import ConfirmDialog from "../Popups/message/PopupDelete";
import {useLocation} from "react-router-dom";

const TableCustomService = ({
                                value,
                                rowName,
                                childTable,
                                typeRow,
                                locale,
                                rows,
                                rowsPage,
                                actions,
                                avatars,
                                setEdit,
                                page,
                                countRow,
                                setPage,
                                rowNameChild,
                                name
                            }) => {
    const {t} = useTranslation();

    const {pathname} = useLocation()
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [message, setTypeMessage] = useState('success')
    const handleClick = (type) => {
        if (type === 'error') {
            setTypeMessage('error')
        } else if (type === 'success') {
            setTypeMessage('success')
        }
        setOpenSnackBar(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <>
            <Card>
                <CardHeader
                    title={name ? name : 'Таблица'}
                    action={<Tooltip title="Все опции">
                        <IconButton
                            size="small"
                        >
                            <MoreVertIcon/>
                        </IconButton>
                    </Tooltip>}
                />
                <CardContent sx={{padding: '0', paddingBottom: '0 !important'}}>
                    <TableContainer component={Paper}>
                        <Table sx={{minWidth: '100%'}} size="big" aria-label="a dense table">
                            <TableHead sx={{backgroundColor: '#fafafa'}}>
                                <TableRow>
                                    {rowName.map((el, idx) => {
                                        return <TableCell key={idx}
                                                          align={idx !== 0 ? 'center' : 'left'}>{el.text}</TableCell>
                                    })}
                                </TableRow>
                            </TableHead>
                            {rows ? rows.map((row, idx) => (
                                <TableRowCustomService value={value}
                                                       rowName={rowNameChild}
                                                       childTable={
                                                           /* eslint-disable-next-line array-callback-return */
                                                           childTable && childTable.filter(el => el.parentId === row.id).map(el => {
                                                           if (pathname === '/service') {
                                                               return {
                                                                   id: el.id,
                                                                   name: el.name,
                                                                   price: el.price
                                                               }
                                                           }
                                                           if (pathname === '/geo') {
                                                               return {
                                                                   id: el.id,
                                                                   name: el.name
                                                               }
                                                           }
                                                       })} handleClick={handleClick} typeRow={typeRow && typeRow[idx]}
                                                       locale={locale}
                                                       rowsPage={rowsPage && rowsPage.find(el => el.id === row.id)}
                                                       key={row.id}
                                                       setEdit={setEdit} row={row} avatars={avatars} actions={actions}/>
                            )) : <TableRow><TableCell><Box>Записей пока нет</Box></TableCell></TableRow>}
                        </Table>
                        {rows && countRow > 9 && <TablePagination rowsPerPageOptions={[10, 25, 100]}
                                                                  component="div"
                                                                  labelRowsPerPage="Отображаемых элементов"
                                                                  count={countRow}
                                                                  page={page}
                                                                  onPageChange={handleChangePage}
                                                                  onRowsPerPageChange={handleChangeRowsPerPage}
                                                                  rowsPerPage={rowsPerPage}/>}
                    </TableContainer>
                </CardContent>
            </Card>
            <Snackbar open={openSnackBar} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={message} sx={{width: '100%'}}>
                    {t(`message.delete.${message}`)}
                </Alert>
            </Snackbar>
            <ConfirmDialog/>
        </>
    );
};

export default TableCustomService;