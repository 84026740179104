export const TYPE_CLIENT_ADD_REQUEST = 'TYPE_CLIENT_ADD_REQUEST'
export const TYPE_CLIENT_ADD_SUCCESS = 'TYPE_CLIENT_ADD_SUCCESS'
export const TYPE_CLIENT_ADD_ERROR = 'TYPE_CLIENT_ADD_ERROR'

export const TYPE_CLIENT_GET_REQUEST = 'TYPE_CLIENT_GET_REQUEST'
export const TYPE_CLIENT_GET_SUCCESS = 'TYPE_CLIENT_GET_SUCCESS'
export const TYPE_CLIENT_GET_ERROR = 'TYPE_CLIENT_GET_ERROR'

export const TYPE_CLIENT_DELETE_REQUEST = 'TYPE_CLIENT_DELETE_REQUEST'
export const TYPE_CLIENT_DELETE_SUCCESS = 'TYPE_CLIENT_DELETE_SUCCESS'
export const TYPE_CLIENT_DELETE_ERROR = 'TYPE_CLIENT_DELETE_ERROR'

export const TYPE_CLIENT_EDIT_REQUEST = 'TYPE_CLIENT_EDIT_REQUEST'
export const TYPE_CLIENT_EDIT_SUCCESS = 'TYPE_CLIENT_EDIT_SUCCESS'
export const TYPE_CLIENT_EDIT_ERROR = 'TYPE_CLIENT_EDIT_ERROR'
