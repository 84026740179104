import React, {useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";
import TextShared from "../../components/Shared/TextShared";
import NumberFormatShared from "../../components/Shared/NumberFormatShared";
import {DatePickerShared, SelectShared} from "../../components";
import {getGeoObject} from "../../redux/actions/geo/geoActions";
import {useDispatch} from "react-redux";
import {register} from "../../redux/actions/userActions";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {GENERAL_ROUTE} from "../../routes/const";
import {useHistory} from "react-router-dom";
import {GeoObjectSelect, RegionSelect} from "../../components/Select/domainSelects";

const SignUpLegalPerson = (props) => {
    const dispatch = useDispatch()
    const [userName, setUserName] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [lastName, setLastName] = useState('')
    const [firstName, setFirstName] = useState('')
    const [middleName, setMiddleName] = useState('')
    const [addressId, setAddressId] = useState('')
    const [bin, setBin] = useState({
        formattedValue: '',
        value: ''
    })
    const [number, setNumber] = useState({
        formattedValue: '',
        value: ''
    })
    const [email, setEmail] = useState('')
    const [opfs, setOpfs] = useState([
        {name: 'РГУ'},
        {name: "ПТ"},
        {name: 'РГП'},
        {name: 'РГП на ПХВ'},
        {name: 'КГП на ПХВ'},
        {name: 'ГККП'},
        {name: 'ГКП на ПХВ'},
        {name: 'ГУ'},
        {name: 'КГКП'},
        {name: 'ЧУ'},
        {name: 'АО'},
        {name: 'ОО'},
        {name: 'НАО'},
        {name: 'ТОО'},
        {name: 'КХ'},
        {name: 'ПК'},
        {name: 'КГУ'},
        {name: 'ИП'}
    ])
    useEffect(() => {
        setOpfs(prev => prev.map((el, idx) => ({id: idx, name: el.name})))
    }, [])
    const [opf, setOpf] = useState(opfs[0]?.id)
    const [regionId, setRegionId] = useState('')

    const [name, setName] = useState('')
    const [supervisorName, setSupervisorName] = useState('')
    const [documentNumber, setDocumentNumber] = useState('')
    const [documentDate, setDocumentDate] = useState(Date.now())
    const [bankName, setBankName] = useState('')
    const [bankBik, setBankBik] = useState('')
    const [bankKBE, setBaknKBE] = useState('')
    const [bankAccountNumber, setBankAccountNumber] = useState('')
    const [workPhone, setWorkPhone] = useState({
        formattedValue: '',
        value: ''
    })

    const [addressLine, setAddressLine] = useState('')

    const handleSubmit = async (event) => {
        event.preventDefault();
        const legalPerson = {
            name: name,
            bin: bin.value,
            bankName: bankName,
            bankBik: bankBik,
            regionLine: addressLine,
            addressLine: addressLine,
            phoneNumber: number.value,
            email: email,
            addressId,
            bankAccountNumber: bankAccountNumber,
            supervisorName: supervisorName,
        }
        if (password === confirmPassword) {
            dispatch(register(userName, password, null, legalPerson))
        } else {
            alert('Пароли не совпадают')
        }

    };

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{mt: 3}}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextShared value={userName} setValue={setUserName} required label="Логин"/>
                </Grid>
                <Grid item xs={6}>
                    <TextShared value={lastName} setValue={setLastName} required label="Фамилия"/>
                </Grid>
                <Grid item xs={6}>
                    <TextShared value={firstName} setValue={setFirstName} required label="Имя"/>
                </Grid>
                <Grid item xs={6}>
                    <TextShared value={middleName} setValue={setMiddleName} required label="Отчество"/>
                </Grid>
                <Grid item xs={6}>
                    <TextShared value={addressLine} setValue={setAddressLine} required={false} label="Адрес"/>
                </Grid>
                <Grid item xs={6}>
                    <NumberFormatShared  format="############" mask="_" placeholder="Бин" value={bin}
                                        setValues={setBin}/>
                </Grid>
                <Grid item xs={6}>
                    <SelectShared array={opfs} required label="Организационно-правовая форма" value={opf}
                                  setValue={setOpf}/>
                </Grid>
                <Grid item xs={6}>
                    <TextShared required={false} value={name} setValue={setName} label="Наименование организации"/>
                </Grid>
                <Grid item xs={6}>
                    <TextShared required={false} value={supervisorName} setValue={setSupervisorName}
                                label="Фамилия Имя Отчество Директора"/>
                </Grid>
                <Grid item xs={6}>
                    <RegionSelect
                        label={"Регион"}
                        fullWidth
                        value={regionId}
                        onChange={val => setRegionId(val)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <GeoObjectSelect
                        label={"Город"}
                        fullWidth
                        value={addressId}
                        onChange={val => setAddressId(val)}
                        disabled={!regionId}
                        parentId={regionId}
                        parentKey="parentId"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextShared value={documentNumber} setValue={setDocumentNumber} required label="Номер документа"/>
                </Grid>
                <Grid item xs={6}>
                    <DatePickerShared value={documentDate} setValue={setDocumentDate} label="Дата документа" fullWidth/>
                </Grid>
                <Grid item xs={6}>
                    <TextShared value={bankName} setValue={setBankName} required label="Название банка"/>
                </Grid>
                <Grid item xs={6}>
                    <TextShared value={bankBik} setValue={setBankBik} required label="БИК"/>
                </Grid>
                <Grid item xs={6}>
                    <TextShared value={bankKBE} setValue={setBaknKBE} required label="КБЕ"/>
                </Grid>
                <Grid item xs={6}>
                    <TextShared value={bankAccountNumber} setValue={setBankAccountNumber} required label="ИИК"/>
                </Grid>
                <Grid item xs={6}>
                    <TextShared value={addressLine} setValue={setAddressLine} required label="Юридический адрес"/>
                </Grid>
                <Grid item xs={6}>
                    <NumberFormatShared format="+7 (###) ###-##-##" mask="_" placeholder="Рабочий телефон"
                                        value={workPhone} setValues={setWorkPhone}/>
                </Grid>
                <Grid item xs={6}>
                    <NumberFormatShared format="+7 (###) ###-##-##" mask="_" placeholder="Мобильный телефон"
                                        value={number} setValues={setNumber}/>
                </Grid>
                <Grid item xs={6}>
                    <TextShared value={email} setValue={setEmail} required label="Email" type="email"/>
                </Grid>
                <Grid item xs={6}>
                    <TextShared value={password} setValue={setPassword} required label="Придумайте пароль"
                                type="password"/>
                </Grid>
                <Grid item xs={6}>
                    <TextShared value={confirmPassword} setValue={setConfirmPassword} required label="Повторите пароль"
                                type="password"/>
                </Grid>
            </Grid>
            <Button
                type="submit"
                variant="contained"
                sx={{mt: 3, mb: 2}}
            >
                Регистрация
            </Button>
        </Box>
    );
}

export default SignUpLegalPerson;