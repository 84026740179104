import React from 'react'
import {MenuItem, TextField} from "@mui/material";

const SelectShared = ({value, label, setValue, array, required, sx, style, disabled}) => {
    return (
        <TextField  required={required} value={value} sx={{...sx, maxWidth: '500px', ...(style || {})}}
                   onChange={(e) => setValue(e.target.value, array.find(el => el.id === e.target.value))}
                   margin="normal" fullWidth label={label} disabled={disabled} select>
            <MenuItem value={null}>Не выбрано</MenuItem>
            {array && array.map(el => (
                <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>
            ))}
        </TextField>
    );
}

SelectShared.defaultProps = {
    required: true,
    disabled: false
}


export default React.memo(SelectShared)
