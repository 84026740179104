import {
    TYPE_SERVICE_ADD_ERROR,
    TYPE_SERVICE_ADD_REQUEST,
    TYPE_SERVICE_ADD_SUCCESS,
    TYPE_SERVICE_DELETE_ERROR,
    TYPE_SERVICE_DELETE_REQUEST,
    TYPE_SERVICE_DELETE_SUCCESS, TYPE_SERVICE_EDIT_ERROR, TYPE_SERVICE_EDIT_REQUEST, TYPE_SERVICE_EDIT_SUCCESS,
    TYPE_SERVICE_GET_ERROR,
    TYPE_SERVICE_GET_REQUEST,
    TYPE_SERVICE_GET_SUCCESS
} from "../../constants/service/typeServiceConstants";

const initialState = {
    typeService: [],
    loading: false,
    success: null,
    error: null
};

export const typeServiceReducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPE_SERVICE_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case TYPE_SERVICE_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true
            };
        case TYPE_SERVICE_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case TYPE_SERVICE_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case TYPE_SERVICE_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true
            };
        case TYPE_SERVICE_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case TYPE_SERVICE_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case TYPE_SERVICE_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true
            };
        case TYPE_SERVICE_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case TYPE_SERVICE_GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case TYPE_SERVICE_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                typeService: action.payload,
            };
        case TYPE_SERVICE_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        default:
            return state;
    }
}