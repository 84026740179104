import React, {useEffect, useState} from 'react';
import DashboardLayout from "../../../layouts/Dashboard";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Button} from "@mui/material";
import Box from "@mui/material/Box";
import PopupServicePrice from "../../../components/Popups/PopupServicePrice";
import {getServicePrice} from "../../../redux/actions/service/serviceActions";
import {DeleteForeverIconCustom, EditIconCustom, TableCustom} from "../../../components";

const Price = () => {
    const {t} = useTranslation();
    const rowName = [
        {text: t("servicePrice.tableItem.id")},
        {text: t("servicePrice.tableItem.name")},
        {text: t("servicePrice.tableItem.service")},
        {text: t("servicePrice.tableItem.unitOfMeasure")},
        {text: t("servicePrice.tableItem.currency")},
        {text: t("servicePrice.tableItem.price")},
        {text: t("servicePrice.tableItem.actions")},
    ]
    const [open, setOpen] = useState(false);
    const [edit, setEdit] = useState(false);
    const [id, setId] = useState(null);
    const dispatch = useDispatch()
    const setEditHandler = async (idEdit) => {
        setOpen(true)
        setEdit(true)
        setId(idEdit)
    }
    const {servicesPrice, servicesPriceCount} = useSelector((state) => state.service);
    const [page, setPage] = useState(0);
    useEffect(() => {
        (async function () {
            dispatch(getServicePrice(page + 1))
        }())
    }, [dispatch, page])
    return (
        <DashboardLayout>
            <Box sx={{margin: '20px 0', display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                <Button onClick={() => setOpen(true)} color="primary"
                        variant="contained">{t("servicePrice.buttonText")}</Button>
            </Box>
            <TableCustom countRow={servicesPriceCount} page={page} setPage={setPage} name="Прейскурант" avatars={false} setEdit={setEditHandler} rowName={rowName}
                         rows={servicesPrice.map(el => {
                             return {
                                 id: el.id,
                                 name: el.name,
                                 service: el.service.name,
                                 unit: el.unit.name,
                                 currency: el.currency.name,
                                 price: el.price
                             }
                         })} locale="legalClient" rowsPage={servicesPrice} actions={[{
                icon: <EditIconCustom/>,
                id: 'EditIcon'
            }, {icon: <DeleteForeverIconCustom/>, id: 'DeleteForeverIcon'}]}/>
            <PopupServicePrice id={id} edit={edit} setEdit={setEdit} setOpen={setOpen} open={open} title="Прейскурант"/>
        </DashboardLayout>
    );
};

export default Price;