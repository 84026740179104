export const PERSON_ADD_REQUEST = 'PERSON_ADD_REQUEST'
export const PERSON_ADD_SUCCESS = 'PERSON_ADD_SUCCESS'
export const PERSON_ADD_ERROR = 'PERSON_ADD_ERROR'

export const PERSON_GET_REQUEST = 'PERSON_GET_REQUEST'
export const PERSON_GET_SUCCESS = 'PERSON_GET_SUCCESS'
export const PERSON_GET_ERROR = 'PERSON_GET_ERROR'

export const ONE_PERSON_GET_REQUEST = 'ONE_PERSON_GET_REQUEST'
export const ONE_PERSON_GET_SUCCESS = 'ONE_PERSON_GET_SUCCESS'
export const ONE_PERSON_GET_ERROR = 'ONE_PERSON_GET_ERROR'

export const ONE_PERSON_EDIT_REQUEST = 'ONE_PERSON_EDIT_REQUEST'
export const ONE_PERSON_EDIT_SUCCESS = 'ONE_PERSON_EDIT_SUCCESS'
export const ONE_PERSON_EDIT_ERROR = 'ONE_PERSON_EDIT_ERROR'

export const PERSON_DELETE_REQUEST = 'PERSON_DELETE_REQUEST'
export const PERSON_DELETE_SUCCESS = 'PERSON_DELETE_SUCCESS'
export const PERSON_DELETE_ERROR = 'PERSON_DELETE_ERROR'

export const PERSON_EDIT_REQUEST = 'PERSON_EDIT_REQUEST'
export const PERSON_EDIT_SUCCESS = 'PERSON_EDIT_SUCCESS'
export const PERSON_EDIT_ERROR = 'PERSON_EDIT_ERROR'

