import React, {createContext, useEffect, useState} from 'react';
import DashboardLayout from "../../layouts/Dashboard";
import {
    DeleteForeverIconCustom,
    EditIconCustom,
    TableCustom,
    VisibilityIconCustom
} from "../../components";
import {Button} from "@mui/material";
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {getGeoObject, getGeoObjectType} from "../../redux/actions/geo/geoActions";
import PopupGeo from "../../components/Popups/PopupGeo";
import { GEO_ROUTE} from "../../routes/const";
import BreadcrumbsShared from "../../components/Shared/BreadcrumbsShared";
import useReload from "../../hooks/useReload";

export const ContextTable = createContext();

const Geo = () => {
    const { reload, reloadValue } = useReload();
    const {t} = useTranslation();
    const rowName = [
        {text: t("geo.tableItem.id")},
        {text: t("geo.tableItem.name")},
        {text: t("geo.tableItem.actions")},
    ]
    const [open, setOpen] = useState(false);
    const [edit, setEdit] = useState(false);
    const [geo, setGeo] = useState([]);
    const [geoChild, setGeoChild] = useState([])
    const [geoChildChild, setGeoChildChild] = useState([])
    const [id, setId] = useState(null);
    const dispatch = useDispatch()
    const setEditHandler = async (idEdit) => {
        setOpen(true)
        setEdit(true)
        setId(idEdit)
    }

    useEffect(() => {
        (async function () {
            setGeo(await dispatch(getGeoObject(1, 10000, {filter: {
                    typeId: {
                        operand1: 2,
                        operator: "equals"
                    },
                }})))
            setGeoChild(await dispatch(getGeoObject(1, 10000, {filter: {
                    typeId: {
                        operand1: 3,
                        operator: "equals"
                    },
                }})))
            setGeoChildChild(await dispatch(getGeoObject(1, 10000, {filter: {
                    typeId: {
                        operand1: 4,
                        operator: "equals"
                    },
                }})))
            await dispatch(getGeoObjectType())
        }())
    }, [dispatch, reloadValue])

    return (
        <DashboardLayout>
            <BreadcrumbsShared breadcrumbs={[
                {text: 'Геолокация', link: GEO_ROUTE},
            ]}/>
            <Box sx={{margin: '20px 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                {/*<SearchPanel searchName="name" search={legalClientStore} label={t("legalClient.searchLabel")}/>*/}
                <Button onClick={() => setOpen(true)} color="primary"
                        variant="contained">{t("geo.buttonText")}</Button>
            </Box>
            <ContextTable.Provider value={{geoChildChild}}>
                <TableCustom childTable={geoChild} reload={reload} name="Гео объекты" avatars={false} setEdit={setEditHandler} rowName={rowName}
                             rows={geo.map(el => {
                                 return {
                                     id: el.id,
                                     name: el.name
                                 }
                             })} actions={[{icon: <VisibilityIconCustom/>, id: 'VisibilityIcon'}, {
                    icon: <EditIconCustom/>,
                    id: 'EditIcon'
                }, {icon: <DeleteForeverIconCustom/>, id: 'DeleteForeverIcon'}]}/>
            </ContextTable.Provider>
            <PopupGeo reload={reload} id={id} edit={edit} setEdit={setEdit} title="Гео объект" open={open}
                              setOpen={setOpen}/>
        </DashboardLayout>
    );
};

export default React.memo(Geo);
