import {getData} from "../template/get";
import {
    AN_OBJECT_GET_ERROR,
    AN_OBJECT_GET_REQUEST,
    AN_OBJECT_GET_SUCCESS,
    AN_OBJECT_KIND_GET_ERROR,
    AN_OBJECT_KIND_GET_REQUEST,
    AN_OBJECT_KIND_GET_SUCCESS, TRANSPORT_TYPE_GET_ERROR,
    TRANSPORT_TYPE_GET_REQUEST, TRANSPORT_TYPE_GET_SUCCESS
} from "../../constants/anObject/anObjectConstants";

export const getAnObject = (page, size, args, nonGlobal) => async (dispatch) => {
    return await dispatch(getData(AN_OBJECT_GET_REQUEST, AN_OBJECT_GET_SUCCESS, AN_OBJECT_GET_ERROR, 'anObject', page, page, null, null, true, size, args, nonGlobal))
};

export const getAnObjectKind = (page, size, args, nonGlobal) => async (dispatch) => {
    return await dispatch(getData(AN_OBJECT_KIND_GET_REQUEST, AN_OBJECT_KIND_GET_SUCCESS, AN_OBJECT_KIND_GET_ERROR, 'anObjectKind', page, page, null, null, true, size, args, nonGlobal))
};

export const getTransportType = (page, size, args, nonGlobal) => async (dispatch) => {
    return await dispatch(getData(TRANSPORT_TYPE_GET_REQUEST, TRANSPORT_TYPE_GET_SUCCESS, TRANSPORT_TYPE_GET_ERROR, 'transportType', page, page, null, null, true, size, args, nonGlobal))
};