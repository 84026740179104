import React, {useEffect, useState} from 'react';
import DashboardLayout from "../../../layouts/Dashboard";
import {
    Paper, Card, CardHeader, Divider, CardContent, TableBody,
    TableRow,
    TableCell, Table, CardActions, Button, Box
} from "@mui/material";
import {makeStyles} from '@mui/styles';
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getOneLegalClient} from "../../../redux/actions/client/legalClientActions";
import {useTranslation} from "react-i18next";
import {EditIconCustom, PopupLegalClient} from "../../../components";

const useStyles = makeStyles(theme => ({
    root: {},
    content: {
        padding: 0
    },
    actions: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        '& > * + *': {
            marginLeft: 0
        }
    }
}));

const LegalClientPage = () => {
    const {t} = useTranslation();
    const classes = useStyles();
    const {id} = useParams()
    const [open, setOpen] = useState(false);
    const [edit, setEdit] = useState(true);
    const dispatch = useDispatch()
    const {oneLegalClient} = useSelector((state) => state.legalClient);
    useEffect(() => {
        dispatch(getOneLegalClient(id))
    }, [dispatch, id])
    return (
        <DashboardLayout>
            <Paper>
                <Card
                    className={classes.root}
                >
                    <CardHeader title={oneLegalClient.name + ' (юр. лицо)'}/>
                    <Divider/>
                    <CardContent className={classes.content}>
                        <Table>
                            <TableBody>
                                {Object.keys(oneLegalClient).slice(0, 10).map((el, idx) => (
                                    <TableRow key={idx}>
                                        <TableCell>{t(`legalClient.tableItem.${[el]}`)}</TableCell>
                                        <TableCell>{oneLegalClient[el]}</TableCell>
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <TableCell>{t(`legalClient.tableItem.typeId`)}</TableCell>
                                    <TableCell>{oneLegalClient.type && oneLegalClient.type.name}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </CardContent>
                    <CardActions className={classes.actions}>
                        <Button onClick={() => setOpen(true)}>
                            <EditIconCustom/>
                            <Box sx={{marginLeft: '1rem'}}>Редактировать</Box>
                        </Button>
                    </CardActions>
                </Card>
            </Paper>
            <PopupLegalClient id={id} details={true} edit={edit} setEdit={setEdit} title="Клиенты (юр. лица)" open={open}
                              setOpen={setOpen}/>
        </DashboardLayout>
    );
};

export default LegalClientPage;