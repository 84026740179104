import {$authHost} from "../../../http";
import {
    ONE_PERSON_EDIT_ERROR,
    ONE_PERSON_EDIT_REQUEST, ONE_PERSON_EDIT_SUCCESS,
    ONE_PERSON_GET_ERROR,
    ONE_PERSON_GET_REQUEST, ONE_PERSON_GET_SUCCESS,
    PERSON_ADD_ERROR,
    PERSON_ADD_REQUEST,
    PERSON_ADD_SUCCESS, PERSON_DELETE_ERROR,
    PERSON_DELETE_REQUEST, PERSON_DELETE_SUCCESS,
    PERSON_EDIT_ERROR,
    PERSON_EDIT_REQUEST,
    PERSON_EDIT_SUCCESS,
    PERSON_GET_ERROR,
    PERSON_GET_REQUEST,
    PERSON_GET_SUCCESS
} from "../../constants/client/personConstants";
import {addClient} from "./clientActions";
import {getData} from "../template/get";
import {editData} from "../template/edit";

export const addPerson = (firstName, lastName, secondName, itn, addressLine, phoneNumber, email, birthDay, gender) => async (dispatch) => {
    try {
        await dispatch({
            type: PERSON_ADD_REQUEST,
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        const {data} = await $authHost.post(
            "/api/v1/person",
            {firstName, lastName, secondName, fullName: `${lastName} ${firstName} ${secondName}`, identityNumber: itn, addressLine, phoneNumber, email, birthDay, genderId: gender},
            config
        );
        await addClient(data.id, null, null)
        await dispatch(getPersons())
        await dispatch({
            type: PERSON_ADD_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: PERSON_ADD_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const editPerson = (id, firstName, lastName, secondName, itn, addressLine, phoneNumber, email, birthDay, gender) => async (dispatch) => {
    return await dispatch(editData(PERSON_EDIT_REQUEST, PERSON_EDIT_SUCCESS, PERSON_EDIT_ERROR, 'person', getPersons(), {id, firstName, lastName, secondName, fullName: `${lastName} ${firstName} ${secondName}`, identityNumber: itn, addressLine, phoneNumber, email, birthDay, genderId: gender}))
};

export const editOnePerson = (id, firstName, lastName, secondName, itn, addressLine, phoneNumber, email, birthDay, gender) => async (dispatch) => {
    try {
        dispatch({
            type: ONE_PERSON_EDIT_REQUEST,
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        await $authHost.put(
            "/api/v1/person",
            {id, firstName, lastName, secondName, fullName: `${lastName} ${firstName} ${secondName}`, identityNumber: itn, addressLine, phoneNumber, email, birthDay, genderId: gender},
            config
        );
        const personNew = await dispatch(getOnePerson(id))
        dispatch({
            type: ONE_PERSON_EDIT_SUCCESS,
            payload: {...personNew}
        });
    } catch (error) {
        dispatch({
            type: ONE_PERSON_EDIT_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};


export const deletePerson = (id) => async (dispatch) => {
    try {
        dispatch({
            type: PERSON_DELETE_REQUEST,
        });

        await $authHost.delete(
            "/api/v1/person",
            {
                data: {
                    id
                }
            },
        );
        dispatch(getPersons())
        dispatch({
            type: PERSON_DELETE_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: PERSON_DELETE_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
}

export const getPersons = (page, size, args, nonGlobal) => async (dispatch) => {
    return await dispatch(getData(PERSON_GET_REQUEST, PERSON_GET_SUCCESS, PERSON_GET_ERROR, 'person', true, page, null, null, true, size, args, nonGlobal))
};

export const getOnePerson = (id) => async (dispatch) => {
    try {
        dispatch({
            type: ONE_PERSON_GET_REQUEST,
        });

        const {data} = await $authHost.get(
            `api/v1/person/${id}`,
        );
        dispatch({
            type: ONE_PERSON_GET_SUCCESS,
            payload: data
        });
        return data
    } catch (error) {
        dispatch({
            type: ONE_PERSON_GET_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};