import React from 'react';

import {serviceCRUD} from "../../../http/CRUD";
import CRUDTableQuery from "../CRUDTableQuery";
import useReload from "../../../hooks/useReload";
import CRUDTableStandart from "../CRUDTableStandart";

export default function ServiceCRUDTable({type = 'standart', ...props}) {
    const {reload, reloadValue} = useReload();
    const columns = [
        {title: "ID", dataIndex: 'id', key: 'id'},
        {
            title: "Название", key: 'name', render: (unknown, row) => row.name ? row.name : row.group ? row.group.name :
                <span style={{color: "gray"}}>Не задан</span>
        },
        {title: "Цена", dataIndex: 'price', key: 'price'},
        {title: "Код", dataIndex: 'code', key: 'code'},
        {
            title: "Лаборатория", key: 'department', render: (unknown, row) => row.department ? row.department?.name :
                <span style={{color: "gray"}}>Не задан</span>
        }
    ];
    const searchFields = [
        {
            title: "Название",
            key: "name",
            compareType: "like",
            operandPosition: 1,
            filterType: "normal"
        }, {
            title: "Код",
            key: "code",
            compareType: "like",
            operandPosition: 1,
            filterType: "normal"
        },
        {
            title: "Цена",
            key: "price",
            compareType: "like",
            operandPosition: 1,
            filterType: "normal"
        },
    ]

    const CRUDTables = {
        standart: CRUDTableStandart,
        query: CRUDTableQuery,
    }

    const CRUDTable = CRUDTables[type]


    return (
        <>
            <CRUDTable search={props.search || serviceCRUD.search}
                            columns={columns}
                            searchFields={searchFields}
                            reloadValue={reloadValue}

                            {...props}
            />
        </>
    );
}
