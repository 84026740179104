import React, {useEffect, useState} from 'react';
import DashboardLayout from "../../layouts/Dashboard";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {getFilial} from "../../redux/actions/filial/filialActions";
import {Button} from "@mui/material";
import Box from "@mui/material/Box";
import {DeleteForeverIconCustom, EditIconCustom, TableCustom} from "../../components";
import PopupGeo from "../../components/Popups/PopupGeo";

const Filial = () => {
    const {t} = useTranslation();
    const rowName = [
        {text: t("filial.tableItem.id")},
        {text: t("filial.tableItem.name")},
        {text: t("filial.tableItem.description")},
        {text: t("filial.tableItem.bin")},
        {text: t("filial.tableItem.addressLine")},
        {text: t("filial.tableItem.surId")},
        {text: t("filial.tableItem.addressId")},
        {text: t("filial.tableItem.actions")},
    ]
    const [open, setOpen] = useState(false);
    const [edit, setEdit] = useState(false);
    const {filial} = useSelector((state) => state.filial);
    const [id, setId] = useState(null);
    const dispatch = useDispatch()
    const setEditHandler = async (idEdit) => {
        setOpen(true)
        setEdit(true)
        setId(idEdit)
    }
    useEffect(() => {
        dispatch(getFilial())
    }, [dispatch])
    return (
        <DashboardLayout>
            <Box sx={{margin: '20px 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <Button onClick={() => setOpen(true)} color="primary"
                        variant="contained">{t("filial.buttonText")}</Button>
            </Box>
            <TableCustom name="Филиалы" avatars={false} setEdit={setEditHandler} rowName={rowName}
                         rows={filial} actions={[{
                icon: <EditIconCustom/>,
                id: 'EditIcon'
            }, {icon: <DeleteForeverIconCustom/>, id: 'DeleteForeverIcon'}]}/>
            <PopupGeo id={id} edit={edit} setEdit={setEdit} title="Гео объект" open={open}
                      setOpen={setOpen}/>
        </DashboardLayout>
    );
}

export default Filial;