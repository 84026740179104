import {
    Bills,
    Login,
    Main,
    SignUp,
    Client,
    Reports,
    TypeClient,
    LegalClient,
    LegalClientPage,
    Clients,
    PersonPage,
    Service,
    TypeService,
    Price,
    Specification,
    Contract,
    Geo,
    ContractPage,
    Filial,
    ContractEdit,
    ContractRequest, ContractRequestAdd, FilialPackage, FilialPackageAdd
} from "../screens";
import {
    BILLS_ROUTE,
    CLIENT_ROUTE,
    CLIENTS_ROUTE,
    COMPLETION_ACT_ADD_ROUTE,
    COMPLETION_ACT_ROUTE,
    CONTRACT_ADDITIONAL_ADD_ROUTE,
    CONTRACT_ADDITIONAL_ROUTE,
    CONTRACT_DETAILS_ROUTE,
    CONTRACT_EDIT_ROUTE,
    CONTRACT_REQUESTS_ADD_ROUTE,
    CONTRACT_REQUESTS_ROUTE,
    CONTRACT_ROUTE,
    CONTRACT_SPECIFICATION, CONTRACT_VIEW_ECP_ROUTE, CONTRACT_VIEW_ROUTE, DATE_ROUTE,
    DEAL_ADD_ROUTE,
    DEAL_ROUTE, EFF_ROUTE, FILIAL_PACKAGE_ADD_ROUTE, FILIAL_PACKAGE_EDIT_ROUTE, FILIAL_PACKAGE_ROUTE,
    FILIAL_ROUTE,
    GENERAL_ROUTE,
    GEO_ROUTE, INVOICE_ADD_ROUTE, INVOICE_ROUTE,
    LEGAL_CLIENT_ROUTE,
    LOGIN_ROUTE,
    MAIN_ROUTE, OBRASH_ROUTE, OHVAT_ROUTE, PAYMENT_ADD_ROUTE, PAYMENT_ROUTE,
    PERSONAL_AREA_ROUTE,
    PRICE_ROUTE,
    PROFILE_ROUTE,
    REPORTS_ROUTE,
    SERVICE_ROUTE, SERVICE_VIEW_ROUTE,
    SIGN_UP_ROUTE, STATUS_ROUTE,
    TYPE_CLIENT_ROUTE,
    TYPE_SERVICE_ROUTE
} from "./const";
import Profile from "../screens/Profile/Profile";
import ContractRequestEdit from "../screens/ContractRequest/ContractRequestEdit";
import General from "../screens/General/General";
import GeneralServices from "../screens/General/GeneralServices";
import Basket from "../screens/Basket/Basket";
import Deal from "../screens/Deal/Deal";
import DealAdd from "../screens/Deal/DealAdd";
import PersonArea from "../screens/PersonArea/PersonArea";
import DealDetails from "../screens/Deal/DealDetails";
import ContractAdditional from "../screens/ContractAdditional/ContractAdditional";
import ContractAdditionalAdd from "../screens/ContractAdditional/ContractAdditionalAdd";
import CompletionAct from "../screens/CompletionAct/CompletionAct";
import CompletionActAdd from "../screens/CompletionAct/CompletionActAdd";
import CompletionActEdit from "../screens/CompletionAct/CompletionActEdit";
import Invoice from "../screens/Invoice/Invoice";
import InvoiceAdd from "../screens/Invoice/InvoiceAdd";
import InvoiceEdit from "../screens/Invoice/InvoiceEdit";
import Payment from "../screens/Payment/Payment";
import PaymentAdd from "../screens/Payment/PaymentAdd";
import Ohvat from "../screens/Ohvat/Ohvat";
import Date from "../screens/Date/Date";
import Obrash from "../screens/obrash/Obrash";
import Effect from "../screens/Effect/Effect";
import Status from "../screens/Status/Status";
import ContractView from "../screens/Contract/ContractView";
import ContractViewECP from "../screens/Contract/ContractViewECP";

export const publicRoutes = [
    {
        path: LOGIN_ROUTE,
        Component: Login,
    },
    {
        path: SIGN_UP_ROUTE,
        Component: SignUp,
    },
]

export const clientRoutes = [
    {
        path: GENERAL_ROUTE,
        Component: General,
    },
    {
        path: GENERAL_ROUTE + '/basket',
        Component: Basket,
    },
    {
        path: GENERAL_ROUTE + '/:id',
        Component: GeneralServices,
    },
    {
        path: PROFILE_ROUTE,
        Component: Profile,
    },
]

export const authRoutes = [
    {
        path: GENERAL_ROUTE + '/basket',
        Component: Basket,
    },
    {
        path: INVOICE_ROUTE,
        Component: Invoice,
    },
    {
        path: EFF_ROUTE,
        Component: Effect,
    },
    {
        path: STATUS_ROUTE,
        Component: Status,
    },
    {
        path: INVOICE_ROUTE + '/:id',
        Component: InvoiceEdit,
    },
    {
        path: OHVAT_ROUTE,
        Component: Ohvat,
    },
    {
        path: DATE_ROUTE,
        Component: Date,
    },
    {
        path: OBRASH_ROUTE,
        Component: Obrash,
    },
    {
        path: INVOICE_ADD_ROUTE,
        Component: InvoiceAdd,
    },
    {
        path: PAYMENT_ROUTE,
        Component: Payment,
    },
    {
        path: PAYMENT_ADD_ROUTE,
        Component: PaymentAdd,
    },
    {
        path: PAYMENT_ROUTE + '/:id',
        Component: PaymentAdd,
    },
    {
        path: COMPLETION_ACT_ROUTE,
        Component: CompletionAct,
    },
    {
        path: COMPLETION_ACT_ROUTE + '/:id',
        Component: CompletionActEdit,
    },
    {
        path: COMPLETION_ACT_ADD_ROUTE,
        Component: CompletionActAdd,
    },
    {
        path: CONTRACT_ADDITIONAL_ROUTE,
        Component: ContractAdditional,
    },
    {
        path: CONTRACT_ADDITIONAL_ROUTE + '/:id',
        Component: ContractEdit,
    },
    {
        path: CONTRACT_ADDITIONAL_ADD_ROUTE,
        Component: ContractAdditionalAdd,
    },
    {
        path: GENERAL_ROUTE,
        Component: General,
    },
    {
        path: GENERAL_ROUTE + '/:id',
        Component: GeneralServices,
    },
    {
        path: MAIN_ROUTE,
        Component: Main,
    },
    {
        path: CONTRACT_REQUESTS_ROUTE,
        Component: ContractRequest,
    },
    {
        path: CONTRACT_REQUESTS_ROUTE + '/:id',
        Component: ContractRequestEdit,
    },
    {
        path: CONTRACT_REQUESTS_ADD_ROUTE,
        Component: ContractRequestAdd,
    },
    {
        path: CONTRACT_VIEW_ECP_ROUTE + '/:id',
        Component: ContractViewECP,
    },
    {
        path: FILIAL_ROUTE,
        Component: Filial,
    },
    {
        path: PROFILE_ROUTE,
        Component: Profile,
    },
    {
        path: BILLS_ROUTE,
        Component: Bills,
    },
    {
        path: CLIENT_ROUTE,
        Component: Client,
    },
    {
        path: CLIENT_ROUTE + '/:id',
        Component: PersonPage,
    },
    {
        path: TYPE_CLIENT_ROUTE,
        Component: TypeClient,
    },
    {
        path: LEGAL_CLIENT_ROUTE,
        Component: LegalClient,
    },
    {
        path: LEGAL_CLIENT_ROUTE + '/:id',
        Component: LegalClientPage,
    },
    {
        path: REPORTS_ROUTE,
        Component: Reports,
    },
    {
        path: DEAL_ROUTE,
        Component: Deal,
    },
    {
        path: DEAL_ROUTE + '/:id',
        Component: DealDetails,
    },
    {
        path: DEAL_ADD_ROUTE,
        Component: DealAdd,
    },
    {
        path: SERVICE_ROUTE,
        Component: Service,
    },
    {
        path: CONTRACT_VIEW_ROUTE + '/:id',
        Component: ContractView,
    },
    {
        path: CLIENTS_ROUTE,
        Component: Clients,
    },
    {
        path: PRICE_ROUTE,
        Component: Price,
    },
    {
        path: TYPE_SERVICE_ROUTE,
        Component: TypeService,
    },
    {
        path: CONTRACT_SPECIFICATION,
        Component: Specification,
    },
    {
        path: CONTRACT_DETAILS_ROUTE,
        Component: ContractPage,
    },
    {
        path: PERSONAL_AREA_ROUTE,
        Component: PersonArea,
    },
    {
        path: CONTRACT_EDIT_ROUTE + '/:id',
        Component: ContractEdit,
    },
    {
        path: CONTRACT_ROUTE,
        Component: Contract,
    },
    {
        path: GEO_ROUTE,
        Component: Geo,
    },
    {
        path: FILIAL_PACKAGE_ROUTE,
        Component: FilialPackage,
    },
    {
        path: FILIAL_PACKAGE_ADD_ROUTE,
        Component: FilialPackageAdd,
    },
    {
        path: FILIAL_PACKAGE_EDIT_ROUTE + '/:id',
        Component: FilialPackageAdd,
    }
]
