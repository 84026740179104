export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_SET = 'LOGIN_SET'
export const ROLES_SET = 'ROLES_SET'
export const MARKETER_SET = 'MARKETER_SET'
export const USER_INFO_SET = 'USER_INFO_SET'
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGIN_RESET = 'LOGIN_RESET'

export const REGISTER_REQUEST = 'REGISTER_REQUEST'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'

export const GET_USER_REQUEST = 'GET_USER_REQUEST'
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
export const GET_USER_ERROR = 'GET_USER_ERROR'

export const SET_FILIAL = 'SET_FILIAL'