import React, {useState} from 'react';
import {Table} from "antd";
import ServiceField from "../../Fields/ServiceField";
import {Button} from "@mui/material";

function DealSpecifications({onRowClick, data, handleChangeObject, dealId, disabled}) {
    const [_data, setData] = useState(data?.specifications)

    const columns = [
        {
            title: "ID", dataIndex: 'serviceId', key: 'serviceId',
            render: (unknown, row) => row.service ? row.service.id :
                <span style={{color: "gray"}}>Не задан</span>
        },
        {
            title: "Услуга", dataIndex: 'service', key: 'service',
            render: (unknown, row) => row.service ? row.service.name : row.service.group.name
        },
        {
            title: "Цена", dataIndex: 'price', key: 'price',
            render: (unknown, row) => row.service ? row.service.price :
                <span style={{color: "gray"}}>Не задана</span>
        },
        {
            title: "Код", dataIndex: 'code', key: 'code',
            render: (unknown, row) => row.service ? row.service.code :
                <span style={{color: "gray"}}>Не задана</span>
        },
        {
            title: "Действия", dataIndex: 'actions', key: 'actions',
            render: (unknown, row) => <Button disabled={disabled} onClick={() => {
                handleChangeObject('specifications', [...data.specifications.filter(el => el.service.id !== row.service.id)])
                setData([...data.specifications.filter(el => el.service.id !== row.service.id)])
            }
            }>Удалить</Button>
        },
    ];
    return (
        <>
            <Table
                columns={columns}
                dataSource={_data}
                pagination={false}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (e) => onRowClick && onRowClick(record, rowIndex), // click row
                        onDoubleClick: (event) => {
                        }, // double click row
                        onContextMenu: (event) => {
                        }, // right button click row
                        onMouseEnter: (event) => {
                        }, // mouse enter row
                        onMouseLeave: (event) => {
                        }, // mouse leave row
                    };
                }}
            />
            <ServiceField disabled={disabled} dealId={dealId} onChange={record => {
                handleChangeObject('specifications', [...data.specifications, ...record])
                setData([...data.specifications, ...record])
            }}/>
        </>
    );
}

export default DealSpecifications;
