import React from 'react';
import DashboardLayout from "../../layouts/Dashboard";
import {useParams} from "react-router-dom";
import {contractCRUD} from "../../http/CRUD";
import useReload from "../../hooks/useReload";
import {Loader, TableTab} from "../../components";
import moment from "moment";
import useLoader from "../../hooks/useLoader";
import {
    CONTRACT_ROUTE,
    CONTRACT_VIEW_ECP_ROUTE,
    CONTRACT_VIEW_ROUTE,
    DEAL_ADD_ROUTE,
    DEAL_ROUTE
} from "../../routes/const";
import BreadcrumbsShared from "../../components/Shared/BreadcrumbsShared";
import {Card} from "@mui/material";
import TableTabECP from "../../components/Contract/TableTabECP";

function ContractViewECP() {
    const {id} = useParams()
    const [data, setData] = React.useState({})
    const {reload, reloadValue} = useReload();
    const {loading, start, stop} = useLoader(true);

    React.useEffect(() => {
        start()
        contractCRUD.get(id).then(res => {
            setData(res)
            stop()
        })
    }, [id, reloadValue])

    return (
        <DashboardLayout>
            <BreadcrumbsShared breadcrumbs={[
                {text: 'Договоры', link: CONTRACT_ROUTE},
                {text: 'Просмотр подписанного договора', link: CONTRACT_VIEW_ECP_ROUTE + `/${id}`},
            ]}/>
            {loading ? <Loader/> :
                <Card><TableTabECP locale="contract" reloadValue={reloadValue} reload={reload} rowsPage={{
                    number: data.id,
                    registeredAt: moment(data.registeredAt).format('LL'),
                    actionAt: moment(data.deadline).format('LL'),
                    typeId: data.typeId,
                    client: data.client,
                    signers: data.signers
                }}/></Card>}

        </DashboardLayout>
    );
}

export default ContractViewECP;
