import React, {useEffect} from 'react';
import DashboardProfile from "../../layouts/DashboardProfile";
import {MAIN_ROUTE, PERSONAL_AREA_ROUTE} from "../../routes/const";
import BreadcrumbsShared from "../../components/Shared/BreadcrumbsShared";
import {Button, Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import {TableCustom} from "../../components";
import Box from "@mui/material/Box";
import PaidIcon from "@mui/icons-material/Paid";
import DownloadIcon from "@mui/icons-material/Download";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {getContract} from "../../redux/actions/contract/contractActions";
import ClientLayout from "../../layouts/ClientLayout";
import {styled} from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function PersonArea(props) {
    const {t} = useTranslation();
    const userLogin = useSelector((state) => state.user);
    const {isClient} = userLogin;
    const history = useHistory()
    const rowName = [
        {text: t("contract.tableItem.date")},
        {text: t("contract.tableItem.payState")},
        {text: t("contract.tableItem.summary")},
        {text: t("contract.tableItem.status")},
        {text: t("contract.tableItem.actions")},
    ]
    const dispatch = useDispatch()
    const [expanded, setExpanded] = React.useState('panel1');
    const {contracts, contractsCount} = useSelector((state) => state.contract);
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    useEffect(() => {
        dispatch(getContract(1, 5))
    }, [dispatch])

    const components = {
        client: ClientLayout,
        user: DashboardProfile
    }

    const TagName = components[isClient? 'client': 'user']

    return (
        <TagName>
            <BreadcrumbsShared breadcrumbs={[
                {text: 'Личный кабинет', link: PERSONAL_AREA_ROUTE}
            ]}/>
            <Paper>
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        <Typography>Мои договоры</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TableCustom countRow={contractsCount} name="Мои договоры" avatars={false} rowName={rowName}
                                     rows={contracts && contracts.map(el => {
                                         const ispol = JSON.parse(el?.data)
                                         return {
                                             date: ispol && ispol['date_contract_ru'],
                                             payState: el?.payState?.name,
                                             summary: el.summary,
                                             status: el?.state?.name,
                                             button: <Box><Button color="secondary"
                                                                  startIcon={<PaidIcon />}
                                                                  variant="contained">Оплатить</Button>
                                                 <Button color="secondary"
                                                         startIcon={<DownloadIcon />}
                                                         sx={{marginLeft: '5px'}}
                                                         variant="contained">Скачать</Button></Box>
                                         }
                                     })} locale="contract"/>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                        <Typography>Мои заявки</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                            malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor
                            sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                            sit amet blandit leo lobortis eget.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Button onClick={() => history.push(MAIN_ROUTE)} sx={{margin: '15px 0 15px 15px'}} variant="contained">На главную</Button>
            </Paper>
        </TagName>
    );
}

export default PersonArea;