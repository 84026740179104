import React, {useEffect, useState} from 'react';
import {Box, Button, Grid, Tabs} from "@mui/material";
import {useTranslation} from "react-i18next";
import Tab from "@mui/material/Tab";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import 'moment/locale/ru'
import {getOneClientRequest} from "../../redux/actions/clientRequest/clientRequestActions";
import Loader from "../Shared/Loader";
import TableClientRequest from "./TableClientRequest";
import {useHistory} from "react-router-dom";
import {CONTRACT_DETAILS_ROUTE, CONTRACT_ROUTE, DEAL_ADD_ROUTE} from "../../routes/const";

moment.locale('ru')

function TableTabClientRequest({rowsPage, locale}) {
    const {t} = useTranslation();
    const dispatch = useDispatch()
    const history = useHistory()
    const [value, setValue] = useState(0);
    const {loading} = useSelector((state) => state.clientRequest)
    const [clientRequestOne, setClientRequestOne] = useState([])
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        (async function () {
            const res = await dispatch(getOneClientRequest(rowsPage.id))
            setClientRequestOne(res)
        }())
    }, [dispatch, rowsPage.id])

    const [type, setType] = useState(1)

    useEffect(() => {
        setType(clientRequestOne.client?.user?.typeId)
    }, [clientRequestOne?.client])

    const handleCreateContract = () => {
        history.push(CONTRACT_DETAILS_ROUTE, {
            client: clientRequestOne
        })
    }

    const handleCreateDeal = () => {
        history.push(DEAL_ADD_ROUTE, {
            client: clientRequestOne
        })
    }


    return (
        <>
            <Tabs
                value={value}
                onChange={handleChange}
            >
                <Tab label="Клиент"/>
                <Tab label="Выбранные услуги"/>
            </Tabs>
            {value === 0 && <>
                {loading ? <Loader/> : type === 1 &&  clientRequestOne?.client?.person && Object.keys(clientRequestOne?.client?.person).slice(0,10).map((el, idx) => {
                    return (
                        <Grid container key={idx}>
                            <Grid item xs={6}>
                                <Box sx={{
                                    padding: '5px 0',
                                    borderTop: "1 px",
                                    borderBottom: '1px'
                                }}>{t(`${locale}.tableItem.${[el]}`)}</Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={{
                                    padding: '5px 0',
                                    borderTop: "1 px",
                                    borderBottom: '1px'
                                }}>{clientRequestOne?.client?.person[el] ? clientRequestOne?.client?.person[el] : 'Значение не задано'}</Box>
                            </Grid>
                        </Grid>
                    )
                })}
                {loading ? <Loader/> : type === 2 && clientRequestOne?.client?.legalPerson && Object.keys(clientRequestOne?.client?.legalPerson).slice(0, 12).map((el, idx) => {
                    return (
                        <Grid container key={idx}>
                            <Grid item xs={6}>
                                <Box sx={{
                                    padding: '5px 0',
                                    borderTop: "1 px",
                                    borderBottom: '1px'
                                }}>{t(`${locale}.tableItem.${[el]}`)}</Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={{
                                    padding: '5px 0',
                                    borderTop: "1 px",
                                    borderBottom: '1px'
                                }}>{clientRequestOne?.client?.legalPerson[el] ? clientRequestOne?.client?.legalPerson[el] : 'Значение не задано'}</Box>
                            </Grid>
                        </Grid>
                    )
                })}
                <Grid sx={{marginTop: 2, marginBottom: 2}}>
                    <Button onClick={handleCreateContract}>Создать договор</Button>
                    <Button onClick={handleCreateDeal}>Создать сделку</Button>
                </Grid>
            </>}
            {value === 1 && <>
                {clientRequestOne?.specifications && <TableClientRequest specification={clientRequestOne?.specifications}/>}
            </>}
        </>
    )
}

export default TableTabClientRequest;