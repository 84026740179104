import React from 'react';
import {Box, Grid} from "@mui/material";
import moment from "moment";

function TableTabPerson({client}) {
    return (
        <Grid container>
            <Grid item xs={6}>
                <Box sx={{
                    padding: '5px 0',
                    borderTop: "1 px",
                    borderBottom: '1px'
                }}>Адрес</Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{
                    padding: '5px 0',
                    borderTop: "1 px",
                    borderBottom: '1px'
                }}>{client.addressLine}</Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{
                    padding: '5px 0',
                    borderTop: "1 px",
                    borderBottom: '1px'
                }}>Дата рождения</Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{
                    padding: '5px 0',
                    borderTop: "1 px",
                    borderBottom: '1px'
                }}>{moment(client.birthDay).format('LL')}</Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{
                    padding: '5px 0',
                    borderTop: "1 px",
                    borderBottom: '1px'
                }}>Email</Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{
                    padding: '5px 0',
                    borderTop: "1 px",
                    borderBottom: '1px'
                }}>{client.email}</Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{
                    padding: '5px 0',
                    borderTop: "1 px",
                    borderBottom: '1px'
                }}>ФИО</Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{
                    padding: '5px 0',
                    borderTop: "1 px",
                    borderBottom: '1px'
                }}>{client.fullName}</Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{
                    padding: '5px 0',
                    borderTop: "1 px",
                    borderBottom: '1px'
                }}>Пол</Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{
                    padding: '5px 0',
                    borderTop: "1 px",
                    borderBottom: '1px'
                }}>{client.gender?.name}</Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{
                    padding: '5px 0',
                    borderTop: "1 px",
                    borderBottom: '1px'
                }}>Номер телефона</Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{
                    padding: '5px 0',
                    borderTop: "1 px",
                    borderBottom: '1px'
                }}>{client.phoneNumber}</Box>
            </Grid>
        </Grid>
    );
}

export default TableTabPerson;