import { $authHost } from "../../http";
import {
    DEAL_STATE_GET_ERROR,
    DEAL_STATE_GET_REQUEST,
    DEAL_STATE_GET_SUCCESS
} from "../constants/deal/dealStateConstants";

export const getDealStates = (args) => async (dispatch) => {
    try {
        dispatch({
            type: DEAL_STATE_GET_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        const {data} = await $authHost.post(
            "/api/v1/dealState/query",
            args || {},
            config,
        );
        dispatch({
            type: DEAL_STATE_GET_SUCCESS,
            payload: data.result,
        });
        return data.result;
    } catch (error) {
        dispatch({
            type: DEAL_STATE_GET_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

