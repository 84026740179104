import React, {useEffect, useMemo, useState} from 'react';
import Dashboard from "../../layouts/Dashboard";
import {INVOICE_ROUTE} from "../../routes/const";
import BreadcrumbsShared from "../../components/Shared/BreadcrumbsShared";
import Status from "../../components/Status/Status";
import Box from "@mui/material/Box";
import {Button, CircularProgress, FormGroup, Grid, Paper, Stack, Switch} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Number, SelectFieldModal, TableMin} from "../../components";
import {getPersons} from "../../redux/actions/client/personActions";
import {contractRequestModalRowName, legalClientTableRowName, personTableRowName} from "../../lang/i18n";
import {getLegalClient} from "../../redux/actions/client/legalClientActions";
import TextShared from "../../components/Shared/TextShared";
import {useDispatch, useSelector} from "react-redux";
import {getContract, getOneContract} from "../../redux/actions/contract/contractActions";
import moment from "moment";
import {v4 as uuidv4} from "uuid";
import {getServiceKind, getUnitOfMeasure} from "../../redux/actions/genericActions";
import {LoadingButton} from "@mui/lab";
import {
    editInvoice,
    getOneInvoice
} from "../../redux/actions/bookkeeping/bookkeepingActions";
import {useHistory, useParams} from "react-router-dom";
import {getService} from "../../redux/actions/service/serviceActions";
import {getClients} from "../../redux/actions/client/clientActions";

function InvoiceEdit(props) {
    const [beneficiaryIdentity, setBeneficiaryIdentity] = useState('')
    const [beneficiaryName, setBeneficiaryName] = useState('')
    const [beneficiaryAccountNumber, setBeneficiaryAccountNumber] = useState('')
    const [recipientCode, setRecipientCode] = useState('')
    const [beneficiaryBank, setBeneficiaryBank] = useState('')
    const [beneficiaryBankCode, setBeneficiaryBankCode] = useState('')
    const [paymentPurposeCode, setPaymentPurposeCode] = useState('')
    const [supplierIdentity, setSupplierIdentity] = useState('')
    const [supplierName, setSupplierName] = useState('')
    const [buyerIdentity, setBuyerIdentity] = useState('')
    const [buyerName, setBuyerName] = useState('')
    const [performerName, setPerformerName] = useState('')
    const {contracts, contractsCount} = useSelector((state) => state.contract)
    const {invoiceOne} = useSelector((state) => state.bookkeeping);

    const [beneficiaryId, setBeneficiaryId] = useState(1);
    const [contractId, setContractId] = useState(1)
    const [qty, setQty] = useState(1)
    const [unitOfMeasure, setUnitOfMeasure] = useState(1)
    const [localServiceKind, setLocalServiceKind] = useState(1)
    const {unityOfMeasure, serviceKind} = useSelector((state) => state.generic);
    const {anObjects, anObjectsCount} = useSelector((state) => state.anObject);
    const {servicesSchedule, servicesScheduleCount} = useSelector((state) => state.service);
    const [anObjectsLocal, setAnObjectsLocal] = useState(1)
    const [servicesScheduleLocal, setServicesScheduleLocal] = useState(1)
    const [service, setService] = useState(1)
    const {services, servicesCount} = useSelector((state) => state.service);
    const [specification, setSpecification] = useState([])
    const [number, setNumber] = useState(1)

    const [loading, setLoading] = useState(false)
    const [loadingAdd, setLoadingAdd] = useState(false)
    const [typeClient, setTypeClient] = useState(false)
    const {
        legalClient: legalClientRedux,
        legalClientCount: legalClientReduxCount
    } = useSelector((state) => state.legalClient);
    const {persons, personsCount} = useSelector((state) => state.person);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleDeleteSpec = async (row) => {
        const newSpec = specification.filter(el => el.Id !== row)
        await setSpecification(newSpec);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleFormSpec = async (e) => {
        e.preventDefault()
        const localServiceKindIdx = serviceKind.findIndex(el => el.id === localServiceKind)
        const serviceIdx = services.findIndex(el => el.id === service)
        const unitOfMeasureIdx = unityOfMeasure.findIndex(el => el.id === unitOfMeasure)
        const newElement = {
            Id: uuidv4(),
            serviceKind: serviceKind[localServiceKindIdx]?.name,
            serviceKindId: localServiceKind,
            service: services[serviceIdx]?.name,
            serviceId: service,
            unitId: unitOfMeasure,
            unit: unityOfMeasure[unitOfMeasureIdx]?.name,
            quantity: qty,
            price: services[serviceIdx]?.price,
            objectId: '',
            scheduleId: ''
        }
        setSpecification(oldArray => [...oldArray, newElement])
    }

    const RenderTableMin = useMemo(() => {
        if (!contractId) return false
        return (
            <Box component={Paper} sx={{mt: 5, display: 'flex', padding: '0px 10px', flexWrap: 'wrap'}}>
                <TableMin edit servicesCount={servicesCount}
                          handleDeleteSpec={handleDeleteSpec}
                          unityOfMeasure={unityOfMeasure} setUnitOfMeasure={setUnitOfMeasure}
                          unitOfMeasure={unitOfMeasure}
                          services={services.map(el => ({id: el.id, name: el.name, price: el.price}))}
                          setService={setService} service={service} serviceKind={serviceKind}
                          setLocalServiceKind={setLocalServiceKind} localServiceKind={localServiceKind}
                          setQty={setQty} qty={qty} setServicesScheduleLocal={setServicesScheduleLocal}
                          servicesScheduleLocal={servicesScheduleLocal} setAnObjectsLocal={setAnObjectsLocal}
                          anObjectsLocal={anObjectsLocal} servicesScheduleCount={servicesScheduleCount}
                          servicesSchedule={servicesSchedule} anObjectsCount={anObjectsCount} anObjects={anObjects}
                          setSpecification={setSpecification} specification={specification}/>
                <Button onClick={handleFormSpec}>Добавить блок</Button>
            </Box>
        )
    }, [anObjects, anObjectsCount, anObjectsLocal, contractId, handleDeleteSpec, handleFormSpec, localServiceKind, qty, service, serviceKind, services, servicesCount, servicesSchedule, servicesScheduleCount, servicesScheduleLocal, specification, unitOfMeasure, unityOfMeasure])

    useEffect(() => {
        (async function () {
            if (contractId) {
                const res = await dispatch(getOneContract(contractId))
                setSpecification(res?.specifications?.map(el => {
                    return {
                        Id: uuidv4(),
                        serviceKind: serviceKind.find(row => row.id === el.serviceKindId)?.name,
                        serviceKindId: el.serviceKindId,
                        service: services.find(row => row.id === el.serviceId)?.name,
                        serviceId: el.serviceId,
                        unit: unityOfMeasure.find(row => row.id === el.unitId)?.name,
                        unitId: el.unitId,
                        quantity: el.quantity,
                        price: el.price,
                        objectId: el.objectId,
                        scheduleId: el.scheduleId
                    }
                }))
            }
        }())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contractId])

    const history = useHistory()

    function handleSubmit(event) {
        event.preventDefault()
        setLoadingAdd(true)
        dispatch(editInvoice(id, number, beneficiaryIdentity, beneficiaryName, beneficiaryAccountNumber, recipientCode, beneficiaryBank, beneficiaryBankCode, paymentPurposeCode, supplierIdentity, supplierName,
            buyerName, buyerName, performerName, beneficiaryId, specification, contractId, typeClient ? 'personId' : 'legalPersonId'))
        setLoadingAdd(false)
        history.push(INVOICE_ROUTE)
    }

    const dispatch = useDispatch()

    const {id} = useParams()

    useEffect(() => {
        (async function () {
            await dispatch(getUnitOfMeasure(1, null, 30))
            await dispatch(getServiceKind())
        }())
    }, [dispatch])

    useEffect(() => {
        (async function () {
            setLoading(true)
            const resService = await dispatch(getService(1, 10))
            const resUnit = await dispatch(getUnitOfMeasure(1, null, 30))
            const resServiceKind = await dispatch(getServiceKind())
            const res = await dispatch(getOneInvoice(id))
            const clients = await dispatch(getClients(1, 1, {
                filter: {
                    id: {
                        operand1: res?.beneficiaryId,
                        operator: "equals"
                    },
                }
            }))
            const client = clients?.result[0]
            if (res) {
                setBeneficiaryAccountNumber(res?.beneficiaryAccountNumber)
                setBeneficiaryBank(res?.beneficiaryBank)
                setBeneficiaryBankCode(res?.beneficiaryBankCode)
                setBeneficiaryIdentity(res?.beneficiaryIdentity)
                setBeneficiaryName(res?.beneficiaryName)
                setBuyerIdentity(res?.buyerIdentity)
                setBuyerName(res?.buyerName)
                setNumber(res?.number)
                setContractId(res?.contractId)
                setPaymentPurposeCode(res?.paymentPurposeCode)
                setPerformerName(res?.performerName)
                setRecipientCode(res?.recipientCode)
                setSupplierIdentity(res?.supplierIdentity)
                setSupplierName(res?.supplierName)
                setNumber(res?.number)
                setPerformerName(res?.performerName)
                if (client?.personId) {
                    setTypeClient(true)
                    setBeneficiaryId(client?.personId)
                } else if (client?.legalPersonId) {
                    setTypeClient(false)
                    setBeneficiaryId(client?.legalPersonId)
                }

            }
            setService(resService.result[0].id)
            setUnitOfMeasure(resUnit[0].id)
            setLocalServiceKind(resServiceKind[0].id)
            setLoading(false)
        }())
    }, [dispatch, id])

    return (
        <Dashboard>
            <BreadcrumbsShared breadcrumbs={[
                {text: 'Счета на оплату', link: INVOICE_ROUTE},
                {text: 'Редактирование счета на оплату', link: INVOICE_ROUTE + `/${id}`},
            ]}/>
            <Status type={3} status={invoiceOne?.state?.name }/>
            {loading ? <Box sx={{
                    minHeight: 556,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}><CircularProgress/></Box> :
                <Box component="form" onSubmit={handleSubmit}>
                    <Box component={Paper}
                         sx={{mt: 5, display: 'flex', padding: '0px 10px', flexWrap: 'wrap'}}>
                        <Typography sx={{margin: '5px 0'}} component="h1" align="center" variant="h5">
                            Бенефициар
                        </Typography>
                        <Grid sx={{paddingTop: 2, paddingBottom: 2}} container spacing={2}>
                            <Grid item xs={6}>
                                <Number value={number} setValue={setNumber} label="№ (Счет на оплату)"/>
                            </Grid>
                            <Grid item xs={6}>
                                <FormGroup sx={{display: 'flex', justifyContent: 'center'}}>
                                    <Typography>Тип клиента</Typography>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <Typography>юр. лицо</Typography>
                                        <Switch onChange={(event) => setTypeClient(event.target.checked)}
                                                checked={typeClient}
                                                inputProps={{'aria-label': 'ant design'}}/>
                                        <Typography>физ. лицо</Typography>
                                    </Stack>
                                </FormGroup>
                            </Grid>
                            <Grid item xs={6}>
                                {typeClient ?
                                    <SelectFieldModal edit func={getPersons} nameFilter="fullName" slice={{first: 0, second: 5}}
                                                      rowName={personTableRowName} reduxCount={personsCount}
                                                      reduxValue={persons} label="Физ. лица" value={beneficiaryId}
                                                      setValue={setBeneficiaryId}/> :
                                    <SelectFieldModal edit func={getLegalClient} nameFilter="name" slice={{first: 0, second: 5}}
                                                      rowName={legalClientTableRowName}
                                                      reduxCount={legalClientReduxCount} reduxValue={legalClientRedux}
                                                      label="Юр. лицо" value={beneficiaryId} setValue={setBeneficiaryId}/>}
                            </Grid>
                            <Grid item xs={6}>
                                <TextShared value={beneficiaryIdentity} setValue={setBeneficiaryIdentity} label="ИИН/БИН"/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextShared value={beneficiaryName} setValue={setBeneficiaryName}
                                            label="Наименование"/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextShared value={beneficiaryAccountNumber} setValue={setBeneficiaryAccountNumber}
                                            label="ИИК"/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextShared value={recipientCode} setValue={setRecipientCode}
                                            label="Кбе"/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextShared value={beneficiaryBank} setValue={setBeneficiaryBank}
                                            label="Банк бенефициар"/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextShared value={beneficiaryBankCode} setValue={setBeneficiaryBankCode}
                                            label="БИК"/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextShared value={paymentPurposeCode} setValue={setPaymentPurposeCode}
                                            label="Код назначения платежа"/>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box component={Paper}
                         sx={{mt: 5, display: 'flex', padding: '0px 10px', flexWrap: 'wrap'}}>
                        <Typography sx={{margin: '5px 0'}} component="h1" align="center" variant="h5">
                            Поставщик
                        </Typography>
                        <Grid sx={{paddingTop: 2, paddingBottom: 2}} container spacing={2}>
                            <Grid item xs={6}>
                                <TextShared value={supplierIdentity} setValue={setSupplierIdentity} label="ИИН/БИН"/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextShared value={supplierName} setValue={setSupplierName}
                                            label="Наименование"/>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box component={Paper}
                         sx={{mt: 5, display: 'flex', padding: '0px 10px', flexWrap: 'wrap'}}>
                        <Typography sx={{margin: '5px 0'}} component="h1" align="center" variant="h5">
                            Покупатель
                        </Typography>
                        <Grid sx={{paddingTop: 2, paddingBottom: 2}} container spacing={2}>
                            <Grid item xs={6}>
                                <TextShared value={buyerIdentity} setValue={setBuyerIdentity} label="ИИН/БИН"/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextShared value={buyerName} setValue={setBuyerName}
                                            label="Наименование"/>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box component={Paper}
                         sx={{mt: 5, display: 'flex', padding: '0px 10px', flexWrap: 'wrap'}}>
                        <Typography sx={{margin: '5px 0'}} component="h1" align="center" variant="h5">
                            Договор
                        </Typography>
                        <Grid sx={{paddingTop: 2, paddingBottom: 2}} container spacing={2}>
                            <Grid item xs={12}>
                                <SelectFieldModal disabled edit={true} func={getContract} nameFilter="name"
                                                  slice={{first: 0, second: 7}}
                                                  rowName={contractRequestModalRowName}
                                                  reduxCount={contractsCount}
                                                  reduxValue={contracts.map(el => {
                                                      let clientName = ''
                                                      if (el?.client?.personId) {
                                                          clientName = el?.client?.lastName + el?.client?.firstName + el?.client?.middleName
                                                      } else if (el?.client?.legalPersonId) {
                                                          clientName = el?.client?.name
                                                      } else {
                                                          clientName = 'Не задано'
                                                      }
                                                      return {
                                                          id: el.id,
                                                          name: el.name,
                                                          date: moment(el?.actionAt).format('LL'),
                                                          type: el?.type?.name,
                                                          filial: el?.filial?.name,
                                                          client: clientName,
                                                          summary: el.summary
                                                      }
                                                  })}
                                                  label="Договор" value={contractId} setValue={setContractId} args={{
                                    isAdditionalAgreement: {
                                        operand1: false,
                                        operator: "equals"
                                    }
                                }}/>
                            </Grid>
                            <Grid item xs={12}>
                                {RenderTableMin}
                            </Grid>
                        </Grid>
                    </Box>
                    <Box component={Paper}
                         sx={{mt: 5, display: 'flex', padding: '0px 10px', flexWrap: 'wrap'}}>
                        <Typography sx={{margin: '5px 0'}} component="h1" align="center" variant="h5">
                            Исполнитель
                        </Typography>
                        <Grid sx={{paddingTop: 2, paddingBottom: 2}} container spacing={2}>
                            <Grid item xs={6}>
                                <TextShared value={performerName} setValue={setPerformerName} label="Исполнитель"/>
                            </Grid>
                        </Grid>
                    </Box>
                    <LoadingButton
                        loading={loadingAdd}
                        type="submit"
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                    >
                        Изменить счёт
                    </LoadingButton>
                </Box>
            }
        </Dashboard>
    );
}

export default InvoiceEdit;