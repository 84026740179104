import React, {useContext, useEffect, useState} from 'react';
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {changeContractState} from "../../redux/actions/contract/contractActions";
import {checkRoles} from "../../functions/checkRoles";
import {showSuccessSnackbar} from "../../redux/actions/userActions";

const TableTabSigners = ({signers, status, number, reload, reloadValue}) => {
    const {t} = useTranslation();
    const rowName = [
        {text: t("employee.tableItem.employee")},
        {text: t("employee.tableItem.employeeAgree")},
        {text: t("employee.tableItem.comment")},
    ]
    const {profile} = useSelector((state) => state.profile);
    const [sendSigner, setSendSigner] = useState(false)
    const dispatch = useDispatch()
    const isAdmin = checkRoles("SuperAdministrator", "Administrator")

    useEffect(() => {
        signers.forEach(el => {
            if (isAdmin && status === 'Черновик') {
                setSendSigner(true)
            } else
            if ((el?.employee?.userId === profile?.userId || el?.userId === profile?.userId) && status === 'Черновик') {
                setSendSigner(true)
            }
        })
    }, [profile?.userId, signers, status, reload])

    const sendToSignerHandle = async () => {
        await dispatch(changeContractState(number, 1, 2))
        await dispatch(showSuccessSnackbar("Статус договора изменен!", "success"))
        await reload()
        await setSendSigner(false)
    }

    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        {rowName.map((el, idx) => {
                            return <TableCell key={idx}
                                              align="center">{el.text}</TableCell>
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {signers?.length && signers.map(el => {
                        return <TableRow
                            key={el.id}
                            hover
                            sx={{
                                '&:last-child td, &:last-child th': {border: 0},
                                cursor: 'pointer'
                            }}>
                            <TableCell align="center">
                                {el?.employee?.fullName}
                            </TableCell>
                            <TableCell align="center">
                                {el.isSigned ? 'Да' : 'Нет'}
                            </TableCell>
                            <TableCell align="center">
                                {el.comment ? el.comment : 'Комментарий пуст'}
                            </TableCell>
                        </TableRow>
                        })}
                </TableBody>
            </Table>
            {sendSigner && <Button
                sx={{margin: '10px 0 10px 10px'}} onClick={sendToSignerHandle} color="warning" variant="outlined" startIcon={<ReadMoreIcon/>}>
                Отправить на согласование
            </Button>}
        </>
    );
}

export default React.memo(TableTabSigners);
