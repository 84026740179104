import React from 'react'
import TextField from "@mui/material/TextField";

const NumberShared = ({value, label, setValue, min, max, disabled, sx, required, input}) => {
    return (
        <TextField
            margin="normal"
            required={required}
            disabled={disabled}
            value={value}
            sx={sx}
            InputProps={{ inputProps: { min: min, max: max, step: "0.1" }, ...input}}
            onChange={(e) => setValue(e.target.value)}
            fullWidth
            type="number"
            label={label}
        />
    );
}

NumberShared.defaultProps = {
    min: "1",
    max: "100000",
    disabled: false,
    required: true
}

export default NumberShared