import {
    ADD_PRODUCT_TO_BASKET_FAIL,
    ADD_PRODUCT_TO_BASKET_REQUEST,
    ADD_PRODUCT_TO_BASKET_START,
    ADD_PRODUCT_TO_BASKET_SUCCESS, CLEAR_BASKET, DELETE_ITEM_FROM_BASKET, SET_COUNT_BASKET
} from "../../constants/basket/basketConstants";

const initialState = {
    basket: [],
    loading: false,
    success: null,
    error: null
};

export const basketReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_PRODUCT_TO_BASKET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CLEAR_BASKET:
            return {
                ...state,
                basket: [],
            };
        case SET_COUNT_BASKET:
            return  {
                ...state,
                basket: state.basket.map(el => {
                    if (el.id !== action.payload.id) return el
                    if (el.count >= 1 && action.payload.type === 'PLUS') {
                        return {
                            ...el,
                            count: el.count + 1
                        }
                    } else if (el.count > 1 && action.payload.type === 'MINUS') {
                        return {
                            ...el,
                            count: el.count - 1
                        }
                    }
                    else {
                        return {
                            ...el
                        }
                    }
                })
            };
        case DELETE_ITEM_FROM_BASKET:
            const item = state.basket.findIndex(el => el.id === action.payload.id)
            const newArr = [
                ...state.basket.slice(0, item), // клонируем все элементы до индекса
                ...state.basket.slice(item+1), // клонируем все элементы после индекса
            ]
            return {
                ...state,
                basket: newArr,
            };
        case ADD_PRODUCT_TO_BASKET_SUCCESS:
            const hasAlready = state.basket.findIndex(el => el.id === action.payload.id)
            if (hasAlready !== -1) {
                let newBasket = JSON.parse(JSON.stringify(state.basket));
                newBasket[hasAlready].summary = (newBasket[hasAlready].qty + action.payload.qty) * newBasket[hasAlready].price
                newBasket[hasAlready].qty = newBasket[hasAlready].qty + action.payload.qty
                return {
                    ...state,
                    basket: [...newBasket],
                    loading: false
                }
            } else {
                return {
                    ...state,
                    basket: [...state.basket, action.payload],
                    loading: false
                }
            }
        case ADD_PRODUCT_TO_BASKET_START:
            return {
                ...state,
                basket: action.payload,
                loading: false
            };
        case ADD_PRODUCT_TO_BASKET_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}