import React, {useEffect, useState} from 'react';
import {makeStyles} from '@mui/styles';
import {
    Button, Checkbox,
    Collapse,
    Divider,
    Drawer, FormControlLabel, FormGroup, Stack, Switch,
    Typography
} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import {DatePickerShared, SelectShared} from "../index";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {$authHost} from "../../http";
import {getContractsState} from "../../redux/actions/contract/contractActions";
import clsx from "clsx";
import PersonField from "../Fields/PersonField";
import LegalClientField from "../Fields/LegalClientField";

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    drawer: {
        width: 420,
        maxWidth: '100%'
    },
    header: {
        padding: theme.spacing(2, 1),
        display: 'flex',
        justifyContent: 'space-between'
    },
    buttonIcon: {
        marginRight: theme.spacing(1)
    },
    content: {
        padding: theme.spacing(0, 3),
        flexGrow: 1
    },
    contentSection: {
        padding: theme.spacing(2, 0)
    },
    contentSectionHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        cursor: 'pointer'
    },
    contentSectionContent: {},
    formGroup: {
        padding: theme.spacing(2, 0)
    },
    fieldGroup: {
        display: 'flex',
        alignItems: 'center'
    },
    field: {
        marginTop: 0,
        marginBottom: 0
    },
    flexGrow: {
        flexGrow: 1
    },
    addButton: {
        marginLeft: theme.spacing(1)
    },
    tags: {
        marginTop: theme.spacing(1)
    },
    minAmount: {
        marginRight: theme.spacing(3)
    },
    maxAmount: {
        marginLeft: theme.spacing(3)
    },
    radioGroup: {},
    actions: {
        padding: theme.spacing(3),
        '& > * + *': {
            marginTop: theme.spacing(2)
        }
    }
}));

const Filter = props => {
    const {open, onClose, setFilter, setPage, filter, className, ...rest} = props;

    const classes = useStyles();
    const {t} = useTranslation();

    const [person, setPerson] = useState('')
    const [legalClient, setLegalClient] = useState('')
    const [actionAt, setActionAt] = useState(new Date())
    const [actionAtBoolean, setActionAtBoolean] = useState(false)
    const {contractsState} = useSelector((state) => state.contract);
    const [stateId, setStateId] = useState(0)

    const [expandProject, setExpandProject] = useState(true);
    const [typeClient, setTypeContract] = useState(false)

    const handleToggleProject = () => {
        setExpandProject(expandProject => !expandProject);
    };

    const dispatch = useDispatch()

    useEffect(() => {
        (async function () {
            if (open) {
                await dispatch(getContractsState(1, 10, null))
            }
        }())
    }, [dispatch, open]);

    const handleFilterSubmit = async () => {
        let nameFilter
        if (person && typeClient)
            nameFilter = 'personId'
        else if (legalClient && !typeClient)
            nameFilter = 'legalPersonId'
        if (nameFilter && !stateId && !actionAtBoolean) {
            const {data: dataClient} = await $authHost.post(
                "/api/v1/client/query",
                {
                        filter: {
                            [nameFilter]: {
                            operand1: person && typeClient ? person : legalClient && !typeClient ? legalClient : '',
                            operator: "equals"
                        },
                        }
                }
            )
            const newFilter = {
                    clientId: {
                        operand1: dataClient?.result[0]?.id,
                        operator: "equals"
                    },
            }
            setFilter(newFilter)
            setPage(0)
        }
        if (!nameFilter && stateId && !actionAtBoolean) {
            const newFilter = {
                    stateId: {
                        operand1: stateId,
                        operator: "equals"
                    },
            }
            setFilter(newFilter)
            setPage(0)
        }
        if (nameFilter && stateId && !actionAtBoolean) {
            const {data: dataClient} = await $authHost.post(
                "/api/v1/client/query",
                {filter: {
                        [nameFilter]: {
                            operand1: person && typeClient ? person : legalClient && !typeClient ? legalClient : '',
                            operator: "equals"
                        },}
                }
            )
            const newFilter = {
                    clientId: {
                        operand1: dataClient?.result[0]?.id,
                        operator: "equals"
                    },
                    stateId: {
                        operand1: stateId,
                        operator: "equals"
                    },
            }
            setFilter(newFilter)
            setPage(0)
        }
        if (!nameFilter && !stateId && actionAtBoolean) {
            const newFilter = {
                actionAt: {
                    operand1: actionAt,
                    operator: "equals"
                },
            }
            setFilter(newFilter)
            setPage(0)
        }
        if (nameFilter && stateId && actionAtBoolean) {
            const {data: dataClient} = await $authHost.post(
                "/api/v1/client/query",
                {filter: {
                    [nameFilter]: {
                        operand1: person && typeClient ? person : legalClient && !typeClient ? legalClient : '',
                        operator: "equals"
                    }},
                }
            )
            const newFilter = {
                clientId: {
                    operand1: dataClient?.result[0]?.id,
                    operator: "equals"
                },
                stateId: {
                    operand1: stateId,
                    operator: "equals"
                },
                actionAt: {
                    operand1: actionAt,
                    operator: "equals"
                },
            }
            setFilter(newFilter)
            setPage(0)
        }
    }

    const handleSubmit = event => {
        event.preventDefault();
    };

    const handleClearFilter = () => {
        setPerson('')
        setLegalClient('')
        setStateId(0)
        setTypeContract(false)
        setActionAtBoolean(false)
        setFilter({})
        setPage(0)
    }

    const filterAccess = (!person && !legalClient) && !stateId && !actionAtBoolean

    return (
        <Drawer
            anchor="right"
            classes={{paper: classes.drawer}}
            onClose={onClose}
            open={open}
            variant="temporary"
        >
            <form
                {...rest}
                className={clsx(classes.root, className)}
                onSubmit={handleSubmit}
            >
                <div className={classes.header}>
                    <Button
                        onClick={onClose}
                        size="small"
                    >
                        <CloseIcon className={classes.buttonIcon}/>
                        Close
                    </Button>
                </div>
                <div className={classes.content}>
                    <div className={classes.contentSection}>
                        <div
                            className={classes.contentSectionHeader}
                            onClick={handleToggleProject}
                        >
                            <Typography variant="h5">Фильтрация по договору</Typography>
                            {expandProject ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                        </div>
                        <Divider/>
                        <Collapse in={expandProject}>
                            <div className={classes.contentSectionContent}>
                                <div className={classes.formGroup}>
                                    <FormGroup sx={{display: 'flex', justifyContent: 'center'}}>
                                        <Typography>Тип клиента</Typography>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography>юр. лицо</Typography>
                                            <Switch onChange={(event) => setTypeContract(event.target.checked)}
                                                    checked={typeClient}
                                                    inputProps={{'aria-label': 'ant design'}}/>
                                            <Typography>физ. лицо</Typography>
                                        </Stack>
                                    </FormGroup>
                                </div>
                                <div className={classes.formGroup}>
                                    {typeClient ?
                                        <PersonField
                                            fullWidth
                                            value={person}
                                            label="Контакт (физ. лицо)"
                                            sx={{paddingTop: '35px'}}
                                            onChange={(client) => {
                                                setPerson(client.id)
                                            }}
                                        /> :
                                        <LegalClientField
                                            fullWidth
                                            value={legalClient}
                                            label="Контакт (юр. лицо)"
                                            sx={{paddingTop: '35px'}}
                                            onChange={(client) => {
                                                setLegalClient(client.id)
                                            }}
                                        />}
                                </div>
                                <div className={classes.formGroup}>
                                    <SelectShared value={stateId} setValue={setStateId} array={contractsState.result} label="Статус"/>
                                </div>
                                <div className={classes.formGroup}>
                                    <FormControlLabel control={<Checkbox checked={actionAtBoolean}
                                                                         onChange={(event) => setActionAtBoolean(event.target.checked)}
                                    />} label="Фильтрация по дате"/>
                                </div>
                                {actionAtBoolean && <div className={classes.formGroup}>
                                    <DatePickerShared value={actionAt} setValue={setActionAt} label="Дата создания"/>
                                </div>}
                            </div>
                        </Collapse>
                    </div>
                </div>
                <div className={classes.actions}>
                    <Button
                        fullWidth
                        onClick={handleClearFilter}
                        sx={{marginBottom: 2}}
                        variant="contained"
                    >
                        <DeleteIcon className={classes.buttonIcon}/>
                        Очистить фильтры
                    </Button>
                    <Button
                        color="primary"
                        disabled={filterAccess}
                        onClick={handleFilterSubmit}
                        fullWidth
                        type="submit"
                        variant="contained"
                    >
                        Применить фильтры
                    </Button>
                </div>
            </form>
        </Drawer>
    );
};

export default Filter;
