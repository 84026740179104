import React from 'react';
import DashboardLayout from "../../layouts/Dashboard";

const Reports = () => {
    return (
        <DashboardLayout>
            reports
        </DashboardLayout>
    );
};

export default Reports;