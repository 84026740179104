import React, {useCallback, useEffect, useState} from 'react';
import Dashboard from "../../layouts/Dashboard";
import {CONTRACT_ADDITIONAL_ADD_ROUTE, CONTRACT_ADDITIONAL_ROUTE} from "../../routes/const";
import BreadcrumbsShared from "../../components/Shared/BreadcrumbsShared";
import {Box, CircularProgress, Step, StepLabel, Stepper} from "@mui/material";
import Paper from "@mui/material/Paper";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import {v4 as uuidv4} from "uuid";
import {getClients} from "../../redux/actions/client/clientActions";
import {getTypeContract} from "../../redux/actions/contract/contractActions";
import {getServiceKind, getUnitOfMeasure} from "../../redux/actions/genericActions";
import {getService} from "../../redux/actions/service/serviceActions";
import {getGeoObject} from "../../redux/actions/geo/geoActions";
import {getEducationServiceKind} from "../../redux/actions/educationService/educationServiceActions";
import {getEmployee} from "../../redux/actions/employee/employeeActions";
import {getFilial, getFilialDivision} from "../../redux/actions/filial/filialActions";
import {AddDate, AddSigners, AddSpecification} from "../index";
import LoadingDocument from "../Contract/Pages/Add/LoadingDocument";
import GenerateDocument from "../Contract/Pages/Add/GenerateDocument";
import {PopupContractSpecificationLocal} from "../../components";
import PopupWarning from "../../components/Popups/message/PopupWarning";
import {ContractContext} from "../Contract/ContractPage";

function ContractAdditionalAdd(props) {

    const [steps, setSteps] = useState(['Добавление данных', 'Добавление специфики', 'Добавление согласующих', 'Формирование договора']);
    const [qty, setQty] = useState(1)
    const [unitOfMeasure, setUnitOfMeasure] = useState(1)
    const [service, setService] = useState(1)
    const [localServiceKind, setLocalServiceKind] = useState(1)
    const {services, servicesCount, servicesPrice} = useSelector((state) => state.service);
    const {unityOfMeasure, serviceKind} = useSelector((state) => state.generic);
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());

    const [specification, setSpecification] = useState([]);
    const [signers, setSigners] = useState([])

    const handleDeleteSpec = async (row) => {
        const newSpec = specification.filter(el => el.Id !== row)
        await setSpecification(newSpec);
    }

    const [data, setData] = useState({
        isDiscount: false,
        toDecrease: false,
        toIncrease: false,
        discountComment: ''
    })

    const handleChangeObject = useCallback(
        (key, value) => setData({...data, [key]: value}),
        [data]
    );

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const [localCity, setLocalCity] = useState(1)
    const {geo} = useSelector((state) => state.geo);

    const {filial, filialDivision} = useSelector((state) => state.filial);

    const [localFilial, setLocalFilial] = useState(1)
    const [localFilialDivision, setLocalFilialDivision] = useState()

    const [name, setName] = useState('')
    const [loading, setLoading] = useState(true)
    const [typeContract, setTypeContract] = useState(false)
    const [number, setNumber] = useState(1)
    const [registeredAt, setRegisteredAt] = useState(new Date())
    const [actionAt, setActionAt] = useState(new Date())

    const [client, setClient] = useState('')
    const [legalClient, setLegalClient] = useState('')
    const [contractObject, setContractObject] = useState([])

    const [type, setType] = useState(3)
    const [square, setSquare] = useState(0)
    const [multiplicity, setMultiplicity] = useState(0)


    const [active, setActive] = useState(false)
    const dispatch = useDispatch()
    const {contractType} = useSelector((state) => state.contract);
    const {client: clientRedux} = useSelector((state) => state.client);
    const {
        legalClient: legalClientRedux,
        legalClientCount: legalClientReduxCount
    } = useSelector((state) => state.legalClient);
    const {persons, personsCount} = useSelector((state) => state.person);
    const {employee, employeeCount} = useSelector((state) => state.employee);

    const {anObjects, anObjectsCount} = useSelector((state) => state.anObject);
    const {servicesSchedule, servicesScheduleCount} = useSelector((state) => state.service);

    const [anObjectsLocal, setAnObjectsLocal] = useState(1)
    const [servicesScheduleLocal, setServicesScheduleLocal] = useState(1)
    const [openModal, setOpenModal] = useState(false)
    const [rowsModal, setRowsModal] = useState({})
    const [date1, setDate1] = useState()
    const [date2, setDate2] = useState()
    const [district, setDistrict] = useState([])

    const setTypeHandle = (val) => {
        setType(val)
    }

    useEffect(() => {
        if (type === 3 || type === 4) {
            setSteps(['Добавление данных', 'Добавление специфики', 'Загрузка договора'])
        } else {
            setSteps(['Добавление данных', 'Добавление специфики', 'Добавление согласующих', 'Формирование доп. соглашения'])
        }
    }, [type])

    const handleForm = async (e) => {
        e.preventDefault()
        handleNext()
    }

    const handleFormGenerate = async (e) => {
        e.preventDefault()
        setDate1(moment(registeredAt).format('LL'))
        setDate2(moment(actionAt).format('LL'))
        setActive(true)
    }

    const handleOpenEditPopup = async (row) => {
        await setRowsModal(row)
        setOpenModal(true)
    }

    const handleFormSpec = async (e) => {
        e.preventDefault()
        const newElement = {
            Id: uuidv4(),
            serviceId: '',
            service: {},
            unitId: '',
            quantity: 1,
            price: 0,
            objectId: '',
            scheduleId: '',
            square: 1,
            multiplicity: 1,
        }
        setSpecification(oldArray => [...oldArray, newElement])
    }

    const handleSigners = async (e) => {
        e.preventDefault()
        const newElement = {
            Id: uuidv4(),
            employeeId: '',
            isSigned: false
        }
        setSigners(oldArray => [...oldArray, newElement])
    }

    const handleContractObjectRequest = () => {
        const newElement = {
            Id: uuidv4(),
            name: '',
            district: '',
            districtId: '',
            city: '',
            cityId: '',
            locality: '',
            localityId: '',
            addressLine: '',
            objectId: ''
        }
        setContractObject(oldArray => [...oldArray, newElement])
    }

    useEffect(() => {
        setLoading(true)
        let resType
        let resUnit
        let resService
        let resCity
        let resFilial
        let resFilialDivision
        const getLocal = async () => {
            resType = await dispatch(getTypeContract())
            resUnit = await dispatch(getUnitOfMeasure(1, null, 30))
            setDistrict(await dispatch(getGeoObject(1, 100, {
                filter: {
                    typeId: {
                        operand1: 3,
                        operator: "equals"
                    },
                }
            }, true)))
            await dispatch(getEducationServiceKind())
            resCity = await dispatch(getGeoObject(1, 100, {filter: {
                    typeId: {
                        operand1: 2,
                        operator: "equals"
                    },
                }}))
            resFilial = await dispatch(getFilial(1, 100))
            resFilialDivision = await dispatch(getFilialDivision())

            if (resService && resUnit && resType && resCity && resFilial && resFilialDivision) {
                setLocalCity(resCity[0].id)
                setUnitOfMeasure(resUnit[0].id)
                setService(resService.result[0].id)
                setLocalFilial(resFilial[0].id)
            }
            setLoading(false)
        }
        getLocal()
    }, [dispatch])

    const [educationServices, setEducationServices] = useState([])
    const [localEducationServiceKind] = useState('')
    const {educationServiceKind} = useSelector((state) => state.education);

    const [isAdditional] = useState(true)

    const handleEducationServices = () => {
        const educationServiceKindIdx = educationServiceKind.findIndex(el => el.id === localEducationServiceKind)
        const newElement = {
            Id: uuidv4(),
            kind: educationServiceKind[educationServiceKindIdx]?.name,
            kindId: localEducationServiceKind,
            service: '',
            serviceId: '',
        }
        setEducationServices(oldArray => [...oldArray, newElement])
    }

    const [schedules, setSchedules] = useState([])
    const [localTargetMonth, setLocalTargetMonth] = useState(new Date())
    const [localTargetYear, setLocalTargetYear] = useState(new Date())

    const handleSchedules = () => {
        const newElement = {
            Id: uuidv4(),
            targetMonth: localTargetMonth,
            targetYear: localTargetYear,
        }
        setSchedules(oldArray => [...oldArray, newElement])
    }

    const [contractId, setContractId] = useState('')
    const {contracts, contractsCount} = useSelector((state) => state.contract);

    return (
        <Dashboard>
            <BreadcrumbsShared breadcrumbs={[
                {text: 'Доп. соглашения', link: CONTRACT_ADDITIONAL_ROUTE},
                {text: 'Добавление доп. соглашения', link: CONTRACT_ADDITIONAL_ADD_ROUTE},
            ]}/>
            <Paper sx={{pb: 5}}>
                {loading ? <Box sx={{
                    minHeight: 556,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}><CircularProgress/></Box> : <Box sx={{pt: 5}}>
                    <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};
                            if (isStepSkipped(index)) {
                                stepProps.completed = false;
                            }
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    {activeStep === 0 &&
                    <ContractContext.Provider value={{contracts, contractsCount, isAdditional, contractId, setContractId, handleSchedules, localTargetYear, setLocalTargetYear, localTargetMonth, setLocalTargetMonth, setSchedules, schedules, setEducationServices, educationServiceKind, localEducationServiceKind, educationServices, handleEducationServices, anObjectsCount, anObjects ,district, handleContractObjectRequest, contractObject, setContractObject,
                        handleChangeObject, data
                    }}>
                        <AddDate filial={filial} filialDivision={filialDivision} setLocalFilial={setLocalFilial} setLocalFilialDivision={setLocalFilialDivision} localFilial={localFilial} localFilialDivision={localFilialDivision} geo={geo} localCity={localCity} setLocalCity={setLocalCity} personRedux={persons} personCount={personsCount} legalClientRedux={legalClientRedux}
                                 legalClientReduxCount={legalClientReduxCount} legalClient={legalClient}
                                 setLegalClient={setLegalClient} typeContract={typeContract}
                                 setTypeContract={setTypeContract} activeStep={activeStep} handleBack={handleBack}
                                 steps={steps} number={number}
                                 setNumber={setNumber} client={client} type={type} name={name}
                                 setName={setName} actionAt={actionAt} clientRedux={clientRedux} contractType={contractType}
                                 setActionAt={setActionAt} handleForm={handleForm}
                                 registeredAt={registeredAt} setRegisteredAt={setRegisteredAt} setClient={setClient}
                                 setType={setTypeHandle}/>
                    </ContractContext.Provider>}
                    {activeStep === 1  &&
                        <ContractContext.Provider value={{
                            square, setSquare,
                            multiplicity, setMultiplicity,
                        }}>
                    <AddSpecification servicesCount={servicesCount} handleDeleteSpec={handleDeleteSpec} anObjects={anObjects}
                                      anObjectsLocal={anObjectsLocal} setAnObjectsLocal={setAnObjectsLocal}
                                      anObjectsCount={anObjectsCount} servicesScheduleLocal={servicesScheduleLocal}
                                      servicesSchedule={servicesSchedule} servicesScheduleCount={servicesScheduleCount}
                                      setServicesScheduleLocal={setServicesScheduleLocal} setOpen={handleOpenEditPopup}
                                      handleNext={handleNext}
                                      setSpecification={setSpecification} specification={specification}
                                      handleFormSpec={handleFormSpec} steps={steps} handleBack={handleBack}
                                      activeStep={activeStep} service={service}
                                      setService={setService} serviceKind={serviceKind}
                                      localServiceKind={localServiceKind}
                                      services={services.map(el => ({id: el.id, name: el.name, price: el.price}))} servicesPrice={servicesPrice}
                                      qty={qty} setQty={setQty} setLocalServiceKind={setLocalServiceKind}
                                      setUnitOfMeasure={setUnitOfMeasure}
                                      unitOfMeasure={unitOfMeasure} unityOfMeasure={unityOfMeasure}
                    /></ContractContext.Provider>}
                    {activeStep === 2 && (type === 1 || type === 2) &&
                    <AddSigners activeStep={activeStep} handleBack={handleBack} steps={steps} handleNext={handleNext} signers={signers} setSigners={setSigners} employee={employee} employeeCount={employeeCount} handleSigners={handleSigners}
                    />}
                    {activeStep === 2 && (type === 3 || type === 4) && <LoadingDocument contractId={contractId} isAdditional={isAdditional} contractObject={contractObject} educationServices={educationServices} schedules={schedules} geo={geo} filial={filial} localFilial={localFilial} localFilialDivision={localFilialDivision} localCity={localCity} legalClient={legalClient} typeContract={typeContract} registeredAt={registeredAt}
                                                                                        actionAt={actionAt} type={type} client={client} activeStep={activeStep}
                                                                                        handleBack={handleBack} name={name} number={number} specification={specification}
                                                                                        active={active} date1={date1} date2={date2} signers={signers}/>}
                    {activeStep === 3 &&
                    <GenerateDocument data={data} contractId={contractId} isAdditional={isAdditional} contractObject={contractObject} educationServices={educationServices} schedules={schedules} geo={geo} filial={filial} localFilial={localFilial} localFilialDivision={localFilialDivision} localCity={localCity} legalClient={legalClient} typeContract={typeContract} registeredAt={registeredAt}
                                      actionAt={actionAt} type={type} client={client} activeStep={activeStep}
                                      handleBack={handleBack} name={name} number={number} specification={specification}
                                      active={active} date1={date1} date2={date2} signers={signers}
                                      handleFormGenerate={handleFormGenerate}/>}
                </Box>}
            </Paper>
            <PopupContractSpecificationLocal specification={specification} setOpen={setOpenModal} open={openModal}
                                             setSpecification={setSpecification}
                                             unitOfMeasureState={rowsModal.unitId}
                                             servicePriceState={rowsModal.servicePriceListId}
                                             serviceState={rowsModal.serviceId}
                                             localServiceKindState={rowsModal.serviceKindId}
                                             Id={rowsModal.Id}
                                             quantity={rowsModal.quantity}
                                             serviceKind={serviceKind}
                                             services={services}
                                             servicesPrice={servicesPrice}
                                             unityOfMeasure={unityOfMeasure}

            />
            <PopupWarning/>
        </Dashboard>
    );
}

export default ContractAdditionalAdd;
