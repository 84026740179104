import {
    ONE_PERSON_EDIT_ERROR,
    ONE_PERSON_EDIT_REQUEST, ONE_PERSON_EDIT_SUCCESS,
    ONE_PERSON_GET_ERROR,
    ONE_PERSON_GET_REQUEST, ONE_PERSON_GET_SUCCESS,
    PERSON_ADD_ERROR,
    PERSON_ADD_REQUEST,
    PERSON_ADD_SUCCESS, PERSON_DELETE_ERROR,
    PERSON_DELETE_REQUEST, PERSON_DELETE_SUCCESS,
    PERSON_EDIT_ERROR,
    PERSON_EDIT_REQUEST,
    PERSON_EDIT_SUCCESS,
    PERSON_GET_ERROR,
    PERSON_GET_REQUEST,
    PERSON_GET_SUCCESS
} from "../../constants/client/personConstants";

const initialState = {
    persons: [],
    personsCount: {},
    onePerson: {},
    loading: false,
    success: null,
    error: null
};

export const personReducer = (state = initialState, action) => {
    switch (action.type) {
        case PERSON_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PERSON_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true
            };
        case PERSON_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case PERSON_GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PERSON_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                persons: action.payload.result,
                personsCount: action.payload.pageCount,
                success: true
            };
        case PERSON_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case ONE_PERSON_GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ONE_PERSON_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                onePerson: action.payload,
                success: true
            };
        case ONE_PERSON_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case ONE_PERSON_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ONE_PERSON_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                onePerson: {...state.onePerson, ...action.payload},
                success: true
            };
        case ONE_PERSON_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case PERSON_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PERSON_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true
            };
        case PERSON_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case PERSON_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PERSON_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true
            };
        case PERSON_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        default:
            return state;
    }
}