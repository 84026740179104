import React from 'react';

import {clientCRUD} from "../../../http/CRUD";
import CRUDTableQuery from "../CRUDTableQuery";
import useReload from "../../../hooks/useReload";
import CRUDTableStandart from "../CRUDTableStandart";

export default function ClientCRUDTable({type = 'standart', ...props}) {
    const { reload, reloadValue } = useReload();
    const columns = [
        {title: "#", dataIndex: 'id', key: 'id'},
        {
            title: "Физ. лицо",
            key: 'person',
            render: (unknown, row) => row.person?.fullName ? row.person?.fullName : row.person?.firstName ? `${row.person?.firstName} ${row.person?.secondName} ${row.person?.lastName}` :
                <span style={{color: "gray"}}>Не физ. лицо</span>
        },
        {
            title: "Юр. лицо",
            key: 'legalPerson',
            render: (unknown, row) => row.legalPerson ? row.legalPerson.name :
                <span style={{color: "gray"}}>Не юр. лицо</span>
        },
    ]
    const searchFields = [
        // {
        //     title: "Название клиента",
        //     key: "name",
        //     compareType: "like",
        //     operandPosition: 1,
        //     filterType: "normal"
        // },
        // {
        //     title: "Бин",
        //     key: "bin",
        //     compareType: "like",
        //     operandPosition: 1,
        //     filterType: "normal"
        // }
    ]

    const CRUDTables = {
        standart: CRUDTableStandart,
        query: CRUDTableQuery,
    }

    const CRUDTable = CRUDTables[type]


    return (
        <>
            <CRUDTable search={clientCRUD.search}
                            columns={columns}
                            searchFields={searchFields}
                            reloadValue={reloadValue}
                            {...props}
            />
        </>
    );
}
