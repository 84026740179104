import {
    CONTRACT_ONE_REQUEST_ERROR,
    CONTRACT_ONE_REQUEST_REQUEST, CONTRACT_ONE_REQUEST_SUCCESS,
    CONTRACT_REQUEST_ERROR, CONTRACT_REQUEST_GET_ERROR, CONTRACT_REQUEST_GET_REQUEST, CONTRACT_REQUEST_GET_SUCCESS,
    CONTRACT_REQUEST_REQUEST,
    CONTRACT_REQUEST_SUCCESS
} from "../../constants/contractRequest/contractRequestConstants";

const initialState = {
    contractsRequest: [],
    contractRequestOne: {},
    contractsRequestCount: null,
    loading: false,
    success: null,
    error: null
};

export const contractRequestReducer = (state = initialState, action) => {
    switch (action.type) {
        case CONTRACT_ONE_REQUEST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CONTRACT_ONE_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                contractRequestOne: action.payload,
                success: true
            };
        case CONTRACT_ONE_REQUEST_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case CONTRACT_REQUEST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CONTRACT_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case CONTRACT_REQUEST_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case CONTRACT_REQUEST_GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CONTRACT_REQUEST_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                contractsRequest: action.payload.result,
                contractsRequestCount: action.payload.total
            };
        case CONTRACT_REQUEST_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        default:
            return state;
    }
}