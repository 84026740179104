import React from 'react';
import {Card, CardActionArea, CardContent, CardMedia} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useHistory} from "react-router-dom";
import {GENERAL_ROUTE} from "../../routes/const";

function CardGeneral({el}) {
    const images = {
        1: '/images/cardGeneral.svg',
        2: '/images/cardGeneral.svg',
        3: '/images/cardGeneral2.svg',
        4: '/images/cardGeneral3.svg',
        5: '/images/cardGeneral4.svg',
        6: '/images/cardGeneral.svg',
        7: '/images/cardGeneral.svg',
        8: '/images/cardGeneral4.svg',
        9: '/images/cardGeneral5.svg',
        10: '/images/cardGeneral6.svg',
        11: '/images/cardGeneral.svg',
        12: '/images/cardGeneral.svg',
        13: '/images/cardGeneral.svg',

    }
    const history = useHistory()
    return (
        <Card>
            <CardActionArea onClick={() => history.push(GENERAL_ROUTE + `/${el.id}`)} sx={{height: 300, display: 'flex', flexDirection: 'column', paddingTop: 2}}>
                <CardMedia
                    component="img"
                    height="140"
                    image={images[el.id]}
                    sx={{maxWidth: 105, objectFit: 'contain'}}
                />
                <CardContent>
                    <Typography align="center" gutterBottom variant="h6" component="div">
                        {el.name}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

export default CardGeneral;