import React from 'react';
import {Collapse, Divider, List, ListItem} from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import IconExpandLess from '@mui/icons-material/ExpandLess'
import IconExpandMore from '@mui/icons-material/ExpandMore'
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";

const ListItemDropDown = ({icon, pathname, open, items, text, type}) => {
    const dispatch = useDispatch()
    const openHandler = async () => {
        open ? dispatch({type: `CLOSE_LIST_${type}`}) : dispatch({type: `OPEN_LIST_${type}`})
    }
    const history = useHistory()
    return (
        <>
            <ListItem button onClick={openHandler}>
                <ListItemIcon>
                    {icon}
                </ListItemIcon>
                <ListItemText primary={text}/>
                {open ? <IconExpandLess/> : <IconExpandMore/>}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Divider/>
                <List component="div" disablePadding>
                    {items.map((el, idx) => (
                        <ListItem onClick={() => {
                            history.push('/' + el.route)
                        }} key={idx} button selected={pathname.split('/')[1] === el.route}>
                            <ListItemIcon>
                                {el.icon}
                            </ListItemIcon>
                            <ListItemText primary={el.text}/>
                        </ListItem>
                    ))}
                </List>
            </Collapse>
        </>
    );
};

export default ListItemDropDown;