import React from 'react';
import DashboardProfile from "../../layouts/DashboardProfile";
import {Button, Paper} from "@mui/material";
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import PersonalInformation from "./PersonalInformation";
import ChangePassword from "./ChangePassword";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {MAIN_ROUTE, PROFILE_ROUTE} from "../../routes/const";
import ClientLayout from "../../layouts/ClientLayout";
import LegalPersonalInformation from "./LegalPersonalInformation";
import BreadcrumbsShared from "../../components/Shared/BreadcrumbsShared";

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));


function Profile(props) {
    const userLogin = useSelector((state) => state.user);
    const {isClient} = userLogin;
    const history = useHistory()
    const [expanded, setExpanded] = React.useState('panel3');
    const {profile} = useSelector((state) => state.profile);
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const components = {
        client: ClientLayout,
        user: DashboardProfile
    }

    const TagName = components[isClient? 'client': 'user']

    return (
        <TagName>
            <BreadcrumbsShared breadcrumbs={[
                {text: 'Профиль', link: PROFILE_ROUTE}
            ]}/>
            <Paper>
                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                        <Typography>Персональные данные</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {profile?.type?.id === 1 && <PersonalInformation person={profile?.client?.person}/>}
                        {profile?.type?.id === 2 && <LegalPersonalInformation legalPerson={profile?.client?.legalPerson}/>}
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                    <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                        <Typography>Настройки</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ChangePassword/>
                    </AccordionDetails>
                </Accordion>
                <Button onClick={() => history.push(MAIN_ROUTE)} sx={{margin: '15px 0 15px 15px'}} variant="contained">На главную</Button>
            </Paper>
        </TagName>
    );
}

export default Profile;