import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {makeStyles} from "@mui/styles";
import {getOnePerson} from "../../../redux/actions/client/personActions";
import DashboardLayout from "../../../layouts/Dashboard";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableRow
} from "@mui/material";
import {EditIconCustom, PopupPerson} from "../../../components";

const useStyles = makeStyles(theme => ({
    root: {},
    content: {
        padding: 0
    },
    actions: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        '& > * + *': {
            marginLeft: 0
        }
    }
}));

const PersonPage = () => {
    const {t} = useTranslation();
    const classes = useStyles();
    const {id} = useParams()
    const [open, setOpen] = useState(false);
    const [edit, setEdit] = useState(true);
    const dispatch = useDispatch()
    const {onePerson} = useSelector((state) => state.person);
    useEffect(() => {
        dispatch(getOnePerson(id))
    }, [dispatch, id])
    return (
        <DashboardLayout>
            <Paper>
                <Card
                    className={classes.root}
                >
                    <CardHeader title={onePerson.fullName + ' (физ. лицо)'}/>
                    <Divider/>
                    <CardContent className={classes.content}>
                        <Table>
                            <TableBody>
                                {Object.keys(onePerson).slice(0, 10).map((el, idx) => (
                                    <TableRow key={idx}>
                                        <TableCell>{t(`client.tableItem.${[el]}`)}</TableCell>
                                        <TableCell>{onePerson[el]}</TableCell>
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <TableCell>{t(`client.tableItem.gender`)}</TableCell>
                                    <TableCell>{onePerson.gender && onePerson.gender.name}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </CardContent>
                    <CardActions className={classes.actions}>
                        <Button onClick={() => setOpen(true)}>
                            <EditIconCustom/>
                            <Box sx={{marginLeft: '1rem'}}>Редактировать</Box>
                        </Button>
                    </CardActions>
                </Card>
            </Paper>
            <PopupPerson id={id} details={true} edit={edit} setEdit={setEdit} title="Клиенты (физ. лица)" open={open}
                              setOpen={setOpen}/>
        </DashboardLayout>
    );
};

export default PersonPage;