export const initialValueLegalContract2 = ({
                                               specification,
                                               proxy,
                                               supervisorName = 'Не задано',
                                               city = 'Не задано',
                                               position,
                                               clientData: clientDataServer,
                                               number = 'Не задано',
                                               filial,
                                               filialDate: filialDateServer,
                                               client = 'Не задано',
                                               inn = 'Не задано',
                                               bin = 'Не задано',
                                               date2,
                                               date1,
                                               price,
                                               filialKz,
                                               phoneNumber = 'Не задано',
                                               addressLine = 'Не задано',
                                               supervisor = 'Не задано',
                                               contractObject,
                                               actionAt ='Не задано',
                                               deadline ='Не задано'
                                           }) => {
    let clientData
    if (clientDataServer) {
        clientData = {...clientDataServer}
        Object.keys(clientDataServer).map(el => {
            if (!clientData[el]) {
                clientData[el] = 'Не задано'
            }
        })
    }
    let filialDate = {}
    if (filialDateServer) {
        filialDate = {...filialDateServer}
        Object.keys(filialDateServer).map(el => {
            if (!filialDate[el]) {
                filialDate[el] = 'Не задано'
            }
        })
    }
    let sum = 0;
    specification.map(el => {
      sum += ((el.quantity ? el.quantity : 1) * el.price)
   })
    return `<figure class="table">
    <table>
        <tbody>
        <tr>
            <td colspan="2"><p style="text-align:right;"><i>Приложение 2</i></p>
                <p style="text-align:right;"><i>к приказу Генерального директора&nbsp;</i></p>
                <p style="text-align:right;"><i>РГП на ПХВ «Национальный центр&nbsp;</i></p>
                <p style="text-align:right;"><i>экспертизы» СЭБК МЗ РК&nbsp;</i></p>
                <p style="text-align:right;"><i>№ _____ от «____»&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 2022 года</i></p>
            </td>
        </tr>
        <tr>
            <td><p>&nbsp;</p>
                <p>№ ${number}&nbsp;</p>
                <p>ӨТЕЛМЕЛІ ҚЫЗМЕТ КӨРСЕТУ ШАРТЫ</p>
                <p style="text-align:center;">&nbsp;</p>
                <p><strong>(керегін жазу) қ.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;${date1}</strong></p>
                <p style="text-align:justify;">&nbsp;</p>
                <p style="text-align:justify;">Бұдан әрі «Орындаушы» деп аталатын, <strong>ҚР ДСМ СЭБК</strong> <strong>«Ұлттық
                    сараптама орталығы» ШЖҚ РМК</strong>, __ 202__ ж. №__&nbsp; Бас сенімхат негізінде әрекет ететін
                    <strong>ҚР ДСМ &nbsp;СЭБК</strong> &nbsp;<strong>«Ұлттық сараптама орталығы» ШЖҚ РМК</strong> филиал
                    директоры <strong>(филиалдың атауы, аты-жөні)&nbsp;</strong>бір тараптан және&nbsp; бұдан әрі
                    «Тапсырыс беруші» деп аталынатын екінші тараптан<strong> (ұйымдық-құқықтық нысан және заңды тұлғаның
                        фирмалық атауы)&nbsp;</strong>&nbsp;<strong>(құжаттың атауы)</strong> негізінде әрекет ететін
                    (аты-жөні, лауазым) атынан, бірлесіп ескергенде «Тараптар», жеке айтқанда «Тарап» немесе жоғарыда
                    айтылғандай осы Шартты (бұдан әрі - Шарт) жасасты және төмендегілер туралы келісімге келді:</p>
                <p style="text-align:center;"><strong>1. Шарттың мәні</strong></p>
                <p style="text-align:justify;">1.1 Орындаушы Тапсырыс берушіге осы Шарттың қосымшасына сәйкес, қызмет
                    (бұдан әрі – Қызмет) көрсетуге, ал Тапсырыс беруші Қызметтерді осы Шарт талаптарына сай қабылдауға
                    және төлеуге міндеттенеді.</p>
                <p style="text-align:justify;">1.2 Төменде келтірілген құжаттар мен онда айтылған шарттар осы Шартты
                    құрайды және оның ажырамас бөлігі болып табылады, атап айтқанда:</p>
                <p style="text-align:justify;">1) осы Шарт;</p>
                <p style="text-align:justify;">2) сатып алынатын қызметтер тізімі (Қосымша);</p>
                <p style="text-align:center;"><strong>2. Шарттың құны және ақы төлеу шарттары</strong></p>
                <p style="text-align:justify;">2.1 Шарттың жалпы сомасы&nbsp;<strong>${price} (_______________)
                    теңге</strong> қызметтерді орындаумен байланысты барлық шығындар, сондай-ақ Қазақстан
                    Республикасының заңнамасымен қарастырылған барлық салықтар мен алымдарды қамтиды.</p>
                <p style="text-align:justify;">2.2 Соманы Тапсырыс беруші Орындаушы көрсетілетін Қызметтерді, осы&nbsp;Шарттың
                    қосымшасына сәйкес факт бойынша көрсеткеннен кейін және көрсетілген Қызметтер актісіне Тараптар қол
                    қойғаннан кейін 30 (отыз) күнтізбелік күн ішінде аударым түрінде төлейді.&nbsp;</p>
                <p style="text-align:justify;">Қызметтерді қабылдап алу-беру актісінің нысанын алдын ала Тапсырыс
                    берушімен келісіледі.</p>
                <p style="text-align:justify;">Тапсырыс берушінің аванстық төлем жүргізуге құқығы бар. Қалған соманы
                    Тапсырыс беруші бұрын төленген аванстық төлемді ұстауды ескере отырып, көрсетілген қызметтер
                    актісіне Тараптар қол қойған күннен бастап 30 (отыз) күнтізбелік күннен кешіктірмей Орындаушының
                    ағымдағы шотына ақша аудару арқылы төлейді.&nbsp;</p>
                <p style="text-align:justify;">2.3 Орындалатын Қызметтердің көлемі сандық және бағалық түрінде Шарттың
                    Қосымшасында көрсетілген.</p>
                <p style="text-align:justify;">2.4 Ақы төлеу алдындағы қажетті құжаттар:</p>
                <p style="text-align:justify;">1) қол қойылған Шарт;</p>
                <p style="text-align:justify;">2) орындалған Жұмыстардың актісі;</p>
                <p style="text-align:justify;">3)&nbsp;Өнім беруші тапсырыс берушіге ұсынған төлем шоты.</p>
                <p style="text-align:justify;">2.5. Қызмет көрсету ушін мамандардың жол жүруі Орындаушының автокөлігінде
                    Орындаушының бағанамасына сәйкес жүзеге асырылады Тапсырыс берушінің автокөлігіне шыққан жағдайда
                    шығу өтеусіз негізде жүзеге асырылады. Егер&nbsp;Орындаушының қызметкерлері 60 км-ден астам
                    қашықтыққа бір бағытта жүрсе, Тапсырыс беруші Орындаушының Прейскурантына сәйкес іссапар шығыстарын
                    төлейді.</p>
                <p style="text-align:center;"><strong>3. Тараптардың жауапкершілігі</strong></p>
                <p style="text-align:justify;">3.1&nbsp;Орындаушы міндетіне алады:</p>
                <p style="text-align:justify;">1) Шарт бойынша өзіне алған міндеттемелердің толық және тиесілі
                    орындалуын қамтамасыз етуге;</p>
                <p style="text-align:justify;">2) Тапсырыс берушінің бірінші талабы бойынша Шарт бойынша
                    міндеттемелердің орындалу барысы туралы ақпарат ұсынуға;<strong>&nbsp;</strong></p>
                <p style="text-align:justify;">3) Көрсетілген қызметтер үшін толық төлем алынғаннан кейін Тапсырыс
                    берушіге зерттеу хаттамаларын беруге міндетті.</p>
                <p style="text-align:justify;"><i>Ескерту: дезинфекциялық қызметтерді көрсеткен жағдайда 3.1-тармағы 3
                    тармақшасының нормасы келісім мәтінінен алынып тасталуы&nbsp;тиіс.</i></p>
                <p style="text-align:justify;">3.2 Тапсырыс беруші:</p>
                <p style="text-align:justify;">1) Қызметтерді көрсету үшін Тапсырыс берушінің мамандарының қол жеткізуін
                    қамтамасыз ету міндетіне алады;</p>
                <p style="text-align:justify;">2) Шарттың талаптарына сәйкес Тапсырыс берушінің Шарт бойынша көрсетілген
                    қызметтерді қабылдап алуға және төлеу міндетіне алады;</p>
                <p style="text-align:justify;">3) Осы келісімшартты үшінші тұлғаларға қатысты міндеттемелерді орындау
                    үшін (қосалқы мердігерлік) пайдалануға болмайды;</p>
                <p style="text-align:justify;">3.3 Өнімдерді зертханалық зерттеудің қанағаттанарлықсыз нәтижесі кезінде
                    Тараптар қосымша келісім жасасуға міндеттенеді, үлгілердің екі еселенген мөлшерін пайдалана отырып,
                    технологиялық үдеріс барысы бойынша өндіріске, шикізатқа, жартылай дайындалған өнімге, қосалқы
                    материалдарға, суға, барлық жұмыс үй-жайларының, санитарлық- гигиеналық жағдайына қосымша бақылау
                    жүргізіледі&nbsp;және&nbsp;тиісті санитарлық-гигиеналық және эпидемияға қарсы іс-шаралар
                    жүргізілуіне қайта зерттеледі.&nbsp;</p>
                <p style="text-align:justify;"><i>Ескерту: дезинфекциялық қызметтерді көрсеткен жағдайда 3.3-тармағының
                    нормасы келісім мәтінінен алынып тасталуы&nbsp;тиіс.</i></p>
                <p style="text-align:justify;">3.4&nbsp;Шарттың тараптары Қазақстан Республикасының Сыбайлас жемқорлыққа
                    қарсы заңнамасының және Қазақстан Республикасы ратификациялаған сыбайлас жемқорлыққа қарсы іс-қимыл
                    саласындағы халықаралық келісімдердің нормаларын сақтауға міндетті.</p>
                <p style="text-align:justify;">3.5 Тараптар шарт бойынша қабылданған міндеттемелерді орындау кезінде
                    іскерлік әдеп, парасаттылық нормаларын, сондай-ақ адал бәсекелестіктің негізгі қағидаттарын сақтауға
                    міндетті.</p>
                <p style="text-align:justify;">3.6 Тараптар шарт жасасу кезінде, сондай-ақ шарт бойынша қабылданған
                    міндеттемелерді орындау процесінде мүдделердің нақты немесе ықтимал қақтығысы туралы мәліметтерді
                    ашуға міндетті.</p>
                <p style="text-align:center;"><strong>4. Қызметтердің техникалық ерекшелікке сәйкестілігін
                    тексеру</strong></p>
                <p style="text-align:justify;">4.1 Берілген Келісім-шарт шегінде жеткізілетін Қызметтер техникалық
                    ерекшелікте көрсетілген стандарттарға сәйкес немесе олардан жоғары болуы қажет.</p>
                <p style="text-align:justify;">4.2 Егер тексеріс кезінде Қызметтердің нәтижелері техникалық ерекшеліктің
                    талаптарына сәйкес емес деп танылса, Орындаушы, Тапсырыс беруші жағынан еш қосымша шығынсыз,
                    техникалық ерекшелік талаптарына сәйкессіздіктерді жою бойынша шараларды қолданады.</p>
                <p style="text-align:justify;">4.3 Жоғарыда көрсетілген ешбір тармақ Орындаушыны Келісім-шарт бойынша
                    басқа міндеттерден босатпайды.<strong>&nbsp;</strong></p>
                <p style="text-align:center;"><strong>5. Қызметтерді көрсету және құжаттама</strong></p>
                <p style="text-align:justify;">5.1 Орындаушының қызметтерді көрсетуі Шарттың ажырамас бөлігі болып
                    табылатын Шарт Қосымшасында көрсетілген мерзімдерде жүзеге асырылады.</p>
                <p style="text-align:justify;">5.2 Көрсетілген қызметтерді есептеу үшін Орындаушы келесі құжаттарды
                    ұсынуы керек:</p>
                <p style="text-align:justify;">1) екі данада көрсетілген Қызметтер актілері;</p>
                <p style="text-align:justify;">2) көрсетілген Қызметтердің жалпы сомасы, көрсете отырып сипаттайтын
                    шот-фактура.</p>
                <p style="text-align:justify;">5.3. Қызмет көрсету мерзімі –&nbsp;202__ ж.&nbsp;«___» ___________ нан
                    202__ ж.&nbsp;«___» ___________ дейін.</p>
                <p style="text-align:justify;">5.4. Қызмет көрсету орны: Шарт қосымшасына сәйкес.</p>
                <p style="text-align:justify;">5.5. Осы келісімшарттың қосымшасында белгіленген нақты талаптарға сәйкес
                    Орындаушы Тапсырыс берушіге қызметті толығымен тапсырған жағдайда қызмет орындалған деп
                    саналады.</p>
                <p style="text-align:center;"><strong>6. Кепілдік</strong></p>
                <p style="text-align:justify;">6.1 Орындаушы Тапсырыс берушіге үздіксіз, сапалы және уақытылы Қызмет
                    көрсетуді кепілдік етеді.</p>
                <p style="text-align:justify;">6.2 Орындаушы техникалық ерекшеліктің (Шарт Қосымшасы) Қызметтерінің
                    қателерін, жете өңдеулері мен басқа сәйкессіздіктерін ақысыз түзетуді кепілдендіреді.</p>
                <p style="text-align:justify;">6.3 Тапсырыс беруші берілген кепілдікке байланысты барлық шағымдары
                    туралы Орындаушыға жазбаша түрде жедел хабарлауға міндетті, одан кейін Орындаушы, осыған байланысты
                    барлық шығындармен бірге, ақаулықтарды жою бойынша шараларды Тапсырыс беруші хабарламасында
                    көрсеткен мерзім ішінде қолдануы қажет.</p>
                <p style="text-align:justify;">6.4 Шартқа қол қойғаннан кейін Тапсырыс беруші не оның өзі, не оның
                    қатысушылары/акционерлері және түпкілікті иелері Орындаушыға қатысты байланысты тарабы болып
                    табылмайтынына сөзсіз кепілдік береді.</p>
                <p style="text-align:center;"><strong>7. Құпиялылық шарттары</strong></p>
                <p style="text-align:justify;">7.1 осы Шарттың шеңберінде Тараптардың өзара келісімі бойынша осы
                    Келісімшартты шартты орындау барысында белгілі болған кез келген ақпарат құпия болып саналады, оның
                    ішінде бірақ осымен шектелмей Орындаушының алынған нәтижелері, құрылтай құжаттары, рұқсат
                    құжаттары.</p>
                <p style="text-align:justify;">7.2 Тараптардың әрқайсысы осы Шарттың шеңберінде оған белгілі болған
                    құпия ақпаратты рұқсат берілмеген пайдалануға, таратудан немесе жарияланудан қорғауды қамтамасыз
                    етуге міндетті</p>
                <p style="text-align:justify;">7.3 Екінші тараптың алдынала жазбаша мақылдаусыз тура немесе жанама түрде
                    қандай да бір нысанда немесе қандай да бір құралдармен құпия ақпаратты немесе оның бір бөлігін
                    үшінші таратпауға, ашпауға немесе жарияламауға Тараптар келісті,&nbsp;Қазақстан Республикасының
                    заңнамасында көзделгендерден басқа.</p>
                <p style="text-align:justify;">7.4 Құпиялылық шарттар осы Келісімшартқа қол қойылғаннан бастап күшіне
                    енеді және Келісімшарттың қолданылу мерзімі аяқталғанннан кейін 3 (үш) жыл ішінде әрекет етеді.&nbsp;</p>
                <p style="text-align:justify;">7.5 Әрбір Тараптар өз қызметкерлеріне өздерінің қызметтік міндеттерін
                    атқару үшін қажетті құпия ақпараттың барлық және/немесе бір бөлігін жария етуге немесе беруге құқылы
                    туралы Тараптар келісті.</p>
                <p style="text-align:justify;">7.6 Тараптар тоқтатылған Келісімшарттың ажырамас бөлігі болып табылатын
                    Бұзу туралы шартқа қол қойылған сәттен бастап Келісімшарттың заңды күшке ие емес екендігімен
                    келісті.</p>
                <p style="text-align:justify;">7.7 Тапсырыс берушінің, осы Келісімшарпен қарастырылған негіздер бойынша
                    бұзылған Келісімшартты үшінші тапарапқа берген жағдайда, Келісімшарттың 8 бөліміне сәйкес
                    жауапкершілікке тартылатындығымен келісті.</p>
                <p style="text-align:center;"><strong>8. Тараптардың жауапкершілігі</strong></p>
                <p style="text-align:justify;">8.1 Тапсырыс беруші осы Келісімшарттың 7 бөлімін, 6.4-тармағын,
                    3.2-тармақтың 3) тармақшасын бұзған жағдайда, Орындаушы осы Келісімшартты&nbsp;біржақты тәртіпте
                    бұзуға құқылы, Тапсырыс беруші Келісімшарттың жалпы сомасынан 50% мөлшерінде айыппұл төлеуге және
                    Орындаушының залалдарын өтеуге міндетті. Тапсырыс беруші Шарттың 7.7 тармағының талаптарын бұзған
                    жағдайда Тапсырыс беруші шарттың жалпы сомасының 50% мөлшерінде айыппұл төлейді және Орындаушының
                    барлық шығындарын өтейді.</p>
                <p style="text-align:justify;">8.2&nbsp;Орындаушы тарапынан Қызметтерді көрсету шарттары бұзылған
                    жағдайда, Тапсырыс беруші Шарт бойынша өз міндеттемелерін орындауды осы келісімшарт бойынша
                    міндеттемелерді орындамағаны туралы жазбаша хабарлама жіберіп, Орындаушыға нақты көрсетілген
                    қызметтерді төлеу арқылы тоқтатуға құқылы.</p>
                <p style="text-align:justify;">8.3 Форс-мажорлық жағдайларды есептемегенде, Орындаушы Шартпен қаралған
                    мерзімдерде Қызметті көрсете алмайтын болса, Тапсырыс беруші өзінің басқа құқықтарына залал
                    келтірмей Шарттың шеңберінде мерзімі кешіктірілген әр күн үшін өнім беруші міндеттемені орындамаған
                    жағдайда шарттың соммасынан 0,1 (нөл бүтін бір) пайыз мөлшерінде, бірақ Келісімшарттың жалпы
                    сомасынан 10% артық емес тұрақсыздық айыбын (айыппұл, өсімақы) өндіреді немесе мерзімі кешіктірілген
                    әр күн үшін тиісті түрде орындалмаған міндеттеменің сомасына 0,1 (нөл бүтін бір) пайыз мөлшерінде,
                    бірақ Келісімшарттың жалпы сомасынан 10% артық еместұрақсыздық айыбын (айыппұл, өсімақы)
                    өндіреді.</p>
                <p style="text-align:justify;">8.4 Тұрақсыздықты төлеу&nbsp;Орындаушы Шарт бойынша өзінің міндеттерін
                    орындаудан босатпайды.</p>
                <p style="text-align:justify;">8.5 Осы Келісімшарттың 2.2-тармағын бұзған жағдайда, Тапсырыс беруші
                    кешіктірілген әр күн үшін&nbsp; шарттың соммасынан&nbsp;0,1% (нөл бүтін оннан бір) (айыппұл, өсіпұл)
                    бірақ Келісімшарттың жалпы сомасынан 10 % артық емес тұрақсыздық айыпты төлейді.</p>
                <p style="text-align:justify;">8.6 Шарт бойынша жұмыстарды орындау үшін&nbsp;Орындаушыға қажетті
                    өзгерістер құнның немесе мерзімнің өсуіне немесе азаюына әкелетін болса, онда Келісімшарттың сомасы
                    немесе Қызметтің көрсету мерзімі, немесе екеуіде тиісті тәртіппен түзетіледі, сондай-ақ
                    Келісімшартқа енгізілген түзетулермен қосымша келісім жасалады.Орындаушының түзетулерді жүргізу
                    туралы барлық сұранымдары&nbsp;Орындаушы Тапсырыс берушіден өзгерістер туралы тапсырма алған күннен
                    бастап 5 (бес) күн ішінде ұсынылуы тиіс.</p>
                <p style="text-align:justify;">8.7&nbsp;Орындаушы Тапсырыс берушінің алдын ала жазбаша келісімінсіз осы
                    Шарт бойынша өзінің міндеттемелерін біреуге толықтай немесе ішінара табыстамауы тиіс.&nbsp;Орындаушы
                    Тапсырыс берушіге осы Шарт аясында жасалған барлық қосалқы мердігерлік шарттардың көшірмелерін
                    ұсынуы тиіс.&nbsp;</p>
                <p style="text-align:justify;">8.8 Қосалқы мердігерлердің болуы&nbsp;Орындаушыны Шарт бойынша
                    материалдық немесе басқа жауапкершіліктен босатпайды.</p>
                <p style="text-align:center;"><strong>9. Шарттың қолданылу мерзімі және&nbsp;</strong></p>
                <p style="text-align:center;"><strong>бұзылу шарттары</strong></p>
                <p style="text-align:justify;">9.1 Осы Шарт қол қойған күннен бастап күшіне енеді және 202__ жылғы «__»
                    _______&nbsp; дейін күшінде болады.</p>
                <p style="text-align:justify;">9.2 Тапсырыс беруші кез келген уақытта біржақты ретте егер Орындаушы
                    банкрот немесе төлеуге қабілетсіз болып қалған жағдайда, Орындаушыға тиісті жазбаша хабарлама
                    жіберіп Шарттың&nbsp; талаптарын орындаудан бас тарта алады. Бұл жағдайда Шарттың талаптарын
                    орындаудан бас тарту тез арада жүзеге асырылады, және Тапсырыс беруші егер Шарттың талаптарын
                    орындаудан бас тарту ешқандай залал келтірмесе немесе артынан Тапсырыс берушіге қойылатын
                    санкцияларды қолдану немесе әрекеттерді жасауға қандай-да бір құқықтарға тиіспесе, Орындаушыға
                    қатысты ешқандай қаржылық міндеттілікті көтермейді.</p>
                <p style="text-align:justify;">9.3 Орындаушы кез келген уақытта біржақты ретте егер Қызмет көрсету әрі
                    қарай тиімді емес болған жағдайда Тапсырыс берушіге тиісті жазбаша хабарлама жіберіп Шарттың
                    талаптарын орындаудан бас тарта алады.</p>
                <p style="text-align:justify;">9.4 Осы шарттың 9.2 тармағы негізінде Шарттың күші жойылған кезде,
                    Орындаушы Шарт бойынша бұзуға байланысты оны бұзатын күнгi iс жүзiндегi шығындар үшiн ғана ақы талап
                    етуге құқылы.</p>
                <p style="text-align:justify;">9.5 Қандай да бір санкцияларға зиян келтірместен Тапсырыс беруші Шарт
                    талаптарын бұзғаны үшін, Өнім берушіге міндеттемелерін орындамағаны туралы жазбаша хабарлама жібере
                    отырып егер Орындаушы Қызметті Шартта көзделген мерзімде немесе Тапсырыс берген Шарттың ұзартылған
                    кезеңі ішінде көрсете алмаса.</p>
                <p style="text-align:justify;">9.6 Шартты тараптардың келісімі бойынша бұзуға болады.</p>
                <p style="text-align:justify;">9.7 Жоғарыда көрсетілген жағдайларда, Өнім берушінің өкілі Тапсырыс
                    беруші Шарттың әр парағына осы бөлімде қарастырылған қандай-да бір себептермен Келісімнің
                    тоқтатылғанын растайтын жазба жасайды.</p>
                <p style="text-align:center;"><strong>10. Форс-мажор</strong></p>
                <p style="text-align:justify;">10.1 Тараптар Шарт талаптарының орындалмауына жауапкершілік
                    көтермейді,егер ол форс-мажорлық жағдайлардың нәтижесі болып табылса.</p>
                <p style="text-align:justify;">10.2 Орындаушы Шарт орындалуының өз қамтамасыз етуінен айырылмайды және
                    айып төлем өтеу үшін немесе Шарт талаптарын орындамау себебінен оны бұзу үшін жауапты болмайды, егер
                    Шарт орындау бойынша кідіріс форс-мажорлық жағдайлардың нәтижесі болып табылса.</p>
                <p style="text-align:justify;">10.3 Шарт мақсаттары үшін «форс-мажор» Тараптар бақылауымен биліксіз және
                    күтпеген сипатқа ие оқиғаны білдіреді. Ондай оқиғалар келесілерді қамти алады, бірақ тек олар ғана
                    емес: соғыс қимылдары, табиғи немесе стихиялық апаттар және басқалар.</p>
                <p style="text-align:justify;">10.4 Форс-мажорлық жағдайлардың пайда болуы кезінде Орындаушы Тапсырыс
                    берушіге ондай жағдайлар мен себептері туралы жазбаша хабарламаны жедел жіберуі тиіс. Егер Тапсырыс
                    берушіден басқа жазбаша нұсқаулықтар келіп түспесе, Орындаушы Шарт бойынша өз міндеттерін
                    мүмкіндігінше орындауды жалғастырады және форс-мажорлық жағдайларға байланысты емес, Шартты
                    орындаудың баламалы әдістерін іздейді.</p>
                <p style="text-align:center;"><strong>11. Даулы мәселелерді шешу</strong></p>
                <p style="text-align:justify;">11.1 Тапсырыс беруші мен Орындаушы Шарт бойынша немесе оған байланысты
                    барлық даулар мен келіспеушіліктерді тікелей келіссөз жүргізу барысында шешу үшін барлық күштерін
                    салулары тиіс.</p>
                <p style="text-align:justify;">11.2 Егер осындай келіссөз басталғаннан кейін 21 (жиырма бір) күнтізбелік
                    күн ішінде Тапсырыс беруші мен Орындаушы Шарт бойынша мәселені шеше алмаса, тараптардың кез келгені
                    осы мәселенің Қазақстан Республикасының заңнамасына сәйкес шешілуін талап ете алады.</p>
                <p style="text-align:center;"><strong>12. Хабарлама</strong></p>
                <p style="text-align:justify;">12.1 Шартқа сәйкес, бір тараптан екінші тарапқа жолдауға кез келген
                    хабарлама келесіде түпнұсқасын ұсынумен, хат, жедел хат, телекс, факс немесе электрондық поштаға
                    арқылы жіберіледі. Тараптардың электронды мекен-жайлары осы Шарттың 14 бөлімінде көрсетіледі.</p>
                <p style="text-align:justify;">12.2 Хабарлама жеткізілгеннен кейін немесе көрсетілген күшіне ену күнінен
                    (хабарламада көрсетілсе) бастап, осы екі мерзімнің қайсысы кеш келетініне байланысты өз күшіне
                    енеді.</p>
                <p style="text-align:center;"><strong>13. Өзге шарттар</strong></p>
                <p style="text-align:justify;">13.1 Шартқа кез келген өзгерістер мен толықтырулар тек олар жазбаша
                    нұсқада және Тараптардың осыған өкілетті өкілдері қол қойған және олардың мөрлерімен бекітілген
                    жағдайда ғана шынайы деп танылады.</p>
                <p style="text-align:justify;">13.2 Қосымша келісімдер тек Тапсырыс беруші келісімшарттың түпнұсқасын
                    ұсынғаннан кейін жасалады;</p>
                <p style="text-align:justify;">13.3 Тараптардың біреуімен Шарт бойынша міндеттерін тапсыру тек басқа
                    Жақтың жазбаша рұқсатымен ғана жүзеге асырылады.</p>
                <p style="text-align:justify;">13.4 Шарт бірдей заңды күшіне ие мемлекеттік және ресми тілінде екі
                    нұсқада құрастырылған, әр Тарап үшін бір нұсқадан.</p>
                <p style="text-align:justify;">13.5 Шартпен реттелмеген бөлігінде Тараптар Қазақстан Республикасының
                    заңнамасына сүйенеді.&nbsp;</p>
                <p style="text-align:center;"><strong>14. Тараптардың реквизиттер</strong></p>
                <p>${filialKz}</p>
                <p>Мекен-жайы: ________________________________</p>
                <p>БСН ${filialDate?.bin}</p>
                <p>ЖСК KZ ${filialDate?.bankAccountNumber}</p>
                <p>АҚ&nbsp;</p>
                <p>БСК ${filialDate?.bankBik}</p>
                <p>КБе ${filialDate?.beneficiaryCode}</p>
                <p>Тел ${filialDate?.phoneNumber}</p>
                <p>e-mail:___________________</p>
                <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;</p>
                <p>&nbsp;<strong>Директор&nbsp; &nbsp;</strong></p>
                <p>&nbsp;</p>
                <p><strong>${supervisor} &nbsp;</strong>&nbsp;<strong>Т.А.Ә.</strong></p>
                <p style="text-align:justify;">М.О.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp;</p>
                <p style="text-align:justify;">&nbsp;</p>
                <p><strong>Тапсырыс беруші</strong></p>
                <p>«_____________»</p>
                <p>Мекен-жайы:: __________________</p>
                <p>БСН&nbsp;</p>
                <p>Банк</p>
                <p>ЖСК&nbsp;</p>
                <p>БСК&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>Тел. ${phoneNumber}</p>
                <p>e-mail:___________________</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p><strong>Директор</strong></p>
                <p><strong>_______________</strong></p>
                <p>М.П.</p></td>
            <td><p style="text-align:center;">&nbsp;</p>
                <p style="text-align:center;"><strong>ДОГОВОР ВОЗМЕЗДНОГО ОКАЗАНИЯ УСЛУГ&nbsp;</strong></p>
                <p style="text-align:center;"><strong>№ ${number}</strong></p>
                <p>&nbsp;</p>
                <p><strong> ${city}&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ${date1}</strong>
                </p>
                <p style="text-align:justify;">&nbsp;</p>
                <p style="text-align:justify;"><strong>РГП на ПХВ «Национальный центр экспертизы»&nbsp;</strong>&nbsp;КСЭК<strong>
                    &nbsp;МЗ РК,&nbsp;</strong>именуемое в дальнейшем «Исполнитель», в лице <strong>${position} филиала
                    ${filial}</strong> ${supervisor} действующего на основании ${proxy?.proxy} №${proxy?.proxyNumber} от ${proxy?.proxyDate} с одной стороны, и <strong>${client},</strong> именуемое в дальнейшем «Заказчик», в лице <strong>${supervisorName}</strong> действующего на основании <strong>${clientData?.supervisorDocumentNumber}</strong>, с другой
                    стороны, при совместном упоминании именуемые Стороны, а по отдельности Сторона или как указано выше,
                    заключили настоящий договор (далее - Договор) о нижеследующем:</p>
                <p style="text-align:center;"><strong>1. Предмет договора</strong></p>
                <p style="text-align:justify;">1.1 Исполнитель обязуется оказать Заказчику услуги согласно приложению к
                    настоящему Договору (далее – Услуги), а Заказчик принять и оплатить Услуги на условиях настоящего
                    Договора.</p>
                <p style="text-align:justify;">1.2 Перечисленные ниже документы и условия, оговоренные в них, образуют
                    данный Договор и считаются его неотъемлемой частью, а именно:</p>
                <p style="text-align:justify;">1) настоящий Договор;</p>
                <p style="text-align:justify;">2) перечень закупаемых услуг (Приложение).</p>
                <p style="text-align:center;"><strong>2. Стоимость Договора и условия оплаты</strong></p>
                <p style="text-align:justify;">2.1 Общая сумма Договора составляет <strong>${price}&nbsp;</strong>(_____________)
                    <strong>тенге&nbsp;</strong>и включает все расходы, связанные с выполнением услуг, а также все
                    налоги и сборы, предусмотренные законодательством Республики Казахстан.&nbsp;</p>
                <p style="text-align:justify;">2.2 Сумма оплачивается путем перечисления на расчетный счет Исполнителя
                    после фактического оказания услуг, в соответствии с приложением к настоящему Договору, и в течении
                    30 (тридцати) календарных дней со дня подписания Сторонами акта оказанных Услуг.&nbsp;</p>
                <p style="text-align:justify;">Форма акта оказанных Услуг предварительно согласовывается с
                    Заказчиком.</p>
                <p style="text-align:justify;">Заказчик имеет право производить авансовый платеж. Оставшаяся сумма
                    оплачивается Заказчиком путем перечисления денежных средств на расчетный счет Исполнителя не позднее
                    30 (тридцати) календарных дней с даты подписания Сторонами акта оказанных услуг, с учетом удержания
                    ранее оплаченного аванса.</p>
                <p style="text-align:justify;">2.3 Объем оказываемых Услуг в количественном и стоимостном выражении
                    оговорен в Приложении к Договору.</p>
                <p style="text-align:justify;">2.4 Необходимые документы, предшествующие оплате:</p>
                <p style="text-align:justify;">1) подписанный Договор;</p>
                <p style="text-align:justify;">2) акт выполненных Работ;</p>
                <p style="text-align:justify;">3) счет к оплате, предоставленный Исполнителем Заказчику.&nbsp;</p>
                <p style="text-align:justify;">2.5.Выезд специалистов для оказания услуг осуществляется на
                    автотранспорте Исполнителя, согласно Прейскуранту цен Исполнителя. В случае выезда на автотранспорте
                    Заказчика, выезд осуществляется на безвозмездной основе. Если выезд работников Исполнителя
                    осуществляется на расстояние более 60 км в одну сторону, то Заказчик оплачивает командировочные
                    расходы согласно Прейскуранту цен Исполнителя.</p>
                <p style="text-align:center;"><strong>3. Обязательства Сторон</strong></p>
                <p style="text-align:justify;">3.1 Исполнитель обязуется:</p>
                <p style="text-align:justify;">1) обеспечить полное и надлежащее исполнение взятых на себя обязательств
                    по Договору;</p>
                <p style="text-align:justify;">2) по первому требованию Заказчика предоставлять информацию о ходе
                    исполнения обязательств по Договору;</p>
                <p style="text-align:justify;">3) выдать Заказчику протоколы исследований после получения полной оплаты
                    за оказанные услуги.</p>
                <p style="text-align:justify;"><i>Примечание: в случае оказания дезинфекционных услуг, норму подпункта 3
                    пункта 3.1 следует удалить из текста договора.</i></p>
                <p style="text-align:justify;">3.2 Заказчик обязуется:</p>
                <p style="text-align:justify;">1) обеспечить доступ специалистов&nbsp;Исполнителя для оказания
                    Услуг;</p>
                <p style="text-align:justify;">2) в соответствии с условиями Договора принимать и оплачивать Услуги,
                    оказанные&nbsp;Исполнителем по Договору;</p>
                <p style="text-align:justify;">3) не использовать данный договор для выполнения обязательств перед
                    третьими лицами (субподряд);</p>
                <p style="text-align:justify;">3.3 Стороны обязуются заключить дополнительное соглашение при
                    неудовлетворительных результатах лабораторных исследований продукции, повторно предоставить на
                    исследование удвоенное количество образцов для проведения дополнительного контроля производства по
                    ходу технологического процесса, сырья, полуфабрикатов, вспомогательных материалов, воды,
                    санитарно-гигиенического состояния всех рабочих помещений и проводятся соответствующие
                    санитарно-гигиенические и противоэпидемические мероприятия.&nbsp;</p>
                <p style="text-align:justify;"><i>Примечание: в случае оказания дезинфекционных услуг, норму пункта 3.3
                    следует удалить из текста договора.</i></p>
                <p style="text-align:justify;">3.4&nbsp;Стороны, договора обязаны соблюдать нормы антикоррупционного
                    законодательства Республики Казахстан и международных соглашений, ратифицированных Республикой
                    Казахстан в области противодействия коррупции.</p>
                <p style="text-align:justify;">3.5 Стороны, при исполнении принятых обязательств по договору обязаны
                    соблюдать нормы деловой этики, добропорядочности, а также основные принципы добросовестной
                    конкуренции.</p>
                <p style="text-align:justify;">3.6 Стороны, при заключении договора, а также в процессе исполнения
                    принятых обязательств по договору обязаны раскрывать сведений о реальном или потенциальном конфликте
                    интересов.</p>
                <p style="text-align:center;"><strong>4. Проверка Услуг на соответствие технической&nbsp;</strong></p>
                <p style="text-align:center;"><strong>спецификации</strong></p>
                <p style="text-align:justify;">4.1 Услуги, оказываемые в рамках данного Договора, должны соответствовать
                    или быть выше стандартов, указанных в технической спецификации.</p>
                <p style="text-align:justify;">4.2 Если результаты оказанных Услуг будут признаны не соответствующими
                    требованиям технической спецификации по вине Исполнителя, Исполнитель принимает меры по устранению
                    несоответствий требованиям технической спецификации, без каких-либо дополнительных затрат со стороны
                    Заказчика.</p>
                <p style="text-align:justify;">4.3 Ни один вышеуказанный пункт не освобождает Исполнителя от других
                    обязательств по Договору.&nbsp;</p>
                <p style="text-align:center;"><strong>5. Оказание Услуг и документация</strong></p>
                <p style="text-align:justify;">5.1 Оказание Услуг Исполнителем осуществляется в соответствии с
                    Приложением к Договору, являющемся неотъемлемой частью настоящего Договора.</p>
                <p style="text-align:justify;">5.2 Для расчетов за оказанные Услуги Исполнитель должен предоставить
                    следующие документы:</p>
                <p style="text-align:justify;">1) акты оказанных Услуг в двух экземплярах;</p>
                <p style="text-align:justify;">2) счет-фактура с указанием суммы оказанных Услуг.<strong>&nbsp;</strong>
                </p>
                <p style="text-align:justify;">5.3. Сроки оказания услуг –&nbsp;c&nbsp;${actionAt} по
                ${deadline}</p>
                <p style="text-align:justify;">5.4. Место оказания услуг: согласно приложению к Договору.</p>
                <p style="text-align:justify;">5.5 Услуга считается оказанной при условии полной сдачи Исполнителем
                    услуги Заказчику в точном соответствии требованиям, указанным в приложениях к настоящему
                    Договору.</p>
                <p style="text-align:center;"><strong>6. Гарантия</strong></p>
                <p style="text-align:justify;">6.1 Исполнитель гарантирует обеспечение бесперебойного, качественного и
                    своевременного оказания Услуг Заказчику.</p>
                <p style="text-align:justify;">6.2 Исполнитель гарантирует безвозмездное исправление ошибок, недоработок
                    и других несоответствий технический спецификации (Приложение к Договору).</p>
                <p style="text-align:justify;">6.3 Заказчик обязан оперативно уведомить Исполнителя в письменном виде
                    обо всех претензиях, связанных с данной гарантией, после чего Исполнитель должен принять меры по
                    устранению недостатков.</p>
                <p style="text-align:justify;">6.4 С подписанием Договора Заказчик безусловно гарантирует, что ни он, ни
                    его участники/акционеры и конечные владельцы не являются связанной стороной по отношению к
                    Исполнителю, а также отсутствует конфликт интересов между ними в отношении предмета Договора.</p>
                <p style="text-align:center;"><strong>7. Условия конфиденциальности</strong></p>
                <p style="text-align:justify;">7.1 По взаимному согласию сторон в рамках настоящего Договора
                    конфиденциальной признается любая информация, ставшая известная сторонам в ходе выполнения
                    настоящего Договора, в том числе, но не&nbsp;ограничиваясь, полученные результаты, учредительные
                    документы, разрешительные документы Исполнителя.</p>
                <p style="text-align:justify;">7.2 Каждая из сторон обязана обеспечить защиту конфиденциальной
                    информации, ставшей доступной ей в рамках настоящего Договора, от несанкционированного
                    использования, распространения или опубликования.</p>
                <p style="text-align:justify;">7.3 Стороны согласились не раскрывать или разглашать прямо или косвенно в
                    какой-либо форме или каким-либо средствами конфиденциальную информацию или ее часть третьей стороне,
                    без предварительного письменного одобрения второй Стороны, за исключением случаев предусмотренных
                    законодательством Республики Казахстан.</p>
                <p style="text-align:justify;">7.4 Условия конфиденциальности вступают в силу с момента подписания
                    настоящего Договора и действуют в течение 3 (трех) лет после прекращения срока действия
                    Договора.</p>
                <p style="text-align:justify;">7.5 Стороны согласились, что каждая Сторона имеет право огласить или дать
                    доступ ко всей и/или части конфиденциальной информации своим работникам в той мере, в которой им
                    необходима данная информация в связи с осуществлением их служебных обязанностей.</p>
                <p style="text-align:justify;">7.6 Стороны согласились, что расторгнутый договор не имеет юридической
                    силы с момента подписания Соглашения о расторжении, что является неотъемлемой частью Договора.</p>
                <p style="text-align:justify;">7.7 В случае предъявления Договора Заказчиком третьей стороне,
                    утратившего силу на основаниях, предусмотренных настоящим Договором, заказчик несет ответственность
                    в соответствии с разделом 8 Договора.</p>
                <p style="text-align:center;"><strong>8. Ответственность сторон</strong></p>
                <p style="text-align:justify;">8.1 В случае нарушения Заказчиком подпункта 3) пункта 3.2, пункта 6.4 и
                    раздела 7 настоящего&nbsp;Договора, Исполнитель вправе расторгнуть настоящий договор в одностороннем
                    порядке, а Заказчик обязуется оплатить штраф в размере 50 % от общей суммы договора и возместить все
                    убытки Исполнителя. В случае нарушения Заказчиком требований пункта 7.7 Договора, Заказчик&nbsp;&nbsp;обязуется
                    оплатить штраф в размере 50 % от общей суммы договора и возместить все убытки Исполнителя.</p>
                <p style="text-align:justify;">8.2 В случае нарушения установленных Договором сроков оказания Услуг со
                    стороны Исполнителя, Заказчик вправе прекратить исполнение своих обязательств по Договору, направив
                    письменное уведомление о неисполнении обязательств по настоящему договору, оплатив Исполнителю
                    фактически оказанные услуги.</p>
                <p style="text-align:justify;">8.3 За исключением форс-мажорных условий, если Исполнитель не может
                    предоставить Услуги в сроки, предусмотренные Договором, Заказчик без ущерба другим своим правам в
                    рамках Договора взыскивает неустойку (штраф, пеню) в размере 0,1 (ноль целых один) % от суммы
                    договора за каждый день просрочки, но не более 10% от общей суммы Договора, в случае полного
                    неисполнения поставщиком обязательства за каждый день просрочки либо взыскивает неустойку (штраф,
                    пеню) в размере 0,1 (ноль целых один) % к сумме ненадлежаще исполненного обязательства за каждый
                    день просрочки, но не более 10% от общей суммы Договора.</p>
                <p style="text-align:justify;">8.4 Выплата неустойки не освобождает Исполнителя от исполнения своих
                    обязательств по Договору.</p>
                <p style="text-align:justify;">8.5 В случае нарушения пункта 2.2 настоящего договора, Заказчик
                    оплачивает неустойку (штраф, пеню) в размере 0,1 (ноль целых один) %&nbsp; от суммы договора за
                    каждый день просрочки, но не более 10% от общей суммы Договора.</p>
                <p style="text-align:justify;">8.6 Если любое изменение ведет к уменьшению или увеличению стоимости, или
                    сроков, необходимых Исполнителю для оказания Услуг по Договору, то сумма Договора или срок оказания
                    Услуг, или и то и другое соответствующим образом корректируется, а к Договору составляется
                    Дополнительное соглашение с внесенными поправками. Все запросы Исполнителя на проведение
                    корректировки должны быть предъявлены в течение 5 (пяти) рабочих дней со дня получения Исполнителем
                    распоряжения об изменениях от Заказчика.</p>
                <p style="text-align:justify;">8.7 Исполнитель ни полностью, ни частично не должен передавать кому-либо
                    свои обязательства по настоящему Договору без предварительного письменного согласия Заказчика.</p>
                <p style="text-align:justify;">8.8 Исполнитель должен предоставить Заказчику копии всех субподрядных
                    договоров, заключенных в рамках данного Договора.&nbsp;</p>
                <p style="text-align:center;"><strong>9. Срок действия и условия&nbsp;</strong></p>
                <p style="text-align:center;"><strong>расторжения договора</strong></p>
                <p style="text-align:justify;">9.1 Договор вступает в силу со дня подписания и действует по ${date2}.</p>
                <p style="text-align:justify;">9.2 Заказчик может в любое время в одностороннем порядке отказаться от
                    исполнения условий Договора, направив Исполнителю соответствующее письменное уведомление, если
                    Исполнитель становится банкротом или неплатежеспособным. В этом случае отказ от исполнения условий
                    Договора осуществляется немедленно, и Заказчик не несет никакой финансовой обязанности по отношению
                    к Исполнителю при условии, если отказ от исполнения условий Договора не наносит ущерба или не
                    затрагивает каких-либо прав на совершение действий или применение санкций, которые были или будут
                    впоследствии предъявлены Заказчику.</p>
                <p style="text-align:justify;">9.3 Исполнитель может в любое время&nbsp;в одностороннем порядке
                    отказаться от исполнения условий Договора, направив&nbsp;Заказчику соответствующее письменное
                    уведомление,&nbsp;ввиду дальнейшей нецелесообразности оказания Услуг.</p>
                <p style="text-align:justify;">9.4&nbsp;Когда Договор расторгается на основании п. 9.2 настоящего
                    Договора, Исполнитель имеет право требовать оплату за фактические затраты, связанные с расторжением
                    по Договору, на день расторжения.</p>
                <p style="text-align:justify;">9.5 Без ущерба каким-либо другим санкциям за нарушение условий Договора
                    Заказчик может расторгнуть настоящий Договор полностью или частично, направив Исполнителю письменное
                    уведомление о невыполнении обязательств если Исполнитель не может оказать Услуги в сроки,
                    предусмотренные Договором, или в течение периода продления настоящего Договора, предоставленного
                    Заказчиком.</p>
                <p style="text-align:justify;">9.6 Договор может быть расторгнут по соглашению сторон.&nbsp;</p>
                <p style="text-align:justify;">9.7 В вышеперечисленных случаях, представитель Исполнителя наносит запись
                    на каждом листе Договора Заказчика, подтверждающую расторжение Договора по каким-либо причинам,
                    предусмотренным настоящим разделом.</p>
                <p style="text-align:center;"><strong>10. Форс-мажор</strong></p>
                <p style="text-align:justify;">10.1 Стороны не несут ответственность за неисполнение условий Договора,
                    если оно явилось результатом форс-мажорных обстоятельств.</p>
                <p style="text-align:justify;">10.2 Стороны Договора не несут ответственность за выплату неустоек или
                    расторжение Договора в силу неисполнения его условий, если задержка с исполнением Договора является
                    результатом форс-мажорных обстоятельств.</p>
                <p style="text-align:justify;">10.3 Для целей Договора «форс-мажор» означает событие, неподвластное
                    контролю Сторон, и имеющее непредвиденный характер. Такие события могут включать, но не
                    исключительно: военные действия, природные или стихийные бедствия и другие.</p>
                <p style="text-align:justify;">10.4 При возникновении форс-мажорных обстоятельств Исполнитель должен
                    незамедлительно направить Заказчику письменное уведомление о таких обстоятельствах и их причинах.
                    Если от Заказчика не поступает иных письменных инструкций, Заказчик продолжает выполнять свои
                    обязательства по Договору, насколько это целесообразно, и ведет поиск альтернативных способов
                    выполнения Договора, не зависящих от форс- мажорных обстоятельств.</p>
                <p style="text-align:center;"><strong>11. Решение спорных вопросов</strong></p>
                <p style="text-align:justify;">11.1 Заказчик и Исполнитель должны прилагать все усилия к тому, чтобы
                    разрешать в процессе прямых переговоров все разногласия или споры, возникающие между ними по
                    Договору или в связи с ним</p>
                <p style="text-align:justify;">11.2 Если в течение 21 (двадцати одного) календарного дня после начала
                    таких переговоров Заказчик и Исполнитель не могут разрешить спор по Договору, любая из сторон может
                    потребовать решения этого вопроса в соответствии с законодательством Республики Казахстан.</p>
                <p style="text-align:center;"><strong>12. Уведомление</strong></p>
                <p style="text-align:justify;">12.1 Любое уведомление, которое одна сторона направляет другой стороне в
                    соответствии с Договором, высылается в виде письма, телеграммы, телекса, факса или посредсвом
                    электронной почты с последующим предоставлением оригинала. Электронные адреса Сторон указываются в
                    разделе 14 настоящего Договора.</p>
                <p style="text-align:justify;">12.2 Уведомление вступает в силу после доставки или в указанный день
                    вступления в силу (если указано в уведомлении) в зависимости от того, какая из этих дат наступит
                    позднее.</p>
                <p style="text-align:center;"><strong>13. Прочие условия</strong></p>
                <p style="text-align:justify;">13.1 Любые изменения и дополнения к Договору действительны лишь при
                    условии, если они совершены в письменной форме и подписаны уполномоченными на то представителями
                    Сторон и скреплены их печатями.</p>
                <p style="text-align:justify;">13.2 Дополнительные соглашения оформляются исключительно при предъявлении
                    Заказчиком подлинника Договора;</p>
                <p style="text-align:justify;">13.3 Передача обязанностей одной из Сторон по Договору допускается только
                    с письменного согласия другой Стороны.</p>
                <p style="text-align:justify;">13.4 Договор составлен в двух экземплярах на казахском и русском языках,
                    имеющих одинаковую юридическую силу, по одному экземпляру для каждой из Сторон.&nbsp;</p>
                <p style="text-align:justify;">13.5 В части, неурегулированной Договором, Стороны руководствуются
                    законодательством Республики Казахстан.<strong>&nbsp;</strong></p>
                <p style="text-align:center;"><strong>14.&nbsp;Реквизиты сторон</strong></p>
                <p><strong>Исполнитель</strong></p>
                <p>${filial}</p>
                <p>Адрес: ${filialDate?.addressLine}</p>
                <p>БИН ${filialDate?.bin}</p>
                <p>ИИК KZ${filialDate?.bankAccountNumber}</p>
                <p>АО&nbsp;</p>
                <p>БИК ${filialDate?.bankBik}</p>
                <p>КБе ${filialDate?.beneficiaryCode}</p>
                <p>Тел ${filialDate?.phoneNumber}</p>
                <p>e-mail:___________________</p>
                <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</p>
                <p><strong>Директор&nbsp; &nbsp; &nbsp;</strong></p>
                <p>&nbsp;</p>
                <p><strong>${supervisor}</strong></p>
                <p>М.П.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</p>
                <p>&nbsp;</p>
                <p><strong>Заказчик</strong>&nbsp;</p>
                <p>${client}</p>
                <p>Адрес: ${addressLine}</p>
                <p>БИН/ИИН&nbsp;${bin}/${inn}</p>
                <p>Банк ${clientData?.bankName || 'Не задано'}</p>
                <p>ИИК &nbsp;${clientData?.bankAccountNumber || 'Не задано'}</p>
                <p>БИК&nbsp;${clientData?.bankBik || 'Не задано'}</p>
                <p>Кбе ${clientData?.beneficiaryCode || 'Не задано'}</p>
                <p>&nbsp;</p>
                <p>Тел.&nbsp;&nbsp;${clientData?.phoneNumber || 'Не задано'}</p>
                <p>e-mail: ${clientData?.email || 'Не задано'}</p>
                <p>&nbsp;</p>
                <p><strong>Директор</strong></p>
                <p><strong>${supervisorName}</strong></p>
                <p>М.П.</p></td>
        </tr>
        </tbody>
    </table>
</figure><p style="text-align:right;"><i>Приложение</i></p><p style="text-align:right;"><i>к Договору</i></p><p
        style="text-align:right;"><i>№${number}&nbsp;</i></p><p style="text-align:right;"><i>от ${date1}</i></p><p style="text-align:center;"><strong>Перечень закупаемых услуг</strong></p>
<figure class="table">
    <table>
        <tbody>
        <tr>
            <td><p style="text-align:center;"><strong>№</strong></p></td>
            <td><p style="text-align:center;"><strong>Вид услуги</strong></p></td>
            <td><p style="text-align:center;"><strong>Наименование услуги/анализа</strong></p></td>
            <td><p style="text-align:center;"><strong>Единица измерения</strong></p></td>
            <td><p style="text-align:center;"><strong>График</strong></p></td>
            <td><p style="text-align:center;"><strong>Количество&nbsp;</strong></p></td>
            <td><p style="text-align:center;"><strong>Цена</strong></p></td>
            <td><p style="text-align:center;"><strong>Сумма</strong></p></td>
        </tr>
        ${specification && specification.map((el, idx) => {
        return `<tr>
        <td>
            ${idx + 1}
        </td>
        <td>
            ${el.service?.category?.name || 'Не задано'}
        </td>
        <td>
            ${el.service?.name || el.group?.name || 'Не задано'}
        </td>
        <td>
            ${el.unit?.name ? el.unit?.name : el.unit || 'Не задано'}
        </td>
        <td>
            ${el?.schedule?.name || ''}
        </td>
        <td>
            ${el.quantity || 'Не задано'}
        </td>
        <td>
            ${el.price || 'Не задано'}
        </td>
        <td>
            ${((el.quantity ? el.quantity : 1) * el.price).toString()}
        </td>
    </tr>`
    })}
        </tbody>
    </table>
</figure><p style="text-align:right;">&nbsp;</p>
<p style="text-align:right;">ИТОГО: ${sum}</p>
<p style="text-align:center;"><strong>Адрес объекта</strong></p>
<figure class="table">
    <table>
        <tbody>
        <tr>
            <td><p style="text-align:center;"><strong>№</strong></p></td>
            <td><p style="text-align:center;"><strong>Наименование объекта</strong></p></td>
            <td><p style="text-align:center;"><strong>Область</strong></p></td>
            <td><p style="text-align:center;"><strong>Район</strong></p></td>
            <td><p style="text-align:center;"><strong>Населенный пункт</strong></p></td>
            <td><p style="text-align:center;"><strong>Фактический адрес</strong></p></td>
        </tr>
        ${contractObject?.map((el, idx) => {
        return `<tr>
            <td><p style="text-align:center;">${idx + 1})</p></td>
            <td><p style="text-align:center;">${el?.object?.name}</p></td>
            <td><p style="text-align:center;">${el?.district}</p></td>
            <td><p style="text-align:center;">${el?.city}</p></td>
            <td><p style="text-align:center;">${el?.locality}</p></td>
            <td><p style="text-align:center;">${el?.addressLine}</p></td>
        </tr>`
    })}
        </tbody>
    </table>
</figure><p style="text-align:center;">&nbsp;</p>
<figure class="table">
    <table>
        <tbody>
        <tr>
            <td><p style="text-align:center;"><strong>14. Тараптардың реквизиттері</strong></p>
                <p style="text-align:center;">&nbsp;</p>
                <p><strong>Орындаушы</strong></p>
                <p>${filialKz}</p>
                <p>Мекен-жайы: ${filialDate?.addressLine}</p>
                <p>БСН ${filialDate?.bin}</p>
                <p>ЖСК KZ${filialDate?.bankAccountNumber}</p>
                <p>АҚ&nbsp;</p>
                <p>БСК ${filialDate?.bankBik}</p>
                <p>КБе ${filialDate?.beneficiaryCode}</p>
                <p>Тел ${filialDate?.phoneNumber}</p>
                <p>e-mail:___________________</p>
                <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</p>
                <p><strong>Директор&nbsp;</strong></p>
                <p><strong>${supervisor}&nbsp;Т.А.Ә.</strong></p>
                <p style="text-align:justify;">М.О.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp;</p>
                <p>&nbsp;</p>
                <p><strong>Тапсырыс беруші</strong></p>
                <p>&nbsp;</p>
                <p>Мекен-жайы: &nbsp;</p>
                <p>БСН&nbsp;${inn}/${bin}</p>
                <p>ЖСК&nbsp;KZ${clientData?.bankAccountNumber || 'Не задано'}</p>
                <p>БСК&nbsp;${clientData?.bankBik || 'Не задано'}</p>
                <p>Тел.&nbsp;&nbsp;${phoneNumber}</p>
                <p>e-mail: ${clientData?.email || 'Не задано'}</p>
                <p>&nbsp;</p>
                <p><strong>Директор&nbsp;</strong></p>
                <p><strong>${supervisorName}</strong></p>
                <p>М.П.</p></td>
            <td><p style="text-align:center;"><strong>14. Реквизиты сторон</strong></p>
                <p style="text-align:center;">&nbsp;</p>
                <p><strong>Исполнитель</strong></p>
                <p>${filial}</p>
                <p>Адрес: ${filialDate?.addressLine}</p>
                <p>БИН ${filialDate?.bin}</p>
                <p>ИИК KZ ${filialDate?.bankAccountNumber}</p>
                <p>АО&nbsp;</p>
                <p>БИК ${filialDate?.bankBik}</p>
                <p>КБе ${filialDate?.beneficiaryCode}</p>
                <p>Тел ${filialDate?.phoneNumber}</p>
                <p>e-mail: ___________________</p>
                <p>&nbsp; &nbsp; &nbsp; &nbsp;</p>
                <p><strong>Директор&nbsp; &nbsp;</strong></p>
                <p><strong>${supervisor} Ф.И.О.</strong></p>
                <p>М.П.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</p>
                <p>&nbsp;</p>
                <p><strong>Заказчик</strong>&nbsp;</p>
                <p>${client}</p>
                <p>Адрес: ${addressLine}</p>
                <p>ИИК ${clientData?.bankAccountNumber || 'Не задано'}</p>
                <p>БИК&nbsp;${clientData?.bankBik || 'Не задано'}</p>
                <p>БИН&nbsp;${clientData?.bin || 'Не задано'}</p>
                <p>Тел ${clientData?.phoneNumber || 'Не задано'}</p>
                <p>e-mail: ${clientData?.email || 'Не задано'}</p>
                <p></p>
                <p><strong>Директор</strong></p>
                <p><strong>${supervisorName} &nbsp;</strong></p>
                <p>М.П.</p></td>
        </tr>
        </tbody>
    </table>
</figure>`
}
