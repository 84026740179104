import React, {useCallback, useState} from 'react';
import {Button, TextField} from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import {ObjectSectionSelect, ObjectSubSectionSelect} from "../../../components/Select/domainSelects";
import useValidations from "../../../hooks/useValidations";
import Typography from "@mui/material/Typography";
import useLoader from "../../../hooks/useLoader";
import LoadingButton from "@mui/lab/LoadingButton";
import {anObjectsCRUD} from "../../../http/CRUD";
import {showSuccessSnackbar} from "../../../redux/actions/userActions";
import {useDispatch} from "react-redux";

export default function CreateObjectForm({afterSubmit, onClose}) {
  const [data, setData] = useState({});
  const handleChange = useCallback((key, value) => {
    setSubmitErrors(false);
    setData({...data, [key]: value});
  }, [data]);

  const {isValid, renderedErrors} = useValidations(data, [
    {label: "2.1 Наименование объекта", code: "name", rules: ["required"]},
    {label: "2.2 БИН/ИИН объекта", code: "bin", rules: ["required"]},
    {label: "2.4 Адрес", code: "addressLine", rules: ["required"]},
  ]);

  const [showSubmitErrors, setShowSubmitErrors] = useState(null);
  const [submitErrors, setSubmitErrors] = useState(null);
  const dispatch = useDispatch()

  const {loading, start, stop} = useLoader(false);
  const submit = useCallback(() => {
    if(!isValid) {
      setShowSubmitErrors(true);
      return;
    }

    const bin = data.bin;

    anObjectsCRUD.search({
      filter: {
        bin: {
          operand1: bin,
          operator: "equals"
        },
      }
    }).then(({result}) => {
      if (result?.length) {
        dispatch(showSuccessSnackbar("Такой БИН уже есть в системе!", "error"))
      } else {
        start();

        anObjectsCRUD.create(data)
            .then(({id}) => {
              return anObjectsCRUD.get(id)
                  .then((r) => {
                    if(afterSubmit) afterSubmit(r);
                    if(onClose) onClose();
                  })
            })
            .catch(setSubmitErrors)
            .finally(stop)
      }
    })


  }, [onClose, data, isValid, start, stop, afterSubmit]);

  return <Box>
    <DialogTitle>Создание объекта</DialogTitle>
    <DialogContent dividers>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label={"2.1 Наименование объекта"}
            onChange={e => handleChange("name", e.target.value)}
            fullWidth
            value={data.name}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={"2.2 БИН/ИИН объекта"}
            onChange={e => handleChange("bin", e.target.value)}
            fullWidth
            value={data.bin}
            required
          />
        </Grid>
        {/*<Grid item xs={12}>*/}
        {/*  <LegalPersonSelect*/}
        {/*    label={"2.3 Организационно-правовая форма"}*/}
        {/*    onChange={val => handleChange("legalPersonId", val)}*/}
        {/*    fullWidth*/}
        {/*  />*/}
        {/*</Grid>*/}
        <Grid item xs={12}>
          <TextField
            label={"2.4 Адрес"}
            onChange={e => handleChange("addressLine", e.target.value)}
            fullWidth
            value={data.addressLine}
          />
        </Grid>
        <Grid item xs={12}>
          <ObjectSectionSelect
            label={"3.1 Раздел объекта"}
            value={data.sectionId}
            onChange={val => handleChange("sectionId", val)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <ObjectSubSectionSelect
            label={"3.2 Подраздел объекта"}
            value={data.subSectionId}
            disabled={!data.sectionId}
            parentId={data.sectionId}
            parentKey="sectionId"
            onChange={val => handleChange("subSectionId", val)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={"3.3 Объект"}
            onChange={e => {

            }}
            value={null}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={"Описание объекта"}
            onChange={e => handleChange("description", e.target.value)}
            fullWidth
            value={data.description}
          />
        </Grid>
        <Grid item xs={12}>
          {(submitErrors || showSubmitErrors) &&
          <Typography color={"secondary"}>
            {submitErrors || renderedErrors}
          </Typography>}
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} style={{marginLeft: "auto", marginRight: 20}}>Отменить</Button>
      <LoadingButton loading={loading} variant={"contained"} onClick={submit}>Сохранить</LoadingButton>
    </DialogActions>
  </Box>
}
