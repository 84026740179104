export const SIDEBAR_OPEN = 'SIDEBAR_OPEN'
export const SIDEBAR_CLOSE = 'SIDEBAR_CLOSE'

export const OPEN_LIST_CLIENT = 'OPEN_LIST_CLIENT'
export const CLOSE_LIST_CLIENT = 'CLOSE_LIST_CLIENT'

export const LOADING_TRUE = 'LOADING_TRUE'
export const LOADING_FALSE = 'LOADING_FALSE'

export const OPEN_LIST_SERVICE = 'OPEN_LIST_SERVICE'
export const CLOSE_LIST_SERVICE = 'CLOSE_LIST_SERVICE'

export const OPEN_LIST_SOCIAL = 'OPEN_LIST_SOCIAL'
export const CLOSE_LIST_SOCIAL = 'CLOSE_LIST_SOCIAL'

export const OPEN_LIST_TEST = 'OPEN_LIST_TEST'
export const CLOSE_LIST_TEST = 'CLOSE_LIST_TEST'

export const OPEN_LIST_BOOKKEEPING = 'OPEN_LIST_BOOKKEEPING'
export const CLOSE_LIST_BOOKKEEPING = 'CLOSE_LIST_BOOKKEEPING'

export const GET_GENDER_REQUEST = 'GET_GENDER_REQUEST'
export const GET_GENDER_SUCCESS = 'GET_GENDER_SUCCESS'
export const GET_GENDER_ERROR = 'GET_GENDER_ERROR'

export const GET_CURRENCY_REQUEST = 'GET_CURRENCY_REQUEST'
export const GET_CURRENCY_SUCCESS = 'GET_CURRENCY_SUCCESS'
export const GET_CURRENCY_ERROR = 'GET_CURRENCY_ERROR'

export const GET_UNIT_MEASURE_REQUEST = 'GET_UNIT_MEASURE_REQUEST'
export const GET_UNIT_MEASURE_SUCCESS = 'GET_UNIT_MEASURE_SUCCESS'
export const GET_UNIT_MEASURE_ERROR = 'GET_UNIT_MEASURE_ERROR'

export const GET_SERVICE_KIND_REQUEST = 'GET_SERVICE_KIND_REQUEST'
export const GET_SERVICE_KIND_SUCCESS = 'GET_SERVICE_KIND_SUCCESS'
export const GET_SERVICE_KIND_ERROR = 'GET_SERVICE_KIND_ERROR'
