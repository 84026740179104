import {
    CONTRACT_STATE_GET_ERROR,
    CONTRACT_STATE_GET_REQUEST,
    CONTRACT_STATE_GET_SUCCESS
} from "../constants/contract/contractConstants";

const initialState = {
    contractStates: [],
    contractState: {},
    loading: false,
    success: null,
    error: null,
    contractStateRequestedCount: 0,
};

export const contractStateReducer = (state = initialState, action) => {
    switch (action.type)     {
        case CONTRACT_STATE_GET_REQUEST:
            return {
                ...state,
                contractStateRequestedCount: ++state.contractStateRequestedCount,
                loading: true,
            };
        case CONTRACT_STATE_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                contractStates: action.payload,
                success: true,
            };
        case CONTRACT_STATE_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
