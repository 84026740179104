import React from 'react';
import NumberFormat from "react-number-format";
import {TextField} from "@mui/material";



const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, format, mask, placeholder, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={({ formattedValue, value }) => onChange({ formattedValue, value })}
            format={format}
            mask={mask}
            required={true}
            placeholder={placeholder}
            type="text"
        />
    );
});


const NumberFormatShared = ({value, setValues, mask, placeholder, format}) => {

    return (
        <TextField
            margin="normal"
            value={value.value}
            mask={mask}
            placeholder={placeholder}
            onChange={setValues}
            format={format}
            required={true}
            InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: {format, mask, placeholder}
            }}
            fullWidth
        />
    );
}

export default React.memo(NumberFormatShared);