import React, {useEffect, useMemo, useState} from 'react';
import TextField from "@mui/material/TextField";
import {
    Card,
    CardContent,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead, Pagination,
    TableRow, CardActions, CircularProgress, Grid, Divider, DialogContent, Dialog
} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {SearchPanel} from "../index";
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/ControlPoint';
import ModalDrawer from "./ModalDrawer";
import {LOADING_FALSE, LOADING_TRUE} from "../../redux/constants/genericConstants";
import {getServiceCategory, getServicePackage} from "../../redux/actions/serviceCategory/serviceCategoryActions";
import {getServicePackageElement} from "../../redux/actions/service/serviceActions";
import {SERVICE_GET_SUCCESS} from "../../redux/constants/service/serviceConstants";
import ServiceCRUDTable from "../Crud/Tables/ServiceCRUDTable";
import {serviceCRUD, serviceToPackageCRUD} from "../../http/CRUD";
import {Radio} from "antd";
import ServiceListCRUDTable from "../Crud/Tables/ServiceListCRUDTable";
import ServiceClientCRUDTable from "../Crud/Tables/ServiceClientCRUDTable";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    height: '85%',
    transform: 'translate(-50%, -50%)',
    width: '95%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const SelectFieldModal = ({
                              value,
                              type,
                              label,
                              setValue,
                              reduxValue,
                              reduxCount,
                              nameFilter,
                              func,
                              sx,
                              edit,
                              navigate,
                              disabled,
                                data
                          }) => {
    const typeDrawer = {
        service: 'Услуга',
        nothing: 'Ничего',
    }

    const [typeData, setTypeData] = React.useState('package')

    const drawer = typeDrawer[navigate === 1 ? 'service' : 'nothing']

    const [packageParent, setPackageParent] = useState([])
    const {serviceCategory} = useSelector((state) => state.serviceCategory);


    const [id, setId] = useState(2)
    const [searchDrawer, setSearchDrawer] = useState(false)

    const RenderDrawer = useMemo(() => {
        if (drawer === 'Услуга') {
            return (
                <ModalDrawer setSearchDrawer={setSearchDrawer} searchDrawer={searchDrawer} packageParent={packageParent}
                             setId={setId} id={id} category={serviceCategory}/>
            )
        } else {
            return false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, packageParent, serviceCategory]);


    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [row, setRow] = useState(reduxValue)
    const [filter, setFilter] = useState({})
    const [rowCount, setRowCount] = useState(reduxCount)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const dispatch = useDispatch()
    const [selectedItem, setSelectedItem] = useState({})
    const [selectedItemCache, setSelectedItemCache] = useState({})
    const [page, setPage] = useState(1);


    const handleClick = (row) => {
        setSelectedItemCache({id: row.id, name: row[nameFilter]})
    }

    const handleFormSubmit = () => {
        setSelectedItem({...selectedItemCache})
        setValue(selectedItemCache?.id)
        setSelectedItemCache({})
        handleClose()
    }

    useEffect(() => {
        setRow(reduxValue)
        setRowCount(reduxCount)
    }, [open, reduxCount, reduxValue])



    useEffect(() => {
        setPage(1)
        setFilter({})
    }, [func, open])

    useEffect(() => {
        (async function () {
            if (drawer === 'Услуга') {
                dispatch({type: LOADING_TRUE})
                await dispatch(getServiceCategory(1, 100))
                const {result} = await dispatch(getServicePackage(1, 100, {
                    filter: {
                        parentId: {
                            operand1: null,
                            operator: "equals"
                        }
                    }
                }, true))
                setPackageParent(result)
                dispatch({type: LOADING_FALSE})
            }
        }())
    }, [dispatch, drawer])

    useEffect(() => {
        return () => {
            setPackageParent([])
            setSearchDrawer(false)
            setId(2)
        }
    }, [])

    const RenderSearch = useMemo(() => {
        if (drawer === 'Услуга') return false
        return (
            <SearchPanel setFilter={setFilter} rowCount={rowCount} setRow={setRow}
                         setRowCount={setRowCount} page={page} setPage={setPage}
                         func={func} searchName={nameFilter} label="Поиск записи"/>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [func, nameFilter, page, rowCount])

    return useMemo(() => {
        return (
            <>
                <TextField
                    margin="normal"
                    required={true}
                    value={data?.name || data?.group || 'Не задано'}
                    fullWidth
                    disabled
                    label={label}
                    type={type}
                    sx={sx}
                    InputProps={{
                        endAdornment: (
                            loading ?
                                <CircularProgress color="inherit" size={20}/>
                                :
                                (!disabled && <React.Fragment>
                                    <IconButton onClick={handleOpen} children={<AddIcon/>}/>
                                </React.Fragment>)
                        )
                    }}
                />
                {!disabled && <Dialog
                    open={open}
                    onClose={handleClose}
                    fullWidth
                    PaperProps={{sx: {maxHeight: '85%'}}}
                    maxWidth={"80%"}
                >
                    <DialogTitle>Выбор услуги</DialogTitle>
                    <Divider/>
                    <DialogContent>
                            <Radio.Group value={typeData} onChange={(e) => setTypeData(e.target.value)}>
                                <Radio.Button value="package">Пакетные услуги</Radio.Button>
                                <Radio.Button value="price-list">Прейскурант</Radio.Button>
                                <Radio.Button value="price-list-services">Услуги по требованию заказчика</Radio.Button>
                            </Radio.Group>
                            <Box sx={{margin: '20px 0'}}>
                                {typeData === 'package' && (
                                    <>
                                        {RenderSearch}
                                        <Box sx={{display: 'flex'}}>
                                            {RenderDrawer}
                                            <Box sx={{display: 'block', flex: 1}}>
                                                <ServiceCRUDTable
                                                    edit={false}
                                                    serviceToPackage={!(drawer === 'Услуга' && !searchDrawer)}
                                                    search={drawer === 'Услуга' && !searchDrawer ? serviceCRUD.search : serviceToPackageCRUD.search}
                                                    extraFilter={{
                                                        ...(drawer === 'Услуга' && !searchDrawer && {
                                                            typeId: {
                                                                operand1: 1,
                                                                operator: "equals"
                                                            },
                                                            categoryId: {
                                                                operand1: id,
                                                                operator: "equals"
                                                            }
                                                        }),
                                                        ...(drawer === 'Услуга' && searchDrawer && {
                                                            packageId: {
                                                                operand1: +id,
                                                                operator: "equals"
                                                            }
                                                        })
                                                    }}
                                                    onClickCreateButton={undefined} // что бы не показывать кнопку создания
                                                    onRowClick={async (record) => {
                                                        await setSelectedItem({
                                                            ...record,
                                                            id: record.id,
                                                            name: record[nameFilter],
                                                            group: record.group?.name
                                                        })
                                                        await setValue(record)
                                                        handleClose()
                                                    }}
                                                />
                                            </Box>

                                        </Box>
                                    </>
                                )}
                                {typeData === 'price-list' && <>
                                    <Box sx={{display: 'block', flex: 1}}>
                                        <ServiceListCRUDTable
                                            edit={false}
                                            search={serviceCRUD.search}
                                            extraFilter={{
                                                typeId: {
                                                    operand1: 1,
                                                    operator: "equals"
                                                },
                                            }}
                                            onClickCreateButton={undefined} // что бы не показывать кнопку создания
                                            onRowClick={async (record) => {
                                                console.log(record)
                                                await setSelectedItem({
                                                    ...record,
                                                    id: record.id,
                                                    name: record[nameFilter],
                                                    group: record.group?.name
                                                })
                                                await setValue(record)
                                                handleClose()
                                            }}
                                        />
                                    </Box>
                                </>}
                                {typeData === 'price-list-services' && <>
                                    <Box sx={{display: 'block', flex: 1}}>
                                        <ServiceClientCRUDTable
                                            onClickCreateButton={undefined} // что бы не показывать кнопку создания
                                            onRowClick={async (record) => {
                                                await setSelectedItem({
                                                    ...record,
                                                    id: record.id,
                                                    name: record[nameFilter],
                                                    group: record.group?.name
                                                })
                                                await setValue(record)
                                                handleClose()
                                            }}
                                        />
                                    </Box>
                                </>}
                            </Box>
                    </DialogContent>
                    <Divider/>
                    <DialogActions>
                        <Button
                            variant={"text"}
                            style={{marginLeft: "auto"}}
                            onClick={() => setOpen(false)}
                        >
                            Отменить
                        </Button>
                    </DialogActions>
                </Dialog>}
            </>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, handleFormSubmit, handleClick, value])
};

SelectFieldModal.defaultProps = {
    type: "text"
}


export default SelectFieldModal;