import React, {useCallback, useEffect, useMemo, useState} from 'react';
import DashboardLayout from "../../layouts/Dashboard";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuidv4} from "uuid";
import {getClients} from "../../redux/actions/client/clientActions";
import {getOneContract, getTypeContract} from "../../redux/actions/contract/contractActions";
import {getServiceKind, getUnitOfMeasure} from "../../redux/actions/genericActions";
import {getService} from "../../redux/actions/service/serviceActions";
import {Box, CircularProgress, Paper, Step, StepLabel, Stepper} from "@mui/material";
import {AddDateEditPage, AddSpecificationEditPage, GenerateDocumentEditPage} from "../index";
import PopupWarning from "../../components/Popups/message/PopupWarning";
import {useLocation, useParams} from "react-router-dom";
import {getEmployee} from "../../redux/actions/employee/employeeActions";
import AddSignersEdit from "./Pages/Edit/AddSignersEdit";
import {getGeoObject} from "../../redux/actions/geo/geoActions";
import {getFilial, getFilialDivision} from "../../redux/actions/filial/filialActions";
import LoadingDocumentEdit from "./Pages/Edit/LoadingDocumentEdit";
import {CONTRACT_EDIT_ROUTE, CONTRACT_ROUTE} from "../../routes/const";
import BreadcrumbsShared from "../../components/Shared/BreadcrumbsShared";
import {ContractContext} from "./ContractPage";
import {getEducationServiceKind} from "../../redux/actions/educationService/educationServiceActions";
import {getContractAdditional} from "../../redux/actions/contractAdditional/contractAdditionalActions";
import {contractCRUD} from "../../http/CRUD";

moment.locale('ru')

function ContractEdit() {
    const [steps, setSteps] = useState(['Изменение данных', 'Изменение специфики', 'Изменение согласующих', 'Формирование договора']);
    const [qty, setQty] = useState(1)
    const [unitOfMeasure, setUnitOfMeasure] = useState(1)
    const [service, setService] = useState(1)
    const [localServiceKind, setLocalServiceKind] = useState(1)
    const {services, servicesCount} = useSelector((state) => state.service);
    const {unityOfMeasure, serviceKind} = useSelector((state) => state.generic);
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());

    const [specification, setSpecification] = useState([])
    const [signers, setSigners] = useState([]);

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const [localCity, setLocalCity] = useState(1)
    const [localDistrict, setLocalDistrict] = useState(1)
    const {geo} = useSelector((state) => state.geo);

    const {filial, filialDivision} = useSelector((state) => state.filial);

    const [localFilial, setLocalFilial] = useState(null)
    const [localFilialDivision, setLocalFilialDivision] = useState(null)

    const [name, setName] = useState('')
    const [loading, setLoading] = useState(true)
    const [typeContract, setTypeContract] = useState(false)

    const [data2, setData2] = useState({
        isDiscount: false,
        toDecrease: false,
        toIncrease: false,
        discountComment: ''
    })

    const handleChangeObject = useCallback(
        (key, value) => setData2({...data2, [key]: value}),
        [data2]
    );

    const [number, setNumber] = useState(1)
    const [registeredAt, setRegisteredAt] = useState(new Date())
    const [actionAt, setActionAt] = useState(new Date())
    const [client, setClient] = useState(null)

    const [square, setSquare] = useState(0)
    const [multiplicity, setMultiplicity] = useState(0)


    const [legalClient, setLegalClient] = useState(null)
    const [type, setType] = useState(1)
    const [filials, setFilials] = useState([])
    const [active, setActive] = useState(false)
    const dispatch = useDispatch()
    const {contractType} = useSelector((state) => state.contract);
    const {client: clientRedux} = useSelector((state) => state.client);
    const {
        legalClient: legalClientRedux,
        legalClientCount: legalClientReduxCount
    } = useSelector((state) => state.legalClient);
    const {persons, personsCount} = useSelector((state) => state.person);
    const {employee, employeeCount} = useSelector((state) => state.employee);

    const {anObjects, anObjectsCount} = useSelector((state) => state.anObject);
    const {servicesSchedule, servicesScheduleCount} = useSelector((state) => state.service);

    const [data, setData] = useState([])

    const [anObjectsLocal, setAnObjectsLocal] = useState(1)
    const [servicesScheduleLocal, setServicesScheduleLocal] = useState(1)

    const [openModal, setOpenModal] = useState(false)
    const [rowsModal, setRowsModal] = useState({})
    const [date1, setDate1] = useState()
    const [date2, setDate2] = useState()

    const {id} = useParams()

    const handleForm = async (e) => {
        e.preventDefault()
        handleNext()
    }

    const {pathname} = useLocation()

    const [isAdditional] = useState(pathname.includes('/contract-additional'))

    useEffect(() => {
        if (type === 3 || type === 4) {
            setSteps(['Добавление данных', 'Добавление специфики', 'Загрузка договора'])
        } else if (type === 5) {
            setSteps(['Добавление данных', 'Загрузка договора'])
        } else {
            setSteps(['Добавление данных', 'Добавление специфики', 'Добавление согласующих', 'Формирование договора'])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type])

    const handleFormGenerate = async (e) => {
        e.preventDefault()
        setDate1(moment(registeredAt).format('LL'))
        setDate2(moment(actionAt).format('LL'))

        setActive(true)
    }

    const handleOpenEditPopup = async (row) => {
        await setRowsModal(row)
        setOpenModal(true)
    }

    const {profile} = useSelector((state) => state.profile);

    const emptyStateSpec = () => {
        setQty(1)
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleDeleteSpec = async (row) => {
        const newSpec = specification.filter(el => el.Id !== row)
        await setSpecification(newSpec);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleFormSpec = async (e) => {
        e.preventDefault()
        const serviceIdx = services.findIndex(el => el.id === service)
        const newElement = {
            Id: uuidv4(),
            serviceId: service,
            unitId: unitOfMeasure,
            quantity: qty,
            price: services[serviceIdx]?.price,
            objectId: '',
            scheduleId: '',
            square: 1,
            multiplicity: 1,

        }
        setSpecification(oldArray => [...oldArray, newElement])
        emptyStateSpec()
    }

    const handleSigners = async (e) => {
        e.preventDefault()
        const newElement = {
            Id: uuidv4(),
            employeeId: '',
            isSigned: false
        }
        setSigners(oldArray => [...oldArray, newElement])
    }

    useEffect(() => {
        setLoading(true)
        let resUnit
        let resService
        let resServiceKind
        let resOneClient
        const getLocal = async () => {
            const res = await contractCRUD.get(id)
            resOneClient = await dispatch(getOneContract(id))
            setData(res)
            setData2({
                isDiscount: res?.isDiscount,
                toDecrease: res?.toDecrease,
                toIncrease: res?.toIncrease,
                discountComment: res?.discountComment
            })
            setTypeContract(!!resOneClient?.client?.personId)
            await dispatch(getClients())
            await dispatch(getTypeContract())
            resUnit = await dispatch(getUnitOfMeasure())
            await dispatch(getEducationServiceKind())
            resServiceKind = await dispatch(getServiceKind())
            resService = await dispatch(getService())
            await dispatch(getEmployee())
            setDistrict(await dispatch(getGeoObject(1, 100, {
                filter: {
                    typeId: {
                        operand1: 2,
                        operator: "equals"
                    },
                }
            }, true)))
            await dispatch(getGeoObject(1, 100, {
                filter: {
                    typeId: {
                        operand1: 3,
                        operator: "equals"
                    },
                }
            }))
            await dispatch(getFilial(1, 100))
            await dispatch(getFilialDivision())

            if (isAdditional) {
                const resAdditional = await dispatch(getContractAdditional(1, 1, {
                    filter: {
                        additionalAgreementId: {
                            operand1: id,
                            operator: "equals"
                        }
                    }
                }))
                setContractId(resAdditional[0]?.contractId)
                setContractIdOld(resAdditional[0]?.contractId)
            }

            if (resOneClient) {
                setFilials(resOneClient?.filials)
                setName(resOneClient?.name)
                setNumber(resOneClient?.number)
                if (resOneClient?.client?.personId) {
                    setClient(resOneClient?.client?.personId)
                } else if (resOneClient?.client?.legalPersonId) {
                    setLegalClient(resOneClient?.client?.legalPersonId)
                }
                setType(resOneClient?.typeId)
                setRegisteredAt(resOneClient?.registeredAt)
                setActionAt(resOneClient?.actionAt)
                setLocalFilial(resOneClient?.filialId)
                setLocalCity(resOneClient?.cityId)
                setLocalFilialDivision(resOneClient?.filialDivisionId)
                setUnitOfMeasure(resUnit[0].id)
                setLocalServiceKind(resServiceKind[0].id)
                setService(resService.result[0].id)
                setLocalDistrict(resOneClient?.districtId)
            }
            setEducationServices(resOneClient?.educationServices.map(el => {
                return {
                    Id: uuidv4(),
                    kind: '',
                    kindId: el?.kindId,
                    service: '',
                    serviceId: el?.serviceId
                }
            }))
            setContractObject(resOneClient?.objects.map(el => {
                return {
                    Id: uuidv4(),
                    name: el.name,
                    district: el?.district?.name || '',
                    districtId: el?.districtId,
                    city: el?.city?.name || '',
                    cityId: el?.cityId,
                    locality: el?.locality?.name || '',
                    localityId: el?.localityId,
                    addressLine: el?.addressLine,
                    objectId: el?.objectId,
                    object: el?.object
                }
            }))
            setSchedules(resOneClient?.schedules.map(el => {
                return {
                    Id: uuidv4(),
                    targetMonth: el.targetMonth,
                    targetYear: el.targetYear,
                }
            }))
            setSpecification(resOneClient.specifications.map(el => {
                return {
                    Id: uuidv4(),
                    service: el.service,
                    serviceId: el.serviceId,
                    unit: resUnit.find(row => row.id === el.unitId)?.name,
                    unitId: el.unitId,
                    quantity: el.quantity,
                    qty: el.quantity,
                    price: el.price,
                    square: 1,
                    multiplicity: 1,
                    object: el.object,
                    objectId: el.objectId,
                    scheduleId: el.scheduleId,
                    schedule: el.schedule
                }
            }))
            setSigners(resOneClient.signers.filter(el => el.userId !== profile.userId).map(el => {
                return {
                    Id: uuidv4(),
                    employeeId: el.employeeId,
                    employee: el.employee,
                    userId: el.userId,
                    isSigned: false
                }
            }))
            setLoading(false)
        }
        getLocal()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    const [educationServices, setEducationServices] = useState([])
    const [localEducationServiceKind] = useState('')
    const {educationServiceKind} = useSelector((state) => state.education);

    const handleEducationServices = () => {
        const educationServiceKindIdx = educationServiceKind.findIndex(el => el.id === localEducationServiceKind)
        const newElement = {
            Id: uuidv4(),
            serviceKind: educationServiceKind[educationServiceKindIdx]?.name,
            service: '',
            serviceId: '',
        }
        setEducationServices(oldArray => [...oldArray, newElement])
    }

    const [schedules, setSchedules] = useState([])
    const [localTargetMonth, setLocalTargetMonth] = useState(new Date())
    const [localTargetYear, setLocalTargetYear] = useState(new Date())
    const [district, setDistrict] = useState([])
    const [contractObject, setContractObject] = useState([])
    const [contractId, setContractId] = useState('')
    const [contractIdOld, setContractIdOld] = useState(1)
    const {contracts, contractsCount} = useSelector((state) => state.contract);

    const handleSchedules = () => {
        const newElement = {
            Id: uuidv4(),
            targetMonth: localTargetMonth,
            targetYear: localTargetYear,
        }
        setSchedules(oldArray => [...oldArray, newElement])
    }

    const handleContractObjectRequest = () => {
        const newElement = {
            Id: uuidv4(),
            name: '',
            district: '',
            districtId: '',
            city: '',
            cityId: '',
            locality: '',
            localityId: '',
            addressLine: '',
            objectId: ''
        }
        setContractObject(oldArray => [...oldArray, newElement])
    }

    const RenderEditDate = useMemo(() => {
        return (
            <AddDateEditPage filial={filial} filialDivision={filialDivision} setLocalFilial={setLocalFilial}
                             setLocalFilialDivision={setLocalFilialDivision} localFilial={localFilial}
                             localFilialDivision={localFilialDivision} geo={geo} localCity={localCity}
                             setLocalCity={setLocalCity} personRedux={persons} personCount={personsCount}
                             legalClientRedux={legalClientRedux}
                             legalClientReduxCount={legalClientReduxCount} legalClient={legalClient}
                             setLegalClient={setLegalClient} typeContract={typeContract}
                             setTypeContract={setTypeContract} activeStep={activeStep}
                             handleBack={handleBack} steps={steps} number={number}
                             setNumber={setNumber} client={client} type={type} name={name}
                             setName={setName} actionAt={actionAt} clientRedux={clientRedux}
                             contractType={contractType}
                             setActionAt={setActionAt} handleForm={handleForm}
                             registeredAt={registeredAt} setRegisteredAt={setRegisteredAt}
                             setClient={setClient}
                             setType={setType}/>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionAt, activeStep, clientRedux, contractType, filialDivision, geo, handleForm, legalClientRedux, localCity, localFilial, localFilialDivision, name, number, persons, registeredAt, steps, type, typeContract]);


    const RenderSpecification = useMemo(() => {
        if (activeStep !== 1) return false;
        return (
            <AddSpecificationEditPage
                number={number}
                name={name}
                id={id}
                registeredAt={registeredAt}
                actionAt={actionAt}
                legalClient={legalClient}
                type={type}
                client={client}
                localFilial={localFilial}
                localCity={localCity}
                typeContract={typeContract}
                localFilialDivision={localFilialDivision}
                educationServices={educationServices}
                schedules={schedules}
                contractObject={contractObject}
                servicesCount={servicesCount} handleDeleteSpec={handleDeleteSpec}
                anObjects={anObjects} anObjectsLocal={anObjectsLocal}
                setAnObjectsLocal={setAnObjectsLocal} anObjectsCount={anObjectsCount}
                servicesScheduleLocal={servicesScheduleLocal}
                servicesSchedule={servicesSchedule}
                servicesScheduleCount={servicesScheduleCount}
                setServicesScheduleLocal={setServicesScheduleLocal}
                setOpen={handleOpenEditPopup} handleNext={handleNext}
                setSpecification={setSpecification} specification={specification}
                handleFormSpec={handleFormSpec} steps={steps} handleBack={handleBack}
                activeStep={activeStep} service={service}
                setService={setService} serviceKind={serviceKind}
                localServiceKind={localServiceKind}
                services={services.map(el => ({
                    id: el.id,
                    name: el.name,
                    price: el.price
                }))}
                qty={qty} setQty={setQty} setLocalServiceKind={setLocalServiceKind}
                setUnitOfMeasure={setUnitOfMeasure}
                unitOfMeasure={unitOfMeasure} unityOfMeasure={unityOfMeasure}
            />
        )
    }, [actionAt, activeStep, anObjects, anObjectsCount, anObjectsLocal, client, contractObject, educationServices, handleDeleteSpec, handleFormSpec, handleNext, legalClient, localCity, localFilial, localFilialDivision, localServiceKind, name, number, qty, registeredAt, schedules, service, serviceKind, services, servicesCount, servicesSchedule, servicesScheduleCount, servicesScheduleLocal, specification, steps, type, typeContract, unitOfMeasure, unityOfMeasure]);

    return (
        <DashboardLayout>
            <BreadcrumbsShared breadcrumbs={[
                {text: isAdditional ? 'Доп. соглашения' : 'Договоры', link: CONTRACT_ROUTE},
                {
                    text: isAdditional ? 'Редактирование доп. соглашения' : 'Редактирование договора',
                    link: CONTRACT_EDIT_ROUTE + `/${id}`
                },
            ]}/>
            <Paper sx={{pb: 5}}>
                {loading ? <Box sx={{
                    minHeight: 556,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}><CircularProgress/></Box> : <Box sx={{pt: 5}}>
                    <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};
                            if (isStepSkipped(index)) {
                                stepProps.completed = false;
                            }
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    {activeStep === 0 &&
                        <ContractContext.Provider value={{
                            contracts,
                            contractsCount,
                            filialDivision, filial, filials, setFilials,
                            isAdditional,
                            contractId,
                            square, setSquare,
                            multiplicity, setMultiplicity,
                            setContractId,
                            handleSchedules,
                            localTargetYear,
                            setLocalTargetYear,
                            localTargetMonth,
                            setLocalTargetMonth,
                            setSchedules,
                            schedules,
                            setEducationServices,
                            educationServiceKind,
                            localEducationServiceKind,
                            educationServices,
                            handleEducationServices,
                            anObjectsCount,
                            anObjects,
                            district,
                            handleContractObjectRequest,
                            contractObject,
                            localDistrict,
                            setLocalDistrict,
                            data,
                            data2,
                            handleChangeObject,
                            setContractObject
                        }}>
                            {RenderEditDate}
                        </ContractContext.Provider>}
                    {activeStep === 1 && (type !== 5) &&
                        <ContractContext.Provider value={{
                            square, setSquare,
                            multiplicity, setMultiplicity,
                        }}>
                            <Box>
                                {RenderSpecification}
                            </Box>
                        </ContractContext.Provider>
                    }
                    {activeStep === 1 && (type === 5) &&
                        <LoadingDocumentEdit filials={filials} id={id} data={data} isAdditional={isAdditional}
                                             contractObject={contractObject} educationServices={educationServices}
                                             schedules={schedules} geo={geo} filial={filial} localFilial={localFilial}
                                             localFilialDivision={localFilialDivision} localCity={localCity}
                                             legalClient={legalClient} typeContract={typeContract}
                                             registeredAt={registeredAt}
                                             actionAt={actionAt} type={type} client={client} activeStep={activeStep}
                                             handleBack={handleBack} name={name} number={number}
                                             specification={specification}
                                             active={active} date1={date1} date2={date2} signers={signers}/>}
                    {activeStep === 2 && (type === 1 || type === 2) &&
                        <AddSignersEdit activeStep={activeStep} handleBack={handleBack} steps={steps}
                                        handleNext={handleNext} signers={signers} setSigners={setSigners}
                                        employee={employee} employeeCount={employeeCount} handleSigners={handleSigners}
                        />}
                    {activeStep === 2 && (type === 3 || type === 4) &&
                        <LoadingDocumentEdit localDistrict={localDistrict} data={data} id={id}
                                             isAdditional={isAdditional} contractObject={contractObject}
                                             educationServices={educationServices} schedules={schedules} geo={geo}
                                             filial={filial} localFilial={localFilial}
                                             localFilialDivision={localFilialDivision} localCity={localCity}
                                             legalClient={legalClient} typeContract={typeContract}
                                             registeredAt={registeredAt}
                                             actionAt={actionAt} type={type} client={client} activeStep={activeStep}
                                             handleBack={handleBack} name={name} number={number}
                                             specification={specification}
                                             active={active} date1={date1} date2={date2} signers={signers}/>}
                    {activeStep === 3 &&
                        <GenerateDocumentEditPage data2={data2} filialDivision={filialDivision} localDistrict={localDistrict}
                                                  data={data} contractIdOld={contractIdOld} contractId={contractId}
                                                  isAdditional={isAdditional}
                                                  contractObject={contractObject}
                                                  educationServices={educationServices}
                                                  schedules={schedules} geo={geo} filial={filial}
                                                  localFilial={localFilial}
                                                  localFilialDivision={localFilialDivision}
                                                  localCity={localCity} legalClient={legalClient}
                                                  signers={signers} id={id}
                                                  typeContract={typeContract}
                                                  registeredAt={registeredAt} actionAt={actionAt}
                                                  type={type} client={client} activeStep={activeStep}
                                                  handleBack={handleBack} name={name} number={number}
                                                  specification={specification} active={active}
                                                  date1={date1} date2={date2}
                                                  handleFormGenerate={handleFormGenerate}
                                                  square={square}
                                                  setSquare={setSquare}
                                                  multiplicity={multiplicity}
                                                  setMultiplicity={setMultiplicity}
                        />}
                </Box>}
            </Paper>
            <PopupWarning/>
        </DashboardLayout>
    );
}

export default ContractEdit;
