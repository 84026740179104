import {
    EMPLOYEE_GET_ERROR,
    EMPLOYEE_GET_SUCCESS,
    EMPLOYEE_GET_REQUEST,
    EMPLOYEE_GET_AUTHORIZED_REQUEST, EMPLOYEE_GET_AUTHORIZED_SUCCESS, EMPLOYEE_GET_AUTHORIZED_ERROR
} from "../../constants/employee/employeeConstants";

const initialState = {
    employee: [],
    employeeCount: null,
    loading: false,
    authorizedEmployee: {},
    success: null,
    error: null
};

export const employeeReducer = (state = initialState, action) => {
    switch (action.type) {
        case EMPLOYEE_GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case EMPLOYEE_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                employee: action.payload.result,
                employeeCount: action.payload.pageCount,
                success: true
            };
        case EMPLOYEE_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case EMPLOYEE_GET_AUTHORIZED_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case EMPLOYEE_GET_AUTHORIZED_SUCCESS:
            return {
                ...state,
                authorizedEmployee: action.payload,
                loading: false,
            };
        case EMPLOYEE_GET_AUTHORIZED_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
}