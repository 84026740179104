import React, {useEffect, useState} from 'react';
import DashboardLayout from "../../layouts/Dashboard";
import {useDispatch, useSelector} from "react-redux";
import {getSpecification} from "../../redux/actions/contract/specificationActions";
import {useTranslation} from "react-i18next";
import {PopupContractSpecification, TableCustom} from "../../components";

const Specification = () => {
    const {t} = useTranslation();
    const rowName = [
        {text: t("specification.tableItem.id")},
        {text: t("specification.tableItem.serviceType")},
        {text: t("specification.tableItem.service")},
        {text: t("specification.tableItem.unitOfMeasure")},
        {text: t("specification.tableItem.servicePriceList")},
        {text: t("specification.tableItem.qty")},
        {text: t("specification.tableItem.price")},
    ]
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false);
    const [edit, setEdit] = useState(false);
    const [id, setId] = useState(null);
    const setEditHandler = async (idEdit) => {
        setOpen(true)
        setEdit(true)
        setId(idEdit)
    }
    const {specification} = useSelector((state) => state.specification);
    useEffect(() => {
        dispatch(getSpecification())
    }, [dispatch])
    return (
        <DashboardLayout>
            <TableCustom name="Специфика" avatars={false} setEdit={setEditHandler} rowName={rowName}
                         rows={specification.map(el => {
                             return {
                                 id: el.id,
                                 serviceType: el.serviceKind?.name,
                                 service: el.service?.name,
                                 unit: el.unit?.name,
                                 servicePriceList: el.servicePriceList?.name,
                                 quantity: el.quantity,
                                 price: el.price
                             }
                         })} locale="specification" rowsPage={specification}/>
            <PopupContractSpecification id={id} edit={edit} setEdit={setEdit} setOpen={setOpen} open={open} title="Специфика"/>
        </DashboardLayout>
    );
};

export default Specification;