import axios from 'axios'
import {LOGIN_ROUTE} from "../routes/const";

const $host = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL : process.env.REACT_APP_PROD_API_URL
})

const $authHost = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL : process.env.REACT_APP_PROD_API_URL
})

const $s3host = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? process.env.REACT_APP_S3_API_URL : process.env.REACT_APP_PROD_S3_API_URL
})

const authIntterceptor = config => {
  config.headers.authorization = `Bearer ${localStorage.getItem('token')}`
  return config
}

const logout = () => {
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("token");
  localStorage.removeItem("userInfo");
  window.location.href = LOGIN_ROUTE
}

$authHost.interceptors.response.use(response => {
  return response;
}, async error => {
  if (error.response.status === 401) {
      if (`/${window.location.href.split('/')[3]}` !== LOGIN_ROUTE) {
        logout();
      }

      // const inf = JSON.parse(localStorage.getItem('userInfo'))
      // const refreshToken = localStorage.getItem('refreshToken')
      // if (inf && refreshToken) {
      //     if (error.response.config.url !== '/api/v1/refreshToken') {
      //         const {data} = await $authHost.post("/api/v1/refreshToken",
      //             {username: inf.userName, refreshToken})
      //         localStorage.setItem("token", data.token);
      //         localStorage.setItem("refreshToken", data.refreshToken);
      //         window.location.reload()
      //     } else {
      //         logout()
      //     }
      // } else if (`/${window.location.href.split('/')[3]}` !== LOGIN_ROUTE) {
      //     logout()
      // }
  }
  throw error;
});


$authHost.interceptors.request.use(authIntterceptor)

$s3host.interceptors.request.use(authIntterceptor)

export function postForDownloadFile(
    data,
    url,
    contentType = 'application/json',
) {
  return axios.request({
    method: 'POST',
    url,
    headers: {
      'Content-Type': contentType,
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    },
    data: data,
    responseType: 'blob',
  });
}

export {
  $host,
  $authHost,
    $s3host
}
