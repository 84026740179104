import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import {
    Dialog,
    DialogContent,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    MenuItem,
    Radio,
    RadioGroup
} from "@mui/material";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    addGeoObject,
    editGeoObject,
    getGeoObject,
    getGeoObjectType, getOneGeoObject
} from "../../redux/actions/geo/geoActions";
import {Number} from "../index";
import TextShared from "../Shared/TextShared";
import {style} from "./popup";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import {FilialSelect, GeoObjectSelect} from "../Select/domainSelects";
import {Autocomplete} from "@mui/lab";
import {geoObjectCRUD} from "../../http/CRUD";

export const PopupGeo = ({open, details, setOpen, reload, edit, setEdit, id}) => {
    const [name, setName] = useState('')
    const [isParent, setIsParent] = useState('2')
    const [filialId, setFilialId] = useState('');
    const [typesGeo, setTypesGeo] = useState({})
    const dispatch = useDispatch()
    const {geoType, geo} = useSelector((state) => state.geo);
    const [geoFilter, setGeoFilter] = useState([])
    const [geoChild, setGeoChild] = useState([])

    const emptyState = () => {
        setName('')
        setIsParent('3')
        setTypesGeo({})
    }
    const handleForm = async (e) => {
        e.preventDefault()
        if (edit) {
            await dispatch(editGeoObject(id, name, parseInt(isParent), filialId, (isParent === '3' || isParent === '4') ? typesGeo?.id : ''))
            reload();
        } else {
            await dispatch(addGeoObject(name, parseInt(isParent), filialId, (isParent === '3' || isParent === '4') ? typesGeo?.id : ''))
            reload();
        }
        setOpen(false)
        if (!details) {
            setEdit(false)
            emptyState()
        }
    }
    const handleClose = async () => {
        if (edit && !details) {
            setEdit(false)
            emptyState()
        }
        setOpen(false)
    }

    const handleChange = (event) => {
        setIsParent(event.target.value);
    };


    useEffect(() => {
        (async function () {
            if (edit) {
                const {name, typeId, filialId, parentId} = await dispatch(getOneGeoObject(id))
                setName(name)
                setFilialId(filialId)
                const typesGeo = await geoObjectCRUD.get(parentId);
                setIsParent(typeId.toString())
                parentId && setTypesGeo({name: typesGeo?.name, id: typesGeo?.id})
            }
        }())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [geo, details, edit, id])

    useEffect(() => {
        let resType
        let resGeo
        let resGeoChild
        const getLocal = async () => {
            await dispatch(getGeoObject(1, 1000))
            resType = await dispatch(getGeoObjectType())
            resGeo = await dispatch(getGeoObject(1, 1000, {
                filter: {
                    typeId: {
                        operand1: 2,
                        operator: "equals"
                    },
                }
            }))
            resGeoChild = await dispatch(getGeoObject(1, 1000, {
                filter: {
                    typeId: {
                        operand1: 3,
                        operator: "equals"
                    },
                }
            }))
            setGeoChild(resGeoChild)
            setGeoFilter(resGeo)
            if (resGeo?.length && resType?.length && !edit) {
                setTypesGeo(resGeo[0].id)
            }
        }
        getLocal()
    }, [dispatch, edit])
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            PaperProps={{sx: {maxHeight: '85%'}}}
            maxWidth={"80%"}
        >
            <DialogTitle>Гео. объекты ({!edit ? 'Создание' : 'Редактирование'})</DialogTitle>
            <Divider/>
            <DialogContent>
                <Box component="form" onSubmit={handleForm} sx={{mt: 1, display: 'flex', flexWrap: 'wrap'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextShared value={name} setValue={setName} label="Название"/>
                        </Grid>
                        <Grid item xs={12}>
                            <FilialSelect
                                label={"Филиал"}
                                fullWidth
                                value={filialId}
                                onChange={val => setFilialId(val)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl required component="fieldset">
                                <FormLabel component="legend">Вид гео объекта</FormLabel>
                                <RadioGroup
                                    aria-label="gender"
                                    name="controlled-radio-buttons-group"
                                    value={isParent}
                                    onChange={handleChange}
                                >
                                    {geoType && geoType.map(el => (
                                        <FormControlLabel key={el.id} value={el.id} control={<Radio/>} label={el.name}/>
                                    ))}
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        {isParent === '3' && <Grid item xs={12} sx={{display: 'flex', alignItems: 'center'}}>
                            <Autocomplete
                                disablePortal
                                name='typesGeo'
                                fullWidth
                                value={typesGeo}
                                onChange={(_, value) => {
                                    setTypesGeo(value)}
                                }
                                noOptionsText='Ничего не найдено'
                                getOptionLabel={(option)=>(option.name ? option.name :'')}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                options={!edit ? geoFilter.map(({id, name}) => ({ id, name: `${name}` })) :
                                    geoFilter.filter(el => el.id !== id).map(({id, name}) => ({ id, name: `${name}` }))}
                                renderInput={params => <TextField {...params}
                                                                  required margin="normal" fullWidth id="select" label="Выберите родительский элемент"/>}/>
                        </Grid>}
                        {isParent === '4' && <Grid item xs={12} sx={{display: 'flex', alignItems: 'center'}}>
                            <Autocomplete
                                disablePortal
                                name='typesGeo'
                                fullWidth
                                value={typesGeo}
                                onChange={(_, value) => {
                                    setTypesGeo(value)}
                                }
                                noOptionsText='Ничего не найдено'
                                getOptionLabel={(option)=>(option.name ? option.name :'')}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                options={!edit ? geoChild.map(({id, name}) => ({ id, name: `${name}` })) :
                                    geoChild.filter(el => el.id !== id).map(({id, name}) => ({ id, name: `${name}` }))}
                                renderInput={params => <TextField {...params}
                                                                  required margin="normal" fullWidth id="select" label="Выберите родительский элемент"/>}/>
                        </Grid>}
                        <Grid item xs={6}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{mt: 3, mb: 2}}
                            >
                                {edit ? 'Редактирование' : 'Добавить'}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <Divider/>
            <DialogActions>
                <Button
                    variant={"text"}
                    style={{marginLeft: "auto"}}
                    onClick={() => setOpen(false)}
                >
                    Отменить
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default React.memo(PopupGeo)
