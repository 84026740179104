import {getData, getOneData} from "../template/get";
import {
    GET_DEAL_ERROR,
    GET_DEAL_REQUEST, GET_DEAL_STAGE_ERROR,
    GET_DEAL_STAGE_REQUEST, GET_DEAL_STAGE_SUCCESS,
    GET_DEAL_SUCCESS, GET_ONE_DEAL_ERROR, GET_ONE_DEAL_REQUEST, GET_ONE_DEAL_SUCCESS
} from "../../constants/deal/dealConstants";
import {
    CONTRACT_ADD_ERROR,
} from "../../constants/contract/contractConstants";
import {$authHost} from "../../../http";

export const getDeal = (page, size, args, nonGlobal, sortName) => async (dispatch) => {
    return await dispatch(getData(GET_DEAL_REQUEST, GET_DEAL_SUCCESS, GET_DEAL_ERROR, 'deal', false, 1, null, null, false, 100, args, nonGlobal, sortName, 'asc'))
};

export const getOneDeal = (id) => async (dispatch) => {
    return await dispatch(getOneData(id, GET_ONE_DEAL_REQUEST, GET_ONE_DEAL_SUCCESS, GET_ONE_DEAL_ERROR, 'deal'))
};

export const changeStateDeal = async (id, oldStageId, newStateId)  => {
    await $authHost.post(
        "/api/v1/deal/workflow/run",
        {
            id,
            oldStateId: oldStageId,
            newStateId: newStateId,
        }
    );
};

export const getDealStage = (page, size, args, nonGlobal) => async (dispatch) => {
    return await dispatch(getData(GET_DEAL_STAGE_REQUEST, GET_DEAL_STAGE_SUCCESS, GET_DEAL_STAGE_ERROR, 'dealState', false, 1, null, null, false, 100, {args}, nonGlobal, 'id', 'asc'))
};

export const editDeal = (id, name, budget, probability, comment, sortingIndex, accountId, contractId, stageId, contactId, nameFilter) => async (dispatch) => {
    try {
        const {data: dataClient} = await $authHost.post(
            "/api/v1/client/query",
            {
                filter: {
                    [nameFilter]: {
                        operand1: contactId,
                        operator: "equals"
                    },
                }
            }
        )
        await $authHost.put(
            "/api/v1/deal",
            {
                id,
                contactId: dataClient?.result[0]?.id,
                name,
                budget,
                probability,
                comment,
                sortingIndex,
                accountId,
                contractId,
                stageId
            }
        );
    } catch (error) {
        dispatch({
            type: CONTRACT_ADD_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const addDeal = (name, sortingIndex, accountId, stageId, contactId, comments, nameFilter, localFilial, localFilialDivision, createdAt) => async (dispatch) => {
    try {
        const {data: dataClient} = await $authHost.post(
            "/api/v1/client/query",
            {
                filter: {
                    [nameFilter]: {
                        operand1: contactId,
                        operator: "equals"
                    },
                }
            }
        )
        await $authHost.post(
            "/api/v1/deal",
            {
                contactId: dataClient?.result[0]?.id,
                name,
                comment: 'Comment',
                sortingIndex,
                accountId,
                stageId,
                comments: [comments],
                filialId: localFilial,
                filialDivisionId: localFilialDivision,
                createdAt
            }
        );
    } catch (error) {
        dispatch({
            type: CONTRACT_ADD_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};
