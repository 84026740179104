import {$authHost} from "../../../http";
import {
    TYPE_SERVICE_ADD_ERROR,
    TYPE_SERVICE_ADD_REQUEST,
    TYPE_SERVICE_ADD_SUCCESS,
    TYPE_SERVICE_DELETE_ERROR,
    TYPE_SERVICE_DELETE_REQUEST,
    TYPE_SERVICE_DELETE_SUCCESS, TYPE_SERVICE_EDIT_ERROR, TYPE_SERVICE_EDIT_REQUEST, TYPE_SERVICE_EDIT_SUCCESS,
    TYPE_SERVICE_GET_ERROR,
    TYPE_SERVICE_GET_REQUEST,
    TYPE_SERVICE_GET_SUCCESS
} from "../../constants/service/typeServiceConstants";
import {getData} from "../template/get";
import {editData} from "../template/edit";

export const addTypeService = (code, description, name) => async (dispatch) => {
    try {
        dispatch({
            type: TYPE_SERVICE_ADD_REQUEST,
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.post(
            "/api/v1/serviceType",
            {code, description, name},
            config
        );
        dispatch(getTypeService())
        dispatch({
            type: TYPE_SERVICE_ADD_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: TYPE_SERVICE_ADD_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const editTypeService = (id, code, description, name) => async (dispatch) => {
    return await dispatch(editData(TYPE_SERVICE_EDIT_REQUEST, TYPE_SERVICE_EDIT_SUCCESS, TYPE_SERVICE_EDIT_ERROR, 'serviceType', getTypeService(), {id, code, description, name}))
};

export const deleteTypeService = (id) => async (dispatch) => {
    try {
        dispatch({
            type: TYPE_SERVICE_DELETE_REQUEST,
        });

        await $authHost.delete(
            "/api/v1/serviceType",
            {
                data: {
                    id
                }
            },
        );
        dispatch(getTypeService())
        dispatch({
            type: TYPE_SERVICE_DELETE_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: TYPE_SERVICE_DELETE_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const getTypeService = () => async (dispatch) => {
    return await dispatch(getData(TYPE_SERVICE_GET_REQUEST, TYPE_SERVICE_GET_SUCCESS, TYPE_SERVICE_GET_ERROR, 'serviceType'))
};