import React from 'react';

import { dealCRUD} from "../../../http/CRUD";
import CRUDTableQuery from "../CRUDTableQuery";
import {formatDate} from "../../../functions/formatters";
import {useHistory} from "react-router-dom";
import {DEAL_ADD_ROUTE, DEAL_ROUTE} from "../../../routes/const";
import {getDealStates} from "../../../redux/actions/dealStateActions";
import {confirmDialog} from "../../Popups/message/PopupDelete";
import CRUDTableStandart from "../CRUDTableStandart";

export default function DealCRUDTable({type = 'query', ...props}) {
    const columns = [
        {title: "#", dataIndex: 'id', key: 'id'},
        {title: "Название", dataIndex: 'name', key: 'name'},
        {
            title: "Филиал",
            key: 'filial',
            render: (unknown, row) => row.filialId ? row.filial?.name :
                <span style={{color: "gray"}}>Не задан</span>
        },
        {
            title: "Районное отделение",
            key: 'filialDivision',
            render: (unknown, row) => row.filialDivisionId ? row.filialDivision?.name :
                <span style={{color: "gray"}}>Не задан</span>
        },
        {
            title: "Клиент", key: 'contact',
            render: (unknown, row) => row.contact?.legalPerson ? row.contact?.legalPerson?.name  : row.contact?.person ? row.contact?.person?.fullName :
                <span style={{color: "gray"}}>Не задан</span>
        },
        {
            title: "Бюджет", key: 'budget',
            render: (unknown, row) => row.budget ? row.budget :
                <span style={{color: "gray"}}>Не задан</span>
        },
        {
            title: "Стадия", key: 'stage',
            render: (unknown, row) => row.stage ? row.stage.name :
                <span style={{color: "gray"}}>Не задан</span>
        },
        {
            title: "Дата регистрации", dataIndex: 'createdAt', key: 'createdAt',
            render: (unknown, row) => row.createdAt ? formatDate(row.createdAt) :
                <span style={{color: "gray"}}>Не задана</span>
        },
    ];

    const searchFields = [
        {
            title: "Название сделки",
            key: "name",
            compareType: "like",
            operandPosition: 1,
            filterType: "normal"
        }
    ]

    const filterFields = [
        {
            title: "Статус сделки",
            key: "stageId",
            compareType: "equals",
            reducerKey: "dealState",
            reducerArrayKey: "dealStates",
            reducerAction: getDealStates,
            operandPosition: 1,
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        }
    ]

    async function deleteHandler(id) {
        return confirmDialog('Удаление элемента', 'Вы действительно хотите удалить этот элемент?', async () => {
            try {
                await dealCRUD.delete(id)
                await props.reload();
            } catch (e) {
                console.log(e);
            }
        })
    }


    const history = useHistory()

    const clickDetails = (id) => {
        history.push({pathname: DEAL_ROUTE + `/${id}`})
    }

    const openAdd = () => {
        history.push({pathname: DEAL_ADD_ROUTE})
    }

    const CRUDTables = {
        standart: CRUDTableStandart,
        query: CRUDTableQuery,
    }

    const CRUDTable = CRUDTables[type]

    return (
        <>
            <CRUDTable search={dealCRUD.search}
                            columns={columns}
                            onClickDetailsButton={clickDetails}
                            onClickCreateButton={openAdd}
                            onClickDeleteButton={deleteHandler}
                            reloadValue={props.reloadValue}
                            searchFields={searchFields}
                            filterFields={filterFields}
                            {...props}
            />
        </>
    );
}
