import React, {useEffect, useState} from 'react';
import DashboardLayout from "../../layouts/Dashboard";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {getTypeService} from "../../redux/actions/service/typeServiceActions";
import {
    PopupTypeService,
    TableCustom
} from "../../components";

const TypeService = () => {
    const {t} = useTranslation();
    const rowName = [
        {text: t("typeService.tableItem.id")},
        {text: t("typeService.tableItem.name")},
        {text: t("typeService.tableItem.code")},
        {text: t("typeService.tableItem.description")},
    ]
    const [open, setOpen] = useState(false);
    const [edit, setEdit] = useState(false);
    const [id, setId] = useState(null);
    const dispatch = useDispatch()
    const {typeService} = useSelector((state) => state.typeService);
    const setEditHandler = async (idEdit) => {
        setOpen(true)
        setEdit(true)
        setId(idEdit)
    }
    useEffect(() => {
        dispatch(getTypeService())
    }, [dispatch])
    return (
        <DashboardLayout>
            <TableCustom name="Тип услуги" avatars={false} rowName={rowName} rows={typeService.map(el => {
                return {
                    id: el.id,
                    name: el.name,
                    code: el.code,
                    description: el.description,
                }
            })} setEdit={setEditHandler}
            />
            <PopupTypeService id={id} edit={edit} setEdit={setEdit} setOpen={setOpen} open={open} title="Типы заявок"/>
        </DashboardLayout>
    );
};

export default TypeService;