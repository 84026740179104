import React, {useMemo, useState} from 'react';
import {Box, IconButton, TableCell, TableRow} from "@mui/material";
import {confirmDialog} from "../Popups/message/PopupDelete";
import {SelectShared} from "../index";
import NumberShared from "../Shared/NumberShared";
import {addProductToBasket, deleteItemBasket} from "../../redux/actions/basket/basketActions";
import {showSuccessSnackbar} from "../../redux/actions/userActions";

function GeneralTableRow({basket, row, actions, handleIcons, service, setService, servicesSchedule, dispatch}) {
    const [localServicesScheduleRow, setLocalServicesScheduleRow] = useState(row.scheduleId)
    const [qty, setQty] = useState(row.qty)
    const [summary, setSummary] = useState(row.price * (row.qty || 1))

    const handleRow = (event, array, valueName, valueId, setValue) => {
        const idx = array.findIndex(el => el.id === event)
        const dataIdx = service.findIndex(el => el.id === row.id)
        let data = [...service]
        data[dataIdx] = {
            ...data[dataIdx], [valueName]: array[idx].name,
            [valueId]: event
        }
        setService(data)
        setValue(event)
    }
    const handleRowVariant2 = (event, valueName, setValue, valueName2, setValue2) => {
        const dataIdx = service.findIndex(el => el.id === row.id)
        let data = [...service]
        data[dataIdx] = {...data[dataIdx], [valueName]: event, [valueName2]: event * row.price}
        setService(data)
        setValue && setValue(event)
        setValue2 && setValue2(event * row.price)
    }

    const handleServicesScheduleRow = (event) => {
        handleRow(event, servicesSchedule, 'schedule', 'scheduleId', setLocalServicesScheduleRow)
    }

    const handleQtyRow = (event) => {
        handleRowVariant2(+event, 'qty', setQty, 'summary', setSummary)
    }

    return useMemo(() => {
        return (
            <TableRow>
                <TableCell align="center">
                    {row.id}
                </TableCell>
                <TableCell align="center">
                    {row.name}
                </TableCell>
                <TableCell align="center">
                    {row.price} ₸
                </TableCell>
                <TableCell align="center">
                    <NumberShared sx={{maxWidth: '60px'}} value={qty} setValue={handleQtyRow} min={1} label="Кол."/>
                </TableCell>

                <TableCell align="center">
                    <SelectShared sx={{maxWidth: '200px'}} array={servicesSchedule}
                                  value={localServicesScheduleRow}
                                  setValue={handleServicesScheduleRow} label="График"/>
                </TableCell>
                <TableCell align="center">
                    {summary} ₸
                </TableCell>
                <TableCell align="center">
                    <Box>
                        {actions.map((el, idx) => (
                            <IconButton data-testid={el.id}
                                        children={el.icon}
                                        onClick={(e) => {
                                            if (el.id === 'DeleteForeverIcon') {
                                                confirmDialog('Удаление элемента', 'Вы действительно хотите удалить этот элемент?', () => {
                                                    return handleIcons(el.id, row.id)
                                                })
                                            } else if (el.id === 'AddShoppingCartIcon') {
                                                dispatch(addProductToBasket(row))
                                                handleRowVariant2(true, 'inBasket')
                                                dispatch(showSuccessSnackbar("Добавлено в корзину!", "success"))
                                            } else if (el.id === 'RemoveShoppingCartIcon') {
                                                confirmDialog('Удаление элемента', 'Вы действительно хотите удалить этот элемент из корзины?', () => {
                                                    return dispatch(deleteItemBasket(row.id))
                                                })
                                            } else
                                                return handleIcons(el.id, row.id)
                                        }}
                                        key={idx}>
                            </IconButton>
                        ))}
                    </Box>
                </TableCell>
            </TableRow>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actions, dispatch, handleIcons, handleServicesScheduleRow, localServicesScheduleRow, qty, row, servicesSchedule, summary])
}

export default GeneralTableRow;