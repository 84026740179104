import React, {useState} from 'react';
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    Box,
    TablePagination
} from "@mui/material";
import {TableRowCustom} from "../index";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";

const TableCustom = ({
                         value,
                         rowName,
                         childTable,
                         typeRow,
                         locale,
                         rows,
                         rowsPage,
                         actions,
                         avatars,
                         setEdit,
                         page,
                         countRow,
                         setPage,
                         name,
                         rowsPerPage,
                         header,
                         setRowsPerPage
                     }) => {
    const {pathname} = useLocation()
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [message, setTypeMessage] = useState('success')
    const handleClick = (type) => {
        if (type === 'error') {
            setTypeMessage('error')
        } else if (type === 'success') {
            setTypeMessage('success')
        }
        setOpenSnackBar(true);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <>
                <Table sx={{width: '50%', backgroundColor: '#fff'}} size="big" aria-label="a dense table">
                    <TableHead sx={{backgroundColor: '#fafafa'}}>
                        {header}
                    </TableHead>
                    {rows ? rows.map((row, idx) => {
                        return (
                            <TableRowCustom value={value}
                                            rowName={rowName}
                                            childTable={
                                                // eslint-disable-next-line array-callback-return
                                                childTable && childTable.filter(el => el.parentId === row.id).map(el => {
                                                    if (pathname === '/service') {
                                                        return {
                                                            id: el.id,
                                                            name: el.name,
                                                            type: el.type?.name,
                                                            unit: el.unit?.name,
                                                        }
                                                    }
                                                    if (pathname === '/geo') {
                                                        return {
                                                            id: el.id,
                                                            name: el.name
                                                        }
                                                    }
                                                })} handleClick={handleClick} typeRow={typeRow && typeRow[idx]}
                                            locale={locale}
                                            rowsPage={rowsPage && rowsPage.find(el => el.id ? el.id === row.id : el.number === row.number)}
                                            key={idx}
                                            setEdit={setEdit} row={row} avatars={avatars}
                                            actions={actions}/>)
                    }) : <TableRow><TableCell><Box>Записей пока нет</Box></TableCell></TableRow>}
                </Table>
                {rows && rowsPerPage && countRow > 9 &&
                <TablePagination showFirstButton showLastButton rowsPerPageOptions={[10, 25, 100]}
                                 component="div"
                                 labelRowsPerPage="Отображаемых элементов"
                                 count={countRow}
                                 page={page}
                                 onPageChange={handleChangePage}
                                 onRowsPerPageChange={handleChangeRowsPerPage}
                                 rowsPerPage={rowsPerPage}/>}
        </>
    );
};

export default TableCustom;