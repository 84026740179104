import {
    COMPLETION_ACT_ADD_ERROR,
    COMPLETION_ACT_ADD_REQUEST,
    COMPLETION_ACT_ADD_SUCCESS,
    COMPLETION_ACT_GET_ERROR,
    COMPLETION_ACT_GET_REQUEST,
    COMPLETION_ACT_GET_SUCCESS,
    COMPLETION_ACT_ONE_GET_ERROR,
    COMPLETION_ACT_ONE_GET_REQUEST,
    COMPLETION_ACT_ONE_GET_SUCCESS,
    INVOICE_ADD_ERROR,
    INVOICE_ADD_REQUEST,
    INVOICE_ADD_SUCCESS,
    INVOICE_GET_ERROR,
    INVOICE_GET_REQUEST,
    INVOICE_GET_SUCCESS,
    INVOICE_ONE_GET_ERROR,
    INVOICE_ONE_GET_REQUEST,
    INVOICE_ONE_GET_SUCCESS,
    PAYMENT_ADD_ERROR,
    PAYMENT_ADD_REQUEST,
    PAYMENT_ADD_SUCCESS,
    PAYMENT_GET_ERROR, PAYMENT_GET_REQUEST,
    PAYMENT_GET_SUCCESS,
    PAYMENT_ONE_GET_ERROR,
    PAYMENT_ONE_GET_REQUEST,
    PAYMENT_ONE_GET_SUCCESS
} from "../../constants/bookkeeping/bookkeepingConstants";

const initialState = {
    completionActs: [],
    completionActOne: {},
    completionActsCountTable: null,
    payments: [],
    paymentOne: {},
    paymentsCountTable: null,
    invoices: [],
    invoiceOne: {},
    invoicesCountTable: null,
    loading: false,
    success: null,
    error: null
};

export const bookkeepingReducer = (state = initialState, action) => {
    switch (action.type) {
        case PAYMENT_GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PAYMENT_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                payments: action.payload.result,
                paymentsCountTable: action.payload.total,
                success: true
            };
        case PAYMENT_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case PAYMENT_ONE_GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PAYMENT_ONE_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                paymentOne: action.payload,
                success: true
            };
        case PAYMENT_ONE_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case PAYMENT_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PAYMENT_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true
            };
        case PAYMENT_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case COMPLETION_ACT_GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case COMPLETION_ACT_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                completionActs: action.payload.result,
                completionActsCountTable: action.payload.total,
                success: true
            };
        case COMPLETION_ACT_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case COMPLETION_ACT_ONE_GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case COMPLETION_ACT_ONE_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                completionActOne: action.payload,
                success: true
            };
        case COMPLETION_ACT_ONE_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case COMPLETION_ACT_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case COMPLETION_ACT_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true
            };
        case COMPLETION_ACT_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case INVOICE_GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case INVOICE_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                invoices: action.payload.result,
                invoicesCountTable: action.payload.total,
                success: true
            };
        case INVOICE_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case INVOICE_ONE_GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case INVOICE_ONE_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                invoiceOne: action.payload,
                success: true
            };
        case INVOICE_ONE_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case INVOICE_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case INVOICE_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true
            };
        case INVOICE_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        default:
            return state;
    }
}