import {
    GET_REQUEST_STATE_REQUEST,
    GET_REQUEST_STATE_SUCCESS,
    GET_REQUEST_STATE_ERROR,
    GET_REQUEST_REQUEST,
    GET_REQUEST_SUCCESS,
    GET_REQUEST_ERROR,
    GET_REQUEST_FILTER_REQUEST,
    GET_REQUEST_FILTER_SUCCESS,
    GET_REQUEST_FILTER_ERROR,
    ADD_REQUEST_REQUEST,
    ADD_REQUEST_ERROR,
    ADD_REQUEST_SUCCESS,
    REQUEST_SECTION_GET_REQUEST, REQUEST_SECTION_GET_SUCCESS, REQUEST_SECTION_GET_ERROR
} from "../../constants/request/requestConstants";

const initialState = {
    requestState: [],
    requestSection: [],
    requests: [],
    requestsFilter: [],
    loading: false,
    success: null,
    error: null
};

export const requestReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_REQUEST_STATE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_REQUEST_STATE_SUCCESS:
            return {
                ...state,
                loading: false,
                requestState: action.payload,
                success: true
            };
        case GET_REQUEST_STATE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case REQUEST_SECTION_GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case REQUEST_SECTION_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                requestSection: action.payload.result,
                success: true
            };
        case REQUEST_SECTION_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case GET_REQUEST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                requests: action.payload,
                success: true
            };
        case ADD_REQUEST_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case ADD_REQUEST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ADD_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true
            };
        case GET_REQUEST_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case GET_REQUEST_FILTER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_REQUEST_FILTER_SUCCESS:
            return {
                ...state,
                loading: false,
                requestsFilter: action.payload,
                success: true
            };
        case GET_REQUEST_FILTER_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        default:
            return state;
    }
}