import React from 'react';
import {Box, Grid} from "@mui/material";

function TableTabLegalClient({client}) {
    return (
        <Grid container>
            <Grid item xs={6}>
                <Box sx={{
                    padding: '5px 0',
                    borderTop: "1 px",
                    borderBottom: '1px'
                }}>Адрес</Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{
                    padding: '5px 0',
                    borderTop: "1 px",
                    borderBottom: '1px'
                }}>{client.addressLine || 'Не указано'}</Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{
                    padding: '5px 0',
                    borderTop: "1 px",
                    borderBottom: '1px'
                }}>БИН</Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{
                    padding: '5px 0',
                    borderTop: "1 px",
                    borderBottom: '1px'
                }}>{client.bin || 'Не указано'}</Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{
                    padding: '5px 0',
                    borderTop: "1 px",
                    borderBottom: '1px'
                }}>Город</Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{
                    padding: '5px 0',
                    borderTop: "1 px",
                    borderBottom: '1px'
                }}>{client.cityLine || 'Не указано'}</Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{
                    padding: '5px 0',
                    borderTop: "1 px",
                    borderBottom: '1px'
                }}>Email</Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{
                    padding: '5px 0',
                    borderTop: "1 px",
                    borderBottom: '1px'
                }}>{client.email || 'Не указано'}</Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{
                    padding: '5px 0',
                    borderTop: "1 px",
                    borderBottom: '1px'
                }}>Название</Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{
                    padding: '5px 0',
                    borderTop: "1 px",
                    borderBottom: '1px'
                }}>{client.name || 'Не указано'}</Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{
                    padding: '5px 0',
                    borderTop: "1 px",
                    borderBottom: '1px'
                }}>Номер телефона</Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{
                    padding: '5px 0',
                    borderTop: "1 px",
                    borderBottom: '1px'
                }}>{client.phoneNumber || 'Не указано'}</Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{
                    padding: '5px 0',
                    borderTop: "1 px",
                    borderBottom: '1px'
                    }}>Руководитель</Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{
                    padding: '5px 0',
                    borderTop: "1 px",
                    borderBottom: '1px'
                }}>{client.supervisorName || 'Не указано'}</Box>
            </Grid>
        </Grid>
    );
}

export default TableTabLegalClient;