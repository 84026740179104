import {
    AN_OBJECT_GET_REQUEST,
    AN_OBJECT_GET_SUCCESS,
    AN_OBJECT_GET_ERROR,
    AN_OBJECT_KIND_GET_REQUEST,
    AN_OBJECT_KIND_GET_SUCCESS,
    AN_OBJECT_KIND_GET_ERROR,
    TRANSPORT_TYPE_GET_REQUEST, TRANSPORT_TYPE_GET_SUCCESS, TRANSPORT_TYPE_GET_ERROR
} from "../../constants/anObject/anObjectConstants";

const initialState = {
    anObjects: [],
    anObjectKind: [],
    transportType: [],
    anObjectsCount: null,
    loading: false,
    success: null,
    error: null
};

export const anObjectReducer = (state = initialState, action) => {
    switch (action.type) {
        case TRANSPORT_TYPE_GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case TRANSPORT_TYPE_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                transportType: action.payload.result,
            };
        case TRANSPORT_TYPE_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case AN_OBJECT_GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AN_OBJECT_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                anObjects: action.payload.result,
                anObjectsCount: action.payload.pageCount
            };
        case AN_OBJECT_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case AN_OBJECT_KIND_GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AN_OBJECT_KIND_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                anObjectKind: action.payload.result,
            };
        case AN_OBJECT_KIND_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        default:
            return state;
    }
}