import XLSX from "xlsx-js-style";
import {formatDate, MOMENT_FORMAT_DATE_DMY_DOTS} from "./formatters";
import QRCode from 'qrcode'

function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

export function stringAvatar(name) {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

export function intersection(a, b, compare) {
    if (!a || !b) return [];
    if (a.length == 0 || b.length == 0) return [];

    const result = [];

    for (let i = 0; i < a.length; i++) {
        let aItem = a[i];
        if (b.filter(_ => compare(aItem, _)).length > 0)
            result.push(aItem);
    }

    return result;
}

export function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function removeEmpty(obj) {
    Object.keys(obj).forEach(function(key) {
        (obj[key] && typeof obj[key] === 'object') && removeEmpty(obj[key]) ||
        (obj[key] === '' || obj[key] === null) && delete obj[key]
    });
    return obj;
};

export async function convertContractToExcel(res, filter, isAdditional) {
    const arrayToExcel = res.map(el => {
        return {
            filial: el?.filialName || "Не задано",
            filialDivision: el?.filialDivisionName || "Не задано",
            name: el?.name || "Не задано",
            client: el?.clientName || "Не задано",
            type: el?.typeName || "Не задано",
            summary: el?.summary || "Не задано",
            createdBy: el?.createdByName || "Не задано",
        }
    })
    const thinBorder = {style: 'thin', color: 'auto'};
    const thinBorders = {top: thinBorder, bottom: thinBorder, left: thinBorder, right: thinBorder};

    const centerBold10_2 = {
        alignment: {horizontal: 'center', vertical: 'center', wrapText: true},
        border: thinBorders,
        font: {sz: 12, bold: true}
    };

    const header = {
        alignment: {horizontal: 'center', vertical: 'center', wrapText: true},
        border: thinBorders,
        font: {sz: 12, bold: true},
        fill: {
            patternType: "solid",
            fgColor: { rgb: "9BC2E6" },
            bgColor: { rgb: "9BC2E6" }
        }
    };



    const wb = XLSX.utils.book_new();

    let name;

    if (isAdditional) {
        name = `Доп. соглашения. Отчетный период c ${formatDate(filter?.actionAt?.operand1, MOMENT_FORMAT_DATE_DMY_DOTS)} по ${formatDate(filter?.actionAt?.operand2, MOMENT_FORMAT_DATE_DMY_DOTS)}`
    } else {
        name = `Договоры. Отчетный период c ${formatDate(filter?.actionAt?.operand1, MOMENT_FORMAT_DATE_DMY_DOTS)} по ${formatDate(filter?.actionAt?.operand2, MOMENT_FORMAT_DATE_DMY_DOTS)}`
    }

    const merges = [];
    const aoa = [
        [
            {t: "s", v: "Филиал", s: header},
            {t: "s", v: "Районное отделение", s: header},
            {t: "s", v: "Наименование договора", s: header},
            {
                t: "s", v: "Клиент (юл/фи)", s: header
            },
            {
                t: "s", v: "Тип договора (создание, госзакупки, тендер)", s: header
            },
            {t: "s", v: "Сумма", s: header},
            {t: "s", v: "Автор", s: header},
        ],
    ];
    arrayToExcel.map(element => {
        aoa.push([
            {t: "s", v: element.filial, s: centerBold10_2},
            {t: "s", v: element.filialDivision, s: centerBold10_2},
            {
                t: "s",
                v: element.name,
                s: centerBold10_2
            },
            {t: "s", v: element.client, s: centerBold10_2},
            {t: "s", v: element.type, s: centerBold10_2},
            {t: "s", v: element.summary, s: centerBold10_2},
            {t: "s", v: element.createdBy, s: centerBold10_2},
        ])
    })
    const sheet = XLSX.utils.aoa_to_sheet(aoa);
    sheet['!cols'] = [{width: 40}, {width: 40}, {width: 40}, {width: 40}, {width: 40}, {width: 40}, {width: 40}];
    sheet['!rows'] = [{hpx: 40, hpt: 10}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
    sheet['!merges'] = merges;
    XLSX.utils.book_append_sheet(wb, sheet, 'Отчет');
    const output = await XLSX.write(wb, {type: 'array'});
    let blob = await new Blob([output], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
    let objectUrl = URL.createObjectURL(blob);
    const link = document.createElement('a')
    link.href = objectUrl
    link.download = `${name}.xlsx`
    document.body.appendChild(link)
    link.click()
    link.remove()
}

export async function convertClientToExcel(res, type = 'name') {
    const arrayToExcel = res.map(el => {
        return {
            name: el[type] || "Не задано",
            phoneNumber: el?.phoneNumber || "Не задано",
            email: el?.email || "Не задано",
        }
    })
    const thinBorder = {style: 'thin', color: 'auto'};
    const thinBorders = {top: thinBorder, bottom: thinBorder, left: thinBorder, right: thinBorder};

    const centerBold10_2 = {
        alignment: {horizontal: 'center', vertical: 'center', wrapText: true},
        border: thinBorders,
        font: {sz: 12, bold: true}
    };

    const header = {
        alignment: {horizontal: 'center', vertical: 'center', wrapText: true},
        border: thinBorders,
        font: {sz: 12, bold: true},
        fill: {
            patternType: "solid",
            fgColor: { rgb: "9BC2E6" },
            bgColor: { rgb: "9BC2E6" }
        }
    };



    const wb = XLSX.utils.book_new();

    let name = 'Клиенты';

    const merges = [];
    const aoa = [
        [
            {t: "s", v: "Клиент", s: header},
            {t: "s", v: "Номер телефона", s: header},
            {t: "s", v: "Email", s: header}
        ],
    ];
    arrayToExcel.map(element => {
        aoa.push([
            {t: "s", v: element.name, s: centerBold10_2},
            {t: "s", v: element.phoneNumber, s: centerBold10_2},
            {
                t: "s",
                v: element.email,
                s: centerBold10_2
            }
            ])
    })
    const sheet = XLSX.utils.aoa_to_sheet(aoa);
    sheet['!cols'] = [{width: 40}, {width: 40}, {width: 40}];
    sheet['!rows'] = [{hpx: 40, hpt: 10}, {}, {}];
    sheet['!merges'] = merges;
    XLSX.utils.book_append_sheet(wb, sheet, 'Отчет');
    const output = await XLSX.write(wb, {type: 'array'});
    let blob = await new Blob([output], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
    let objectUrl = URL.createObjectURL(blob);
    const link = document.createElement('a')
    link.href = objectUrl
    link.download = `${name}.xlsx`
    document.body.appendChild(link)
    link.click()
    link.remove()
}

export const generateQR = async text => {
    try {
        await QRCode.toDataURL(text)
    } catch (err) {
        console.error(err)
    }
}

export const referralSources = [
    {id: 1, name: 'Реклама'},
    {id: 2, name: 'Рекомендации третьих лиц'},
    {id: 3, name: 'Активный поиск специалистом НЦЭ'},
    {id: 4, name: 'Самостоятельное обращение'},
]

export function isLocalNetwork(hostname = window.location.hostname) {
    return (
        (['localhost', '127.0.0.1', '', '::1'].includes(hostname))
        || (hostname.startsWith('192.168.'))
        || (hostname.startsWith('10.0.'))
        || (hostname.endsWith('.local'))
    )
}

export const generateLink = (id) => {
    let link;
    if (isLocalNetwork()) {
        link = `${window.location.host}/contract-view-ecp/${id}`
    } else {
        link = `${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL : process.env.REACT_APP_PROD_API_URL}/contract-view/${id}`
    }
    return {url: link}
}
