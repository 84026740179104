import React, {createContext, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getServiceCategory, getServicePackage} from "../../redux/actions/serviceCategory/serviceCategoryActions";
import DashboardLayout from "../../layouts/Dashboard";
import BreadcrumbsShared from "../../components/Shared/BreadcrumbsShared";
import {GENERAL_ROUTE} from "../../routes/const";
import ClientLayout from "../../layouts/ClientLayout";
import Box from "@mui/material/Box";
import GeneralDrawer from "../../components/General/GeneralDrawer";
import {useLocation, useParams} from "react-router-dom";
import {Paper} from "@mui/material";
import GeneralTable from "../../components/General/GeneralTable";
import {LOADING_FALSE, LOADING_TRUE} from "../../redux/constants/genericConstants";
import {getService, getServicePackageElement, getServicesSchedule} from "../../redux/actions/service/serviceActions";
import Button from "@mui/material/Button";
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import {addProductToBasket} from "../../redux/actions/basket/basketActions";
import {showSuccessSnackbar} from "../../redux/actions/userActions";

export const GeneralService = createContext();

function GeneralServices(props) {
    const userLogin = useSelector((state) => state.user);
    const [service, setService] = useState([])
    const [packageParent, setPackageParent] = useState([])
    const dispatch = useDispatch();
    const {isClient} = userLogin;
    const {id} = useParams()
    const {search} = useLocation()
    const {serviceCategory} = useSelector((state) => state.serviceCategory);
    const {servicesSchedule} = useSelector((state) => state.service);
    const [services, setServices] = useState([])
    useEffect(() => {
        (async function () {
            dispatch({type: LOADING_TRUE})
            await dispatch(getServiceCategory(1, 100))
            if (search) {
                setServices(await dispatch(getServicePackageElement(1, 1000, {
                    filter: {
                        packageId: {
                            operand1: +id,
                            operator: "equals"
                        },
                    }
                }, true)))

            } else {
                setServices(await dispatch(getService(1, 1000, {
                    filter: {
                        categoryId: {
                            operand1: +id,
                            operator: "equals"
                        },
                    }
                }, true)))
            }
            await dispatch(getServicesSchedule(1, 100))
            dispatch({type: LOADING_FALSE})
        }())
    }, [dispatch, id, search])

    useEffect(() => {
        if (services?.result?.length) {
            if (!search) {
                setService(services.result?.map(el => ({
                    id: el.id,
                    name: el.name,
                    qty: 1,
                    price: el.price,
                    summary: el.price,
                    schedule: servicesSchedule[0]?.name,
                    scheduleId: servicesSchedule[0]?.id,
                    inBasket: false,
                })))
            } else {
                setService(services.result?.map(el => ({
                    id: el.service?.id,
                    name: el.service?.name,
                    qty: 1,
                    price: el.service?.price,
                    summary: el.service?.price,
                    schedule: servicesSchedule[0]?.name,
                    scheduleId: servicesSchedule[0]?.id,
                    inBasket: false,
                })))
            }
        }
    }, [search, services, servicesSchedule])

    const addAllInBasket = () => {
        if (services?.result?.length) {
            services?.result?.forEach(el => {
                let row
                if (!search) {
                    row = {
                        id: el.id,
                        name: el.name,
                        qty: 1,
                        price: el.price,
                        summary: el.price,
                        schedule: servicesSchedule[0]?.name,
                        scheduleId: servicesSchedule[0]?.id
                    }
                } else {
                    row = {
                        id: el.service?.id,
                        name: el.service?.name,
                        qty: 1,
                        price: el.service?.price,
                        summary: el.service?.price,
                        schedule: servicesSchedule[0]?.name,
                        scheduleId: servicesSchedule[0]?.id,
                    }
                }
                dispatch(addProductToBasket(row))
            })
            dispatch(showSuccessSnackbar("Пакет добавлен в корзину!", "success"))
        }
    }

    useEffect(() => {
        (async function () {
            const {result} = await dispatch(getServicePackage(1, 100, {
                filter: {
                    parentId: {
                        operand1: null,
                        operator: "equals"
                    }
                }
            }, true))
            setPackageParent(result)
        }())
    }, [dispatch])

    const RenderDrawer = useMemo(() => {
        return (
            <GeneralDrawer packageParent={packageParent} id={id} category={serviceCategory}/>
        )
    }, [id, packageParent, serviceCategory]);


    return (
        <>
            {!isClient && <DashboardLayout>
                <BreadcrumbsShared breadcrumbs={[
                    {text: 'Главная', link: GENERAL_ROUTE},
                    {text: 'Услуги', link: GENERAL_ROUTE + `/${id}`},
                ]}/>
                <Box sx={{display: 'flex'}}>
                    {RenderDrawer}
                    <Box sx={{flex: '1', marginLeft: '30px', display: 'flex', flexDirection: 'column'}}>
                        <Paper sx={{height: 700, overflowY: 'scroll'}}>
                            {service &&
                            <GeneralTable dispatch={dispatch} servicesSchedule={servicesSchedule}
                                          setService={setService} service={service} servicePackage={service}/>
                            }
                        </Paper>
                        <Button
                            variant="outlined"
                            onClick={addAllInBasket}
                            startIcon={<ShoppingBasketIcon/>}
                            sx={{mt: 3, alignSelf: 'flex-start'}}
                        >
                            Добавить всё
                        </Button>
                    </Box>
                </Box>
            </DashboardLayout>}
            {isClient && <ClientLayout>
                <BreadcrumbsShared breadcrumbs={[
                    {text: 'Главная', link: GENERAL_ROUTE},
                    {text: 'Услуги', link: GENERAL_ROUTE + `/${id}`},
                ]}/>
                <Box sx={{display: 'flex'}}>
                    {RenderDrawer}
                    <Box sx={{flex: '1', marginLeft: '30px', display: 'flex', flexDirection: 'column'}}>
                        <Paper sx={{height: 700, overflowY: 'scroll'}}>
                            {service &&
                            <GeneralTable dispatch={dispatch} servicesSchedule={servicesSchedule}
                                          setService={setService} service={service} servicePackage={service}/>
                            }
                        </Paper>
                        <Button
                            variant="outlined"
                            startIcon={<ShoppingBasketIcon/>}
                            sx={{mt: 3, alignSelf: 'flex-start'}}
                        >
                            Добавить всё
                        </Button>
                    </Box>
                </Box>
            </ClientLayout>}
        </>
    );
}

export default GeneralServices;