export const TRANSLATIONS_EN = {
    dashboard: {
        listItem: {
            applications: "applications",
            client: "client",
            bills: "bills",
            reports: "reports",
            exit: "Выход"
        }
    }
}