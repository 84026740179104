import React from 'react';
import DashboardLayout from "../../layouts/Dashboard";
import {
    CONTRACT_REQUESTS_ADD_ROUTE,
    CONTRACT_REQUESTS_ROUTE,
} from "../../routes/const";
import {ContractRequestCRUDTable} from "../../components/Crud/Tables/ContractRequestCRUDTable";
import {checkRoles} from "../../functions/checkRoles";
import BreadcrumbsShared from "../../components/Shared/BreadcrumbsShared";
import {contractRequestCRUD} from "../../http/CRUD";
import {confirmDialog} from "../../components/Popups/message/PopupDelete";
import useReload from "../../hooks/useReload";
import {useHistory} from "react-router-dom";

const ContractRequest = (props) => {
    const { reload, reloadValue } = useReload();

    const handleDelete = (_) => {
        return confirmDialog('Удаление сводной заявки, ID:' + _, 'Вы действительно хотите удалить эту сводную заявку?', async () => {
            await contractRequestCRUD.delete(_);
            await reload();
        })
    }

    const {push} = useHistory()

    return (
        <DashboardLayout>
            <BreadcrumbsShared breadcrumbs={[
                {text: 'Сводные заявки', link: CONTRACT_REQUESTS_ROUTE}
            ]}/>
            <ContractRequestCRUDTable onClickCreateButton={() => push(CONTRACT_REQUESTS_ADD_ROUTE)} reloadValue={reloadValue} onClickDeleteButton={checkRoles('SuperAdministrator') ? handleDelete : null} modalFilter/>
        </DashboardLayout>
    );
}

export default ContractRequest;
