import {$authHost} from "../../../http";
import {CLIENT_GET_ERROR, CLIENT_GET_REQUEST, CLIENT_GET_SUCCESS} from "../../constants/client/clientConstants";
import {getData} from "../template/get";

export const addClient = async (personId, legalPersonId, userId) => {
    try {
        await $authHost.post(
            "/api/v1/client",
            {personId, legalPersonId, userId}
        );
    } catch (error) {
        console.log(error)
    }
};

export const getClients = (page, size, args, nonGlobal) => async (dispatch) => {
    return await dispatch(getData(CLIENT_GET_REQUEST, CLIENT_GET_SUCCESS, CLIENT_GET_ERROR, 'client', false, page, null, null, true, size, args, nonGlobal))
};
