export const EMPLOYEE_GET_REQUEST = 'EMPLOYEE_GET_REQUEST'
export const EMPLOYEE_GET_SUCCESS = 'EMPLOYEE_GET_SUCCESS'
export const EMPLOYEE_GET_ERROR = 'EMPLOYEE_GET_ERROR'


export const EMPLOYEE_GET_AUTHORIZED_REQUEST =
    "EMPLOYEE_GET_AUTHORIZED_REQUEST";
export const EMPLOYEE_GET_AUTHORIZED_SUCCESS =
    "EMPLOYEE_GET_AUTHORIZED_SUCCESS";
export const EMPLOYEE_GET_AUTHORIZED_ERROR = "EMPLOYEE_GET_AUTHORIZED_ERROR";
