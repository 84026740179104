import React, {useContext, useEffect, useState} from 'react';
import {SelectFieldModal, SelectShared} from "../../../components";
import {Box, IconButton, TableCell, TableRow} from "@mui/material";
import {confirmDialog} from "../../../components/Popups/message/PopupDelete";
import TextShared from "../../../components/Shared/TextShared";
import {useDispatch} from "react-redux";
import {getGeoObject} from "../../../redux/actions/geo/geoActions";
import {getAnObject} from "../../../redux/actions/anObject/anObjectActions";
import {t} from "i18next";
import {ContractContext} from "../../Contract/ContractPage";
import {useLocation} from "react-router-dom";
import {ContractRequestContext} from "../ContractRequestEdit";
import PersonField from "../../../components/Fields/PersonField";
import AnObjectField from "../../../components/Fields/AnObjectField";

const ContractRequestObjectRow = ({
                                      edit,
                                      row,
                                      actions,
                                      geo,
                                      contractRequestObject,
                                      setContractRequestObject,
                                      handleIcons,
                                      mode,
                                     disabled
                                  }) => {
    const [anObjectsLocal, setAnObjectsLocal] = useState('')

    const rowId = row.Id ? row.Id : row.id

    const handleRowVariant3 = (client) => {
        const dataIdx = contractRequestObject.findIndex(el => el.Id === rowId)
        let data = [...contractRequestObject]
        data[dataIdx] = {...data[dataIdx], 'object': client, 'objectId': client.id, 'addressLine': client?.addressLine}
        setContractRequestObject(data)
        setAnObjectsLocal(client.id)
        setAddressLine(client?.addressLine)
    }

    const handleRowVariant2 = (event, valueName, setValue) => {
        const dataIdx = contractRequestObject.findIndex(el => el.Id === rowId)
        let data = [...contractRequestObject]
        data[dataIdx] = {...data[dataIdx], [valueName]: event}
        setContractRequestObject(data)
        setValue(event)
    }

    const handleRow = (event, array, valueName, valueId, setValue) => {
        const idx = array.findIndex(el => el.id === event)
        const dataIdx = contractRequestObject.findIndex(el => el.Id === rowId)
        let data = [...contractRequestObject]
        data[dataIdx] = {...data[dataIdx], [valueName]: array[idx]?.name,
            [valueId]: event}
        setContractRequestObject(data)
        setValue(event)
    }
    const dispatch = useDispatch()


    const [nameContractRequestObject, setNameContractRequestObject] = useState('')
    const [localDistrict, setLocalDistrict] = useState('')
    const [city, setCity] = useState([])
    const [localCity, setLocalCity] = useState('')
    const [addressLine, setAddressLine] = useState('')
    const [settlements, setSettlements] = useState([])
    const [locality, setLocality] = useState(1)

    const handleContractRequestAddressLineRow = (event) => {
        handleRowVariant2(event, 'addressLine', setAddressLine)
    }

    const handleContractRequestObjectDistrictRow = (event) => {
        handleRow(event, geo,'district', 'districtId', setLocalDistrict)
    }

    const handleLocalCityRow = (event) => {
        handleRow(event, city,'city', 'cityId', setLocalCity)
    }

    const handleLocalLocalityRow = (event) => {
        handleRow(event, settlements,'locality', 'localityId', setLocality)
    }

    useEffect(() => {
        (async function () {
            if (localDistrict) {
                const res = await dispatch(getGeoObject(1, 10000, {
                    filter: {
                        parentId: {
                            operand1: localDistrict,
                            operator: "equals"
                        }
                    }
                }, true))
                setCity(res)
            }
        }())
    }, [dispatch, localDistrict])

    useEffect(() => {
        (async function (){
            if (localCity) {
                const geoLocality = await dispatch(getGeoObject(1, 10000, {filter: {
                        typeId: {
                            operand1: 4,
                            operator: "equals"
                        },
                        parentId: {
                            operand1: localCity,
                            operator: "equals"
                        },
                    }}, true))
                setSettlements(geoLocality)
            }
        }())
    }, [localCity, dispatch, setSettlements])

    useEffect(() => {
        setNameContractRequestObject(row?.name)
        setAddressLine(row?.addressLine)
        setLocalDistrict(row?.districtId)
        setLocalCity(row?.cityId)
        setLocality(row?.localityId)
        setAnObjectsLocal(row?.objectId)
    }, [edit, row])


    return (
        <TableRow sx={{minWidth: '80%'}}>
            {
                (mode === 'edit' || !mode) &&
                <>
                    <TableCell align="center">
                        <AnObjectField
                            fullWidth
                            object={row?.object}
                            value={anObjectsLocal}
                            disabled={disabled}
                            onChange={(client) => {
                                handleRowVariant3(client)
                            }}
                        />
                    </TableCell>
                    <TableCell align="center">
                        {geo && <SelectShared required={false} disabled={disabled} sx={{maxWidth: '200px'}} array={geo} value={localDistrict}
                                      setValue={handleContractRequestObjectDistrictRow} label="Область"/>}
                    </TableCell>
                    <TableCell align="center">
                        {city && <SelectShared required={false} disabled={disabled || !localDistrict} sx={{maxWidth: '200px'}} array={city} value={localCity}
                                      setValue={handleLocalCityRow} label="Город"/>}
                    </TableCell>
                    <TableCell align="center">
                        {settlements && <SelectShared required={false} disabled={disabled || !localCity} sx={{maxWidth: '200px'}} array={settlements}
                                      value={locality}
                                      setValue={handleLocalLocalityRow} label="Населенный пункт"/>}
                    </TableCell>
                    <TableCell align="center">
                        <TextShared required={false} disabled={disabled} value={addressLine} setValue={handleContractRequestAddressLineRow} label="Адрес"/>
                    </TableCell>
                    <TableCell align="center">
                        <Box>
                            {actions && actions.map((el, idx) => (
                                <IconButton data-testid={el.id}
                                            children={el.icon}
                                            onClick={(e) => {
                                                if (el.id === 'DeleteForeverIcon') {
                                                    confirmDialog('Удаление элемента', 'Вы действительно хотите удалить этот элемент?', () => {
                                                        return handleIcons(el.id, rowId)
                                                    })
                                                } else
                                                    return handleIcons(el.id, rowId)
                                            }}
                                            key={idx}>
                                </IconButton>
                            ))}
                        </Box>
                    </TableCell>
                </>
            }
            {
                mode === 'see' &&
                <>
                    <TableCell align="center">
                        {row?.object?.name}
                    </TableCell>
                    <TableCell align="center">
                        {geo && geo.find(el => el.id === localDistrict)?.name}
                    </TableCell>
                    <TableCell align="center">
                        {city && city.find(el => el.id === localCity)?.name}
                    </TableCell>
                    <TableCell align="center">
                        {settlements && settlements.find(el => el.id === locality)?.name}
                    </TableCell>
                    <TableCell align="center">
                        {addressLine}
                    </TableCell>
                </>
            }
        </TableRow>
    );
}

export default React.memo(ContractRequestObjectRow);