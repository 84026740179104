import React from 'react';
import {Box, CircularProgress} from "@mui/material";

function Loader() {
    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            zIndex: '999999',
            height: '100%',
            width: '100%',
            background: 'rgba(255,255,255,0.4)',
            left: 0,
            right: 0,
            top: 0
        }}>
            <CircularProgress size={200}/>
        </Box>
    );
}

export default Loader;