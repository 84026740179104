import React from 'react';
import {Box, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {useTranslation} from "react-i18next";
import ConfirmDialog from "../Popups/message/PopupDelete";
import {DeleteForeverIconCustom} from "../index";
import TableMinRow from "./TableMinRow";

const TableMin = ({
                      setSpecification,
                      setOpen,
                      serviceKind,
                      specification,
                      services,
                      setService,
                      service,
                      unitOfMeasure,
                      anObjects,
                      count,
                      anObjectsCount,
                      servicesScheduleLocal,
                      setServicesScheduleLocal,
                      setQty,
                      qty,
                    mode,
                      edit,
                      servicesCount,
                      anObjectsLocal,
                      handleDeleteSpec,
                      setAnObjectsLocal,
                      servicesSchedule,
                      servicesScheduleCount,
                      unityOfMeasure,
                      localServiceKind
                  }) => {
    const {t} = useTranslation();
    let rowName = [
        {text: t("specification.tableItem.service")},
        {text: t("specification.tableItem.unitOfMeasure")},
        {text: t("specification.tableItem.qty")},
        {text: t("specification.tableItem.price")},
        {text: t("specification.tableItem.anObject")},
        {text: t("specification.tableItem.servicesSchedule")},
        {text: 'Площадь'},
        {text: 'Кратность'},
        {text: t("specification.tableItem.actions")},
    ]

    if (count) {
        rowName = [
            {text: t("specification.tableItem.service")},
            {text: t("specification.tableItem.unitOfMeasure")},
            {text: t("specification.tableItem.qty")},
            {text: 'Количество по договору'},
            {text: t("specification.tableItem.price")},
            {text: t("specification.tableItem.anObject")},
            {text: t("specification.tableItem.servicesSchedule")},
            {text: 'Площадь'},
            {text: 'Кратность'},
            {text: t("specification.tableItem.actions")},
        ]
    }

    const actions = [{icon: <DeleteForeverIconCustom/>, id: 'DeleteForeverIcon'}]

    const handleIcons = (type, row) => {
        if (type === 'DeleteForeverIcon') {
            handleDeleteSpec(row)
        }
        if (type === 'EditIcon') {
            const newRow = specification.find(el => el.Id === row)
            setOpen(newRow)
        }
    }

    return (
        <Table sx={{minWidth: '80%', maxWidth: '100%',
            overflowX: "scroll"}}>
            <TableHead sx={{backgroundColor: '#fafafa'}}>
                <TableRow>
                    {(mode === 'edit' || !mode) ? rowName.map((el, idx) => {
                        return <TableCell key={idx}
                                          align="center">{el.text}</TableCell>
                    }) : rowName.slice(0, -1).map((el, idx) => {
                        return <TableCell key={idx}
                                          align="center">{el.text}</TableCell>
                    })}
                </TableRow>
            </TableHead>
            <TableBody>
                {specification?.length ? specification.map((row) => (
                        <TableMinRow count={count} mode={mode} servicesCount={servicesCount} edit={edit} setQty={setQty} qty={qty} anObjects={anObjects}
                                     anObjectsLocal={anObjectsLocal} setAnObjectsLocal={setAnObjectsLocal}
                                     anObjectsCount={anObjectsCount} servicesScheduleLocal={servicesScheduleLocal}
                                     servicesSchedule={servicesSchedule} servicesScheduleCount={servicesScheduleCount}
                                     setServicesScheduleLocal={setServicesScheduleLocal} unityOfMeasure={unityOfMeasure}
                                     unitOfMeasure={unitOfMeasure} service={service}
                                     setService={setService} services={services} specification={specification}
                                     setSpecification={setSpecification} key={row.Id} row={row} handleIcons={handleIcons}
                                     actions={actions} localServiceKind={localServiceKind} serviceKind={serviceKind}/>)) :
                    <TableRow><TableCell align="center" colSpan={8}><Box>Записей пока нет</Box></TableCell></TableRow>}
            </TableBody>
            <ConfirmDialog/>
        </Table>
    );
}

export default React.memo(TableMin);
