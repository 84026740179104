import {
    CLIENT_GET_ERROR,
    CLIENT_GET_REQUEST,
    CLIENT_GET_SUCCESS,
} from "../../constants/client/clientConstants";
import {
    CLIENT_DELETE_ERROR,
    CLIENT_DELETE_REQUEST,
    CLIENT_DELETE_SUCCESS
} from "../../constants/client/clientConstants";

const initialState = {
    client: [],
    clientCount: null,
    loading: false,
    success: null,
    error: null
};

export const clientReducer = (state = initialState, action) => {
    switch (action.type) {
        case CLIENT_GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CLIENT_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                client: action.payload.result,
                clientCount: action.payload.pageCount,
                success: true
            };
        case CLIENT_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case CLIENT_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CLIENT_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                legalClient: action.payload,
            };
        case CLIENT_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        default:
            return state;
    }
}