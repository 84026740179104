import React from 'react';
import Dashboard from "../../layouts/Dashboard";
import {TableCell, TableRow} from "@mui/material";
import TableCustomVariantTwo from "../../components/TableCustom/TableCustomVariantTwo";
import BreadcrumbsShared from "../../components/Shared/BreadcrumbsShared";
import {OBRASH_ROUTE} from "../../routes/const";

function Obrash(props) {
    return (
        <Dashboard>
            <BreadcrumbsShared breadcrumbs={[
                {text: 'Отчет по разовым обращениям', link: OBRASH_ROUTE}
            ]}/>
            <TableCustomVariantTwo name="Отчет по разовым обращ и ф.л. КОНТРОЛЬ (и так по каждой услуге)" header={<React.Fragment>
                <TableRow sx={{width: '100%'}}>
                    <TableCell align="center" colSpan={1}>
                        №
                    </TableCell>
                    <TableCell align="center" colSpan={2}>
                        Филиал/Отделение
                    </TableCell>
                    <TableCell align="center" colSpan={2}>
                        Население
                    </TableCell>
                    <TableCell align="center" colSpan={2}>
                        Количество обращений
                    </TableCell>
                    <TableCell align="center" colSpan={2}>
                        Сумма поступлений
                    </TableCell>
                </TableRow>
            </React.Fragment>}/>
        </Dashboard>
    );
}

export default Obrash;