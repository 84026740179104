import React from 'react';
import {Checkbox, FormControlLabel, FormGroup, Grid} from "@mui/material";
import {DatePickerShared, SelectShared} from "../../../components";
import moment from "moment";

function ContractRequestAdditionallyInfo({anObjectKind, transportType, mode, setContractDeadline, contractDeadline, departureOfSpecialists, setDepartureOfSpecialists, objectKind, setObjectKind, personTransportType, setPersonTransportType, legalPersonTransportType, setLegalPersonTransportType}) {
    return (
        <Grid sx={{paddingTop: 2, paddingBottom: 2}} container spacing={2}>
            {(mode === 'edit' || !mode) && <>
            <Grid item xs={6}>
                <SelectShared array={anObjectKind} label="Вид объекта" value={objectKind} setValue={setObjectKind}/>
            </Grid>
            <Grid item xs={6}>
                <DatePickerShared value={contractDeadline} setValue={setContractDeadline} label="Срок действия договора"/>
            </Grid>
            <Grid item xs={6}>
                <FormGroup>
                    <FormControlLabel control={<Checkbox checked={departureOfSpecialists} onChange={(event) => setDepartureOfSpecialists(event.target.checked)} />} label="Выезд специалистов" />
                </FormGroup>
            </Grid>
            <Grid item xs={6}>
                <SelectShared array={transportType} label="Тип транспорта для ФЛ" value={personTransportType} setValue={setPersonTransportType}/>
            </Grid>
            {departureOfSpecialists && <Grid item xs={6}>
                <SelectShared array={transportType} label="Тип транспорта для ЮЛ" value={legalPersonTransportType} setValue={setLegalPersonTransportType}/>
            </Grid>}
            </>
            }
            {mode === 'see' && <>
                <Grid item xs={6}>
                    {anObjectKind.find(el => el.id === objectKind)?.name}
                </Grid>
                <Grid item xs={6}>
                    Срок действия: {moment(contractDeadline).format('LL')}
                </Grid>
                <Grid item xs={6}>
                    <FormGroup>
                        <FormControlLabel disabled control={<Checkbox checked={departureOfSpecialists} onChange={(event) => setDepartureOfSpecialists(event.target.checked)} />} label="Выезд специалистов" />
                    </FormGroup>
                </Grid>
                <Grid item xs={6}>
                    {transportType.find(el => el.id === personTransportType)?.name}
                </Grid>
                {departureOfSpecialists && <Grid item xs={6}>
                    {transportType.find(el => el.id === legalPersonTransportType)?.name}
                </Grid>}
            </>
            }
        </Grid>
    );
}

export default React.memo(ContractRequestAdditionallyInfo);
