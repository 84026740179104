import React, {useContext, useEffect, useState} from 'react'
import Box from "@mui/material/Box";
import {Grid} from "@mui/material";
import Button from "@mui/material/Button";
import {SelectFieldModalFilial, SelectFieldModalGroup, TableMin} from "../../../../components";
import {confirmWarning} from "../../../../components/Popups/message/PopupWarning";
import useCreateObjectForm from '../../../../components/Crud/Models/useCreateObjectForm';
import {useHistory} from "react-router-dom";
import {editContract} from "../../../../redux/actions/contract/contractActions";
import {CONTRACT_ROUTE} from "../../../../routes/const";
import {useDispatch} from "react-redux";
import {getService} from "../../../../redux/actions/service/serviceActions";
import {ContractContext} from "../../ContractPage";
import TextShared from "../../../../components/Shared/TextShared";

const ContractPage = ({
                          anObjects,
                          anObjectsCount,
                          servicesSchedule,
                          servicesScheduleCount,
                          anObjectsLocal,
                          setAnObjectsLocal,
                          servicesScheduleLocal,
                          setServicesScheduleLocal,
                          handleNext,
                          specification,
                          setSpecification,
                          handleFormSpec,
                          handleDeleteSpec,
                          steps,
                          handleBack,
                          activeStep,
                          qty,
                          setQty,
                          localServiceKind,
                          setLocalServiceKind,
                          serviceKind,
                          service,
                          setService,
                          servicesCount,
                          services,
                          unitOfMeasure,
                          setUnitOfMeasure,
                          unityOfMeasure,
                          setOpen,
                          name,
                          number,
                          registeredAt,
                          client,
                          actionAt,
                          typeContract,
                          legalClient,
                          type,
                          localFilial,
                          localCity,
                          schedules,
                          educationServices,
                          contractObject,
                          localFilialDivision,
                            id
                      }) => {
                        const { openCreateObjectForm, DIALOG } = useCreateObjectForm();
    const [price, setPrice] = useState()
    const history = useHistory()
    const dispatch = useDispatch()

    const {square, setSquare,
        multiplicity, setMultiplicity} = useContext(ContractContext)

    useEffect(() => {
        setPrice(specification?.reduce((acc, curr) => {
            return acc + (curr.price * curr.quantity) * +curr.square * +curr.multiplicity;
        }, 0))
    }, [specification]);

    const handleSave = async () => {
        await dispatch(editContract(id, name, number, registeredAt, actionAt, price, typeContract ? client : legalClient, type, specification, [], typeContract ? 'personId' : 'legalPersonId', {}, localFilial, localFilialDivision, localCity, educationServices,
            schedules,
            contractObject, null, false))
        await history.push(CONTRACT_ROUTE)
    }
    return (
        <Box onSubmit={handleFormSpec} component="form"
             sx={{mt: 1, display: 'flex', padding: '0px 10px', flexWrap: 'wrap'}}>
                 <DIALOG />
            <Box sx={{width: '100%', mt: 3, overflowX: 'auto'}}>
                <TableMin edit servicesCount={servicesCount} handleDeleteSpec={handleDeleteSpec} unityOfMeasure={unityOfMeasure} setUnitOfMeasure={setUnitOfMeasure} unitOfMeasure={unitOfMeasure} services={services} setService={setService} service={service} serviceKind={serviceKind} setLocalServiceKind={setLocalServiceKind} localServiceKind={localServiceKind} setQty={setQty} qty={qty} setServicesScheduleLocal={setServicesScheduleLocal} servicesScheduleLocal={servicesScheduleLocal} setAnObjectsLocal={setAnObjectsLocal} anObjectsLocal={anObjectsLocal} servicesScheduleCount={servicesScheduleCount} servicesSchedule={servicesSchedule} anObjectsCount={anObjectsCount} anObjects={anObjects} setOpen={setOpen} setSpecification={setSpecification} specification={specification}/>
                <Box sx={{display: 'flex', alignItems: 'center', columnGap: '5px'}}>
                    <Button type="submit">Добавить блок</Button>
                    <SelectFieldModalGroup sx={{minWidth: '250px'}} label="Услуги" navigate={1} edit={true} func={getService} nameFilter="name" slice={{first: 0, second: 3}}
                                           reduxCount={servicesCount} reduxValue={services} setValue={setSpecification}/>
                    <SelectFieldModalFilial sx={{minWidth: '250px'}} label="Услуги" navigate={1} edit={true} func={getService} nameFilter="name" slice={{first: 0, second: 3}}
                                            reduxCount={servicesCount} reduxValue={services} setValue={setSpecification}/>
                    {specification.length ? <Button onClick={() => openCreateObjectForm()}>Создать объект</Button> : <></>}
                    <TextShared  fullWidth={false} required={false} size="small" label="Итого" disabled value={price}/>
                </Box>
            </Box>
            <Box sx={{width: '100%', mt: 10}}>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Button
                            fullWidth
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            variant="contained"
                        >
                            Назад
                        </Button>
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            fullWidth
                            onClick={() => {
                                if (!specification.length) {
                                    confirmWarning('Специфика не может быть пустой', () => {
                                    })
                                } else
                                    handleNext()
                            }
                            }
                            variant="contained"
                        >
                            {activeStep === steps.length - 1 ? 'Завершить' : 'Далее'}
                        </Button>
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            onClick={handleSave}
                            fullWidth
                            variant="contained"
                        >
                            Сохранить
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default ContractPage