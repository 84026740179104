import React from 'react';
import List from "@mui/material/List";
import ModalDrawerChildRow from "./ModalDrawerChildRow";

function ModalDrawerChild({id, packageParent, goToPage}) {

    return (
        <List component="div" disablePadding sx={{marginLeft: '10px'}}>
            {packageParent && packageParent.map((el, idx) => (
                <ModalDrawerChildRow id={id} goToPage={goToPage} el={el} key={el.id}/>
            ))}
        </List>
    );
}

export default ModalDrawerChild;