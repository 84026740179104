export const emptyDate = "0001-01-01T00:00:00";
function toDatetimeLocal(date) {
    const ten = function (i) {
      return (i < 10 ? '0' : '') + i;
    };
    const YYYY = date.getFullYear();
    const MM = ten(date.getMonth() + 1);
    const DD = ten(date.getDate());
    const HH = ten(date.getHours());
    const II = ten(date.getMinutes());
    const SS = ten(date.getSeconds());
  ;
  return YYYY + '-' + MM + '-' + DD + 'T' +
           HH + ':' + II + ':' + SS;
};

export function adjustForTimezone(date) {
    const timeOffsetInMS = date.getTimezoneOffset() * 60000;
    date.setTime(date.getTime() + timeOffsetInMS);
    return date;
}

export function decrementTimezone(date) {
    const timeOffsetInMS = date.getTimezoneOffset() * 60000;
    date.setTime(date.getTime() - timeOffsetInMS);
    return date;
}

export function toUIDateTime(date, adjustTimezone) {       
    if (date == emptyDate)
        return null;
    
    if (!date)
        return date;

    if (date.indexOf('T') >= 0) {
        let parsedDate = new Date(date);

        if (adjustTimezone)
            parsedDate = decrementTimezone(parsedDate);

        return toDatetimeLocal(parsedDate);
    }

    return date;
}