import React from 'react';
import {Box, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {useTranslation} from "react-i18next";
import TableClientRequestRow from "./TableClientRequestRow";

const TableClientRequest = ({specification}) => {
    const {t} = useTranslation();
    const rowName = [
        {text: t("specification.tableItem.service")},
        {text: t("specification.tableItem.qty")},
        {text: t("specification.tableItem.price")},
        {text: t("specification.tableItem.servicesSchedule")},
        {text: t("specification.tableItem.summary")},
    ]

    return (
        <Table sx={{minWidth: '80%'}}>
            <TableHead sx={{backgroundColor: '#fafafa'}}>
                <TableRow>
                    {rowName.map((el, idx) => {
                        return <TableCell key={idx}
                                          align="center">{el.text}</TableCell>
                    })}
                </TableRow>
            </TableHead>
            <TableBody>
                {specification.length ? specification.map((row) => (
                        <TableClientRequestRow key={row.id} row={row}/>)) :
                    <TableRow><TableCell align="center" colSpan={8}><Box>Записей пока нет</Box></TableCell></TableRow>}
            </TableBody>
        </Table>
    );
}

export default TableClientRequest;