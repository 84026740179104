import React from 'react';
import {Breadcrumbs, Link, Stack} from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {useHistory} from "react-router-dom";

function BreadcrumbsShared({breadcrumbs}) {
    const history = useHistory()
    return (
        <Stack sx={{marginBottom: 4}} spacing={2}>
            <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
            >
                {breadcrumbs.map((el, idx) => (
                    <Link underline="hover" key={idx} color="inherit" onClick={() => history.push(el.link)}>
                        {el.text}
                    </Link>
                ))}
            </Breadcrumbs>
        </Stack>
    );
}

export default BreadcrumbsShared;