import {
    CHANGE_CONTRACT_STATE,
    CONTRACT_ADD_ERROR,
    CONTRACT_ADD_REQUEST,
    CONTRACT_ADD_SUCCESS,
    CONTRACT_GET_ERROR,
    CONTRACT_GET_REQUEST,
    CONTRACT_GET_SUCCESS,
    CONTRACT_ONE_GET_ERROR,
    CONTRACT_ONE_GET_REQUEST,
    CONTRACT_ONE_GET_SUCCESS, CONTRACT_PAY_STATE_ERROR, CONTRACT_PAY_STATE_REQUEST, CONTRACT_PAY_STATE_SUCCESS,
    CONTRACT_STATE_GET_ERROR,
    CONTRACT_STATE_GET_REQUEST,
    CONTRACT_STATE_GET_SUCCESS,
    CONTRACT_TYPE_GET_ERROR,
    CONTRACT_TYPE_GET_REQUEST,
    CONTRACT_TYPE_GET_SUCCESS,
} from "../../constants/contract/contractConstants";
import {
    CONTRACT_DELETE_ERROR,
    CONTRACT_DELETE_REQUEST,
    CONTRACT_DELETE_SUCCESS
} from "../../constants/contract/contractConstants";
import {
    CONTRACT_FILE_ADD_ERROR,
    CONTRACT_FILE_ADD_REQUEST,
    CONTRACT_FILE_ADD_SUCCESS
} from "../../constants/contract/contractFileConstants";

const initialState = {
    contracts: [],
    contractsState: [],
    contractsPayState: [],
    contractOne: {},
    contractsCount: null,
    contractsCountTable: null,
    contractType: [],
    successChangeState: null,
    loading: false,
    success: null,
    error: null
};

export const contractReducer = (state = initialState, action) => {
    switch (action.type) {
        case CONTRACT_GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CONTRACT_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                contracts: action.payload.result,
                contractsCount: action.payload.pageCount,
                contractsCountTable: action.payload.total,
                success: true
            };
        case CONTRACT_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case CONTRACT_STATE_GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CONTRACT_STATE_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                contractsState: action.payload,
                success: true
            };
        case CONTRACT_STATE_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case CONTRACT_PAY_STATE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CONTRACT_PAY_STATE_SUCCESS:
            return {
                ...state,
                loading: false,
                contractsPayState: action.payload,
                success: true
            };
            case CHANGE_CONTRACT_STATE:
            return {
                ...state,
                loading: false,
                successChangeState: true,
            };
        case CONTRACT_PAY_STATE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case CONTRACT_ONE_GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CONTRACT_ONE_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                contractOne: action.payload,
                success: true
            };
        case CONTRACT_ONE_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case CONTRACT_TYPE_GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CONTRACT_TYPE_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                contractType: action.payload,
                success: true
            };
        case CONTRACT_TYPE_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case CONTRACT_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CONTRACT_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case CONTRACT_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case CONTRACT_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CONTRACT_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case CONTRACT_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case CONTRACT_FILE_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CONTRACT_FILE_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case CONTRACT_FILE_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        default:
            return state;
    }
}