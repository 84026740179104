import React from 'react';
import {makeStyles} from '@mui/styles';
import {
    Box,
    Button,
    Divider,
    Drawer,
    Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    drawer: {
        width: 420,
        maxWidth: '100%'
    },
    header: {
        padding: theme.spacing(2, 1),
        display: 'flex',
        justifyContent: 'space-between'
    },
    buttonIcon: {
        marginRight: theme.spacing(1)
    },
    content: {
        padding: theme.spacing(0, 3),
        flexGrow: 1
    },
    contentSection: {
        padding: theme.spacing(2, 0)
    },
    contentSectionHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        cursor: 'pointer'
    },
    contentSectionContent: {},
    formGroup: {
        padding: theme.spacing(2, 0)
    },
    fieldGroup: {
        display: 'flex',
        alignItems: 'center'
    },
    contentFilter: {
      display: 'flex',
      flexDirection: 'column'
    },
    field: {
        marginTop: 0,
        marginBottom: 0
    },
    flexGrow: {
        flexGrow: 1
    },
    addButton: {
        marginLeft: theme.spacing(1)
    },
    tags: {
        marginTop: theme.spacing(1)
    },
    minAmount: {
        marginRight: theme.spacing(3)
    },
    maxAmount: {
        marginLeft: theme.spacing(3)
    },
    radioGroup: {},
    actions: {
        padding: theme.spacing(3),
        '& > * + *': {
            marginTop: theme.spacing(2)
        }
    }
}));

const FilterCrud = props => {
    const {open, onClose, setFilter, setPage, filter, className, children, ...rest} = props;

    const classes = useStyles();

    return (
        <Drawer
            anchor="right"
            classes={{paper: classes.drawer}}
            onClose={onClose}
            open={open}
            variant="temporary"
        >
            <Box
                {...rest}
                className={clsx(classes.root, className)}
            >
                <div className={classes.header}>
                    <Button
                        onClick={onClose}
                        size="small"
                    >
                        <CloseIcon className={classes.buttonIcon}/>
                        Закрыть
                    </Button>
                </div>
                <div className={classes.content}>
                    <div className={classes.contentSection}>
                        <div
                            className={classes.contentSectionHeader}
                        >
                            <Typography variant="h5">Меню фильтров</Typography>
                        </div>
                        <Divider/>
                        <div className={classes.contentFilter}>
                            {children}
                        </div>
                    </div>
                </div>
            </Box>
        </Drawer>
    );
};

export default FilterCrud;
