import {Button, Collapse, Divider, ListItem} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import IconExpandLess from "@mui/icons-material/ExpandLess";
import IconExpandMore from "@mui/icons-material/ExpandMore";
import React, {useCallback, useContext, useState} from "react";
import {Grid} from "@mui/material";
import FilterCrud from "../Filter/FilterCrud";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {LoadingButton} from "@mui/lab";
import {ContractContext} from "../../screens/Contract/Contract";
import {useLocation} from "react-router-dom";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import {checkRoles} from "../../functions/checkRoles";

const CRUDFilter = ({children, filter, filterParsed, rightChildren, modalFilter}) => {
    const {pathname} = useLocation()
    const [open, setOpen] = useState(false)
    const openHandler = useCallback(() => {
        setOpen(!open)
    }, [open])

    const context = useContext(ContractContext)
    const isSuperAdmin = checkRoles("SuperAdministrator")
    const isMarketer = checkRoles("Marketer");

    return (
        <>
            <Grid container item xs={12} sx={{marginBottom: 2}}>
                <Grid item xs={4}>
                    {modalFilter ? <><Button
                        color="primary"
                        size="small"
                        variant="outlined"
                        onClick={openHandler}
                    >
                        <FilterAltIcon sx={{marginRight: 2}}/> Фильтры
                    </Button>
                        {(pathname === "/contract" || pathname === '/legal-client' || pathname === '/person' || pathname === '/contract-additional') && (isSuperAdmin || isMarketer) ? <LoadingButton size="small" variant="outlined" sx={{marginLeft: 3}} onClick={() => {
                            if (context?.handleContractToExcel) {
                                context?.handleContractToExcel(filter)
                            }
                        }
                        }>
                            <CloudDownloadIcon sx={{marginRight: 1}}/> Скачать отчет (excel)
                        </LoadingButton> : null}
                    </> : <ListItem button onClick={openHandler} style={{"maxWidth": "150px"}}>
                        <ListItemText primary={"Фильтры"}/>
                        {open ? <IconExpandLess/> : <IconExpandMore/>}
                    </ListItem>}
                </Grid>
                <Grid item xs={8}>
                    <div style={{"float": "right"}}>
                        {rightChildren && rightChildren}
                    </div>
                </Grid>
            </Grid>
            {modalFilter ? <FilterCrud onClose={() => setOpen(false)}
                                   open={open}>{children}</FilterCrud> : <Collapse in={open} timeout="auto" unmountOnExit>
                <Divider/>
                {children}
            </Collapse>}
        </>
    )
}

export default CRUDFilter
