import React from 'react';

import {employeesCRUD} from "../../../http/CRUD";
import CRUDTableStandart from "../CRUDTableStandart";


export default function EmployeeCRUDTable({...props}) {
    const columns = [
        {title: "#", dataIndex: 'id', key: 'id'},
        {title: "Полное имя", dataIndex: 'fullName', key: 'fullName'},
        {
            title: "Должность",
            key: 'position',
            render: (unknown, row) => row.positionId ? row.position?.name :
                <span style={{color: "gray"}}>Не задан</span>
        },
        {
            title: "Филиал",
            key: 'filial',
            render: (unknown, row) => row.filialId ? row.filial?.name :
                <span style={{color: "gray"}}>Не задан</span>
        },
        {
            title: "Районное отделение",
            key: 'filialDivision',
            render: (unknown, row) => row.filialDivisionId ? row.filialDivision?.name :
                <span style={{color: "gray"}}>Не задан</span>
        },
        {
            title: "Лаборатория",
            key: 'department',
            render: (unknown, row) => row.departmentId ? row.department?.name :
                <span style={{color: "gray"}}>Не задан</span>
        },
        {
            title: "Отдел лаборатории",
            key: 'division',
            render: (unknown, row) => row.divisionId ? row.division?.name :
                <span style={{color: "gray"}}>Не задан</span>
        },
    ]
    const searchFields = [
        {
            title: "ФИО работника",
            key: "fullName",
            compareType: "like",
            operandPosition: 1,
            filterType: "normal"
        }
    ]

    const filterFields = [

        // {
        //     title: "Лаборатория",
        //     key: "departmentId",
        //     compareType: "equals",
        //     reducerKey: "department",
        //     reducerArrayKey: "departments",
        //     reducerAction: getDepartments,
        //     operandPosition: 1,
        //     optionLabelKey: "name",
        //     type: "normal",
        //     filterType: "normal",
        // }
    ]

    return (
        <CRUDTableStandart search={employeesCRUD.search}
                        columns={columns} {...props}
                        searchFields={searchFields}
                        filterFields={filterFields}
        />
    );
}
