export const CONTRACT_GET_REQUEST = 'CONTRACT_GET_REQUEST'
export const CONTRACT_GET_SUCCESS = 'CONTRACT_GET_SUCCESS'
export const CONTRACT_GET_ERROR = 'CONTRACT_GET_ERROR'

export const CONTRACT_STATE_GET_REQUEST = 'CONTRACT_STATE_GET_REQUEST'
export const CONTRACT_STATE_GET_SUCCESS = 'CONTRACT_STATE_GET_SUCCESS'
export const CONTRACT_STATE_GET_ERROR = 'CONTRACT_STATE_GET_ERROR'

export const CONTRACT_ONE_GET_REQUEST = 'CONTRACT_ONE_GET_REQUEST'
export const CONTRACT_ONE_GET_SUCCESS = 'CONTRACT_ONE_GET_SUCCESS'
export const CONTRACT_ONE_GET_ERROR = 'CONTRACT_ONE_GET_ERROR'

export const CONTRACT_ADD_REQUEST = 'CONTRACT_ADD_REQUEST'
export const CONTRACT_ADD_SUCCESS = 'CONTRACT_ADD_SUCCESS'
export const CONTRACT_ADD_ERROR = 'CONTRACT_ADD_ERROR'

export const CHANGE_CONTRACT_STATE = 'CHANGE_CONTRACT_STATE'

export const CONTRACT_EDIT_REQUEST = 'CONTRACT_EDIT_REQUEST'
export const CONTRACT_EDIT_SUCCESS = 'CONTRACT_EDIT_SUCCESS'
export const CONTRACT_EDIT_ERROR = 'CONTRACT_EDIT_ERROR'

export const CONTRACT_DELETE_REQUEST = 'CONTRACT_DELETE_REQUEST'
export const CONTRACT_DELETE_SUCCESS = 'CONTRACT_DELETE_SUCCESS'
export const CONTRACT_DELETE_ERROR = 'CONTRACT_DELETE_ERROR'

export const CONTRACT_TYPE_GET_REQUEST = 'CONTRACT_TYPE_GET_REQUEST'
export const CONTRACT_TYPE_GET_SUCCESS = 'CONTRACT_TYPE_GET_SUCCESS'
export const CONTRACT_TYPE_GET_ERROR = 'CONTRACT_TYPE_GET_ERROR'

export const CONTRACT_PAY_STATE_REQUEST = 'CONTRACT_PAY_STATE_REQUEST'
export const CONTRACT_PAY_STATE_SUCCESS = 'CONTRACT_PAY_STATE_SUCCESS'
export const CONTRACT_PAY_STATE_ERROR = 'CONTRACT_PAY_STATE_ERROR'