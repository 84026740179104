import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {Grid} from "@mui/material";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getServiceKind, getUnitOfMeasure} from "../../redux/actions/genericActions";
import {
    addService, editService,
    getService, getServiceGroup,
    getServiceSubGroup, getServiceType
} from "../../redux/actions/service/serviceActions";
import TextShared from "../Shared/TextShared";
import NumberShared from "../Shared/NumberShared";
import {AutoCompleteShared, SelectShared} from "../index";
import {style} from "./popup";

export default function PopupService({open, details, setOpen, title, edit, setEdit, id}) {
    const [name, setName] = useState('')
    const [code, setCode] = useState(1)
    const [isParent, setIsParent] = useState({})
    const [typesGroup, setTypesGroup] = useState({})
    const [typesSubGroup, setTypesSubGroup] = useState({})
    const [typesUnit, setTypesUnit] = useState({})
    const [types, setTypes] = useState(1)
    const [serviceLocal, setServiceLocal] = useState(1)
    const dispatch = useDispatch()
    const {services, serviceType} = useSelector((state) => state.service);
    const emptyState = () => {
    }
    const handleForm = async (e) => {
        e.preventDefault()
        if (edit) {
            await dispatch(editService(id, name, code, isParent, types, typesUnit, typesGroup, typesSubGroup, isParent === '2' ? serviceLocal : ''))
        } else
            await dispatch(addService(name, code, isParent.id, types, typesUnit.id, typesGroup.id, typesSubGroup.id, isParent === '2' ? serviceLocal : ''))
        setOpen(false)
        if (!details) {
            setEdit(false)
            emptyState()
        }
    }
    const handleClose = async () => {
        if (edit && !details) {
            setEdit(false)
            emptyState()
        }
        setOpen(false)
    }

    useEffect(() => {
        if (edit) {
            const {
                name,
                typeId,
                unitId,
                kindId,
                groupId,
                subGroupId,
                code
            } = services.find(el => el.id === id)
            setName(name)
            setCode(code)
            setTypes(typeId)
            setTypesUnit(unitId)
            setTypesGroup(groupId)
            setTypesSubGroup(subGroupId)
            setIsParent(kindId.toString())
        }
    }, [services, details, edit, id])

    useEffect(() => {
        let resKind
        let resGroup
        let resType
        let resSubGroup
        let resUnit
        let resService
        const getLocal = async () => {
            resService = await dispatch(getService(1))
            resKind = await dispatch(getServiceKind())
            resUnit = await dispatch(getUnitOfMeasure(1))
            resType = await dispatch(getServiceType())
            resGroup = await dispatch(getServiceGroup(1))
            resSubGroup = await dispatch(getServiceSubGroup(1))
            setIsParent({id: resKind[0].id, title: resKind[0].name})
            setTypesUnit({id: resUnit[0].id, title: resUnit[0].name})
            setTypesGroup({id: resGroup[0].id, title: resGroup[0].name})
            setTypesSubGroup({id: resSubGroup[0].id, title: resSubGroup[0].name})
            if (resService?.length && resUnit?.length && resType?.length && resSubGroup?.length && resKind?.length && resGroup?.length && !edit) {
                setServiceLocal(resService[0].id)
                setTypes(resType[0].id)
            }
        }
        getLocal()
    }, [dispatch, edit])
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{...style, width: 800}}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {title} {edit && '(редактирование)'}
                    </Typography>
                    <Box component="form" onSubmit={handleForm} sx={{mt: 1, display: 'flex', flexWrap: 'wrap'}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextShared setValue={setName} value={name} label="Название"/>
                            </Grid>
                            <Grid item xs={12}>
                                <NumberShared value={code} setValue={setCode} label="Код"/>
                            </Grid>
                            <Grid item xs={6}>
                                <AutoCompleteShared values={isParent} setValues={setIsParent} label="Вид услуги"
                                                    func={getServiceKind}/>
                            </Grid>
                            <Grid item xs={6}>
                                <AutoCompleteShared values={typesUnit} setValues={setTypesUnit}
                                                    label="Единица измерения" func={getUnitOfMeasure}/>
                            </Grid>
                            <Grid item xs={6}>
                                <SelectShared value={types} setValue={setTypes} label="Тип заявки" array={serviceType}/>
                            </Grid>
                            <Grid item xs={6}>
                                <AutoCompleteShared values={typesSubGroup} setValues={setTypesSubGroup}
                                                    label="Подгруппа" func={getServiceSubGroup}/>
                            </Grid>
                            <Grid item xs={6}>
                                <AutoCompleteShared values={typesGroup} setValues={setTypesGroup} label="Группа"
                                                    func={getServiceGroup}/>
                            </Grid>
                            {isParent === '2' && <Grid item xs={12} sx={{display: 'flex', alignItems: 'center'}}>
                                <SelectShared value={serviceLocal} setValue={setServiceLocal}
                                              label="Выберите родительский элемент" array={services}/>
                            </Grid>}
                            <Grid item xs={6}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{mt: 3, mb: 2}}
                                >
                                    {edit ? 'Редактирование' : 'Добавить'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Modal>
        </div>)
}
