import React from 'react';
import DashboardLayout from "../../../layouts/Dashboard";
import ClientCRUDTable from "../../../components/Crud/Tables/ClientCRUDTable";

const Clients = () => {
    return (
        <DashboardLayout>
            <ClientCRUDTable modalFilter/>
        </DashboardLayout>
    );
};

export default Clients;