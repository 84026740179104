import moment from "moment";

export const MOMENT_FORMAT_DATE_DMY_DOTS = "DD.MM.YYYY";
export const MOMENT_FORMAT_DATE_DMYHM_DOTS = "DD.MM.YYYY H:mm";
export const MOMENT_FORMAT_DATE_YMD = "YYYY-MM-DD";

export function formatDate(date, format = MOMENT_FORMAT_DATE_DMY_DOTS) {
  if (!date)
    return "";
  return moment(date).isValid() && moment(date).format(format)
}

export function formatDateAsDate(date) {
  return formatDate(date, MOMENT_FORMAT_DATE_DMY_DOTS);
}

export function formatDate2(date, format = MOMENT_FORMAT_DATE_YMD) {
  if (!date)
    return "";
  return moment(date).isValid() && moment(date).format(format)
}


export function formatDateAsDateTime(date) {
  return formatDate(date, MOMENT_FORMAT_DATE_DMYHM_DOTS);
}