import { useEffect, useState } from "react";
import { ncaLayer } from "./NCALayerClient";

export const useNCALayerClient = () =>{
    const [ready, setReady] = useState(false);
    const [client, setClient] = useState(null);

    // setup NCALayer
    useEffect(() => {
        const connect = async () => {
            // create instance of NCALayerClient
            const clientInstance = new ncaLayer();
            setClient(clientInstance);

            try {
                await clientInstance.connect();
                setReady(clientInstance?.isOpen)
            } catch (error) {
                console.log(`Не удалось подключиться к NCALayer: ${error.toString()}`);
                return;
            }
        }

        connect();

        // destructor
        return () => {
            // close connection
            client?.close();
        }
    },[]);

    return [client, ready];
}