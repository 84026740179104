import {getData, getOneData} from "../template/get";
import {
    ADD_GEO_OBJECT_ERROR,
    ADD_GEO_OBJECT_REQUEST,
    ADD_GEO_OBJECT_SUCCESS, DELETE_GEO_OBJECT_ERROR, DELETE_GEO_OBJECT_REQUEST, DELETE_GEO_OBJECT_SUCCESS,
    EDIT_GEO_OBJECT_ERROR,
    EDIT_GEO_OBJECT_REQUEST,
    EDIT_GEO_OBJECT_SUCCESS, GET_GEO_CHILD_OBJECT_REQUEST, GET_GEO_CHILD_OBJECT_SUCCESS,
    GET_GEO_OBJECT_ERROR,
    GET_GEO_OBJECT_FILTER_REQUEST,
    GET_GEO_OBJECT_FILTER_SUCCESS,
    GET_GEO_OBJECT_REQUEST,
    GET_GEO_OBJECT_SUCCESS,
    GET_GEO_OBJECT_TYPE_ERROR,
    GET_GEO_OBJECT_TYPE_REQUEST,
    GET_GEO_OBJECT_TYPE_SUCCESS
} from "../../constants/geo/geoConstants";
import {$authHost} from "../../../http";
import {editData} from "../template/edit";
import {deleteData} from "../template/delete";

export const getGeoObject = (page, size, args, nonGlobal) => async (dispatch) => {
    return await dispatch(getData(GET_GEO_OBJECT_REQUEST, GET_GEO_OBJECT_SUCCESS, GET_GEO_OBJECT_ERROR, 'geoObject', false, page, null, null, false, size, args, nonGlobal, null, 'asc'))
};

export const getOneGeoObject = (id) => async (dispatch) => {
    return await dispatch(getOneData(id, '', '', '', 'geoObject'))
};

export const editGeoObject = (id, name, typeId, filialId, parentId) => async (dispatch) => {
    return await dispatch(editData(EDIT_GEO_OBJECT_REQUEST, EDIT_GEO_OBJECT_SUCCESS, EDIT_GEO_OBJECT_ERROR, 'geoObject', getGeoObject(), {
        id, name, typeId, filialId, parentId: parentId ? parentId : ''
    }))
};

export const deleteGeoObject = (id) => async (dispatch) => {
    return await dispatch(deleteData(DELETE_GEO_OBJECT_REQUEST, DELETE_GEO_OBJECT_SUCCESS, DELETE_GEO_OBJECT_ERROR, 'geoObject', getGeoObject(), id))
};

export const addGeoObject = (name, typeId, filialId, parentId) => async (dispatch) => {
    try {
        await dispatch({
            type: ADD_GEO_OBJECT_REQUEST,
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.post(
            "/api/v1/geoObject",
            {
                name,
                typeId,
                filialId,
                parentId: parentId ? parentId : '',
            },
            config
        );
        await dispatch(getGeoObject(1, 100, {filter: {
                typeId: {
                    operand1: 3,
                    operator: "equals"
                },
            }}))
        await dispatch({
            type: ADD_GEO_OBJECT_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: ADD_GEO_OBJECT_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const getGeoObjectFilter = (filter1) => async (dispatch) => {
    try {
        dispatch({
            type: GET_GEO_OBJECT_FILTER_REQUEST,
        });
        const {data} = await $authHost.post(
            `api/v1/geoObject/query`,
            {
                sort: {
                    id: {
                        operator: 1,
                        ordinal: 0
                    }
                },
                filter: {
                    typeId: {
                        operand1: filter1,
                        operator: "equals"
                    }
                }
            }
        );
        dispatch({
            type: GET_GEO_OBJECT_FILTER_SUCCESS,
            payload: data.result
        });
        return data.result
    } catch (error) {
        console.log(error)
    }
};

export const getGeoObjectChild = (filter1) => async (dispatch) => {
    try {
        dispatch({
            type: GET_GEO_CHILD_OBJECT_REQUEST,
        });
        const {data} = await $authHost.post(
            `api/v1/geoObject/query`,
            {
                sort: {
                    id: {
                        operator: 1,
                        ordinal: 0
                    }
                },
                filter: {
                    parentId: {
                        operand1: filter1,
                        operator: "equals"
                    }
                }
            }
        );
        dispatch({
            type: GET_GEO_CHILD_OBJECT_SUCCESS,
            payload: data.result
        });
        return data.result
    } catch (error) {
        console.log(error)
    }
};

export const getGeoObjectType = () => async (dispatch) => {
    return await dispatch(getData(GET_GEO_OBJECT_TYPE_REQUEST, GET_GEO_OBJECT_TYPE_SUCCESS, GET_GEO_OBJECT_TYPE_ERROR, 'geoObjectType', false, 1, null, null, false, 1000, null, null, null, 'asc'))
};