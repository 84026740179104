import React, {useContext, useEffect, useState} from 'react';
import {Box, IconButton, TableCell, TableRow} from "@mui/material";
import {confirmDialog} from "../Popups/message/PopupDelete";
import {ContractContext} from "../../screens/Contract/ContractPage";
import moment from "moment";
import {DatePickerShared} from "../index";
import {ContractRequestAddContext} from "../../screens/ContractRequest/ContractRequestAdd";
import {useLocation} from "react-router-dom";

const ResearchScheduleRow = ({
                                                      row,
                                                      actions,
                                                      handleIcons,
                                                      mode,
                                                      edit,
                                                  }) => {
    const {pathname} = useLocation()
    const {localTargetYear, localTargetMonth, setSchedules, schedules} = useContext(pathname.includes('/contract-request') ? ContractRequestAddContext : ContractContext)
    const [localTargetMonthRow, setLocalTargetMonthRow] = useState(localTargetMonth)
    const [localTargetYearRow, setLocalTargetYearRow] = useState(localTargetYear)

    const handleRowVariant2 = (event, valueName, setValue) => {
        const dataIdx = schedules.findIndex(el => el.Id === row.Id)
        let data = [...schedules]
        data[dataIdx] = {...data[dataIdx], [valueName]: event}
        setSchedules(data)
        setValue(event)
    }

    const handleMountRow = (event) => {
        handleRowVariant2(event, 'targetMonth', setLocalTargetMonthRow)
    }

    const handleYearRow = (event) => {
        handleRowVariant2(event, 'targetYear', setLocalTargetYearRow)
    }


    useEffect(() => {
        setLocalTargetMonthRow(row?.targetMonth)
        setLocalTargetYearRow(row?.targetYear)
    }, [edit, row])

    return (
        <TableRow>
            {
                (mode === 'edit' || !mode) &&
                <>
                    <TableCell align="center">
                        <DatePickerShared views={['year', 'month']} value={localTargetMonthRow} setValue={handleMountRow} label="Месяц" />
                    </TableCell>
                    <TableCell align="center">
                        <DatePickerShared views={['year']} value={localTargetYearRow} setValue={handleYearRow} label="Год" />
                    </TableCell>
                    <TableCell align="center">
                        <Box>
                            {actions.map((el, idx) => (
                                <IconButton data-testid={el.id}
                                            children={el.icon}
                                            onClick={(e) => {
                                                if (el.id === 'DeleteForeverIcon') {
                                                    confirmDialog('Удаление элемента', 'Вы действительно хотите удалить этот элемент?', () => {
                                                        return handleIcons(el.id, row.Id)
                                                    })
                                                } else
                                                    return handleIcons(el.id, row.Id)
                                            }}
                                            key={idx}>
                                </IconButton>
                            ))}
                        </Box>
                    </TableCell>
                </>
            }
            {
                mode === 'see' && <>
                    <TableCell align="center">
                        {moment(localTargetMonthRow).format('MMMM')}
                    </TableCell>
                    <TableCell align="center">
                        {moment(localTargetYearRow).format('YYYY')}
                    </TableCell>
                </>
            }

        </TableRow>
    );
}

export default React.memo(ResearchScheduleRow);