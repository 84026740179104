import React, {useEffect, useState} from 'react';
import Dashboard from "../../layouts/Dashboard";
import BreadcrumbsShared from "../../components/Shared/BreadcrumbsShared";
import {PAYMENT_ROUTE} from "../../routes/const";
import moment from "moment";
import {DeleteForeverIconCustom, TableCustom, VisibilityIconCustom} from "../../components";
import {completionActTableRowName} from "../../lang/i18n";
import {useDispatch, useSelector} from "react-redux";
import {getPayments} from "../../redux/actions/bookkeeping/bookkeepingActions";

function Payment(props) {
    const dispatch = useDispatch()
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const {payments, paymentsCountTable} = useSelector((state) => state.bookkeeping);

    const rowName = [
        ...completionActTableRowName
    ]

    useEffect(() => {
        (async function () {
            dispatch(getPayments(page + 1, rowsPerPage))
        }())
    }, [dispatch, page, rowsPerPage]);

    return (
        <Dashboard>
            <BreadcrumbsShared breadcrumbs={[
                {text: 'Платежи', link: PAYMENT_ROUTE}
            ]}/>
            <TableCustom rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} page={page} setPage={setPage}
                         countRow={paymentsCountTable} name="Платежи" avatars={false} rowName={rowName}
                         rows={payments && payments.map(el => {
                             return {
                                 id: el?.id,
                                 startDate: moment(el.startDate).format('LL'),
                                 name: el?.contract?.name,
                                 status: el?.state?.name,
                             }
                         })} locale="payments" actions={[
                {icon: <VisibilityIconCustom/>, id: 'VisibilityIcon'},
                {icon: <DeleteForeverIconCustom/>, id: 'DeleteForeverIcon'}]}/>
        </Dashboard>
    );
}

export default Payment;