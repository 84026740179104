import React, {useState} from 'react';
import Dashboard from "../../layouts/Dashboard";
import {PAYMENT_ADD_ROUTE, PAYMENT_ROUTE} from "../../routes/const";
import BreadcrumbsShared from "../../components/Shared/BreadcrumbsShared";
import Box from "@mui/material/Box";
import {CircularProgress} from "@mui/material";

function PaymentAdd(props) {
    const [loading] = useState(false);

    function handleSubmit(event) {
        event.preventDefault()
    }

    return (
        <Dashboard>
            <BreadcrumbsShared breadcrumbs={[
                {text: 'Платежи', link: PAYMENT_ROUTE},
                {text: 'Добавление платежа', link: PAYMENT_ADD_ROUTE}
            ]}/>
            {loading ? <Box sx={{
                    minHeight: 556,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}><CircularProgress/></Box> :
                <Box component="form" onSubmit={handleSubmit}>
                </Box>}
        </Dashboard>
    );
}

export default PaymentAdd;