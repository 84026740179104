import * as React from "react";
import AnObjectField from "../Fields/AnObjectField";
import ClientField from "../Fields/ClientField";
import ContractField from "../Fields/ContractField";
import EmployeeField from "../Fields/EmployeeField";
import LegalClientField from "../Fields/LegalClientField";
import PersonField from "../Fields/PersonField";
import ServiceField from "../Fields/ServiceField";
import ServiceScheduleField from "../Fields/ServiceScheduleField";

const DomainFields = ({
                          fieldName,
                          label,
                          onChange,
                          ...props
                      }) => {

    const fields = {
        AnObjectField,
        ClientField,
        ContractField,
        EmployeeField,
        LegalClientField,
        PersonField,
        ServiceField,
        ServiceScheduleField
    }

    const FieldsName = fields[fieldName]

    return (
        <FieldsName onChange={onChange} label={label} {...props}/>
    );
};

DomainFields.defaultProps = {
    optionValueKey: "id",
    optionLabelKey: "name",
};

export default DomainFields;