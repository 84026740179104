import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {Grid} from "@mui/material";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getCurrency, getUnitOfMeasure} from "../../redux/actions/genericActions";
import {addServicePrice, editServicePrice, getService} from "../../redux/actions/service/serviceActions";
import {Number, SelectShared} from "../index";
import TextShared from "../Shared/TextShared";
import {style} from "./popup";

export default function PopupServicePrice({open, details, setOpen, title, edit, setEdit, id}) {
    const [name, setName] = useState('')
    const [price, setPrice] = useState(0)
    const [currency, setCurrency] = useState(1)
    const [unitOfMeasure, setUnitOfMeasure] = useState(1)
    const [service, setService] = useState(1)
    const dispatch = useDispatch()
    const {services, servicesPrice} = useSelector((state) => state.service);
    const {unityOfMeasure, currencyAll} = useSelector((state) => state.generic);
    const emptyState = () => {
        setName('')
        setPrice(0)
        setCurrency(1)
        setUnitOfMeasure(1)
        setService(1)
    }
    const handleForm = async (e) => {
        e.preventDefault()
        if (edit) {
            await dispatch(editServicePrice(id, name, price, unitOfMeasure, currency, service))
        } else
            await dispatch(addServicePrice(name, price, unitOfMeasure, currency, service))
        setOpen(false)
        if (!details) {
            setEdit(false)
            emptyState()
        }
    }
    const handleClose = async () => {
        if (edit && !details) {
            setEdit(false)
            emptyState()
        }
        setOpen(false)
    }
    useEffect(() => {
        if (edit) {
            const {name, price, currencyId, serviceId, unitId} = servicesPrice.find(el => el.id === id)
            setName(name)
            setPrice(price)
            setCurrency(currencyId)
            setService(serviceId)
            setUnitOfMeasure(unitId)
        }
    }, [details, edit, id, servicesPrice])

    useEffect(() => {
        let resCurrency
        let resUnit
        let resService
        const getLocal = async () => {
            resCurrency = await dispatch(getCurrency())
            resUnit = await dispatch(getUnitOfMeasure())
            resService = await dispatch(getService())
            if (resCurrency?.length && resService?.length && resUnit?.length && !edit) {
                setCurrency(resCurrency[0].id)
                setUnitOfMeasure(resUnit[0].id)
                setService(resService[0].id)
            }
        }
        getLocal()
    }, [dispatch, edit])

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {title} {edit && '(редактирование)'}
                        </Typography>
                        <Box component="form" onSubmit={handleForm} sx={{mt: 1, display: 'flex', flexWrap: 'wrap'}}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextShared value={name} setValue={setName} label="Название"/>
                                </Grid>
                                <Grid item xs={6}>
                                    <Number max="9999999" value={price} setValue={setPrice} label="Цена"/>
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectShared value={service} setValue={setService} label="Услуга" array={services}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectShared value={unitOfMeasure} setValue={setUnitOfMeasure} label="Ед. измерения" array={unityOfMeasure}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectShared value={currency} setValue={setCurrency} label="Валюта" array={currencyAll}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{mt: 3, mb: 2}}
                                    >
                                        {edit ? 'Редактирование' : 'Добавить'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
            </Modal>
        </div>
    );
}
