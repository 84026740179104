import { Button, Dialog, DialogContent, Divider } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import LegalPersonCRUDTable from "../Crud/Tables/LegalPersonCRUDTable";
import AnObjectCRUDTable from "../Crud/Tables/AnObjectCRUDTable";
import ServiceCRUDTable from "../Crud/Tables/ServiceCRUDTable";

export default function ServiceField({
                                         dealId,
                                          value,
                                          onChange,
                                          extraFilter,
                                          disabled
                                      }) {
    const [open, setOpen] = useState(false);
    return (
        <>
            <Grid container spacing={1}>
                <Button disabled={disabled} onClick={() => setOpen(true)} style={{ marginLeft: "10px", marginTop: '10px', outline: 'none' }}>Добавить услугу</Button>
            </Grid>
            {/* Dialog Select*/}
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                fullWidth
                PaperProps={{sx: {maxHeight: '85%'}}}
                maxWidth={"80%"}
            >
                <DialogTitle>Выбор услуги</DialogTitle>
                <Divider />
                <DialogContent>
                    <ServiceCRUDTable
                        edit={false}
                        extraFilter={{
                            typeId: {
                                operand1: 1,
                                operator: "equals"
                            }
                        }}
                        onClickCreateButton={undefined} // что бы не показывать кнопку создания
                        onRowClick={(record, rowIndex) => {
                            let specification = {
                                service: record,
                                dealId: +dealId,
                                serviceId: record.id,
                            };
                            if (onChange) onChange([...(value || []), specification]);
                            setOpen(false);
                        }}
                    />
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button
                        variant={"text"}
                        style={{ marginLeft: "auto" }}
                        onClick={() => setOpen(false)}
                    >
                        Отменить
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
