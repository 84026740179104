import useLoader from "../../hooks/useLoader";
import {Button, Grid} from "@mui/material";
import Box from "@mui/material/Box";
import TablePagination from "@mui/material/TablePagination";
import TextField from "@mui/material/TextField";
import {makeStyles} from "@mui/styles";
import {Table} from "antd";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import DomainSelect from "../Select/DomainSelect";
import FilterDatePicker from "../DatePicker/FilterDatePiecker";
import {cleanObject} from "../../functions/viewDataMap";
import CRUDFilter from "./CRUDFilter";
import CRUDTableContext from "./CRUDTableContext";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DomainFields from "./DomainFields";
import FilterDatePicker2 from "../Shared/FilterDatePicker2";


const useStyles = makeStyles({
    trClickable: {
        cursor: "pointer",
    },
});


const CRUDTableStandart = ({
                               extraFilter,
                               searchPlaceholder,
                               search,
                               rowClassNameHandler,
                               searchByIdHide,
                               columns,
                               onClickDetailsButton,
                               onClickEditButton,
                               onClickDeleteButton,
                               onClickCreateButton,
                               onClickCopyButton,
                               detailsVisibleFunc,
                               editVisibleFunc,
                               deleteVisibleFunc,
                               extraActions,
                               reloadValue,
                               rowKey,
                               searchByOperator,
                               extraHeader,
                               expandable,
                               filterFields,
                               onRowClick,
                               CollapseCRUDTable,
                               searchFields,
                               sendFilterValues,
                               searchBy,
                               pagination,
                               otherColumns,
                               collapsibleTable,
                               collapsedTableTitle,
                               reducerFilterKey,
                               reducerParentKey,
                               serviceToPackage,
                               disableCreateButton,
                               onData,
                               mapData,
                               modalFilter = false,
                               ...props
                           }) => {

    const classes = useStyles();
    const _columns = useMemo(() => {
        const result = columns;

        if (extraActions)
            for (let i = 0; i < extraActions.length; i++)
                result.push(extraActions[i]);

        if (onClickDetailsButton || onClickEditButton || onClickDeleteButton || onClickCopyButton) {
            if (!result.find(el => el.key === 'actions')) {
                result.push({
                    title: 'Действия',
                    key: 'actions',
                    fixed: 'right',
                    width: 100,
                    render: (_, record) => {
                        return (
                            <>
                                {onClickDetailsButton &&
                                <VisibilityIcon style={{fontSize: '1.5rem', marginRight: '20px'}}
                                                onClick={() => onClickDetailsButton(record.id)}/>}
                                {onClickEditButton && <EditIcon style={{fontSize: '1.5rem', marginRight: '20px'}}
                                                                onClick={() => onClickEditButton(record.id)}/>}
                                {onClickDeleteButton && <DeleteForeverIcon style={{fontSize: '1.5rem', marginRight: '20px'}}
                                                                           onClick={() => onClickDeleteButton(record.id)}/>}
                                {onClickCopyButton && <ContentCopyIcon style={{fontSize: '1.5rem'}}
                                                                       onClick={() => onClickCopyButton(record)}/>}
                            </>
                        )
                    },
                })
            }
            ;
        }

        return result;
    }, [columns, extraActions, onClickDeleteButton, onClickEditButton, onClickDetailsButton]);

    const [data, setData] = useState([]);
    const Context = CRUDTableContext

    const [total, setTotal] = useState(0);
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(pagination ? 10 : null);

    const {loading, start, stop} = useLoader(true);
    const paging = useMemo(
        () => ({take, skip, returnCount: true}),
        [take, skip]
    );
    const [sort] = useState({id: {operator: "desc"}});
    const [filter, setFilter] = useState({});
    const [searchValue, setSearchValue] = useState({});
    const [advancedSearchValue, setAdvancedSearchValue] = useState({});

    const fetch = useCallback((reloadExtraFilter) => {
        start();
        if (typeof search === 'function') {
            search({paging, sort, filter: {...filter, ...(reloadExtraFilter || extraFilter || {})}})
                .then(async (data) => {
                    if (mapData)
                        data.result = mapData(data.result);
                    if (onData) {
                        await onData(data.result);
                    }
                    if (serviceToPackage) {
                        const result = await data.result.map(el => el.service)
                        setData(result);
                    } else
                        setData(data.result);
                    setTotal(data.total);
                })
                .catch(alert)
                .finally(stop);
        } else {
            setData(search?.result)
            setTotal(search?.total);
            stop()
        }
    }, [paging, filter, extraFilter, sort, start, stop, search, reloadValue]);
    useEffect(() => {
        fetch(extraFilter);
    }, [skip, take, paging, filter, extraFilter, search, reloadValue]);

    const rowClassName = useMemo(() => {
        const classNames = [];
        if (onRowClick) classNames.push(classes.trClickable);
        return classNames.join(" ");
    }, [onRowClick, classes]);


    const handleSearchPanelChange = (val, key, compareType, operandPosition, filterType) => {
        switch (filterType) {
            case "normal":
                setSearchValue(state => {
                    const stateObject = {
                        ...state,
                        [key]: {
                            ...state[key],
                            operator: compareType,
                            [`operand${operandPosition}`]: val,
                        }
                    }
                    if (!val) {
                        stateObject[key] = null
                    }
                    return stateObject
                });
                break
            case "advanced":
                setAdvancedSearchValue(state => {
                    const stateObject = {
                        ...state,
                        [key]: val
                    }
                    if (!val) {
                        stateObject[key] = null
                    }
                    return stateObject
                })
                break

        }


    };
    const applyFilter = () => {
        setSkip(0)
        setFilter(state => {
            return {
                ...state,
                ...cleanObject(searchValue),
                advancedFilter: {
                    ...advancedSearchValue
                }
            }
        })
    }
    const removeFilter = () => {
        setSearchValue({})
        setAdvancedSearchValue({})
        setFilter({})
    }


    return (
        <>{
            (searchFields || filterFields) && <CRUDFilter filter={filter} {...props} rightChildren={
                onClickCreateButton && <Button onClick={onClickCreateButton}>Добавить</Button>
            } modalFilter={modalFilter}>
                <Box sx={{display: 'flex', flexDirection: modalFilter ? 'column' : 'row'}}>
                    {searchFields && searchFields.map((field, idx) => {
                        return (
                            <Box
                                key={idx}
                                sx={{
                                    margin: "0 0 0 0",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginRight: modalFilter ? '0' : '20px',
                                    width: modalFilter ? '100%' : 'auto'
                                }}
                            >
                                <TextField
                                    type="text"
                                    label={field.title}
                                    value={(() => {
                                        switch (field.filterType) {
                                            case "normal":
                                                return searchValue[field.key]?.[`operand${field.operandPosition}`] || ""
                                            case "advanced":
                                                return advancedSearchValue?.[field.key] || ""
                                        }
                                    })()}
                                    style={{
                                        margin: "8px 0",
                                        width: modalFilter ? '100%' : 'auto'
                                    }}
                                    onChange={(ev) => handleSearchPanelChange(ev.target.value, field.key, field.compareType, field.operandPosition, field.filterType)}

                                />
                            </Box>
                        )
                    })}
                    <Grid container style={{
                        margin: "8px 0 8px -10px",
                        ...(!modalFilter && {alignItems: 'center'}),
                        rowGap: 20,
                        flexDirection: modalFilter && 'column'
                    }} item xs={12}>
                        {filterFields && filterFields.map((field, idx) => {
                            switch (field.type) {
                                case "date2":
                                    return <Grid key={idx} style={{
                                        marginLeft: "10px",
                                        minWidth: modalFilter ? '100%' : 'auto',
                                    }} item xs={2}>
                                        <FilterDatePicker2 key={field.key} label={field.title} value={(() => {
                                            switch (field.filterType) {
                                                case "normal":
                                                    return searchValue[field.key] ? searchValue[field.key][`operand${field.operandPosition}`] ? searchValue[field.key][`operand${field.operandPosition}`] : null : null
                                            }
                                        })()}
                                                           handleFunction={(e) => {
                                                               const val = e.target.value;
                                                               if (sendFilterValues && (field.key && val)) {
                                                                   sendFilterValues(field.key + field.operandPosition, val)
                                                               }
                                                               handleSearchPanelChange(val ? new Date(val) : null, field.key, field.compareType, field.operandPosition, field.filterType)
                                                           }}/>
                                    </Grid>

                                case "normal":
                                    return <Grid key={idx} style={{
                                        marginLeft: "10px",
                                        minWidth: modalFilter ? '100%' : 'auto',
                                    }} item xs={2}>
                                        <DomainSelect
                                            label={field.title}
                                            fullWidth
                                            margin={"none"}
                                            reducerKey={field.reducerKey}
                                            reducerArrayKey={field.reducerArrayKey}
                                            reducerAction={field.reducerAction}
                                            optionLabelKey={field.optionLabelKey}
                                            value={(() => {
                                                switch (field.filterType) {
                                                    case "normal":
                                                        return searchValue[field.key]?.[`operand${field.operandPosition}`] || ""
                                                    case "advanced":
                                                        return advancedSearchValue?.[field.key] || ""
                                                }
                                            })()}
                                            key={field.key}
                                            parentKey={field.parentKey}
                                            parentId={field.parentKey && searchValue[field.parentKey]}
                                            filter={field.filter}
                                            onChange={(val) => {
                                                if (sendFilterValues && (field.reducerKey && val)) {
                                                    sendFilterValues(field.reducerKey, val)
                                                }
                                                handleSearchPanelChange(val, field.key, field.compareType, field.operandPosition, field.filterType)
                                            }}
                                        />
                                    </Grid>
                                case "table":
                                    return <Grid key={idx} style={{
                                        marginLeft: "10px",
                                        minWidth: modalFilter ? '100%' : 'auto'
                                    }} item xs={2}>
                                        <DomainFields
                                            fieldName={field.fieldName}
                                            label={field.title}
                                            fullWidth
                                            sx={{minWidth: '300px'}}
                                            value={(() => {
                                                switch (field.filterType) {
                                                    case "normal":
                                                        return searchValue[field.key]?.[`operand${field.operandPosition}`] || ""
                                                    case "advanced":
                                                        return advancedSearchValue?.[field.key] || ""
                                                }
                                            })()}
                                            key={field.key}
                                            onChange={(val) => {
                                                handleSearchPanelChange(val.id, field.key, field.compareType, field.operandPosition, field.filterType)
                                            }}
                                        />
                                    </Grid>
                                case "date":
                                    return <Grid key={idx} style={{
                                        marginLeft: "10px",
                                        width: modalFilter ? '100%' : 'auto'
                                    }} item xs={2}>
                                        <FilterDatePicker key={field.key} label={field.title} value={(() => {
                                            switch (field.filterType) {
                                                case "normal":
                                                    return searchValue[field.key] ? searchValue[field.key][`operand${field.operandPosition}`] ? searchValue[field.key][`operand${field.operandPosition}`] : null : null
                                            }
                                        })()}
                                                          handleFunction={(val) => {
                                                              if (sendFilterValues && (field.key && val)) {
                                                                  sendFilterValues(field.key + field.operandPosition, val)
                                                              }
                                                              handleSearchPanelChange(new Date(val), field.key, field.compareType, field.operandPosition, field.filterType)
                                                          }}/>
                                    </Grid>
                                default:
                                    <></>
                            }

                        })}
                    </Grid>
                </Box>
                <Grid container item xs={12} marginBottom={2} justifyContent={"space-between"}>
                    <Grid container item xs={10}>
                        <Button onClick={() => applyFilter()}>
                            Найти
                        </Button>
                        <Button style={{marginLeft: "10px"}} onClick={() => removeFilter()}>
                            Сбросить фильтры
                        </Button>
                    </Grid>
                </Grid>
            </CRUDFilter>
        }
            {collapsibleTable ?
                <Context.Provider value={{
                    CollapseCRUDTable: CollapseCRUDTable,
                    onRowClick: onRowClick,
                    reducerFilterKey: reducerFilterKey,
                    reducerParentKey: reducerParentKey,
                    columns: _columns,
                    rows: data,
                    collapsedTableTitle: collapsedTableTitle
                }}>
                    <CollapseCRUDTable otherColumns={otherColumns}/>
                </Context.Provider>

                :
                <Table
                    columns={_columns}
                    rowKey={rowKey}
                    dataSource={data}
                    loading={loading}
                    expandable={expandable}
                    pagination={false}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: (e) => onRowClick && onRowClick(record, rowIndex), // click row
                            onDoubleClick: (event) => {
                            }, // double click row
                            onContextMenu: (event) => {
                            }, // right button click row
                            onMouseEnter: (event) => {
                            }, // mouse enter row
                            onMouseLeave: (event) => {
                            }, // mouse leave row
                        };
                    }}
                    rowClassName={rowClassNameHandler ? rowClassNameHandler : rowClassName}
                    {...props}
                />
            }


            {pagination && <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100, 500, 1000]}
                style={{alignItems: "center"}}
                component="div"
                count={total}
                rowsPerPage={take}
                labelRowsPerPage="Отображаемых элементов"
                page={skip / take}
                onPageChange={(e, page) => {
                    setSkip(page * take);
                }}
                onRowsPerPageChange={(event) => {
                    setSkip(0);
                    setTake(event.target.value);
                }}
            />}

        </>
    );
};

CRUDTableStandart.defaultProps = {
    rowKey: "id",
    pagination: true
};

export default CRUDTableStandart;
