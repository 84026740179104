import React from 'react';
import Box from "@mui/material/Box";
import TableMinSigners from "../../../../components/TableCustom/TableMinSigners";
import {Button, Grid} from "@mui/material";
import {confirmWarning} from "../../../../components/Popups/message/PopupWarning";

const AddSignersEdit = ({signers, setSigners, handleSigners,  employee, employeeCount, activeStep, handleBack, handleNext, steps}) => {
    return (
        <Box component="form"
             sx={{mt: 1, display: 'flex', padding: '0px 10px', flexWrap: 'wrap'}}>
            <TableMinSigners edit={true} employee={employee} employeeCount={employeeCount} signers={signers} setSigners={setSigners}/>
            <Button onClick={handleSigners}>Добавить блок</Button>
            <Box sx={{width: '100%', mt: 10}}>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Button
                            fullWidth
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            variant="contained"
                        >
                            Назад
                        </Button>
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            fullWidth
                            onClick={() => {
                                if (!signers.length) {
                                    confirmWarning('Список согласующих не может быть пустым', () => {
                                    })
                                } else
                                    handleNext()
                            }
                            }
                            variant="contained"
                        >
                            {activeStep === steps.length - 1 ? 'Завершить' : 'Далее'}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default AddSignersEdit;