import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {CircularProgress, Grid, Stack} from "@mui/material";
import {Link, useHistory} from "react-router-dom";
import {GENERAL_ROUTE} from "../../routes/const";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {check, login} from "../../redux/actions/userActions";
import {getProfile} from "../../redux/actions/profile/profileActions";
import {useNCALayerClient} from "../../hooks/ecp/SignHooks";

const theme = createTheme();

const SignIn = () => {
    const history = useHistory()
    const dispatch = useDispatch()

    const [ client, ready ] = useNCALayerClient();
    const [isEcpMethod, setIsEcpMethod] = useState(process.env.NODE_ENV !== 'development');
 //   const [isEcpMethod, setIsEcpMethod] = useState(true);
    const [ecpLoginXml, setEcpLoginXml] = useState(null);
    const [ecpReady, setEcpReady] = useState(false);
    const [ecpMessage, setEcpMessage] = useState(null);
    const [ecpLoginSignedXml, setEcpLoginSignedXml] = useState(null);


    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const user = useSelector((state) => state.user);
    const {loading, success} = user;

    useEffect(() => {
        if (!client)
            return;

        client.onopen = function() {
            setEcpReady(true);
            setEcpMessage(null);
        }

        client.onclose = function() {
            setEcpReady(false);
            setEcpMessage("Ошибка соединения с провайдером ЭЦП (NCALayer)");
        }

        client.onmessage = function (msg) {
            console.log(2, 'msg', msg);
            setEcpLoginSignedXml(msg.responseObject);
        };

    }, [client])

    useEffect(async () => {
        if (success) {
            await dispatch(getProfile())
            await dispatch(check());
            setEmail('');
            setPassword('');
            history.push(GENERAL_ROUTE)
        }
    }, [history, success])

    const handleSubmit = async (event) => {
        event.preventDefault();
        await dispatch(login(email, password, ecpLoginXml, ecpLoginSignedXml))
    };

    const willSelectEcp = () => {
        const d = new Date();
        const n = d.getTime();
        const loginXml = '<login><time>' + n + '</time></login>';
        setEcpLoginXml(loginXml);

        client.signXml('PKCS12', 'AUTHENTICATION', loginXml);
    }

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="sm">
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{m: 5, height: 150, width: 150, bgcolor: 'secondary.main'}} src="/images/logo.svg"/>
                    <Typography component="h1" variant="h5">
                        Авторизация
                    </Typography>
                    {loading && <CircularProgress/>}
                    {ecpMessage && <span>{ecpMessage}</span>}
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            id="email"
                            label="Ваш логин"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            name="password"
                            label="Пароль"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <FormControlLabel
                            value={isEcpMethod}
                            onChange={e => {
                                setIsEcpMethod(e.target.checked)
                            }}
                            control={<Checkbox disabled checked={isEcpMethod} color="primary"/>}
                            label="Вход по эцп"
                        />
                        <Stack direction="row" alignItems="center" spacing={3}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                disabled={isEcpMethod && !ecpLoginSignedXml}
                            >
                                Войти
                            </Button>
                            <Button fullWidth disabled={!isEcpMethod}
                                    variant="contained"
                                    onClick={willSelectEcp}
                            >
                                Выбрать ЭЦП
                            </Button>
                        </Stack>
                        <Grid container sx={{mt: 2}}>
                            <Grid item>
                                <Link to="/sign-up">Ещё нет аккаунта? Регистрация
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
};

export default SignIn;