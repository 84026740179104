import React, {useEffect, useState} from 'react';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {style} from "./popup";
import Typography from "@mui/material/Typography";
import {Page, Text, View, Document, StyleSheet} from '@react-pdf/renderer';
import {PDFViewer} from '@react-pdf/renderer';
import {Font} from '@react-pdf/renderer'
import RobotoRegular from '../../assets/fonts/Roboto/Roboto-Regular.ttf'
import RobotoItalic from '../../assets/fonts/Roboto/Roboto-Italic.ttf'
import RobotoBold from '../../assets/fonts/Roboto/Roboto-Bold.ttf'
import RobotoMedium from '../../assets/fonts/Roboto/Roboto-Medium.ttf'
import {useDispatch, useSelector} from "react-redux";
import {getOneInvoice} from "../../redux/actions/bookkeeping/bookkeepingActions";
import moment from "moment";
import {getOneContract} from "../../redux/actions/contract/contractActions";
import Loader from "../Shared/Loader";

function PopupExcelInvoice({open, handleClose, id}) {

    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [specifications, setSpecifications] = useState([])
    const {invoiceOne} = useSelector((state) => state.bookkeeping);
    const [price, setPrice] = useState(0)

    useEffect(() => {
        (async function () {
            setLoading(true)
           const res = await dispatch(getOneInvoice(id))
           const contract = await dispatch(getOneContract(res?.contractId))
            setSpecifications(contract?.specifications)
            setPrice(contract?.specifications?.reduce((acc, curr) => {
                return acc + (curr.price * curr.quantity);
            }, 0))
            setLoading(false)
        }())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, id]);

    Font.register({
        family: "RobotoRegular",
        src: RobotoRegular
    });

    Font.register({
        family: "RobotoItalic",
        src: RobotoItalic
    });

    Font.register({
        family: "RobotoMedium",
        src: RobotoMedium
    });

    Font.register({
        family: "RobotoBold",
        src: RobotoBold
    });

    const styles = StyleSheet.create({
        page: {
            flexDirection: 'row',
            fontFamily: "RobotoRegular",
            fontSize: 10,
            width: '100%'
        },
        section: {
            marginLeft: 44,
            marginRight: 24,
            marginTop: 32,
            padding: 10,
            flexGrow: 1
        },
        text1: {
            alignSelf: 'flex-end',
            marginRight: 51,
            fontSize: 8,
            maxWidth: 326
        },
        text2: {
            marginTop: 18,
            fontFamily: "RobotoMedium"
        },
        tableFirst: {
            width: '100%',
            border: '1px solid black',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            height: 117
        },
        tableHeader1: {
            width: '55%',
            height: 71,
            display: 'flex',
            flexDirection: 'column',
            borderRight: '1px solid black',
            borderBottom: '1px solid black',
            fontFamily: "RobotoMedium"
        },
        tableHeader2: {
            width: '25%',
            height: 71,
            display: 'flex',
            flexDirection: 'column',
            borderRight: '1px solid black',
            borderBottom: '1px solid black',
            fontFamily: "RobotoMedium"
        },
        tableHeader3: {
            width: '20%',
            height: 71,
            display: 'flex',
            flexDirection: 'column',
            borderBottom: '1px solid black',
            fontFamily: "RobotoMedium"
        },
        tableHeader1Text: {
            fontFamily: "RobotoMedium",
            marginLeft: 3
        },
        tableHeader2Text: {
            textAlign: 'center',
        },
        tableHeader3Text: {
            textAlign: 'center',
        },
        tableHeader2TextSecond: {
            textAlign: 'center',
            marginTop: 20
        },
        tableHeader3TextSecond: {
            textAlign: 'center',
            marginTop: 20
        },
        tableHeader1TextLast: {
            fontFamily: "RobotoRegular",
            marginLeft: 3,
            marginTop: 'auto'
        },
        tableHeader4: {
            width: '55%',
            height: 44,
            display: 'flex',
            flexDirection: 'column',
            borderRight: '1px solid black',
        },
        tableHeader4Text: {
            marginLeft: 3
        },
        tableHeader5: {
            width: '20%',
            height: 44,
            display: 'flex',
            flexDirection: 'column',
            borderRight: '1px solid black',
            fontFamily: "RobotoMedium"
        },
        tableHeader5Text: {
            textAlign: 'center',
        },
        tableHeader5TextSecond: {
            textAlign: 'center',
            marginTop: 8
        },
        tableHeader6: {
            width: '25%',
            height: 44,
            display: 'flex',
            flexDirection: 'column',
            fontFamily: "RobotoMedium"
        },
        tableHeader6Text: {
            textAlign: 'center',
        },
        tableHeader6TextSecond: {
            textAlign: 'center',
            marginTop: 8
        },
        invoiceName: {
            marginTop: 30,
            fontFamily: "RobotoBold",
            fontSize: 15,
            marginLeft: 5
        },
        hr: {
            width: '100%',
            marginTop: 10,
            marginBottom: 10,
            borderBottom: '2px solid black'
        },
        invoicePok: {
            marginTop: 43,
            marginBottom: 43,
            marginLeft: 5
        },
        text3: {
            marginLeft: 5
        },
        text4: {
            marginLeft: 5
        },
        text5: {
            fontFamily: "RobotoBold",
            marginLeft: 25
        },
        tableSecond: {
            marginTop: 9,
            borderTop: '1px solid black',
            borderRight: '1px solid black',
            borderLeft: '1px solid black',
            width: '100%'
        },
        tableColumn1: {
            width: '7%',
            height: 14,
            borderRight: '1px solid black',
            textAlign: 'center'
        },
        tableColumn2: {
            width: '13%',
            height: 14,
            borderRight: '1px solid black',
            textAlign: 'center'
        },
        tableColumn3: {
            width: '30%',
            height: 14,
            borderRight: '1px solid black',
            textAlign: 'center'
        },
        tableColumn4: {
            width: '10%',
            height: 14,
            borderRight: '1px solid black',
            textAlign: 'center'
        },
        tableColumn5: {
            width: '7%',
            height: 14,
            borderRight: '1px solid black',
            textAlign: 'center'
        },
        tableColumn6: {
            width: '16.5%',
            height: 14,
            borderRight: '1px solid black',
            textAlign: 'center'
        },
        tableRow: {
            fontFamily: "RobotoMedium",
            display: 'flex',
            flexDirection: 'row',
            borderBottom: '1px solid black',
        },
        bottom: {
            display: 'flex',
            flexDirection: 'row'
        },
        bottomLeft: {
            flex: '1 0 50%'
        },
        bottomRight: {
            alignSelf: 'flex-end',
            flex: '1 0 50%',
            marginTop: 10
        },
        text6: {
            marginLeft: 138,
            fontFamily: "RobotoMedium"
        },
        text7: {
            marginTop: 3,
            fontFamily: "RobotoMedium",
            marginLeft: 85
        },
        text8: {
            marginTop: 40,
            marginLeft: 4
        },
        text9: {
            marginTop: 3,
            marginLeft: 4,
            fontFamily: "RobotoMedium",
        },
        ispol: {
            marginLeft: 4,
            display: 'flex',
            fontFamily: "RobotoMedium",
            flexDirection: 'row',
        },
        input1: {
            marginLeft: 22,
            borderBottom: '1px solid black',
            width: 294
        }
    });
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{...style, width: "90%", minHeight: "90%", display: 'flex', flexDirection: 'column'}}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Просмотр счёта
                    </Typography>
                    {loading ? <Loader/> : <PDFViewer width="100%" height="100%" style={{flex: 1}}>
                        <Document language="ru">
                            <Page wrap size="A4" style={styles.page}>
                                <View style={styles.section}>
                                    <Text style={styles.text1}>Внимание! Оплата данного счета означает согласие с
                                        условиями поставки товара.
                                        Уведомление об оплате обязательно, в противном случае не гарантируется наличие
                                        товара на складе. Товар отпускается по факту прихода денег на р/с Поставщика,
                                        самовывозом, при наличии доверенности и документов удостоверяющих
                                        личность.</Text>
                                    <Text style={styles.text2}>Образец платежного поручения</Text>
                                    <View style={styles.tableFirst}>
                                        <View style={styles.tableHeader1}>
                                            <Text style={styles.tableHeader1Text}>Бенефициар: {invoiceOne?.beneficiaryName} </Text>
                                            <Text style={styles.tableHeader1TextLast}>БИН: {invoiceOne?.beneficiaryIdentity}</Text>
                                        </View>
                                        <View style={styles.tableHeader2}>
                                            <Text style={styles.tableHeader2Text}>ИИК</Text>
                                            <Text style={styles.tableHeader2TextSecond}>{invoiceOne?.beneficiaryAccountNumber}</Text>
                                        </View>
                                        <View style={styles.tableHeader3}>
                                            <Text style={styles.tableHeader3Text}>Кбе</Text>
                                            <Text style={styles.tableHeader3TextSecond}>{invoiceOne?.recipientCode}</Text>
                                        </View>
                                        <View style={styles.tableHeader4}>
                                            <Text style={styles.tableHeader4Text}>Банк бенефициара: {invoiceOne?.beneficiaryBank}</Text>
                                        </View>
                                        <View style={styles.tableHeader5}>
                                            <Text style={styles.tableHeader5Text}>БИК</Text>
                                            <Text style={styles.tableHeader5TextSecond}>{invoiceOne?.beneficiaryBankCode}</Text>
                                        </View>
                                        <View style={styles.tableHeader6}>
                                            <Text style={styles.tableHeader6Text}>Код назначения платежа</Text>
                                            <Text style={styles.tableHeader6TextSecond}>{invoiceOne?.paymentPurposeCode}</Text>
                                        </View>
                                    </View>
                                    <Text style={styles.invoiceName}>Счет на оплату №{invoiceOne?.number} от {moment(invoiceOne?.createdAt).format('L')}</Text>
                                    <View style={styles.hr}/>
                                    <Text style={styles.text3}>Поставщик: {invoiceOne?.supplierName}</Text>
                                    <Text style={styles.invoicePok}>Покупатель: {invoiceOne?.supplierIdentity}/{invoiceOne?.buyerIdentity}: </Text>
                                    <Text style={styles.text4}>Договор: <Text style={styles.text5}>{invoiceOne?.contract?.name}</Text></Text>
                                    <View style={styles.tableSecond}>
                                        <View style={styles.tableRow}>
                                            <View style={styles.tableColumn1}>
                                                <Text>№</Text>
                                            </View>
                                            <View style={styles.tableColumn2}>
                                                <Text>Код</Text>
                                            </View>
                                            <View style={styles.tableColumn3}>
                                                <Text>Наименование</Text>
                                            </View>
                                            <View style={styles.tableColumn4}>
                                                <Text>Кол-во</Text>
                                            </View>
                                            <View style={styles.tableColumn5}>
                                                <Text>Ед</Text>
                                            </View>
                                            <View style={styles.tableColumn6}>
                                                <Text>Цена</Text>
                                            </View>
                                            <View style={styles.tableColumn6}>
                                                <Text>Сумма</Text>
                                            </View>
                                        </View>
                                        {specifications.length && specifications.map((el, idx) => (
                                            <View style={styles.tableRow}>
                                                <View style={styles.tableColumn1}>
                                                    <Text>{idx + 1}</Text>
                                                </View>
                                                <View style={styles.tableColumn2}>
                                                    <Text>{el.id}</Text>
                                                </View>
                                                <View style={styles.tableColumn3}>
                                                    <Text>{el?.service?.name}</Text>
                                                </View>
                                                <View style={styles.tableColumn4}>
                                                    <Text>{el?.quantity}</Text>
                                                </View>
                                                <View style={styles.tableColumn5}>
                                                    <Text>{el?.unit?.name}</Text>
                                                </View>
                                                <View style={styles.tableColumn6}>
                                                    <Text>{el?.price}</Text>
                                                </View>
                                                <View style={styles.tableColumn6}>
                                                    <Text>{el?.price * el?.quantity}</Text>
                                                </View>
                                            </View>
                                        ))}
                                    </View>
                                    <View style={styles.bottom}>
                                        <View style={styles.bottomLeft}>
                                            <Text style={styles.text8}>Всего наименований {specifications.length}, на сумму {price} KZT</Text>
                                            <Text style={styles.text9}>Всего к оплате: {price}</Text>
                                        </View>
                                        <View style={styles.bottomRight}>
                                            <Text style={styles.text6}>Итого: {price}</Text>
                                            <Text style={styles.text7}>В том числе НДС:</Text>
                                        </View>
                                    </View>
                                    <View style={styles.hr}/>
                                    <View style={styles.ispol}>
                                        <Text>Исполнитель</Text>
                                        <View style={styles.input1}><Text>
                                            {invoiceOne?.performerName}
                                        </Text></View>
                                        {/* eslint-disable-next-line react/jsx-no-comment-textnodes */}
                                        <Text style={styles.separator}>//
                                        </Text>
                                    </View>
                                </View>
                            </Page>
                        </Document>
                    </PDFViewer>}
                </Box>
            </Modal>
        </div>
    );
}

export default PopupExcelInvoice;