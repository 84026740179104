import * as React from 'react';
import DashboardLayout from "../../layouts/Dashboard";
import Paper from "@mui/material/Paper";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {DeleteForeverIconCustom, TableCustom, VisibilityIconCustom} from "../../components";
import {MAIN_ROUTE} from "../../routes/const";
import BreadcrumbsShared from "../../components/Shared/BreadcrumbsShared";
import {getClientRequest} from "../../redux/actions/clientRequest/clientRequestActions";
import moment from "moment";

const Main = () => {
    const dispatch = useDispatch()
    const {clientRequests, clientRequestsCountTable} = useSelector((state) => state.clientRequest);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const {t} = useTranslation();
    const rowName = [
        {text: t("main.tableItem.id")},
        {text: t("main.tableItem.number")},
        {text: t("main.tableItem.filial")},
        {text: t("main.tableItem.summary")},
        {text: t("main.tableItem.actionAt")},
        {text: t("main.tableItem.actions")},
    ]


    useEffect(() => {
        (async function () {
                await dispatch(getClientRequest(page + 1, rowsPerPage))
        }())
    }, [dispatch, page, rowsPerPage])


    return (
        <DashboardLayout>
            <BreadcrumbsShared breadcrumbs={[
                {text: 'Клиентские заявки', link: MAIN_ROUTE},
            ]}/>
            <Paper sx={{backgroundColor: '#fafafa'}}>
                            <TableCustom countRow={clientRequestsCountTable} page={page} setPage={setPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} locale="main" name="Клиентские заявки" avatars={false}
                                         rowName={rowName} rowsPage={clientRequests && clientRequests}
                                         rows={clientRequests && clientRequests.map(el => {
                                             return {
                                                 id: el.id,
                                                 number: el.number,
                                                 filial: el?.filial?.name,
                                                 summary: el?.specificationsSummary,
                                                 actionAt: moment(el?.createdAt).format('LL'),
                                             }
                                         })}
                                         actions={[
                                                         {icon: <VisibilityIconCustom/>, id: 'VisibilityIcon'},
                                                         {icon: <DeleteForeverIconCustom/>, id: 'DeleteForeverIcon'}]}/>
            </Paper>
        </DashboardLayout>
    );
};

export default Main;