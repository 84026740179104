import * as React from 'react';
import {styled, createTheme, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Avatar from "@mui/material/Avatar";
import {stringAvatar} from "../functions";
import {useDispatch, useSelector} from "react-redux";
import SearchIcon from '@mui/icons-material/Search';
import {Input, ListItemIcon, Menu, MenuItem} from "@mui/material";
import {makeStyles} from "@mui/styles";
import Logout from '@mui/icons-material/Logout';
import {logout} from "../redux/actions/userActions";
import {PERSONAL_AREA_ROUTE, PROFILE_ROUTE} from "../routes/const";
import {useHistory} from "react-router-dom";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import Button from "@mui/material/Button";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));


const mdTheme = createTheme({
    palette: {
        primary: {
            main: '#3f51b5',
            contrastText: 'white',
        },
    },
});

const useStyles = makeStyles(theme => ({
    search: {
        backgroundColor: 'rgba(255,255,255, 0.1)',
        borderRadius: 4,
        flexBasis: 300,
        height: 36,
        padding: '0 10px',
        display: 'flex',
        alignItems: 'center'
    },
    searchIcon: {
        marginRight: '10px',
        color: 'inherit'
    },
    searchInput: {
        flexGrow: 1,
        color: 'inherit',
        '& input::placeholder': {
            opacity: 1,
            color: 'inherit'
        }
    },
}));


const DashboardLayout = ({children}) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const {profile} = useSelector((state) => state.profile);
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openEl = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const clientName = profile?.type?.id === 1 ? `${profile?.client?.person?.firstName} ${profile?.client?.person?.lastName}` : `Company ${profile?.client?.legalPerson?.name}`
    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{display: 'flex', backgroundColor: '#f5f5f5'}}>
                <CssBaseline/>
                <AppBar position="absolute">
                    <Toolbar
                        sx={{
                            pr: '24px',
                        }}
                    >
                        <Box sx={{display: 'flex', alignItems: 'center', flexGrow: 1}}>
                            <Avatar src="/images/logo.svg"/>
                            <Typography
                                component="span"
                                color="inherit"
                                sx={{
                                    marginLeft: '10px',
                                    textTransform: 'uppercase',
                                    fontSize: '14px',
                                    maxWidth: '250px'
                                }}
                            >
                                Национальный центр экспертизы
                            </Typography>
                            <div
                                className={classes.search}
                            >
                                <SearchIcon className={classes.searchIcon} />
                                <Input
                                    className={classes.searchInput}
                                    disableUnderline
                                    placeholder="Поиск..."
                                />
                            </div>
                        </Box>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Button
                                sx={{marginLeft: 2, marginRight: 2}}
                                variant="contained"
                                color="info"
                                onClick={() => history.push(PERSONAL_AREA_ROUTE)}
                                startIcon={<AdminPanelSettingsIcon/>}
                            >
                                Личный кабинет
                            </Button>
                            <IconButton onClick={handleClick}>
                                <Avatar {...stringAvatar(clientName)} />
                            </IconButton>
                        </Box>
                        <Menu
                            anchorEl={anchorEl}
                            open={openEl}
                            onClose={handleClose}
                            onClick={handleClose}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            <MenuItem onClick={() => history.push(PROFILE_ROUTE)}>
                                <Avatar /> Профиль
                            </MenuItem>
                            <Divider/>
                            <MenuItem onClick={() => dispatch(logout())}>
                                <ListItemIcon>
                                    <Logout fontSize="small" />
                                </ListItemIcon>
                                Выйти
                            </MenuItem>
                        </Menu>
                    </Toolbar>
                </AppBar>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar/>
                    <Container maxWidth={false} sx={{mt: 4, mb: 2}}>
                        {children}
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default DashboardLayout;