export const FILIAL_GET_REQUEST = 'FILIAL_GET_REQUEST';
export const FILIAL_GET_SUCCESS = 'FILIAL_GET_SUCCESS';
export const FILIAL_GET_ERROR = 'FILIAL_GET_ERROR';
export const FILIAL_GET_BY_KEY_REQUEST = 'FILIAL_GET_BY_KEY_REQUEST';
export const FILIAL_GET_BY_KEY_SUCCESS = 'FILIAL_GET_BY_KEY_SUCCESS';
export const FILIAL_GET_BY_KEY_ERROR = 'FILIAL_GET_BY_KEY_ERROR';
export const FILIAL_ADD_REQUEST = 'FILIAL_ADD_REQUEST';
export const FILIAL_ADD_SUCCESS = 'FILIAL_ADD_SUCCESS';
export const FILIAL_ADD_ERROR = 'FILIAL_ADD_ERROR';
export const FILIAL_EDIT_REQUEST = 'FILIAL_EDIT_REQUEST';
export const FILIAL_EDIT_SUCCESS = 'FILIAL_EDIT_SUCCESS';
export const FILIAL_EDIT_ERROR = 'FILIAL_EDIT_ERROR';
export const FILIAL_DELETE_REQUEST = 'FILIAL_DELETE_REQUEST';
export const FILIAL_DELETE_SUCCESS = 'FILIAL_DELETE_SUCCESS';
export const FILIAL_DELETE_ERROR = 'FILIAL_DELETE_ERROR';
