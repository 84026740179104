import React from 'react'
import TextField from "@mui/material/TextField";

const TextShared = ({value, label, setValue, type, required, size, fullWidth, multiline, disabled}) => {
    return (
        <TextField
            margin="normal"
            required={required}
            value={value}
            disabled={disabled}
            onChange={(e) => setValue(e.target.value)}
            fullWidth={fullWidth}
            multiline={multiline}
            type={type}
            label={label}
            size={size}
        />
    );
}

TextShared.defaultProps = {
    required: true,
    fullWidth: true,
    multiline: false,
    disabled: false,
    type: "text",
    size: "medium"
}

export default React.memo(TextShared)