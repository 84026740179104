import React, {useEffect, useState} from 'react';
import DashboardLayout from "../../layouts/Dashboard";
import {useTranslation} from "react-i18next";
import {Button} from "@mui/material";
import Box from "@mui/material/Box";
import {
    DeleteForeverIconCustom,
    TableCustomService,
    VisibilityIconCustom
} from "../../components";
import PopupService from "../../components/Popups/PopupService";
import {useDispatch, useSelector} from "react-redux";
import {getService} from "../../redux/actions/service/serviceActions";

const Service = () => {
    const {t} = useTranslation();
    const rowName = [
        {text: t("service.tableItem.id")},
        {text: t("service.tableItem.name")},
        {text: t("service.tableItem.actions")},
    ]
    const rowNameChild = [
        {text: t("service.tableItem.id")},
        {text: t("service.tableItem.name")},
        {text: t("service.tableItem.price")},
        {text: t("service.tableItem.actions")},
    ]
    const [open, setOpen] = useState(false);
    const [edit, setEdit] = useState(false);
    const dispatch = useDispatch()
    const [id, setId] = useState(null);
    const {services, servicesCount} = useSelector((state) => state.service);
    const setEditHandler = async (idEdit) => {
        setOpen(true)
        setEdit(true)
        setId(idEdit)
    }
    const [page, setPage] = useState(0);
    useEffect(() => {
        (async function () {
            await dispatch(getService(page + 1))
        }())
    }, [dispatch, page]);
    
    return (
        <DashboardLayout>
            <Box sx={{margin: '20px 0', display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                <Button onClick={() => setOpen(true)} color="primary"
                        variant="contained">{t("service.buttonText")}</Button>
            </Box>
            {/* eslint-disable-next-line array-callback-return */}
            <TableCustomService countRow={servicesCount} page={page} setPage={setPage} rowNameChild={rowNameChild} rowName={rowName} childTable={services} rows={services.filter(el => {
                if (el.parent === null) {
                    return {...el}
                }
            }).map(el => {
                return {
                    id: el.id,
                    name: el.name,
                }
            })} setEdit={setEditHandler} avatars={false} name="Услуги"
                         actions={[{icon: <VisibilityIconCustom/>, id: 'VisibilityIcon'},  {icon: <DeleteForeverIconCustom/>, id: 'DeleteForeverIcon'}]}/>
            <PopupService id={id} edit={edit} setEdit={setEdit} title="Услуги" open={open}
                          setOpen={setOpen}/>
        </DashboardLayout>
    );
};

export default Service;