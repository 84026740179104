export const TYPE_SERVICE_ADD_REQUEST = 'TYPE_SERVICE_ADD_REQUEST'
export const TYPE_SERVICE_ADD_SUCCESS = 'TYPE_SERVICE_ADD_SUCCESS'
export const TYPE_SERVICE_ADD_ERROR = 'TYPE_SERVICE_ADD_ERROR'

export const TYPE_SERVICE_GET_REQUEST = 'TYPE_SERVICE_GET_REQUEST'
export const TYPE_SERVICE_GET_SUCCESS = 'TYPE_SERVICE_GET_SUCCESS'
export const TYPE_SERVICE_GET_ERROR = 'TYPE_SERVICE_GET_ERROR'

export const TYPE_SERVICE_DELETE_REQUEST = 'TYPE_SERVICE_DELETE_REQUEST'
export const TYPE_SERVICE_DELETE_SUCCESS = 'TYPE_SERVICE_DELETE_SUCCESS'
export const TYPE_SERVICE_DELETE_ERROR = 'TYPE_SERVICE_DELETE_ERROR'

export const TYPE_SERVICE_EDIT_REQUEST = 'TYPE_SERVICE_EDIT_REQUEST'
export const TYPE_SERVICE_EDIT_SUCCESS = 'TYPE_SERVICE_EDIT_SUCCESS'
export const TYPE_SERVICE_EDIT_ERROR = 'TYPE_SERVICE_EDIT_ERROR'
