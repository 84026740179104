import React, {useState} from 'react';

import {clientCRUD, legalPersonCRUD, personCRUD} from "../../../http/CRUD";
import CRUDTableQuery from "../CRUDTableQuery";
import {PopupPerson} from "../../index";
import {confirmDialog} from "../../Popups/message/PopupDelete";
import useReload from "../../../hooks/useReload";
import CRUDTableStandart from "../CRUDTableStandart";

export default function PersonCRUDTable({type = 'standart', ...props}) {
    const { reload, reloadValue } = useReload();
    const [edit, setEdit] = useState(false)
    const columns = [
        {title: "#", dataIndex: 'id', key: 'id'},
        {title: "ФИО", dataIndex: 'fullName', key: 'fullName'},
        {
            title: "Email",
            key: 'email',
            render: (unknown, row) => row.email ? row.email :
                <span style={{color: "gray"}}>Не задан</span>
        },
        {
            title: "Адрес",
            key: 'addressLine',
            render: (unknown, row) => row.addressLine ? row.addressLine :
                <span style={{color: "gray"}}>Не задан</span>
        },
        {
            title: "Номер телефона",
            key: 'phoneNumber',
            render: (unknown, row) => row.phoneNumber ? row.phoneNumber :
                <span style={{color: "gray"}}>Не задан</span>
        }
    ]
    const searchFields = [
        {
            title: "ФИО",
            key: "fullName",
            compareType: "like",
            operandPosition: 1,
            filterType: "normal"
        },
        {
            title: "Email",
            key: "email",
            compareType: "like",
            operandPosition: 1,
            filterType: "normal"
        },
        {
            title: "Адрес",
            key: "addressLine",
            compareType: "like",
            operandPosition: 1,
            filterType: "normal"
        },
        {
            title: "Иин",
            key: "identityNumber",
            compareType: "like",
            operandPosition: 1,
            filterType: "normal"
        },
        {
            title: "Бин",
            key: "bin",
            compareType: "like",
            operandPosition: 1,
            filterType: "normal"
        },
        {
            title: "Номер телефона",
            key: "phoneNumber",
            compareType: "like",
            operandPosition: 1,
            filterType: "normal"
        }
    ]

    const [open, setOpen] = useState(false);
    const [id, setId] = useState(null);
    const editHandler = async (idEdit) => {
        setOpen(true)
        setEdit(true)
        setId(idEdit)
    }

    const confirmHandler = async () => {
        setOpen(false)
        await reload();
    }

    async function deleteHandler(id) {
        return confirmDialog('Удаление элемента', 'Вы действительно хотите удалить этот элемент?', async () => {
            try {
                const {result} = await clientCRUD.search({filter: {
                        personId: {
                            operand1: id,
                            operator: "equals"
                        }
                    }})
                if (result?.length){
                    await clientCRUD.delete(result[0].id)
                    await personCRUD.delete(id)
                }
                await reload();
            } catch (e) {
                console.log(e);
            }
        })
    }

    async function openHandler () {
        setOpen(true)
        setEdit(false)
    }

    const CRUDTables = {
        standart: CRUDTableStandart,
        query: CRUDTableQuery,
    }

    const CRUDTable = CRUDTables[type]


    return (
        <>
            <CRUDTable search={personCRUD.search}
                            columns={columns}
                            searchFields={searchFields}
                            onClickEditButton={props.edit ? editHandler : null}
                            onClickDeleteButton={props.delete ? deleteHandler : null}
                            onClickCreateButton={openHandler}
                            reloadValue={reloadValue}
                            {...props}
            />
            <PopupPerson edit={edit} id={id} setId={setId} open={open} setOpen={setOpen} confirmHandler={confirmHandler}/>
        </>
    );
}
