import {DEAL_STATE_ADD_SUCCESS, DEAL_STATE_ADD_REQUEST, DEAL_STATE_ADD_ERROR, DEAL_STATE_DELETE_REQUEST, DEAL_STATE_DELETE_SUCCESS
, DEAL_STATE_EDIT_ERROR, DEAL_STATE_EDIT_REQUEST, DEAL_STATE_EDIT_SUCCESS, DEAL_STATE_GET_BY_KEY_ERROR, DEAL_STATE_GET_BY_KEY_REQUEST, DEAL_STATE_GET_BY_KEY_SUCCESS,
 DEAL_STATE_GET_ERROR,  DEAL_STATE_GET_REQUEST, DEAL_STATE_GET_SUCCESS, DEAL_STATE_DELETE_ERROR} from "../../constants/deal/dealStateConstants";

const initialState = {
    dealStates: [],
    dealState: {},
    loading: false,
    success: null,
    error: null,
    dealStateRequestedCount: 0,
};

export const dealStateReducer = (state = initialState, action) => {
    switch (action.type)     {
        case DEAL_STATE_GET_REQUEST:
            return {
                ...state,
                dealStateRequestedCount: ++state.dealStateRequestedCount,
                loading: true,
            };
        case DEAL_STATE_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                dealStates: action.payload,
                success: true,
            };
        case DEAL_STATE_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case DEAL_STATE_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DEAL_STATE_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                dealState: action.payload,
                success: true,
            };
        case DEAL_STATE_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case DEAL_STATE_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DEAL_STATE_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case DEAL_STATE_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case DEAL_STATE_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DEAL_STATE_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case DEAL_STATE_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case DEAL_STATE_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DEAL_STATE_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case DEAL_STATE_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
