import * as React from 'react';
import {styled, createTheme, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Avatar from "@mui/material/Avatar";
import {MainListItems} from "../components";
import {stringAvatar} from "../functions";
import {useDispatch, useSelector} from "react-redux";
import {SIDEBAR_CLOSE, SIDEBAR_OPEN} from "../redux/constants/genericConstants";
import SearchIcon from '@mui/icons-material/Search';
import {FormControlLabel, FormGroup, Input, ListItemIcon, Menu, MenuItem, Stack, Switch} from "@mui/material";
import {makeStyles} from "@mui/styles";
import Logout from '@mui/icons-material/Logout';
import {GENERAL_ROUTE, PERSONAL_AREA_ROUTE, PROFILE_ROUTE} from "../routes/const";
import {useHistory, useLocation} from "react-router-dom";
import {logout} from "../redux/actions/userActions";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Button from "@mui/material/Button";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import {useCallback, useEffect, useMemo, useState} from "react";
import ConfirmDialog from "../components/Popups/message/PopupDelete";
import {getAuthorizedEmployee} from "../redux/actions/employee/employeeActions";
import {mapRole} from "../functions/checkRoles";
import {TOGGLE_LANG} from "../redux/constants/langConstants";
import {$authHost} from "../http";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));


const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

const mdTheme = createTheme({
    palette: {
        primary: {
            main: '#3f51b5',
            contrastText: 'white',
        },
    },
    components: {
        MuiTextField: {
            defaultProps: {
                value: ""
            }
        }
    }
});

const useStyles = makeStyles(theme => ({
    search: {
        backgroundColor: 'rgba(255,255,255, 0.1)',
        borderRadius: 4,
        flexBasis: 300,
        height: 36,
        padding: '0 10px',
        display: 'flex',
        alignItems: 'center'
    },
    searchIcon: {
        marginRight: '10px',
        color: 'inherit'
    },
    searchInput: {
        flexGrow: 1,
        color: 'inherit',
        '& input::placeholder': {
            opacity: 1,
            color: 'inherit'
        }
    },
}));


const DashboardLayout = ({children}) => {
    const authorizedEmployee = useSelector(state => state.employee.authorizedEmployee)

    const userRole = useMemo(()=>{
        return JSON.parse(localStorage.getItem("roles")) || []
    }, [authorizedEmployee.id])
    const dispatch = useDispatch()
    const {pathname} = useLocation()
    const [userLogin, setUserLogin] = useState("Отсутствует")
    const {langEditor} = useSelector((state) => state.lang);
    const {basket} = useSelector((state) => state.basket)
    const history = useHistory()
    const {open} = useSelector((state) => state.generic);

    const toggleDrawer = () => {
        if (open)
            dispatch({type: SIDEBAR_CLOSE});
        else
            dispatch({type: SIDEBAR_OPEN});
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const openEl = Boolean(anchorEl);
    const fetchUser = useCallback(() => {
        dispatch(getAuthorizedEmployee()).then((resp) => {
            const {userName} = JSON.parse(localStorage.getItem('userInfo'))
            setUserLogin(userName)
        })
    }, [dispatch])
    useEffect(fetchUser, [fetchUser])
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const MainLists = useCallback(
        () => {
            return (
                <List><MainListItems/></List>
            )
        }, [])

    const handleChangeLangEditor = (event) => {
        if (event.target.checked === true) {
            localStorage.setItem('langEditor', 'kz')
            dispatch({type: TOGGLE_LANG, payload: 'kz'})
        } else {
            localStorage.setItem('langEditor', 'ru')
            dispatch({type: TOGGLE_LANG, payload: 'ru'})
        }
    }

    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{
                display: 'flex', backgroundColor: '#f5f5f5'
            }}>
                <CssBaseline/>
                <AppBar position="absolute" open={open}>
                    <Toolbar
                        sx={{
                            pr: '24px',
                            overflowX: 'auto'
                        }}
                    >
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer}
                            sx={{
                                marginRight: '36px',
                                ...(open && {display: 'none'}),
                            }}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Box sx={{display: 'flex', alignItems: 'center', flexGrow: 1}}>
                            <Avatar src="/images/logo.svg"/>
                            <Typography
                                component="span"
                                color="inherit"
                                sx={{
                                    marginLeft: '10px',
                                    textTransform: 'uppercase',
                                    fontSize: '14px',
                                    maxWidth: '250px'
                                }}
                            >
                                Национальный центр экспертизы
                            </Typography>
                            <Typography
                                noWrap
                                title={authorizedEmployee.fullName || ""}
                                component="span"
                                color="inherit"
                                sx={{
                                    marginLeft: "10px",
                                    textTransform: "uppercase",
                                    fontSize: "12px",
                                    maxWidth: "250px",
                                    textOverflow: "ellipsis"

                                }}
                            >
                                ФИО: <br/>
                                {authorizedEmployee.fullName || ""}
                            </Typography>
                            <Typography
                                noWrap
                                component="span"
                                title={userLogin}
                                color="inherit"
                                sx={{
                                    marginLeft: "30px",
                                    textTransform: "uppercase",
                                    fontSize: "12px",
                                    maxWidth: "250px",
                                    textOverflow: "ellipsis"

                                }}

                            >Должность: <br/>
                                {authorizedEmployee.position?.name || ""}
                            </Typography>
                            <Typography
                                noWrap
                                component="span"
                                title={authorizedEmployee.filial?.name || ""}
                                color="inherit"
                                sx={{
                                    marginLeft: "30px",
                                    textTransform: "uppercase",
                                    fontSize: "12px",
                                    maxWidth: "250px",
                                    textOverflow: "ellipsis"
                                }}
                            >
                                Филиал: <br/>
                                {authorizedEmployee.filial?.name || ""}
                            </Typography>
                            <Typography
                                noWrap
                                component="span"
                                title={authorizedEmployee.filial?.name || ""}
                                color="inherit"
                                sx={{
                                    marginLeft: "30px",
                                    textTransform: "uppercase",
                                    fontSize: "12px",
                                    maxWidth: "250px",
                                    textOverflow: "ellipsis"
                                }}
                            >
                                Районное отделение: <br/>
                                {authorizedEmployee.filialDivision?.name || ""}
                            </Typography>
                            <Typography
                                noWrap
                                component="span"
                                color="inherit"
                                title={authorizedEmployee.division?.name || "Не указано"}
                                sx={{
                                    marginLeft: "30px",
                                    textTransform: "uppercase",
                                    fontSize: "12px",
                                    maxWidth: "250px",
                                    textOverflow: "ellipsis"

                                }}
                            >Роли:<br/>
                                {userRole && userRole.map(el=> mapRole(el)).join(', ')}
                            </Typography>
                        </Box>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            {pathname.includes('general') &&
                            <IconButton onClick={() => history.push(GENERAL_ROUTE + '/basket')}
                                        sx={{marginRight: '10px'}} color="inherit">
                                <Badge badgeContent={basket.length ? basket.length : 0} color="info">
                                    <ShoppingCartIcon/>
                                </Badge>
                            </IconButton>}
                            {/*<Stack sx={{ml: 2, mr: 2}} direction="row" spacing={1} alignItems="center">*/}
                            {/*    <Typography noWrap component="span" color="inherit">Версия редактора</Typography>*/}
                            {/*    <Typography noWrap component="span" color="inherit">RU</Typography>*/}
                            {/*    <Switch onChange={handleChangeLangEditor} checked={langEditor === 'kz'} color="default" defaultChecked />*/}
                            {/*    <Typography noWrap component="span" color="inherit">KZ</Typography>*/}
                            {/*</Stack>*/}
                            <IconButton onClick={handleClick}>
                                <Avatar {...stringAvatar(Object.keys(authorizedEmployee)?.length ? authorizedEmployee?.fullName : "John Doe")} />
                            </IconButton>
                        </Box>
                        <Menu
                            anchorEl={anchorEl}
                            open={openEl}
                            onClose={handleClose}
                            onClick={handleClose}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{horizontal: 'right', vertical: 'top'}}
                            anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                        >
                            <MenuItem onClick={() => history.push(PROFILE_ROUTE)}>
                                <Avatar/> Профиль
                            </MenuItem>
                            <Divider/>
                            <MenuItem onClick={() => dispatch(logout())}>
                                <ListItemIcon>
                                    <Logout fontSize="small"/>
                                </ListItemIcon>
                                Выйти
                            </MenuItem>
                        </Menu>
                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open}>
                    <Toolbar
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            px: [1],
                        }}
                    >
                        <Typography
                            component="span"
                            color="inherit"
                            sx={{
                                marginLeft: '10px',
                                fontSize: '18px',
                            }}
                        >
                            Реестр
                        </Typography>
                        <IconButton onClick={toggleDrawer}>
                            <ChevronLeftIcon/>
                        </IconButton>
                    </Toolbar>
                    <Divider/>
                    <MainLists/>
                </Drawer>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar/>
                    <Container maxWidth={false} sx={{mt: 4, mb: 2}}>
                        {children}
                    </Container>
                </Box>
                <ConfirmDialog/>
            </Box>
        </ThemeProvider>
    );
};

export default DashboardLayout;
