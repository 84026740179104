import { $authHost } from "../../http";
import {
    GEO_OBJECT_GET_ERROR,
    GEO_OBJECT_GET_REQUEST,
    GEO_OBJECT_GET_SUCCESS, GEO_REGION_OBJECT_GET_ERROR, GEO_REGION_OBJECT_GET_REQUEST,
    GEO_REGION_OBJECT_GET_SUCCESS
} from "../constants/geoObjectConstants";

export const getGeoObjects = (args) => async (dispatch) => {
    try {
        dispatch({
            type: GEO_OBJECT_GET_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        const {data} = await $authHost.post(
            "/api/v1/geoObject/query",
            args || {},
            config,
        );
        dispatch({
            type: GEO_OBJECT_GET_SUCCESS,
            payload: data.result,
        });
        return data.result;
    } catch (error) {
        dispatch({
            type: GEO_OBJECT_GET_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const getRegionGeoObjects = (args) => async (dispatch) => {
    try {
        dispatch({
            type: GEO_REGION_OBJECT_GET_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        const {data} = await $authHost.post(
            "/api/v1/geoObject/query",
            args || {},
            config,
        );
        dispatch({
            type: GEO_REGION_OBJECT_GET_SUCCESS,
            payload: data.result,
        });
        return data.result;
    } catch (error) {
        dispatch({
            type: GEO_REGION_OBJECT_GET_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

