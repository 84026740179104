import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {Link} from "react-router-dom";
import {createContext, useState} from "react";
import SignUpPerson from "./SignUpPerson";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import {TabContext, TabPanel} from "@mui/lab";
import SignUpLegalPerson from "./SignUpLegalPerson";

const theme = createTheme();

export const SignUpContext = createContext();

const SignUp = () => {
    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth={false}>
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{m: 5, bgcolor: 'secondary.main'}} src="/images/logo.svg"/>
                    <Typography component="h1" variant="h5">
                        Регистрация
                    </Typography>

                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
                                <TabList sx={{width: '100%'}} onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label="Для физических лиц" value="1" />
                                    <Tab label="Для юридических лиц" value="2" />
                                </TabList>
                            </Box>
                            <TabPanel value="1">
                                    <SignUpPerson/>
                            </TabPanel>
                            <TabPanel value="2">
                                <SignUpLegalPerson/>
                            </TabPanel>
                        </TabContext>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Link to="/sign-in" variant="body2">
                                    Уже есть аккаунт? Войти
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
            </Container>
        </ThemeProvider>
    );
};

export default SignUp;