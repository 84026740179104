import {$authHost} from "../../../http";
import {
    COMPLETION_ACT_ADD_ERROR,
    COMPLETION_ACT_ADD_REQUEST,
    COMPLETION_ACT_ADD_SUCCESS,
    COMPLETION_ACT_GET_ERROR,
    COMPLETION_ACT_GET_REQUEST,
    COMPLETION_ACT_GET_SUCCESS,
    COMPLETION_ACT_ONE_GET_ERROR,
    COMPLETION_ACT_ONE_GET_REQUEST,
    COMPLETION_ACT_ONE_GET_SUCCESS,
    INVOICE_ADD_ERROR,
    INVOICE_ADD_REQUEST,
    INVOICE_ADD_SUCCESS,
    INVOICE_GET_ERROR,
    INVOICE_GET_REQUEST,
    INVOICE_GET_SUCCESS,
    INVOICE_ONE_GET_ERROR,
    INVOICE_ONE_GET_REQUEST,
    INVOICE_ONE_GET_SUCCESS, PAYMENT_GET_ERROR,
    PAYMENT_GET_REQUEST,
    PAYMENT_GET_SUCCESS
} from "../../constants/bookkeeping/bookkeepingConstants";
import {LOADING_FALSE, LOADING_TRUE} from "../../constants/genericConstants";
import {getData, getOneData} from "../template/get";

export const getCompletionAct = (page, size, args, nonGlobal) => async (dispatch) => {
    dispatch({type: LOADING_TRUE})
    const completionAct = await dispatch(getData(COMPLETION_ACT_GET_REQUEST, COMPLETION_ACT_GET_SUCCESS, COMPLETION_ACT_GET_ERROR, 'completionAct', true, page, null, null, true, size, args, nonGlobal))
    dispatch({type: LOADING_FALSE})
    return completionAct
};

export const getInvoice = (page, size, args, nonGlobal) => async (dispatch) => {
    dispatch({type: LOADING_TRUE})
    const completionAct = await dispatch(getData(INVOICE_GET_REQUEST, INVOICE_GET_SUCCESS, INVOICE_GET_ERROR, 'invoice', true, page, null, null, true, size, args, nonGlobal))
    dispatch({type: LOADING_FALSE})
    return completionAct
};

export const getPayments = (page, size, args, nonGlobal) => async (dispatch) => {
    dispatch({type: LOADING_TRUE})
    const payment = await dispatch(getData(PAYMENT_GET_REQUEST, PAYMENT_GET_SUCCESS, PAYMENT_GET_ERROR, 'payment', true, page, null, null, true, size, args, nonGlobal))
    dispatch({type: LOADING_FALSE})
    return payment
};

export const getOneInvoice = (id) => async (dispatch) => {
    dispatch({type: LOADING_TRUE})
    const oneContract = await dispatch(getOneData(id, INVOICE_ONE_GET_REQUEST, INVOICE_ONE_GET_SUCCESS, INVOICE_ONE_GET_ERROR, 'invoice'))
    dispatch({type: LOADING_FALSE})
    return oneContract
};



export const getOneCompletionAct = (id) => async (dispatch) => {
    dispatch({type: LOADING_TRUE})
    const oneContract = await dispatch(getOneData(id, COMPLETION_ACT_ONE_GET_REQUEST, COMPLETION_ACT_ONE_GET_SUCCESS, COMPLETION_ACT_ONE_GET_ERROR, 'completionAct'))
    dispatch({type: LOADING_FALSE})
    return oneContract
};

export const changeCompletionActState = (id, oldStateId, newStateId, page, rowsPerPage, filter) => async (dispatch) => {
    try {
        dispatch({
            type: COMPLETION_ACT_ADD_REQUEST,
        });
        await $authHost.post(
            "/api/v1/completionAct/workflow/run",
            {id, oldStateId, newStateId}
        )

        dispatch({
            type: COMPLETION_ACT_ADD_SUCCESS
        });
        dispatch(getCompletionAct(page + 1, rowsPerPage, filter))
    } catch (error) {
        dispatch({
            type: COMPLETION_ACT_ADD_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};


export const addCompletionAct = (number,
                                 startDate,
                                 customerIdentity,
                                 customerName,
                                 customerAddressLine,
                                 customerContactLine,
                                 performerIdentity,
                                 performerName,
                                 performerAddressLine,
                                 performerContactLine,
                                 customerStockUsageDescription,
                                 application,
                                 signPerfomerPosition,
                                 signPerfomerFullName,
                                 signCustomerPosition,
                                 signCustomerFullName,
                                 signedDateTime,
                                 customerId,
                                 filialId,
                                 signPerformerId,
                                 specification,
                                 contractId, nameFilter) => async (dispatch) => {
    try {
        dispatch({
            type: COMPLETION_ACT_ADD_REQUEST,
        });
        const {data: dataClient} = await $authHost.post(
            "/api/v1/client/query",
            {
                filter: {
                    [nameFilter]: {
                        operand1: customerId,
                        operator: "equals"
                    },
                }
            }
        )
        await $authHost.post(
            "/api/v1/completionAct",
            {
                createdAt: new Date(), number,
                startDate,
                customerIdentity,
                customerName,
                customerAddressLine,
                customerContactLine,
                performerIdentity,
                performerName,
                performerAddressLine,
                performerContactLine,
                customerStockUsageDescription,
                application,
                specifications: specification,
                signPerfomerPosition,
                signPerfomerFullName,
                signCustomerPosition,
                signCustomerFullName,
                signedDateTime,
                customerId: dataClient?.result[0]?.id,
                performerId: filialId,
                signPerformerId,
                contractId, stateId: 1
            }
        );
        dispatch({
            type: COMPLETION_ACT_ADD_SUCCESS
        });
    } catch (error) {
        dispatch({
            type: COMPLETION_ACT_ADD_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
}

export const changeInvoiceState = (id, oldStateId, newStateId, page, rowsPerPage, filter) => async (dispatch) => {
    try {
        dispatch({
            type: INVOICE_ADD_REQUEST,
        });
        await $authHost.post(
            "/api/v1/invoice/workflow/run",
            {id, oldStateId, newStateId}
        )

        dispatch({
            type: INVOICE_ADD_SUCCESS
        });
        dispatch(getInvoice(page + 1, rowsPerPage, filter))
    } catch (error) {
        dispatch({
            type: INVOICE_ADD_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const addInvoice = (number,
                           beneficiaryIdentity,
                           beneficiaryName,
                           beneficiaryAccountNumber,
                           recipientCode,
                           beneficiaryBank,
                           beneficiaryBankCode,
                           paymentPurposeCode,
                           supplierIdentity,
                           supplierName,
                           buyerIdentity,
                           buyerName,
                           performerName,
                           beneficiaryId,
                           specification,
                           contractId, nameFilter) => async (dispatch) => {
    try {
        dispatch({
            type: INVOICE_ADD_REQUEST,
        });
        const {data: dataClient} = await $authHost.post(
            "/api/v1/client/query",
            {
                filter: {
                    [nameFilter]: {
                        operand1: beneficiaryId,
                        operator: "equals"
                    },
                }
            }
        )
        await $authHost.post(
            "/api/v1/invoice",
            {
                number,
                createdAt: new Date(),
                beneficiaryId: dataClient?.result[0]?.id,
                beneficiaryIdentity,
                beneficiaryName,
                beneficiaryAccountNumber,
                recipientCode,
                beneficiaryBank,
                beneficiaryBankCode,
                paymentPurposeCode,
                supplierIdentity,
                supplierName,
                specifications: specification,
                buyerIdentity,
                buyerName,
                performerName,
                contractId, stateId: 1
            }
        );
        dispatch({
            type: INVOICE_ADD_SUCCESS
        });
    } catch (error) {
        dispatch({
            type: INVOICE_ADD_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
}

export const editInvoice = (id, number,
                           beneficiaryIdentity,
                           beneficiaryName,
                           beneficiaryAccountNumber,
                           recipientCode,
                           beneficiaryBank,
                           beneficiaryBankCode,
                           paymentPurposeCode,
                           supplierIdentity,
                           supplierName,
                           buyerIdentity,
                           buyerName,
                           performerName,
                           beneficiaryId,
                           specification,
                           contractId, nameFilter) => async (dispatch) => {
    try {
        dispatch({
            type: INVOICE_ADD_REQUEST,
        });
        const {data: dataClient} = await $authHost.post(
            "/api/v1/client/query",
            {
                filter: {
                    [nameFilter]: {
                        operand1: beneficiaryId,
                        operator: "equals"
                    },
                }
            }
        )
        await $authHost.put(
            "/api/v1/invoice",
            {
                id,
                number,
                createdAt: new Date(),
                beneficiaryId: dataClient?.result[0]?.id,
                beneficiaryIdentity,
                beneficiaryName,
                beneficiaryAccountNumber,
                recipientCode,
                beneficiaryBank,
                beneficiaryBankCode,
                paymentPurposeCode,
                supplierIdentity,
                supplierName,
                specifications: specification,
                buyerIdentity,
                buyerName,
                performerName,
                contractId, stateId: 1
            }
        );
        dispatch({
            type: INVOICE_ADD_SUCCESS
        });
    } catch (error) {
        dispatch({
            type: INVOICE_ADD_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
}


export const editCompletionAct = (id, number,
                                  startDate,
                                  customerIdentity,
                                  customerName,
                                  customerAddressLine,
                                  customerContactLine,
                                  performerIdentity,
                                  performerName,
                                  performerAddressLine,
                                  performerContactLine,
                                  customerStockUsageDescription,
                                  application,
                                  signPerfomerPosition,
                                  signPerfomerFullName,
                                  signCustomerPosition,
                                  signCustomerFullName,
                                  signedDateTime,
                                  customerId,
                                  filialId,
                                  signPerformerId,
                                  specification,
                                  contractId, nameFilter) => async (dispatch) => {
    try {
        dispatch({
            type: COMPLETION_ACT_ADD_REQUEST,
        });
        const {data: dataClient} = await $authHost.post(
            "/api/v1/client/query",
            {
                filter: {
                    [nameFilter]: {
                        operand1: customerId,
                        operator: "equals"
                    },
                }
            }
        )
        await $authHost.put(
            "/api/v1/completionAct",
            {
                id,
                createdAt: new Date(), number,
                startDate,
                customerIdentity,
                customerName,
                customerAddressLine,
                customerContactLine,
                performerIdentity,
                performerName,
                performerAddressLine,
                performerContactLine,
                customerStockUsageDescription,
                application,
                specifications: specification,
                signPerfomerPosition,
                signPerfomerFullName,
                signCustomerPosition,
                signCustomerFullName,
                signedDateTime,
                customerId: dataClient?.result[0]?.id,
                performerId: filialId,
                signPerformerId,
                contractId, stateId: 1
            }
        );
        dispatch({
            type: COMPLETION_ACT_ADD_SUCCESS
        });
    } catch (error) {
        dispatch({
            type: COMPLETION_ACT_ADD_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
}