import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextShared from "../../components/Shared/TextShared";
import NumberFormatShared from "../../components/Shared/NumberFormatShared";
import {DatePickerShared, SelectShared} from "../../components";
import {useDispatch, useSelector} from "react-redux";
import {getGenderAll} from "../../redux/actions/genericActions";
import {getGeoObject} from "../../redux/actions/geo/geoActions";
import {LoadingButton} from "@mui/lab";
import {showSuccessSnackbar} from "../../redux/actions/userActions";
import {updateProfile} from "../../redux/actions/profile/profileActions";

function PersonalInformation({legalPerson}) {
    const dispatch = useDispatch();
    const {profile} = useSelector((state) => state.profile);
    const [lastName, setLastName] = useState('')
    const [firstName, setFirstName] = useState('')
    const [middleName, setMiddleName] = useState('')
    const [bin, setBin] = useState({
        formattedValue: '',
        value: ''
    })
    const [number, setNumber] = useState({
        formattedValue: '',
        value: ''
    })
    const [email, setEmail] = useState('')
    const [opfs, setOpfs] = useState([
        {name: 'РГУ'},
        {name: "ПТ"},
        {name: 'РГП'},
        {name: 'РГП на ПХВ'},
        {name: 'КГП на ПХВ'},
        {name: 'ГККП'},
        {name: 'ГКП на ПХВ'},
        {name: 'ГУ'},
        {name: 'КГКП'},
        {name: 'ЧУ'},
        {name: 'АО'},
        {name: 'ОО'},
        {name: 'НАО'},
        {name: 'ТОО'},
        {name: 'КХ'},
        {name: 'ПК'},
        {name: 'КГУ'},
        {name: 'ИП'}
    ])
    useEffect(() => {
        setOpfs(prev => prev.map((el, idx) => ({id: idx, name: el.name})))
    }, [])
    const [opf, setOpf] = useState(opfs[0]?.id)
    const [loadingEdit, setLoadingEdit] = useState(false)

    const [name, setName] = useState('')
    const [supervisorName, setSupervisorName] = useState('')
    const [documentNumber, setDocumentNumber] = useState('')
    const [documentDate, setDocumentDate] = useState(Date.now())
    const [bankName, setBankName] = useState('')
    const [bankBik, setBankBik] = useState('')
    const [bankKBE, setBaknKBE] = useState('')
    const [bankAccountNumber, setBankAccountNumber] = useState('')
    const [workPhone, setWorkPhone] = useState({
        formattedValue: '',
        value: ''
    })

    const [district, setDistrict] = useState(1)
    const [city, setCity] = useState(1)
    const [locality, setLocality] = useState(1)

    const [cities, setCities] = useState([])
    const [districts, setDistricts] = useState([])
    const [settlements, setSettlements] = useState([])
    const [addressLine, setAddressLine] = useState([])
    useEffect(() => {
        dispatch(getGenderAll())
    }, [dispatch])

    useEffect(() => {
        if (legalPerson) {
            setSupervisorName(legalPerson?.supervisorName)
            setAddressLine(legalPerson?.addressLine)
            setDistrict(legalPerson?.addressId)
            setBankAccountNumber(legalPerson?.bankAccountNumber)
            setBankBik(legalPerson?.bankBik)
            setBankName(legalPerson?.bankName)
            setBin({formattedValue: '', value: legalPerson?.bin})
            setEmail(legalPerson?.email)
            setNumber({formattedValue: '',
                value: legalPerson?.phoneNumber})
            setName(legalPerson?.name)
        }
    }, [legalPerson])

    const handleSubmit = (event) => {
        event.preventDefault()
        setLoadingEdit(true)
        const legalPersonNew = {
            id: legalPerson?.id,
            name: name,
            bin: bin.value,
            cityLine: addressLine,
            bankName: bankName,
            bankBik: bankBik,
            regionLine: addressLine,
            addressLine: addressLine,
            phoneNumber: number.value,
            email: email,
            bankAccountNumber: bankAccountNumber,
            supervisorName: supervisorName,
        }
        dispatch(updateProfile(profile?.client?.id, profile?.client?.legalPersonId, profile?.client?.personId, profile?.client?.userId, {}, legalPersonNew))
        setLoadingEdit(false)
        dispatch(showSuccessSnackbar("Профиль обновлён!", "success"));
    }
    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextShared value={lastName} setValue={setLastName} required label="Фамилия"/>
                </Grid>
                <Grid item xs={6}>
                    <TextShared value={firstName} setValue={setFirstName} required label="Имя"/>
                </Grid>
                <Grid item xs={6}>
                    <TextShared value={middleName} setValue={setMiddleName} required label="Отчество"/>
                </Grid>
                <Grid item xs={6}>
                    <NumberFormatShared format="############" mask="_" placeholder="Бин" value={bin}
                                        setValues={setBin}/>
                </Grid>
                <Grid item xs={6}>
                    <SelectShared array={opfs} required label="Организационно-правовая форма" value={opf}
                                  setValue={setOpf}/>
                </Grid>
                <Grid item xs={6}>
                    <TextShared value={name} setValue={setName} required label="Наименование организации"/>
                </Grid>
                <Grid item xs={6}>
                    <TextShared value={supervisorName} setValue={setSupervisorName} required
                                label="Фамилия Имя Отчество Директора"/>
                </Grid>
                <Grid item xs={6}>
                    <TextShared value={documentNumber} setValue={setDocumentNumber} required label="Номер документа"/>
                </Grid>
                <Grid item xs={6}>
                    <DatePickerShared value={documentDate} setValue={setDocumentDate} label="Дата документа" fullWidth/>
                </Grid>
                <Grid item xs={6}>
                    <TextShared value={bankName} setValue={setBankName} required label="Название банкаы"/>
                </Grid>
                <Grid item xs={6}>
                    <TextShared value={bankBik} setValue={setBankBik} required label="БИК"/>
                </Grid>
                <Grid item xs={6}>
                    <TextShared value={bankKBE} setValue={setBaknKBE} required label="КБЕ"/>
                </Grid>
                <Grid item xs={6}>
                    <TextShared value={bankAccountNumber} setValue={setBankAccountNumber} required label="ИИК"/>
                </Grid>
                <Grid item xs={6}>
                    <TextShared value={addressLine} setValue={setAddressLine} required label="Юридический адрес"/>
                </Grid>
                <Grid item xs={6}>
                    <NumberFormatShared format="+7 (###) ###-##-##" mask="_" placeholder="Рабочий телефон"
                                        value={workPhone} setValues={setWorkPhone}/>
                </Grid>
                <Grid item xs={6}>
                    <NumberFormatShared format="+7 (###) ###-##-##" mask="_" placeholder="Мобильный телефон"
                                        value={number} setValues={setNumber}/>
                </Grid>
                <Grid item xs={6}>
                    <TextShared value={email} setValue={setEmail} required label="Email" type="email"/>
                </Grid>
            </Grid>
            <LoadingButton
                loading={loadingEdit}
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
            >
                Сохранить
            </LoadingButton>
        </Box>

    );
}

export default PersonalInformation;