import {
    CONTRACT_ADDITIONAL_ADD_ERROR,
    CONTRACT_ADDITIONAL_ADD_REQUEST,
    CONTRACT_ADDITIONAL_ADD_SUCCESS
} from "../../constants/contractAdditional/contractAdditionalConstants";

const initialState = {
    loading: false,
    success: null,
    error: null
};

export const contractAdditionalReducer = (state = initialState, action) => {
    switch (action.type) {
        case CONTRACT_ADDITIONAL_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CONTRACT_ADDITIONAL_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case CONTRACT_ADDITIONAL_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        default:
            return state;
    }
}