import React, {useContext} from 'react'
import {Box, Checkbox, FormControlLabel, FormGroup, Grid, MenuItem, Stack, Switch} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {DatePickerShared, SelectFieldModal, SelectShared} from "../../../../components";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import {ContractContext} from "../../ContractPage";
import {ContractRequestObject, EducationServicesTableContractRequest} from "../../../index";
import ResearchSchedule from "../../../../components/Contract/ResearchSchedule";
import {getContract} from "../../../../redux/actions/contract/contractActions";
import {contractModalRowName} from "../../../../lang/i18n";
import TextShared from "../../../../components/Shared/TextShared";
import useCreateObjectForm from '../../../../components/Crud/Models/useCreateObjectForm';
import LegalClientField from "../../../../components/Fields/LegalClientField";
import PersonField from "../../../../components/Fields/PersonField";
import ContractFilials from "../../../../components/Contract/ContractFilials";

const AddDateEditPage = ({
                             typeContract,
                             setLegalClient,
                             legalClient,
                             setTypeContract,
                             steps,
                             handleBack,
                             activeStep,
                             handleForm,
                             name,
                             setName,
                             number,
                             setNumber,
                             registeredAt,
                             setRegisteredAt,
                             actionAt,
                             setActionAt,
                             client,
                             type,
                             contractType,
                             setClient,
                             setType,
                             filial,
                             filialDivision,
                             localFilialDivision,
                             localFilial,
                             setLocalFilialDivision,
                             setLocalFilial,
                         }) => {
    const {t} = useTranslation();
    const {
        data,
        contracts,
        contractsCount,
        isAdditional,
        contractId,
        setContractId,
        contractObject,
        setContractObject,
        handleSchedules,
        district,
        handleContractObjectRequest,
        setEducationServices,
        educationServiceKind,
        localEducationServiceKind,
        educationServices,
        handleEducationServices,
        data2,
        handleChangeObject
    } = useContext(ContractContext)

    const { openCreateObjectForm, DIALOG } = useCreateObjectForm();

    return (
        <Box component="form" onSubmit={handleForm}
             sx={{mt: 1, display: 'flex', padding: '0px 10px', flexWrap: 'wrap'}}>
                 <DIALOG />
            <Grid container spacing={2}>
                {isAdditional && <Grid item xs={12}>
                    <SelectFieldModal disabled edit={true} func={getContract} nameFilter="name"
                                      slice={{first: 0, second: 5}} args={{
                        isAdditionalAgreement: {
                            operand1: false,
                            operator: "equals"
                        }
                    }}
                                      rowName={contractModalRowName}
                                      reduxCount={contractsCount}
                                      reduxValue={contracts.map(el => ({id: el.id, name: el.name}))}
                                      label="Договор" value={contractId} setValue={setContractId}/>
                </Grid>}
                <Grid item xs={6}>
                    <TextField
                        margin="normal"
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        fullWidth
                        id="name"
                        name="name"
                        label="Название"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextShared value={number} setValue={setNumber} label="Номер"/>
                </Grid>
                {type !== 5 && <>  <Grid item xs={6}>
                    <SelectShared value={localFilial} setValue={setLocalFilial} label="Филиал" array={filial}/>
                </Grid>
                <Grid item xs={6}>
                    <SelectShared value={localFilialDivision} required={false} setValue={setLocalFilialDivision}
                                  label="Районное отделение" array={filialDivision && filialDivision.filter(el => el.filialId === localFilial)}/>
                </Grid></>}
                <Grid item xs={6}>
                    <DatePickerShared setValue={setRegisteredAt} value={registeredAt} label="Дата регистрации"
                                      fullWidth/>
                </Grid>
                <Grid item xs={6}>
                    <DatePickerShared setValue={setActionAt} value={actionAt} label="Дата действия" fullWidth/>
                </Grid>
                <Grid item xs={6}>
                    <FormGroup sx={{display: 'flex', justifyContent: 'center'}}>
                        <Typography>Тип договора</Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography>с юр. лицом</Typography>
                            <Switch onChange={(event) => setTypeContract(event.target.checked)} checked={typeContract}
                                    inputProps={{'aria-label': 'ant design'}}/>
                            <Typography>с физ. лицом</Typography>
                        </Stack>
                    </FormGroup>
                </Grid>
                <Grid item xs={6}>
                    {typeContract ?
                        <PersonField
                            fullWidth
                            value={client}
                            object={data?.client?.person}
                            onChange={(client) => {
                                setClient(client.id)
                            }}
                        /> :
                        <LegalClientField
                            fullWidth
                            value={legalClient}
                            object={data?.client?.legalPerson}
                            onChange={(client) => {
                                setLegalClient(client.id)
                            }}
                        />}
                </Grid>
                <Grid item xs={6}>
                    <TextField required value={type} onChange={(e) => setType(e.target.value)}
                               margin="normal" fullWidth id="select" label="Тип договора" select>
                        {contractType && contractType.map(el => (
                            <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <Stack sx={{width: '100%', height: '100%'}} direction="row" alignItems="center">
                        <FormControlLabel  control={<Checkbox checked={data2?.isDiscount}
                                                              onChange={(event) =>
                                                                  handleChangeObject('isDiscount', event.target.checked)}/>}
                                           label="Скидка"/>
                        {data2?.isDiscount &&
                        <TextShared required={false} value={data2?.discountComment}
                                    setValue={(val) => handleChangeObject('discountComment', val)} label="Скидка (комментарий)"/>
                        }
                    </Stack>
                </Grid>
            </Grid>
            {type === 5 && <Box sx={{
                margin: '30px 0',
                width: '100%',
                borderTop: '1px solid rgba(224, 224, 224, 1)',
                borderBottom: '1px solid rgba(224, 224, 224, 1)'
            }}>
                <Typography sx={{marginTop: '5px'}} variant="h6" component="h3">Филиалы</Typography>
                <ContractFilials/>
            </Box>}
            {type !== 5 && <>  <Box sx={{
                margin: '30px 0',
                width: '100%',
                borderTop: '1px solid rgba(224, 224, 224, 1)',
                borderBottom: '1px solid rgba(224, 224, 224, 1)'
            }}>
                <Typography sx={{marginTop: '5px'}} variant="h6" component="h3">Вид обучения</Typography>
                <EducationServicesTableContractRequest edit setEducationServices={setEducationServices}
                                                       educationServiceKind={educationServiceKind}
                                                       localEducationServiceKind={localEducationServiceKind}
                                                       educationServices={educationServices}/>
                <Button onClick={handleEducationServices}>Добавить блок</Button>
            </Box>
            <Box sx={{
                margin: '30px 0',
                width: '100%',
                borderTop: '1px solid rgba(224, 224, 224, 1)',
                borderBottom: '1px solid rgba(224, 224, 224, 1)'
            }}>
                <Typography sx={{marginTop: '5px'}} variant="h6" component="h3">Объекты</Typography>
                <ContractRequestObject edit geo={district} contractRequestObject={contractObject}
                                       setContractRequestObject={setContractObject}/>
                <Button onClick={handleContractObjectRequest}>Добавить блок</Button>
                {contractObject.length ? <Button onClick={() => openCreateObjectForm()}>Создать объект</Button> : <></>}
            </Box>
            </>}
            <Box sx={{width: '100%', mt: 10}}>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Button
                            fullWidth
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            variant="contained"
                        >
                            Назад
                        </Button>
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                        >
                            {activeStep === steps.length - 1 ? 'Завершить' : 'Далее'}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default AddDateEditPage
