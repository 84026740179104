import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {getServicePackage} from "../../redux/actions/serviceCategory/serviceCategoryActions";
import {Collapse, ListItemButton} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import GeneralDrawerChild from "./GeneralDrawerChild";

function GeneralDrawerChildRow({el, goToPage, id}) {
    const [open, setOpen] = useState(false)
    const [packageChild, setPackageChild] = useState([])
    const dispatch = useDispatch()
    useEffect(() => {
        (async function () {
            const {result} = await dispatch(getServicePackage(1, 100, {
                filter: {
                    parentId: {
                        operand1: el.id,
                        operator: "equals"
                    }
                }
            }, true))
            setPackageChild(result)
        }())
    }, [dispatch, el.id])
    return (
        <React.Fragment key={el.id}>
            <ListItemButton selected={el.id === +id} sx={{pl: 4}}
                            onClick={packageChild.length ? () => setOpen(!open) : goToPage(el.id, '?package=true')}
            >
                <ListItemText secondary={
                    <Typography
                        component="span"
                        variant="body2"
                        color="text.primary"
                    >
                        {el.name}
                    </Typography>}/>
                {packageChild.length ? (open ? <ExpandLess/> : <ExpandMore/>) : null}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <GeneralDrawerChild id={id} goToPage={goToPage} packageParent={packageChild}/>
            </Collapse>
        </React.Fragment>
    );
}

export default GeneralDrawerChildRow;