import {$authHost} from "../../../http";
import {
    CONTRACT_ADDITIONAL_ADD_ERROR,
    CONTRACT_ADDITIONAL_ADD_REQUEST,
    CONTRACT_ADDITIONAL_ADD_SUCCESS
} from "../../constants/contractAdditional/contractAdditionalConstants";
import {v4 as uuidv4} from "uuid";
import {getData} from "../template/get";

export const addContractAdditional = (contractId, additionalAgreementId) => async (dispatch) => {
    try {
        dispatch({
            type: CONTRACT_ADDITIONAL_ADD_REQUEST,
        });
        await $authHost.post(
            "/api/v1/contractAdditionalAgreement",
            {contractId, number: uuidv4(), additionalAgreementId, isAdditionalAgreement: true}
        );
        dispatch({
            type: CONTRACT_ADDITIONAL_ADD_SUCCESS
        });
    } catch (error) {
        dispatch({
            type: CONTRACT_ADDITIONAL_ADD_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const editContractAdditional = (id, contractId, additionalAgreementId) => async (dispatch) => {
    try {
        dispatch({
            type: CONTRACT_ADDITIONAL_ADD_REQUEST,
        });
        await $authHost.put(
            "/api/v1/contractAdditionalAgreement",
            {id, contractId, number: uuidv4(), additionalAgreementId, isAdditionalAgreement: true}
        );
        dispatch({
            type: CONTRACT_ADDITIONAL_ADD_SUCCESS
        });
    } catch (error) {
        dispatch({
            type: CONTRACT_ADDITIONAL_ADD_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};


export const getContractAdditional = (page, size, args, nonGlobal) => async (dispatch) => {
    return await dispatch(getData('', '', '', 'contractAdditionalAgreement', true, page, null, null, false, size, args, nonGlobal))
};