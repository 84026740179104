import {$authHost} from "../../../http";
import {
    SERVICE_ADD_PRICE_ERROR,
    SERVICE_ADD_PRICE_REQUEST,
    SERVICE_ADD_PRICE_SUCCESS,
    SERVICE_DELETE_PRICE_ERROR,
    SERVICE_DELETE_PRICE_REQUEST,
    SERVICE_DELETE_PRICE_SUCCESS,
    SERVICE_EDIT_PRICE_ERROR,
    SERVICE_EDIT_PRICE_REQUEST,
    SERVICE_EDIT_PRICE_SUCCESS,
    SERVICE_GET_CHILD_ERROR,
    SERVICE_GET_CHILD_REQUEST,
    SERVICE_GET_CHILD_SUCCESS,
    SERVICE_GET_ERROR,
    SERVICE_GET_PRICE_ERROR,
    SERVICE_GET_PRICE_REQUEST,
    SERVICE_GET_PRICE_SUCCESS,
    SERVICE_GET_REQUEST,
    SERVICE_GET_SUCCESS,
    SERVICE_GROUP_GET_ERROR,
    SERVICE_GROUP_GET_REQUEST,
    SERVICE_GROUP_GET_SUCCESS, SERVICE_PACKAGE_GET_ERROR, SERVICE_PACKAGE_GET_REQUEST, SERVICE_PACKAGE_GET_SUCCESS,
    SERVICE_SCHEDULE_GET_ERROR,
    SERVICE_SCHEDULE_GET_REQUEST,
    SERVICE_SCHEDULE_GET_SUCCESS,
    SERVICE_SUB_GROUP_GET_ERROR,
    SERVICE_SUB_GROUP_GET_REQUEST,
    SERVICE_SUB_GROUP_GET_SUCCESS,
    SERVICE_TYPE_GET_ERROR,
    SERVICE_TYPE_GET_REQUEST,
    SERVICE_TYPE_GET_SUCCESS
} from "../../constants/service/serviceConstants";
import {getData, getDataWithoutChild} from "../template/get";
import {editData} from "../template/edit";
import {ADD_REQUEST_ERROR, ADD_REQUEST_REQUEST, ADD_REQUEST_SUCCESS} from "../../constants/request/requestConstants";
import {deleteData} from "../template/delete";

export const getService = (page, size, args, nonGlobal) => async (dispatch) => {
    return await dispatch(getData(SERVICE_GET_REQUEST, SERVICE_GET_SUCCESS, SERVICE_GET_ERROR, 'service', true, page, null, null, true, size, args, nonGlobal ))
};

export const getServicePackageElement = (page, size, args, nonGlobal) => async (dispatch) => {
    return await dispatch(getData(SERVICE_PACKAGE_GET_REQUEST, SERVICE_PACKAGE_GET_SUCCESS, SERVICE_PACKAGE_GET_ERROR, 'serviceToPackage', true, page, null, null, true, size, args, nonGlobal ))
};

export const getServicesSchedule = (page, size, args, nonGlobal) => async (dispatch) => {
    return await dispatch(getData(SERVICE_SCHEDULE_GET_REQUEST, SERVICE_SCHEDULE_GET_SUCCESS, SERVICE_SCHEDULE_GET_ERROR, 'serviceSchedule', page, null, null, null, true, size, args, nonGlobal))
};

export const getServiceChild = (page, id) => async (dispatch) => {
    return await dispatch(getDataWithoutChild(SERVICE_GET_CHILD_REQUEST, SERVICE_GET_CHILD_SUCCESS, SERVICE_GET_CHILD_ERROR, 'service', page, id))
};

export const getServiceType = () => async (dispatch) => {
    return await dispatch(getData(SERVICE_TYPE_GET_REQUEST, SERVICE_TYPE_GET_SUCCESS, SERVICE_TYPE_GET_ERROR, 'serviceType', true))
};

export const getServiceGroup = (value, name) => async (dispatch) => {
    return await dispatch(getData(SERVICE_GROUP_GET_REQUEST, SERVICE_GROUP_GET_SUCCESS, SERVICE_GROUP_GET_ERROR, 'serviceGroup', true, 1, value, name))
};

export const getServiceSubGroup = (value, name) => async (dispatch) => {
    return await dispatch(getData(SERVICE_SUB_GROUP_GET_REQUEST, SERVICE_SUB_GROUP_GET_SUCCESS, SERVICE_SUB_GROUP_GET_ERROR, 'serviceSubGroup', true, 1, value, name))
};

export const getServicePrice = (page) => async (dispatch) => {
    return await dispatch(getData(SERVICE_GET_PRICE_REQUEST, SERVICE_GET_PRICE_SUCCESS, SERVICE_GET_PRICE_ERROR, 'servicePriceList', true, page || 1, null, null, true))
};

export const editService = (id, name, code, kindId, typeId, unitId, groupId, subGroupId, parentId) => async (dispatch) => {
    return await dispatch(editData(ADD_REQUEST_REQUEST, ADD_REQUEST_SUCCESS, ADD_REQUEST_ERROR, 'service', getService(), {
        id,
        name,
        code,
        kindId,
        unitId,
        groupId,
        subGroupId,
        typeId,
        parentId: parentId ? parentId : '',
    }))
};

export const deleteService = (id) => async (dispatch) => {
    return await dispatch(deleteData(ADD_REQUEST_REQUEST, ADD_REQUEST_SUCCESS, ADD_REQUEST_ERROR, 'service', getService(), id))
};

export const addService = (name, code, kindId, typeId, unitId, groupId, subGroupId, parentId) => async (dispatch) => {
    try {
        await dispatch({
            type: ADD_REQUEST_REQUEST,
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.post(
            "/api/v1/service",
            {
                name,
                code,
                kindId,
                unitId,
                groupId,
                subGroupId,
                typeId,
                parentId: parentId ? parentId : '',
            },
            config
        );
        await dispatch(getService())
        await dispatch({
            type: ADD_REQUEST_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: ADD_REQUEST_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const addServicePrice = (name, price, unitId, currencyId, serviceId) => async (dispatch) => {
    try {
        await dispatch({
            type: SERVICE_ADD_PRICE_REQUEST,
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.post(
            "/api/v1/servicePriceList",
            {
                name,
                price,
                unitId,
                currencyId,
                serviceId,
            },
            config
        );
        await dispatch(getServicePrice())
        await dispatch({
            type: SERVICE_ADD_PRICE_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: SERVICE_ADD_PRICE_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const editServicePrice = (id, name, price, unitId, currencyId, serviceId) => async (dispatch) => {
    return await dispatch(editData(SERVICE_EDIT_PRICE_REQUEST, SERVICE_EDIT_PRICE_SUCCESS, SERVICE_EDIT_PRICE_ERROR, 'servicePriceList', getServicePrice(), {
        id,
        name,
        price,
        unitId,
        currencyId,
        serviceId
    }))
};

export const deleteServicePrice = (id) => async (dispatch) => {
    try {
        dispatch({
            type: SERVICE_DELETE_PRICE_REQUEST,
        });

        await $authHost.delete(
            "/api/v1/servicePriceList",
            {
                data: {
                    id
                }
            },
        );
        dispatch(getServicePrice())
        dispatch({
            type: SERVICE_DELETE_PRICE_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: SERVICE_DELETE_PRICE_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};