export const SERVICE_GET_REQUEST = 'SERVICE_GET_REQUEST'
export const SERVICE_GET_SUCCESS = 'SERVICE_GET_SUCCESS'
export const SERVICE_GET_ERROR = 'SERVICE_GET_ERROR'

export const SET_SERVICE = 'SET_SERVICE'

export const SERVICE_PACKAGE_GET_REQUEST = 'SERVICE_PACKAGE_GET_REQUEST'
export const SERVICE_PACKAGE_GET_SUCCESS = 'SERVICE_PACKAGE_GET_SUCCESS'
export const SERVICE_PACKAGE_GET_ERROR = 'SERVICE_PACKAGE_GET_ERROR'

export const SERVICE_SCHEDULE_GET_REQUEST = 'SERVICE_SCHEDULE_GET_REQUEST'
export const SERVICE_SCHEDULE_GET_SUCCESS = 'SERVICE_SCHEDULE_GET_SUCCESS'
export const SERVICE_SCHEDULE_GET_ERROR = 'SERVICE_SCHEDULE_GET_ERROR'

export const SERVICE_GET_CHILD_REQUEST = 'SERVICE_GET_CHILD_REQUEST'
export const SERVICE_GET_CHILD_SUCCESS = 'SERVICE_GET_CHILD_SUCCESS'
export const SERVICE_GET_CHILD_ERROR = 'SERVICE_GET_CHILD_ERROR'

export const SERVICE_ADD_REQUEST = 'SERVICE_ADD_REQUEST'
export const SERVICE_ADD_SUCCESS = 'SERVICE_ADD_SUCCESS'
export const SERVICE_ADD_ERROR = 'SERVICE_ADD_ERROR'

export const SERVICE_GET_PRICE_REQUEST = 'SERVICE_GET_PRICE_REQUEST'
export const SERVICE_GET_PRICE_SUCCESS = 'SERVICE_GET_PRICE_SUCCESS'
export const SERVICE_GET_PRICE_ERROR = 'SERVICE_GET_PRICE_ERROR'

export const SERVICE_ADD_PRICE_REQUEST = 'SERVICE_ADD_PRICE_REQUEST'
export const SERVICE_ADD_PRICE_SUCCESS = 'SERVICE_ADD_PRICE_SUCCESS'
export const SERVICE_ADD_PRICE_ERROR = 'SERVICE_ADD_PRICE_ERROR'

export const SERVICE_EDIT_PRICE_REQUEST = 'SERVICE_EDIT_PRICE_REQUEST'
export const SERVICE_EDIT_PRICE_SUCCESS = 'SERVICE_EDIT_PRICE_SUCCESS'
export const SERVICE_EDIT_PRICE_ERROR = 'SERVICE_EDIT_PRICE_ERROR'

export const SERVICE_TYPE_GET_REQUEST = 'SERVICE_TYPE_GET_REQUEST'
export const SERVICE_TYPE_GET_SUCCESS = 'SERVICE_TYPE_GET_SUCCESS'
export const SERVICE_TYPE_GET_ERROR = 'SERVICE_TYPE_GET_ERROR'

export const SERVICE_GROUP_GET_REQUEST = 'SERVICE_GROUP_GET_REQUEST'
export const SERVICE_GROUP_GET_SUCCESS = 'SERVICE_GROUP_GET_SUCCESS'
export const SERVICE_GROUP_GET_ERROR = 'SERVICE_GROUP_GET_ERROR'

export const SERVICE_SUB_GROUP_GET_REQUEST = 'SERVICE_SUB_GROUP_GET_REQUEST'
export const SERVICE_SUB_GROUP_GET_SUCCESS = 'SERVICE_SUB_GROUP_GET_SUCCESS'
export const SERVICE_SUB_GROUP_GET_ERROR = 'SERVICE_SUB_GROUP_GET_ERROR'

export const SERVICE_DELETE_PRICE_REQUEST = 'SERVICE_DELETE_PRICE_REQUEST'
export const SERVICE_DELETE_PRICE_SUCCESS = 'SERVICE_DELETE_PRICE_SUCCESS'
export const SERVICE_DELETE_PRICE_ERROR = 'SERVICE_DELETE_PRICE_ERROR'

export const SERVICE_DELETE_REQUEST = 'SERVICE_DELETE_REQUEST'
export const SERVICE_DELETE_SUCCESS = 'SERVICE_DELETE_SUCCESS'
export const SERVICE_DELETE_ERROR = 'SERVICE_DELETE_ERROR'

export const SERVICE_EDIT_REQUEST = 'SERVICE_EDIT_REQUEST'
export const SERVICE_EDIT_SUCCESS = 'SERVICE_EDIT_SUCCESS'
export const SERVICE_EDIT_ERROR = 'SERVICE_EDIT_ERROR'