import {
    LEGAL_CLIENT_ADD_ERROR,
    LEGAL_CLIENT_ADD_REQUEST,
    LEGAL_CLIENT_ADD_SUCCESS,
    LEGAL_CLIENT_GET_ERROR,
    LEGAL_CLIENT_GET_REQUEST,
    LEGAL_CLIENT_GET_SUCCESS,
    ONE_LEGAL_CLIENT_EDIT_ERROR,
    ONE_LEGAL_CLIENT_EDIT_REQUEST,
    ONE_LEGAL_CLIENT_EDIT_SUCCESS,
    ONE_LEGAL_CLIENT_GET_ERROR,
    ONE_LEGAL_CLIENT_GET_REQUEST,
    ONE_LEGAL_CLIENT_GET_SUCCESS
} from "../../constants/client/legalClientConstants";

const initialState = {
    legalClient: [],
    legalClientCount: null,
    oneLegalClient: {},
    loading: false,
    success: null,
    error: null
};

export const legalClientReducer = (state = initialState, action) => {
    switch (action.type) {
        case LEGAL_CLIENT_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case LEGAL_CLIENT_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true
            };
        case LEGAL_CLIENT_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case ONE_LEGAL_CLIENT_GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ONE_LEGAL_CLIENT_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                oneLegalClient: action.payload,
                success: true
            };
        case ONE_LEGAL_CLIENT_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case ONE_LEGAL_CLIENT_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ONE_LEGAL_CLIENT_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                oneLegalClient: {...state.oneLegalClient, ...action.payload},
                success: true
            };
        case ONE_LEGAL_CLIENT_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case LEGAL_CLIENT_GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case LEGAL_CLIENT_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                legalClient: action.payload.result,
                legalClientCount: action.payload.pageCount,
            };
        case LEGAL_CLIENT_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        default:
            return state;
    }
}