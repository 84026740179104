import {
    CONTRACT_ONE_REQUEST_ERROR,
    CONTRACT_ONE_REQUEST_REQUEST, CONTRACT_ONE_REQUEST_SUCCESS,
    CONTRACT_REQUEST_ERROR, CONTRACT_REQUEST_GET_ERROR, CONTRACT_REQUEST_GET_REQUEST, CONTRACT_REQUEST_GET_SUCCESS,
    CONTRACT_REQUEST_REQUEST,
    CONTRACT_REQUEST_SUCCESS
} from "../../constants/contractRequest/contractRequestConstants";
import {$authHost} from "../../../http";
import {LOADING_FALSE, LOADING_TRUE} from "../../constants/genericConstants";
import {getData, getOneData} from "../template/get"

export const getContractRequest = (page, size, args, nonGlobal) => async (dispatch) => {
    dispatch({type: LOADING_TRUE})
    const contract = await dispatch(getData(CONTRACT_REQUEST_GET_REQUEST, CONTRACT_REQUEST_GET_SUCCESS, CONTRACT_REQUEST_GET_ERROR, 'contractRequest', true, page, null, null, true, size, args, nonGlobal))
    dispatch({type: LOADING_FALSE})
    return contract
};

export const getOneContractRequest = (id) => async (dispatch) => {
    return await dispatch(getOneData(id, CONTRACT_ONE_REQUEST_REQUEST, CONTRACT_ONE_REQUEST_SUCCESS, CONTRACT_ONE_REQUEST_ERROR, 'contractRequest'))
};

export const editContractRequest = (id, educationServices, specifications, objects, number, createdAt, contractType, paymentPassed, signBySystem, contractNumber, contractActionAt, researchCount, paySummary, contractSummary,
                                   realizationSummary, contractPayedSummary, contractPaySummaryRemainder, contractDeadline, departureOfSpecialists, clientId, performerId, responsibleId, viewSectionId,
                                   contractId, contractStateId, contractFilialId, contractFilialDivisionId, contractPayStateId, objectKindId, personTransportTypeId, legalPersonTransportTypeId,
                                   filialId, nameFilter, schedules) => async (dispatch) => {
    try {
        dispatch({
            type: CONTRACT_REQUEST_REQUEST,
        });
        const {data: dataClient} = await $authHost.post(
            "/api/v1/client/query",
            {filter: {
                    [nameFilter]: {
                        operand1: clientId,
                        operator: "equals"
                    },
                }}
        )
        await $authHost.put(
            "/api/v1/contractRequest",
            {id, stateId: 1, specifications: specifications, educationServices, objects, number, createdAt, contractType, paymentPassed, signBySystem, contractNumber, contractActionAt, researchCount, paySummary, contractSummary,
                realizationSummary, contractPayedSummary, contractPaySummaryRemainder, contractDeadline, departureOfSpecialists, clientId: dataClient?.result[0]?.id, performerId, responsibleId, viewSectionId,
                contractId, contractStateId, contractFilialId, contractFilialDivisionId, contractPayStateId, objectKindId, personTransportTypeId, legalPersonTransportTypeId,
                filialId, schedules}
        )
        dispatch({
            type: CONTRACT_REQUEST_SUCCESS
        });
    } catch (error) {
        dispatch({
            type: CONTRACT_REQUEST_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const addContractRequest = (educationServices, specifications, objects, number, createdAt, contractType, paymentPassed, signBySystem, contractNumber, contractActionAt, researchCount, paySummary, contractSummary,
                                   realizationSummary, contractPayedSummary, contractPaySummaryRemainder, contractDeadline, departureOfSpecialists, clientId, performerId, responsibleId, viewSectionId,
                                   contractId, contractStateId, contractFilialId, contractFilialDivisionId, contractPayStateId, objectKindId, personTransportTypeId, legalPersonTransportTypeId,
                                   filialId, nameFilter, schedules) => async (dispatch) => {
    try {
        dispatch({
            type: CONTRACT_REQUEST_REQUEST,
        });
        const {data: dataClient} = await $authHost.post(
            "/api/v1/client/query",
            {filter: {
                    [nameFilter]: {
                        operand1: clientId,
                        operator: "equals"
                    },
                }}
        )
        await $authHost.post(
            "/api/v1/contractRequest",
            {stateId: 1, educationServices, objects, number, createdAt, contractType, paymentPassed, signBySystem, contractNumber, contractActionAt, researchCount, paySummary, contractSummary,
                realizationSummary,specifications: specifications, contractPayedSummary, contractPaySummaryRemainder, contractDeadline, departureOfSpecialists, clientId: dataClient?.result[0]?.id, performerId, responsibleId, viewSectionId,
                contractId, contractStateId, contractFilialId, contractFilialDivisionId, contractPayStateId, objectKindId, personTransportTypeId, legalPersonTransportTypeId,
                filialId, schedules}
        )
        dispatch({
            type: CONTRACT_REQUEST_SUCCESS
        });
    } catch (error) {
        dispatch({
            type: CONTRACT_REQUEST_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

