import React, {useEffect, useState} from 'react';
import {style} from "../Popups/popup";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useSelector} from "react-redux";
import ContractView from "./ContractView";
import {initialValueVariantPreviewBasket} from "../../screens/Contract/Pages/tableTemplate";
import moment from "moment";

const PopupPreliminarilyContract = ({open, handleClose}) => {
    const [initialValue, setInitialValue] = useState(null)
    const {profile} = useSelector((state) => state.profile);
    const [price, setPrice] = useState(0)
    const {basket} = useSelector((state) => state.basket)
    const {filialUser} = useSelector((state) => state.user)

    useEffect(() => {
        setPrice(basket.reduce((acc, curr) => {
            return acc + (curr.price * curr.qty);
        }, 0))
    }, [basket]);
    useEffect(() => {
        (async function () {
            const value = initialValueVariantPreviewBasket({specification: basket, supervisorName: '', city: '', name: 'Договор об указании услуг',
                number: 1, filial: filialUser?.name, client: profile?.client?.person?.lastName + ' ' + profile?.client?.person?.firstName + ' ' + profile?.client?.person?.secondName, inn: profile?.client?.person?.identityNumber,
                bin: '', date2: moment(new Date()).format('LL'), date1: moment(new Date()).format('LL'), price, phoneNumber: profile?.client?.person?.phoneNumber, addressLine: profile?.client?.person?.addressLine,
                supervisor: '', bank: '', bankBik: '', bankAccountNumber: ''})
            setInitialValue(value)
        }())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{...style, width: 900}}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Предварительный договор
                    </Typography>
                    {initialValue && <ContractView download initialValue={initialValue}/>}
                </Box>
            </Modal>
        </div>
    );
}

export default PopupPreliminarilyContract;