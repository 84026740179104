import React, {useEffect, useMemo, useState} from 'react';
import {Dialog, DialogContent, Divider} from "@mui/material";
import Button from "@mui/material/Button";
import {filialPackageCRUD} from "../../http/CRUD";
import {v4 as uuidv4} from "uuid";
import FilialPackageCRUDTable from "../Crud/Tables/FilialPackageCRUDTable";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import {useSelector} from "react-redux";

const SelectFieldModalFilial = ({
                                    setValue,
                                    func,
                                    navigate,
                                    disabled
                                }) => {
    const typeDrawer = {
        service: 'Услуга',
        nothing: 'Ничего',
    }


    const drawer = typeDrawer[navigate === 1 ? 'service' : 'nothing']

    const [packageParent, setPackageParent] = useState([])

    const [id, setId] = useState(2)
    const [searchDrawer, setSearchDrawer] = useState(false)


    const [open, setOpen] = useState(false)
    const [filter, setFilter] = useState({})
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [page, setPage] = useState(1);

    useEffect(() => {
        setPage(1)
        setFilter({})
    }, [func, open])


    useEffect(() => {
        return () => {
            setPackageParent([])
            setSearchDrawer(false)
            setId(2)
        }
    }, [])


    const handleSelectAllServicesInPackage = async (record) => {
        let data = await filialPackageCRUD.get(record.id);
        const services = data.services.map(el => {
            return {...el.service}
        })
        const servicesParsing = services?.map(el => {
            console.log(el)
            return {
                Id: uuidv4(),
                service: el,
                serviceId: el?.id,
                unitId: el?.unitId,
                unit: el?.unit,
                quantity: 1,
                square: 1,
                multiplicity: 1,
                price: el?.prices[0]?.price || 0,
                objectId: '',
                scheduleId: '',
            }
        })
        await setValue([...servicesParsing])
        handleClose()

    }

    const authorizedEmployee = useSelector(state => state.employee.authorizedEmployee)

    return useMemo(() => {
        return (
            <>
                <Button onClick={handleOpen} variant="text">Выбрать пакет (филиал)</Button>
                {!disabled && <Dialog
                    open={open}
                    onClose={handleClose}
                    fullWidth
                    PaperProps={{sx: {maxHeight: '85%'}}}
                    maxWidth={"80%"}
                >
                    <DialogTitle>Выбор пакета (филиал)</DialogTitle>
                    <Divider/>
                    <DialogContent>
                        <FilialPackageCRUDTable
                            edit={false}
                            extraFilter={{
                                filialId: {
                                    operand1: authorizedEmployee?.filialId,
                                    operator: "equals"
                                },
                                ...(authorizedEmployee?.filialDivisionId && {
                                    filialDivisionId: {
                                        operand1: authorizedEmployee?.filialDivisionId,
                                        operator: "equals"
                                    }
                                })
                            }}
                            onRowClick={async (record, rowIndex) => {
                                await handleSelectAllServicesInPackage(record)
                            }}
                            onClickCreateButton={undefined} // что бы не показывать кнопку создания
                        />
                    </DialogContent>
                    <Divider/>
                    <DialogActions>
                        <Button
                            variant={"text"}
                            style={{marginLeft: "auto"}}
                            onClick={() => setOpen(false)}
                        >
                            Отменить
                        </Button>
                    </DialogActions>
                </Dialog>
                }
            </>
        );
    }, [disabled, drawer, handleSelectAllServicesInPackage, id, open, searchDrawer, setValue])
};

SelectFieldModalFilial.defaultProps = {
    type: "text"
}


export default SelectFieldModalFilial;
