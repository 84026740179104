import React, {useContext, useState} from 'react';
import {Button} from "@mui/material";
import {SelectShared} from "../index";
import {ContractContext} from "../../screens/Contract/ContractPage";
import {Table} from "antd";
import {getRandomInt} from "../../functions";

function ContractFilials({onRowClick}) {
    const {filials, setFilials, filial} = useContext(ContractContext)
    const [_data, setData] = useState(filials)

    const changeOriginal = (row, e, name) => {
        let objectOld = filials?.find(el => el.id === row.id)
        let idx = filials?.findIndex(el => el.id === row.id)
        let filial = [...filials]
        filial[idx] = {...objectOld, [name]: e}
        setFilials([...filial])
        setData([...filial])
    }

    const columns = [
        {
            title: "Филиал", dataIndex: 'filialId', key: 'filialId',
            render: (unknown, row) => {
                return <SelectShared value={row['filialId']} setValue={(e) => {
                    changeOriginal(row, e, 'filialId')
                }} label="Филиал" array={filial} style={{"maxWidth": "100%"}}/>
            }
        },
        {
            title: "Действия", dataIndex: 'actions', key: 'actions',
            render: (unknown, row) => <Button onClick={() => {
                setFilials([...filials.filter(el => el.id !== row.id)])
                setData([...filials.filter(el => el.id !== row.id)])
            }
            }>Удалить</Button>
        },
    ];


    return (
        <>
            <Table
                columns={columns}
                dataSource={_data}
                pagination={false}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (e) => onRowClick && onRowClick(record, rowIndex), // click row
                        onDoubleClick: (event) => {
                        }, // double click row
                        onContextMenu: (event) => {
                        }, // right button click row
                        onMouseEnter: (event) => {
                        }, // mouse enter row
                        onMouseLeave: (event) => {
                        }, // mouse leave row
                    };
                }}
            />
            <Button onClick={
                () => {
                    const newElement = {
                        id: getRandomInt(1, 10000000),
                        filialId: 1,
                    }
                    setFilials([...filials, newElement])
                    setData([...filials, newElement])
                }
            }>Добавить филиал</Button>
        </>
    );
}

export default ContractFilials;
