export const GET_FILIAL_REQUEST = 'GET_FILIAL_REQUEST'
export const GET_FILIAL_SUCCESS = 'GET_FILIAL_SUCCESS'
export const GET_FILIAL_ERROR = 'GET_FILIAL_ERROR'

export const GET_FILIAL_DIVISION_REQUEST = 'GET_FILIAL_DIVISION_REQUEST'
export const GET_FILIAL_DIVISION_SUCCESS = 'GET_FILIAL_DIVISION_SUCCESS'
export const GET_FILIAL_DIVISION_ERROR = 'GET_FILIAL_DIVISION_ERROR'

export const ADD_FILIAL_REQUEST = 'ADD_FILIAL_REQUEST'
export const ADD_FILIAL_SUCCESS = 'ADD_FILIAL_SUCCESS'
export const ADD_FILIAL_ERROR = 'ADD_FILIAL_ERROR'

export const DELETE_FILIAL_REQUEST = 'DELETE_FILIAL_REQUEST'
export const DELETE_FILIAL_SUCCESS = 'DELETE_FILIAL_SUCCESS'
export const DELETE_FILIAL_ERROR = 'DELETE_FILIAL_ERROR'

export const GET_CLIENT_FILIAL_REQUEST = 'GET_CLIENT_FILIAL_REQUEST'
export const GET_CLIENT_FILIAL_SUCCESS = 'GET_CLIENT_FILIAL_SUCCESS'
export const GET_CLIENT_FILIAL_ERROR = 'GET_CLIENT_FILIAL_ERROR'