
export const State = {
    Created: "created",
    ClarificationOfNeed: "clarificationofneed",
    ProposalPreparation: "proposalpreparation",
    OfferProtection: "offerprotection",
    ToContract: "tocontract",
    Declined: "declined",
};

export class DealStateChecker {
    constructor(stateCode) {
        this.stateCode = stateCode?.toLowerCase();
    }
    isCreated() {
        return !this.stateCode || this.stateCode === State.Created;
    }
    isClarificationOfNeed() {
        return this.stateCode === State.ClarificationOfNeed;
    }
    isProposalPreparation() {
        return this.stateCode === State.ProposalPreparation;
    }
    isOfferProtection() {
        return this.stateCode === State.OfferProtection;
    }
    isToContract() {
        return this.stateCode === State.ToContract;
    }
    isDeclined() {
        return this.stateCode === State.Declined;
    }
    isFinished() {
        return this.isToContract() || this.isDeclined();
    }
}