import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import {Grid, LinearProgress} from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {editContract} from "../../../../redux/actions/contract/contractActions";
import {CONTRACT_ADDITIONAL_ROUTE, CONTRACT_ROUTE} from "../../../../routes/const";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {$s3host} from "../../../../http";

const LoadingDocumentEdit = ({ filials, isAdditional, contractObject, educationServices, schedules, signers, id, activeStep, handleBack ,name, number, data, legalClient, typeContract, date1, date2, specification, client, type, registeredAt, actionAt, localCity, localFilial, localFilialDivision}) => {
    const inputFileRef = React.useRef(null);
    const dispatch = useDispatch();
    const history = useHistory()
    const [progress, setProgress] = useState()
    const [price, setPrice] = useState()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleChangeImage = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const fileForm = new File([file], "name", { type: 'application/pdf' });
            let formData = new FormData()
            formData.append("file", fileForm)
            const size = file.size
            const {data: s3Path} = await $s3host.post("/api/v1/file/upload?userName=user1&path=contracts&size=" + size, formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                },
                onUploadProgress: progressEvent  => {
                    const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                    if (totalLength)
                        setProgress(Math.round((progressEvent.loaded * 100) / totalLength))
                },
            })
            if (isAdditional) {
                await dispatch(editContract(id, name, number, registeredAt, actionAt, price, typeContract ? client : legalClient, type, specification, signers, typeContract ? 'personId' : 'legalPersonId', s3Path, localFilial, localFilialDivision, localCity, educationServices,
                    schedules,
                    contractObject, null, true, 4))
                await history.push(CONTRACT_ADDITIONAL_ROUTE)
            } else {
                await dispatch(editContract(id, name, number, registeredAt, actionAt, price, typeContract ? client : legalClient, type, specification, signers, typeContract ? 'personId' : 'legalPersonId', s3Path, localFilial, localFilialDivision, localCity, educationServices,
                    schedules,
                    contractObject,null, false, 4, filials))
                await history.push(CONTRACT_ROUTE)
            }
        }
    };

    const handleSubmit = async () => {
        if (isAdditional) {
            await dispatch(editContract(id, name, number, registeredAt, actionAt, price, typeContract ? client : legalClient, type, specification, signers, typeContract ? 'personId' : 'legalPersonId', null, localFilial, localFilialDivision, localCity, educationServices,
                schedules,
                contractObject, null, true, 4))
            await history.push(CONTRACT_ADDITIONAL_ROUTE)
        } else {
            await dispatch(editContract(id, name, number, registeredAt, actionAt, price, typeContract ? client : legalClient, type, specification, signers, typeContract ? 'personId' : 'legalPersonId', null, localFilial, localFilialDivision, localCity, educationServices,
                schedules,
                contractObject, null, false, 4, filials))
            await history.push(CONTRACT_ROUTE)
        }
        await history.push(CONTRACT_ROUTE)
    }

    useEffect(() => {
        setPrice(specification.reduce((acc, curr) => {return acc + (curr.price * curr.quantity);}, 0))
    }, [specification]);
    React.useEffect(() => {
        if (inputFileRef.current) {
            inputFileRef.current.addEventListener('change', handleChangeImage);
        }
    }, [handleChangeImage]);
    return (
        <Box component="form"
             sx={{mt: 10, padding: '0px 10px'}}>
            <Box sx={{width: '100%', mt: 10}}>
                <Grid container spacing={2}>
                    <Grid item xs={4}/>
                    <Grid item xs={4}>
                        <input
                            style={{ display: 'none' }}
                            ref={inputFileRef}
                            accept="application/msword, application/pdf"
                            id="upload-photo"
                            name="upload-photo"
                            type="file"
                        />
                        <label htmlFor="upload-photo">
                            <Button
                                fullWidth
                                component="span"
                                variant="contained"
                            >
                                Загрузить новый документ (необязательно)
                            </Button>
                        </label>
                    </Grid>
                </Grid>
                {progress && <Box sx={{ width: '100%', mt: 3, display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ width: '100%', mr: 1 }}>
                        <LinearProgress variant="determinate" value={progress} />
                    </Box>
                    <Box sx={{ minWidth: 35 }}>
                        <Typography variant="body2" color="text.secondary">{`${Math.round(
                            progress,
                        )}%`}</Typography>
                    </Box>
                </Box>}
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Button
                            fullWidth
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            variant="contained"
                        >
                            Назад
                        </Button>
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            fullWidth
                            onClick={handleSubmit}
                            variant="contained"
                        >
                            Завершить
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default LoadingDocumentEdit;
