import {createStore, compose, combineReducers, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
import {genericReducer} from './reducers/genericReducer'
import {userReducer} from "./reducers/userReducer";
import {typeClientReducer} from "./reducers/client/typeClientReducer";
import {legalClientReducer} from "./reducers/client/legalClientReducer";
import {personReducer} from "./reducers/client/personReducer";
import {typeServiceReducer} from "./reducers/service/typeServiceReducer";
import {clientReducer} from "./reducers/client/clientReducer";
import {serviceReducer} from "./reducers/service/serviceReducer";
import {specificationReducer} from "./reducers/contract/specificationReducer";
import {contractReducer} from "./reducers/contract/contractReducer";
import {requestReducer} from "./reducers/request/requestReducer";
import {geoReducer} from "./reducers/geo/geoReducer";
import {filialReducer} from "./reducers/filial/filialReducer";
import {anObjectReducer} from "./reducers/anObject/anObjectReducer";
import {employeeReducer} from "./reducers/employee/employeeReducer";
import {educationReducer} from "./reducers/educationService/educationReducer";
import {contractRequestReducer} from "./reducers/contractRequest/contractRequestReducer";
import {serviceCategoryReducer} from "./reducers/serviceCategory/serviceCategoryReducer";
import {basketReducer} from "./reducers/basket/basketReducer";
import {profileReducer} from "./reducers/profile/profileReducer";
import {clientRequestReducer} from "./reducers/clientRequest/clientRequestReducer";
import {dealReducer} from "./reducers/deal/dealReducer";
import {contractAdditionalReducer} from "./reducers/contractAdditional/contractAdditionalReducer";
import {bookkeepingReducer} from "./reducers/bookkeeping/bookkeepingReducer";
import { anObjectSectionReducer } from './reducers/anObjectSectionReducer';
import { anObjectSubSectionReducer } from './reducers/anObjectSubSectionReducer';
import {dealStateReducer} from "./reducers/deal/dealStateReducer";
import {contractStateReducer} from "./reducers/contractStateReducer";
import {getObjectReducer} from "./reducers/geoObjectReducer";
import {filialsReducer} from "./reducers/filialReducer";
import {filialDivisionReducer} from "./reducers/filialDivisionReducer";
import {langReducer} from "./reducers/langReducer";

const reducer = combineReducers({
    anObject: anObjectReducer,
    generic: genericReducer,
    person: personReducer,
    contractAdditional: contractAdditionalReducer,
    deal: dealReducer,
    filials: filialsReducer,
    filialDivision: filialDivisionReducer,
    dealState: dealStateReducer,
    employee: employeeReducer,
    filial: filialReducer,
    client: clientReducer,
    clientRequest: clientRequestReducer,
    basket: basketReducer,
    profile: profileReducer,
    education: educationReducer,
    specification: specificationReducer,
    contract: contractReducer,
    contractState: contractStateReducer,
    contractRequest: contractRequestReducer,
    request: requestReducer,
    geo: geoReducer,
    geoObject: getObjectReducer,
    service: serviceReducer,
    serviceCategory: serviceCategoryReducer,
    typeService: typeServiceReducer,
    legalClient: legalClientReducer,
    typeClient: typeClientReducer,
    user: userReducer,
    bookkeeping: bookkeepingReducer,
    anObjectSection: anObjectSectionReducer,
    anObjectSubSection: anObjectSubSectionReducer,
    lang: langReducer,
})

const basketItemsFromStorage = localStorage.getItem('basketItems') ? JSON.parse(localStorage.getItem('basketItems')) : []
const langEditor = localStorage.getItem('langEditor') ? localStorage.getItem('langEditor') : 'ru'

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const initialState = {
    basket: { basket: basketItemsFromStorage },
    lang: { langEditor: langEditor },
};

const store = createStore(reducer, initialState, composeEnhancer(applyMiddleware(thunk)))

export default store
