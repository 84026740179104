import {$authHost} from "../../../http";

export const deleteData = (get, success, err, link, funcGet, id) => async (dispatch) => {
    try {
        dispatch({
            type: get,
        });

        const res = await $authHost.delete(
            `api/v1/${link}`,
            {
                data: {
                    id
                }
            },
        );
        dispatch(funcGet)
        dispatch({
            type: success,
        });
        return res
    } catch (error) {
        dispatch({
            type: err,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
        return error.message
    }
};