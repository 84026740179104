import React from 'react';
import {Grid} from "@mui/material";
import CardGeneral from "./CardGeneral";

function CardGeneralRow({serviceCategory}) {
    return (
        <Grid container spacing={2}>
            {serviceCategory && serviceCategory.slice(1).map(el => (
                <Grid key={el.id} item xs={4}>
                    <CardGeneral el={el}/>
                </Grid>
            ))}
        </Grid>
    );
}

export default CardGeneralRow;