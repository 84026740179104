import React, {useState} from 'react';
import {
    Avatar,
    Box, Chip, Collapse, Grid,
    IconButton, Table, TableBody,
    TableCell, TableHead,
    TableRow
} from "@mui/material";
import {stringAvatar} from "../../functions";
import {useHistory, useLocation} from "react-router-dom";
import {useDispatch} from "react-redux";
import {deleteTypeClient} from "../../redux/actions/client/typeClientActions";
import {deleteTypeService} from "../../redux/actions/service/typeServiceActions";
import {deleteLegalClient} from "../../redux/actions/client/legalClientActions";
import {deletePerson} from "../../redux/actions/client/personActions";
import {useTranslation} from "react-i18next";
import {deleteService, deleteServicePrice} from "../../redux/actions/service/serviceActions";
import {deleteSpecification} from "../../redux/actions/contract/specificationActions";
import {deleteGeoObject} from "../../redux/actions/geo/geoActions";
import {deleteRequest} from "../../redux/actions/request/reqestActions";
import {confirmDialog} from "../Popups/message/PopupDelete";
import TableTab from "../Contract/TableTab";
import {deleteContract} from "../../redux/actions/contract/contractActions";
import TableRowCustomChild from "./TableRowCustomChild";
import {COMPLETION_ACT_ROUTE, CONTRACT_REQUESTS_ROUTE, DEAL_ROUTE, INVOICE_ROUTE} from "../../routes/const";
import TableTabClientRequest from "../ClientRequest/TableTabClientRequest";
import {contractRequestCRUD} from "../../http/CRUD";
import {showSuccessSnackbar} from "../../redux/actions/userActions";
import {deleteClientRequest} from "../../redux/actions/clientRequest/clientRequestActions";

function TableRowCustom({
                            value,
                            row,
                            childTable,
                            typeRow,
                            handleClick,
                            avatars,
                            actions,
                            setEdit,
                            rowsPage,
                            reload,
                            locale,
                            rowName
                        }) {
    const {pathname} = useLocation()
    const history = useHistory()
    const {t} = useTranslation();
    const [show, setShow] = useState(false)

    async function handleContractRequest(rowId) {
        const contractRequest = await contractRequestCRUD.get(rowId)
        if (contractRequest.clinicalRequests?.length || contractRequest.desinsectionRequests?.length || contractRequest.withoutDepartureRequests?.length
            || contractRequest.serviceWithoutActRequests?.length || contractRequest.departureSchedules?.length) {
            dispatch(showSuccessSnackbar("К этой сводной заявке прикреплены дочерние заявки, сначала удалите их", "error"))
        } else {
            await contractRequestCRUD.delete(rowId)
            await reload()
        }
    }

    const handleIcons = async (e, id, rowId) => {
        if (e.target.childNodes.length) {
            let hasIconChild = false
            if (id === 'DeleteForeverIcon' && pathname === '/type-client') {
                e.target.childNodes.forEach(el => {
                    if (el.getAttribute('data-testid') === id) {
                        hasIconChild = true
                    }
                })
                if (hasIconChild)
                    await deleteIconHandler(deleteTypeClient(rowId))
            }
            if (id === 'DeleteForeverIcon' && pathname === '/type-service') {
                e.target.childNodes.forEach(el => {
                    if (el.getAttribute('data-testid') === id) {
                        hasIconChild = true
                    }
                })
                if (hasIconChild)
                    await deleteIconHandler(deleteTypeService(rowId))
            }
            if (id === 'DeleteForeverIcon' && pathname === '/contract') {
                e.target.childNodes.forEach(el => {
                    if (el.getAttribute('data-testid') === id) {
                        hasIconChild = true
                    }
                })
                if (hasIconChild) {
                    await deleteIconHandler(deleteContract(rowId))
                    reload()
                }
            }
            if (id === 'DeleteForeverIcon' && pathname === '/legal-client') {
                e.target.childNodes.forEach(el => {
                    if (el.getAttribute('data-testid') === id) {
                        hasIconChild = true
                    }
                })
                if (hasIconChild)
                    await deleteIconHandler(deleteLegalClient(rowId))
            }
            if (id === 'DeleteForeverIcon' && pathname === '/person') {
                e.target.childNodes.forEach(el => {
                    if (el.getAttribute('data-testid') === id) {
                        hasIconChild = true
                    }
                })
                if (hasIconChild)
                    await deleteIconHandler(deletePerson(rowId))
            }
            if (id === 'DeleteForeverIcon' && pathname === '/clients') {
                e.target.childNodes.forEach(el => {
                    if (el.getAttribute('data-testid') === id) {
                        hasIconChild = true
                    }
                })
                if (hasIconChild)
                    typeRow === 'person' ? await deleteIconHandler(deletePerson(rowId)) : await deleteIconHandler(deleteLegalClient(rowId))
            }
            if (id === 'DeleteForeverIcon' && pathname === '/price-service') {
                e.target.childNodes.forEach(el => {
                    if (el.getAttribute('data-testid') === id) {
                        hasIconChild = true
                    }
                })
                if (hasIconChild)
                    await deleteIconHandler(deleteServicePrice(rowId))
            }
            if (id === 'DeleteForeverIcon' && pathname === '/applications') {
                e.target.childNodes.forEach(el => {
                    if (el.getAttribute('data-testid') === id) {
                        hasIconChild = true
                    }
                })
                if (hasIconChild)
                    await deleteIconHandler(deleteClientRequest(rowId))
            }

            if (id === 'DeleteForeverIcon' && pathname === '/contract-specification') {
                e.target.childNodes.forEach(el => {
                    if (el.getAttribute('data-testid') === id) {
                        hasIconChild = true
                    }
                })
                if (hasIconChild)
                    await deleteIconHandler(deleteSpecification(rowId))
            }
            if (id === 'DeleteForeverIcon' && pathname === '/geo') {
                e.target.childNodes.forEach(el => {
                    if (el.getAttribute('data-testid') === id) {
                        hasIconChild = true
                    }
                })
                if (hasIconChild) {
                    await deleteIconHandler(deleteGeoObject(rowId))
                    reload()
                }
            }
            if (id === 'DeleteForeverIcon' && pathname === '/contract-request') {
                e.target.childNodes.forEach(el => {
                    if (el.getAttribute('data-testid') === id) {
                        hasIconChild = true
                    }
                })
                if (hasIconChild) {
                    await handleContractRequest(rowId)
                }
            }
            if (id === 'DeleteForeverIcon' && pathname === '/service') {
                e.target.childNodes.forEach(el => {
                    if (el.getAttribute('data-testid') === id) {
                        hasIconChild = true
                    }
                })
                if (hasIconChild)
                    await deleteIconHandler(deleteService(rowId, value))
            }
            if (id === 'VisibilityIcon') {
                if (pathname === '/contract-request') {
                    history.push(CONTRACT_REQUESTS_ROUTE + '/' + rowId)
                } else if (pathname === '/deal') {
                    history.push(DEAL_ROUTE + '/' + rowId)
                } else if (pathname === '/completion-act') {
                    history.push(COMPLETION_ACT_ROUTE + '/' + rowId)
                } else if (pathname === '/invoice') {
                    history.push(INVOICE_ROUTE + '/' + rowId)
                }  else {
                    setShow(!show)
                }
            }
            if (id === 'EditIcon') {
                setEdit(rowId, typeRow)
            }

        } else {
            if (e.target.getAttribute('data-testid') === id) {
                if (id === 'DeleteForeverIcon' && pathname === '/type-client') {
                    await deleteIconHandler(deleteTypeClient(rowId))
                }
                if (id === 'DeleteForeverIcon' && pathname === '/type-service') {
                    await deleteIconHandler(deleteTypeService(rowId))
                }
                if (id === 'DeleteForeverIcon' && pathname === '/legal-client') {
                    await deleteIconHandler(deleteLegalClient(rowId))
                }
                if (id === 'DeleteForeverIcon' && pathname === '/person') {
                    await deleteIconHandler(deletePerson(rowId))
                }
                if (id === 'DeleteForeverIcon' && pathname === '/price-service') {
                    await deleteIconHandler(deleteServicePrice(rowId))
                }
                if (id === 'DeleteForeverIcon' && pathname === '/applications') {
                    await deleteIconHandler(deleteClientRequest(rowId))
                }
                if (id === 'DeleteForeverIcon' && pathname === '/contract-specification') {
                    await deleteIconHandler(deleteSpecification(rowId))
                }
                if (id === 'DeleteForeverIcon' && pathname === '/geo') {
                    await deleteIconHandler(deleteGeoObject(rowId))
                    reload()
                }
                if (id === 'DeleteForeverIcon' && pathname === '/contract-request') {
                    await handleContractRequest(rowId)
                    // await contractRequestCRUD.delete(rowId)
                }
                if (id === 'DeleteForeverIcon' && pathname === '/service') {
                    await deleteIconHandler(deleteService(rowId, value))
                }
                if (id === 'DeleteForeverIcon' && pathname === '/contract') {
                    await deleteIconHandler(deleteContract(rowId))
                    reload()
                }
                if (id === 'DeleteForeverIcon' && pathname === '/clients') {
                    typeRow === 'person' ? await deleteIconHandler(deletePerson(rowId)) : await deleteIconHandler(deleteLegalClient(rowId))
                }
                if (id === 'VisibilityIcon') {
                    if (pathname === '/contract-request') {
                        history.push(CONTRACT_REQUESTS_ROUTE + '/' + rowId)
                    } else if (pathname === '/deal') {
                        history.push(DEAL_ROUTE + '/' + rowId)
                    } else if (pathname === '/completion-act') {
                        history.push(COMPLETION_ACT_ROUTE + '/' + rowId)
                    } else if (pathname === '/invoice') {
                        history.push(INVOICE_ROUTE + '/' + rowId)
                    }  else {
                        setShow(!show)
                    }
                }
                if (id === 'EditIcon') {
                    setEdit(rowId, typeRow)
                }
            }
        }
    }
    const dispatch = useDispatch()
    const deleteIconHandler = async (func) => {
        let res;
        try {
            res = await dispatch(func)
            res === 'Network Error' ? handleClick('error') :
                handleClick('success')
        } catch (e) {
            handleClick('error')
        }

    }

    return (
        <TableBody>
            <TableRow
                key={row.id}
                hover
                sx={{'&:last-child td, &:last-child th': {border: 0}, cursor: 'pointer'}}
            >
                {Object.keys(row).map((rowChild, idx) => {
                    if (idx === 0) {
                        return <TableCell key={idx} component="th" scope="row">
                            <Box>
                                {avatars && <IconButton>
                                    <Avatar {...stringAvatar(row[rowChild].split(' ')[0] + ' ' + row[rowChild].split(' ')[1])} />
                                </IconButton>}
                                {row[rowChild]}
                            </Box>
                        </TableCell>
                    }
                    return <TableCell key={idx} align="center">{rowChild === 'status' ?
                        <Chip label={row[rowChild] ? row[rowChild] : 'Не задано'}
                              color={row[rowChild] === 'На согласовании' ? "warning" : "default"}/>
                        : row[rowChild] ? row[rowChild] : 'Не задано'}</TableCell>
                })}
                {actions && <TableCell align="center">
                    <Box>
                        {actions.map((el, idx) => (
                            <IconButton data-testid={el.id} children={el.icon}
                                        onClick={(e) => {
                                            if (el.id === 'DeleteForeverIcon') {
                                                confirmDialog('Удаление элемента', 'Вы действительно хотите удалить этот элемент?', () => {
                                                    return handleIcons(e, el.id, row.id ? row.id : row.number)
                                                })
                                            } else
                                                return handleIcons(e, el.id, row.id)
                                        }} key={idx}>
                            </IconButton>
                        ))}
                    </Box>
                </TableCell>}
            </TableRow>
            {(rowsPage || childTable) && <TableRow>
                <TableCell style={{
                    paddingBottom: 0,
                    paddingTop: 0,
                    paddingLeft: childTable && 0,
                    paddingRight: childTable && 0
                }} colSpan={`100%`}>
                    <Collapse in={show} timeout="auto" unmountOnExit>
                        {childTable ?
                            (
                                <Table>
                                    <TableHead sx={{backgroundColor: '#E1DFDF'}}>
                                        <TableRow>
                                            {rowName && rowName.map((el, idx) => {
                                                return <TableCell key={idx}
                                                                  align={idx !== 0 ? 'center' : 'left'}>{el.text}</TableCell>
                                            })}
                                        </TableRow>
                                    </TableHead>
                                    {childTable.map((row) => (
                                        <TableRowCustomChild key={row.id} row={row} avatars={avatars} actions={actions}
                                                             typeRow={typeRow} rowName={rowName} childTable={childTable}
                                                             setEdit={setEdit} locale={locale} rowsPage={rowsPage}
                                                             handleClick={handleClick} value={value}/>
                                    ))}
                                </Table>)
                            : pathname === '/applications' ?
                                <TableTabClientRequest id={rowsPage?.number} rowsPage={rowsPage}
                                                       locale={locale}/> : (pathname === '/contract' || pathname === '/contract-additional') ?
                                    <TableTab id={rowsPage?.number} rowsPage={rowsPage}
                                              locale={locale}/> : Object.keys(rowsPage).slice(0, 10).map((el, idx) => (
                                        <Grid container key={idx}>
                                            <Grid item xs={6}>
                                                <Box sx={{
                                                    padding: '5px 0',
                                                    borderTop: "1 px",
                                                    borderBottom: '1px'
                                                }}>{t(`${locale}.tableItem.${[el]}`)}</Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box sx={{
                                                    padding: '5px 0',
                                                    borderTop: "1 px",
                                                    borderBottom: '1px'
                                                }}>{rowsPage[el]}</Box>
                                            </Grid>
                                        </Grid>
                                    ))}
                    </Collapse>
                </TableCell>
            </TableRow>}
        </TableBody>
    );
}

export default TableRowCustom;
