import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {Dialog, Divider, Grid} from "@mui/material";
import {useCallback, useEffect, useState} from "react";
import {Loader, Number, SelectShared} from "../index";
import TextShared from "../Shared/TextShared";
import PhoneShared from "../Shared/PhoneShared";
import {anObjectsCRUD, legalPersonCRUD} from "../../http/CRUD";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import useValidations from "../../hooks/useValidations";
import Typography from "@mui/material/Typography";
import {addClient} from "../../redux/actions/client/clientActions";
import {showSuccessSnackbar} from "../../redux/actions/userActions";
import {useDispatch} from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import {v4 as uuidv4} from "uuid";
import {referralSources} from "../../functions";

export default function PopupLegalClient({open, setOpen, edit, id, confirmHandler, setId}) {
    const isNew = !id
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false)

    const handleChange = useCallback((key, value) => {
        setSubmitErrors(false);
        setData({...data, [key]: value});
    }, [data]);

    const fetch = useCallback(() => {
        if (open && id) {
            if (isNew) {
                return;
            }
            setLoading(true)
            legalPersonCRUD
                .get(id)
                .then((data) => {
                    let phoneParsing = {}

                    JSON.parse(data?.phonesLine)?.map(row => {
                        if (row?.phone)
                            phoneParsing[uuidv4()] = {
                                phone: row?.phone,
                            }
                    })

                    if (Object.keys(phoneParsing)?.length) {
                        setPhones(phoneParsing)
                    }

                    setData({...data});
                    setLoading(false)
                })
        }
    }, [id, isNew, open]);

    useEffect(() => {
        if (!id && open) {
            setData({})
        }
    }, [id, open])

    useEffect(fetch, [fetch]);

    const {isValid, renderedErrors} = useValidations(data, [
        {label: "2.1 Наименование клиента", code: "name", rules: ["required"]},
        {label: "2.2 БИН/ИИН клиента", code: "bin", rules: ["required"]},
        {label: "2.4 Адрес", code: "addressLine", rules: ["required"]},
    ]);

    const dispatch = useDispatch()

    const [showSubmitErrors, setShowSubmitErrors] = useState(null);
    const [submitErrors, setSubmitErrors] = useState(null);

    const handleForm = async (e) => {
        e.preventDefault()
        if (!isValid) {
            setShowSubmitErrors(true);
            return;
        }

        const bin = data.bin;
        const regionLine = data?.regionLine;
        const cityLine = data?.cityLine;
        const addressLine = data?.addressLine;
        const supervisorName = data?.supervisorName;

        let phonesLine = JSON.stringify(Object.keys(phones)?.map(el => {
                return {
                    phone: phones[el].phone
                }
            })
        )

        if (!isNew) {
            if (edit && id) {
                await legalPersonCRUD.edit({...data, phonesLine})
            }
            confirmHandler ? confirmHandler() : setOpen(false)
            !confirmHandler && setPhones({})
            id && setId(null)
        } else {

            legalPersonCRUD.search({
                filter: {
                    bin: {
                        operand1: bin,
                        operator: "equals"
                    },
                }
            }).then(async ({result}) => {
                if (result?.length) {
                    legalPersonCRUD.search({
                        filter: {
                            bin: {
                                operand1: bin,
                                operator: "equals"
                            },
                            regionLine: {
                                operand1: regionLine,
                                operator: "equals"
                            },
                            cityLine: {
                                operand1: cityLine,
                                operator: "equals"
                            },
                            addressLine: {
                                operand1: addressLine,
                                operator: "equals"
                            },
                            supervisorName: {
                                operand1: supervisorName,
                                operator: "equals"
                            },
                        }
                    }).then(async ({result}) => {
                        if (result.length) {
                            dispatch(showSuccessSnackbar("Такой БИН уже есть в системе!", "error"))
                        } else {
                            if (edit && id) {
                                await legalPersonCRUD.edit({...data, phonesLine})
                            } else {
                                const {id} = await legalPersonCRUD.create({...data, phonesLine})
                                await addClient(null, id, null)
                            }
                            confirmHandler ? confirmHandler() : setOpen(false)
                            !confirmHandler && setPhones({})
                            id && setId(null)
                        }
                    })
                } else {
                    if (edit && id) {
                        await legalPersonCRUD.edit({...data, phonesLine})
                    } else {
                        const {id} = await legalPersonCRUD.create({...data, phonesLine})
                        await addClient(null, id, null)
                    }
                    confirmHandler ? confirmHandler() : setOpen(false)
                    !confirmHandler && setPhones({})
                    id && setId(null)
                }
            })
        }
    }
    const handleClose = async () => {
        setOpen(false)
        id && setId(null)
    }

    const [phones, setPhones] = useState({})

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            PaperProps={{sx: {maxHeight: '85%'}}}
            maxWidth={"80%"}
        >
            <DialogTitle>Юр.лица ({!edit ? 'Создание' : 'Редактирование'})</DialogTitle>
            <Divider/>
            {loading ? <Loader/> : <DialogContent>
                <Box>
                    <Box component="form" noValidate onSubmit={handleForm}
                         sx={{mt: 1, display: 'flex', flexWrap: 'wrap'}}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextShared value={data?.name} setValue={val => handleChange("name", val)}
                                            label="Название"/>
                            </Grid>
                            <Grid item xs={6}>
                                <Number max="9999999999999" value={data?.bin}
                                        setValue={val => handleChange("bin", val)} label="БИН"/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextShared value={data?.regionLine}
                                            setValue={val => handleChange("regionLine", val)} label="Регион"/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextShared value={data?.cityLine} setValue={val => handleChange("cityLine", val)}
                                            label="Город"/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextShared value={data?.addressLine}
                                            setValue={val => handleChange("addressLine", val)} label="Адрес"/>
                            </Grid>
                            <Grid item xs={6}>
                                <PhoneShared value={data?.phoneNumber}
                                             setValue={val => handleChange("phoneNumber", val)}/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextShared value={data?.email} setValue={val => handleChange("email", val)}
                                            label="Почта" type="email"/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextShared value={data?.bankAccountNumber}
                                            setValue={val => handleChange("bankAccountNumber", val)}
                                            label="ИИК" required={false}/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextShared value={data?.identityNumber}
                                            setValue={val => handleChange("identityNumber", val)}
                                            label="ИИН" required={false}/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextShared value={data?.bankBik}
                                            setValue={val => handleChange("bankBik", val)}
                                            label="БИК" required={false}/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextShared value={data?.bankName}
                                            setValue={val => handleChange("bankName", val)}
                                            label="Название банка" required={false}/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextShared value={data?.beneficiaryCode}
                                            setValue={val => handleChange("beneficiaryCode", val)}
                                            label="Кбе" required={false}/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextShared value={data?.supervisorName}
                                            setValue={val => handleChange("supervisorName", val)}
                                            label="Руководитель"/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextShared required={false} value={data?.supervisorDocumentNumber}
                                            setValue={val => handleChange("supervisorDocumentNumber", val)}
                                            label="Название документа"/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextShared required={false} value={data?.supervisorPosition}
                                            setValue={val => handleChange("supervisorPosition", val)}
                                            label="Название должности"/>
                            </Grid>
                            <Grid item xs={6}>
                                <SelectShared required={false} value={data?.referalSource} setValue={
                                    val => handleChange("referalSource", val)
                                } label="Способ поиска клиента" array={referralSources} style={{maxWidth: '100%'}}/>
                            </Grid>
                            <Grid container xs={12} style={{paddingLeft: 20, paddingRight: 20}}>
                                <Grid item xs={12} marginTop={5}>
                                    <Typography variant={"h6"} style={{display: 'inline'}}>Другие номера</Typography>
                                </Grid>
                                <Grid container spacing={3} style={{display: "flex", alignItems: "center"}} xs={12}>
                                    {Object.keys(phones).map((key) => {
                                        return (phones[key].type !== "deleted" ? <>
                                                <Grid item xs={11}>
                                                    <PhoneShared
                                                        label={"Номер"}
                                                        value={phones[key].phone || ""}
                                                        setValue={(val) => {
                                                            setPhones((state) => {
                                                                return {
                                                                    ...state,
                                                                    [key]: {
                                                                        ...state[key],
                                                                        phone: val
                                                                    }
                                                                }
                                                            })
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <Button onClick={() => {
                                                        setPhones((state) => {
                                                            delete state[key]
                                                            return {
                                                                ...state,

                                                            }
                                                        })
                                                    }}>Удалить</Button>
                                                </Grid>
                                            </> : <></>

                                        )
                                    })}


                                    <Grid item xs={12}>
                                        <Button onClick={() => {
                                            setPhones((state) => {
                                                return {
                                                    ...state,
                                                    [uuidv4()]: {
                                                        roleId: null,
                                                        type: "new"
                                                    },
                                                }
                                            })
                                        }}><AddIcon sx={{marginRight: 2}}/> Добавить телефон</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{mt: 3, mb: 2}}
                                >
                                    Сохранить
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                {(submitErrors || showSubmitErrors) &&
                                <Typography color={"secondary"}>
                                    {submitErrors || renderedErrors}
                                </Typography>}
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </DialogContent>}
            <Divider/>
            <DialogActions>
                <Button
                    variant={"text"}
                    style={{marginLeft: "auto"}}
                    onClick={() => setOpen(false)}
                >
                    Отменить
                </Button>
            </DialogActions>
        </Dialog>
    );
}
