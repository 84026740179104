import {getData} from "../template/get";
import {
    ADD_REQUEST_ERROR,
    ADD_REQUEST_REQUEST, ADD_REQUEST_SUCCESS,
    GET_REQUEST_ERROR, GET_REQUEST_FILTER_REQUEST, GET_REQUEST_FILTER_SUCCESS,
    GET_REQUEST_REQUEST,
    GET_REQUEST_STATE_ERROR,
    GET_REQUEST_STATE_REQUEST,
    GET_REQUEST_STATE_SUCCESS,
    GET_REQUEST_SUCCESS, REQUEST_SECTION_GET_ERROR, REQUEST_SECTION_GET_REQUEST, REQUEST_SECTION_GET_SUCCESS
} from "../../constants/request/requestConstants";
import {$authHost} from "../../../http";
import {addData} from "../template/add";
import {editData} from "../template/edit";
import {deleteData} from "../template/delete";

export const getRequestState = (page, size, args, nonGlobal) => async (dispatch) => {
    return await dispatch(getData(GET_REQUEST_STATE_REQUEST, GET_REQUEST_STATE_SUCCESS, GET_REQUEST_STATE_ERROR, 'requestState', true, page, null, null, true,  size, args, nonGlobal))
};

export const getRequest = () => async (dispatch) => {
    return await dispatch(getData(GET_REQUEST_REQUEST, GET_REQUEST_SUCCESS, GET_REQUEST_ERROR, 'request'))
};

export const getRequestSection = () => async (dispatch) => {
    return await dispatch(getData(REQUEST_SECTION_GET_REQUEST, REQUEST_SECTION_GET_SUCCESS, REQUEST_SECTION_GET_ERROR, 'requestSection', false, 1, null, null, true))
};

export const editRequest = (id, number, stateId, serviceId, contragentId, createdAt, value) => async (dispatch) => {
    return await dispatch(editData(ADD_REQUEST_REQUEST, ADD_REQUEST_SUCCESS, ADD_REQUEST_ERROR, 'request', getRequestFilter(value), {id, number, stateId, serviceId, contragentId, createdAt, value}))
};

export const deleteRequest = (id, value) => async (dispatch) => {
    return await dispatch(deleteData(ADD_REQUEST_REQUEST, ADD_REQUEST_SUCCESS, ADD_REQUEST_ERROR, 'request', getRequestFilter(value), id))
};

export const addRequest = (number, stateId, serviceId, contragentId, createdAt, value) => async (dispatch) => {
    return await dispatch(addData(ADD_REQUEST_REQUEST, ADD_REQUEST_SUCCESS, ADD_REQUEST_ERROR, 'request', getRequestFilter(value), {number, stateId, serviceId, contragentId, createdAt}))
};

export const getRequestFilter = (filter1) => async (dispatch) => {
    try {
        dispatch({
            type: GET_REQUEST_FILTER_REQUEST,
        });
        const {data} = await $authHost.post(
            `api/v1/request/query`,
            {
                sort: {
                    id: {
                        operator: 1,
                        ordinal: 0
                    }
                },
                filter: {
                    stateId: {
                        operand1: filter1,
                        operator: "equals"
                    }
                }
            }
        );
        dispatch({
            type: GET_REQUEST_FILTER_SUCCESS,
            payload: data.result
        });
        return data.result
    } catch (error) {
        console.log(error)
    }
};