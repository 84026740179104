export const checkRoles = (...args) => {
    let status = false
    let roles = JSON.parse(localStorage.roles)
    if (!Array.isArray(roles)) {
        roles = [roles]
    }
    args.some(role => {
        if (roles.includes(role)) {
            status = true
            return status
        }
    });
    return status
}

export function mapRole(role) {
    switch (role) {
        case 'SuperAdministrator':
            return "СуперАдминистратор";
        case 'Administrator':
            return "Администратор";
        case 'Patient':
            return "Пациент";
        case 'Marketer':
            return "Маркетолог";
        case 'Supervisor':
            return "Заведующий";
        case 'Performer':
            return "Лаборант";
        case 'Tarificator':
            return "Тарификатор";
        case 'Specialist':
            return "ООП";
        case 'Client':
            return "Клиент";
        case 'Committee':
            return "КСЭК";
        case 'AdministratorReadonly':
            return "Центральный аппарат";
        case 'FilialAdministrator':
            return "Администратор филиала";
        default:
            return "Не определено";
    }
}
