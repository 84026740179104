import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from "@mui/styles";
import {
    Card,
    CardActions,
    CardContent,
    CardMedia,
    colors,
    Divider,
    IconButton, ListItemIcon, ListItemText,
    Menu,
    MenuItem,
    Tooltip
} from "@mui/material";
import Typography from "@mui/material/Typography";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from "@mui/material/Button";
import EditIcon from '@mui/icons-material/Edit';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import RefreshIcon from '@mui/icons-material/Refresh';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {style} from "../Popups/popup";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@pred1995/crm-nce-ckeditor';
import {initialValuePerson} from "../../screens/Contract/Pages/templatePerson.js";
import {initialValueLegalContract2} from "../../screens/Contract/Pages/templateLegal";
import {additionalContract} from "../../screens/Contract/Pages/tableTemplate";
import moment from "moment";
import {useDispatch} from "react-redux";
import {getOneEmployee} from "../../redux/actions/employee/employeeActions";
import useLoader from "../../hooks/useLoader";
import {$s3host} from "../../http";
import {
    editContract,
    getContractFile,
    deleteContractFileS3
} from "../../redux/actions/contract/contractActions";
import {CONTRACT_ROUTE} from "../../routes/const";
import {LoadingButton} from "@mui/lab";
import {useHistory} from "react-router-dom";
import { contractCRUD, contractFileCRUD, geoObjectCRUD } from '../../http/CRUD';
import { toUIDateTime } from '../../functions/dateHelpers';
import useRoles from "../../hooks/useRoles";





const useStyles = makeStyles(theme => ({
    root: {},
    media: {
        height: 240
    },
    placeholder: {
        height: 240,
        backgroundColor: colors.blueGrey[50],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    insertDriveFileIcon: {
        height: theme.spacing(6),
        width: theme.spacing(6),
        fontSize: theme.spacing(6)
    },
    content: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    actions: {
        justifyContent: 'center'
    },
    getAppIcon: {
        marignRight: theme.spacing(1)
    },
    menu: {
        width: 250,
        maxWidth: '100%'
    }
}));

function FileCard({handleOpen, data}) {
    const { isInRole, isAdministrator, defaultRoles } = useRoles();
    const privilegedUser = isAdministrator() || isInRole(defaultRoles.Committee);

    const isAdmin = privilegedUser === defaultRoles.Administrator || defaultRoles.SuperAdministrator ? true : false;

    const classes = useStyles();

    const moreRef = useRef(null);

    const [openMenu, setOpenMenu] = useState(false);
    const [openGegenerateDocument, setOpenGegenerateDocument] = useState(false);
    const {loading} = useLoader(false);
    const [progress, setProgress] = useState()
    const history = useHistory()
    const [price, setPrice] = useState()



    const handleMenuOpen = () => {
        setOpenMenu(true);
    };

    const handleMenuClose = () => {
        setOpenMenu(false);
    };

    const regenerateDocument = () => {
        setOpenGegenerateDocument(true)
    }

    const handleGegenerateDocumentClose = () => {
        setOpenGegenerateDocument(false);
    };
    const dispatch = useDispatch()

    const [supervisor, setSupervisor] = useState({})
    const [city, setCity] = useState({})

    const editorRef = useRef(null)
    useEffect(() => {
        (async function () {
            const person = await dispatch(getOneEmployee(data?.filial ? data?.filial?.supervisorId :data?.filialDivision?.supervisorId ))
            setSupervisor(person)
            const resCity = await geoObjectCRUD.get(data?.filialDivision ? data?.filialDivision?.addressId : data?.filial?.addressId)
            setCity(resCity)
        }())
    }, [data, dispatch])

    console.log(supervisor)

    useEffect(() => {
        let allPrice = data?.specifications.reduce((acc, curr) => {
            return acc + (curr.price * curr.quantity) * +curr.square * +curr.multiplicity;
        }, 0)
        setPrice(allPrice)
    }, [data?.specifications]);

    const contractAdditionalInit = additionalContract({
        data,
        specification: data?.specifications,
        proxy: data?.filialDivision ? {
            proxy: data?.filialDivision?.proxy,
            proxyDate: moment(data?.filialDivision?.proxyDate).format('LL'),
            proxyNumber: data?.filialDivision?.proxyNumber,
        } : {
            proxy: data?.filial?.proxy,
            proxyDate: moment(data?.filial?.proxyDate).format('LL'),
            proxyNumber: data?.filial?.proxyNumber,
        }, 
        number: data?.number,
        position: supervisor?.position?.name,
        supervisor: supervisor?.fullName,
        supervisorName: supervisor?.fullName,
        filial: data?.filial?.name,
        client: data?.client?.person ? data?.client?.person?.fullName : data?.client?.legalPerson?.name,
        clientData: data?.client?.person ? data?.client?.person : data?.client?.legalPerson,
        addressLine: data?.client?.person ? data?.client?.person?.addressLine : data?.client?.legalPerson?.addressLine,
        filialDate: data?.filial,
        date1: moment(toUIDateTime(data?.registeredAt, true)).format('LL'),
        date2: moment(data?.actionAt).format('LL'),
        inn: data?.client?.person ? data?.client?.person?.identityNumber : data?.client?.legalPerson?.identityNumber,
        bin: data?.client?.legalPerson?.bin,
        price: price,
        phoneNumber: data?.client?.person ? data?.client?.person?.phoneNumber : data?.client?.legalPerson.phoneNumber,
        city: city.name,
        bank: data?.client?.person ? data?.client?.person?.bankName : data?.client?.legalPerson?.bankName,
        bankBik: data?.client?.person ? data?.client?.person?.bankBik : data?.client?.legalPerson?.bankBik,
        bankAccountNumber: data?.client?.person ? data?.client?.person?.bankAccountNumber : data?.client?.legalPerson?.bankAccountNumber,
    })

    const contractInitPerson = initialValuePerson({
        data,
        specification: data?.specifications,
        proxy: data?.filialDivision ? {
            proxy: data?.filialDivision?.proxy,
            proxyDate: moment(data?.filialDivision?.proxyDate).format('LL'),
            proxyNumber: data?.filialDivision?.proxyNumber,
        } : {
            proxy: data?.filial?.proxy,
            proxyDate: moment(data?.filial?.proxyDate).format('LL'),
            proxyNumber: data?.filial?.proxyNumber,
        }, 
        number: data?.number,
        position: supervisor?.position?.name,
        supervisor: supervisor?.fullName,
        filial: data?.filial?.name,
        client: data?.client?.person ? data?.client?.person?.fullName : data?.client?.legalPerson.fullName,
        clientData: data?.client?.person ? data?.client?.person : data?.client?.legalPerson,
        addressLine: data?.client?.person?.addressLine,
        filialDate: data?.filial,
        date1: moment(toUIDateTime(data?.registeredAt, true)).format('LL'),
        date2: moment(data?.actionAt).format('LL'),
        bank: data?.client?.person?.bankName,
        bankBik: data?.client?.person?.bankBik,
        bankAccountNumber: data?.client?.person?.bankAccountNumber,
        city: city.name,

    })

    const contractInitLegal = initialValueLegalContract2({
        data,
        specification: data?.specifications,
        proxy: data?.filialDivision ? {
            proxy: data?.filialDivision?.proxy,
            proxyDate: moment(data?.filialDivision?.proxyDate).format('LL'),
            proxyNumber: data?.filialDivision?.proxyNumber,
        } : {
            proxy: data?.filial?.proxy,
            proxyDate: moment(data?.filial?.proxyDate).format('LL'),
            proxyNumber: data?.filial?.proxyNumber,
        }, 
        number: data?.number,
        position: supervisor?.position?.name,
        supervisor: supervisor?.fullName,
        filial: data?.filial?.name,
        client: data?.client?.person ? data?.client?.person?.fullName : data?.client?.legalPerson.fullName,
        clientData: data?.client?.person ? data?.client?.person : data?.client?.legalPerson,
        addressLine: data?.client?.person?.addressLine,
        filialDate: data?.filial,
        date1: moment(toUIDateTime(data?.registeredAt, true)).format('LL'),
        date2: moment(data?.actionAt).format('LL'),
        actionAt: moment(data?.actionAt).format('LL'),
        deadline: moment(data?.deadline).format('LL'),
        city: city.name,
    })

    console.log(data);

    const handleRegenerate = async () => {
        const htmlFile = editorRef.current?.getData()
        const file = new File([htmlFile], "name", {type: 'text/html'});
        let formData = new FormData()
        formData.append("file", file)
        const size = file.size
        setProgress(1)
        const {data: s3Path} = await $s3host.post("/api/v1/file/upload?userName=user1&path=contracts&size=" + size, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress: progressEvent => {
                const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                if (totalLength)
                    setProgress(Math.round((progressEvent.loaded * 100) / totalLength))
            },
        })

        const contractFileResponse = await contractFileCRUD.search(
            {
                filter: {
                    contractId: {
                        operand1: data.id,
                        operator: "equals"
                    }
                }
            });
        const contractFile = contractFileResponse.result[0];
    
        if (contractFile) {
            const response = await contractFileCRUD.edit({ ...contractFile, s3Path: s3Path});
            console.log(response)
        }

        const {bucketName, key, objectName} = JSON.parse(contractFile.s3Path);
        const answerS3Deleted = await dispatch(deleteContractFileS3(bucketName, key, objectName))
        console.log(answerS3Deleted)

        await history.push(CONTRACT_ROUTE)    
    }
    return (
        <div>
            <Card>
                <CardMedia
                    className={classes.media}
                    image='/images/file.jpg'
                />
                <CardContent className={classes.content}>
                    <div>
                        <Typography variant="h5">Договор</Typography>
                    </div>
                    <div>
                        <Tooltip title="Все опции">
                            <IconButton
                                edge="end"
                                onClick={handleMenuOpen}
                                ref={moreRef}
                                size="small"
                            >
                                <MoreVertIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                </CardContent>
                <Divider />
                <CardActions className={classes.actions}>
                    <Button onClick={() => handleOpen(true)}>
                        <FindInPageIcon className={classes.getAppIcon} />
                        Просмотр
                    </Button>
                    {isAdmin && (
                    <Button onClick={() => regenerateDocument()}>
                        <RefreshIcon className={classes.getAppIcon} />
                        Переформировать
                    </Button> ) }
                </CardActions>

                <Menu
                    anchorEl={moreRef.current}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                    }}
                    classes={{ paper: classes.menu }}
                    onClose={handleMenuClose}
                    open={openMenu}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                    }}
                >
                    <MenuItem divider>
                        <ListItemIcon>
                            <EditIcon />
                        </ListItemIcon>
                        <ListItemText primary="Редактировать" />
                    </MenuItem>
                </Menu>
            </Card>
            <div>
            <Modal
                open={openGegenerateDocument}
                onClose={handleGegenerateDocumentClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{...style, width: '80%'}}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Просмотр договора
                    </Typography>
                    <Box sx={{mt: 5}} className="view-contract">
                        <CKEditor
                        id="editor"
                        onReady={ editor => {
                            editorRef.current = editor
                        } }
                        editor={ ClassicEditor }
                        data={data?.isAdditionalAgreement ? contractAdditionalInit : data?.client?.person ? contractInitPerson : contractInitLegal }
                        />
                    <LoadingButton loading={loading} onClick={handleRegenerate} startIcon={<RefreshIcon/>}
                            sx={{marginTop: 3}} size="small" letiant="outlined">Переформировать договор</LoadingButton>
                    </Box>  
                    {/* <ContractView lastSigned={lastSigned} rowsPage={rowsPage} initialValue={contractInitPerson}/> */}
                </Box>
            </Modal>
        </div>
        </div>
    
    );
}

export default FileCard;