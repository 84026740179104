export const LOGIN_ROUTE = '/sign-in'
export const SIGN_UP_ROUTE = '/sign-up'
export const MAIN_ROUTE = '/applications'
export const GENERAL_ROUTE = '/general'
export const TYPE_CLIENT_ROUTE = '/type-client'
export const LEGAL_CLIENT_ROUTE = '/legal-client'
export const BILLS_ROUTE = '/bills'
export const SERVICE_ROUTE = '/service'
export const TYPE_SERVICE_ROUTE = '/type-service'
export const CONTRACT_ADDITIONAL_ROUTE = '/contract-additional'
export const CONTRACT_ADDITIONAL_ADD_ROUTE = '/contract-additional-add'
export const CONTRACT_VIEW_ROUTE = '/contract-view'
export const CONTRACT_VIEW_ECP_ROUTE = '/contract-view-ecp'
export const CLIENTS_ROUTE = '/clients'
export const CLIENT_ROUTE = '/person'
export const CONTRACT_ROUTE = '/contract'
export const FILIAL_ROUTE = '/filial'
export const CONTRACT_DETAILS_ROUTE = '/contract-Add'
export const CONTRACT_EDIT_ROUTE = '/contract-edit'
export const CONTRACT_REQUESTS_ROUTE = '/contract-request'
export const CONTRACT_REQUESTS_ADD_ROUTE = '/contract-request-add'
export const GEO_ROUTE = '/geo'
export const PRICE_ROUTE = '/price-service'
export const CONTRACT_SPECIFICATION = '/contract-specification'
export const REPORTS_ROUTE = '/reports'
export const PROFILE_ROUTE = '/profile'
export const DEAL_ROUTE = '/deal'
export const DEAL_ADD_ROUTE = '/deal-add'
export const PERSONAL_AREA_ROUTE = '/personal-area'
export const COMPLETION_ACT_ROUTE = '/completion-act'
export const COMPLETION_ACT_ADD_ROUTE = '/completion-act-add'
export const INVOICE_ROUTE = '/invoice'
export const INVOICE_ADD_ROUTE = '/invoice-add'
export const PAYMENT_ROUTE = '/payment'
export const PAYMENT_ADD_ROUTE = '/payment-add'
export const OHVAT_ROUTE = '/ohvat'
export const DATE_ROUTE = '/date'
export const OBRASH_ROUTE = '/obrash'
export const EFF_ROUTE = '/eff'
export const STATUS_ROUTE = '/status'
export const FILIAL_PACKAGE_ROUTE = '/filialPackage'
export const FILIAL_PACKAGE_ADD_ROUTE = '/filialPackage-add'
export const FILIAL_PACKAGE_EDIT_ROUTE = '/filialPackage-edit'
