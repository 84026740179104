import {PROFILE_GET_REQUEST, PROFILE_GET_SUCCESS} from "../../constants/profile/profileConstants";
import {$authHost} from "../../../http";

export const getProfile = () => async (dispatch) => {
    dispatch({
        type: PROFILE_GET_REQUEST,
    });
    const {data} = await $authHost.get(
        `api/v1/profile`,
    );
    dispatch({
        type: PROFILE_GET_SUCCESS,
        payload: data
    });
    return data
};

export const updateProfile = (id, legalPersonId, personId, userId, person, legalPerson) => async (dispatch) => {

    const {data} = await $authHost.put(
        `api/v1/profile`,
        {
            client: {
                id, legalPersonId, personId, userId, person: {
                    ...person
                }, legalPerson: {
                    ...legalPerson
                }
            }
        }
    );
    return data
};