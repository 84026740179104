import {
    SIDEBAR_OPEN,
    SIDEBAR_CLOSE,
    GET_GENDER_REQUEST,
    GET_GENDER_SUCCESS,
    GET_GENDER_ERROR,
    GET_UNIT_MEASURE_REQUEST,
    GET_UNIT_MEASURE_ERROR,
    GET_UNIT_MEASURE_SUCCESS,
    GET_CURRENCY_SUCCESS,
    GET_CURRENCY_REQUEST,
    GET_CURRENCY_ERROR,
    OPEN_LIST_CLIENT,
    CLOSE_LIST_CLIENT,
    OPEN_LIST_SERVICE,
    CLOSE_LIST_SERVICE,
    GET_SERVICE_KIND_SUCCESS,
    GET_SERVICE_KIND_REQUEST,
    GET_SERVICE_KIND_ERROR,
    LOADING_TRUE,
    LOADING_FALSE,
    OPEN_LIST_SOCIAL, CLOSE_LIST_SOCIAL, OPEN_LIST_BOOKKEEPING, CLOSE_LIST_BOOKKEEPING, OPEN_LIST_TEST, CLOSE_LIST_TEST
} from '../constants/genericConstants'

const initialState = {
    open: false,
    loader: false,
    openListClient: false,
    openListSocial: false,
    openListService: false,
    openListBookkeeping: false,
    openListTest: false,
    genderAll: [],
    serviceKind: [],
    unityOfMeasure: [],
    currencyAll: [],
};

export const genericReducer = (state = initialState, action) => {
    switch (action.type) {
        case SIDEBAR_OPEN:
            return {
                ...state,
                open: true,
            };
        case LOADING_TRUE:
            return {
                ...state,
                loader: true,
            };
        case LOADING_FALSE:
            return {
                ...state,
                loader: false,
            };
        case SIDEBAR_CLOSE:
            return {
                ...state,
                open: false,
            };
        case OPEN_LIST_CLIENT:
            return {
                ...state,
                openListClient: true,
            };
        case CLOSE_LIST_CLIENT:
            return {
                ...state,
                openListClient: false,
            };
        case OPEN_LIST_BOOKKEEPING:
            return {
                ...state,
                openListBookkeeping: true,
            };
        case CLOSE_LIST_BOOKKEEPING:
            return {
                ...state,
                openListBookkeeping: false,
            };
        case OPEN_LIST_TEST:
            return {
                ...state,
                openListTest: true,
            };
        case CLOSE_LIST_TEST:
            return {
                ...state,
                openListTest: false,
            };
        case OPEN_LIST_SOCIAL:
            return {
                ...state,
                openListSocial: true,
            };
        case CLOSE_LIST_SOCIAL:
            return {
                ...state,
                openListSocial: false,
            };
        case OPEN_LIST_SERVICE:
            return {
                ...state,
                openListService: true,
            };
        case CLOSE_LIST_SERVICE:
            return {
                ...state,
                openListService: false,
            };
        case GET_GENDER_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case GET_GENDER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_GENDER_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                genderAll: action.payload,
            };
        case GET_SERVICE_KIND_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case GET_SERVICE_KIND_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_SERVICE_KIND_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                serviceKind: action.payload,
            };
        case GET_UNIT_MEASURE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case GET_UNIT_MEASURE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_UNIT_MEASURE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                unityOfMeasure: action.payload,
            };
        case GET_CURRENCY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case GET_CURRENCY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_CURRENCY_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                currencyAll: action.payload,
            };
        default:
            return state;
    }
}