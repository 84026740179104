export const CONTRACT_REQUEST_REQUEST = 'CONTRACT_REQUEST_REQUEST'
export const CONTRACT_REQUEST_SUCCESS = 'CONTRACT_REQUEST_SUCCESS'
export const CONTRACT_REQUEST_ERROR = 'CONTRACT_REQUEST_ERROR'

export const CONTRACT_ONE_REQUEST_REQUEST = 'CONTRACT_ONE_REQUEST_REQUEST'
export const CONTRACT_ONE_REQUEST_SUCCESS = 'CONTRACT_ONE_REQUEST_SUCCESS'
export const CONTRACT_ONE_REQUEST_ERROR = 'CONTRACT_ONE_REQUEST_ERROR'

export const CONTRACT_REQUEST_GET_REQUEST = 'CONTRACT_REQUEST_GET_REQUEST'
export const CONTRACT_REQUEST_GET_SUCCESS = 'CONTRACT_REQUEST_GET_SUCCESS'
export const CONTRACT_REQUEST_GET_ERROR = 'CONTRACT_REQUEST_GET_ERROR'