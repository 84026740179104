import React from 'react';
import Dashboard from "../../layouts/Dashboard";
import {TableCell, TableRow} from "@mui/material";
import TableCustomVariantTwo from "../../components/TableCustom/TableCustomVariantTwo";
import BreadcrumbsShared from "../../components/Shared/BreadcrumbsShared";
import {DATE_ROUTE} from "../../routes/const";

function Date(props) {
    return (
        <Dashboard>
            <BreadcrumbsShared breadcrumbs={[
                {text: 'Данные по услугам', link: DATE_ROUTE}
            ]}/>
            <TableCustomVariantTwo name="Данные по услугам" header={<React.Fragment>
                <TableRow>
                    <TableCell width="10%" align="center" colSpan={1}>
                        №
                    </TableCell>
                    <TableCell width="10%" align="center" colSpan={2}>
                        Вид услуги
                    </TableCell>
                    <TableRow sx={{width: '80%'}}>
                        <TableCell rowSpan={1} colSpan={12} align="center">
                            Количество оказанных услуг
                        </TableCell>
                        <TableCell rowSpan={1} colSpan={12} align="center" >
                            Количество оказанных услуг
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="center" colSpan={4}>
                            юр.лица
                        </TableCell>
                        <TableCell align="center" colSpan={4}>физ.лица
                        </TableCell>
                        <TableCell align="center" colSpan={4} >Всего
                        </TableCell>
                        <TableCell  align="center" colSpan={4}>
                            юр.лиц
                        </TableCell>
                        <TableCell  align="center" colSpan={4} >физ.лица
                        </TableCell>
                        <TableCell align="center" colSpan={4}>Всего
                        </TableCell>
                    </TableRow>
                </TableRow>
            </React.Fragment>}/>
        </Dashboard>
    );
}

export default Date;