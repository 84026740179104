import React from 'react';
import {filialPackageCRUD} from "../../../http/CRUD";
import CRUDTableQuery from "../CRUDTableQuery";
import CRUDTableStandart from "../CRUDTableStandart";

export default function FilialPackageCRUDTable({type = 'standart', ...props}) {

    const columns = [
        {title: "#", dataIndex: 'id', key: 'id'},
        {
            title: "Название",
            key: 'name',
            render: (unknown, row) => row.name ? row.name :
                <span style={{color: "gray"}}>Не задан</span>
        },
        {
            title: "Филиал",
            key: 'filial',
            render: (unknown, row) => row.filialId ? row.filial?.name :
                <span style={{color: "gray"}}>Не задан</span>
        },
        {
            title: "Районное отделение",
            key: 'filialDivision',
            render: (unknown, row) => row.filialDivisionId ? row.filialDivision?.name :
                <span style={{color: "gray"}}>Не задан</span>
        },
    ]
    const searchFields = [
        {
            title: "Название пакета",
            key: "name",
            compareType: "like",
            operandPosition: 1,
            filterType: "normal"
        },
    ]

    const CRUDTables = {
        standart: CRUDTableStandart,
        query: CRUDTableQuery,
    }

    const CRUDTable = CRUDTables[type]


    return (
        <CRUDTable search={filialPackageCRUD.search}
                        columns={columns}
                        {...props}
                        searchFields={searchFields}
                        extraFilter={{
                       ...(props?.extraFilter && {...props.extraFilter})
                   }}
        />
    );
}
