import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import create from 'zustand';


const useConfirmWarningStore = create((set) => ({
    message: '',
    onSubmit: undefined,
    close: () => set({onSubmit: undefined}),
}));


export const confirmWarning = (message, onSubmit) => {
    useConfirmWarningStore.setState({
        message,
        onSubmit,
    });
};

const ConfirmWarning = () => {
    const {message, onSubmit, close} = useConfirmWarningStore();
    return (
            <Dialog
                open={Boolean(onSubmit)}
                onClose={close}
            >
                <DialogTitle>Предупреждение</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => close()} color="primary" variant="contained">
                        Ок
                    </Button>
                </DialogActions>
            </Dialog>
    );
}

export default ConfirmWarning;
