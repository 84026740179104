import React from 'react';
import DashboardLayout from "../../layouts/Dashboard";

const Bills = () => {
    return (
        <DashboardLayout>
            bills
        </DashboardLayout>
    );
};

export default Bills;