import {getData} from "../template/get";
import {
    SERVICE_CATEGORY_GET_ERROR,
    SERVICE_CATEGORY_GET_REQUEST,
    SERVICE_CATEGORY_GET_SUCCESS, SERVICE_PACKAGE_GET_ERROR, SERVICE_PACKAGE_GET_REQUEST, SERVICE_PACKAGE_GET_SUCCESS
} from "../../constants/serviceCategory/serviceCategoryConstants";

export const getServiceCategory = (page, size, args, nonGlobal) => async (dispatch) => {
    return await dispatch(getData(SERVICE_CATEGORY_GET_REQUEST, SERVICE_CATEGORY_GET_SUCCESS, SERVICE_CATEGORY_GET_ERROR, 'serviceCategory', true, page, null, null, true, 1000, args, nonGlobal, null,  'asc' ))
};

export const getServicePackage = (page, size, args, nonGlobal) => async (dispatch) => {
    return await dispatch(getData(SERVICE_PACKAGE_GET_REQUEST, SERVICE_PACKAGE_GET_SUCCESS, SERVICE_PACKAGE_GET_ERROR, 'servicePackage', true, page, null, null, true, size, args, nonGlobal, null, 'asc' ))
};