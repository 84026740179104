import React from 'react'
import TextField from "@mui/material/TextField";
import InputMask from "react-input-mask";

const PhoneShared = ({value, setValue}) => {
    return (
        <InputMask
            mask="+7 999 999 99 99"
            value={value}
            onChange={(e) => setValue(e.target.value)}
        >
            {() => <TextField
                id="phone"
                fullWidth
                label="Телефон"
                name="phone"
                margin="normal"
                type="text"
            />}
        </InputMask>
    );
}

export default PhoneShared