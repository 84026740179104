import React from 'react';
import DashboardLayout from "../../../layouts/Dashboard";
import LegalPersonCRUDTable from "../../../components/Crud/Tables/LegalPersonCRUDTable";
import {ContractContext} from "../../Contract/Contract";
import {legalPersonCRUD} from "../../../http/CRUD";
import {convertClientToExcel} from "../../../functions";

const LegalClient = () => {

    const handleContractToExcel = async () => {
        const {result} = await legalPersonCRUD.search({paging: {skip: 0}})
        if (result?.length) {
            await convertClientToExcel(result, 'name')
        }
    }

    return (
        <DashboardLayout>
            <ContractContext.Provider value={{handleContractToExcel}}>
                <LegalPersonCRUDTable modalFilter edit={true} delete={true}/>
            </ContractContext.Provider>
        </DashboardLayout>
    );
};

export default LegalClient;
