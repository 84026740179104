import React, {useState} from 'react';
import {Document, Page} from "react-pdf";
import {Button} from "@mui/material";
import RemoveIcon from '@mui/icons-material/Remove';
import PlusOneIcon from '@mui/icons-material/PlusOne';

const ContractViewPdf = ({initialValue}) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
    return (
        <div>
            <Document
                className="document-pdf"
                file={initialValue}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                <Page pageNumber={pageNumber} />
            </Document>
            <p>Страница {pageNumber} из {numPages}</p>
            <Button disabled={pageNumber === 1} onClick={() => setPageNumber(prev => prev - 1)} variant="outlined" startIcon={<RemoveIcon />}>
                Предыдущая страница
            </Button>
            <Button disabled={pageNumber === numPages} onClick={() => setPageNumber(prev => prev + 1)} variant="contained" sx={{marginLeft: '10px'}} endIcon={<PlusOneIcon />}>
                Следующая страница
            </Button>
        </div>
    );
}

export default ContractViewPdf;