import React from 'react';
import List from "@mui/material/List";
import GeneralDrawerChildRow from "./GeneralDrawerChildRow";

function GeneralDrawerChild({id, packageParent, goToPage}) {

    return (
        <List component="div" disablePadding sx={{marginLeft: '10px'}}>
            {packageParent && packageParent.map((el, idx) => (
                <GeneralDrawerChildRow id={id} goToPage={goToPage} el={el} key={el.id}/>
            ))}
        </List>
    );
}

export default GeneralDrawerChild;