import React, {useEffect} from 'react';
import {Grid} from "@mui/material";
import {DatePickerShared, Number, SelectShared} from "../../../components";
import moment from "moment";
import TextShared from "../../../components/Shared/TextShared";
import {FilialDivisionSelect, FilialSelect} from "../../../components/Select/domainSelects";

const ContractRequestContract = ({
                                     contractActionAt,
                                     mode,
                                     contractFilial,
                                     contractFilialDivision,
                                     contractNumber,
                                     contractPayState,
                                     contractPaySummaryRemainder,
                                     oneContract,
                                     contractPaySummary,
                                     setContractPaySummary,
                                     contractState,
                                     paySummary,
                                     realizationSummary,
                                     setContractActionAt,
                                     setContractFilial,
                                     setContractNumber,
                                     researchCount,
                                     setContractFilialDivision,
                                     setContractPayState,
                                     setContractPaySummaryRemainder,
                                     setPaySummary,
                                     setRealizationSummary,
                                     contractSummary,
                                     setContractSummary,
                                     setContractState,
                                     filial,
                                     filialDivision,
                                     contractsState,
                                     contractsPayState,
                                     setResearchCount
                                 }) => {


    useEffect(() => {
        if (oneContract) {
            setContractNumber(oneContract?.number)
            setContractActionAt(oneContract?.actionAt)
            setContractState(oneContract?.stateId)
            setContractFilial(oneContract?.filialId)
            setContractFilialDivision(oneContract?.filialDivisionId)
            setResearchCount(oneContract?.specifications?.length)
            setContractSummary(oneContract?.summary)
            setContractPayState(oneContract?.payStateId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oneContract])

    return (
        <Grid sx={{paddingTop: 2, paddingBottom: 2}} container spacing={2}>
            {(mode === 'edit' || !mode) && <>
                <Grid item xs={6}>
                    <TextShared value={contractNumber} setValue={setContractNumber} label="№ договора:" max={9999}/>
                </Grid>
                <Grid item xs={6}>
                    <DatePickerShared value={contractActionAt} setValue={setContractActionAt} label="Дата договора"/>
                </Grid>
                <Grid item xs={6}>
                    <FilialSelect
                        label={"Филиал"}
                        fullWidth
                        value={contractFilial}
                        onChange={val => setContractFilial(val)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FilialDivisionSelect
                        required={false}
                        filialId={contractFilial}
                        fullWidth
                        value={contractFilialDivision}
                        onChange={val => setContractFilialDivision(val)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Number disabled value={researchCount} setValue={setResearchCount} label="Количество исследований:"
                            max={999999}/>
                </Grid>
                <Grid item xs={6}>
                    <Number value={paySummary} setValue={setPaySummary} label="Сумма оплаты на портале:" max={9999999}/>
                </Grid>
                <Grid item xs={6}>
                    <Number value={contractSummary} setValue={setContractSummary} label="Сумма договора:"
                            max={9999999}/>
                </Grid>
                <Grid item xs={6}>
                    <Number value={realizationSummary} setValue={setRealizationSummary} label="Сумма реализации:"
                            max={9999999}/>
                </Grid>
                <Grid item xs={6}>
                    <Number disabled value={contractPaySummary} setValue={setContractPaySummary}
                            label="Оплаченная сумма по договору:" max={9999999}/>
                </Grid>
                <Grid item xs={6}>
                    <Number value={contractPaySummaryRemainder} setValue={setContractPaySummaryRemainder}
                            label="Остаток суммы выплаты по договору:" max={9999999}/>
                </Grid>
                <Grid item xs={6}>
                    <SelectShared array={contractsPayState} label="Статус оплаты" value={contractPayState}
                                  setValue={setContractPayState}/>
                </Grid>
            </>}
            {mode === 'see' && <>
                <Grid item xs={6}>
                    № договора: {contractNumber}
                </Grid>
                <Grid item xs={6}>
                    Дата договора: {moment(contractActionAt).format('LL')}
                </Grid>
                <Grid item xs={6}>
                    Исполнитель: {filial.find(el => el.id === contractFilial)?.name}
                </Grid>
                <Grid item xs={6}>
                    Исполнитель: {filialDivision.find(el => el.id === contractFilialDivision)?.name}
                </Grid>
                <Grid item xs={6}>
                    Количество исследований: {researchCount}
                </Grid>
                <Grid item xs={6}>
                    Сумма оплаты на портале: {paySummary}
                </Grid>
                <Grid item xs={6}>
                    Сумма договора: {contractSummary}
                </Grid>
                <Grid item xs={6}>
                    Сумма реализации: {realizationSummary}
                </Grid>
                <Grid item xs={6}>
                    Оплаченная сумма по договору: {contractPaySummary}
                </Grid>
                <Grid item xs={6}>
                    Остаток суммы выплаты по договору: {contractPaySummaryRemainder}
                </Grid>
                <Grid item xs={6}>
                    Статус оплаты: {contractsPayState.find(el => el.id === contractPayState)?.name}
                </Grid>
            </>}

        </Grid>
    );
}

export default React.memo(ContractRequestContract);
