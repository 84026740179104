import React, {useState} from 'react';
import {
    CONTRACT_ADDITIONAL_ADD_ROUTE,
    CONTRACT_ADDITIONAL_ROUTE, CONTRACT_VIEW_ROUTE,
} from "../../routes/const";
import BreadcrumbsShared from "../../components/Shared/BreadcrumbsShared";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import moment from "moment";
import {ContractContext} from "../Contract/Contract";
import ContractCRUDTable from "../../components/Crud/Tables/ContractCRUDTable";
import TableTab from "../../components/Contract/TableTab";
import DashboardLayout from "../../layouts/Dashboard";
import useReload from "../../hooks/useReload";
import {confirmDialog} from "../../components/Popups/message/PopupDelete";
import {contractCRUD} from "../../http/CRUD";
import {convertContractToExcel} from "../../functions";
import {showSuccessSnackbar} from "../../redux/actions/userActions";

function ContractAdditional() {
    const {reload, reloadValue} = useReload();
    const history = useHistory()
    const [page, setPage] = useState(0);
    const [filter, setFilter] = useState({})
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const dispatch = useDispatch()

    async function deleteHandler(id) {
        return confirmDialog('Удаление элемента', 'Вы действительно хотите удалить этот элемент?', async () => {
            try {
                await contractCRUD.delete(id)
                await reload();
            } catch (e) {
            }
        })
    }

    const handleContractToExcel = async (filter) => {
        if (filter?.actionAt?.operand1 && filter?.actionAt?.operand2) {
            const filterToServer = {
                actionAt: {...filter?.actionAt},
                isAdditionalAgreement: {
                    operand1: true,
                    operator: "equals"
                }
            };

            if (filter?.name) {
                filterToServer.name = {...filter?.name};
            }
            if (filter?.number) {
                filterToServer.number = {...filter?.number};
            }
            if (filter?.stateId) {
                filterToServer.stateId = {...filter?.stateId}
            }

            const res = await contractCRUD.search({
                paging: { skip: 0 },
                filter: filterToServer
            })
            if (res?.result?.length) {
                await convertContractToExcel(res, filter, true)
            }
        } else {
            dispatch(showSuccessSnackbar("Вы не указали отчётный период", "error"))
        }
    }

    return (
        <DashboardLayout>
            <BreadcrumbsShared breadcrumbs={[
                {text: 'Доп соглашения', link: CONTRACT_ADDITIONAL_ROUTE}
            ]}/>
            <ContractContext.Provider value={{handleContractToExcel, page, rowsPerPage, filter}}>
                <ContractCRUDTable extraFilter={{
                    isAdditionalAgreement: {
                        operand1: true,
                        operator: "equals"
                    }
                }} onClickEditButton={(id) => history.push(CONTRACT_ADDITIONAL_ROUTE + `/${id}`)}
                                   onClickDeleteButton={deleteHandler}
                                   onClickCreateButton={() => history.push(CONTRACT_ADDITIONAL_ADD_ROUTE)} reloadValue={reloadValue}
                                   onClickDetailsButton={(id) => history.push(CONTRACT_VIEW_ROUTE + `/${id}`)}
                                   modalFilter/>
            </ContractContext.Provider>
        </DashboardLayout>
    );
}

export default ContractAdditional;
