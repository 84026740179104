import {
    LOGIN_REQUEST,
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    LOGIN_SET,
    LOGIN_RESET,
    REGISTER_REQUEST,
    REGISTER_SUCCESS,
    GET_USER_REQUEST,
    GET_USER_SUCCESS,
    GET_USER_ERROR,
    USER_INFO_SET,
    ROLES_SET,
    SET_FILIAL
} from '../constants/userConstants'

const initialState = {
    loading: false,
    success: null,
    isAuth: false,
    filialUser: {},
    userInfo: null,
    info: null,
    roles: [],
    user: null,
    error: null,
    isClient: false,
    isMarketer: false,
    successSnackbarOpen: false,
    successSnackbarMessage: '',
    severitySnackbarMessage: 'success',
    errorSnackbarOpen: false,
    infoSnackbarOpen: false
};

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SNACKBAR_SUCCESS":
            return {
                ...state,
                successSnackbarOpen: true,
                severitySnackbarMessage: action.severity,
                successSnackbarMessage: action.message
            };
        case "SNACKBAR_CLEAR":
            return {
                ...state,
                successSnackbarOpen: false,
                errorSnackbarOpen: false,
                infoSnackbarOpen: false
            };
        case REGISTER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SET_FILIAL:
            return {
                ...state,
                filialUser: action?.payload ? action?.payload[0] : {},
            };
        case REGISTER_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                isAuth: true
            };
        case LOGIN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                isAuth: true
            };
        case LOGIN_SET:
            return {
                ...state,
                userInfo: action.payload
            };
        case ROLES_SET:
            return {
                ...state,
                roles: action.payload,
                isClient: action.payload.length === 1 && action.payload[0] === 'Client',
                isMarketer: action.payload.length === 1 && action.payload[0] === 'Marketer',
            };
        case USER_INFO_SET:
            return {
                ...state,
                info: action.payload
            };
        case LOGIN_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case GET_USER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.payload[0],
                success: true
            };
        case GET_USER_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case LOGIN_RESET:
            return {
                ...state,
                user: null,
                roles: [],
                loading: false,
                userInfo: null,
                error: null,
                success: false,
                isAuth: false
            }
        default:
            return state;
    }
}