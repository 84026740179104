import {
    LOGIN_FAIL,
    LOGIN_REQUEST,
    LOGIN_RESET, LOGIN_SET,
    LOGIN_SUCCESS,
    REGISTER_REQUEST, ROLES_SET, USER_INFO_SET
} from "../constants/userConstants";
import {$authHost, $host} from "../../http";
import {getClients} from "./client/clientActions";
import {getProfile} from "./profile/profileActions";
import {GENERAL_ROUTE, MAIN_ROUTE} from "../../routes/const";

export const login = (username, password, xml, signedXml) => async (dispatch) => {
    try {
        dispatch({
            type: LOGIN_REQUEST,
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        const request = { userName: username, password: password};

        if (xml && signedXml) {
            request.signature = {
                xml,
                signedXml
            }
        }

        const { data } = await $host.post(
            "/api/v1/authenticate",
            request,
            config
        );
        dispatch({
            type: LOGIN_SUCCESS,
        });
        localStorage.setItem("token", data.token);
        localStorage.setItem("roles", JSON.stringify(data.roles))
        localStorage.setItem("employee", JSON.stringify(data.employee));

        const info = {
            userId: data.userId, userName: username, roles: data.roles
        }
        localStorage.setItem("userInfo", JSON.stringify(info));
        dispatch({
            type: LOGIN_SET,
            payload: info
        });
        dispatch({
            type: ROLES_SET,
            payload: data.roles
        });
    } catch (error) {
        dispatch({
            type: LOGIN_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const register = (userName, password, person, legalPerson) => async (dispatch) => {
    try {
        dispatch({
            type: REGISTER_REQUEST,
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const res = await $host.post(
            "/api/v1/user/register",
            { userName, password, client: {
                    person: person ? {...person} : null,
                    legalPerson: legalPerson ? {...legalPerson} : null,
                }, type: person ? "Person" : "LegalPerson" },
            config
        );
        if (res.data?.affected === 0) {
            return alert(res.data?.responseCode);
        }
        const { data } = await $host.post(
            "/api/v1/authenticate",
            { Username: userName, Password: password },
            config
        );
        dispatch({
            type: LOGIN_SUCCESS,
        });
        localStorage.setItem("token", data.token);
        localStorage.setItem("roles", JSON.stringify(data.roles))
        const info = {
            userId: data.userId, userName: userName, roles: data.roles
        }
        localStorage.setItem("userInfo", JSON.stringify(info));
        dispatch({
            type: LOGIN_SET,
            payload: info
        });
        dispatch({
            type: ROLES_SET,
            payload: data.roles
        });
        window.location.href = '/general';

    } catch (error) {
        alert(error?.response?.data?.responseCode)
    }
};

export const check = () => async (dispatch) => {
    try {
        const inf = JSON.parse(localStorage.getItem('userInfo'));
        const token = localStorage.getItem('token');
        // const refreshToken = localStorage.getItem('refreshToken')
        // const { data } = await $authHost.post("/api/v1/refreshToken",
        //     { username: inf.userName, refreshToken});
        // localStorage.setItem("token", data.token);
        //localStorage.setItem("refreshToken", data.refreshToken);
        //localStorage.setItem("roles", JSON.stringify(data.roles))
        if (token) {
            dispatch({
                type: LOGIN_SUCCESS,
            });
            dispatch({
                type: LOGIN_SET,
                payload: inf
            });
            dispatch({
                type: ROLES_SET,
                payload: inf.roles
            });
            dispatch(getProfile(inf?.userId))
            let isClient
            if (inf.roles) {
                isClient = inf.roles.length === 1 && inf.roles[0] === 'Client'
            }
            return isClient
        } else {
            dispatch(logout())
        }


    } catch (error) {}
};

export const changePassword = (oldPassword, newPassword) => async (dispatch) => {
    try {
        const {userName} = JSON.parse(localStorage.getItem('userInfo'))
        const { data } = await $authHost.post("/api/v1/user/changePassword",
            {userName, oldPassword, newPassword});
        return data

    } catch (error) {}
};


export const getUserInfo = (userId) => async (dispatch) => {
    const data = await dispatch(getClients(1, 1, {filter: {
            userId: {
                operand1: userId,
                operator: "equals"
            },
        }}, true))
    if (data?.result) {
        dispatch({
            type: USER_INFO_SET,
            payload: data.result[0]
        });
        return data.result
    }
}


export const logout = () => async (dispatch) => {
    await localStorage.removeItem("token");
    await localStorage.removeItem("userInfo");
    await dispatch({ type: LOGIN_RESET });
};

export const showSuccessSnackbar = (message, severity) => {
    return dispatch => {
        dispatch({ type: "SNACKBAR_SUCCESS", message, severity });
    };
};

export const clearSnackbar = () => {
    return dispatch => {
        dispatch({ type: "SNACKBAR_CLEAR" });
    };
};