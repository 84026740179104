import React from 'react';
import Dashboard from "../../layouts/Dashboard";
import TableCustomVariantTwo from "../../components/TableCustom/TableCustomVariantTwo";
import {TableCell, TableRow} from "@mui/material";
import Box from "@mui/material/Box";
import BreadcrumbsShared from "../../components/Shared/BreadcrumbsShared";
import {OHVAT_ROUTE} from "../../routes/const";

function Ohvat(props) {

    return (
        <Dashboard>
            <BreadcrumbsShared breadcrumbs={[
                {text: 'Охват рынка', link: OHVAT_ROUTE}
            ]}/>
            <TableCustomVariantTwo name="Период" header={<React.Fragment>
                <TableRow sx={{width: '100%'}}>
                    <TableCell align="center" colSpan={1}>
                        №
                    </TableCell>
                    <TableCell align="center" colSpan={2}>
                        Филиал/Отделение
                    </TableCell>
                    <TableRow sx={{width: '100%'}}>
                        <TableRow sx={{width: '100%'}}>
                            <TableCell align="center" colSpan={4}>
                                Рынок
                            </TableCell>
                            <TableCell sx={{width: '100%', padding: 0}} align="center" colSpan={5} >
                                <TableRow sx={{width: '100%'}}>
                                    <TableCell align="center" colSpan={5}>
                                        Охват рынка
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{width: '100%'}}>
                                    <TableCell sx={{width: '100%'}} align="center" colSpan={2}>
                                        Субъекты
                                    </TableCell>
                                    <TableCell sx={{width: '100%'}} align="center" colSpan={2}>
                                        Объекты
                                    </TableCell>
                                    <TableCell sx={{width: '100%'}} align="center" colSpan={1}>
                                        Тенге
                                    </TableCell>
                                </TableRow>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center">
                                Население
                            </TableCell>
                            <TableCell align="center">
                                Количество субъектов
                                (юр.лица и ИП)
                            </TableCell>
                            <TableCell align="center" >
                                Количество объектов
                            </TableCell>
                            <TableCell align="center" >
                                Объем рынка в тенге
                            </TableCell>
                            <TableCell align="center">
                                Количество
                            </TableCell>
                            <TableCell align="center">
                                %
                            </TableCell>
                            <TableCell align="center">
                                Количество
                            </TableCell>
                            <TableCell align="center">
                                %
                            </TableCell>
                            <TableCell align="center">
                                Сумма по заключенным договорам
                            </TableCell>
                        </TableRow>
                    </TableRow>
                </TableRow>
            </React.Fragment>}/>
            <Box sx={{marginTop: 10}}>
                <TableCustomVariantTwo header={<React.Fragment>
                    <TableRow sx={{width: '100%'}}>
                        <TableCell align="center" colSpan={1}>
                            №
                        </TableCell>
                        <TableCell align="center" colSpan={2}>
                            Филиал/Отделение
                        </TableCell>
                        <TableCell align="center" colSpan={2}>
                            Виды объектов
                        </TableCell>
                        <TableCell align="center" colSpan={2}>
                            Количество объектов
                        </TableCell>
                        <TableCell align="center" colSpan={2}>
                            Количество договоров
                        </TableCell>
                        <TableCell align="center" colSpan={2}>
                            Средний чек договора
                        </TableCell>
                        <TableCell align="center" colSpan={2}>
                            Объем рынка в тенге
                        </TableCell>
                        <TableRow >
                            <TableCell align="center" colSpan={2}>
                                Охват в объектах
                            </TableCell>
                            <TableCell align="center" colSpan={1}>
                                Охват в тенге
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center">
                                Количество
                            </TableCell>
                            <TableCell align="center">%</TableCell>
                            <TableCell align="center" >%</TableCell>
                        </TableRow>
                    </TableRow>
                </React.Fragment>}/>
            </Box>

        </Dashboard>
    );
}

export default Ohvat;