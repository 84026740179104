import React, {createContext, useEffect, useMemo, useState} from 'react';
import DashboardLayout from "../../layouts/Dashboard";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {getRequestSection} from "../../redux/actions/request/reqestActions";
import {
    getContractsPayState,
    getOneContract
} from "../../redux/actions/contract/contractActions";
import {getEducationServiceKind} from "../../redux/actions/educationService/educationServiceActions";
import {getGeoObject} from "../../redux/actions/geo/geoActions";
import { getUnitOfMeasure} from "../../redux/actions/genericActions";
import {v4 as uuidv4} from "uuid";
import {
    editContractRequest,
    getOneContractRequest
} from "../../redux/actions/contractRequest/contractRequestActions";
import Status from "../../components/Status/Status";
import Box from "@mui/material/Box";
import {
    Button, Checkbox, CircularProgress,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    Paper, Radio,
    RadioGroup,
    Stack,
    Switch
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {
    DatePickerShared,
    PopupLegalClient,
    PopupPerson,
    SelectShared,
    TableMin
} from "../../components";
import {ContractRequestObject, EducationServicesTableContractRequest} from "../index";
import ContractRequestAdditionallyInfo from "./Add/ContractRequestAdditionallyInfo";
import ContractRequestContract from "./Add/ContractRequestContract";
import {LoadingButton} from "@mui/lab";
import StatusView from "../../components/Status/StatusView";
import moment from "moment";
import {showSuccessSnackbar} from "../../redux/actions/userActions";
import {getAnObjectKind, getTransportType} from "../../redux/actions/anObject/anObjectActions";
import ResearchSchedule from "../../components/Contract/ResearchSchedule";
import {ContractRequestAddContext} from "./ContractRequestAdd";
import BreadcrumbsShared from "../../components/Shared/BreadcrumbsShared";
import {CONTRACT_REQUESTS_ROUTE} from "../../routes/const";
import ContractField from "../../components/Fields/ContractField";
import PersonField from "../../components/Fields/PersonField";
import LegalClientField from "../../components/Fields/LegalClientField";
import EmployeeField from "../../components/Fields/EmployeeField";

export const ContractRequestContext = createContext();

function ContractRequestEdit(props) {
    const {id} = useParams()
    const [number, setNumber] = useState(1)

    const [createdAt, setCreatedAt] = useState(new Date())
    const dispatch = useDispatch()

    const [client, setClient] = useState('')
    const [legalClient, setLegalClient] = useState('')
    const [typeClient, setTypeClient] = useState(false)
    const {
        legalClient: legalClientRedux,
        legalClientCount: legalClientReduxCount
    } = useSelector((state) => state.legalClient);
    const {persons, personsCount} = useSelector((state) => state.person);

    const [educationServices, setEducationServices] = useState([])
    const [localEducationServiceKind] = useState('')
    const {educationServiceKind} = useSelector((state) => state.education);

    const [contractId, setContractId] = useState('')
    const {contracts, contractsCount} = useSelector((state) => state.contract);

    const [contractType, setContractType] = useState(1)
    const [paymentPassed, setPaymentPassed] = useState(false)
    const [signBySystem, setSignBySystem] = useState(false)

    const [contractRequestObject, setContractRequestObject] = useState([])
    const {geo} = useSelector((state) => state.geo);

    const {employee, employeeCount} = useSelector((state) => state.employee);
    const {requestSection} = useSelector((state) => state.request);
    const [localEmployee, setLocalEmployee] = useState('')
    const [localRequestSection, setLocalRequestSection] = useState(1)
    const [localEmployeeResponsible, setLocalEmployeeResponsible] = useState('')

    // Договор
    const [oneContract, setOneContract] = useState({})
    const [contractNumber, setContractNumber] = useState('')
    const [contractActionAt, setContractActionAt] = useState(Date.now())
    const [contractState, setContractState] = useState(1)
    const [contractFilial, setContractFilial] = useState(1)
    const [contractSummary, setContractSummary] = useState(1)
    const [contractFilialDivision, setContractFilialDivision] = useState(1)
    const [researchCount, setResearchCount] = useState(1)
    const [paySummary, setPaySummary] = useState(1)
    const [realizationSummary, setRealizationSummary] = useState(1)
    const [contractPaySummary, setContractPaySummary] = useState(1)
    const [contractPaySummaryRemainder, setContractPaySummaryRemainder] = useState(1)
    const [contractPayState, setContractPayState] = useState(1)

    // Специфики
    const [specification, setSpecification] = useState([])
    const [localServiceKind, setLocalServiceKind] = useState(1)
    const [unitOfMeasure, setUnitOfMeasure] = useState(1)
    const {unityOfMeasure, serviceKind} = useSelector((state) => state.generic);
    const {anObjects, anObjectsCount} = useSelector((state) => state.anObject);
    const {servicesSchedule, servicesScheduleCount} = useSelector((state) => state.service);
    const [anObjectsLocal, setAnObjectsLocal] = useState(1)
    const [servicesScheduleLocal, setServicesScheduleLocal] = useState(1);
    const [service, setService] = useState(1)
    const {services, servicesCount} = useSelector((state) => state.service);
    const {contractRequestOne} = useSelector((state) => state.contractRequest);
    const [qty, setQty] = useState(1)
    const [loadingAdd, setLoadingAdd] = useState(false)

    const [objectKind, setObjectKind] = useState(1)
    const [contractDeadline, setContractDeadline] = useState(new Date())
    const [departureOfSpecialists, setDepartureOfSpecialists] = useState(false)
    const [personTransportType, setPersonTransportType] = useState(1)
    const [legalPersonTransportType, setLegalPersonTransportType] = useState(1)

    const handleDeleteSpec = async (row) => {
        const newSpec = specification.filter(el => el.Id !== row)
        await setSpecification(newSpec);
    }

    const [openPerson, setOpenPerson] = useState(false);
    const [openLegalClient, setOpenLegalClient] = useState(false);

    useEffect(() => {
        if (!signBySystem) {
            dispatch(getAnObjectKind(1, 100))
            dispatch(getTransportType(1, 100))
        }
    }, [dispatch, signBySystem])

    const [data, setData] = useState({})

    useEffect(() => {
        (async function () {
            setLoading(true)
            let resUnit
            let resServiceKind
            let resOneContractRequest
            const resReqSection = await dispatch(getRequestSection())
            await dispatch(getEducationServiceKind())
            await dispatch(getGeoObject(1, 100000, {
                filter: {
                    typeId: {
                        operand1: 2,
                        operator: "equals"
                    },
                }
            }))
            resUnit = await dispatch(getUnitOfMeasure(1, null, 30))
            await dispatch(getContractsPayState())
            resOneContractRequest = await dispatch(getOneContractRequest(id))
            setData(resOneContractRequest)
            const res = await dispatch(getOneContract(resOneContractRequest?.contractId))
            setContractNumber(res?.number)
            setOneContract(res)
            setStatus(resOneContractRequest?.state?.id)
            if (resReqSection.result.length) {
                setTypeClient(resOneContractRequest?.client?.personId)
                if (resOneContractRequest?.client?.personId) {
                    setClient(resOneContractRequest?.client?.personId)
                } else if (resOneContractRequest?.client?.legalPersonId) {
                    setLegalClient(resOneContractRequest?.client?.legalPersonId)
                }
                setResearchCount(res.specifications?.map(item => item.quantity).reduce((prev, curr) => prev + curr, 0))
                setNumber(resOneContractRequest?.number)
                setContractFilial(resOneContractRequest?.contractFilialId)
                setContractFilialDivision(resOneContractRequest?.contractFilialDivisionId)
                setLocalEmployee(resOneContractRequest?.performerId)
                setLocalEmployeeResponsible(resOneContractRequest?.responsibleId)
                setCreatedAt(resOneContractRequest?.createdAt)
                setLocalRequestSection(resOneContractRequest?.viewSectionId)
                setContractId(resOneContractRequest?.contractId)
                setUnitOfMeasure(resUnit[0].id)
                setContractType(resOneContractRequest?.contractType)
                setSignBySystem(resOneContractRequest?.signBySystem)
                setPaymentPassed(resOneContractRequest?.paymentPassed)
                if (resOneContractRequest?.signBySystem === false) {
                    setObjectKind(resOneContractRequest?.objectKindId)
                    setContractDeadline(resOneContractRequest?.contractDeadline)
                    setDepartureOfSpecialists(resOneContractRequest?.departureOfSpecialists)
                    setPersonTransportType(resOneContractRequest?.personTransportTypeId)
                }
                setLocalRequestSection(resReqSection.result[0]?.id)
                setContractPayState(resOneContractRequest?.contractPayStateId)

                setContractRequestObject(resOneContractRequest.objects.map(el => {
                    return {
                        Id: uuidv4(),
                        name: el.name,
                        district: '',
                        districtId: el?.districtId,
                        city: '',
                        cityId: el?.cityId,
                        locality: '',
                        localityId: el?.localityId,
                        addressLine: el?.addressLine,
                        objectId: el?.objectId,
                        object: el?.object,
                    }
                }))
                setSpecification(resOneContractRequest.specifications.map(el => {
                    return {
                        Id: uuidv4(),
                        service: el.service,
                        object: el.object,
                        serviceId: el.serviceId,
                        unit: el.unit,
                        contractCount: el.contractCount,
                        unitId: el.unitId,
                        quantity: el.quantity,
                        price: el.price,
                        count: el.count,
                        objectId: el.objectId,
                        scheduleId: el.scheduleId,
                        schedule: el.schedule
                    }
                }))
                setEducationServices(resOneContractRequest?.educationServices.map(el => {
                    return {
                        Id: uuidv4(),
                        kind: '',
                        kindId: el?.kindId,
                        service: '',
                        serviceId: el?.serviceId
                    }
                }))
                setSchedules(resOneContractRequest?.schedules.map(el => {
                    return {
                        Id: uuidv4(),
                        targetMonth: el.targetMonth,
                        targetYear: el.targetYear,
                    }
                }))
            }
            setLoading(false)
        }())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, id])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleEducationServices = () => {
        const educationServiceKindIdx = educationServiceKind.findIndex(el => el.id === localEducationServiceKind)
        const newElement = {
            Id: uuidv4(),
            kind: educationServiceKind[educationServiceKindIdx]?.name,
            kindId: localEducationServiceKind,
            service: '',
            serviceId: '',
        }
        setEducationServices(oldArray => [...oldArray, newElement])
    }

    const handleContractRequest = () => {
        const newElement = {
            Id: uuidv4(),
            name: '',
            district: '',
            districtId: '',
            city: '',
            cityId: '',
            locality: '',
            localityId: '',
            addressLine: ''
        }
        setContractRequestObject(oldArray => [...oldArray, newElement])
    }

    const handleFormSpec = async (e) => {
        e.preventDefault()
        const newElement = {
            Id: uuidv4(),
            service: {},
            serviceId: '',
            unitId: '',
            unit: {},
            quantity: qty,
            square: 1,
            multiplicity: 1,
            price: 0,
            objectId: '',
            count: 0,
            scheduleId: '',
        }
        setSpecification(oldArray => [...oldArray, newElement])
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        setLoadingAdd(true)
        await dispatch(editContractRequest(id, educationServices, specification, contractRequestObject, number, createdAt, contractType, paymentPassed, signBySystem, contractNumber,
            contractActionAt, researchCount, paySummary, contractSummary, realizationSummary, contractPaySummary, contractPaySummaryRemainder, contractDeadline,
            departureOfSpecialists, typeClient ? client : legalClient, localEmployee, localEmployeeResponsible, localRequestSection, contractId,
            contractState, contractFilial, contractFilialDivision, contractPayState, !signBySystem ? objectKind : null, !signBySystem ? personTransportType : null, !signBySystem ? legalPersonTransportType : null, contractFilial, typeClient ? 'personId' : 'legalPersonId', schedules))
        setLoadingAdd(false)
        dispatch(showSuccessSnackbar("Заявка обновлена!", "success"))
    }

    const {anObjectKind, transportType} = useSelector((state) => state.anObject);

    const [alignment, setAlignment] = useState('see');

    const [schedules, setSchedules] = useState([])
    const [localTargetMonth] = useState(new Date())
    const [localTargetYear] = useState(new Date())


    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleSchedules = () => {
        const newElement = {
            Id: uuidv4(),
            targetMonth: localTargetMonth,
            targetYear: localTargetYear,
        }
        setSchedules(oldArray => [...oldArray, newElement])
    }

    const handleChange = (event, newAlignment) => {
        if (newAlignment)
            setAlignment(newAlignment);
    };
    const {contractsState, contractsPayState} = useSelector((state) => state.contract);
    const {filial, filialDivision} = useSelector((state) => state.filial);


    const RenderContractRequest = useMemo(() => {
        return (
            <Box component={Paper}
                 sx={{mt: 5, display: 'flex', padding: '0px 10px', flexWrap: 'wrap'}}>
                {alignment === 'see' && <Grid sx={{paddingTop: 2, paddingBottom: 2}} container spacing={2}>
                    <Grid item xs={12}>
                        Договор: {oneContract.typeId === 1 ? 'ФЛ или разовые ЮЛ и ИП' : contractType === 2 ? 'ЮЛ и ИП' : ''}
                    </Grid>
                    <Grid item xs={12}>
                        Отправлять договор на подписание через систему: {!signBySystem ? 'Да' : 'Нет'}
                    </Grid>
                    <Grid item xs={12}>
                        Id договора: {oneContract?.id}
                    </Grid>
                    <Grid item xs={6}>
                        <FormGroup>
                            <FormControlLabel disabled control={<Checkbox checked={paymentPassed}
                                                                          onChange={(event) => setPaymentPassed(event.target.checked)}/>}
                                              label="Оплата прошла"/>
                        </FormGroup>
                    </Grid>
                </Grid>}
                {alignment === 'edit' && <Grid sx={{paddingTop: 2, paddingBottom: 2}} container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Договор</FormLabel>
                            <RadioGroup
                                aria-label="gender"
                                name="controlled-radio-buttons-group"
                                value={contractType}
                                onChange={(event => setContractType(event.target.value))}
                            >
                                <FormControlLabel value={1} control={<Radio/>} label="ФЛ или разовые ЮЛ и ИП"/>
                                <FormControlLabel value={2} control={<Radio/>} label="ЮЛ и ИП"/>
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormGroup sx={{display: 'flex', justifyContent: 'center'}}>
                            <Typography>Отправлять договор на подписание через систему</Typography>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography>Да</Typography>
                                <Switch onChange={(event) => setSignBySystem(event.target.checked)}
                                        checked={signBySystem}
                                        inputProps={{'aria-label': 'ant design'}}/>
                                <Typography>Нет</Typography>
                            </Stack>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <ContractField
                            fullWidth
                            object={oneContract}
                            value={contractId}
                            disabled
                            onChange={(contract) => {
                                setContractId(contract.id)
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={paymentPassed}
                                                                 onChange={(event) => setPaymentPassed(event.target.checked)}/>}
                                              label="Оплата прошла"/>
                        </FormGroup>
                    </Grid>

                </Grid>}
            </Box>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alignment, contractId, contractType, contracts, contractsCount, paymentPassed, signBySystem])

    const RenderChooseAdditionalInfo = useMemo(() => {
        if (!signBySystem) return false;
        if (signBySystem) return (
            <Box component={Paper} sx={{mt: 5, display: 'flex', padding: '0px 10px', flexWrap: 'wrap'}}>
                <ContractRequestAdditionallyInfo anObjectKind={anObjectKind} transportType={transportType}
                                                 mode={alignment}
                                                 setLegalPersonTransportType={setLegalPersonTransportType}
                                                 legalPersonTransportType={legalPersonTransportType}
                                                 dispatch={dispatch} signBySystem={signBySystem}
                                                 setPersonTransportType={setPersonTransportType}
                                                 personTransportType={personTransportType}
                                                 setDepartureOfSpecialists={setDepartureOfSpecialists}
                                                 departureOfSpecialists={departureOfSpecialists}
                                                 setContractDeadline={setContractDeadline}
                                                 contractDeadline={contractDeadline} setObjectKind={setObjectKind}
                                                 objectKind={objectKind}/>
            </Box>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alignment, anObjectKind, contractDeadline, departureOfSpecialists, dispatch, legalPersonTransportType, objectKind, personTransportType, signBySystem, transportType])

    const RenderEducationServices = useMemo(() => {
        return (
            <Box component={Paper} sx={{marginTop: 5}}>
                <EducationServicesTableContractRequest edit mode={alignment}
                                                       setEducationServices={setEducationServices}
                                                       educationServiceKind={educationServiceKind}
                                                       localEducationServiceKind={localEducationServiceKind}
                                                       educationServices={educationServices}/>
                {alignment === 'edit' && <Button onClick={handleEducationServices}>Добавить блок</Button>}
            </Box>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alignment, educationServiceKind, educationServices, handleEducationServices, localEducationServiceKind])

    const RenderContractObject = useMemo(() => {
        if (!contractId) return false
        return (
            <Box component={Paper} sx={{marginTop: 5}}>
                <ContractRequestContext.Provider value={{anObjectsCount, anObjects}}>
                    <ContractRequestObject mode={alignment} edit geo={geo}
                                           contractRequestObject={contractRequestObject}
                                           setContractRequestObject={setContractRequestObject}/>
                </ContractRequestContext.Provider>
                {alignment === 'edit' && <Button onClick={handleContractRequest}>Добавить блок</Button>}
            </Box>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alignment, anObjects, anObjectsCount, contractRequestObject, geo])

    const RenderContractContract = useMemo(() => {
        if (!contractId) return false
        return (
            <Box component={Paper} sx={{mt: 5, display: 'flex', padding: '0px 10px', flexWrap: 'wrap'}}>
                <ContractRequestContract mode={alignment} setContractPaySummary={setContractPaySummary}
                                         contractPaySummary={contractPaySummary} contractSummary={contractSummary}
                                         setContractSummary={setContractSummary} dispatch={dispatch}
                                         contractNumber={contractNumber} setContractNumber={setContractNumber}
                                         oneContract={oneContract}
                                         contractsPayState={contractsPayState}
                                         filial={filial}
                                         filialDivision={filialDivision}
                                         contractsState={contractsState}
                                         contractActionAt={contractActionAt}
                                         setContractActionAt={setContractActionAt} contractState={contractState}
                                         setContractState={setContractState}
                                         contractFilial={contractFilial} setContractFilial={setContractFilial}
                                         contractFilialDivision={contractFilialDivision}
                                         setContractFilialDivision={setContractFilialDivision}
                                         researchCount={researchCount} setResearchCount={setResearchCount}
                                         paySummary={paySummary} setPaySummary={setPaySummary}
                                         realizationSummary={realizationSummary}
                                         setRealizationSummary={setRealizationSummary}
                                         contractPaySummaryRemainder={contractPaySummaryRemainder}
                                         setContractPaySummaryRemainder={setContractPaySummaryRemainder}
                                         contractPayState={contractPayState}
                                         setContractPayState={setContractPayState}/>
            </Box>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alignment, contractActionAt, contractFilial, contractFilialDivision, contractNumber, contractPayState, contractPaySummary, contractPaySummaryRemainder, contractState, contractSummary, dispatch, oneContract, paySummary, realizationSummary, researchCount])

    const RenderTableMin = useMemo(() => {
        if (!contractId) return false
        return (
            <Box component={Paper} sx={{mt: 5, display: 'flex', padding: '0px 10px', flexWrap: 'wrap'}}>
                <TableMin count mode={alignment} edit={true} servicesCount={servicesCount}
                          handleDeleteSpec={handleDeleteSpec}
                          unityOfMeasure={unityOfMeasure} setUnitOfMeasure={setUnitOfMeasure}
                          unitOfMeasure={unitOfMeasure}
                          services={services.map(el => ({id: el.id, name: el.name, price: el.price}))}
                          setService={setService} service={service} serviceKind={serviceKind}
                          setLocalServiceKind={setLocalServiceKind} localServiceKind={localServiceKind}
                          setQty={setQty} qty={qty} setServicesScheduleLocal={setServicesScheduleLocal}
                          servicesScheduleLocal={servicesScheduleLocal} setAnObjectsLocal={setAnObjectsLocal}
                          anObjectsLocal={anObjectsLocal} servicesScheduleCount={servicesScheduleCount}
                          servicesSchedule={servicesSchedule} anObjectsCount={anObjectsCount} anObjects={anObjects}
                          setSpecification={setSpecification} specification={specification}/>
                {alignment === 'edit' && <Button onClick={handleFormSpec}>Добавить блок</Button>}
            </Box>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alignment, anObjects, anObjectsCount, anObjectsLocal, handleDeleteSpec, handleFormSpec, localServiceKind, qty, service, serviceKind, services, servicesCount, servicesSchedule, servicesScheduleCount, servicesScheduleLocal, specification, unitOfMeasure, unityOfMeasure])

    const RenderResearchSchedule = useMemo(() => {
        return (
            <Box component={Paper} sx={{mt: 5, display: 'flex', padding: '0px 10px', flexWrap: 'wrap'}}>
                <ContractRequestAddContext.Provider
                    value={{setSchedules, schedules, localTargetYear, localTargetMonth}}>
                    <ResearchSchedule mode={alignment}/>
                </ContractRequestAddContext.Provider>
                {alignment === 'edit' && <Button onClick={handleSchedules}>Добавить блок</Button>}
            </Box>
        )
    }, [alignment, handleSchedules, localTargetMonth, localTargetYear, schedules])


    const RenderChooseContract = useMemo(() => {
        return (
            <Box component={Paper}
                 sx={{mt: 5, display: 'flex', padding: '0px 10px', flexWrap: 'wrap'}}>
                <Typography sx={{margin: '5px 0'}} component="h1" align="center" variant="h5">
                    Сводная заявка
                </Typography>
                <Grid sx={{paddingTop: 2, paddingBottom: 2}} container spacing={2}>
                    {alignment === 'edit' && <>
                        <Grid item xs={6}>
                            <DatePickerShared value={createdAt} setValue={setCreatedAt}
                                              label="Дата время создания"/>
                        </Grid>
                        <Grid item xs={6}>
                            <EmployeeField
                                fullWidth
                                value={localEmployee}
                                object={data?.performer}
                                label="Исполнитель заявки"
                                onChange={(employee) => {
                                    setLocalEmployee(employee.id)
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <EmployeeField
                                fullWidth
                                value={localEmployeeResponsible}
                                object={data?.responsible}
                                label="Ответственный за отбор проб/Специалист ЦД или отдела Дезинфекции"
                                onChange={(employee) => {
                                    setLocalEmployeeResponsible(employee.id)
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SelectShared array={requestSection} value={localRequestSection}
                                          setValue={setLocalRequestSection} label="Просмотреть пункты заявки"/>
                        </Grid>
                        <Grid item xs={6}>
                            <FormGroup sx={{display: 'flex', justifyContent: 'center'}}>
                                <Typography>Тип клиента</Typography>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography>юр. лицо</Typography>
                                    <Switch onChange={(event) => setTypeClient(event.target.checked)}
                                            checked={typeClient}
                                            inputProps={{'aria-label': 'ant design'}}/>
                                    <Typography>физ. лицо</Typography>
                                </Stack>
                            </FormGroup>
                        </Grid>
                        <Grid item xs={6}>
                            {typeClient ?
                                <PersonField
                                    fullWidth
                                    value={client}
                                    object={oneContract?.client?.person}
                                    label="Контакт (физ. лицо)"
                                    sx={{paddingTop: '35px'}}
                                    onChange={(client) => {
                                        setClient(client.id)
                                    }}
                                /> :
                                <LegalClientField
                                    fullWidth
                                    object={oneContract?.client?.legalPerson}
                                    value={legalClient}
                                    label="Контакт (юр. лицо)"
                                    sx={{paddingTop: '35px'}}
                                    onChange={(client) => {
                                        setLegalClient(client.id)
                                    }}
                                />}
                        </Grid>
                        <Grid item xs={6} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            {typeClient ?
                                <Button onClick={() => setOpenPerson(true)} color="info" variant="contained">Добавить
                                    физ лицо</Button> :
                                <Button onClick={() => setOpenLegalClient(true)} color="info" variant="contained">Добавить
                                    юр. лицо</Button>}
                        </Grid>
                    </>}
                    {alignment === 'see' && <>
                        <Grid item xs={6}>
                            Номер заявки: {number}
                        </Grid>
                        <Grid item xs={6}>
                            Дата действия: {moment(createdAt).format('LL')}
                        </Grid>
                        <Grid item xs={6}>
                            Исполнитель заявки (id): {data?.performer?.fullName}
                        </Grid>
                        <Grid item xs={6}>
                            Ответственный за отбор проб/Специалист ЦД или отдела Дезинфекции
                            (id): {data?.responsible?.fullName}
                        </Grid>
                        <Grid item xs={6}>
                            {requestSection.find(el => el.id === localRequestSection)?.name}
                        </Grid>
                        <Grid item xs={6}>
                            Тип клиента: {typeClient ? 'Физ. лицо' : 'Юр. лио'}
                        </Grid>
                        <Grid item xs={6}>
                            {typeClient ?
                                `Физ. лицо (id): ${oneContract?.client?.person?.fullName}` :
                                `Юр. лицо (id): ${oneContract?.client?.legalPerson?.name}`
                            }
                        </Grid>
                    </>}

                </Grid>
            </Box>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alignment, client, createdAt, employee, employeeCount, legalClient, legalClientRedux, legalClientReduxCount, localEmployee, localEmployeeResponsible, localRequestSection, number, persons, personsCount, requestSection, typeClient]);

    const [status, setStatus] = useState(2)
    const [loading, setLoading] = useState(false)

    return (
        <DashboardLayout>
            <BreadcrumbsShared breadcrumbs={[
                {text: 'Сводные заявки', link: CONTRACT_REQUESTS_ROUTE},
                {text: 'Редактирование сводной заявки', link: CONTRACT_REQUESTS_ROUTE + `/${id}`},
            ]}/>
            {loading ? <Box sx={{
                minHeight: 556,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}><CircularProgress/></Box> : <Box>
                <StatusView status={status} alignment={alignment} handleChange={handleChange}/>
                <Status status={contractRequestOne?.state?.name}/>
                {RenderContractRequest}
                {RenderChooseAdditionalInfo}
                <Box component="form" onSubmit={handleSubmit}>
                    {RenderChooseContract}
                    {RenderContractObject}
                    {RenderEducationServices}
                    {RenderContractContract}
                    {RenderResearchSchedule}
                    {RenderTableMin}
                    {(alignment === 'edit' || !alignment) && <>
                        <LoadingButton
                            loading={loadingAdd}
                            type="submit"
                            variant="contained"
                            sx={{mt: 3, mb: 2}}
                        >
                            Изменить заявку
                        </LoadingButton>
                    </>}
                </Box>
                <PopupLegalClient details={true} title="Клиенты (юр. лица)" open={openLegalClient}
                                  setOpen={setOpenLegalClient}/>
                <PopupPerson details={true} title="Клиенты (физ. лица)" open={openPerson}
                             setOpen={setOpenPerson}/>
            </Box>}
        </DashboardLayout>
    );
}

export default React.memo(ContractRequestEdit);
