import React from 'react';
import {Button, Paper, Typography} from "@mui/material";
import Box from "@mui/material/Box";

function Status({onClick, type, status, buttonText}) {
    const statusName = {
        request: 'Статус заявки',
        completionAct: 'Статус акта',
        invoice: 'Статус счета',
    }

    const name = statusName[type === 1 ? 'request': type === 2 ? 'completionAct' : 'invoice']

    return (
        <Box component={Paper} sx={{marginTop: 5, marginBottom: 5, display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '15px 10px 15px 10px'}}>
            <Typography variant="h5" component="div">{name}: {status}</Typography>
            {buttonText && <Button onClick={onClick} variant="contained"
                    color="primary">{buttonText}</Button>}
        </Box>
    );
}

Status.defaultProps = {
    type: 1,
}

export default Status;