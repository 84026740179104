import {$authHost} from "../../../http";

export const editData = (get, success, err, link, funcGet, data) => async (dispatch) => {
    try {
        dispatch({
            type: get,
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.put(
            `api/v1/${link}`,
            {...data},
            config
        );
        dispatch(funcGet)
        dispatch({
            type: success,
        });
    } catch (error) {
        dispatch({
            type: err,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};