import React, {useState} from 'react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextShared from "../../components/Shared/TextShared";
import Button from "@mui/material/Button";
import {useDispatch} from "react-redux";
import {changePassword, showSuccessSnackbar} from "../../redux/actions/userActions";

function ChangePassword(props) {
    const dispatch = useDispatch()
    const [password, setPassword] = useState('')
    const [oldPassword, setOldPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const handleSubmit = async (event) => {
        event.preventDefault()
        if (password === confirmPassword) {
            await dispatch(changePassword(oldPassword, password))
        } else {
            dispatch(showSuccessSnackbar("Пароли не совпадают!", "error"))
        }
    }
    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextShared value={oldPassword} setValue={setOldPassword} required label="Старый пароль" type="password"/>
                </Grid>
                <Grid item xs={6}>
                    <TextShared value={password} setValue={setPassword} required label="Новый пароль" type="password"/>
                </Grid>
                <Grid item xs={6}>
                    <TextShared value={confirmPassword} setValue={setConfirmPassword} required label="Повторите новый пароль" type="password"/>
                </Grid>
            </Grid>
            <Button
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
            >
                Сохранить
            </Button>
        </Box>

    );
}

export default ChangePassword;