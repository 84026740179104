import React from 'react';
import {contractCRUD} from "../../../http/CRUD";
import CRUDTableQuery from "../CRUDTableQuery";
import {formatDate} from "../../../functions/formatters";
import {getContractStates} from "../../../redux/actions/contract/contractActions";
import {checkRoles} from "../../../functions/checkRoles";
import CRUDTableStandart from "../CRUDTableStandart";
import {getFilials} from "../../../redux/actions/filialActions";

export default function ContractCRUDTable({type = 'standart', ...props}) {
    const isSuperAdmin = checkRoles("SuperAdministrator")

    const columns = [
        {title: "#", dataIndex: 'id', key: 'id'},
        {
            title: "Название",
            key: 'name',
            render: (unknown, row) => row.name ? row.name :
                <span style={{color: "gray"}}>Не задан</span>
        },
        {
            title: "Дата окончания договора", dataIndex: 'actionAt', key: 'actionAt',
            render: (unknown, row) => row.actionAt ? formatDate(row.actionAt) :
                <span style={{color: "gray"}}>Не задана</span>
        },
        {
            title: "Клиент",
            key: 'client',
            render: (unknown, row) => row?.typeId === 5 ? <span style={{color: "gray"}}>Госзаказ</span> : row.client?.legalPersonId ? row.client?.legalPerson?.name : row.client?.personId ? row.client?.person?.fullName :
                <span style={{color: "gray"}}>Не задан</span>
        },
        {
            title: "Тип договора",
            key: 'typeId',
            render: (unknown, row) => row.typeId ? row.type?.name :
                <span style={{color: "gray"}}>Не задан</span>
        },
        {
            title: "Филиал",
            key: 'filial',
            render: (unknown, row) => row.filialId ? row.filial?.name :
                <span style={{color: "gray"}}>Не задан</span>
        },
        {
            title: "Сумма",
            key: 'summary',
            render: (unknown, row) => row.summary ? row.summary :
                <span style={{color: "gray"}}>Не задано</span>
        },
        {
            title: "Статус",
            key: 'state',
            render: (unknown, row) => row.state ? row.state?.name :
                <span style={{color: "gray"}}>Не задан</span>
        },
    ]
    const searchFields = [
        {
            title: "#",
            key: "id",
            compareType: "equals",
            operandPosition: 1,
            filterType: "normal"
        },
        {
            title: "Название договора",
            key: "name",
            compareType: "like",
            operandPosition: 1,
            filterType: "normal"
        },
        {
            title: "Номер",
            key: "number",
            compareType: "like",
            operandPosition: 1,
            filterType: "normal"
        }
    ]

    const filterFields = [
        {
            title: "Cтатус",
            key: "stateId",
            compareType: "equals",
            reducerKey: "contractState",
            reducerArrayKey: "contractStates",
            reducerAction: getContractStates,
            operandPosition: 1,
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        },
        {
            title: "Клиент",
            key: "clientId",
            fieldName: "ClientField",
            compareType: "equals",
            operandPosition: 1,
            type: "table",
            filterType: "normal",
        },
        {
            title: "Филиал",
            key: "filialId",
            compareType: "equals",
            reducerKey: "filials",
            reducerArrayKey: "filials",
            reducerAction: getFilials,
            operandPosition: 1,
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        },
        {
            title: "Отчетный месяц с",
            key: "actionAt",
            operandPosition: 1,
            compareType: "between",
            type: "date2",
            filterType: "normal",
        },
        {
            title: "Отчетный месяц по",
            key: "actionAt",
            operandPosition: 2,
            compareType: "between",
            type: "date2",
            filterType: "normal",
        },
    ]

    const CRUDTables = {
        standart: CRUDTableStandart,
        query: CRUDTableQuery,
    }

    const CRUDTable = CRUDTables[type]

    return (
        <CRUDTable search={contractCRUD.search}
                        columns={columns}
                        {...props}
                        filterFields={filterFields}
                        searchFields={searchFields}
                        rowClassNameHandler={(record) => {
                       if (record?.state?.code === "Signed") {
                           return 'rowSigned'
                       }
                   }}
                        extraFilter={{
                       ...(!isSuperAdmin && {
                           typeId: {
                               operand1: 5,
                               operator: "notEquals"
                           },
                       }),
                       ...(props?.extraFilter && {...props.extraFilter})
                   }}
        />
    );
}
