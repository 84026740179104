export const ADD_PRODUCT_TO_BASKET_REQUEST = 'ADD_PRODUCT_TO_BASKET_REQUEST'
export const ADD_PRODUCT_TO_BASKET_FAIL = 'ADD_PRODUCT_TO_BASKET_FAIL'
export const ADD_PRODUCT_TO_BASKET_SUCCESS = 'ADD_PRODUCT_TO_BASKET_SUCCESS'
export const ADD_PRODUCT_TO_BASKET_START = 'ADD_PRODUCT_TO_BASKET_START'

export const SET_COUNT_BASKET = 'SET_COUNT_BASKET'
export const DELETE_ITEM_FROM_BASKET = 'DELETE_ITEM_FROM_BASKET'
export const CLEAR_BASKET = 'CLEAR_BASKET'

export const CHANGE_BASKET_REQUEST = 'CHANGE_BASKET_REQUEST'
export const CHANGE_BASKET_SUCCESS = 'CHANGE_BASKET_SUCCESS'