import React, {useEffect, useState} from 'react';
import {
    Box,
    Grid,
    Popover,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tabs,
    TextField
} from "@mui/material";
import {useTranslation} from "react-i18next";
import Tab from "@mui/material/Tab";
import Button from "@mui/material/Button";
import {FileCard, PopupContract, TableTabLegalClient} from "../index";
import {useDispatch, useSelector} from "react-redux";
import {getOnePerson} from "../../redux/actions/client/personActions";
import {getOneLegalClient} from "../../redux/actions/client/legalClientActions";
import moment from "moment";
import 'moment/locale/ru'
import TableTabPerson from "./TableTabPerson";
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import TableTabSigners from "./TableTabSigners";
import PopupContractComment from "./PopupContractComment";
import {changeContractState, getOneContract} from "../../redux/actions/contract/contractActions";
import {checkRoles} from "../../functions/checkRoles";
import {useNCALayerClient} from "../../hooks/ecp/SignHooks";
import {contractRequestCRUD, contractSignerCRUD} from "../../http/CRUD";
import {LoadingButton} from "@mui/lab";
import useLoader from "../../hooks/useLoader";
import {showSuccessSnackbar} from "../../redux/actions/userActions";
import Typography from "@mui/material/Typography";
import {useHistory} from "react-router-dom";
import { CONTRACT_REQUESTS_ROUTE} from "../../routes/const";
import {$authHost} from "../../http";

window.Buffer = window.Buffer || require("buffer").Buffer;

moment.locale('ru')

function TableTabECP({rowsPage, locale, reload, reloadValue}) {

    const {t} = useTranslation();
    const [client, setClient] = useState([])
    const dispatch = useDispatch()
    const [contractRequest, setContractRequest] = useState([])
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        contractRequestCRUD.search(
            {
                paging: {skip: 0},
                filter: {
                    contractId: {
                        operand1: rowsPage?.number,
                        operator: "equals"
                    }
                }
            }
        ).then(({result}) => {
            setContractRequest(result)
        })
    }, [])

    const [clientNCA] = useNCALayerClient();

    useEffect(() => {
        if (!clientNCA)
            return;

        clientNCA.onmessage = function (msg) {
            console.log(msg)
            if (msg?.result === true) {
                dispatch(showSuccessSnackbar("Договор валидный", "success"))
            }
        };

    }, [clientNCA])

    const [conform, setConform] = useState(false)

    const isAdmin = checkRoles("SuperAdministrator", "Administrator")

    const {profile} = useSelector((state) => state.profile);

    useEffect(() => {
        (async function () {
            const personId = rowsPage?.client?.personId
            const legalPersonId = rowsPage?.client?.legalPersonId
            if (personId) {
                const data = await dispatch(getOnePerson(personId))
                setClient({...data, typeClient: 1})
            } else if (legalPersonId) {
                const data = await dispatch(getOneLegalClient(legalPersonId))
                setClient({...data, typeClient: 2})
            }
        }())
    }, [dispatch, rowsPage]);

    const [oneContract, setOneContract] = useState([])

    useEffect(() => {
        (async function () {
            const res = await dispatch(getOneContract(rowsPage?.number))
            setOneContract(res)
        }())
    }, [dispatch, rowsPage?.number])

    useEffect(() => {
        rowsPage.signers.forEach(el => {
            if (isAdmin && rowsPage.status === 'На согласовании' && el.isSigned !== true) {
                setConform(true)
            } else if ((el?.employee?.userId === profile?.userId || el?.userId === profile?.userId) && rowsPage.status === 'На согласовании' && el.isSigned !== true) {
                setConform(true)
            }
        })
    }, [profile?.userId, rowsPage.signers, rowsPage.status, reload, reloadValue])

    return (
        <Box sx={{padding: '0 20px'}}>
            <Tabs
                value={value}
                onChange={handleChange}
            >
                <Tab label="Основная информация"/>
                <Tab label="Клиент"/>
                {(rowsPage?.typeId === 1 || rowsPage?.typeId === 2) && <Tab label="Согласующие"/>}
            </Tabs>
            {value === 0 && <>
                {Object.keys(rowsPage).slice(0, 2).map((el, idx) => (
                    <Grid container key={idx}>
                        <Grid item xs={6}>
                            <Box sx={{
                                padding: '5px 0',
                                borderTop: "1 px",
                                borderBottom: '1px'
                            }}>{t(`${locale}.tableItem.${[el]}`)}</Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box sx={{
                                padding: '5px 0',
                                borderTop: "1 px",
                                borderBottom: '1px'
                            }}>{rowsPage[el] ? rowsPage[el] : 'Значение не задано'}</Box>
                        </Grid>
                    </Grid>
                ))}
            </>
            }
            {value === 1 && <>
                {client && client.typeClient === 1 ? <TableTabPerson client={client}/> : client.typeClient === 2 ?
                    <TableTabLegalClient client={client}/> : <Box>Клиент не указан</Box>}
            </>}
            {(rowsPage?.typeId === 1 || rowsPage?.typeId === 2) && value === 2 &&
                <TableTabSigners reloadValue={reloadValue} reload={reload} number={rowsPage?.number}
                                 status={rowsPage?.status} signers={rowsPage?.signers}/>}
        </Box>
    )
}

export default TableTabECP;
