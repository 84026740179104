import React from "react";
import {useTranslation} from "react-i18next";
import {useHistory, useLocation} from "react-router-dom";
import {formatDate, MOMENT_FORMAT_DATE_DMYHM_DOTS} from "../../../functions/formatters";
import {getFilials} from "../../../redux/actions/filialActions";
import {getFilialDivisions} from "../../../redux/actions/filialDivisionActions";
import {contractRequestCRUD} from "../../../http/CRUD";
import CRUDTableStandart from "../CRUDTableStandart";
import CRUDTableQuery from "../CRUDTableQuery";


export const ContractRequestCRUDTable = (props) => {
    const {t} = useTranslation();
    const {push} = useHistory();
    const {pathname} = useLocation();

    const columns = [
        {title: t("request.tableItem.id"), dataIndex: "id", key: "id"},
        {
            title: t("request.tableItem.number"),
            dataIndex: "number",
            key: "number",
        },
        {
            title: t("request.tableItem.contractId"),
            dataIndex: "contractId",
            key: "contractId",
            render: (row) => row || "-",
        },
        {
            title: t("request.tableItem.status"),
            dataIndex: "state",
            key: "state",
            render: (row) => row?.name || "-",
        },
        {
            title: 'Наименование клиента',
            dataIndex: "client",
            key: "client",
            render: (row) => row?.legalPerson ? row.legalPerson?.name : row?.person ? row.person?.fullName : "-",
        },
        {
            title: 'Исполнитель заявки',
            dataIndex: "performer",
            key: "performer",
            render: (row) => row?.fullName || "-",
        },
        {
            title: t("request.tableItem.createdAt"),
            dataIndex: "createdAt",
            key: "createdAt",
            render: (row) => formatDate(row, MOMENT_FORMAT_DATE_DMYHM_DOTS) || "-",
        },
        {
            dataIndex: "filial",
            key: "filial",
            title: "Филиал",
            render: (unknown, row) => {
                return row.filial?.name
            }
        },
        {
            dataIndex: "filialDivision",
            key: "filialDivision",
            title: "Районное отделение",
            render: (unknown, row) => {
                return row.filialDivision?.name
            }
        }
    ];

    const searchFields = [
        {
            title: "Номер",
            key: "number",
            compareType: "like",
            operandPosition: 1,
            filterType: "normal"
        }
    ];

    const filterFields = [

        {
            title: "Филиал",
            key: "filialId",
            compareType: "equals",
            reducerKey: "filials",
            reducerArrayKey: "filials",
            reducerAction: getFilials,
            operandPosition: 1,
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        },
        {
            title: "Районное отделение",
            key: "filialDivisionId",
            compareType: "equals",
            reducerKey: "filialDivision",
            reducerArrayKey: "filialDivisions",
            reducerAction: getFilialDivisions,
            operandPosition: 1,
            parentKey: 'filialId',
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        }
    ];

    const CRUDTables = {
        standart: CRUDTableStandart,
        query: CRUDTableQuery,
    }

    const CRUDTable = CRUDTables['standart']

    return (
        <CRUDTable
            searchFields={searchFields}
            filterFields={filterFields}
            search={contractRequestCRUD.search}
            columns={columns}
            onClickDetailsButton={(id) => {
                push(`${pathname}/${id}`)
            }}
            {...props}
        />
    );
};
