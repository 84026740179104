import {Button, Dialog, DialogContent, Divider, Stack} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import LegalPersonCRUDTable from "../Crud/Tables/LegalPersonCRUDTable";
import DealCRUDTable from "../Crud/Tables/DealCRUDTable";

export default function DealField({
                                          label,
                                          value,
                                          object,
                                          onChange,
                                          extraFilter,
                                          disabled,
                                          sx
                                      }) {
    const [open, setOpen] = useState(false);
    const [name, setName] = useState('')
    return (
        <>
            <Stack direction="row" mt={4} sx={sx} alignItems="center">
                <Typography style={{ fontWeight: "bold", width: "50%" }}>
                    {label || "Сделка"}:{" "}
                </Typography>
                <Typography>
                    {name || object?.name || `id сделки: ${value || 'не указано'}`}
                </Typography>
                <Button disabled={disabled} onClick={() => setOpen(true)} variant={"outlined"} style={{ marginLeft: "10px", outline: 'none' }}>Выбрать</Button>
            </Stack>
            {/* Dialog Select*/}
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                fullWidth
                PaperProps={{sx: {maxHeight: '85%'}}}
                maxWidth={"80%"}
            >
                <DialogTitle>Выбор сделки</DialogTitle>
                <Divider />
                <DialogContent>
                    <DealCRUDTable
                        edit={false}
                        type="standart"
                        extraFilter={extraFilter}
                        onClickCreateButton={undefined} // что бы не показывать кнопку создания
                        onRowClick={(record, rowIndex) => {
                            onChange(record);
                            setName(record.name)
                            setOpen(false);
                        }}
                    />
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button
                        variant={"text"}
                        style={{ marginLeft: "auto" }}
                        onClick={() => setOpen(false)}
                    >
                        Отменить
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
