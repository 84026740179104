import React, {useEffect, useState} from 'react';
import {Box, IconButton, TableCell, TableRow} from "@mui/material";
import {SelectFieldModal} from "../index";
import {confirmDialog} from "../Popups/message/PopupDelete";
import {useTranslation} from "react-i18next";
import {getEmployee} from "../../redux/actions/employee/employeeActions";
import AnObjectField from "../Fields/AnObjectField";
import EmployeeField from "../Fields/EmployeeField";

const TableMinSignersRow = ({edit, actions, row, signers, setSigners, handleIcons}) => {
    const handleRowVariant2 = (event, object, valueName, valueId, setValue) => {
        const dataIdx = signers.findIndex(el => el.Id === row.Id)
        let data = [...signers]
        data[dataIdx] = {...data[dataIdx], [valueName]: {...object},
            [valueId]: event}
        setSigners(data)
        setValue(event)
    }

    useEffect(() => {
        setLocalEmployee(row?.employeeId)
    }, [row, edit])

    const handleEmployeeRow = (event, employee) => {
        handleRowVariant2(+event, employee, 'employee','employeeId', setLocalEmployee)
    }
    const [localEmployee, setLocalEmployee] = useState('')
    return (
        <TableRow
            hover
            sx={{
                '&:last-child td, &:last-child th': {border: 0},
                cursor: 'pointer'
            }}>
            <TableCell align="center">
                <EmployeeField
                    fullWidth
                    object={row?.employee}
                    value={localEmployee}
                    onChange={(employee) => {
                        handleEmployeeRow(employee.id, employee)
                    }}
                />
            </TableCell>
            <TableCell align="center">
                <Box>
                    {actions.map((el, idx) => (
                        <IconButton data-testid={el.id}
                                    children={el.icon}
                                    onClick={(e) => {
                                        if (el.id === 'DeleteForeverIcon') {
                                            confirmDialog('Удаление элемента', 'Вы действительно хотите удалить этот элемент?', () => {
                                                return handleIcons(el.id, row.Id)
                                            })
                                        } else
                                            return handleIcons(el.id, row.Id)
                                    }}
                                    key={idx}>
                        </IconButton>
                    ))}
                </Box>
            </TableCell>
        </TableRow>
    );
}

export default TableMinSignersRow;