import {getData} from "../template/get";
import {
    GET_EDUCATION_SERVICE_ERROR,
    GET_EDUCATION_SERVICE_KIND_ERROR,
    GET_EDUCATION_SERVICE_KIND_REQUEST,
    GET_EDUCATION_SERVICE_KIND_SUCCESS, GET_EDUCATION_SERVICE_REQUEST, GET_EDUCATION_SERVICE_SUCCESS
} from "../../constants/educationService/educationServiceConstants";

export const getEducationServiceKind = (page, size, args) => async (dispatch) => {
    return await dispatch(getData(GET_EDUCATION_SERVICE_KIND_REQUEST, GET_EDUCATION_SERVICE_KIND_SUCCESS, GET_EDUCATION_SERVICE_KIND_ERROR, 'educationServiceKind', true, page, null, null, true, size, args))
};

export const getEducationService = (page, size, args) => async (dispatch) => {
    return await dispatch(getData(GET_EDUCATION_SERVICE_REQUEST, GET_EDUCATION_SERVICE_SUCCESS, GET_EDUCATION_SERVICE_ERROR, 'educationService', true, page, null, null, true, size, args))
};