import {
    FILIAL_DIVISION_GET_REQUEST, FILIAL_DIVISION_GET_SUCCESS, FILIAL_DIVISION_GET_ERROR, 
    FILIAL_DIVISION_GET_BY_KEY_REQUEST, FILIAL_DIVISION_GET_BY_KEY_SUCCESS, FILIAL_DIVISION_GET_BY_KEY_ERROR, 
    FILIAL_DIVISION_ADD_REQUEST, FILIAL_DIVISION_ADD_SUCCESS, FILIAL_DIVISION_ADD_ERROR, 
    FILIAL_DIVISION_EDIT_REQUEST, FILIAL_DIVISION_EDIT_SUCCESS, FILIAL_DIVISION_EDIT_ERROR, 
    FILIAL_DIVISION_DELETE_REQUEST, FILIAL_DIVISION_DELETE_SUCCESS, FILIAL_DIVISION_DELETE_ERROR
} from "../constants/filialDivisionConstants";
import {filialDivisionCRUD} from "../../http/CRUD";

export const getFilialDivisions = (args) => async (dispatch) => {
    try {
        dispatch({
            type: FILIAL_DIVISION_GET_REQUEST
        });
        const data = await filialDivisionCRUD.search(args);
        dispatch({
            type: FILIAL_DIVISION_GET_SUCCESS,
            payload: data.result,
        });
        return data.result;
    } catch (error) {
        dispatch({
            type: FILIAL_DIVISION_GET_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const getFilialDivisionByKey = (id) => async (dispatch) => {
    try {
        dispatch({
            type: FILIAL_DIVISION_GET_BY_KEY_REQUEST
        });

        const data = await filialDivisionCRUD.get(id);
        dispatch({
            type: FILIAL_DIVISION_GET_BY_KEY_SUCCESS,
            payload: data,
        });
        return data.result;
    } catch (error) {
        dispatch({
            type: FILIAL_DIVISION_GET_BY_KEY_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const addFilialDivision = (data, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: FILIAL_DIVISION_ADD_REQUEST
        });
        const response = await filialDivisionCRUD.create(data);
        dispatch(getFilialDivisions(queryArgs || {}));
        dispatch({
            type: FILIAL_DIVISION_ADD_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: FILIAL_DIVISION_ADD_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const editFilialDivision = (data, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: FILIAL_DIVISION_EDIT_REQUEST
        });
        await filialDivisionCRUD.edit(data);
        dispatch(getFilialDivisions(queryArgs || {}));
        dispatch({
            type: FILIAL_DIVISION_EDIT_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: FILIAL_DIVISION_EDIT_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const deleteFilialDivision = (id, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: FILIAL_DIVISION_DELETE_REQUEST
        });
        await filialDivisionCRUD.delete(id);
        dispatch(getFilialDivisions(queryArgs || {}));
        dispatch({
            type: FILIAL_DIVISION_DELETE_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: FILIAL_DIVISION_DELETE_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

