import {
    GET_CURRENCY_REQUEST, GET_CURRENCY_SUCCESS,
    GET_GENDER_ERROR,
    GET_GENDER_REQUEST,
    GET_GENDER_SUCCESS, GET_SERVICE_KIND_ERROR, GET_SERVICE_KIND_REQUEST, GET_SERVICE_KIND_SUCCESS,
    GET_UNIT_MEASURE_REQUEST, GET_UNIT_MEASURE_SUCCESS
} from "../constants/genericConstants";
import {getData} from "./template/get";

export const getGenderAll = () => async (dispatch) => {
    return await dispatch(getData(GET_GENDER_REQUEST, GET_GENDER_SUCCESS, GET_GENDER_ERROR, 'gender'))
};

export const getCurrency = () => async (dispatch) => {
    return await dispatch(getData(GET_CURRENCY_REQUEST, GET_CURRENCY_SUCCESS, GET_GENDER_ERROR, 'currency'))
};

export const getUnitOfMeasure = (page, name, size) => async (dispatch) =>  {
    return await dispatch(getData(GET_UNIT_MEASURE_REQUEST, GET_UNIT_MEASURE_SUCCESS, GET_GENDER_ERROR, 'unitOfMeasure', true, 1, page, name, false, 10000))
};

export const getServiceKind = (value, name) => async (dispatch) =>  {
    return await dispatch(getData(GET_SERVICE_KIND_REQUEST, GET_SERVICE_KIND_SUCCESS, GET_SERVICE_KIND_ERROR, 'serviceKind', true, 1, value, name))
};