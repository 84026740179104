import React from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {ContractRequestObject} from "../../../screens";
import {Button, Grid, InputAdornment} from "@mui/material";
import DealSpecifications from "./DealSpecifications";
import DealLeads from "./DealLeads";
import {Number} from "../../index";
import TextShared from "../../Shared/TextShared";
import useCreateObjectForm from "../../Crud/Models/useCreateObjectForm";

function DealClarificationofneed({district, data, handleContractObjectRequest, handleChangeObject, id, handleLeads, disabled}) {
    const { openCreateObjectForm, DIALOG } = useCreateObjectForm();
    return (
        <>
            <Typography sx={{margin: '20px 0'}}>Уточнение потребностей</Typography>
            <Box sx={{
                margin: '30px 0',
                width: '100%',
                overflowX: 'scroll',
                borderTop: '1px solid rgba(224, 224, 224, 1)',
                borderBottom: '1px solid rgba(224, 224, 224, 1)'
            }}>
                <DIALOG />
                <Typography sx={{marginTop: '5px'}} variant="h6"
                            component="h3">Объекты</Typography>
                <ContractRequestObject disabled={disabled} geo={district} contractRequestObject={data?.objects}
                                       setContractRequestObject={(e) => handleChangeObject('objects', e)}/>
                <Button disabled={disabled} onClick={handleContractObjectRequest}>Добавить блок</Button>
                <Button onClick={() => openCreateObjectForm()}>Создать объект</Button>
            </Box>
            <Box sx={{
                margin: '30px 0',
                width: '100%',
                borderTop: '1px solid rgba(224, 224, 224, 1)',
                borderBottom: '1px solid rgba(224, 224, 224, 1)'
            }}>
                <DealSpecifications disabled={disabled} dealId={id} handleChangeObject={handleChangeObject} data={data}/>
            </Box>
            <Box sx={{
                margin: '30px 0',
                width: '100%',
                borderTop: '1px solid rgba(224, 224, 224, 1)',
                borderBottom: '1px solid rgba(224, 224, 224, 1)'
            }}>
                <DealLeads disabled={disabled} handleLeads={handleLeads} handleChangeObject={handleChangeObject} data={data}/>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Number min={0} disabled={disabled} value={data?.budget} setValue={(e) => handleChangeObject('budget', e)} required={false}
                            label="Бюджет"/>
                </Grid>
                <Grid item xs={6}>
                    <Number input={{endAdornment: <InputAdornment position="end">%</InputAdornment>}} min={0} max={100} disabled={disabled} value={data?.probability} setValue={(e) => handleChangeObject('probability', e)} required={false}
                            label="Вероятность"/>
                </Grid>
                <Grid item xs={6}>
                    <TextShared disabled={disabled} value={data?.comment} setValue={(e) => handleChangeObject('comment', e)} required={false}
                                label="Примечание"/>
                </Grid>
                <Grid item xs={6}>
                    <TextShared disabled={disabled} value={data?.comments?.length ? data?.comments[1]?.comment : ''} setValue={(e) => {
                        let comments = [...data?.comments]
                        comments[1] = {...data?.comments[1], 'comment': e, dealId: data?.id, stageId: data?.stageId}
                        handleChangeObject('comments', [...comments])
                    }} required={false} label="Комментарии"/>
                </Grid>
            </Grid>
        </>
    );
}

export default DealClarificationofneed;