import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import StorefrontIcon from '@mui/icons-material/Storefront';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import {useTranslation} from "react-i18next";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import {useHistory, useLocation} from "react-router-dom";
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import {Divider} from "@mui/material";
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import {ListItemDropDown} from "../index";
import {useDispatch, useSelector} from "react-redux";
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import {logout} from "../../redux/actions/userActions";
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import DescriptionIcon from '@mui/icons-material/Description';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import QuizIcon from '@mui/icons-material/Quiz';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import SmsIcon from '@mui/icons-material/Sms';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import {useMemo} from "react";
import BookIcon from '@mui/icons-material/Book';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import SpeedIcon from '@mui/icons-material/Speed';
import FilterHdrIcon from '@mui/icons-material/FilterHdr';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import DataArrayIcon from '@mui/icons-material/DataArray';
import RouteIcon from '@mui/icons-material/Route';
import {intersection} from "../../functions";
import Inventory2Icon from '@mui/icons-material/Inventory2';

const MainListItems = () => {
    const {t} = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const {pathname} = useLocation()
    const {openListClient, openListSocial, openListService, openListTest, openListBookkeeping} = useSelector((state) => state.generic);

    let itemsFirst = useMemo(() => [
        {text: t("dashboard.listItem.general"), route: 'general', icon: <HomeWorkIcon/>,
        },
        {text: t("dashboard.listItem.applications"), route: 'applications', icon: <DashboardIcon/>,
            roles: ["SuperAdministrator", "Administrator", "Marketer"]
        },
        {text: t("dashboard.listItem.contractRequest"), route: 'contract-request', icon: <RequestPageIcon/>,
            roles: ["SuperAdministrator", "Administrator", "Marketer"]},
        {text: t("dashboard.listItem.contract"), route: 'contract', icon: <DescriptionIcon/>},
        {text: t("dashboard.listItem.contractAdditional"), route: 'contract-additional', icon: <ImportContactsIcon/>},
        {text: t("dashboard.listItem.geo"), route: 'geo', icon: <LocationOnIcon/>,
            roles: ["SuperAdministrator", "Administrator"]},
        {text: t("dashboard.listItem.deal"), route: 'deal', icon: <SyncProblemIcon/>,
            roles: ["SuperAdministrator", "Administrator", "Marketer"]},
        {text: t("dashboard.listItem.test"), route: 'test', icon: <QuizIcon/>,
            roles: ["SuperAdministrator", "Administrator"]},
        {text: t("dashboard.listItem.filialPackage"), route: 'filialPackage', icon: <Inventory2Icon/>,
            roles: ["SuperAdministrator", "Administrator"]}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ], [])
    let itemsSecond = useMemo(() => [
        {text: t("dashboard.listItem.legalClient"), route: 'legal-client', icon: <PeopleIcon/>,
            roles: ["SuperAdministrator", "Administrator", "Marketer"]},
        {text: t("dashboard.listItem.typeClient"), route: 'type-client', icon: <SupervisedUserCircleIcon/>,
            roles: ["SuperAdministrator", "Administrator", "Marketer"]},
        {text: t("dashboard.listItem.client"), route: 'person', icon: <PeopleOutlineIcon/>,
            roles: ["SuperAdministrator", "Administrator", "Marketer"]},
        {text: t("dashboard.listItem.clients"), route: 'clients', icon: <SupervisorAccountIcon/>,
            roles: ["SuperAdministrator", "Administrator", "Marketer"]},
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ], [])
    let itemsThird = useMemo(() => [
        {text: t("dashboard.listItem.service"), route: 'service', icon: <HomeRepairServiceIcon/>,
            roles: ["SuperAdministrator", "Administrator"]},
        {text: t("dashboard.listItem.typeService"), route: 'type-service', icon: <DesignServicesIcon/>,
            roles: ["SuperAdministrator", "Administrator"]},
        {text: t("dashboard.listItem.servicePriceList"), route: 'price-service', icon: <PriceChangeIcon/>,
            roles: ["SuperAdministrator", "Administrator"]},
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ], [])
    let itemsFourth = useMemo(() => [
        {text: t("dashboard.listItem.socialWhatsapp"), route: 'social-whatsapp', icon: <WhatsAppIcon/>,
            roles: ["SuperAdministrator", "Administrator"]},
        {text: t("dashboard.listItem.mail"), route: 'mail', icon: <MailOutlineIcon/>,
            roles: ["SuperAdministrator", "Administrator"]},
        {text: t("dashboard.listItem.sms"), route: 'sms', icon: <SmsIcon/>,
            roles: ["SuperAdministrator", "Administrator"]},
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ], [])
    let itemsFive = useMemo(() => [
        {text: t("dashboard.listItem.completionAct"), route: 'completion-act', icon: <BookIcon/>,
            roles: ["SuperAdministrator", "Administrator"]},
        {text: t("dashboard.listItem.invoice"), route: 'invoice', icon: <AccountBalanceWalletIcon/>,
            roles: ["SuperAdministrator", "Administrator"]},
        {text: t("dashboard.listItem.payment"), route: 'payment', icon: <PointOfSaleIcon/>,
            roles: ["SuperAdministrator", "Administrator"]},
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ], [])
    let itemsSix = useMemo(() => [
        {text: t("dashboard.listItem.ohvat"), route: 'ohvat', icon: <StorefrontIcon/>,
            roles: ["SuperAdministrator", "Administrator"]},
        {text: t("dashboard.listItem.eff"), route: 'eff', icon: <FilterHdrIcon/>,
            roles: ["SuperAdministrator", "Administrator"]},
        {text: t("dashboard.listItem.status"), route: 'status', icon: <MultipleStopIcon/>,
            roles: ["SuperAdministrator", "Administrator"]},
        {text: t("dashboard.listItem.date"), route: 'date', icon: <DataArrayIcon/>,
            roles: ["SuperAdministrator", "Administrator"]},
        {text: t("dashboard.listItem.obrash"), route: 'obrash', icon: <RouteIcon/>,
            roles: ["SuperAdministrator", "Administrator"]},
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ], [])
    let itemsLast = useMemo(() => [
        {text: t("dashboard.listItem.exit"), route: 'exit', icon: <ExitToAppIcon/>
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ], [])

    const jsonRoles = localStorage.getItem("roles");
    const roles = jsonRoles && JSON.parse(jsonRoles);

    const filterByRoles = (_) => {
        if (!roles) return _;
        return intersection(_, roles, (a, b) => {
            return !a.roles || a.roles.find((_) => _ == b);
        });
    };

    itemsFirst = filterByRoles(itemsFirst)
    itemsSecond = filterByRoles(itemsSecond)
    itemsThird = filterByRoles(itemsThird)
    itemsFourth = filterByRoles(itemsFourth)
    itemsFive = filterByRoles(itemsFive)
    itemsSix = filterByRoles(itemsSix)

        return (
            <>
                <div className="list-main">
                    {itemsFirst.map((el, idx) => (
                        <ListItem onClick={() => {
                            history.push('/' + el.route)
                        }} key={idx} button selected={pathname.split('/')[1] === el.route}>
                            <ListItemIcon>
                                {el.icon}
                            </ListItemIcon>
                            <ListItemText primary={el.text}/>
                        </ListItem>
                    ))}
                    <Divider/>
                    {itemsFive.length ? <><ListItemDropDown icon={<BookmarksIcon/>} open={openListBookkeeping} pathname={pathname}
                                      text={t("dashboard.listItem.bookkeeping")} items={itemsFive} type='BOOKKEEPING'/>
                    <Divider/></> : <></>}
                    {itemsSecond.length ? <><ListItemDropDown icon={<EmojiPeopleIcon/>} open={openListClient} pathname={pathname}
                                      text={t("dashboard.allClient")} items={itemsSecond} type='CLIENT'/>
                    <Divider/></> : <></>}
                    {itemsThird.length ? <><ListItemDropDown icon={<MedicalServicesIcon/>} open={openListService} pathname={pathname}
                                      text={t("dashboard.service")} items={itemsThird} type='SERVICE'/>
                    <Divider/></> : <></>}
                    {itemsSix.length ? <><ListItemDropDown icon={<SpeedIcon/>} open={openListTest} pathname={pathname}
                                      text={t("dashboard.listItem.report")} items={itemsSix} type='TEST'/>
                    <Divider/></> : <></>}
                    {itemsFourth.length ? <><ListItemDropDown icon={<ConnectWithoutContactIcon/>} open={openListSocial} pathname={pathname}
                                      text={t("dashboard.listItem.social")} items={itemsFourth} type='SOCIAL'/></> : <></>}
                    <Divider/>
                    {itemsLast.map((el, idx) => (
                        <ListItem onClick={() => {
                            dispatch(logout())
                        }} key={idx} button selected={pathname.split('/')[1] === el.route}>
                            <ListItemIcon>
                                {el.icon}
                            </ListItemIcon>
                            <ListItemText primary={el.text}/>
                        </ListItem>
                    ))}
                </div>
            </>
        )
};

export default React.memo(MainListItems)
