import React, {useState} from 'react';
import {
    Avatar,
    Box, Chip, Collapse, Grid,
    IconButton, Table, TableBody,
    TableCell, TableHead,
    TableRow
} from "@mui/material";
import {stringAvatar} from "../../functions";
import {useLocation} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {deleteGeoObject} from "../../redux/actions/geo/geoActions";
import {confirmDialog} from "../Popups/message/PopupDelete";
import TableTab from "../Contract/TableTab";
import {ContextTable} from "../../screens/Geo/Geo";

function TableRowCustomChild({
                            row,
                            childTable,
                            typeRow,
                            handleClick,
                            avatars,
                            actions,
                            setEdit,
                            rowsPage,
                            locale,
                            rowName
                        }) {
    const {pathname} = useLocation()
    const {t} = useTranslation();
    const {geoChildChild} = React.useContext(ContextTable);
    const [show, setShow] = useState(false)

    const handleIcons = async (e, id, rowId) => {
        if (e.target.childNodes.length) {
            let hasIconChild = false
            if (id === 'DeleteForeverIcon' && pathname === '/geo') {
                e.target.childNodes.forEach(el => {
                    if (el.getAttribute('data-testid') === id) {
                        hasIconChild = true
                    }
                })
                if (hasIconChild)
                    await deleteIconHandler(deleteGeoObject(rowId))
            }
            if (id === 'VisibilityIcon') {
                setShow(!show)
            }
            if (id === 'EditIcon') {
                setEdit(rowId, typeRow)
            }

        } else {
            if (e.target.getAttribute('data-testid') === id) {
                if (id === 'DeleteForeverIcon' && pathname === '/geo') {
                    await deleteIconHandler(deleteGeoObject(rowId))
                }
                if (id === 'VisibilityIcon') {
                    setShow(!show)
                }
                if (id === 'EditIcon') {
                    setEdit(rowId, typeRow)
                }
            }
        }
    }
    const dispatch = useDispatch()
    const deleteIconHandler = async (func) => {
        const res = await dispatch(func)
        res === 'Network Error' ? handleClick('error') :
            handleClick('success')
    }

    return (
                <TableBody>
                    <TableRow
                        key={row.id}
                        hover
                        sx={{'&:last-child td, &:last-child th': {border: 0}, cursor: 'pointer'}}
                    >
                        {Object.keys(row).map((rowChild, idx) => {
                            if (idx === 0) {
                                return <TableCell key={idx} component="th" scope="row">
                                    <Box>
                                        {avatars && <IconButton>
                                            <Avatar {...stringAvatar(row[rowChild].split(' ')[0] + ' ' + row[rowChild].split(' ')[1])} />
                                        </IconButton>}
                                        {row[rowChild]}
                                    </Box>
                                </TableCell>
                            }
                            return <TableCell key={idx} align="center">{rowChild === 'status' ? <Chip label={row[rowChild] ? row[rowChild] : 'Не задано'} color={row[rowChild] === 'На согласовании' ? "warning" : "default"} />
                                : row[rowChild] ? row[rowChild] : 'Не задано'}</TableCell>
                        })}
                        {actions && <TableCell align="center">
                            <Box>
                                {actions.map((el, idx) => (
                                    <IconButton data-testid={el.id} children={el.icon}
                                                onClick={(e) => {
                                                    if (el.id === 'DeleteForeverIcon') {
                                                        confirmDialog('Удаление элемента', 'Вы действительно хотите удалить этот элемент?', () => {
                                                            return handleIcons(e, el.id, row.id ? row.id : row.number)
                                                        })
                                                    } else
                                                        return handleIcons(e, el.id, row.id)
                                                }} key={idx}>
                                    </IconButton>
                                ))}
                            </Box>
                        </TableCell>}
                    </TableRow>
                    {(rowsPage || childTable) && <TableRow >
                        <TableCell style={{
                            paddingBottom: 0,
                            paddingTop: 0,
                            paddingLeft: childTable && 0,
                            paddingRight: childTable && 0
                        }} colSpan={`100%`}>
                            <Collapse in={show} timeout="auto" unmountOnExit>
                                {geoChildChild ?
                                    (
                                        <Table>
                                            <TableHead sx={{backgroundColor: '#E1DFDF'}}>
                                                <TableRow>
                                                    {rowName && rowName.map((el, idx) => {
                                                        return <TableCell key={idx}
                                                                          align={idx !== 0 ? 'center' : 'left'}>{el.text}</TableCell>
                                                    })}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {geoChildChild.filter(el => el.parentId === row.id).map(el => {
                                                    return {
                                                        id: el.id,
                                                        name: el.name
                                                    }
                                                }).map((row) => (
                                                            <TableRow
                                                                key={row.id}
                                                                sx={{
                                                                    '&:last-child td, &:last-child th': {border: 0},
                                                                    cursor: 'pointer'
                                                                }}>
                                                                {Object.keys(row).map((rowChild, idx) => {
                                                                    if (idx === 0) {
                                                                        return <TableCell key={idx} component="th"
                                                                                          scope="row">
                                                                            <Box>
                                                                                {row[rowChild]}
                                                                            </Box>
                                                                        </TableCell>
                                                                    }
                                                                    return <TableCell key={idx}
                                                                                      align="center">{row[rowChild] ? row[rowChild] : 'Значение не задано'}</TableCell>
                                                                })}
                                                                <TableCell align="center">
                                                                    <Box>
                                                                        {actions.slice(1).map((el, idx) => (
                                                                            <IconButton data-testid={el.id}
                                                                                        children={el.icon}
                                                                                        onClick={(e) => {
                                                                                            if (el.id === 'DeleteForeverIcon') {
                                                                                                confirmDialog('Удаление элемента','Вы действительно хотите удалить этот элемент?', () => {
                                                                                                    return handleIcons(e, el.id, row.id)
                                                                                                })
                                                                                            } else
                                                                                                return handleIcons(e, el.id, row.id)
                                                                                        }}
                                                                                        key={idx}>
                                                                            </IconButton>
                                                                        ))}
                                                                    </Box>
                                                                </TableCell>
                                                            </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>)
                                    : pathname === '/contract' ? <TableTab id={rowsPage?.number} rowsPage={rowsPage} locale={locale}/> : Object.keys(rowsPage).slice(0, 10).map((el, idx) => (
                                        <Grid container key={idx}>
                                            <Grid item xs={6}>
                                                <Box sx={{
                                                    padding: '5px 0',
                                                    borderTop: "1 px",
                                                    borderBottom: '1px'
                                                }}>{t(`${locale}.tableItem.${[el]}`)}</Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box sx={{
                                                    padding: '5px 0',
                                                    borderTop: "1 px",
                                                    borderBottom: '1px'
                                                }}>{rowsPage[el]}</Box>
                                            </Grid>
                                        </Grid>
                                    ))}
                            </Collapse>
                        </TableCell>
                    </TableRow>}
                </TableBody>
    );
}

export default TableRowCustomChild;