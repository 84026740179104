import React from 'react';
import {CircularProgress, TextField} from "@mui/material";
import {Autocomplete} from "@mui/lab";
import {useDispatch} from "react-redux";

function sleep(delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}

const AutoCompleteShared = ({func, label, values, setValues}) => {
    const [open, setOpen] = React.useState(false);
    const [data, setData] = React.useState()
    const [options, setOptions] = React.useState([]);
    const loading = open && options.length === 0;
    const dispatch = useDispatch()
    React.useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            if (active) {
                await sleep(1e3);
                const response = await dispatch(func(data, 'name'))
                if (!response.length) {
                    setOptions([])
                    active = false
                    setOpen(false)
                } else {
                    const mas = response.map(el => {
                        return {id: el.id, title: el.name}
                    })
                    setOptions(mas);
                }
            }

        })();

        return () => {
            active = false;
        };
    }, [data, dispatch, func, loading]);

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    return (
        <Autocomplete
            id="asynchronous-demo"
            open={open}
            onInput={(event) => {
                setData(event.target.value)
            }}
            onChange={(event, newValue) => {
                setValues(newValue)
            }}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            value={values}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.title || ""}
            options={options}
            loading={loading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    margin="normal"
                    fullWidth
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? (
                                    <CircularProgress color="inherit" size={20}/>
                                ) : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        )
                    }}
                />
            )}
        />
    );
}

export default AutoCompleteShared;