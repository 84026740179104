import {TOGGLE_LANG} from "../constants/langConstants";

const initialState = {
    langEditor: 'ru',
};

export const langReducer = (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_LANG:
            return {
                ...state,
                langEditor: action.payload
            };
        default:
            return state;
    }
}
