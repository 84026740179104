import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import {Grid, MenuItem} from "@mui/material";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getServiceKind, getUnitOfMeasure} from "../../../redux/actions/genericActions";
import {
    getService,
    getServicePrice
} from "../../../redux/actions/service/serviceActions";
import {addSpecification, editSpecification} from "../../../redux/actions/contract/specificationActions";
import {style} from "../popup";

export default function PopupContractSpecification({open, details, setOpen, title, edit, setEdit, id}) {
    const [qty, setQty] = useState(0)
    const [price, setPrice] = useState(0)
    const [unitOfMeasure, setUnitOfMeasure] = useState(1)
    const [service, setService] = useState(1)
    const [localServiceKind, setLocalServiceKind] = useState(1)
    const [servicePrice, setServicePrice] = useState(1)
    const dispatch = useDispatch()
    const {services, servicesPrice} = useSelector((state) => state.service);
    const {unityOfMeasure, serviceKind} = useSelector((state) => state.generic);
    const {specification} = useSelector((state) => state.specification);
    const emptyState = () => {
        setQty(0)
        setPrice(0)
        setUnitOfMeasure(1)
        setService(1)
        setLocalServiceKind(1)
        setServicePrice(1)
    }
    const handleForm = async (e) => {
        e.preventDefault()
        if (edit) {
            await dispatch(editSpecification(id, qty, price, unitOfMeasure, service, localServiceKind, servicePrice))
        } else
            await dispatch(addSpecification(qty, price, unitOfMeasure, service, localServiceKind, servicePrice))
        setOpen(false)
        if (!details) {
            setEdit(false)
            emptyState()
        }
    }
    const handleClose = async () => {
        if (edit && !details) {
            setEdit(false)
            emptyState()
        }
        setOpen(false)
    }
    useEffect(() => {
        if (edit) {
            const {quantity, serviceKindId, price, servicePriceListId, serviceId, unitId} = specification.find(el => el.id === id)
            setQty(quantity)
            setPrice(price)
            setLocalServiceKind(serviceKindId)
            setServicePrice(servicePriceListId)
            setService(serviceId)
            setUnitOfMeasure(unitId)
        }
    }, [details, edit, id, specification])

    useEffect(() => {
        let resUnit
        let resService
        let resServicesPrice
        let resServiceKind
        const getLocal = async () => {
            resUnit = await dispatch(getUnitOfMeasure())
            resServiceKind = await dispatch(getServiceKind())
            resService = await dispatch(getService())
            resServicesPrice = await dispatch(getServicePrice())
            if (resServicesPrice?.length && resService?.length && resUnit?.length && !edit) {
                setUnitOfMeasure(resUnit[0].id)
                setLocalServiceKind(resServiceKind[0].id)
                setService(resService[0].id)
                setServicePrice(resServicesPrice[0].id)
            }
        }
        getLocal()
    }, [dispatch, edit])

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {title} {edit && '(редактирование)'}
                        </Typography>
                        <Box component="form" onSubmit={handleForm} sx={{mt: 1, display: 'flex', flexWrap: 'wrap'}}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        margin="normal"
                                        required
                                        value={qty}
                                        onChange={(e) => setQty(e.target.value)}
                                        fullWidth
                                        id="qty"
                                        type="number"
                                        name="qty"
                                        label="Количество"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        margin="normal"
                                        required
                                        value={price}
                                        onChange={(e) => setPrice(e.target.value)}
                                        fullWidth
                                        id="price"
                                        type="number"
                                        name="price"
                                        label="Стоимость"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField required value={localServiceKind} onChange={(e) => setLocalServiceKind(e.target.value)}
                                               margin="normal" fullWidth id="select" label="Вид услуги" select>
                                        {serviceKind && serviceKind.map(el => (
                                            <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField required value={service} onChange={(e) => setService(e.target.value)}
                                               margin="normal" fullWidth id="select" label="Услуга" select>
                                        {services && services.map(el => (
                                            <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField required value={unitOfMeasure} onChange={(e) => setUnitOfMeasure(e.target.value)}
                                               margin="normal" fullWidth id="select" label="Ед. измерения" select>
                                        {unityOfMeasure && unityOfMeasure.map(el => (
                                            <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField required value={servicePrice} onChange={(e) => setServicePrice(e.target.value)}
                                               margin="normal" fullWidth id="select" label="Прейскурант (тариф)" select>
                                        {servicesPrice && servicesPrice.map(el => (
                                            <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{mt: 3, mb: 2}}
                                    >
                                        {edit ? 'Редактирование' : 'Добавить'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
            </Modal>
        </div>
    );
}
