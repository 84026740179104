import React, {useEffect, useState} from 'react';
import {
    Avatar,
    Box, CircularProgress, Collapse,
    IconButton, Table, TableBody,
    TableCell, TableContainer, TableHead, TablePagination,
    TableRow
} from "@mui/material";
import {stringAvatar} from "../../functions";
import {useLocation} from "react-router-dom";
import {useDispatch} from "react-redux";
import {deleteService, getServiceChild} from "../../redux/actions/service/serviceActions";
import {confirmDialog} from "../Popups/message/PopupDelete";
import {TableRowCustomServiceChild} from "../index";

function TableRowCustomService({
                                   value,
                                   row,
                                   childTable,
                                   typeRow,
                                   handleClick,
                                   avatars,
                                   actions,
                                   setEdit,
                                   rowName
                               }) {
    const {pathname} = useLocation()
    const [show, setShow] = useState(false)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(true)
    const [servicesChild, setServicesChild] = useState([])
    const [servicesChildCount, setServicesChildCount] = useState(null)
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleIcons = async (e, id, rowId) => {
        if (e.target.childNodes.length) {
            let hasIconChild = false
            if (id === 'DeleteForeverIcon' && pathname === '/service') {
                e.target.childNodes.forEach(el => {
                    if (el.getAttribute('data-testid') === id) {
                        hasIconChild = true
                    }
                })
                if (hasIconChild)
                    await deleteIconHandler(deleteService(rowId, value))
            }
            if (id === 'VisibilityIcon') {
                setShow(!show)
            }
            if (id === 'EditIcon') {
                // setEdit(rowId, typeRow)
            }

        } else {
            if (e.target.getAttribute('data-testid') === id) {
                if (id === 'DeleteForeverIcon' && pathname === '/service') {
                    await deleteIconHandler(deleteService(rowId, value))
                }
                if (id === 'VisibilityIcon') {
                    setShow(!show)
                }
                if (id === 'EditIcon') {
                    // setEdit(rowId, typeRow)
                }
            }
        }
    }

    const dispatch = useDispatch()
    const deleteIconHandler = async (func) => {
        const res = await dispatch(func)
        res === 'Network Error' ? handleClick('error') :
            handleClick('success')
    }

    useEffect(() => {
        (async function () {
            if (row.id && show) {
                setLoading(true)
                const res = await dispatch(getServiceChild(page + 1, row.id))
                if (res?.result?.length) {
                    setServicesChild(res.result)
                    setServicesChildCount(res.total)
                }
                setLoading(false)
            }
        }())
    }, [dispatch, page, row.id, show])

    return (
        <TableBody>
            <TableRow
                key={row.id}
                hover
                sx={{'&:last-child td, &:last-child th': {border: 0}, cursor: 'pointer'}}
            >
                {Object.keys(row).map((rowChild, idx) => {
                    if (idx === 0) {
                        return <TableCell key={idx} component="th" scope="row">
                            <Box>
                                {avatars && <IconButton>
                                    <Avatar {...stringAvatar(row[rowChild].split(' ')[0] + ' ' + row[rowChild].split(' ')[1])} />
                                </IconButton>}
                                {row[rowChild]}
                            </Box>
                        </TableCell>
                    }
                    return <TableCell key={idx} align="center">{row[rowChild]}</TableCell>
                })}
                {actions && <TableCell align="center">
                    <Box>
                        {actions.map((el, idx) => (
                            <IconButton data-testid={el.id} children={el.icon}
                                        onClick={(e) => {
                                            if (el.id === 'DeleteForeverIcon') {
                                                confirmDialog('Удаление элемента', 'Вы действительно хотите удалить этот элемент?', () => {
                                                    return handleIcons(e, el.id, row.id)
                                                })
                                            } else
                                                return handleIcons(e, el.id, row.id)
                                        }} key={idx}>
                            </IconButton>
                        ))}
                    </Box>
                </TableCell>}
            </TableRow>
            {childTable && <TableRow style={{backgroundColor: '#E5E4E2'}}>
                <TableCell style={{
                    paddingBottom: 0,
                    paddingTop: 0,
                    paddingLeft: childTable && 0,
                    paddingRight: childTable && 0
                }} colSpan={6}>
                    <Collapse in={show} timeout="auto" unmountOnExit>
                        {loading ? <Box sx={{
                            width: '100%',
                            height: '300px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}><CircularProgress/></Box> : servicesChild &&
                            (
                                <TableContainer>
                                    <Table>
                                        <TableHead sx={{backgroundColor: '#E1DFDF'}}>
                                            <TableRow>
                                                {rowName && rowName.map((el, idx) => {
                                                    return <TableCell key={idx}
                                                                      align={idx !== 0 ? 'center' : 'left'}>{el.text}</TableCell>
                                                })}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {servicesChild.map(el => {
                                                return {
                                                    id: el.id,
                                                    name: el.name,
                                                    price: el.price || 'Нет',
                                                    childsCount: el.childsCount
                                                }
                                            }).map((row, idx) => (
                                                <TableRowCustomServiceChild rowName={rowName} typeRow={typeRow} setEdit={setEdit} key={idx} row={row} actions={actions} handleClick={handleClick} handleIcons={handleIcons}/>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    {servicesChildCount && <TablePagination rowsPerPageOptions={[10, 25, 100]}
                                                                            component="div"
                                                                            labelRowsPerPage="Отображаемых элементов"
                                                                            count={servicesChildCount}
                                                                            page={page}
                                                                            onPageChange={handleChangePage}
                                                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                                                            rowsPerPage={rowsPerPage}/>}
                                </TableContainer>
                            )}
                    </Collapse>
                </TableCell>
            </TableRow>}
        </TableBody>
    );
}

export default TableRowCustomService;