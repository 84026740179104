import React, {useCallback} from 'react';
import CreateObjectForm from "../Forms/CreateObject";
import useDialogs from "../../Dialog/useDialogs";

export default function useCreateObjectForm() {
  const {DIALOG, openDialog, closeDialog} = useDialogs();

  const openCreateObjectForm = useCallback(() => {
    openDialog({
      content: <CreateObjectForm onClose={closeDialog} afterSubmit={null}/>,
      onClose: closeDialog,
    })
  }, [openDialog, closeDialog]);

  return {openCreateObjectForm, DIALOG}
}
