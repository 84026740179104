import { Dialog, DialogContent, Divider } from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useCallback, useMemo, useState } from 'react';

export default function useDialogs() {
  const [content, setContent] = useState(null);
  const [open, setOpen] = useState(false);



  const openDialog = useCallback(({content, onClose}) => {
    setOpen(true)
    setContent(content)
  }, []);

  const closeDialog = useCallback(() => {
    setOpen(false)
  }, []);

  const DIALOG = useCallback(() => {
    return open && <Dialog open={true} onClose={closeDialog}>{content}</Dialog>
  }, [open, content, closeDialog]);

  const renderUserText = (text) => {
    if(Array.isArray(text)) return <div style={{display: 'flex', flexDirection: "column"}}>{text.map(row => <span>{row}</span>)}</div>
    return text;
  };

  const alert = useCallback((title = "Уведомление", description = null) => {
    return (new Promise((resolve) => {
      openDialog({
        content: <>
          <DialogTitle>{renderUserText(title)}</DialogTitle>
          {description && <DialogContent dividers>{renderUserText(description)}</DialogContent>}
          <DialogActions>
            <Button variant={"contained"} onClick={() => {
              closeDialog();
              resolve();
            }}>OK</Button>
          </DialogActions>
        </>,
        onClose: resolve,
      })
    }));
  }, [openDialog, closeDialog]);

  const confirm = useCallback((title = "Уведомление", description = null) => {
    return new Promise((resolve, reject) => {
      openDialog({
        content: <>
          <DialogTitle>{renderUserText(title)}</DialogTitle>
          {description && <DialogContent dividers>{renderUserText(description)}</DialogContent>}
          <DialogActions>
            <Button variant={"text"} onClick={() => {
              closeDialog();
              reject();
            }}>Отмена</Button>
            <Button variant={"contained"} onClick={() => {
              closeDialog();
              resolve();
            }}>OK</Button>
          </DialogActions>
        </>,
        onClose: reject,
      })
    });
  }, [openDialog, closeDialog]);

  const deleteConfirm = useCallback((elementName) => {
    return confirm(`Вы действительно хотите удалить ${elementName} ?`, "Действие необратимо.")
  }, [confirm])

  return {alert, confirm, deleteConfirm, DIALOG, openDialog, closeDialog};
}
