import {
    SPECIFICATION_ADD_ERROR,
    SPECIFICATION_ADD_REQUEST, SPECIFICATION_ADD_SUCCESS,
    SPECIFICATION_GET_ERROR,
    SPECIFICATION_GET_REQUEST,
    SPECIFICATION_GET_SUCCESS,
} from "../../constants/contract/specifitaionConstants";
import {
    SPECIFICATION_DELETE_ERROR,
    SPECIFICATION_DELETE_REQUEST,
    SPECIFICATION_DELETE_SUCCESS
} from "../../constants/contract/specifitaionConstants";

const initialState = {
    specification: [],
    loading: false,
    success: null,
    error: null
};

export const specificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case SPECIFICATION_GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SPECIFICATION_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                specification: action.payload,
                success: true
            };
        case SPECIFICATION_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case SPECIFICATION_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SPECIFICATION_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SPECIFICATION_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case SPECIFICATION_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SPECIFICATION_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SPECIFICATION_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        default:
            return state;
    }
}