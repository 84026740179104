import React, {useEffect, useState} from 'react';
import {Box, IconButton, TableCell, TableRow} from "@mui/material";
import {SelectFieldModal, SelectShared} from "../index";
import {confirmDialog} from "../Popups/message/PopupDelete";
import {useTranslation} from "react-i18next";
import NumberShared from "../Shared/NumberShared";
import {getService} from "../../redux/actions/service/serviceActions";
import AnObjectField from "../Fields/AnObjectField";
import ServiceScheduleField from "../Fields/ServiceScheduleField";
import ServicePriceListField from "../Fields/ServicePriceListField";

const TableMinRow = ({mode, idx, edit, service, servicesCount, services, actions, handleIcons, row,  specification, setSpecification, unitOfMeasure,
                        count, unityOfMeasure}) => {
    const [serviceRow, setServiceRow] = useState(service)
    const [unitOfMeasureRow, setUnitOfMeasureRow] = useState(unitOfMeasure)
    const [qtyRow, setQtyRow] = useState(row?.count ? row?.count : row?.quantity)
    const [square, setSquare] = useState(row?.square)
    const [multiplicity, setMultiplicity] = useState(row?.multiplicity)
    const [priceRow, setPriceRow] = useState(row?.price)

    const [anObjectsLocal, setAnObjectsLocal] = useState('')
    const [servicesScheduleLocal, setServicesScheduleLocal] = useState('')

    useEffect(() => {
        setServiceRow(row?.serviceId)
        setUnitOfMeasureRow(row?.unitId)
        setQtyRow(row?.count ? row?.count : row?.quantity)
        setAnObjectsLocal(row?.objectId)
        setServicesScheduleLocal(row?.scheduleId)
    }, [edit, row])

    const handleRowObject = (record) => {
        const dataIdx = specification.findIndex(el => el.Id === row.Id)
        let data = [...specification]
        data[dataIdx] = {...data[dataIdx], service: {...record},
            serviceId: record.id, price: record?.price, unit: record?.unit, unitId: record?.unitId}
        setPriceRow(record?.price)
        setSpecification(data)
    }


    const handleServiceRow = (event) => {
        handleRowObject(event)
    }

    const handleUnitRow = (event) => {
        handleRow(event, unityOfMeasure, 'unit', 'unitId', setUnitOfMeasureRow)
    }

    const handleQtyRow = (event) => {
        handleRowVariant2(+event, count ? 'count' : 'quantity', setQtyRow)
    }

    const handleSquareRow = (event) => {
        handleRowVariant2(+event, 'square', setSquare)
    }
    const handleMultiplicityRow = (event) => {
        handleRowVariant2(+event, 'multiplicity', setMultiplicity)
    }


    const handlePriceRow = (event) => {
        handleRowVariant2(+event, 'price', setPriceRow)
    }

    const handleObjectRow = (event, object) => {
        handleRowVariant3(+event, object, 'object','objectId', setAnObjectsLocal)
    }


    const handleScheduleRow = (event, object) => {
        handleRowVariant3(event, object, 'schedule', 'scheduleId', setServicesScheduleLocal)
    }

    const handleRow = (event, array, valueName, valueId, setValue, price) => {
        const idx = array.findIndex(el => el.id === event)
        const dataIdx = specification.findIndex(el => el.Id === row.Id)
        let data = [...specification]
        data[dataIdx] = {...data[dataIdx], [valueName]: array[idx].name,
            [valueId]: event, ...(price && { price: array[idx].price})}
        price && setPriceRow(array[idx].price)
        setSpecification(data)
        setValue(event)
    }

    const handleRowVariant3 = (event, object, valueName, valueId, setValue) => {
        const dataIdx = specification.findIndex(el => el.Id === row.Id)
        let data = [...specification]
        data[dataIdx] = {...data[dataIdx], [valueName]: {...object},
            [valueId]: event}
        setSpecification(data)
        setValue(event)
    }

    const handleRowVariant2 = (event, valueName, setValue) => {
        const dataIdx = specification.findIndex(el => el.Id === row.Id)
        let data = [...specification]
        data[dataIdx] = {...data[dataIdx], [valueName]: event}
        setSpecification(data)
        setValue(event)
    }
    return (
        <TableRow key={idx}
                  hover
                  sx={{
                      '&:last-child td, &:last-child th': {border: 0},
                      cursor: 'pointer'

                  }}>
            {(mode === 'edit' || !mode) && <>
                <TableCell align="center">
                    <SelectFieldModal data={row.service} sx={{minWidth: '250px'}} label="Услуги" navigate={1} edit={edit} func={getService} nameFilter="name" slice={{first: 0, second: 3}}
                                      reduxCount={servicesCount} reduxValue={services} value={serviceRow} setValue={handleServiceRow}/>
                </TableCell>
                <TableCell align="center">
                    <SelectShared required sx={{maxWidth: '300px'}} array={unityOfMeasure} value={unitOfMeasureRow} setValue={handleUnitRow} label="Единица измерения"/>
                </TableCell>
                <TableCell align="center">
                    <NumberShared required value={qtyRow} setValue={handleQtyRow} label="Количество"/>
                </TableCell>
                {count && <TableCell align="center">
                    <NumberShared disabled value={row?.contractCount} label="Количество по договору"/>
                </TableCell>}
                <TableCell align="center">
                    <NumberShared required max={999999999} sx={{maxWidth: '160px'}} value={priceRow} setValue={handlePriceRow} label="Стоимость"/>
                </TableCell>
                <TableCell align="center">
                    <AnObjectField
                        fullWidth
                        object={row?.object}
                        value={anObjectsLocal}
                        onChange={(client) => {
                            handleObjectRow(client.id, client)
                        }}
                    />
                </TableCell>
                <TableCell align="center">
                    <ServiceScheduleField
                        fullWidth
                        object={row?.schedule}
                        value={anObjectsLocal}
                        onChange={(schedule) => {
                            handleScheduleRow(schedule.id, schedule)
                        }}
                    />
                </TableCell>
                <TableCell align="center">
                    <NumberShared required value={square} setValue={handleSquareRow} label="Площадь"/>
                </TableCell>
                <TableCell align="center">
                    <NumberShared required sx={{maxWidth: '160px'}} value={multiplicity} setValue={handleMultiplicityRow} label="Крастность"/>
                </TableCell>
                <TableCell align="center">
                    <Box>
                        {actions.map((el, idx) => (
                            <IconButton data-testid={el.id}
                                        children={el.icon}
                                        onClick={(e) => {
                                            if (el.id === 'DeleteForeverIcon') {
                                                confirmDialog('Удаление элемента', 'Вы действительно хотите удалить этот элемент?', () => {
                                                    return handleIcons(el.id, row.Id)
                                                })
                                            } else
                                                return handleIcons(el.id, row.Id)
                                        }}
                                        key={idx}>
                            </IconButton>
                        ))}
                    </Box>
                </TableCell>
            </>
            }
            {mode === 'see' && <>
                <TableCell align="center">
                    {row?.service?.name}
                </TableCell>
                <TableCell align="center">
                    {row?.unit?.name}
                </TableCell>
                <TableCell align="center">
                    {qtyRow}
                </TableCell>
                {count ? <TableCell align="center">
                    {row?.contractCount}
                </TableCell> : null}
                <TableCell align="center">
                    {priceRow}
                </TableCell>
                <TableCell align="center">
                    {row?.object?.name}
                </TableCell>
                <TableCell align="center">
                    {row?.schedule?.name}
                </TableCell>
            </>
            }
        </TableRow>
    );
}

export default React.memo(TableMinRow);
