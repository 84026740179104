import React from 'react';
import {TableCell, TableRow} from "@mui/material";

const TableMinRow = ({row}) => {
    return (
        <TableRow key={row.id}
                  hover
                  sx={{
                      '&:last-child td, &:last-child th': {border: 0},
                      cursor: 'pointer'
                  }}>
                <TableCell align="center">
                    {row?.service?.name}
                </TableCell>
                <TableCell align="center">
                    {row?.count}
                </TableCell>
                <TableCell align="center">
                    {row?.price}
                </TableCell>
                <TableCell align="center">
                    {row?.schedule?.name}
                </TableCell>
                <TableCell align="center">
                    {row?.summary}
                </TableCell>
        </TableRow>
    );
}

export default TableMinRow;