import {getData} from "../template/get";
import {$authHost} from "../../../http";
import {deleteData} from "../template/delete";
import {
    ADD_FILIAL_ERROR,
    ADD_FILIAL_REQUEST,
    ADD_FILIAL_SUCCESS,
    DELETE_FILIAL_ERROR,
    DELETE_FILIAL_REQUEST,
    DELETE_FILIAL_SUCCESS,
    GET_CLIENT_FILIAL_ERROR,
    GET_CLIENT_FILIAL_REQUEST,
    GET_CLIENT_FILIAL_SUCCESS,
    GET_FILIAL_DIVISION_ERROR,
    GET_FILIAL_DIVISION_REQUEST,
    GET_FILIAL_DIVISION_SUCCESS,
    GET_FILIAL_ERROR,
    GET_FILIAL_REQUEST,
    GET_FILIAL_SUCCESS
} from "../../constants/filial/filialConstants";

export const getFilial = (page, size, args, nonGlobal) => async (dispatch) => {
    return await dispatch(getData(GET_FILIAL_REQUEST, GET_FILIAL_SUCCESS, GET_FILIAL_ERROR, 'filial', true, page, null, null, false, 10000, args, nonGlobal))
};

export const getFilialClient = () => async (dispatch) => {
    try {
        dispatch({
            type: GET_CLIENT_FILIAL_REQUEST,
        });
        // const {data} = await $authHost.get(
        //     `api/v1/area/client/filial`,
        // );
        dispatch({
            type: GET_CLIENT_FILIAL_SUCCESS,
            payload: []
        });
        return []
    } catch (error) {
        dispatch({
            type: GET_CLIENT_FILIAL_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }

};

export const getFilialDivision = (page, size, args, nonGlobal) => async (dispatch) => {
    return await dispatch(getData(GET_FILIAL_DIVISION_REQUEST, GET_FILIAL_DIVISION_SUCCESS, GET_FILIAL_DIVISION_ERROR, 'filialDivision', true, page, null, null, false, 1000, args))
};

export const deleteFilial = (id) => async (dispatch) => {
    return await dispatch(deleteData(DELETE_FILIAL_REQUEST, DELETE_FILIAL_SUCCESS, DELETE_FILIAL_ERROR, 'filial', getFilial(), id))
};

export const addFilial = (name, description, bin, addressLine, surId, addressId) => async (dispatch) => {
    try {
        await dispatch({
            type: ADD_FILIAL_REQUEST,
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.post(
            "/api/v1/filial",
            {
                name,
                bin,
                description,
                addressLine,
                surId,
                addressId
            },
            config
        );
        await dispatch(getFilial())
        await dispatch({
            type: ADD_FILIAL_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: ADD_FILIAL_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};
