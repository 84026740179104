import React, {useEffect, useMemo, useState} from 'react';
import Dashboard from "../../layouts/Dashboard";
import BreadcrumbsShared from "../../components/Shared/BreadcrumbsShared";
import {FILIAL_PACKAGE_ADD_ROUTE, FILIAL_PACKAGE_ROUTE} from "../../routes/const";
import {
    Button,
    Grid,
    List,
    ListItem,
    Paper,
} from "@mui/material";
import Box from "@mui/material/Box";
import {LoadingButton} from "@mui/lab";
import {
    SelectShared
} from "../../components";
import {useDispatch, useSelector} from "react-redux";
import Typography from "@mui/material/Typography";
import ServiceCRUDTable from "../../components/Crud/Tables/ServiceCRUDTable";
import {getFilial, getFilialDivision} from "../../redux/actions/filial/filialActions";
import TextShared from "../../components/Shared/TextShared";
import {filialPackageCRUD} from "../../http/CRUD";
import {useHistory, useParams} from "react-router-dom";
import {showSuccessSnackbar} from "../../redux/actions/userActions";

function DealAdd() {
    const {id} = useParams();
    const isEditing = useMemo(() => id, [id]);
    const {push} = useHistory()

    const [loadingAdd, setLoadingAdd] = useState(false)
    const dispatch = useDispatch();
    const {filial, filialDivision} = useSelector((state) => state.filial);

    const [left, setLeft] = React.useState([0, 1, 2, 3]);
    const [right, setRight] = React.useState([4, 5, 6, 7]);

    useEffect(() => {
        (async function(){
            await dispatch(getFilial(1, 100))
            await dispatch(getFilialDivision(1, 10000))
        }())
    }, [dispatch])

    useEffect(() => {
        (async function(){
            if (isEditing) {
                const data = await filialPackageCRUD.get(id)
                setLocalFilial(data?.filialId)
                setLocalFilialDivision(data?.filialDivisionId)
                const services = data.services.map(el => {
                    return {...el.service}
                })
                setSelectedRows(services)
                setName(data?.name)
            }
        }())
    }, [dispatch, isEditing])



    const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
    const [selectedRowKeysRight, setSelectedRowKeysRight] = React.useState([]);
    const [selectedRowLeft, setSelectedRowLeft] = React.useState([]);
    const [selectedRowRight, setSelectedRowRight] = React.useState([]);
    const [selectedRow, setSelectedRows] = React.useState([]);

    const [localFilial, setLocalFilial] = useState(1)
    const [localFilialDivision, setLocalFilialDivision] = useState()
    const [name, setName] = useState('')



    const handleAllRight = () => {
        setSelectedRows((prev) => [...prev, ...selectedRowLeft])
        setSelectedRowKeys([])
        setSelectedRowLeft([])
    };

    const handleAllLeft = () => {
        setSelectedRows((prev) => [...prev.filter(el => !selectedRowKeysRight.includes(el.id))])
        setSelectedRowRight([])
        setSelectedRowKeysRight([])
    };

    const rowSelection = {
        onSelect: (record, push) => {
            if (push) {
                setSelectedRowKeys((prev) => [...prev, record.id])
                setSelectedRowLeft((prev) => [...prev, record])
            } else {
                setSelectedRowKeys((prev) => [...prev.filter(el => el !== record.id)])
                setSelectedRowLeft((prev) => [...prev.filter(el => el.id !== record.id)])
            }
        },
        selectedRowKeys,
        getCheckboxProps: (record) => ({
            disabled: selectedRow.find(el => el.id === record.id),
        }),
    };

    const rowSelectionRight = {
        onSelect: (record, push) => {
            if (push) {
                setSelectedRowKeysRight((prev) => [...prev, record.id])
                setSelectedRowRight((prev) => [...prev, record])
            } else {
                setSelectedRowKeysRight((prev) => [...prev.filter(el => el !== record.id)])
                setSelectedRowRight((prev) => [...prev.filter(el => el.id !== record.id)])
            }
        },
        selectedRowKeys: selectedRowKeysRight,
        getCheckboxProps: (record) => ({
            // disabled: record.name === 'Disabled User', // Column configuration not to be checked
            name: record.name,
        }),
    };



    const customList = (items, left = true) => (
        <Box sx={{ width: '100%', height: 700, overflow: 'auto', border: '1px solid rgba(224, 224, 224, 1)', padding: '0 10px' }}>
            <List dense component="div" role="list">
                <ListItem
                    role="listitem"
                    button
                >
                    <Typography sx={{marginTop: '5px'}} variant="h6"
                                component="h3">{left ? 'Все услуги' : 'Пакет'}</Typography>
                </ListItem>

                {left ?  <ServiceCRUDTable rowSelection={{
                    type: 'checkbox',
                    ...rowSelection,
                }}/> :  <ServiceCRUDTable searchFields={[]} search={{
                    result: selectedRow,
                    total: selectedRow.length
                }} rowSelection={{
                    type: 'checkbox',
                    ...rowSelectionRight,
                }}/>}
                <ListItem />
            </List>
        </Box>
    );


    const handleSubmit = async (event) => {
        event.preventDefault()
        setLoadingAdd(true)
        try {
            if (isEditing) {
                const data = {
                    id,
                    filialId: localFilial,
                    filialDivisionId: localFilialDivision,
                    name: name,
                    services: selectedRow.map(el => {
                        return {
                            serviceId: el.id
                        }
                    }),

                }
                await filialPackageCRUD.edit(data)
                dispatch(showSuccessSnackbar("Пакет обновлен!", "success"))
            } else  {
                const data = {
                    filialId: localFilial,
                    filialDivisionId: localFilialDivision,
                    name: name,
                    services: selectedRow.map(el => {
                        return {
                            serviceId: el.id
                        }
                    }),
                }
                await filialPackageCRUD.create(data)
                dispatch(showSuccessSnackbar("Пакет добавлен!", "success"))
            }
            push(FILIAL_PACKAGE_ROUTE)


        } finally {
            setLoadingAdd(false)
        }

    }


    return (
        <Dashboard>
            <BreadcrumbsShared breadcrumbs={[
                {text: 'Пакетные услуги (филиал)', link: FILIAL_PACKAGE_ROUTE},
                {text: isEditing ? 'Изменить пакет' : 'Добавить пакет', link: FILIAL_PACKAGE_ADD_ROUTE},
            ]}/>
            <Box component="form" onSubmit={handleSubmit}>
                <Box component={Paper} sx={{mt: 1, display: 'flex', padding: '0px 10px', flexWrap: 'wrap', marginBottom: '30px'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextShared value={name} setValue={setName} label="Название пакета"/>
                        </Grid>
                        <Grid item xs={6}>
                            <SelectShared value={localFilial} setValue={setLocalFilial} label="Филиал" array={filial}/>
                        </Grid>
                        <Grid item xs={6}>
                            <SelectShared disabled={!localFilial}  value={localFilialDivision} required={false} setValue={setLocalFilialDivision}
                                          label="Районное отделение" array={filialDivision && filialDivision.filter(el => el.filialId === localFilial)}/>
                        </Grid>
                    </Grid>
                </Box>


                <Box component={Paper} sx={{mt: 1, display: 'flex', flexWrap: 'wrap'}}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item sx={{flex: 1}}>{customList(left, true)}</Grid>
                        <Grid item>
                            <Grid container direction="column" alignItems="center">
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleAllRight}
                                    disabled={selectedRowKeys.length === 0}
                                    aria-label="move all right"
                                >
                                    &gt;
                                </Button>
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleAllLeft}
                                    disabled={selectedRowKeysRight.length === 0}
                                    aria-label="move all left"
                                >
                                    &lt;
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item sx={{flex: 1}}>{customList(right, false)}</Grid>
                    </Grid>
                </Box>
                <LoadingButton
                    loading={loadingAdd}
                    disabled={selectedRow.length === 0}
                    type="submit"
                    variant="contained"
                    sx={{mt: 3, mb: 2}}
                >
                    {isEditing ? 'Изменить пакет' : "Добавить пакет"}
                </LoadingButton>
            </Box>
        </Dashboard>
    );
}

export default DealAdd;
