import {
    GEO_OBJECT_GET_REQUEST,
    GEO_OBJECT_GET_SUCCESS,
    GEO_OBJECT_GET_ERROR,
    GEO_OBJECT_GET_BY_KEY_REQUEST,
    GEO_OBJECT_GET_BY_KEY_SUCCESS,
    GEO_OBJECT_GET_BY_KEY_ERROR,
    GEO_OBJECT_ADD_REQUEST,
    GEO_OBJECT_ADD_SUCCESS,
    GEO_OBJECT_ADD_ERROR,
    GEO_OBJECT_EDIT_REQUEST,
    GEO_OBJECT_EDIT_SUCCESS,
    GEO_OBJECT_EDIT_ERROR,
    GEO_OBJECT_DELETE_REQUEST,
    GEO_OBJECT_DELETE_SUCCESS,
    GEO_OBJECT_DELETE_ERROR,
    GEO_REGION_OBJECT_GET_REQUEST,
    GEO_REGION_OBJECT_GET_SUCCESS, GEO_REGION_OBJECT_GET_ERROR
} from "../constants/geoObjectConstants";

const initialState = {
    geoObjects: [],
    regionGeoObjects: [],
    geoObject: {},
    loading: false,
    success: null,
    error: null,
    geoObjectRequestedCount: 0,
    geoRegionObjectRequestedCount: 0,
};

export const getObjectReducer = (state = initialState, action) => {
    switch (action.type)     {
        case GEO_REGION_OBJECT_GET_REQUEST:
            return {
                ...state,
                geoRegionObjectRequestedCount: ++state.geoObjectRequestedCount,
                loading: true,
            };
        case GEO_REGION_OBJECT_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                regionGeoObjects: action.payload,
                success: true,
            };
        case GEO_REGION_OBJECT_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case GEO_OBJECT_GET_REQUEST:
            return {
                ...state,
                geoObjectRequestedCount: ++state.geoObjectRequestedCount,
                loading: true,
            };
        case GEO_OBJECT_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                geoObjects: action.payload,
                success: true,
            };
        case GEO_OBJECT_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case GEO_OBJECT_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GEO_OBJECT_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                geoObject: action.payload,
                success: true,
            };
        case GEO_OBJECT_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case GEO_OBJECT_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GEO_OBJECT_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case GEO_OBJECT_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case GEO_OBJECT_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GEO_OBJECT_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case GEO_OBJECT_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case GEO_OBJECT_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GEO_OBJECT_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case GEO_OBJECT_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
