import React, {useEffect, useState} from 'react';
import {Box, IconButton, TableCell, TableRow} from "@mui/material";
import {confirmDialog} from "../../../components/Popups/message/PopupDelete";
import {SelectShared} from "../../../components";
import {getEducationService} from "../../../redux/actions/educationService/educationServiceActions";
import {useDispatch} from "react-redux";

const EducationServicesTableContractRequestRow = ({
                                                      row,
                                                      actions,
                                                      handleIcons,
                                                      educationServices,
                                                      educationServiceKind,
                                                      mode,
                                                      edit,
                                                      localEducationServiceKind,
                                                      setEducationServices
                                                  }) => {
    const [localEducationServiceKindRow, setLocalEducationServiceKindRow] = useState(localEducationServiceKind)
    const dispatch = useDispatch()
    const [localEducationServiceRow, setLocalEducationServiceRow] = useState(1)
    const [educationService, setEducationService] = useState([])

    const handleRow = (event, array, valueName, valueId, setValue) => {
        const idx = array.findIndex(el => el.id === event)
        const dataIdx = educationServices.findIndex(el => el.Id === row.Id)
        let data = [...educationServices]
        data[dataIdx] = {
            ...data[dataIdx], [valueName]: array[idx].name,
            [valueId]: event
        }
        setEducationServices(data)
        setValue(event)
    }

    const handleServiceKindRow = (event) => {
        handleRow(event, educationServiceKind, 'kind', 'kindId', setLocalEducationServiceKindRow)
    }

    const handleServiceRow = (event) => {
        handleRow(event, educationService, 'service', 'serviceId', setLocalEducationServiceRow)
    }

    useEffect(() => {
        (async function () {
            if (localEducationServiceKindRow) {
                const {result} = await dispatch(getEducationService(1, 10, {
                    filter: {
                        kindId: {
                            operand1: localEducationServiceKindRow,
                            operator: "equals"
                        }
                    }
                }))
                setEducationService(result)
            }
        }())
    }, [dispatch, localEducationServiceKindRow])

    useEffect(() => {
        setLocalEducationServiceRow(row?.serviceId)
        setLocalEducationServiceKindRow(row?.kindId)
    }, [edit, row])

    return (
        <TableRow>
            {
                (mode === 'edit' || !mode) &&
                <>
                    <TableCell align="center">
                        <SelectShared sx={{maxWidth: '200px'}} array={educationServiceKind}
                                      value={localEducationServiceKindRow}
                                      setValue={handleServiceKindRow} label="Вид обучения"/>
                    </TableCell>
                    <TableCell align="center">
                        <SelectShared disabled={!localEducationServiceKindRow} sx={{maxWidth: '600px'}}
                                      array={educationService} value={localEducationServiceRow}
                                      setValue={handleServiceRow} label="Вид обучения"/>
                    </TableCell>
                    <TableCell align="center">
                        <Box>
                            {actions.map((el, idx) => (
                                <IconButton data-testid={el.id}
                                            children={el.icon}
                                            onClick={(e) => {
                                                if (el.id === 'DeleteForeverIcon') {
                                                    confirmDialog('Удаление элемента', 'Вы действительно хотите удалить этот элемент?', () => {
                                                        return handleIcons(el.id, row.Id)
                                                    })
                                                } else
                                                    return handleIcons(el.id, row.Id)
                                            }}
                                            key={idx}>
                                </IconButton>
                            ))}
                        </Box>
                    </TableCell>
                </>
            }
            {
                mode === 'see' && <>
                    <TableCell align="center">
                        {educationServiceKind.find(el => el.id === localEducationServiceKindRow)?.name}
                    </TableCell>
                    <TableCell align="center">
                        {educationService.find(el => el.id === localEducationServiceRow)?.name}
                    </TableCell>
                </>
            }

        </TableRow>
    );
}

export default React.memo(EducationServicesTableContractRequestRow);