export const GET_REQUEST_STATE_REQUEST = 'GET_REQUEST_STATE_REQUEST'
export const GET_REQUEST_STATE_SUCCESS = 'GET_REQUEST_STATE_SUCCESS'
export const GET_REQUEST_STATE_ERROR = 'GET_REQUEST_STATE_ERROR'

export const GET_REQUEST_REQUEST = 'GET_REQUEST_REQUEST'
export const GET_REQUEST_SUCCESS = 'GET_REQUEST_SUCCESS'
export const GET_REQUEST_ERROR = 'GET_REQUEST_ERROR'

export const ADD_REQUEST_REQUEST = 'ADD_REQUEST_REQUEST'
export const ADD_REQUEST_SUCCESS = 'ADD_REQUEST_SUCCESS'
export const ADD_REQUEST_ERROR = 'ADD_REQUEST_ERROR'

export const GET_REQUEST_FILTER_REQUEST = 'GET_REQUEST_FILTER_REQUEST'
export const GET_REQUEST_FILTER_SUCCESS = 'GET_REQUEST_FILTER_SUCCESS'
export const GET_REQUEST_FILTER_ERROR = 'GET_REQUEST_FILTER_ERROR'

export const REQUEST_SECTION_GET_REQUEST = 'REQUEST_SECTION_GET_REQUEST'
export const REQUEST_SECTION_GET_SUCCESS = 'REQUEST_SECTION_GET_SUCCESS'
export const REQUEST_SECTION_GET_ERROR = 'REQUEST_SECTION_GET_ERROR'