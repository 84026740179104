export const TRANSLATIONS_RU = {
    request: {
        tableItem: {
            id: "№",
            contractId: "Номер договора",
            performer: "Исполнитель",
            filial: "Филиал",
            number: "Номер",
            createdAt: "Дата создания",
            createdBy: "Автор",
            status: "Статус",
            state: "Статус",
            name: "Наименование",
            department: "Лаборатория",
            responsiblePerformer: "Лаборант",
            reponsiblePerformer: "Лаборант",
            division: "Отдел лаборатории",
            specialist: "Специалист",
            departmentHead: "Зав. лабораторией",
            responsible: "Зав. лабораторией",
            departureDate: "Дата прибытия",
            dateOfDeparture: "Дата выезда",
            actions: "Действия",
            contractRequest: "Сводная заявка",
            contractRequestId: "Сводная заявка",
        },
    },
    dashboard: {
        listItem: {
            applications: "Заявки",
            legalClient: "Клиенты (юр. лицо)",
            client: "Клиенты (физ. лицо)",
            typeClient: "Типы клиентов",
            filial: "Филиалы",
            bills: "Счета",
            deal: "Сделки",
            test: "Тестирование",
            filialPackage: "Пакетные услуги (филиал)",
            social: "Каналы связи",
            general: "Главная",
            socialWhatsapp: "Whatsapp",
            contractAdditional: "Доп. соглашения",
            service: 'Услуги',
            mail: 'Почта',
            invoice: 'Счета на оплату',
            payment: 'Платежи',
            sms: 'Смс',
            report: 'Отчеты',
            contractSpecification: 'Специфика',
            contract: 'Договоры',
            geo: 'Геолокация',
            servicePriceList: 'Прейскурант',
            clients: 'Клиенты (все)',
            typeService: 'Тип услуги',
            bookkeeping: 'Бухгалтерия',
            completionAct: 'Акт выполненных работ',
            reports: "Отчёты",
            contractRequest: "Сводные заявки",
            ohvat: "Охват рынка",
            obrash: "Отчет по раз. обр.",
            eff: "Эффект отделений",
            status: "Статус договора",
            date: "Данные по услугам",
            exit: "Выход",
        },
        allClient: 'Клиенты',
        service: 'Услуги',
    },
    client: {
        tableItem: {
            id: "Номер",
            firstName: "Имя",
            lastName: "Фамилия",
            secondName: "Отчество",
            fullName: "ФИО",
            email: "Email",
            addressLine: "Адрес",
            identityNumber: 'ИНН',
            phoneNumber: 'Телефон',
            gender: 'Пол',
            birthDay: "Дата рождения",
            actions: "Действия"
        },
        searchLabel: 'Найти клиентов...',
        buttonText: 'Добавить клиента'
    },
    completionAct: {
        tableItem: {
            id: "№",
            startDate: "Дата составления",
            name: "Договор",
            bookkeeping: "Бухгалтерия",
            status: "Статус",
            view: "Просмотр",
            actions: "Действия"
        },
        searchLabel: 'Найти клиентов...',
        buttonText: 'Добавить акт'
    },
    payment: {
        tableItem: {
            id: "№",
            startDate: "Дата составления",
            name: "Договор",
            bookkeeping: "Бухгалтерия",
            status: "Статус",
            actions: "Действия"
        },
        searchLabel: 'Найти клиентов...',
        buttonText: 'Добавить платежку'
    },
    invoice: {
        tableItem: {
            id: "№",
            startDate: "Дата составления",
            name: "Договор",
            bookkeeping: "Бухгалтерия",
            status: "Статус",
            actions: "Действия"
        },
        searchLabel: 'Найти клиентов...',
        buttonText: 'Оформить счет на оплату'
    },
    filial: {
        tableItem: {
            id: "Номер",
            name: "Название",
            description: "Описание",
            bin: "БИН",
            addressLine: "Адрес",
            surId: "Сур",
            addressId: "Объект",
            actions: "Действия"
        },
        searchLabel: 'Найти клиентов...',
        buttonText: 'Добавить клиента'
    },
    servicePrice: {
        tableItem: {
            id: "Номер",
            name: "Название",
            service: "Услуга",
            unitOfMeasure: "Единица измерения",
            currency: "Валюта",
            price: "Стоимость",
            actions: "Действия"
        },
        searchLabel: 'Найти цену...',
        buttonText: 'Добавить прейскурант'
    },
    generalTable: {
        tableItem: {
            id: "№",
            name: "Наименование услуги",
            price: "Цена",
            qty: "Количество",
            schedule: "График",
            summary: "Сумма",
            actions: "Действия"
        },
        searchLabel: 'Найти цену...',
        buttonText: 'Добавить прейскурант'
    },
    clients: {
        tableItem: {
            id: "Номер",
            firstName: "Имя",
            lastName: "Фамилия",
            secondName: "Отчество",
            fullName: "ФИО",
            email: "Email",
            addressLine: "Адрес",
            identityNumber: 'ИНН',
            phoneNumber: 'Телефон',
            gender: 'Пол',
            birthDay: "Дата рождения",
            actions: "Действия",
            name: "Название",
            bin: "БИН",
            cityLine: 'Город',
            bankAccountNumber: 'Банковский номер',
            supervisorName: 'Руководитель',
            regionLine: "Район",
            typeId: "Тип клиента",
        },
        searchLabel: 'Найти клиентов...'
    },
    typeClient: {
        tableItem: {
            name: "Название",
            code: "Код",
            description: "Описание",
            actions: "Действия"
        },
        searchLabel: 'Найти тип клиента...',
        buttonText: 'Добавить тип клиента'
    },
    employee: {
        tableItem: {
            id: 'Номер',
            fullName: "Название",
            employee: "Согласующий",
            employeeAgree: "Согласовал ли",
            comment: "Комментарий",
        },
    },
    geo: {
        tableItem: {
            id: "Номер",
            name: "Название",
            actions: "Действия"
        },
        buttonText: 'Добавить гео объект'
    },
    service: {
        tableItem: {
            id: "Номер",
            name: "Название",
            price: "Цена",
            type: "Тип",
            unit: "Единица измерения",
            actions: "Действия"
        },
        buttonText: 'Добавить услугу'
    },
    specification: {
        tableItem: {
            id: "Номер",
            serviceType: "Вид услуги",
            service: "Услуга",
            unitOfMeasure: "Единица измерения",
            servicePriceList: "Прейскурант",
            qty: "Количество",
            price: "Стоимость",
            anObject: "Объект",
            servicesSchedule: "График исследований",
            summary: "Суммарно",
            actions: "Действия"
        },
        searchLabel: 'Найти тип клиента...',
        buttonText: 'Добавить специфику'
    },
    servicesSchedule: {
        tableItem: {
            id: "Номер",
            name: "Название"
        }
    },
    anObjects: {
        tableItem: {
            id: "Номер",
            name: "Название",
            addressLine: "Адрес",
            bin: "Бин",
        }
    },
    contract: {
        tableItem: {
            name: "Название",
            number: "№",
            nubmerVal: "№",
            registeredAt: "Дата регистрации",
            actionAt: "Дата действия",
            typeContract: "Тип договора",
            payState: 'Статус оплаты',
            date: "Дата окончания договора",
            status: "Статус",
            filial: "Филиал",
            city: "Город",
            summary: "Сумма договора",
            file: "Файл",
            type: "Тип",
            typeObj: "Тип объекта",
            client: "Клиент",
            actions: "Действия"
        },
        tableItemSchedule: {
            targetMonth: 'Месяц',
            targetYear: 'Год',
        },
        searchLabel: 'Найти договор...',
        buttonText: 'Добавить договор'
    },
    contractRequest: {
        tableItem: {
            id: '№',
            name: "Название",
            number: "Номер",
            status: "Статус",
            filial: "Филиал",
            act: "Акт",
            contractActionAt: "Дата договора",
            contractCreatedAt: "Дата создания",
            actions: "Действия"
        },
        tableItemEducationServices: {
            kind: 'Вид обучения',
            place: 'Лаборатория/Наименование/Форма обучения/График/Формат и место обучения\n'
        },
        tableItemRequestObject: {
            name: 'Наименование объекта',
            district: 'Область',
            city: 'Город',
            locality: 'Населенный пункт',
            address: 'Адрес',
        },
        searchLabel: 'Найти сводную заявку...',
        buttonText: 'Добавить сводную заявку'
    },
    contractAdditional: {
        tableItem: {
            id: '№',
            name: "Название",
            number: "Номер",
            status: "Статус",
            filial: "Филиал",
            contractActionAt: "Дата договора",
            contractCreatedAt: "Дата создания",
            actions: "Действия"
        },
        tableItemEducationServices: {
            kind: 'Вид обучения',
            place: 'Лаборатория/Наименование/Форма обучения/График/Формат и место обучения\n'
        },
        tableItemRequestObject: {
            name: 'Наименование объекта',
            district: 'Область',
            city: 'Город',
            locality: 'Населенный пункт',
            address: 'Адрес',
        },
        searchLabel: 'Найти сводную заявку...',
        buttonText: 'Добавить доп. соглашение'
    },
    typeService: {
        tableItem: {
            id: "Номер",
            name: "Название",
            code: "Код",
            description: "Описание",
            actions: "Действия"
        },
        buttonText: 'Добавить тип услуги'
    },
    legalClient: {
        tableItem: {
            id: "Номер",
            name: "Название",
            bin: "БИН",
            cityLine: 'Город',
            addressLine: 'Адрес',
            email: 'Email',
            bankAccountNumber: 'Банковский номер',
            supervisorName: 'Руководитель',
            regionLine: "Район",
            phoneNumber: "Телефон",
            typeId: "Тип клиента",
            actions: "Действия"
        },
        searchLabel: 'Найти клиента (юр. лицо)...',
        buttonText: 'Добавить клиента (юр. лицо)'
    },
    main: {
        tabItem: {
            all: "Все",
            made: "Сделанные",
            cancel: "Отменённые",
        },
        tableItem: {
            id: "№",
            number: "Номер",
            filial: "Филиал",
            client: "Клиент",
            firstName: "Имя",
            bankName: 'Банк',
            bankBik: 'Бик',
            lastName: "Фамилия",
            secondName: "Отчество",
            fullName: "ФИО",
            email: "Email",
            addressLine: "Адрес",
            identityNumber: 'ИНН',
            phoneNumber: 'Телефон',
            gender: 'Пол',
            birthDay: "Дата рождения",
            name: "Название",
            bin: "БИН",
            actionAt: "Дата создания",
            cityLine: 'Город',
            summary: 'Сумма',
            bankAccountNumber: 'Банковский номер',
            supervisorName: 'Руководитель',
            regionLine: "Район",
            typeId: "Тип клиента",
            actions: "Действия"
        },
        buttonText: 'Добавить заявку'
    },
    deal: {
        tableItem: {
            id: "№",
            name: "Название",
            contact: "Контакт",
            budget: "Бюджет",
            contract: "Договор",
            stage: "Стадия",
            actions: "Действия"
        },
        buttonText: 'Создать сделку'
    },
    message: {
        delete: {
            success: 'Элемент успешно удалён',
            error: 'Не удалось удалить элемент'
        }
    }
}