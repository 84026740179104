import {useDispatch, useSelector} from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import {clearSnackbar} from "../../redux/actions/userActions";
import {Alert} from "@mui/lab";

export default function SuccessSnackbar() {
    const dispatch = useDispatch();

    const {successSnackbarMessage, severitySnackbarMessage, successSnackbarOpen} = useSelector(
        state => state.user
    );

    function handleClose() {
        dispatch(clearSnackbar());
    }

    return (
        <Snackbar
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
            }}
            open={successSnackbarOpen}
            autoHideDuration={1000}
        >
            <Alert onClose={handleClose} variant="filled" severity={severitySnackbarMessage} sx={{ width: '100%' }}>
                {successSnackbarMessage}
            </Alert>
        </Snackbar>
    );
}
