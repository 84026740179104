export const GET_GEO_OBJECT_REQUEST = 'GET_GEO_OBJECT_REQUEST'
export const GET_GEO_OBJECT_SUCCESS = 'GET_GEO_OBJECT_SUCCESS'
export const GET_GEO_OBJECT_ERROR = 'GET_GEO_OBJECT_ERROR'

export const GET_GEO_CHILD_OBJECT_REQUEST = 'GET_GEO_CHILD_OBJECT_REQUEST'
export const GET_GEO_CHILD_OBJECT_SUCCESS = 'GET_GEO_CHILD_OBJECT_SUCCESS'
export const GET_GEO_CHILD_OBJECT_ERROR = 'GET_GEO_CHILD_OBJECT_ERROR'

export const ADD_GEO_OBJECT_REQUEST = 'ADD_GEO_OBJECT_REQUEST'
export const ADD_GEO_OBJECT_SUCCESS = 'ADD_GEO_OBJECT_SUCCESS'
export const ADD_GEO_OBJECT_ERROR = 'ADD_GEO_OBJECT_ERROR'

export const DELETE_GEO_OBJECT_REQUEST = 'DELETE_GEO_OBJECT_REQUEST'
export const DELETE_GEO_OBJECT_SUCCESS = 'DELETE_GEO_OBJECT_SUCCESS'
export const DELETE_GEO_OBJECT_ERROR = 'DELETE_GEO_OBJECT_ERROR'

export const EDIT_GEO_OBJECT_REQUEST = 'EDIT_GEO_OBJECT_REQUEST'
export const EDIT_GEO_OBJECT_SUCCESS = 'EDIT_GEO_OBJECT_SUCCESS'
export const EDIT_GEO_OBJECT_ERROR = 'EDIT_GEO_OBJECT_ERROR'

export const GET_GEO_OBJECT_TYPE_REQUEST = 'GET_GEO_OBJECT_TYPE_REQUEST'
export const GET_GEO_OBJECT_TYPE_SUCCESS = 'GET_GEO_OBJECT_TYPE_SUCCESS'
export const GET_GEO_OBJECT_TYPE_ERROR = 'GET_GEO_OBJECT_TYPE_ERROR'

export const GET_GEO_OBJECT_FILTER_REQUEST = 'GET_GEO_OBJECT_FILTER_REQUEST'
export const GET_GEO_OBJECT_FILTER_SUCCESS = 'GET_GEO_OBJECT_FILTER_SUCCESS'
export const GET_GEO_OBJECT_FILTER_ERROR = 'GET_GEO_OBJECT_FILTER_ERROR'

export const GET_GEO_FILTER_TABLE = 'GET_REQUEST_FILTER_TABLE'
