import {BrowserRouter as Router} from "react-router-dom";
import React, {useEffect, useState} from 'react'
import AppRouter from "./components/AppRouter/AppRouter";
import "./lang/i18n";
import 'antd/dist/antd.css'
import {useDispatch, useSelector} from "react-redux";
import {check} from "./redux/actions/userActions";
import {CircularProgress} from "@mui/material";
import {Loader} from "./components";
import {pdfjs} from 'react-pdf';
import SuccessSnackbar from "./components/Shared/SuccessSnackbar";
import {getProfile} from "./redux/actions/profile/profileActions";
import {getFilial, getFilialClient} from "./redux/actions/filial/filialActions";
import {SET_FILIAL} from "./redux/constants/userConstants";
import './App.scss';
import './functions/timezone'
import {$authHost} from "./http";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const App = () => {
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch()
    const {loader} = useSelector((state) => state.generic);
    const user = useSelector((state) => state.user);
    const {isClient} = user
    useEffect(async () => {
        try {
            let isClient = await dispatch(check());
            const profile = await dispatch(getProfile())
            if (isClient === false) {
                const address = profile?.type?.id === 1 ? profile?.client?.person?.addressId : profile?.client?.legalPerson?.addressId
                const filial = await dispatch(getFilial(1, 100, {
                        filter: {
                            addressId: {
                                operand1: address,
                                operator: "equals"
                            },
                        }
                    }, true
                ))
                dispatch({type: SET_FILIAL, payload: filial})
            } else {
                const filial = await dispatch(getFilialClient(1, 100))
                const address = profile?.type?.id === 1 ? profile?.client?.person?.addressId : profile?.client?.legalPerson?.addressId
                const filialMy = filial?.filter(el => el.addressId === address)
                dispatch({type: SET_FILIAL, payload: filialMy})
            }
        } finally {
            setLoading(false);
        }
    }, [dispatch, isClient]);
    if (loading) {
        return (
            <div style={{height: "100vh", display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <CircularProgress/>
            </div>
        );
    }

    return (
        <Router>
            <div className="wrapper">
                <div className="content">
                    <AppRouter/>
                    <SuccessSnackbar/>
                    {loader && <Loader/>}
                </div>
            </div>
        </Router>
    );
}

export default App;
