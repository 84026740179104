import {
    SERVICE_GET_ERROR,
    SERVICE_GET_SUCCESS,
    SERVICE_GET_REQUEST,
    SERVICE_ADD_REQUEST,
    SERVICE_ADD_SUCCESS,
    SERVICE_ADD_ERROR,
    SERVICE_DELETE_REQUEST,
    SERVICE_DELETE_SUCCESS,
    SERVICE_DELETE_ERROR,
    SERVICE_EDIT_REQUEST,
    SERVICE_EDIT_SUCCESS,
    SERVICE_EDIT_ERROR,
    SERVICE_ADD_PRICE_REQUEST,
    SERVICE_ADD_PRICE_SUCCESS,
    SERVICE_ADD_PRICE_ERROR,
    SERVICE_GET_PRICE_REQUEST,
    SERVICE_GET_PRICE_SUCCESS,
    SERVICE_GET_PRICE_ERROR,
    SERVICE_EDIT_PRICE_REQUEST,
    SERVICE_EDIT_PRICE_SUCCESS,
    SERVICE_EDIT_PRICE_ERROR,
    SERVICE_DELETE_PRICE_REQUEST,
    SERVICE_DELETE_PRICE_SUCCESS,
    SERVICE_DELETE_PRICE_ERROR,
    SERVICE_TYPE_GET_REQUEST,
    SERVICE_TYPE_GET_SUCCESS,
    SERVICE_TYPE_GET_ERROR,
    SERVICE_GROUP_GET_REQUEST,
    SERVICE_GROUP_GET_SUCCESS,
    SERVICE_GROUP_GET_ERROR,
    SERVICE_SUB_GROUP_GET_REQUEST,
    SERVICE_SUB_GROUP_GET_SUCCESS,
    SERVICE_SUB_GROUP_GET_ERROR,
    SERVICE_GET_CHILD_REQUEST,
    SERVICE_GET_CHILD_SUCCESS,
    SERVICE_GET_CHILD_ERROR,
    SERVICE_SCHEDULE_GET_REQUEST,
    SERVICE_SCHEDULE_GET_SUCCESS,
    SERVICE_SCHEDULE_GET_ERROR,
    SERVICE_PACKAGE_GET_REQUEST, SERVICE_PACKAGE_GET_SUCCESS, SERVICE_PACKAGE_GET_ERROR
} from "../../constants/service/serviceConstants";

const initialState = {
    services: [],
    servicesPackage: [],
    servicesCount: null,
    servicesChild: [],
    servicesChildCount: null,
    servicesPrice: [],
    servicesSchedule: [],
    servicesScheduleCount: null,
    servicesPriceCount: null,
    serviceType: [],
    serviceGroup: [],
    serviceSubGroup: [],
    loading: false,
    loadingChild: false,
    success: null,
    error: null
};

export const serviceReducer = (state = initialState, action) => {
    switch (action.type) {
        case SERVICE_GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                services: action.payload.result,
                servicesCount: action.payload.pageCount
            };
        case SERVICE_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case SERVICE_PACKAGE_GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_PACKAGE_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                servicesPackage: action.payload.result,
            };
        case SERVICE_PACKAGE_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case SERVICE_SCHEDULE_GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_SCHEDULE_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                servicesSchedule: action.payload.result,
                servicesScheduleCount: action.payload.pageCount
            };
        case SERVICE_SCHEDULE_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case SERVICE_GET_CHILD_REQUEST:
            return {
                ...state,
                loadingChild: true,
            };
        case SERVICE_GET_CHILD_SUCCESS:
            return {
                ...state,
                loadingChild: false,
                success: true,
                servicesChild: action.payload.result,
                servicesChildCount: action.payload.total
            };
        case SERVICE_GET_CHILD_ERROR:
            return {
                ...state,
                loadingChild: false,
                success: false,
                error: action.payload
            };
        case SERVICE_TYPE_GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_TYPE_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                serviceType: action.payload
            };
        case SERVICE_TYPE_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case SERVICE_GROUP_GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_GROUP_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                serviceGroup: action.payload
            };
        case SERVICE_GROUP_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case SERVICE_SUB_GROUP_GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_SUB_GROUP_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                serviceSubGroup: action.payload
            };
        case SERVICE_SUB_GROUP_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case SERVICE_ADD_PRICE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_ADD_PRICE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SERVICE_ADD_PRICE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case SERVICE_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SERVICE_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case SERVICE_GET_PRICE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_GET_PRICE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                servicesPrice: action.payload.result,
                servicesPriceCount: action.payload.total
            };
        case SERVICE_GET_PRICE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case SERVICE_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SERVICE_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case SERVICE_EDIT_PRICE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_EDIT_PRICE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SERVICE_EDIT_PRICE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case SERVICE_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SERVICE_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case SERVICE_DELETE_PRICE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_DELETE_PRICE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SERVICE_DELETE_PRICE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        default:
            return state;
    }
}