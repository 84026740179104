import {
    ADD_FILIAL_ERROR,
    ADD_FILIAL_REQUEST,
    ADD_FILIAL_SUCCESS,
    DELETE_FILIAL_REQUEST,
    DELETE_FILIAL_SUCCESS,
    DELETE_FILIAL_ERROR,
    GET_FILIAL_ERROR,
    GET_FILIAL_SUCCESS,
    GET_FILIAL_REQUEST,
    GET_FILIAL_DIVISION_REQUEST,
    GET_FILIAL_DIVISION_SUCCESS, GET_FILIAL_DIVISION_ERROR
} from "../../constants/filial/filialConstants";

const initialState = {
    filial: [],
    filialDivision: [],
    loading: false,
    success: null,
    error: null
};

export const filialReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_FILIAL_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_FILIAL_SUCCESS:
            return {
                ...state,
                loading: false,
                filial: action.payload,
                success: true
            };
        case GET_FILIAL_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case GET_FILIAL_DIVISION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_FILIAL_DIVISION_SUCCESS:
            return {
                ...state,
                loading: false,
                filialDivision: action.payload,
                success: true
            };
        case GET_FILIAL_DIVISION_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case DELETE_FILIAL_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DELETE_FILIAL_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true
            };
        case DELETE_FILIAL_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case ADD_FILIAL_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ADD_FILIAL_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true
            };
        case ADD_FILIAL_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        default:
            return state;
    }
}