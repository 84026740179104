import React from "react";

import { getAnObjectSections } from "../../redux/actions/anObjectSectionActions";
import { getAnObjectSubSections } from "../../redux/actions/anObjectSubSectionActions";
import DomainSelect from "./DomainSelect";
import {getDealStates} from "../../redux/actions/dealStateActions";
import {getGeoObjects, getRegionGeoObjects} from "../../redux/actions/geoObjectActions";
import {getFilials} from "../../redux/actions/filialActions";
import {getFilialDivisions} from "../../redux/actions/filialDivisionActions";

export const ObjectSectionSelect = ({...props}) => <DomainSelect
  label={"Раздел объекта"}
  reducerKey={"anObjectSection"}
  reducerArrayKey={"anObjectSections"}
  reducerAction={getAnObjectSections}
  {...props}
/>

export const ObjectSubSectionSelect = ({...props}) => <DomainSelect
  label={"Подраздел объекта"}
  reducerKey={"anObjectSubSection"}
  reducerArrayKey={"anObjectSubSections"}
  reducerAction={getAnObjectSubSections}
  {...props}
/>

export const GeoObjectSelect = ({...props}) => <DomainSelect
    label={"Города"}
    reducerKey={"geoObject"}
    filter={{ typeId: {
            operand1: 3,
            operator: "equals"
        },}}
    reducerArrayKey={"geoObjects"}
    reducerAction={getGeoObjects}
    {...props}
/>

export const RegionSelect = ({...props}) => <DomainSelect
    label={"Города"}
    reducerKey={"geoObject"}
    filter={{ typeId: {
            operand1: 2,
            operator: "equals"
        },}}
    reducerArrayKey={"regionGeoObjects"}
    reducerAction={getRegionGeoObjects}
    {...props}
/>


export const FilialSelect = ({...props}) => <DomainSelect
    label={"Филиал"}
    reducerKey={"filials"}
    reducerArrayKey={"filials"}
    reducerAction={getFilials}
    {...props}
/>

export const FilialDivisionSelect = ({filialId, organizationId, ...props}) => <DomainSelect
    label={"Районное отделение"}
    reducerKey={"filialDivision"}
    reducerArrayKey={"filialDivisions"}
    reducerAction={getFilialDivisions}
    filterOptions={(opt) => {
        let result = true;

        if (opt.id == null) {
            return true;
        }

        if (filialId) {
            result = result && (Number(opt.filialId) === Number(filialId));
        }

        return result;
    }}
    {...props}
/>


export const DealStateSelect = ({...props}) => <DomainSelect
  label={"Статус сделки"}
  reducerKey={"dealState"}
  reducerArrayKey={"dealStates"}
  reducerAction={getDealStates}
  {...props}
/>
