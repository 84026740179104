import React from 'react';
import {Box, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {useTranslation} from "react-i18next";
import ConfirmDialog from "../Popups/message/PopupDelete";
import {DeleteForeverIconCustom} from "../index";
import TableMinSignersRow from "./TableMinSignersRow";

const TableMinSigners = ({edit, setSigners, signers, employee, employeeCount}) => {
    const {t} = useTranslation();
    const rowName = [
        {text: t("employee.tableItem.employee")},
        {text: t("specification.tableItem.actions")},
    ]
    const actions = [{icon: <DeleteForeverIconCustom/>, id: 'DeleteForeverIcon'}]

    const handleIcons = async (type, row) => {
        if (type === 'DeleteForeverIcon') {
            const newSigners = signers.filter(el => el.Id !== row)
            await setSigners(newSigners);
        }
    }

    return (
        <Table sx={{minWidth: '80%'}}>
            <TableHead sx={{backgroundColor: '#fafafa'}}>
                <TableRow>
                    {rowName.map((el, idx) => {
                        return <TableCell key={idx}
                                          align="center">{el.text}</TableCell>
                    })}
                </TableRow>
            </TableHead>
            <TableBody>
                {signers.length ? signers.map((row) => (<TableMinSignersRow edit={edit} key={row.Id} employee={employee} employeeCount={employeeCount} signers={signers} row={row} handleIcons={handleIcons} actions={actions} setSigners={setSigners}/>)) :
                    <TableRow><TableCell align="center" colSpan={8}><Box>Записей пока нет</Box></TableCell></TableRow>}
            </TableBody>
            <ConfirmDialog/>
        </Table>
    );
}

export default TableMinSigners;