import React, {useEffect, useState} from 'react';
import DashboardLayout from "../../layouts/Dashboard";
import {GENERAL_ROUTE} from "../../routes/const";
import BreadcrumbsShared from "../../components/Shared/BreadcrumbsShared";
import {useDispatch, useSelector} from "react-redux";
import {LOADING_FALSE, LOADING_TRUE} from "../../redux/constants/genericConstants";
import {getServicesSchedule} from "../../redux/actions/service/serviceActions";
import GeneralTable from "../../components/General/GeneralTable";
import {Card, CardActions, CardContent, Paper} from "@mui/material";
import Button from "@mui/material/Button";
import ShopIcon from '@mui/icons-material/Shop';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {changeBasket, clearBasket} from "../../redux/actions/basket/basketActions";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {confirmDialog} from "../../components/Popups/message/PopupDelete";
import ClientLayout from "../../layouts/ClientLayout";
import PopupPreliminarilyContract from "../../components/Contract/PopupPreliminarilyContract";
import RecommendIcon from '@mui/icons-material/Recommend';
import {addClientRequest} from "../../redux/actions/clientRequest/clientRequestActions";
import {showSuccessSnackbar} from "../../redux/actions/userActions";
import {geoObjectCRUD} from "../../http/CRUD";

function Basket(props) {
    const [open, setOpen] = useState(false)
    const handleClose = () => {
        setOpen(false)
    }
    const userLogin = useSelector((state) => state.user);
    const {isClient} = userLogin;
    const {profile} = useSelector((state) => state.profile);
    const {basket} = useSelector((state) => state.basket)
    const [basketLocal, setBasketLocal] = useState([])
    const [price, setPrice] = useState(0)
    const addressId = profile?.client?.person?.addressId
    const {servicesSchedule} = useSelector((state) => state.service);
    const dispatch = useDispatch()
    useEffect(() => {
        (async function () {
            dispatch({type: LOADING_TRUE})
            await dispatch(getServicesSchedule(1, 100))
            dispatch({type: LOADING_FALSE})
        }())
    }, [dispatch])

    useEffect(() => {
        setPrice(basketLocal.reduce((acc, curr) => {
            return acc + (curr.price * curr.qty);
        }, 0))
    }, [basketLocal]);

    const setBasketHandle = async (event) => {
        setBasketLocal(event)
        await dispatch(changeBasket(event))
    }

    useEffect(() => {
        if (basket) {
            setBasketLocal([...basket])
        }
    }, [basket])

    const handleConfirmClientRequest = async () => {
        const specification = basketLocal.map(el => ({
            price: el.price,
            count: el.qty,
            summary: el.summary,
            serviceId: el.id,
            scheduleId: el.scheduleId
        }))
        const specificationsSummary = specification.reduce((acc, curr) => {
            return acc + curr.summary;
        }, 0)
        dispatch(addClientRequest(specification, profile?.client?.id, addressId, specificationsSummary))
        setBasketLocal([])
        dispatch(clearBasket())
        dispatch(showSuccessSnackbar("Заявка создана!", "success"))
    }

    return (
        <>
            {!isClient && <DashboardLayout>
                <BreadcrumbsShared breadcrumbs={[
                    {text: 'Главная', link: GENERAL_ROUTE},
                    {text: 'Корзина', link: GENERAL_ROUTE + `/basket`},
                ]}/>
                <Box sx={{display: 'flex'}}>
                    <Paper>
                        {basketLocal &&
                        <GeneralTable basket dispatch={dispatch} servicesSchedule={servicesSchedule}
                                      setService={setBasketHandle} service={basketLocal} servicePackage={basketLocal}/>
                        }
                    </Paper>
                    <Card sx={{marginLeft: 5, flex: 1, alignSelf: 'flex-start'}}>
                        <CardContent>
                            <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom variant="body2">
                                Общая стоимость: {price}
                            </Typography>
                            <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom variant="body2">
                                Количество исследований: {basketLocal?.length || 0}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button disabled={!basket?.length} onClick={() => setOpen(true)} size="small"
                                    variant="outlined"
                                    startIcon={<ShopIcon/>}>Получить договор</Button>
                        </CardActions>
                    </Card>
                </Box>
                <Button
                    disabled={!basket?.length}
                    onClick={() => confirmDialog('Удаление элемента', 'Вы действительно хотите очистить корзину?', () => {
                        setBasketLocal([])
                        return dispatch(clearBasket())
                    })} sx={{marginTop: 3}} size="small" variant="outlined" startIcon={<HighlightOffIcon/>}>Очистить
                    корзину</Button>
                <Button disabled={!basket?.length} onClick={handleConfirmClientRequest} startIcon={<RecommendIcon/>}
                        sx={{marginTop: 3, marginLeft: 2}} size="small" variant="outlined">Оформить заявку</Button>
            </DashboardLayout>}
            {isClient && <ClientLayout>
                <BreadcrumbsShared breadcrumbs={[
                    {text: 'Главная', link: GENERAL_ROUTE},
                    {text: 'Корзина', link: GENERAL_ROUTE + `/basket`},
                ]}/>
                <Box sx={{display: 'flex'}}>
                    <Paper>
                        {basketLocal &&
                        <GeneralTable basket dispatch={dispatch} servicesSchedule={servicesSchedule}
                                      setService={setBasketHandle} service={basketLocal} servicePackage={basketLocal}/>
                        }
                    </Paper>
                    <Card sx={{marginLeft: 5, flex: 1, alignSelf: 'flex-start'}}>
                        <CardContent>
                            <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom variant="body2">
                                Общая стоимость: {price}
                            </Typography>
                            <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom variant="body2">
                                Количество исследований: {basketLocal?.length || 0}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button disabled={!basket?.length} onClick={() => setOpen(true)} size="small"
                                    variant="outlined"
                                    startIcon={<ShopIcon/>}>Получить договор</Button>
                        </CardActions>
                    </Card>
                </Box>
                <Button
                    disabled={!basket?.length}
                    onClick={() => confirmDialog('Удаление элемента', 'Вы действительно хотите очистить корзину?', () => {
                        setBasketLocal([])
                        return dispatch(clearBasket())
                    })} sx={{marginTop: 3}} size="small" variant="outlined" startIcon={<HighlightOffIcon/>}>Очистить
                    корзину</Button>
                <Button disabled={!basket?.length} onClick={handleConfirmClientRequest} startIcon={<RecommendIcon/>}
                        sx={{marginTop: 3, marginLeft: 2}} size="small" variant="outlined">Оформить заявку</Button>
            </ClientLayout>}
            <PopupPreliminarilyContract open={open} handleClose={handleClose}/>
        </>
    );
}

export default Basket;