import React from 'react';
import {useTranslation} from "react-i18next";
import {Box, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import ConfirmDialog from "../Popups/message/PopupDelete";
import GeneralTableRow from "./GeneralTableRow";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';

function GeneralTable({basket, servicePackage, service, setService, servicesSchedule, dispatch}) {
    const {t} = useTranslation();
    const rowName = [
        {text: t("generalTable.tableItem.id")},
        {text: t("generalTable.tableItem.name")},
        {text: t("generalTable.tableItem.price")},
        {text: t("generalTable.tableItem.qty")},
        {text: t("generalTable.tableItem.schedule")},
        {text: t("generalTable.tableItem.summary")},
        {text: t("generalTable.tableItem.actions")},
    ]
    const actionsAdded = [{icon: <AddShoppingCartIcon/>, id: 'AddShoppingCartIcon'}]
    const actionsDelete = [{icon: <RemoveShoppingCartIcon/>, id: 'RemoveShoppingCartIcon'}]
    const handleIcons = async (type, row) => {
            if (type === 'AddShoppingCartIcon') {
            }
    }
    return (
        <Table sx={{minWidth: '80%'}}>
            <TableHead>
                <TableRow>
                    {rowName.map((el, idx) => {
                        return <TableCell key={idx}
                                          align="center">{el.text}</TableCell>
                    })}
                </TableRow>
            </TableHead>
            <TableBody>
                {servicePackage.length ? servicePackage.map((row) => (
                        <GeneralTableRow dispatch={dispatch} servicesSchedule={servicesSchedule}
                                         setService={setService} service={service} servicePackage={service} handleIcons={handleIcons} row={row} key={row.id} actions={basket ? actionsDelete : actionsAdded}/>)) :
                    <TableRow><TableCell align="center" colSpan={8}><Box>Записей пока
                        нет</Box></TableCell></TableRow>}
            </TableBody>
            <ConfirmDialog/>
        </Table>
    );
}

export default React.memo(GeneralTable);