import i18n, {t} from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { TRANSLATIONS_RU } from "./ru/translations";
import { TRANSLATIONS_EN } from "./en/translations";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: TRANSLATIONS_EN
            },
            ru: {
                translation: TRANSLATIONS_RU
            }
        }
    });

i18n.changeLanguage("ru");

export const contractModalRowName = [{text: t("contract.tableItem.number")}, {text: t("contract.tableItem.name")}]
export const contractRequestModalRowName = [{text: t("contract.tableItem.number")},
    {text: t("contract.tableItem.name")},
    {text: t("contract.tableItem.date")},
    {text: t("contract.tableItem.type")},
    {text: t("contract.tableItem.filial")},
    {text: t("contract.tableItem.client")},
    {text: t("contract.tableItem.summary")},
]
export const contractTableRowName = [{text: t("contract.tableItem.number")},
    {text: t("contract.tableItem.name")},
    {text: t("contract.tableItem.date")},
    {text: t("contract.tableItem.typeContract")},
    {text: t("contract.tableItem.filial")},
    {text: t("contract.tableItem.client")},
    {text: t("contract.tableItem.summary")},
    {text: t("contract.tableItem.status")},
    {text: t("contract.tableItem.actions")},]

export const completionActTableRowName = [{text: t("completionAct.tableItem.id")},
    {text: t("completionAct.tableItem.startDate")},
    {text: t("completionAct.tableItem.name")},
    {text: t("completionAct.tableItem.status")},
    {text: t("completionAct.tableItem.view")},
    {text: t("completionAct.tableItem.bookkeeping")},
    {text: t("completionAct.tableItem.actions")},]

export const legalClientTableRowName = [{text: t("legalClient.tableItem.id")},
    {text: t("legalClient.tableItem.name")},
    {text: t("legalClient.tableItem.bin")},
    {text: t("legalClient.tableItem.cityLine")},
    {text: t("legalClient.tableItem.regionLine")}]

export const personTableRowName = [{text: t("client.tableItem.id")},
    {text: t("client.tableItem.fullName")},
    {text: t("client.tableItem.email")},
    {text: t("client.tableItem.addressLine")},
    {text: t("client.tableItem.phoneNumber")}]
