export const COMPLETION_ACT_GET_REQUEST = 'COMPLETION_ACT_GET_REQUEST'
export const COMPLETION_ACT_GET_SUCCESS = 'COMPLETION_ACT_GET_SUCCESS'
export const COMPLETION_ACT_GET_ERROR = 'COMPLETION_ACT_GET_ERROR'

export const COMPLETION_ACT_ONE_GET_REQUEST = 'COMPLETION_ACT_ONE_GET_REQUEST'
export const COMPLETION_ACT_ONE_GET_SUCCESS = 'COMPLETION_ACT_ONE_GET_SUCCESS'
export const COMPLETION_ACT_ONE_GET_ERROR = 'COMPLETION_ACT_ONE_GET_ERROR'

export const COMPLETION_ACT_ADD_REQUEST = 'COMPLETION_ACT_ADD_REQUEST'
export const COMPLETION_ACT_ADD_SUCCESS = 'COMPLETION_ACT_ADD_SUCCESS'
export const COMPLETION_ACT_ADD_ERROR = 'COMPLETION_ACT_ADD_ERROR'

export const INVOICE_GET_REQUEST = 'INVOICE_GET_REQUEST'
export const INVOICE_GET_SUCCESS = 'INVOICE_GET_SUCCESS'
export const INVOICE_GET_ERROR = 'INVOICE_GET_ERROR'

export const INVOICE_ONE_GET_REQUEST = 'INVOICE_ONE_GET_REQUEST'
export const INVOICE_ONE_GET_SUCCESS = 'INVOICE_ONE_GET_SUCCESS'
export const INVOICE_ONE_GET_ERROR = 'INVOICE_ONE_GET_ERROR'

export const INVOICE_ADD_REQUEST = 'INVOICE_ADD_REQUEST'
export const INVOICE_ADD_SUCCESS = 'INVOICE_ADD_SUCCESS'
export const INVOICE_ADD_ERROR = 'INVOICE_ADD_ERROR'

export const PAYMENT_GET_REQUEST = 'PAYMENT_GET_REQUEST'
export const PAYMENT_GET_SUCCESS = 'PAYMENT_GET_SUCCESS'
export const PAYMENT_GET_ERROR = 'PAYMENT_GET_ERROR'

export const PAYMENT_ONE_GET_REQUEST = 'PAYMENT_ONE_GET_REQUEST'
export const PAYMENT_ONE_GET_SUCCESS = 'PAYMENT_ONE_GET_SUCCESS'
export const PAYMENT_ONE_GET_ERROR = 'PAYMENT_ONE_GET_ERROR'

export const PAYMENT_ADD_REQUEST = 'PAYMENT_ADD_REQUEST'
export const PAYMENT_ADD_SUCCESS = 'PAYMENT_ADD_SUCCESS'
export const PAYMENT_ADD_ERROR = 'PAYMENT_ADD_ERROR'