import React, {useState} from 'react';
import {Button, Input} from "@mui/material";
import {makeStyles} from "@mui/styles";
import Paper from "@mui/material/Paper";
import SearchIcon from '@mui/icons-material/Search';
import {useDispatch} from "react-redux";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    search: {
        flexGrow: 1,
        height: 42,
        padding: theme.spacing(0, 2),
        display: 'flex',
        alignItems: 'center'
    },
    searchIcon: {
        marginRight: theme.spacing(2),
        color: theme.palette.icon
    },
    searchInput: {
        flexGrow: 1
    },
    searchButton: {
        marginLeft: theme.spacing(2)
    }
}));

const SearchPanel = ({ page, setPage, func, searchName, setRow, setRowCount, setFilter }) => {
    const classes = useStyles();
    const dispatch = useDispatch()
    const [searchState, setSearchState] = useState('')

    const handleSearch = async () => {
        const {result, pageCount} = await dispatch(func(1, 10, {
                [searchName]: {
                    operand1: searchState,
                    operator: "like"
                },
        }, true))
        setRowCount(pageCount)
        setPage(1)
        setRow(result)
        setFilter({
                [searchName]: {
                    operand1: searchState,
                    operator: "like"
                },
        })
    }

    const handleClear = async () => {
        const {result, pageCount} = await dispatch(func(1, 10))
        setRowCount(pageCount)
        setPage(1)
        setRow(result)
        setFilter({})
    }

    return (
        <div
            className={classes.root}
        >
            <Paper
                className={classes.search}
                elevation={1}
            >
                <SearchIcon className={classes.searchIcon} />
                <Input
                    onChange={(e) => setSearchState(e.target.value)}
                    className={classes.searchInput}
                    disableUnderline
                    placeholder="Поиск..."
                />
            </Paper>
            <Button
                onClick={handleSearch}
                className={classes.searchButton}
                disabled={!searchState}
                variant="contained"
            >
                Найти
            </Button>
            <Button
                onClick={handleClear}
                disabled={!searchState}
                sx={{marginLeft: '5px'}}
                className={classes.searchButton}
                variant="contained"
            >
                Очистить
            </Button>
        </div>
    );
};

export default SearchPanel;