import {
    ADD_PRODUCT_TO_BASKET_FAIL,
    ADD_PRODUCT_TO_BASKET_REQUEST, ADD_PRODUCT_TO_BASKET_START,
    ADD_PRODUCT_TO_BASKET_SUCCESS, CLEAR_BASKET, DELETE_ITEM_FROM_BASKET
} from "../../constants/basket/basketConstants";

export const addProductToBasket = (row) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ADD_PRODUCT_TO_BASKET_REQUEST,
        });
        dispatch({
            type: ADD_PRODUCT_TO_BASKET_SUCCESS,
            payload: {...row, inBasket: true}
        });
        localStorage.setItem('basketItems', JSON.stringify(getState().basket.basket))
    } catch (error) {
        dispatch({
            type: ADD_PRODUCT_TO_BASKET_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const clearBasket = () => async (dispatch) => {
    try {
        dispatch({
            type: CLEAR_BASKET,
        });
        localStorage.removeItem('basketItems')
    } catch (error) {
        dispatch({
            type: ADD_PRODUCT_TO_BASKET_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};


export const deleteItemBasket = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: DELETE_ITEM_FROM_BASKET,
            payload: {id}
        });
        localStorage.setItem('basketItems', JSON.stringify(getState().basket.basket))
    } catch (error) {
        dispatch({
            type: ADD_PRODUCT_TO_BASKET_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const changeBasket = (data) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ADD_PRODUCT_TO_BASKET_START,
            payload: data
        });
        dispatch({
            type: ADD_PRODUCT_TO_BASKET_FAIL,
        });
        localStorage.setItem('basketItems', JSON.stringify(getState().basket.basket))
    } catch (error) {
        dispatch({
            type: ADD_PRODUCT_TO_BASKET_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};