import React, {useEffect, useState} from 'react';
import {
    Box,
    CircularProgress,
    Collapse,
    IconButton,
    Table, TableBody,
    TableCell,
    TableContainer,
    TableHead, TablePagination,
    TableRow
} from "@mui/material";
import {confirmDialog} from "../Popups/message/PopupDelete";
import {deleteService, getServiceChild} from "../../redux/actions/service/serviceActions";
import {useLocation} from "react-router-dom";
import {useDispatch} from "react-redux";

const TableRowCustomServiceChild = ({typeRow, setEdit, row, actions, handleClick, rowName}) => {
    const {pathname} = useLocation()
    const [show, setShow] = useState(false)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch()
    const deleteIconHandler = async (func) => {
        const res = await dispatch(func)
        res === 'Network Error' ? handleClick('error') :
            handleClick('success')
    }
    const [servicesChild, setServicesChild] = useState([])
    const [servicesChildCount, setServicesChildCount] = useState(null)
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleIcons = async (e, id, rowId) => {
        if (e.target.childNodes.length) {
            let hasIconChild = false
            if (id === 'DeleteForeverIcon' && pathname === '/service') {
                e.target.childNodes.forEach(el => {
                    if (el.getAttribute('data-testid') === id) {
                        hasIconChild = true
                    }
                })
                if (hasIconChild)
                    await deleteIconHandler(deleteService(rowId))
            }
            if (id === 'VisibilityIcon') {
                setShow(!show)
            }
            if (id === 'EditIcon') {
                setEdit(rowId, typeRow)
            }

        } else {
            if (e.target.getAttribute('data-testid') === id) {
                if (id === 'DeleteForeverIcon' && pathname === '/service') {
                    await deleteIconHandler(deleteService(rowId))
                }
                if (id === 'VisibilityIcon') {
                    setShow(!show)
                }
                if (id === 'EditIcon') {
                    setEdit(rowId, typeRow)
                }
            }
        }
    }

    useEffect(() => {
        (async function () {
            if (row.id && show) {
                setLoading(true)
                const res = await dispatch(getServiceChild(page + 1, row.id))
                setServicesChild(res.result)
                setServicesChildCount(res.total)
                setLoading(false)
            }
        }())
    }, [dispatch, page, row.id, show])

    return (
        <React.Fragment>
            <TableRow
                key={row.id}
                sx={{
                    '&:last-child td, &:last-child th': {border: 0},
                    cursor: 'pointer'
                }}>
                {Object.keys(row).slice(0, -1).map((rowChild, idx) => {
                    if (idx === 0) {
                        return <TableCell key={idx} component="th"
                                          scope="row">
                            <Box>
                                {row[rowChild]}
                            </Box>
                        </TableCell>
                    }
                    return <TableCell key={idx}
                                      align="center">{row[rowChild]}</TableCell>
                })}
                <TableCell align="center">
                    <Box>
                        {actions.slice(row.childsCount ? 0 : 1).map((el, idx) => (
                            <IconButton data-testid={el.id}
                                        children={el.icon}
                                        onClick={(e) => {
                                            if (el.id === 'DeleteForeverIcon') {
                                                confirmDialog('Удаление элемента', 'Вы действительно хотите удалить этот элемент?', () => {
                                                    return handleIcons(e, el.id, row.id)
                                                })
                                            } else
                                                return handleIcons(e, el.id, row.id)
                                        }}
                                        key={idx}>
                            </IconButton>
                        ))}
                    </Box>
                </TableCell>
            </TableRow>
            <TableRow style={{backgroundColor: '#bdbcbc'}}>
                <TableCell style={{
                    paddingBottom: 0,
                    paddingTop: 0,
                    paddingLeft: 0,
                    paddingRight: 0
                }} colSpan={6}>
                    <Collapse in={show} timeout="auto" unmountOnExit>
                        {loading ? <Box sx={{
                            width: '100%',
                            height: '300px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}><CircularProgress/></Box> : servicesChild &&
                            (
                                <TableContainer>
                                    <Table>
                                        <TableHead sx={{backgroundColor: 'rgba(189,188,188,0.77)'}}>
                                            <TableRow>
                                                {rowName && rowName.map((el, idx) => {
                                                    return <TableCell key={idx}
                                                                      align={idx !== 0 ? 'center' : 'left'}>{el.text}</TableCell>
                                                })}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {servicesChild.map(el => {
                                                return {
                                                    id: el.id,
                                                    name: el.name,
                                                    price: el.price || 'Нет',
                                                    childsCount: el.childsCount
                                                }
                                            }).map((row, idx) => (
                                                <TableRow
                                                    key={row.id}
                                                    sx={{
                                                        '&:last-child td, &:last-child th': {border: 0},
                                                        cursor: 'pointer'
                                                    }}>
                                                    {Object.keys(row).slice(0, -1).map((rowChild, idx) => {
                                                        if (idx === 0) {
                                                            return <TableCell key={idx} component="th"
                                                                              scope="row">
                                                                <Box>
                                                                    {row[rowChild]}
                                                                </Box>
                                                            </TableCell>
                                                        }
                                                        return <TableCell key={idx}
                                                                          align="center">{row[rowChild]}</TableCell>
                                                    })}
                                                    <TableCell align="center">
                                                        <Box>
                                                            {actions.slice(row.childsCount ? 0 : 1).map((el, idx) => (
                                                                <IconButton data-testid={el.id}
                                                                            children={el.icon}
                                                                            onClick={(e) => {
                                                                                if (el.id === 'DeleteForeverIcon') {
                                                                                    confirmDialog('Удаление элемента', 'Вы действительно хотите удалить этот элемент?', () => {
                                                                                        return handleIcons(e, el.id, row.id)
                                                                                    })
                                                                                } else
                                                                                    return handleIcons(e, el.id, row.id)
                                                                            }}
                                                                            key={idx}>
                                                                </IconButton>
                                                            ))}
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    {servicesChildCount && <TablePagination rowsPerPageOptions={[10, 25, 100]}
                                                                            component="div"
                                                                            labelRowsPerPage="Отображаемых элементов"
                                                                            count={servicesChildCount}
                                                                            page={page}
                                                                            onPageChange={handleChangePage}
                                                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                                                            rowsPerPage={rowsPerPage}/>}
                                </TableContainer>
                            )}
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default TableRowCustomServiceChild;