import {$authHost} from "../../../http";
import {
    LEGAL_CLIENT_ADD_ERROR,
    LEGAL_CLIENT_ADD_REQUEST,
    LEGAL_CLIENT_ADD_SUCCESS,
    LEGAL_CLIENT_DELETE_ERROR,
    LEGAL_CLIENT_DELETE_REQUEST,
    LEGAL_CLIENT_DELETE_SUCCESS,
    LEGAL_CLIENT_EDIT_ERROR,
    LEGAL_CLIENT_EDIT_REQUEST,
    LEGAL_CLIENT_EDIT_SUCCESS,
    LEGAL_CLIENT_GET_ERROR,
    LEGAL_CLIENT_GET_REQUEST,
    LEGAL_CLIENT_GET_SUCCESS, ONE_LEGAL_CLIENT_EDIT_ERROR,
    ONE_LEGAL_CLIENT_EDIT_REQUEST, ONE_LEGAL_CLIENT_EDIT_SUCCESS,
    ONE_LEGAL_CLIENT_GET_ERROR,
    ONE_LEGAL_CLIENT_GET_REQUEST,
    ONE_LEGAL_CLIENT_GET_SUCCESS
} from "../../constants/client/legalClientConstants";
import {getOneTypeClient} from "./typeClientActions";
import {addClient} from "./clientActions";
import {getData} from "../template/get";
import {editData} from "../template/edit";

export const getLegalClient = (page, size, args, nonGlobal) => async (dispatch) => {
    return await dispatch(getData(LEGAL_CLIENT_GET_REQUEST, LEGAL_CLIENT_GET_SUCCESS, LEGAL_CLIENT_GET_ERROR, 'legalPerson', true, page, null, null, true, size, args, nonGlobal))
};

export const getOneLegalClient = (id) => async (dispatch) => {
    try {
        dispatch({
            type: ONE_LEGAL_CLIENT_GET_REQUEST,
        });

        const {data} = await $authHost.get(
            `api/v1/legalPerson/${id}`,
        );
        dispatch({
            type: ONE_LEGAL_CLIENT_GET_SUCCESS,
            payload: data
        });
        return data
    } catch (error) {
        dispatch({
            type: ONE_LEGAL_CLIENT_GET_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const deleteLegalClient = (id) => async (dispatch) => {
    try {
        dispatch({
            type: LEGAL_CLIENT_DELETE_REQUEST,
        });

        await $authHost.delete(
            "/api/v1/legalPerson",
            {
                data: {
                    id
                }
            },
        );
        dispatch(getLegalClient())
        dispatch({
            type: LEGAL_CLIENT_DELETE_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: LEGAL_CLIENT_DELETE_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const addLegalClient = (name, bin, district, addressLine, phoneNumber, email, bankAccountNumber, supervisorName, typeId) => async (dispatch) => {
    try {
        await dispatch({
            type: LEGAL_CLIENT_ADD_REQUEST,
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        const {data} = await $authHost.post(
            "/api/v1/legalPerson",
            {
                bin,
                name,
                addressId: district,
                addressLine,
                CityLine: 'Город',
                regionLine: 'Регион',
                phoneNumber,
                email,
                bankAccountNumber,
                supervisorName,
                typeId
            },
            config
        );
        await addClient(null, data.id, null)
        await dispatch(getLegalClient())
        await dispatch({
            type: LEGAL_CLIENT_ADD_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: LEGAL_CLIENT_ADD_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const editLegalClient = (id, name, bin, district, addressLine, phoneNumber, email, bankAccountNumber, supervisorName, typeId) => async (dispatch) => {
    return await dispatch(editData(LEGAL_CLIENT_EDIT_REQUEST, LEGAL_CLIENT_EDIT_SUCCESS, LEGAL_CLIENT_EDIT_ERROR, 'legalPerson', getLegalClient(), {
        id,
        name,
        bin,
        addressLine,
        addressId: district,
        phoneNumber,
        CityLine: 'Город',
        regionLine: 'Регион',
        email,
        bankAccountNumber,
        supervisorName,
        typeId
    }))
};

export const editOneLegalClient = (id, name, bin, cityLine, regionLine, addressLine, phoneNumber, email, bankAccountNumber, supervisorName, typeId) => async (dispatch) => {
    try {
        dispatch({
            type: ONE_LEGAL_CLIENT_EDIT_REQUEST,
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.put(
            "/api/v1/legalPerson",
            {
                id,
                bin,
                cityLine,
                name,
                regionLine,
                addressLine,
                phoneNumber,
                email,
                bankAccountNumber,
                supervisorName,
                typeId
            },
            config
        );
        const type = await getOneTypeClient(typeId)
        dispatch({
            type: ONE_LEGAL_CLIENT_EDIT_SUCCESS,
            payload: {
                id,
                bin,
                cityLine,
                name,
                regionLine,
                addressLine,
                phoneNumber,
                email,
                bankAccountNumber,
                supervisorName,
                typeId,
                type
            }
        });
    } catch (error) {
        dispatch({
            type: ONE_LEGAL_CLIENT_EDIT_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

