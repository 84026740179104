import React, {useEffect, useRef, useState} from 'react';
import {Grid, Box, LinearProgress} from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {useDispatch} from "react-redux";
import {editContract} from "../../../../redux/actions/contract/contractActions";
import {useHistory} from "react-router-dom";
import {CONTRACT_ADDITIONAL_ROUTE, CONTRACT_ROUTE} from "../../../../routes/const";
import {getOnePerson} from "../../../../redux/actions/client/personActions";
import {getOneLegalClient} from "../../../../redux/actions/client/legalClientActions";
import moment from "moment";
import {$s3host} from "../../../../http";
import {filialCRUD, filialDivisionCRUD} from "../../../../http/CRUD";
import {initialValueLegalContract2} from "../templateLegal";
import ClassicEditor from '@pred1995/crm-nce-ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {initialValuePerson} from "../templatePerson";

const GenerateDocumentEditPage = ({
                                      data,
                                      data2,
                                      contractObject,
                                      educationServices,
                                      schedules,
                                      isAdditional,
                                      filial,
                                      geo,
                                      id,
                                      signers,
                                      activeStep,
                                      handleBack,
                                      name,
                                      number,
                                      handleFormGenerate,
                                      active,
                                      legalClient,
                                      filialDivision,
                                      typeContract,
                                      date1,
                                      date2,
                                      specification,
                                      client,
                                      type,
                                      registeredAt,
                                      actionAt,
                                      localCity,
                                      localFilial,
                                      localFilialDivision,
                                      localDistrict,
                                      setSquare, square, multiplicity, setMultiplicity
                                  }) => {
    const editorRef = useRef(null);
    const dispatch = useDispatch()
    const history = useHistory()
    const [progress, setProgress] = useState()
    const [price, setPrice] = useState()

    const [nameClient, setNameClient] = useState('')
    const [addressClient, setAddressClient] = useState('')
    const [identityNumberClient, setIdentityNumberClient] = useState('')
    const [phoneNumberClient, setPhoneNumberClient] = useState('')
    const [binClient, setBinClient] = useState('')

    const [filialData, setFilialData] = useState({})
    const [filialDivisionData, setFilialDivisionData] = useState({})

    const [localClientGenerate, setLocalClientGenerate] = useState({})


    useEffect(() => {
        let allPrice = specification.reduce((acc, curr) => {
            return acc + (curr.price * curr.quantity) * +curr.square * +curr.multiplicity;
        }, 0)
        setPrice(allPrice)
    }, [specification]);

    useEffect(() => {
        (async function () {
            if (localFilialDivision) {
                const division = await filialDivisionCRUD.get(localFilialDivision)
                setFilialDivisionData(division)
            } else if (localFilial) {
                const filial = await filialCRUD.get(localFilial)
                setFilialData(filial)
            }
        }())
    }, [dispatch, localFilial, localFilialDivision])

    useEffect(() => {
        (async function () {
            if (typeContract) {
                const data = await dispatch(getOnePerson(client))
                setNameClient(data?.fullName)
                setAddressClient(data?.addressLine)
                setIdentityNumberClient(data?.identityNumber)
                setPhoneNumberClient(data?.phoneNumber)
            } else {
                const data = await dispatch(getOneLegalClient(legalClient))
                setNameClient(data?.name)
                setAddressClient(data?.addressLine)
                setBinClient(data?.bin)
                setPhoneNumberClient(data?.phoneNumber)
                setLocalClientGenerate(data)
            }
        }())
    }, [client, dispatch, legalClient, typeContract])

    const log = async () => {
        const htmlFile = editorRef.current?.getData()
        const file = new File([htmlFile], "name", {type: 'text/html'});
        let formData = new FormData()
        formData.append("file", file)
        const size = file.size
        setProgress(1)
        const {data: s3Path} = await $s3host.post("/api/v1/file/upload?userName=user1&path=contracts&size=" + size, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress: progressEvent => {
                const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                if (totalLength)
                    setProgress(Math.round((progressEvent.loaded * 100) / totalLength))
            },
        })
        let newSigners = [...signers]
        if (isAdditional) {
            await dispatch(editContract(id, name, number, registeredAt, actionAt, price, typeContract ? client : legalClient, type, specification, newSigners, typeContract ? 'personId' : 'legalPersonId', s3Path, localFilial, localFilialDivision, localCity, educationServices,
                schedules,
                contractObject, localDistrict, true, data.stateId, null, data2))
            await history.push(CONTRACT_ADDITIONAL_ROUTE)
        } else {
            await dispatch(editContract(id, name, number, registeredAt, actionAt, price, typeContract ? client : legalClient, type, specification, newSigners, typeContract ? 'personId' : 'legalPersonId', s3Path, localFilial, localFilialDivision, localCity, educationServices,
                schedules,
                contractObject, localDistrict, false, data.stateId, null, data2))
            await history.push(CONTRACT_ROUTE)
        }
    };

    const contractInitLegal = initialValueLegalContract2({
        specification,
        supervisorName: localClientGenerate?.supervisorName,
        city: geo[geo?.findIndex(el => el.id === localCity)]?.name,
        name,
        number,
        schedules: schedules.map(el => ({
            id: el.id,
            targetMonth: moment(el.targetMonth).format('MMMM'),
            targetYear: moment(el.targetYear).format('YYYY')
        })),
        filial: Object.keys(filialDivisionData).length ? `${filialDivisionData?.filial?.name} ${filialDivisionData?.name}` : filialData?.name,
        filialKz: Object.keys(filialDivisionData).length ? `${filialDivisionData?.filial?.nameKz} ${filialDivisionData?.nameKz}` : filialData?.nameKz,
        filialDate: filial[filial?.findIndex(el => el.id === localFilial)],
        client: nameClient,
        inn: identityNumberClient,
        bin: binClient,
        date2,
        date1,
        price,
        proxy: Object.keys(filialDivisionData).length ? {
            proxy: filialDivisionData?.proxy,
            proxyDate: moment(filialDivisionData?.proxyDate).format('LL'),
            proxyNumber: filialDivisionData?.proxyNumber,

        } : {
            proxy: filialData?.proxy,
            proxyDate: moment(filialData?.proxyDate).format('LL'),
            proxyNumber: filialData?.proxyNumber,
        },
        phoneNumber: phoneNumberClient,
        addressLine: addressClient,
        supervisor: Object.keys(filialDivisionData).length ? filialDivisionData?.supervisor?.fullName : filialData?.supervisor?.fullName,
        position: Object.keys(filialDivisionData).length ? filialDivisionData?.supervisor?.position?.name : filialData?.supervisor?.position?.name,
        position2: Object.keys(filialDivisionData).length ? filialDivisionData?.supervisor?.position?.name : filialData?.supervisor?.position?.name,
        clientData: localClientGenerate,
        contractObject
    })


    const contractInitPerson = initialValuePerson({
        specification,
        supervisorName: localClientGenerate?.supervisorName,
        city: geo[geo?.findIndex(el => el.id === localCity)]?.name,
        name,
        number,
        schedules: schedules.map(el => ({
            id: el.id,
            targetMonth: moment(el.targetMonth).format('MMMM'),
            targetYear: moment(el.targetYear).format('YYYY')
        })),
        filial: Object.keys(filialDivisionData).length ? `${filialDivisionData?.filial?.name} ${filialDivisionData?.name}` : filialData?.name,
        filialKz: Object.keys(filialDivisionData).length ? `${filialDivisionData?.filial?.nameKz} ${filialDivisionData?.nameKz}` : filialData?.nameKz,
        filialDate: filial[filial?.findIndex(el => el.id === localFilial)],
        client: nameClient,
        inn: identityNumberClient,
        bin: binClient,
        date2,
        date1,
        price,
        proxy: Object.keys(filialDivisionData).length ? {
            proxy: filialDivisionData?.proxy,
            proxyDate: moment(filialDivisionData?.proxyDate).format('LL'),
            proxyNumber: filialDivisionData?.proxyNumber,

        } : {
            proxy: filialData?.proxy,
            proxyDate: moment(filialData?.proxyDate).format('LL'),
            proxyNumber: filialData?.proxyNumber,
        },
        phoneNumber: phoneNumberClient,
        addressLine: addressClient,
        supervisor: Object.keys(filialDivisionData).length ? filialDivisionData?.supervisor?.fullName : filialData?.supervisor?.fullName,
        position: Object.keys(filialDivisionData).length ? filialDivisionData?.supervisor?.position?.name : filialData?.supervisor?.position?.name,
        position2: Object.keys(filialDivisionData).length ? filialDivisionData?.supervisor?.position?.name : filialData?.supervisor?.position?.name,
        clientData: localClientGenerate,
        contractObject
    })

    return (
        <Box component="form" onSubmit={handleFormGenerate}
             sx={{mt: 10, padding: '0px 10px'}}>
            <Grid container spacing={2}>
                <Grid item xs={4}/>
                <Grid item xs={4}>
                    <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                    >
                        Сформировать документ
                    </Button>
                </Grid>
            </Grid>
            {active && <Box sx={{mt: 5}}>
                <CKEditor
                    id="editor"
                    onReady={ editor => {
                        editorRef.current = editor
                    } }
                    editor={ ClassicEditor }
                    data={typeContract ? contractInitPerson : contractInitLegal}
                />
            </Box>}
            {/*<Button onClick={() => exportHtml()}>Export</Button>*/}
            {progress && <Box sx={{width: '100%', mt: 3, display: 'flex', alignItems: 'center'}}>
                <Box sx={{width: '100%', mr: 1}}>
                    <LinearProgress variant="determinate" value={progress}/>
                </Box>
                <Box sx={{minWidth: 35}}>
                    <Typography variant="body2" color="text.secondary">{`${Math.round(
                        progress,
                    )}%`}</Typography>
                </Box>
            </Box>}
            <Box sx={{width: '100%', mt: 10}}>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Button
                            fullWidth
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            variant="contained"
                        >
                            Назад
                        </Button>
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            disabled={!active}
                            fullWidth
                            onClick={log}
                            variant="contained"
                        >
                            Завершить
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default GenerateDocumentEditPage;
