import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {CircularProgress} from "@mui/material";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {addTypeService, editTypeService} from "../../redux/actions/service/typeServiceActions";
import TextShared from "../Shared/TextShared";
import NumberShared from "../Shared/NumberShared";
import {style} from "./popup";

export default function PopupTypeService({open, setOpen, title, edit, setEdit, id}) {
    const [name, setName] = useState('')
    const [code, setCode] = useState(1)
    const dispatch = useDispatch()
    const [description, setDescription] = useState('')
    const {loading, typeService} = useSelector((state) => state.typeService);
    const handleForm = async () => {
        if (edit) {
            await dispatch(editTypeService(id, code, description, name))
        } else
            await dispatch(addTypeService(code, description, name))
        setName('')
        setCode(1)
        setDescription('')
        setEdit(false)
        setOpen(false)
    }
    const handleClose = async () => {
        if (edit) {
            setEdit(false)
            setName('')
            setCode('')
            setDescription('')
        }
        setOpen(false)
    }
    useEffect(() => {
        if (edit) {
            const {name, code, description} = typeService.find(el => el.id === id)
            setName(name)
            setCode(code)
            setDescription(description)
        }
    }, [edit, id, typeService])
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {loading ? <CircularProgress/> :
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {title} {edit && '(редактирование)'}
                        </Typography>
                        <Box component="form" onSubmit={handleForm} sx={{mt: 1}}>
                            <TextShared value={name} setValue={setName} label="Название"/>
                            <NumberShared value={code} setValue={setCode} label="Код"/>
                            <TextShared value={description} setValue={setDescription} label="Описание"/>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{mt: 3, mb: 2}}
                            >
                                {edit ? 'Редактирование' : 'Добавить'}
                            </Button>
                        </Box>
                    </Box>}
            </Modal>
        </div>
    );
}
