import React, {useEffect, useState} from 'react';
import Dashboard from "../../layouts/Dashboard";
import BreadcrumbsShared from "../../components/Shared/BreadcrumbsShared";
import {DEAL_ADD_ROUTE, DEAL_ROUTE} from "../../routes/const";
import {Button, FormGroup, Grid, Paper, Stack, Switch} from "@mui/material";
import Box from "@mui/material/Box";
import {LoadingButton} from "@mui/lab";
import {
    DatePickerShared,
    PopupLegalClient,
    PopupPerson,
    SelectShared
} from "../../components";
import {useDispatch, useSelector} from "react-redux";
import TextShared from "../../components/Shared/TextShared";
import {getServiceCategory} from "../../redux/actions/serviceCategory/serviceCategoryActions";
import Typography from "@mui/material/Typography";
import {getDeal, getDealStage} from "../../redux/actions/deal/dealActions";
import {useHistory} from "react-router-dom";
import PersonField from "../../components/Fields/PersonField";
import LegalClientField from "../../components/Fields/LegalClientField";
import EmployeeField from "../../components/Fields/EmployeeField";
import {getFilial, getFilialDivision} from "../../redux/actions/filial/filialActions";
import {$authHost} from "../../http";
import {dealCRUD} from "../../http/CRUD";
import {v4 as uuidv4} from "uuid";

function DealAdd() {
    const [loadingAdd, setLoadingAdd] = useState(false)
    const dispatch = useDispatch();
    const history = useHistory()
    const authorizedEmployee = useSelector(state => state.employee.authorizedEmployee)

    useEffect(() => {
        setManager(authorizedEmployee?.id)
        setManagerObject(authorizedEmployee)
    }, [authorizedEmployee])

    React.useEffect(() => {
        if (history.location.state?.client) {
            const requestClient = history.location.state?.client;
            setLocalFilial(requestClient?.filialId)
            if (requestClient?.client?.legalPersonId) {
                setLegalClient(requestClient?.client?.legalPersonId)
                setClientData(requestClient?.client?.legalPerson)
            } else {
                setClient(requestClient?.client?.personId)
                setClientData(requestClient?.client?.person)
            }
            const clientRequestSpecifications = requestClient?.specifications?.map(el => {
                const newElement = {
                    Id: uuidv4(),
                    service: el.service,
                    serviceId: el.service?.id,
                    code: el.service?.code,
                    unitId: '',
                    unit: {},
                    quantity: el?.count,
                    price: el?.summary,
                    objectId: '',
                    schedule: el?.schedule,
                    scheduleId: el?.scheduleId,
                }
                return newElement
            })
            setSpecifications(clientRequestSpecifications)
        }
    }, [history.location.state])


    const [comments, setComments] = useState('')
    const [createdAt, setCreatedAt] = useState(new Date())
    const [manager, setManager] = useState(1)
    const [managerObject, setManagerObject] = useState({})
    const [specifications, setSpecifications] = useState([])
    const [legalClient, setLegalClient] = useState('')
    const [serviceCategoryId, setServiceCategoryId] = useState(1)
    const [typeClient, setTypeClient] = useState(false)
    const [localFilial, setLocalFilial] = useState(1)
    const [localFilialDivision, setLocalFilialDivision] = useState()
    const [openPerson, setOpenPerson] = useState(false);
    const [openLegalClient, setOpenLegalClient] = useState(false);

    const [client, setClient] = useState('')
    const [clientData, setClientData] = useState({})
    const {serviceCategory} = useSelector((state) => state.serviceCategory);
    const {filial, filialDivision} = useSelector((state) => state.filial);

    useEffect(() => {
        dispatch(getServiceCategory(1, 100))
        dispatch(getDealStage(1, 100))
        dispatch(getDeal(1, 100))
    }, [dispatch]);

    const handleSubmit = async (event) => {
        event.preventDefault()
        setLoadingAdd(true)
        const name = serviceCategory.find(el => el.id === serviceCategoryId)?.name
        const {data: dataClient} = await $authHost.post(
            "/api/v1/client/query",
            {
                filter: {
                    [typeClient ? 'personId' : 'legalPersonId']: {
                        operand1: typeClient ? client : legalClient,
                        operator: "equals"
                    },
                }
            }
        )
        await dealCRUD.create({
            contactId: dataClient?.result[0]?.id,
            name,
            sortingIndex: 1,
            stageId: 1,
            specifications: specifications,
            comments: [{
                'comment': comments || ' ',
                stageId: 1
            }],
            filialId: localFilial,
            filialDivisionId: localFilialDivision,
            managerId: manager,
            createdAt
        })
        history.push(DEAL_ROUTE)
    }

    useEffect(() => {
        (async function(){
            await dispatch(getFilial(1, 100))
            await dispatch(getFilialDivision(1, 10000))
        }())
    }, [dispatch])

    return (
        <Dashboard>
            <BreadcrumbsShared breadcrumbs={[
                {text: 'Сделки', link: DEAL_ROUTE},
                {text: 'Добавить сделку', link: DEAL_ADD_ROUTE},
            ]}/>
            <Box component="form" onSubmit={handleSubmit}>
                <Box component={Paper} sx={{mt: 1, display: 'flex', padding: '0px 10px', flexWrap: 'wrap'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <FormGroup sx={{marginTop: 1, display: 'flex', justifyContent: 'center'}}>
                                <Typography>Тип контакта</Typography>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography>юр. лицо</Typography>
                                    <Switch onChange={(event) => setTypeClient(event.target.checked)}
                                            checked={typeClient}
                                            inputProps={{'aria-label': 'ant design'}}/>
                                    <Typography>физ. лицо</Typography>
                                </Stack>
                            </FormGroup>
                            {typeClient ?
                                <Button sx={{marginTop: 1}} onClick={() => setOpenPerson(true)} color="info"
                                        variant="contained">Добавить физ лицо</Button> :
                                <Button sx={{marginTop: 1}} onClick={() => setOpenLegalClient(true)} color="info"
                                        variant="contained">Добавить юр. лицо</Button>}
                        </Grid>
                        <Grid item xs={6}>
                            {typeClient ?
                                <PersonField
                                    fullWidth
                                    value={client}
                                    object={clientData}
                                    label="Контакт (физ. лицо)"
                                    sx={{paddingTop: '35px'}}
                                    onChange={(client) => {
                                        setClient(client.id)
                                    }}
                                /> :
                                <LegalClientField
                                    fullWidth
                                    value={legalClient}
                                    object={clientData}
                                    label="Контакт (юр. лицо)"
                                    sx={{paddingTop: '35px'}}
                                    onChange={(client) => {
                                        setLegalClient(client.id)
                                    }}
                                />}
                        </Grid>
                        <Grid item xs={6}>
                            <EmployeeField
                                fullWidth
                                object={managerObject}
                                value={manager}
                                label="Менеджер"
                                sx={{paddingTop: '35px'}}
                                onChange={(employee) => {
                                    setManager(employee.id)
                                    setManagerObject(employee)
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SelectShared value={localFilial} setValue={setLocalFilial} label="Филиал" array={filial}/>
                        </Grid>
                        <Grid item xs={6}>
                            <SelectShared disabled={!localFilial}  value={localFilialDivision} required={false} setValue={setLocalFilialDivision}
                                          label="Районное отделение" array={filialDivision && filialDivision.filter(el => el.filialId === localFilial)}/>
                        </Grid>
                        <Grid item xs={6}>
                            <DatePickerShared setValue={setCreatedAt} value={createdAt} label="Дата регистрации"
                                              fullWidth/>
                        </Grid>
                        <Grid item xs={6}>
                            <TextShared required={false} value={comments} setValue={setComments} label="Комментарии"/>
                        </Grid>
                        <Grid item xs={6}>
                            <SelectShared array={serviceCategory}
                                          value={serviceCategoryId}
                                          setValue={setServiceCategoryId} label="Название сделки"/>
                        </Grid>
                    </Grid>
                </Box>
                <LoadingButton
                    loading={loadingAdd}
                    type="submit"
                    variant="contained"
                    sx={{mt: 3, mb: 2}}
                >
                    Зарегистрировать сделку
                </LoadingButton>
            </Box>
            <PopupLegalClient details={true} title="Клиенты (юр. лица)" open={openLegalClient}
                              setOpen={setOpenLegalClient}/>
            <PopupPerson details={true} title="Клиенты (физ. лица)" open={openPerson}
                         setOpen={setOpenPerson}/>
        </Dashboard>
    );
}

export default DealAdd;
