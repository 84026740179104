import React from 'react';
import {useTranslation} from "react-i18next";
import {DeleteForeverIconCustom} from "../../../components";
import {Box, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import ConfirmDialog from "../../../components/Popups/message/PopupDelete";
import ContractRequestObjectRow from "./ContractRequestObjectRow";

const ContractRequestObject = ({mode, edit, contractRequestObject, setContractRequestObject, geo, disabled}) => {
    const {t} = useTranslation();
    const rowName = [
        {text: t("contractRequest.tableItemRequestObject.name")},
        {text: t("contractRequest.tableItemRequestObject.district")},
        {text: t("contractRequest.tableItemRequestObject.city")},
        {text: t("contractRequest.tableItemRequestObject.locality")},
        {text: t("contractRequest.tableItemRequestObject.address")},
        {text: t("contractRequest.tableItem.actions")},
    ]
    const actions = [{icon: <DeleteForeverIconCustom/>, id: 'DeleteForeverIcon'}];
    const handleIcons = async (type, row) => {
        if (type === 'DeleteForeverIcon') {
            const newSpec = contractRequestObject.filter(el => el.Id !== row)
            await setContractRequestObject(newSpec);
        }
    }
    return (
        <Table sx={{minWidth: '80%'}}>
            <TableHead>
                <TableRow>
                    {(mode === 'edit' || !mode) ? rowName.map((el, idx) => {
                        return <TableCell key={idx}
                                          align="center">{el.text}</TableCell>
                    }) : rowName.slice(0, -1).map((el, idx) => {
                        return <TableCell key={idx}
                                          align="center">{el.text}</TableCell>
                    })}
                </TableRow>
            </TableHead>
            <TableBody>
                {contractRequestObject.length ? contractRequestObject.map((row) => (
                        <ContractRequestObjectRow disabled={disabled} mode={mode} edit={edit} key={row.Id || row.id} geo={geo} setContractRequestObject={setContractRequestObject} contractRequestObject={contractRequestObject} actions={actions} row={row} handleIcons={handleIcons}/>)) :
                    <TableRow><TableCell align="center" colSpan={8}><Box>Записей пока
                        нет</Box></TableCell></TableRow>}
            </TableBody>
            <ConfirmDialog/>
        </Table>
    );
}

export default React.memo(ContractRequestObject);